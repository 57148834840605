import {Component, Input, QueryList} from '@angular/core';
import {ControlValueAccessor} from '@angular/forms';
import {EsBeforeOptionDirective, EsOptionsDirective, IEsOption} from 'angular-bootstrap4-extended-select';
import {AbstractCustomSelectComponent} from 'src/modules/dynamic-fields/controls/dynamic-select/custom-select/abstract-custom-select.component';
import {
    AbstractUnvalidatedControlValueAccessor
} from 'src/modules/app-forms/abstract-control-value-accessors/abstract-unvalidated-control-value-accessor';

@Component({
    selector: 'radio-select',
    templateUrl: './abstract-custom-select.component.html',
    styleUrls: ['./abstract-custom-select.component.scss'],
    providers: AbstractUnvalidatedControlValueAccessor.getProviders(RadioSelectComponent)
})
export class RadioSelectComponent extends AbstractCustomSelectComponent implements ControlValueAccessor {
    @Input() esOptionsList: QueryList<EsOptionsDirective<unknown>>;
    @Input() esBeforeOption?: EsBeforeOptionDirective<unknown>;
    @Input() inline: boolean;
    customControlClass = 'custom-radio';
    inputType = 'radio';

    isDisabled = false;
    private _currentOption: IEsOption<unknown>;

    writeValue(value: unknown): void {
        this.esOptionsList.some((esOption) => {
            const option = esOption.options.value.find((option) => option.getValue() === value);
            if (option) {
                this._currentOption = option;
                return true;
            }
        });
    }

    isChecked(option: IEsOption<unknown>): boolean {
        return this._currentOption === option;
    }

    change(
        option: IEsOption<unknown>,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        checked: boolean // needed by abstract-custom-select.component.html
    ): void {
        this._currentOption = option;
        this._onChange(option.getValue());
    }
}
