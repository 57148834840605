import {Directive, ElementRef, HostBinding, HostListener, Inject, Input, OnInit} from '@angular/core';
import {WINDOW} from 'app-custom-providers';
import {StateService} from '@uirouter/angular';

export interface IRowClickInput {
    state: string,
    params?: Record<string, unknown>
}

@Directive({
    selector: '[rowClick]'
})
export class RowClickDirective implements OnInit {
    @Input() rowClick?: IRowClickInput;

    @HostBinding('style.cursor') styleCursor = 'default';

    private _clickData: {
        x: number,
        y: number,
        button: number
    }

    private _blockedElements = ['A', 'BUTTON', 'DYNAMIC-FIELD', 'INPUT', 'TEXTAREA', 'SELECT'];

    constructor(
        private elementRef: ElementRef<HTMLElement>,
        @Inject(WINDOW) private window: Window,
        private stateService: StateService
    ) {
    }

    ngOnInit(): void {
        if (this.rowClick) {
            this.styleCursor = 'pointer';
        }
    }

    // 'click' event works only for left mouse button, so we have to use mousedown/up
    @HostListener('mousedown', ['$event']) onMouseDown(event: MouseEvent): void {
        if (typeof this.rowClick === 'undefined') {
            return;
        }

        this._clickData = {
            x: event.clientX,
            y: event.clientY,
            button: event.button
        };
    }

    @HostListener('mouseup', ['$event']) onMouseUp(event: MouseEvent): void {
        if (
            typeof this.rowClick === 'undefined'
            || this._clickData.x !== event.clientX
            || this._clickData.y !== event.clientY
            || this._clickData.button !== event.button
            || ![0, 1].includes(event.button) // only left or middle click
        ) {
            // ignore mouse selection/drag
            return;
        }

        let target = event.target as HTMLElement;

        while (target !== this.elementRef.nativeElement) {
            if (this._blockedElements.includes(target.tagName)) {
                return;
            }

            target = target.parentNode as HTMLElement;
        }

        if (event.ctrlKey || event.button === 1) { // ctrl or mid mouse btn
            this.window.open(
                this.stateService.href(this.rowClick.state, this.rowClick.params, {absolute: true}),
                '_blank'
            );
        } else {
            this.stateService.go(this.rowClick.state, this.rowClick.params);
        }
    }
}
