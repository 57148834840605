/* eslint-disable max-len */
/**
 * This file has been auto generated. Do not edit.
 */
import {IScheduleTask} from '_types/rest/Entity/IRestScheduleTask';
import {IRestScheduleTask} from '_types/rest/Entity/IRestScheduleTask';
import {IScheduleMail} from '_types/rest/Entity/IRestScheduleMail';
import {IRestScheduleMail} from '_types/rest/Entity/IRestScheduleMail';
import {IBaseRestEntity} from '_types/rest/IBaseRestEntity';
import {IRestObject} from 'core/rest/rest-object.service';

export const ScheduleType = {
    SCHEDULE_TYPE_SCHEDULE_ONE_TIME: 1,
    SCHEDULE_TYPE_SCHEDULE_PERIODICAL: 2
} as const;

export const ScheduleTypeOptions = [
    {
        value: ScheduleType.SCHEDULE_TYPE_SCHEDULE_ONE_TIME,
        label: 'SCHEDULE_TYPE_SCHEDULE_ONE_TIME',
    },
    {
        value: ScheduleType.SCHEDULE_TYPE_SCHEDULE_PERIODICAL,
        label: 'SCHEDULE_TYPE_SCHEDULE_PERIODICAL',
    }
] as const;

export const ScheduleStartDateType = {
    SCHEDULE_TYPE_DATE_NEVER: 1,
    SCHEDULE_TYPE_DATE_DATE: 2,
    SCHEDULE_TYPE_DATE_CONTEXT: 3
} as const;

export const ScheduleStartDateTypeOptions = [
    {
        value: ScheduleStartDateType.SCHEDULE_TYPE_DATE_NEVER,
        label: 'SCHEDULE_TYPE_DATE_NEVER',
    },
    {
        value: ScheduleStartDateType.SCHEDULE_TYPE_DATE_DATE,
        label: 'SCHEDULE_TYPE_DATE_DATE',
    },
    {
        value: ScheduleStartDateType.SCHEDULE_TYPE_DATE_CONTEXT,
        label: 'SCHEDULE_TYPE_DATE_CONTEXT',
    }
] as const;

export const ScheduleStartDateOffsetUnit = {
    SCHEDULE_TYPE_UNIT_DAYS: 1,
    SCHEDULE_TYPE_UNIT_WEEKS: 2,
    SCHEDULE_TYPE_UNIT_YEARS: 3,
    SCHEDULE_TYPE_UNIT_MONTHS: 4
} as const;

export const ScheduleStartDateOffsetUnitOptions = [
    {
        value: ScheduleStartDateOffsetUnit.SCHEDULE_TYPE_UNIT_DAYS,
        label: 'SCHEDULE_TYPE_UNIT_DAYS',
    },
    {
        value: ScheduleStartDateOffsetUnit.SCHEDULE_TYPE_UNIT_WEEKS,
        label: 'SCHEDULE_TYPE_UNIT_WEEKS',
    },
    {
        value: ScheduleStartDateOffsetUnit.SCHEDULE_TYPE_UNIT_YEARS,
        label: 'SCHEDULE_TYPE_UNIT_YEARS',
    },
    {
        value: ScheduleStartDateOffsetUnit.SCHEDULE_TYPE_UNIT_MONTHS,
        label: 'SCHEDULE_TYPE_UNIT_MONTHS',
    }
] as const;

export const ScheduleStopDateType = {
    SCHEDULE_TYPE_DATE_NEVER: 1,
    SCHEDULE_TYPE_DATE_DATE: 2,
    SCHEDULE_TYPE_DATE_CONTEXT: 3
} as const;

export const ScheduleStopDateTypeOptions = [
    {
        value: ScheduleStopDateType.SCHEDULE_TYPE_DATE_NEVER,
        label: 'SCHEDULE_TYPE_DATE_NEVER',
    },
    {
        value: ScheduleStopDateType.SCHEDULE_TYPE_DATE_DATE,
        label: 'SCHEDULE_TYPE_DATE_DATE',
    },
    {
        value: ScheduleStopDateType.SCHEDULE_TYPE_DATE_CONTEXT,
        label: 'SCHEDULE_TYPE_DATE_CONTEXT',
    }
] as const;

export const ScheduleStopDateOffsetUnit = {
    SCHEDULE_TYPE_UNIT_DAYS: 1,
    SCHEDULE_TYPE_UNIT_WEEKS: 2,
    SCHEDULE_TYPE_UNIT_YEARS: 3,
    SCHEDULE_TYPE_UNIT_MONTHS: 4
} as const;

export const ScheduleStopDateOffsetUnitOptions = [
    {
        value: ScheduleStopDateOffsetUnit.SCHEDULE_TYPE_UNIT_DAYS,
        label: 'SCHEDULE_TYPE_UNIT_DAYS',
    },
    {
        value: ScheduleStopDateOffsetUnit.SCHEDULE_TYPE_UNIT_WEEKS,
        label: 'SCHEDULE_TYPE_UNIT_WEEKS',
    },
    {
        value: ScheduleStopDateOffsetUnit.SCHEDULE_TYPE_UNIT_YEARS,
        label: 'SCHEDULE_TYPE_UNIT_YEARS',
    },
    {
        value: ScheduleStopDateOffsetUnit.SCHEDULE_TYPE_UNIT_MONTHS,
        label: 'SCHEDULE_TYPE_UNIT_MONTHS',
    }
] as const;

export const ScheduleFrequencyOffsetUnit = {
    SCHEDULE_TYPE_UNIT_DAYS: 1,
    SCHEDULE_TYPE_UNIT_WEEKS: 2,
    SCHEDULE_TYPE_UNIT_YEARS: 3,
    SCHEDULE_TYPE_UNIT_MONTHS: 4
} as const;

export const ScheduleFrequencyOffsetUnitOptions = [
    {
        value: ScheduleFrequencyOffsetUnit.SCHEDULE_TYPE_UNIT_DAYS,
        label: 'SCHEDULE_TYPE_UNIT_DAYS',
    },
    {
        value: ScheduleFrequencyOffsetUnit.SCHEDULE_TYPE_UNIT_WEEKS,
        label: 'SCHEDULE_TYPE_UNIT_WEEKS',
    },
    {
        value: ScheduleFrequencyOffsetUnit.SCHEDULE_TYPE_UNIT_YEARS,
        label: 'SCHEDULE_TYPE_UNIT_YEARS',
    },
    {
        value: ScheduleFrequencyOffsetUnit.SCHEDULE_TYPE_UNIT_MONTHS,
        label: 'SCHEDULE_TYPE_UNIT_MONTHS',
    }
] as const;

export const ScheduleTargetType = {
    SCHEDULE_TYPE_TARGET_TASK: 1,
    SCHEDULE_TYPE_TARGET_MAIL: 2
} as const;

export const ScheduleTargetTypeOptions = [
    {
        value: ScheduleTargetType.SCHEDULE_TYPE_TARGET_TASK,
        label: 'SCHEDULE_TYPE_TARGET_TASK',
    },
    {
        value: ScheduleTargetType.SCHEDULE_TYPE_TARGET_MAIL,
        label: 'SCHEDULE_TYPE_TARGET_MAIL',
    }
] as const;

export interface IScheduleConst {
    type: [{id: 1, value: 'SCHEDULE_TYPE_SCHEDULE_ONE_TIME'}, {id: 2, value: 'SCHEDULE_TYPE_SCHEDULE_PERIODICAL'}],
    startDateType: [{id: 1, value: 'SCHEDULE_TYPE_DATE_NEVER'}, {id: 2, value: 'SCHEDULE_TYPE_DATE_DATE'}, {id: 3, value: 'SCHEDULE_TYPE_DATE_CONTEXT'}],
    startDateOffsetUnit: [{id: 1, value: 'SCHEDULE_TYPE_UNIT_DAYS'}, {id: 2, value: 'SCHEDULE_TYPE_UNIT_WEEKS'}, {id: 3, value: 'SCHEDULE_TYPE_UNIT_YEARS'}, {id: 4, value: 'SCHEDULE_TYPE_UNIT_MONTHS'}],
    stopDateType: [{id: 1, value: 'SCHEDULE_TYPE_DATE_NEVER'}, {id: 2, value: 'SCHEDULE_TYPE_DATE_DATE'}, {id: 3, value: 'SCHEDULE_TYPE_DATE_CONTEXT'}],
    stopDateOffsetUnit: [{id: 1, value: 'SCHEDULE_TYPE_UNIT_DAYS'}, {id: 2, value: 'SCHEDULE_TYPE_UNIT_WEEKS'}, {id: 3, value: 'SCHEDULE_TYPE_UNIT_YEARS'}, {id: 4, value: 'SCHEDULE_TYPE_UNIT_MONTHS'}],
    frequencyOffsetUnit: [{id: 1, value: 'SCHEDULE_TYPE_UNIT_DAYS'}, {id: 2, value: 'SCHEDULE_TYPE_UNIT_WEEKS'}, {id: 3, value: 'SCHEDULE_TYPE_UNIT_YEARS'}, {id: 4, value: 'SCHEDULE_TYPE_UNIT_MONTHS'}],
    targetType: [{id: 1, value: 'SCHEDULE_TYPE_TARGET_TASK'}, {id: 2, value: 'SCHEDULE_TYPE_TARGET_MAIL'}]
}

export interface ISchedule extends IBaseRestEntity {
    id?: number,
    /**
     * @default 1
     */
    type: typeof ScheduleType[keyof typeof ScheduleType],
    /**
     * @default 2
     */
    startDateType: typeof ScheduleStartDateType[keyof typeof ScheduleStartDateType],
    /**
     * @format date-time
     */
    startDate?: string | null,
    startDateContext?: string | null,
    startDateOffset: number,
    /**
     * @default 2
     */
    startDateOffsetUnit: typeof ScheduleStartDateOffsetUnit[keyof typeof ScheduleStartDateOffsetUnit],
    /**
     * @default 1
     */
    stopDateType: typeof ScheduleStopDateType[keyof typeof ScheduleStopDateType],
    /**
     * @format date-time
     */
    stopDate?: string | null,
    stopDateContext?: string | null,
    stopDateOffset: number,
    /**
     * @default 2
     */
    stopDateOffsetUnit: typeof ScheduleStopDateOffsetUnit[keyof typeof ScheduleStopDateOffsetUnit],
    frequencyOffset: number,
    /**
     * @default 1
     */
    frequencyOffsetUnit: typeof ScheduleFrequencyOffsetUnit[keyof typeof ScheduleFrequencyOffsetUnit],
    businessDays: boolean,
    /**
     * @default 1
     */
    targetType: typeof ScheduleTargetType[keyof typeof ScheduleTargetType],
    scheduleTasks?: (IScheduleTask | string)[],
    scheduleMails?: (IScheduleMail | string)[],
    /**
     * @description Record status
     *  0 = deleted
     *  1 = active.
     * @default 1
     */
    status?: number,
    /**
     * @description Creation timestamp.
     * @default CURRENT_TIMESTAMP
     * @format date-time
     */
    created?: string,
    /**
     * @description Last modification timestamp.
     * @format date-time
     */
    modified?: string | null
}

/** @deprecated */
export interface IRestSchedule extends ISchedule, IRestObject {
    scheduleTasks?: IRestScheduleTask[] | string[],
    scheduleMails?: IRestScheduleMail[] | string[],
}
