import {NgModule} from '@angular/core';
import {DynamicFieldsModule} from 'src/modules/dynamic-fields/dynamic-fields.module';
import {EditableFieldComponent} from 'src/modules/editable-field/editable-field.component';
import {ExtendedSelectModule} from 'angular-bootstrap4-extended-select';
import {AppFormsModule} from 'src/modules/app-forms/app-forms.module';
import {ValidateModule, ValidatorsModule} from 'angular-bootstrap4-validate';
import {AppCommonModule} from 'src/modules/app-common/app-common.module';


@NgModule({
    imports: [
        AppCommonModule,
        AppFormsModule,
        DynamicFieldsModule,
        ExtendedSelectModule,
        ValidateModule,
        ValidatorsModule
    ],
    declarations: [
        EditableFieldComponent
    ],
    exports: [
        EditableFieldComponent
    ]
})
export class EditableFieldModule {
}
