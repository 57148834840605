<selection-tooltip [view$]="view$"
                   *ngIf="open"
                   [from]="state.range.from"
                   [to]="state.range.to"
                   position="start">
    <div class="dropdown-menu show">
        <div class="users-container">
            <ng-container *ngIf="!loading">
                <a *ngFor="let option of options; let index = index"
                   href="javascript:"
                   class="mention-user-list-item dropdown-item d-flex align-items-center"
                   (click)="handleUserClick(index)"
                   [ngClass]="{'active': index === selectedOptionIndex}">
                    <div>
                        <ng-container *ngIf="beforeTemplate"
                                      [ngTemplateOutlet]="beforeTemplate"
                                      [ngTemplateOutletContext]="option.templateContext">
                        </ng-container>
                    </div>

                    <div>
                        <ng-container [ngTemplateOutlet]="option.template"
                                      [ngTemplateOutletContext]="option.templateContext">
                        </ng-container>
                    </div>
                </a>
            </ng-container>

            <span *ngIf="loading"
                  class="dropdown-item-text text-center">
            <i class="fas fa-circle-notch fa-spin"></i>
        </span>

            <span *ngIf="!options?.length && !loading"
                  class="dropdown-item-text">
            {{'COMMON_LIST_IS_EMPTY' | translate}}
        </span>
        </div>
    </div>
</selection-tooltip>
