import {Injectable} from '@angular/core';
import {ReplaySubject, throwError} from 'rxjs';
import {RestClient} from 'src/modules/rest/rest-client.service';
import {Comparison} from 'src/services/comparison';
import {catchError} from 'rxjs/operators';
import {IEntityCategory, IEntityCategoryNode} from '_types/custom/IEntityCategory';


/**
 * Categories service
 * Provide categories data by downloading and computing them once.
 */
@Injectable({
    providedIn: 'root'
})
export class CategoriesService {

    private dataReady: Record<string, boolean> = {};
    private dataLoading: Record<string, boolean> = {};
    private nestedCategories: Record<string, IEntityCategoryNode[]> = {};
    private categoriesList: IEntityCategory[] = [];
    private categoriesNames: Record<string, Record<string, string>> = {};
    private nestedCategoriesSubjects: Record<string, ReplaySubject<IEntityCategoryNode[]>> = {};

    constructor(
        private readonly restClient: RestClient
    ) {
    }

    /**
     * Initialize data loading and computing
     */
    getCategories(
        endpoint: string,
        property = 'categories',
        allowInactive = false
    ): ReplaySubject<IEntityCategoryNode[]> {
        const path = this.computePath(endpoint, property, allowInactive);

        if (typeof this.nestedCategoriesSubjects[path] === 'undefined') {
            this.nestedCategoriesSubjects[path] = new ReplaySubject<IEntityCategoryNode[]>(1);
        }

        if (!this.dataReady[path] && !this.dataLoading[path]) {
            this.dataLoading[path] = true;

            this.loadCategories(endpoint, property, allowInactive, path);
        }
        return this.nestedCategoriesSubjects[path];
    }

    private computePath(endpoint: string, property: string, allowInactive: boolean): string {
        return [
            endpoint,
            property,
            allowInactive.toString()
        ].join('.');
    }

    /**
     * Loads selected entity.entityProperty specific categories.
     */
    private loadCategories(entity: string, entityProperty: string, allowInactive: boolean, path: string): void {
        this.restClient.endpoint<string, never, IEntityCategoryNode>(entity + '/categories')
            .getAll({
                property: entityProperty,
                allowInactive
            })
            .pipe(
                catchError((error) => {
                    this.nestedCategoriesSubjects[path].error(error);
                    return throwError(() => error);
                })
            )
            .subscribe((response) => {
                // Compute data
                this.nestedCategories[path] = response;
                this.computeCategoriesNames(this.nestedCategories[path], this.categoriesNames[path] = {});
                this.computeCategoriesList(this.nestedCategories[path]);

                // Set flags, resolve nestedCategoriesSubjects
                this.dataReady[path] = true;
                this.dataLoading[path] = false;
                this.nestedCategoriesSubjects[path].next(response);
            });
    }

    private computeCategoriesNames(
        nestedCategories: IEntityCategoryNode[],
        categoriesNames: Record<string, string>
    ): void {
        if (!Array.isArray(nestedCategories)) {
            return;
        }
        nestedCategories.forEach((item) => {
            if (Array.isArray(item.categories)) {
                item.categories.forEach((item) => {
                    categoriesNames[item.iri] = item.name;
                });
            }
            this.computeCategoriesNames(item.categoryNodes, categoriesNames);
        });
    }

    computeCategoriesList(
        nestedCategories: IEntityCategoryNode[]
    ): void {
        if (!Array.isArray(nestedCategories)) {
            return;
        }
        nestedCategories.forEach((item) => {
            if (Array.isArray(item.categories)) {
                item.categories.forEach((item) => {
                    this.categoriesList.push(item);
                });
            }
            this.computeCategoriesList(item.categoryNodes);
        });
    }


    getNestedCategories(endpoint: string, property = 'categories', allowInactive = false): IEntityCategoryNode[] {
        const path = this.computePath(endpoint, property, allowInactive);
        return this.nestedCategories[path];
    }

    getCategoriesNames(endpoint: string, property = 'categories', allowInactive = false): Record<string, string> {
        const path = this.computePath(endpoint, property, allowInactive);
        return this.categoriesNames[path];
    }

    getCategoryByIri(iri: string): IEntityCategory {
        return this.categoriesList.find(
            Comparison.criteria({iri: iri})
        );
    }

    getCategoryById(id: number): IEntityCategory {
        return this.categoriesList.find(
            Comparison.byId(id)
        );
    }
}
