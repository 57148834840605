<!-- plain -->
<ng-template [(templateContext)]="plain" let-value="value">
    <span>{{value}}</span>
</ng-template>

<!-- badge -->
<ng-template [(templateContext)]="badge" let-value="value" let-config="config">
    <span class="badge" [ngClass]="config?.preset ? 'badge-' + config.preset : 'badge-primary'"
          [ngStyle]="{color: config?.color, 'background-color': config?.backgroundColor}">
        {{value}}
    </span>
</ng-template>

<!-- icon -->
<ng-template [(templateContext)]="icon" let-value="value" let-config="config">
    <i class="fa"
       [ngClass]="[
            config.icon,
            bootstrapColors[config.color] ? 'text-' + config.color : config.color,
            config.after ? 'ml-2' : 'mr-2'
       ]"></i>
    <span [ngClass]="{'float-left': config.after}" [hidden]="config.hideContent">
        {{value}}
    </span>
</ng-template>

<!-- dynamicField -->
<ng-template [(templateContext)]="dynamicField" let-config="config">
    <dynamic-field [fieldConfig]="config" [formControl]="config.formControl"></dynamic-field>
</ng-template>

<!-- stateUrl -->
<ng-template [(templateContext)]="stateUrl" let-value="value" let-config="config">
    <a [uiSref]="config.state" [uiParams]="config.params">{{value}}</a>
</ng-template>

<!-- title -->
<ng-template [(templateContext)]="title" let-value="value" let-config="config">
    <div [title]="config.title">
        {{value}}
    </div>
</ng-template>

<!-- user -->
<ng-template [(templateContext)]="user" let-config="config">
    <ng-container *ngIf="!config.user && config.showDash; else userContent">
        -
    </ng-container>
    <ng-template #userContent>
        <div class="d-flex align-items-center">
            <avatar [user]="config.user" class="avatar-sm mr-3"
                    [company]="config.user?.company"
                    [userDetails]="config.userDetails"
                    [loadAsyncUserDetails]="config.loadAsyncUserDetails">
            </avatar>
            <div *ngIf="config.user">
                {{ config.user.firstName }} {{ config.user.lastName }}
            </div>
            <div *ngIf="!config.user && config.showUnassigned">{{ 'USER_UNASSIGNED' | translate }}</div>
        </div>
    </ng-template>
</ng-template>
