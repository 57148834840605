/* eslint-disable max-len */
/**
 * This file has been auto generated. Do not edit.
 */
import {ITaskTemplateAction} from '_types/rest/Entity/IRestTaskTemplateAction';
import {IRestTaskTemplateAction} from '_types/rest/Entity/IRestTaskTemplateAction';
import {ITaskActionParameterValue} from '_types/rest/Entity/IRestTaskActionParameterValue';
import {IRestTaskActionParameterValue} from '_types/rest/Entity/IRestTaskActionParameterValue';
import {ITaskActionParameterDefinition} from '_types/rest/Entity/IRestTaskActionParameterDefinition';
import {IRestTaskActionParameterDefinition} from '_types/rest/Entity/IRestTaskActionParameterDefinition';
import {IBaseRestEntity} from '_types/rest/IBaseRestEntity';
import {IRestObject} from 'core/rest/rest-object.service';

export const TaskTemplateAutomaticActionType = {
    CREATE_NOTE: '/rest/task_template_automatic_action_types/1',
    CREATE_TASK: '/rest/task_template_automatic_action_types/2',
    CHANGE_ASSIGNEE: '/rest/task_template_automatic_action_types/3',
    CHANGE_DUE_DATE: '/rest/task_template_automatic_action_types/4'
} as const;

export const TaskTemplateAutomaticActionTypeOptions = [
    {
        value: TaskTemplateAutomaticActionType.CREATE_NOTE,
        label: 'CREATE_NOTE',
    },
    {
        value: TaskTemplateAutomaticActionType.CREATE_TASK,
        label: 'CREATE_TASK',
    },
    {
        value: TaskTemplateAutomaticActionType.CHANGE_ASSIGNEE,
        label: 'CHANGE_ASSIGNEE',
    },
    {
        value: TaskTemplateAutomaticActionType.CHANGE_DUE_DATE,
        label: 'CHANGE_DUE_DATE',
    }
] as const;

export interface ITaskTemplateAutomaticActionConst {
    type: [{id: '/rest/task_template_automatic_action_types/1', value: 'CREATE_NOTE'}, {id: '/rest/task_template_automatic_action_types/2', value: 'CREATE_TASK'}, {id: '/rest/task_template_automatic_action_types/3', value: 'CHANGE_ASSIGNEE'}, {id: '/rest/task_template_automatic_action_types/4', value: 'CHANGE_DUE_DATE'}]
}

export interface ITaskTemplateAutomaticAction extends IBaseRestEntity {
    id?: number,
    type: typeof TaskTemplateAutomaticActionType[keyof typeof TaskTemplateAutomaticActionType],
    taskTemplateAction: ITaskTemplateAction | string,
    /**
     * @default 1
     */
    order?: number,
    taskActionParameterValues?: (ITaskActionParameterValue | string)[],
    taskActionParameterDefinitions?: (ITaskActionParameterDefinition | string)[],
    /**
     * @description Record status
     *  0 = deleted
     *  1 = active.
     * @default 1
     */
    status?: number,
    /**
     * @description Creation timestamp.
     * @default CURRENT_TIMESTAMP
     * @format date-time
     */
    created?: string,
    /**
     * @description Last modification timestamp.
     * @format date-time
     */
    modified?: string | null
}

/** @deprecated */
export interface IRestTaskTemplateAutomaticAction extends ITaskTemplateAutomaticAction, IRestObject {
    taskTemplateAction: IRestTaskTemplateAction | string,
    taskActionParameterValues?: IRestTaskActionParameterValue[] | string[],
    taskActionParameterDefinitions?: IRestTaskActionParameterDefinition[] | string[],
}
