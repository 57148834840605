import {Component, Input, OnChanges} from '@angular/core';
import {COLORS} from 'src/modules/colors/colors.constant';
import {IUserData} from 'src/modules/rest/user/user-login.service';
import {IUserLogin} from '_types/rest/Entity/IRestUserLogin';
import {Utils} from 'src/services/utils';
import {ICompany, ICompanyContactPhone, IUserLoginSummaryOutputDto} from '_types/rest';
import isEqual from 'lodash.isequal';

export type AvatarUser = (IUserData
        | IUserLogin
        | IUserLoginSummaryOutputDto
        | { name: string }
        | { firstName: string, lastName: string })
    & { fileName?: string };

@Component({
    selector: 'avatar',
    templateUrl: './avatar.component.html',
    styleUrls: ['./avatar.component.scss']
})
export class AvatarComponent implements OnChanges {
    @Input() user?: AvatarUser;
    @Input() userDetails? = false;
    @Input() loadAsyncUserDetails? = false;
    @Input() company?: ICompany;
    @Input() companyContactPhone?: ICompanyContactPhone;
    @Input() size? = 28;
    @Input() shadow? = false;

    _user?: AvatarUser;

    initials?: string;
    color?: string;
    textColor?: string;

    fileName?: string;

    ngOnChanges(): void {
        if (isEqual(this.user, this._user)) {
            return;
        }

        this._user = Utils.clone(this.user);
        this.refreshAvatarProperties();
    }

    private refreshAvatarProperties(): void {
        this.initials = undefined;
        this.color = undefined;

        if (!this.user) {
            return;
        }

        if (
            'fileName' in this.user && this.user.fileName
        ) {
            this.fileName = this.user['fileName'];

            return;
        }

        if (
            'file' in this.user
            && typeof this.user.file !== 'undefined'
            && this.user.file !== null
            && typeof this.user.file !== 'string'
            && typeof this.user.file.fileName !== 'undefined'
        ) {
            this.fileName = this.user.file.fileName;

            return;
        }

        this.getInitials();
        this.getColor();
    }

    private getColor(): void {
        let color = (this.initials.charCodeAt(0) + this.initials.charCodeAt(1)) % COLORS.length;

        if (COLORS[color] === 'white') {
            color++;
        }

        this.color = COLORS[color];
        this.textColor = Utils.getTextColor(this.color);
    }

    private getInitials(): void {
        if (!('name' in this.user)) {
            this.initials = this.user.firstName.substring(0, 1) + this.user.lastName.substring(0, 1);
            return;
        }

        const user = this.user.name.split(' ');
        if (user.length === 1) {
            this.initials = Utils.initialsGenerator(this.user.name);
            return;
        }
        this.initials = user[0].substring(0, 1) + user[1].substring(0, 1);
        return;
    }

    onAvatarLoadingError(): void {
        this.fileName = undefined;
        this.getInitials();
        this.getColor();
    }
}
