import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {
    AbstractControl,
    DefaultValueAccessor,
    NG_VALIDATORS,
    NG_VALUE_ACCESSOR,
    ValidationErrors,
    Validator
} from '@angular/forms';
import {
    IDynamicFieldControlValueAccessor, IEmailDynamicFieldConfig,
    IStringDynamicFieldConfig
} from 'src/modules/dynamic-fields/interfaces';
import {Validators} from 'angular-bootstrap4-validate';

@Component({
    selector: 'custom-input',
    templateUrl: './custom-input.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: CustomInputComponent
        },
        {
            provide: NG_VALIDATORS,
            multi: true,
            useExisting: CustomInputComponent
        }
    ]
})
export class CustomInputComponent
    extends DefaultValueAccessor
    implements IDynamicFieldControlValueAccessor, Validator, OnInit {

    @Input() fieldConfig: IEmailDynamicFieldConfig | IStringDynamicFieldConfig;
    @ViewChild('inputElement', {static: true}) inputElement: ElementRef<HTMLInputElement>;

    ngOnInit(): void {
        // @ts-expect-error: we need to override elementRef of BaseControlValueAccessor for proper setDisabled support
        this._elementRef = this.inputElement;
    }

    validate(control: AbstractControl): ValidationErrors | null {
        if (this.fieldConfig.type === 'email') {
            return Validators.email(control);
        }

        return null;
    }
}
