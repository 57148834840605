import {Component, Host, Input, OnDestroy, OnInit} from '@angular/core';
import {IFile, ITaskInputDto, ITaskNote, With} from '_types/rest';
import isEqual from 'lodash.isequal';
import {forkJoin, Subject, Subscription, throwError} from 'rxjs';
import {catchError, finalize, takeUntil, tap} from 'rxjs/operators';
import {IFileListItem} from 'src/modules/file-upload/file-list/file-list.component';
import {FileUploadHelper, IFileRestModel} from 'src/modules/file-upload/file-upload-helper';
import {IUploadFile} from 'src/modules/file-upload/file-upload/file-upload.component';
import {IRestObject} from 'src/modules/rest/objects';
import {RestClient} from 'src/modules/rest/rest-client.service';
import {
    AppTask,
    boundNoteFile, boundTask,
    boundTaskFile,
    TaskViewComponent,
} from 'src/modules/tasks/task-view/task-view.component';
import {Utils} from 'src/services/utils';

type IFileListUploadedItem = IFileListItem & {
    fileUploadSubscription?: Subscription;
    file?: IFile;
}

@Component({
    selector: 'task-files',
    templateUrl: './task-files.component.html'
})
export class TaskFilesComponent implements OnInit, OnDestroy {
    @Input() task: AppTask;
    @Input() taskRest: IRestObject<'tasks/item', boundTask>;

    @Input() files: IFile[] = [];
    filesRestModel: With<IFileRestModel, 'file'>[] = [];

    taskFilesLoading = false;

    taskFilesCount: number;

    taskFiles: With<IFileRestModel, 'file'>[] = [];

    uploadingFiles : IFileListUploadedItem[] = [];

    private checkAllFilesAreUploadedSubject = new Subject<void>();
    private readonly checkAreAllFilesUploaded$ = this.checkAllFilesAreUploadedSubject.asObservable();

    private readonly _destroy$ = new Subject<void>();

    constructor(
        @Host() private readonly taskViewComponent: TaskViewComponent,
        private readonly restClient: RestClient
    ) {}

    ngOnInit(): void {
        if (this.task.id) {
            this.loadFilesFromApi();
        } else {
            this.loadFilesFromInput();
        }
        this.subscribeAreAllFilesUploaded();
    }

    /**
     * Re/Load files from task and notes
     */
    loadFilesFromApi(): void {
        this.taskFilesLoading = true;
        forkJoin([
            this.restClient.endpoint<'task_files', unknown, boundTaskFile>('task_files').getAll({
                'task.id': this.task?.id,
                pagination: false
            }),
            this.restClient.endpoint<'task_note_files', unknown, boundNoteFile>('task_note_files').getAll({
                'taskNote.task.id': this.task?.id,
                pagination: false
            })
        ])
            .pipe(
                finalize(() => {
                    this.taskFilesLoading = false;
                })
            )
            .subscribe(([taskFiles, taskNoteFiles]) => {
                this.taskFilesCount = taskFiles.hydra().totalItems + taskNoteFiles.hydra().totalItems;

                this.taskFiles = [
                    ...(taskFiles || []),
                    ...(taskNoteFiles  || [])
                ] as With<IFileRestModel, 'file'>[];
            });
    }

    private loadFilesFromInput() {
        if (!this.files) {
            return;
        }

        this.files.forEach((file) => {
            this.uploadFileInNewTask(file);

            this.filesRestModel.push(
                FileUploadHelper.prepareFileObjectModelFromFile(file)
            );
        });
    }

    private subscribeAreAllFilesUploaded(): void {
        this.checkAreAllFilesUploaded$
            .pipe(takeUntil(this._destroy$))
            .subscribe(() => {
                const filesToSave = Utils.clone(this.uploadingFiles);

                if (!filesToSave.length || this.areSomeUploadingFilesSubscriptionsNotClosed()) {
                    return;
                }

                this.saveTaskNoteWithFiles(filesToSave);
            });
    }

    private areSomeUploadingFilesSubscriptionsNotClosed(): boolean {
        return this.uploadingFiles.some((uploadingFile) => {
            return !uploadingFile.fileUploadSubscription.closed;
        });
    }

    uploadFilesInExistingTask(files: File[]): void {
        Array.from(files).forEach((file: File) => {
            const fileObject: IFileListUploadedItem = {
                id: null,
                name: file.name,
                progress: 0
            };

            fileObject.fileUploadSubscription = this.restClient.endpoint('files/upload')
                .upload(
                    file,
                    {
                        tableName: 'task_note_file',
                        entityFieldName: 'file'
                    },
                    (e) => {
                        fileObject.progress = (e.loaded / e.total) * 100;
                    }
                )
                .pipe(
                    catchError((error) => {
                        this.uploadingFiles.splice(this.uploadingFiles.indexOf(fileObject), 1);

                        return throwError(() => error);
                    }),
                    tap(() => {
                        fileObject.progress = 100;
                    }),
                    finalize(() => {
                        this.checkAllFilesAreUploadedSubject.next();
                    })
                ).subscribe((file) => {
                    fileObject.file = file;
                });

            this.uploadingFiles.push(fileObject);
        });
    }

    cancelUpload(fileToRemove: IFileListUploadedItem): void {
        this.uploadingFiles.splice(this.uploadingFiles.indexOf(fileToRemove), 1);
        fileToRemove.fileUploadSubscription.unsubscribe();
    }

    saveTaskNoteWithFiles(files: IFileListUploadedItem[]): void {
        const filesToSave = Utils.clone(files);
        filesToSave.forEach((file) => Utils.shortenFields(file, ['file']));

        const noteToSave: ITaskNote = {
            task: this.task['@id'],
            text: '',
            public: 1,
            taskNoteFiles: filesToSave
        };

        this.restClient.endpoint('task_notes').createObject(noteToSave)
            .persist()
            .subscribe(() => {
                this.removeFilesFromUploadingFiles(files);
                this.loadFilesFromApi();
                this.taskViewComponent.taskStoryComponent.reloadStoryFromOutside();
            });
    }

    private removeFilesFromUploadingFiles(files: IFileListUploadedItem[]): void {
        const uploadingFilesClone = Utils.clone(this.uploadingFiles),
            filesClone = Utils.clone(files);

        uploadingFilesClone.map((uploadingFile) => {
            delete uploadingFile.fileUploadSubscription;
        });

        filesClone.map((file) => {
            delete file.fileUploadSubscription;
        });

        this.uploadingFiles = uploadingFilesClone.filter(
            (uploadingFile) => {
                return !filesClone.some((file) => {
                    return isEqual(uploadingFile, file);
                });
            });
    }

    uploadFileInNewTask(file: IFile): void {
        if (this.task?.id) {
            return;
        }

        this.getNewTaskFiles().push(file['@id']);
    }

    private getNewTaskFiles(): (IFile | string)[] {
        const taskDto = this.task as unknown as ITaskInputDto;

        if (!taskDto.files) {
            taskDto.files = [];
        }

        return taskDto.files;
    }

    deleteFileInNewTask(file: IUploadFile): void {
        const newTasKFiles = this.getNewTaskFiles(),
            fileIndex = newTasKFiles.indexOf(file['@id']);

        newTasKFiles.splice(fileIndex, 1);
    }

    ngOnDestroy(): void {
        this._destroy$.next();
        this._destroy$.complete();
    }

}
