import {Injector} from '@angular/core';
import {Observable, of, Subscription} from 'rxjs';
import {OperationsService} from 'src/modules/operations/operations.service';
import {RestClient} from 'src/modules/rest/rest-client.service';
import {
    AbstractOperationDefinition, IOperationResult,
} from 'src/modules/operations/interfaces';
import {TimeTrackerService} from 'src/modules/time-tracker/time-tracker.service';
import {
    ITrackedEntry,
    TIME_TRACKER_CHANNEL,
    TrackerEntryType
} from 'src/modules/time-tracker/interfaces';
import {ITimeTrackerSubjectOutputDto} from '_types/rest';
import {catchError, filter, map, tap} from 'rxjs/operators';
import {TIMETRACKER_FEATURE_PRIVILEGE} from 'src/providers/feature-privileges';
import {BroadcastMessage} from 'src/services/broadcast.service';
import {ITimeTrackerStartOutputDto} from '_types/rest/Dto/IRestTimeTrackerSubjectTimeTrackerStartOutputDto';
import {UserPrivilegesPipe} from 'src/modules/rest/user/user-privileges.pipe';
import {UserService} from 'src/modules/rest/user/user.service';

export abstract class AbstractTimeTrackerOperation<T extends TrackerEntryType = TrackerEntryType>
    extends AbstractOperationDefinition<string, T> {

    private registeredSubscriptionsMap = new WeakMap<object, Subscription>();

    hasPrivilegesToTimeTrackerFeature(injector: Injector): boolean {
        return new UserPrivilegesPipe(injector.get(UserService)).transform(TIMETRACKER_FEATURE_PRIVILEGE);
    }

    getIri(context: T): string | undefined {
        if (typeof context === 'undefined') {
            return undefined;
        }

        if ('iri' in context && 'name' in context) {
            return context.iri;
        }
        return context['@id'];
    }

    protected getTrackingEntryFromEntityOrPrepareNew(entity: T, injector: Injector): ITrackedEntry {
        const timeTrackerService = injector.get(TimeTrackerService),
            contextTrackedEntry = timeTrackerService.getTimeTrackerEntryByIri(this.getIri(entity));

        if (typeof contextTrackedEntry !== 'undefined') {
            return contextTrackedEntry;
        }

        return {
            id: entity.id,
            iri: this.getIri(entity),
            name: entity.name,
            start: null,
            services: [timeTrackerService.getService(entity)],
            userTimeLoggedTotal: 0,
            working: false
        };
    }

    // The operation is refreshed asynchronously. To refresh the operation, you need to change
    // operation's context. Each operation has one instance.
    // We create a map with subscriptions to listen on BroadcastChannel
    // and destroy the Subscription when the <operation> component is destroyed
    private registerTimestampRefresh(
        context: T,
        injector: Injector,
        operationComponent: object
    ): void {
        if (typeof context === 'undefined' || typeof operationComponent === 'undefined') {
            return;
        }

        if (this.registeredSubscriptionsMap.has(operationComponent)) {
            return;
        }

        const subscription = this.handleMessagesAndReturnSubscription(context, injector);
        this.registeredSubscriptionsMap.set(operationComponent, subscription);
    }

    // Change the operation context to call access() and reload the available operations on the entry
    private handleMessagesAndReturnSubscription(context: T, injector: Injector): Subscription {
        const broadcastService = injector.get(TIME_TRACKER_CHANNEL),
            iri = this.getIri(context);

        return broadcastService
            .messagesOfType(['start', 'stop', 'pause'])
            .pipe(
                filter((message: BroadcastMessage<'start' | 'stop' | 'pause', ITrackedEntry>) => {
                    if (message.type === 'start') {
                        return true;
                    }
                    return message.payload.iri === iri;
                })
            ).subscribe(() => {
                context._timeTrackerRefreshTimestamp = Date.now();
            });
    }

    onInit(context: T, injector: Injector, operationComponent?: object): void {
        this.registerTimestampRefresh(context, injector, operationComponent);
    }

    onDestroy(context: T, injector: Injector, operationComponent?: object): void {
        this.registeredSubscriptionsMap.get(operationComponent)?.unsubscribe();
        this.registeredSubscriptionsMap.delete(operationComponent);
    }
}

export abstract class TimeTrackerCommonOperations {
    static startTrackerRequest(context: ITrackedEntry, injector: Injector): Observable<IOperationResult> {
        const restClient = injector.get(RestClient),
            timeTrackerService = injector.get(TimeTrackerService),
            broadcastService = injector.get(TIME_TRACKER_CHANNEL),
            startEndpoint = restClient.endpoint('time_tracker_subjects/start');

        const bodyRequest = typeof context !== 'undefined' && 'iri' in context ? {
            iri: context.iri
        } : {};

        return startEndpoint.create(bodyRequest)
            .pipe(
                tap((response: ITimeTrackerStartOutputDto) => {
                    const newContext: ITrackedEntry = timeTrackerService.getTrackingEntryFromResponse(response);

                    timeTrackerService.activeEntry = newContext;
                    broadcastService.publish('start', newContext);
                }),
                map(() => {
                    return {success: true};
                }),
                catchError((error) => {
                    restClient.handleError(error);
                    return of({success: false});
                })
            );
    }

    static pauseTrackerRequest(context: ITrackedEntry, injector: Injector): Observable<IOperationResult> {
        const restClient = injector.get(RestClient),
            timeTrackerService = injector.get(TimeTrackerService),
            broadcastService = injector.get(TIME_TRACKER_CHANNEL),
            pauseEndpoint = restClient.endpoint<'time_tracker_subjects/pause', ITimeTrackerSubjectOutputDto>
            ('time_tracker_subjects/pause');

        return pauseEndpoint.update(undefined, {})
            .pipe(
                tap((response) => {
                    const newContext: ITrackedEntry = timeTrackerService.getTrackingEntryFromResponse(response);

                    timeTrackerService.activeEntry = undefined;
                    broadcastService.publish('pause', newContext);

                }),
                map(() => {
                    return {success: true};
                }),
                catchError((error) => {
                    restClient.handleError(error);
                    return of({success: false});
                })
            );
    }

    static executeStopOperation(context: ITrackedEntry, injector: Injector): Observable<IOperationResult> {
        const operationsService = injector.get(OperationsService);
        const operation = operationsService.get(TimeTrackerStopOperation);
        return operationsService.execute(operation, context);
    }
}

export class TimeTrackerStopOperation extends AbstractTimeTrackerOperation<ITrackedEntry> {
    readonly endpoint = 'time_tracker_stop_operation'
    readonly name = 'stop';

    icon = 'fa-stop';
    lang = 'COMMON_STOP';
    showHeaderCloseButton = false;

    component = (): Promise<unknown> =>
        import('view-modules/operations/time-tracker/time-tracker-form/time-tracker-form.component');

    access = (): boolean => {
        return true;
    };
}

export const timeTrackerAbstractOperations = [
    TimeTrackerStopOperation
] as const;
