import {AbstractDeleteOperation} from 'view-modules/operations/abstract/abstract-delete.operation';
import {AbstractOperationDefinition} from 'src/modules/operations/interfaces';
import {OperationsService} from 'src/modules/operations/operations.service';
import {Injector} from '@angular/core';
import {
    ConfirmModalService,
    IConfirmModalOptions
} from 'src/modules/global-components/confirm-modal/confirm-modal.service';
import {TranslateService} from 'src/modules/translate/translate.service';
import {AccessPipe} from 'src/pipes/access.pipe';
import {IRestObject} from 'src/modules/rest/objects';

export class ApiListDeleteOperation extends AbstractDeleteOperation<'client_o_auth2s'> {
    readonly endpoint = 'client_o_auth2s';
    lang = 'DELETE';
    confirmMessage = 'DELETE_API_CLIENT_CONFIRM';
    confirmOptions: IConfirmModalOptions = {
        langYes: 'DELETE_API_CLIENT_CONFIRM_LANG_YES',
        primaryBtn: 'danger',
        text: 'ACTION_CANNOT_BE_UNDONE'
    };
}

export class ApiListEditOperation extends AbstractOperationDefinition<'client_o_auth2s'> {
    readonly endpoint = 'client_o_auth2s';
    readonly name = 'put';
    lang = 'EDIT';
    icon = 'fa-edit';
    component = (): Promise<unknown> => import('./api-list-form/api-list-form.component');
}

export class ApiListAddOperation extends AbstractOperationDefinition<'client_o_auth2s'> {
    readonly endpoint = 'client_o_auth2s';
    readonly name = 'post';
    lang = 'ADD';
    icon = 'fa-plus';
    component = (): Promise<unknown> => import('./api-list-form/api-list-form.component');
}

export class ConfirmRegenerateSecretKey extends AbstractOperationDefinition<'client_o_auth2s'> {
    readonly endpoint = 'client_o_auth2s';
    readonly name = 'confirm_regenerate_secret_key';
    lang = 'OAUTH_REGENERATE_SECRET';
    icon = 'fas fa-key text-primary';
    invoke = (
        context: IRestObject<'client_o_auth2s'>,
        injector: Injector
    ): void => {
        const confirmModal = injector.get(ConfirmModalService);
        const translate = injector.get(TranslateService);
        const operationsService = injector.get(OperationsService);
        confirmModal.confirm(translate.get('REFRESH_OAUTH_CLIENT_SECRET_CONFIRM'), () => {
            const getRegenerateSecretKeyOperation = operationsService.get(ApiListRegenerateSecretKeyOperation);
            operationsService.execute(getRegenerateSecretKeyOperation, context);
        });
    };
    access = (context: IRestObject<'client_o_auth2s'>): boolean => {
        if (!context || typeof context.access !== 'function') {
            return false;
        }
        return new AccessPipe().transform(context, 'delete');
    };
}

export class ApiListRegenerateSecretKeyOperation extends AbstractOperationDefinition<'client_o_auth2s'> {
    readonly endpoint = 'client_o_auth2s';
    readonly name = 'regenerate_secret_key';
    lang = 'OAUTH_CREATE_SUCCESS';
    icon = 'fas fa-key text-primary';
    component = (): Promise<unknown> => import('./regenerate-secret-key/regenerate-secret-key.component');
    access = (context: IRestObject<'client_o_auth2s'>): boolean => {
        if (!context || typeof context.access !== 'function') {
            return false;
        }
        return new AccessPipe().transform(context, 'refresh_password');
    };
}

export const apiListOperations = [
    ApiListAddOperation,
    ApiListDeleteOperation,
    ApiListEditOperation,
    ApiListRegenerateSecretKeyOperation,
    ConfirmRegenerateSecretKey
] as const;
