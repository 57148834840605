import {animate, animation, style} from '@angular/animations';


export const fadeOutToastsAnimation = animation([
    style({ opacity: 0.9}),
    animate(
        '{{ time }}',
        style({ opacity: 0 })
    ),
]);
