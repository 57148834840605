import {
    INotificationToastOutputDto
} from '_types/rest/Dto/IRestNotificationToastNotificationToastOutputDto';
import {IRestCollection, IRestObject} from 'src/modules/rest/objects';
import {Inject, Injectable, OnDestroy} from '@angular/core';
import {RestClient} from 'src/modules/rest/rest-client.service';
import {IContractItem, NotificationLevel, With} from '_types/rest';
import {Comparison} from 'src/services/comparison';
import {Observable} from 'rxjs';
import {Utils} from 'src/services/utils';
import {
    INotificationsBroadcast,
    NOTIFICATIONS_CHANNEL
} from 'src/modules/notifications/interfaces';
import {BroadcastService} from 'src/services/broadcast.service';
import {finalize, tap} from 'rxjs/operators';


export const levelColors = [
    {
        value:'NOTIFICATION_LEVEL_CRITICAL',
        icon: 'fas fa-times',
        color: '#FF7588'
    } ,
    {
        value:'NOTIFICATION_LEVEL_IMPORTANT',
        icon: 'fas fa-exclamation-triangle',
        color: '#FFA87D'
    },
    {
        value:'NOTIFICATION_LEVEL_INFO',
        icon: 'fas fa-info-circle',
        color: '#2DCEE3'
    }
];

export interface IAppUnreadCounts {
    '': never; // this is needed to make sure that IAppUnreadCounts doesn't falsely extend IBaseRestEntity
    [key: string]: number
}

export interface IAppNotificationType {
    id: number;
    value: string;
    icon?: string;
    color?: string;
    unread?: number
}

export interface IAppNotificationToast extends INotificationToastOutputDto {
    _type?: IAppNotificationType
    _saving?: boolean
}

@Injectable({
    providedIn: 'root'
})
export class NotificationsService implements OnDestroy {

    constructor(
        @Inject(NOTIFICATIONS_CHANNEL) private broadcastService: BroadcastService<INotificationsBroadcast>,
        private restClient: RestClient
    ) {}

    prepareData(notificationToasts: IRestCollection<'notification_toasts',
                    IRestObject<'notification_toasts', INotificationToastOutputDto>>,
    unreadCounts: IAppUnreadCounts):
        [IAppNotificationType[], IAppNotificationToast[], number] {
        let unreadTotal = 0;
        let notificationTypes: IAppNotificationType[] = [];

        Object.keys(NotificationLevel).forEach((item) => {
            notificationTypes.push({
                id: NotificationLevel[item],
                value: item
            });
        });

        notificationTypes = notificationTypes.map((item) => {
            return Object.assign(item, levelColors.find(Comparison.criteria({value: item.value})));
        });
        Object.keys(unreadCounts).forEach((item) => {
            if (item === 'all') {
                unreadTotal = unreadCounts[item];
                return;
            }
            const index = notificationTypes.findIndex(Comparison.criteria({value: item}));
            notificationTypes[index] = Object.assign({unread: unreadCounts[item]},
                notificationTypes[index]);
        });
        const notifications: IAppNotificationToast[] = this.prepareToasts(notificationTypes, notificationToasts);

        return [notificationTypes, notifications, unreadTotal];
    }

    prepareToasts(notificationTypes: IAppNotificationType[],  notificationToasts: IAppNotificationToast[]):
        IAppNotificationToast[] {
        notificationToasts.map((item: IAppNotificationToast) => {
            item = this.prepareToast(notificationTypes, item);
            return item;
        });

        return notificationToasts;
    }

    prepareToast(notificationTypes: IAppNotificationType[],  notificationToast: IAppNotificationToast):
        IAppNotificationToast {
        notificationToast._type = notificationTypes.find(Comparison.criteria({id: notificationToast.level}));
        return notificationToast;
    }

    filterNotifications(toasts: IAppNotificationToast[], level?: number, read?: boolean): IAppNotificationToast[] {
        if (typeof read !== 'undefined') {
            toasts = toasts.filter((toast) => toast.read === read);
        }
        if (typeof level !== 'undefined') {
            toasts = toasts.filter((toast) => toast.level === level);
        }

        return toasts;
    }

    toggleReadState(notify: IAppNotificationToast): void {
        notify._saving = true;

        this.restClient.endpoint('notification_toasts').update(notify.uuid, {
            read: !notify.read
        })
            .pipe(
                finalize(() => {
                    notify._saving = false;
                }))
            .subscribe(
                (response) => {
                    this.broadcastService.publish('toggle_read_state', response);
                }
            );
    }

    recalculateUnread(notify: IAppNotificationToast,
        responseNotify: INotificationToastOutputDto,
        notificationTypes: IAppNotificationType[],
        unreadTotal: number): number {
        const oldNotify = Utils.clone(notify);
        if (responseNotify !== null) {
            notify.read = responseNotify.read;
        }

        if ((responseNotify !== null && (oldNotify.read !== responseNotify.read)) || responseNotify === null) {
            const index = notificationTypes.findIndex(Comparison.criteria({id: notify.level}));
            if (notify.read) {
                notificationTypes[index].unread--;
                unreadTotal--;
            } else {
                notificationTypes[index].unread++;
                unreadTotal++;
            }
        }
        return unreadTotal;
    }

    setReadAll(): Observable<IRestObject<'notification_toasts/batch'>> {
        return this.restClient.endpoint('notification_toasts/batch').update(null,
            {
                type: 1
            })
            .pipe(
                tap(() => {
                    this.broadcastService.publish('set_read_all');
                })
            );
    }

    getNotifyAndBroadcast(uuid: string): void {
        this.restClient.endpoint('notification_toasts')
            .get(uuid,
                {
                    with: [ 'company', 'contract', 'service', 'contract.contractItems']
                })
            .subscribe((notify) => {
                this.broadcastService.publish('new_notify', notify);
            });
    }

    unshiftIfNotExists(notifications: IAppNotificationToast[],
        notification: IAppNotificationToast,
        notificationTypes: IAppNotificationType[],
        unreadTotal: number): number {

        if (notifications.findIndex((item) => item.uuid === notification.uuid) === -1) {
            notifications.unshift(notification);
            return this.recalculateUnread(notification, null, notificationTypes, unreadTotal);
        }
        return unreadTotal;
    }

    recalculateUnreadAll(notifications: IAppNotificationToast[],
        notificationTypes: IAppNotificationType[]): void {
        notifications.map((notify) => {
            notify.read = true;
            return notify;
        });

        notificationTypes.map((type) => {
            type.unread = 0;
            return type;
        });
    }

    getContractItemId(notify: IAppNotificationToast): number {
        if (notify.contract !== null && Array.isArray(notify.contract.contractItems)) {
            const contractItem = (notify.contract.contractItems as With<IContractItem, 'service'>[])
                .find((item) => {
                    return item.service.id === notify.service.id;
                });
            return contractItem.id || null;
        }
        return null;
    }

    ngOnDestroy(): void {
        this.broadcastService.destroy();
    }
}
