/* eslint-disable max-len */
/**
 * This file has been auto generated. Do not edit.
 */
import {ICompany} from '_types/rest/Entity/IRestCompany';
import {IRestCompany} from '_types/rest/Entity/IRestCompany';
import {IPrivilegeAccessLevel} from '_types/rest/Entity/IRestPrivilegeAccessLevel';
import {IRestPrivilegeAccessLevel} from '_types/rest/Entity/IRestPrivilegeAccessLevel';
import {IDocumentTemplate} from '_types/rest/Entity/IRestDocumentTemplate';
import {IRestDocumentTemplate} from '_types/rest/Entity/IRestDocumentTemplate';
import {IPartnerDomain} from '_types/rest/Entity/IRestPartnerDomain';
import {IRestPartnerDomain} from '_types/rest/Entity/IRestPartnerDomain';
import {IPartnerAuthenticationType} from '_types/rest/Entity/IRestPartnerAuthenticationType';
import {IRestPartnerAuthenticationType} from '_types/rest/Entity/IRestPartnerAuthenticationType';
import {ICatalog} from '_types/rest/Entity/IRestCatalog';
import {IRestCatalog} from '_types/rest/Entity/IRestCatalog';
import {IProduct} from '_types/rest/Entity/IRestProduct';
import {IRestProduct} from '_types/rest/Entity/IRestProduct';
import {IBsgServiceLockPartnerSettings} from '_types/rest/Entity/IRestBsgServiceLockPartnerSettings';
import {IRestBsgServiceLockPartnerSettings} from '_types/rest/Entity/IRestBsgServiceLockPartnerSettings';
import {IBusinessDay} from '_types/rest/Entity/IRestBusinessDay';
import {IRestBusinessDay} from '_types/rest/Entity/IRestBusinessDay';
import {IACLApiScopePartner} from '_types/rest/Entity/IRestACLApiScopePartner';
import {IRestACLApiScopePartner} from '_types/rest/Entity/IRestACLApiScopePartner';
import {IEntityFieldValueFile} from '_types/rest/Entity/IEntityFieldValueFile';
import {IBaseRestEntity} from '_types/rest/IBaseRestEntity';
import {IRestObject} from 'core/rest/rest-object.service';

export const PartnerType = {
    PARTNER_TYPE_WHITELABEL: 1,
    PARTNER_TYPE_BLACKLABEL: 2,
    PARTNER_TYPE_BAUER: 3
} as const;

export const PartnerTypeOptions = [
    {
        value: PartnerType.PARTNER_TYPE_WHITELABEL,
        label: 'PARTNER_TYPE_WHITELABEL',
    },
    {
        value: PartnerType.PARTNER_TYPE_BLACKLABEL,
        label: 'PARTNER_TYPE_BLACKLABEL',
    },
    {
        value: PartnerType.PARTNER_TYPE_BAUER,
        label: 'PARTNER_TYPE_BAUER',
    }
] as const;

export const PartnerMfaWorker = {
    PARTNER_MFA_DISABLED: 0,
    PARTNER_MFA_OPTIONAL: 1,
    PARTNER_MFA_REQUIRED: 2
} as const;

export const PartnerMfaWorkerOptions = [
    {
        value: PartnerMfaWorker.PARTNER_MFA_DISABLED,
        label: 'PARTNER_MFA_DISABLED',
    },
    {
        value: PartnerMfaWorker.PARTNER_MFA_OPTIONAL,
        label: 'PARTNER_MFA_OPTIONAL',
    },
    {
        value: PartnerMfaWorker.PARTNER_MFA_REQUIRED,
        label: 'PARTNER_MFA_REQUIRED',
    }
] as const;

export const PartnerMfaClient = {
    PARTNER_MFA_DISABLED: 0,
    PARTNER_MFA_OPTIONAL: 1,
    PARTNER_MFA_REQUIRED: 2
} as const;

export const PartnerMfaClientOptions = [
    {
        value: PartnerMfaClient.PARTNER_MFA_DISABLED,
        label: 'PARTNER_MFA_DISABLED',
    },
    {
        value: PartnerMfaClient.PARTNER_MFA_OPTIONAL,
        label: 'PARTNER_MFA_OPTIONAL',
    },
    {
        value: PartnerMfaClient.PARTNER_MFA_REQUIRED,
        label: 'PARTNER_MFA_REQUIRED',
    }
] as const;

export interface IPartnerConst {
    type: [{id: 1, value: 'PARTNER_TYPE_WHITELABEL'}, {id: 2, value: 'PARTNER_TYPE_BLACKLABEL'}, {id: 3, value: 'PARTNER_TYPE_BAUER'}],
    mfaWorker: [{id: 0, value: 'PARTNER_MFA_DISABLED'}, {id: 1, value: 'PARTNER_MFA_OPTIONAL'}, {id: 2, value: 'PARTNER_MFA_REQUIRED'}],
    mfaClient: [{id: 0, value: 'PARTNER_MFA_DISABLED'}, {id: 1, value: 'PARTNER_MFA_OPTIONAL'}, {id: 2, value: 'PARTNER_MFA_REQUIRED'}]
}

export interface IPartner extends IBaseRestEntity {
    /**
     * @description ID.
     */
    id?: number,
    /**
     * @description Partner type (Whitelabel, Blacklabel).
     * @default 2
     */
    type: typeof PartnerType[keyof typeof PartnerType],
    /**
     * @description Partner to which a record is assigned (client to partner, partner to the Bauer company).
     */
    partner?: IPartner | string | null,
    readonly children?: (IPartner | string)[],
    /**
     * @description Company to which the partner is assigned.
     */
    company?: ICompany | string | null,
    /**
     * @description Collection of privilege groups created for partner.
     */
    privilegeAccessLevels?: (IPrivilegeAccessLevel | string)[],
    /**
     * @description Determines if partner's panel is active.
     */
    active?: number,
    documentTemplates?: (IDocumentTemplate | string)[],
    partnerDomains?: (IPartnerDomain | string)[],
    /**
     * @description Delay between password changes.
     * @default 30
     */
    passwordChangeDelay?: number,
    /**
     * @description Multi-factor authentication for workers.
     */
    mfaWorker?: typeof PartnerMfaWorker[keyof typeof PartnerMfaWorker],
    /**
     * @description Multi-factor authentication for clients.
     */
    mfaClient?: typeof PartnerMfaClient[keyof typeof PartnerMfaClient],
    authenticationTypes?: (IPartnerAuthenticationType | string)[],
    catalogs?: (ICatalog | string)[],
    products?: (IProduct | string)[],
    bsgServiceLockPartnerSettings?: (IBsgServiceLockPartnerSettings | string)[],
    businessDays?: (IBusinessDay | string)[],
    /**
     * @description Automatic Contract's states.
     * @default true
     */
    automaticContractStateChange?: boolean,
    ACLApiScopePartners?: (IACLApiScopePartner | string)[],
    /**
     * @description Record status
     *  0 = deleted
     *  1 = active.
     * @default 1
     */
    status?: number,
    /**
     * @description Creation timestamp.
     * @default CURRENT_TIMESTAMP
     * @format date-time
     */
    created?: string,
    /**
     * @description Last modification timestamp.
     * @format date-time
     */
    modified?: string | null,
    _field?: { [key: string]: string | IEntityFieldValueFile }
}

/** @deprecated */
export interface IRestPartner extends IPartner, IRestObject {
    /**
     * @description Partner to which a record is assigned (client to partner, partner to the Bauer company).
     */
    partner?: IRestPartner | string | null,
    readonly children?: IRestPartner[] | string[],
    /**
     * @description Company to which the partner is assigned.
     */
    company?: IRestCompany | string | null,
    /**
     * @description Collection of privilege groups created for partner.
     */
    privilegeAccessLevels?: IRestPrivilegeAccessLevel[] | string[],
    documentTemplates?: IRestDocumentTemplate[] | string[],
    partnerDomains?: IRestPartnerDomain[] | string[],
    authenticationTypes?: IRestPartnerAuthenticationType[] | string[],
    catalogs?: IRestCatalog[] | string[],
    products?: IRestProduct[] | string[],
    bsgServiceLockPartnerSettings?: IRestBsgServiceLockPartnerSettings[] | string[],
    businessDays?: IRestBusinessDay[] | string[],
    ACLApiScopePartners?: IRestACLApiScopePartner[] | string[],
    _field?: { [key: string]: string | IEntityFieldValueFile }
}
