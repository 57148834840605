import {AbstractOperationDefinition} from 'src/modules/operations/interfaces';
import {Injector} from '@angular/core';
import {IClientDocumentOutputDto} from '_types/rest/Dto/IRestDocumentClientDocumentOutputDto';
import {FilesService} from 'src/services/files.service';
import {Observable, of} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {IOperationResult} from 'src/modules/operations/interfaces';
import {Utils} from 'src/services/utils';


export class DocumentDownload extends AbstractOperationDefinition<'documents/client', IClientDocumentOutputDto> {
    readonly endpoint = 'documents/client'
    readonly name = 'document_download';
    lang = 'DOWNLOAD';
    icon = 'fa-download';
    invoke = (
        context: IClientDocumentOutputDto,
        injector: Injector
    ): Observable<IOperationResult> => {
        const filesService = injector.get(FilesService);

        return filesService.download(
            {
                '@id': Utils.getIriFromObjectProperty(context, 'parentEntity'),
                file: Utils.getIriFromObjectProperty(context, 'file')
            },
            context.parentFileProp
        )
            .pipe(
                map(() => {
                    return {success: true};
                }),
                catchError(() => of({success: false}))
            );
    };

    access(): boolean {
        return true;
    }
}

export const clientDocumentsOperations = [
    DocumentDownload,
] as const;
