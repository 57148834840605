/**
 * This file has been auto generated. Do not edit.
 */
import {ITask} from '_types/rest/Entity/IRestTask';
import {IRestTask} from '_types/rest/Entity/IRestTask';
import {ITaskUser} from '_types/rest/Entity/IRestTaskUser';
import {IRestTaskUser} from '_types/rest/Entity/IRestTaskUser';
import {ITaskAssignment} from '_types/rest/Entity/IRestTaskAssignment';
import {IRestTaskAssignment} from '_types/rest/Entity/IRestTaskAssignment';
import {IBaseRestEntity} from '_types/rest/IBaseRestEntity';
import {IRestObject} from 'core/rest/rest-object.service';

export const TaskAcceptanceCriterionState = {
    TASK_ACCEPTANCE_CRITERION_NEW: 1,
    TASK_ACCEPTANCE_CRITERION_COMPLETED: 2
} as const;

export const TaskAcceptanceCriterionStateOptions = [
    {
        value: TaskAcceptanceCriterionState.TASK_ACCEPTANCE_CRITERION_NEW,
        label: 'TASK_ACCEPTANCE_CRITERION_NEW',
    },
    {
        value: TaskAcceptanceCriterionState.TASK_ACCEPTANCE_CRITERION_COMPLETED,
        label: 'TASK_ACCEPTANCE_CRITERION_COMPLETED',
    }
] as const;

export interface ITaskAcceptanceCriterionConst {
    state: [{id: 1, value: 'TASK_ACCEPTANCE_CRITERION_NEW'}, {id: 2, value: 'TASK_ACCEPTANCE_CRITERION_COMPLETED'}]
}

export interface ITaskAcceptanceCriterion extends IBaseRestEntity {
    id?: number,
    task: ITask | string,
    taskUser?: ITaskUser | string | null,
    /**
     * @maxLength 512
     */
    title: string,
    /**
     * @default 1
     */
    state: typeof TaskAcceptanceCriterionState[keyof typeof TaskAcceptanceCriterionState],
    /**
     * @default true
     */
    obligatory?: boolean,
    /**
     * @format date-time
     */
    doneDate?: string | null,
    taskAssignment?: ITaskAssignment | string | null,
    /**
     * @description Record status
     *  0 = deleted
     *  1 = active.
     * @default 1
     */
    status?: number,
    /**
     * @description Creation timestamp.
     * @default CURRENT_TIMESTAMP
     * @format date-time
     */
    created?: string,
    /**
     * @description Last modification timestamp.
     * @format date-time
     */
    modified?: string | null
}

/** @deprecated */
export interface IRestTaskAcceptanceCriterion extends ITaskAcceptanceCriterion, IRestObject {
    task: IRestTask | string,
    taskUser?: IRestTaskUser | string | null,
    taskAssignment?: IRestTaskAssignment | string | null,
}
