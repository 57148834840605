import {Directive, Input, Output, TemplateRef} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Directive({
    selector: '[templateContext]'
})
export class TemplateContextDirective<T extends TemplateRef<unknown>> {
    @Input() templateContext: T
    @Output() readonly templateContextChange: BehaviorSubject<T>;

    constructor(
        public template: TemplateRef<T extends TemplateRef<infer P> ? P : never>
    ) {
        this.templateContextChange = new BehaviorSubject<T>(this.template as T);
    }
}
