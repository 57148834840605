<div id="rodo-cookies-bar" *ngIf="visible" @showHideCookies>
    <div class="row align-items-center">
        <div class="col">
            <span class="mr-2">
                <i class="fas fa-cookie-bite"></i>
            </span>
            <span>
                {{ 'RODO_COOKIE_BAR_INFO_TEXT' | translate }}
                <a href="javascript:" class="primary" (click)="openModal()" *ngIf="cmsItemId">
                    {{ 'RODO_COOKIE_BAR_INFO_LINK' | translate }}
                </a>.
            </span>
        </div>
        <div class="col col-auto">
            <button type="button" class="btn btn-primary btn-sm" (click)="accept()">
                {{ 'RODO_COOKIE_BAR_BUTTON' | translate }}
            </button>
        </div>
    </div>
</div>
