import {AbstractOperationDefinition} from 'src/modules/operations/interfaces';
import {IRestCollection, IRestObject} from 'src/modules/rest/objects';
import {IACLOrganicGroup} from '_types/rest';
import {AbstractDeleteOperation} from 'view-modules/operations/abstract/abstract-delete.operation';

export interface IOrganicGroupElement {
    id: number;
    name: string;
    roles: number;
    users: number;
}

export interface OgRoleContext {
    organicGroupElement: IOrganicGroupElement;
    organicGroup: IACLOrganicGroup
    roles: IRestCollection<'a_c_l_o_g_roles'>;
    role: IRestObject<'a_c_l_o_g_roles'>
}

export class OGRoleAddOperation extends AbstractOperationDefinition<string, OgRoleContext> {
    readonly endpoint = 'a_c_l_o_g_roles';
    readonly name = 'post';
    lang = 'ADD';
    icon = 'fa-plus';

    component = (): Promise<unknown> => import('./og-roles-form.component');

    access(): boolean {
        return true;
    }
}

export class OGRoleEditOperation extends AbstractOperationDefinition<string, IRestObject<'a_c_l_o_g_roles'>> {
    readonly endpoint = 'a_c_l_o_g_roles';
    readonly name = 'put';
    lang = 'EDIT';
    icon = 'fa-edit';


    component = (): Promise<unknown> => import('./og-roles-form.component');

    access(): boolean {
        return true;
    }
}

export class OGRoleRemoveOperation extends AbstractDeleteOperation<'a_c_l_o_g_roles'> {
    readonly endpoint = 'a_c_l_o_g_roles';
    lang = 'DELETE';
    confirmMessage = 'OG_ROLE_REMOVE_CONFIRM';
    confirmOptions = {
        langYes: 'OG_ROLE_REMOVE_CONFIRM_LANG_YES'
    };
}

export const ogRolesOperations = [
    OGRoleAddOperation,
    OGRoleEditOperation,
    OGRoleRemoveOperation
] as const;
