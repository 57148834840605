import {NgModule} from '@angular/core';
import {ToastsComponent} from 'src/modules/global-components/toasts/toasts.component';
import {BrowserModule} from '@angular/platform-browser';


@NgModule({
    declarations: [
        ToastsComponent
    ],
    imports: [
        BrowserModule
    ],
    exports: [
        ToastsComponent
    ]
})
export class ToastsModule {
}
