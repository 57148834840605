<div class="btn-group btn-group-toggle">
    <ng-container *ngFor="let group of esOptionsList; index as groupId">
        <ng-container *ngFor="let option of group.options | async; index as optionId">
            <label class="btn btn-outline-primary" [ngClass]="{active: isChecked(option)}">
                <input [type]="inputType" class="custom-control-input" [attr.id]="getId(groupId, optionId)"
                       [checked]="isChecked(option)" [disabled]="isDisabled"
                       (change)="change(option, $event.target['checked'])"/>
                <ng-container *ngIf="esBeforeOption">
                    <ng-container *ngTemplateOutlet="esBeforeOption.template; context: {$implicit: option.item}"></ng-container>
                </ng-container>
                <ng-container *ngTemplateOutlet="group.template; context: {$implicit: option.item}"></ng-container>
            </label>
        </ng-container>
    </ng-container>
</div>
