/* eslint-disable max-len */
/**
 * This file has been auto generated. Do not edit.
 */
import {ISearchConsoleReport} from '_types/rest/Entity/IRestSearchConsoleReport';
import {IRestSearchConsoleReport} from '_types/rest/Entity/IRestSearchConsoleReport';
import {IBaseRestEntity} from '_types/rest/IBaseRestEntity';
import {IRestObject} from 'core/rest/rest-object.service';

export const SearchConsoleScheduleState = {
    SEARCH_CONSOLE_SCHEDULE_STATE_TODO: 0,
    SEARCH_CONSOLE_SCHEDULE_STATE_IN_PROGRESS: 1,
    SEARCH_CONSOLE_SCHEDULE_STATE_FINISHED: 2,
    SEARCH_CONSOLE_SCHEDULE_STATE_ERROR: -1,
    SEARCH_CONSOLE_SCHEDULE_STATE_INVALID_GRANT: -2,
    SEARCH_CONSOLE_SCHEDULE_STATE_NO_GRANT: -3,
    SEARCH_CONSOLE_SCHEDULE_STATE_NO_DOMAIN: -4
} as const;

export const SearchConsoleScheduleStateOptions = [
    {
        value: SearchConsoleScheduleState.SEARCH_CONSOLE_SCHEDULE_STATE_TODO,
        label: 'SEARCH_CONSOLE_SCHEDULE_STATE_TODO',
    },
    {
        value: SearchConsoleScheduleState.SEARCH_CONSOLE_SCHEDULE_STATE_IN_PROGRESS,
        label: 'SEARCH_CONSOLE_SCHEDULE_STATE_IN_PROGRESS',
    },
    {
        value: SearchConsoleScheduleState.SEARCH_CONSOLE_SCHEDULE_STATE_FINISHED,
        label: 'SEARCH_CONSOLE_SCHEDULE_STATE_FINISHED',
    },
    {
        value: SearchConsoleScheduleState.SEARCH_CONSOLE_SCHEDULE_STATE_ERROR,
        label: 'SEARCH_CONSOLE_SCHEDULE_STATE_ERROR',
    },
    {
        value: SearchConsoleScheduleState.SEARCH_CONSOLE_SCHEDULE_STATE_INVALID_GRANT,
        label: 'SEARCH_CONSOLE_SCHEDULE_STATE_INVALID_GRANT',
    },
    {
        value: SearchConsoleScheduleState.SEARCH_CONSOLE_SCHEDULE_STATE_NO_GRANT,
        label: 'SEARCH_CONSOLE_SCHEDULE_STATE_NO_GRANT',
    },
    {
        value: SearchConsoleScheduleState.SEARCH_CONSOLE_SCHEDULE_STATE_NO_DOMAIN,
        label: 'SEARCH_CONSOLE_SCHEDULE_STATE_NO_DOMAIN',
    }
] as const;

export interface ISearchConsoleScheduleConst {
    state: [{id: 0, value: 'SEARCH_CONSOLE_SCHEDULE_STATE_TODO'}, {id: 1, value: 'SEARCH_CONSOLE_SCHEDULE_STATE_IN_PROGRESS'}, {id: 2, value: 'SEARCH_CONSOLE_SCHEDULE_STATE_FINISHED'}, {id: -1, value: 'SEARCH_CONSOLE_SCHEDULE_STATE_ERROR'}, {id: -2, value: 'SEARCH_CONSOLE_SCHEDULE_STATE_INVALID_GRANT'}, {id: -3, value: 'SEARCH_CONSOLE_SCHEDULE_STATE_NO_GRANT'}, {id: -4, value: 'SEARCH_CONSOLE_SCHEDULE_STATE_NO_DOMAIN'}]
}

export interface ISearchConsoleSchedule extends IBaseRestEntity {
    id?: number,
    /**
     * @format date-time
     */
    date?: string,
    searchConsoleReport?: ISearchConsoleReport | string | null,
    state?: typeof SearchConsoleScheduleState[keyof typeof SearchConsoleScheduleState],
    /**
     * @description Record status
     *  0 = deleted
     *  1 = active.
     * @default 1
     */
    status?: number,
    /**
     * @description Creation timestamp.
     * @default CURRENT_TIMESTAMP
     * @format date-time
     */
    created?: string,
    /**
     * @description Last modification timestamp.
     * @format date-time
     */
    modified?: string | null
}

/** @deprecated */
export interface IRestSearchConsoleSchedule extends ISearchConsoleSchedule, IRestObject {
    searchConsoleReport?: IRestSearchConsoleReport | string | null,
}
