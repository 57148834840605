import {Component, ElementRef, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {EditorView} from 'prosemirror-view';
import {Observable, Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';


@Component({
    selector: 'selection-tooltip',
    templateUrl: './selection-tooltip.component.html',
    styleUrls: ['./selection-tooltip.component.scss']
})
export class SelectionTooltipComponent implements OnInit, OnDestroy {
    @Input() view$: Observable<EditorView>;
    @Input() tooltipWidth = 400;
    @Input() margin = 10;
    @Input() position: 'mid' | 'start' = 'mid';
    @Input() from: number;
    @Input() to: number;

    @ViewChild('tooltipContainer', {static: true}) tooltipContainer: ElementRef;

    left: number;
    top: number;
    view: EditorView;

    private lastValue: Record<string, unknown> = {};
    private _destroy$ = new Subject<void>();

    ngOnInit(): void {
        this.view$
            .pipe(takeUntil(this._destroy$))
            .subscribe((view) => {
                this.view = view;

                this.repositionTooltip();
            });
    }

    private repositionTooltip(): void {
        const from = this.from || this.view.state.selection.from,
            to = this.to || this.view.state.selection.to;
        if (
            this.lastValue.from === from
            && this.lastValue.to === to
        ) {
            return;
        }

        try {
            const editorBox = this.tooltipContainer.nativeElement.getBoundingClientRect(),
                efficientEditorWidth = editorBox.width - (this.tooltipWidth / 2),
                start = this.view.coordsAtPos(from),
                end = this.view.coordsAtPos(to);

            switch (this.position) {
                case 'mid': {
                    // Calculate point between start and end of selection
                    // as css left value relative to tooltip container.
                    const midPoint = ((start.left + end.left) / 2 - (this.tooltipWidth / 2)) - editorBox.left;
                    this.left =  this.assurePointInEditorRange(midPoint, efficientEditorWidth, editorBox);
                    break;
                }
                case 'start': {
                    const leftPoint = start.left - editorBox.left;
                    this.left = this.assurePointInEditorRange(leftPoint, efficientEditorWidth, editorBox);
                    break;
                }
            }
            this.top = start.bottom - editorBox.top;

            this.lastValue.from = from;
            this.lastValue.to = to;
        } catch (e) {
            // Do nothing, since given position can already be outside view available positions.
        }
    }

    private assurePointInEditorRange(
        point: number,
        efficientEditorWidth: number,
        editorBox: {width: number}
    ): number {
        const tooltipRightBorder = point + (this.tooltipWidth / 2);
        if (tooltipRightBorder > efficientEditorWidth) {
            point = editorBox.width - this.tooltipWidth - this.margin;
        } else if (point < 0) {
            point = this.margin;
        }

        return point;
    }

    updateTooltipWidth(width: number): void {
        this.tooltipWidth = width;
        this.repositionTooltip();
    }

    ngOnDestroy(): void {
        this._destroy$.next();
        this._destroy$.complete();
    }
}
