<ng-template #defaultTemplate let-value>
    <ng-container *ngIf="!value">
        -
    </ng-container>
    <ng-container *ngIf="value">
        {{control.value}}
    </ng-container>
</ng-template>

<div (click)="edit()"
     (mouseenter)="handleMouseEnter()"
     (mouseleave)="handleMouseLeave()"
     class="editable-container h-100">

    <div [hidden]="editing"
         class="editable-field"
         [ngClass]="[config.type, control.disabled ? 'editable-field--disabled': '']">
        <ng-container *ngTemplateOutlet="template || defaultTemplate; context: {$implicit: control.value, objectValue}">
        </ng-container>
        <a href="javascript:"
           class="text-danger deselect"
           *ngIf="isDeselectActive && !disableDeselect && config.type !== 'color'"
           (click)="handleDeselect($event)">
            <i class="fa fa-times"></i>
        </a>
    </div>

    <div [hidden]="!editing">
        <dynamic-field *ngIf="initializeDynamicField"
                       [formControl]="control"
                       [fieldConfig]="config"
                       [objectValue]="objectValue"
                       [focus]="focus"
                       (initializing)="initializing.emit($event)"
                       (click)="$event.stopPropagation()"
                       (objectValueChange)="handleObjectValueChange($event)">

            <ng-container *ngIf="config.type === 'select' && optionTemplate">

                <ng-container *ngIf="config.options">
                    <ng-container *esOptions="let o of config.options; value: 'value'">
                        <ng-container *ngTemplateOutlet="optionTemplate;context: {$implicit: o}"></ng-container>
                    </ng-container>
                </ng-container>
            </ng-container>
            <ng-container *ngFor="let esOptions of esOptionsList">
                <ng-container
                        *esOptions="let o of esOptions.esOptionsOf; value: esOptions.esOptionsValue; group: esOptions.esOptionsGroup">
                    <ng-container
                            *ngTemplateOutlet="esOptions.template; context: {$implicit: o}"></ng-container>
                </ng-container>
            </ng-container>
        </dynamic-field>
    </div>
</div>
