import {Observable, of, Subject} from 'rxjs';
import {ToastsService} from 'src/modules/global-components/toasts/toasts.service';
import {Injector} from '@angular/core';
import {AbstractOperationDefinition, IOperationContext} from 'src/modules/operations/interfaces';
import {IRestObject} from 'src/modules/rest/objects';
import {IOperationResult} from 'src/modules/operations/interfaces';
import {RestEndpoint} from 'src/modules/rest/rest-endpoint';
import {catchError} from 'rxjs/operators';


export class DevInvokeOperation extends AbstractOperationDefinition<'dev', unknown> {
    readonly endpoint = 'dev'
    readonly name = 'dev_operation_invoke';
    lang = 'DEV_OPERATION_LANG';
    icon = 'fa-caret-square-left';

    invoke(
        context: unknown,
        injector: Injector
    ): Observable<IOperationResult> {
        const obs = new Subject<IOperationResult>();
        if (context['test']) {
            context['test'] = 'test';
        }
        setTimeout(() => {
            const toastService = injector.get(ToastsService);
            toastService.pop(`Context: ${JSON.stringify(context)}`, 'Dev operation invoked');
            obs.next({success: false});
            obs.complete();
        }, 1000);

        return obs.asObservable();
    }

    access(context: unknown): boolean {
        return typeof context !== 'object' || context === null || context['test'] !== 'test';
    }
}

export class DevComponentOperation extends AbstractOperationDefinition<'dev'> {
    readonly endpoint = 'dev';
    readonly name = 'dev_operation_component';
    lang = 'DEV_COMPONENT_LANG';
    icon = 'fa-caret-square-right';
    component = (): Promise<unknown> => import('view-modules/operations/dev/dev-operation.component');

    access(): boolean {
        return true;
    }
}

export class FirstDevComponentOperation extends AbstractOperationDefinition<'dev'> {
    readonly endpoint = 'dev';
    readonly name = 'dev_operation_component_1';
    lang = 'DEV_COMPONENT_LANG_1';
    icon = 'fa-caret-square-right';
    component = (): Promise<unknown> => import('view-modules/operations/dev/dev-operation.component');

    access(): boolean {
        return true;
    }
}

export class SecondDevComponentOperation extends AbstractOperationDefinition<'dev'> {
    readonly endpoint = 'dev';
    readonly name = 'dev_operation_component_2';
    lang = 'DEV_COMPONENT_LANG_2';
    icon = 'fa-caret-square-right';
    component = (): Promise<unknown> => import('view-modules/operations/dev/dev-operation.component');

    access(): boolean {
        return true;
    }
}

export class ThirdDevComponentOperation extends AbstractOperationDefinition<'dev'> {
    readonly endpoint = 'dev';
    readonly name = 'dev_operation_component_3';
    lang = 'DEV_COMPONENT_LANG_3';
    icon = 'fa-caret-square-right';
    component = (): Promise<unknown> => import('view-modules/operations/dev/dev-operation.component');

    access(): boolean {
        return true;
    }
}

export class FourthDevComponentOperation extends AbstractOperationDefinition<'dev'> {
    readonly endpoint = 'dev';
    readonly name = 'dev_operation_component_4';
    lang = 'DEV_COMPONENT_LANG_4';
    icon = 'fa-caret-square-right';
    component = (): Promise<unknown> => import('view-modules/operations/dev/dev-operation.component');

    access(): boolean {
        return true;
    }
}

export class FifthDevComponentOperation extends AbstractOperationDefinition<'dev'> {
    readonly endpoint = 'dev';
    readonly name = 'dev_operation_component_5';
    lang = 'DEV_COMPONENT_LANG_5';
    icon = 'fa-caret-square-right';
    component = (): Promise<unknown> => import('view-modules/operations/dev/dev-operation.component');

    access(): boolean {
        return true;
    }
}

export class SixthDevComponentOperation extends AbstractOperationDefinition<'dev', never, never> {
    readonly endpoint = 'dev';
    readonly name = 'dev_operation_component_6';
    lang = 'DEV_COMPONENT_LANG_6';
    icon = 'fa-caret-square-right';
    component = (): Promise<unknown> => import('view-modules/operations/dev/dev-operation.component');

    access(): boolean {
        return true;
    }
}

export class EndpointOperation extends AbstractOperationDefinition<'companies'> {
    readonly endpoint = 'companies';
    readonly name = 'endpoint_dev_operation';
    lang = 'DEV_ENDPOINT_LANG';
    icon = 'fa-caret-square-right';

    invoke(
        // On real endpoint context is typed with IRestObject by default (typing works only if type is declared).
        context: IRestObject<'companies'>,
        injector: Injector
    ): Observable<IOperationResult> {
        const obs = new Subject<IOperationResult>();

        setTimeout(() => {
            const toastService = injector.get(ToastsService);
            toastService.pop(`Context: ${JSON.stringify(context)}`, 'Dev operation invoked');
            obs.next({success: true});
            obs.complete();
        }, 1000);

        return obs.asObservable();
    }

    access(): boolean {
        return true;
    }
}

export class MassOperation extends AbstractOperationDefinition<'dev', { test: string }> {
    readonly endpoint = 'dev';
    readonly name = 'dev_mass_operation';
    lang = 'INVOKE_DEV_MASS_OPERATION';
    icon = 'fa-caret-square-left';

    invoke(
        context: { test: string },
    ): Observable<IOperationResult> {
        const subject = new Subject<IOperationResult>();
        setTimeout(() => {
            context.test = 'mass test property change';
            subject.next({success: true});
            subject.complete();
        }, 3000);
        return subject.asObservable();
    }

    access(): boolean {
        return true;
    }
}

export interface IWithResolverDevOperationContext {
    partnerId: number;
}

export interface IWithResolverDevOperationAdditionalData {
    cmsType: string;
}

interface IWithResolverDevOperationResolvedTObject {
    id: number;
    source: string;
}

export type IWithResolverDevOperationResolvedContext = IRestObject<'dev', IWithResolverDevOperationResolvedTObject>;

export class WithResolverDevOperation extends AbstractOperationDefinition<
    'dev',
    IWithResolverDevOperationContext,
    IWithResolverDevOperationResolvedContext
> {
    readonly endpoint = 'dev';
    readonly name = 'with_resolver';
    lang = 'WITH_RESOLVER_OPERATION';
    icon = 'fa-caret-square-left';

    invoke(
        context: IWithResolverDevOperationResolvedContext,
        injector: Injector
    ): Observable<IOperationResult> {
        const subject = new Subject<IOperationResult>();
        setTimeout(() => {
            const toastService = injector.get(ToastsService);
            toastService.pop(`Context: ${JSON.stringify(context)}`, 'Operation context with resolver');
            subject.next({success: true});
            subject.complete();
        }, 1000);
        return subject.asObservable();
    }

    access(): boolean {
        return true;
    }

    contextResolver(
        context: IOperationContext<IWithResolverDevOperationContext, IWithResolverDevOperationAdditionalData>
    ): Observable<IWithResolverDevOperationResolvedContext> {
        const resolvedContext = (new RestEndpoint<'dev', IWithResolverDevOperationResolvedTObject>(this.endpoint))
            .createObject({
                id: context.baseContext.partnerId,
                source: 'custom' + context.additionalData.cmsType
            });

        return of(resolvedContext);
    }
}

export interface IWithStaticResolverDevOperationContext {
    id: number;
}

export type IWithStaticResolverDevOperationResolvedContext = IRestObject<'dev', {customProp: string}>;

export class WithStaticResolverDevOperation extends AbstractOperationDefinition<
    'dev',
    IWithStaticResolverDevOperationContext,
    IWithStaticResolverDevOperationResolvedContext
> {
    readonly endpoint = 'dev';
    readonly name = 'with_static_resolver';
    lang = 'WITH_STATIC_RESOLVER_OPERATION';
    icon = 'fa-caret-square-left';

    invoke(
        context: IWithStaticResolverDevOperationResolvedContext,
        injector: Injector
    ): Observable<IOperationResult> {
        const subject = new Subject<IOperationResult>();
        setTimeout(() => {
            const toastService = injector.get(ToastsService);
            toastService.pop(`Context: ${JSON.stringify(context)}`, 'Operation context with STATIC resolver');
            subject.next({success: true});
            subject.complete();
        }, 1000);
        return subject.asObservable();
    }

    access(): boolean {
        return true;
    }

    contextResolver(
        context: IOperationContext<IWithStaticResolverDevOperationContext>,
        injector: Injector
    ): Observable<IWithStaticResolverDevOperationResolvedContext> {
        return AbstractOperationDefinition.contextResolver<
            IWithStaticResolverDevOperationContext,
            IWithStaticResolverDevOperationResolvedContext
        >(this.endpoint, context, injector)
            // We do api call on not existing dev endpoint, that's why catchError() is necessary
            // to mock dev endpoint response, normally we use static resolver without pipe() and we have
            // response in type IResObject<'endpoint', IType>
            .pipe(
                catchError(() => {
                    return of(
                        (new RestEndpoint<'dev', {customProp: string}>(this.endpoint)).createObject({
                            customProp: '1 2 3'
                        })
                    );
                })
            );
    }
}

export const devOperations = [
    DevInvokeOperation,
    DevComponentOperation,
    EndpointOperation,
    MassOperation,
    FirstDevComponentOperation,
    SecondDevComponentOperation,
    ThirdDevComponentOperation,
    FourthDevComponentOperation,
    FifthDevComponentOperation,
    SixthDevComponentOperation,
    WithResolverDevOperation,
    WithStaticResolverDevOperation
] as const;
