/* eslint-disable max-len */
/**
 * This file has been auto generated. Do not edit.
 */
import {IUserLogin} from '_types/rest/Entity/IRestUserLogin';
import {IRestUserLogin} from '_types/rest/Entity/IRestUserLogin';
import {ILocale} from '_types/rest/Entity/IRestLocale';
import {IRestLocale} from '_types/rest/Entity/IRestLocale';
import {ICms} from '_types/rest/Entity/IRestCms';
import {IRestCms} from '_types/rest/Entity/IRestCms';
import {IBaseRestEntity} from '_types/rest/IBaseRestEntity';
import {IRestObject} from 'core/rest/rest-object.service';

export const GdprType = {
    GDPR_TYPE_CUSTOM: 0,
    GDPR_TYPE_TERMS: 1,
    GDPR_TYPE_PRIVACY_POLICY: 2
} as const;

export const GdprTypeOptions = [
    {
        value: GdprType.GDPR_TYPE_CUSTOM,
        label: 'GDPR_TYPE_CUSTOM',
    },
    {
        value: GdprType.GDPR_TYPE_TERMS,
        label: 'GDPR_TYPE_TERMS',
    },
    {
        value: GdprType.GDPR_TYPE_PRIVACY_POLICY,
        label: 'GDPR_TYPE_PRIVACY_POLICY',
    }
] as const;

export const GdprState = {
    GDPR_STATE_ACTIVE: 1,
    GDPR_STATE_DEACTIVE: 2
} as const;

export const GdprStateOptions = [
    {
        value: GdprState.GDPR_STATE_ACTIVE,
        label: 'GDPR_STATE_ACTIVE',
    },
    {
        value: GdprState.GDPR_STATE_DEACTIVE,
        label: 'GDPR_STATE_DEACTIVE',
    }
] as const;

export interface IGdprConst {
    type: [{id: 0, value: 'GDPR_TYPE_CUSTOM'}, {id: 1, value: 'GDPR_TYPE_TERMS'}, {id: 2, value: 'GDPR_TYPE_PRIVACY_POLICY'}],
    state: [{id: 1, value: 'GDPR_STATE_ACTIVE'}, {id: 2, value: 'GDPR_STATE_DEACTIVE'}]
}

export interface IGdpr extends IBaseRestEntity {
    id?: number,
    type: typeof GdprType[keyof typeof GdprType],
    required?: boolean,
    /**
     * @default 1
     */
    state: typeof GdprState[keyof typeof GdprState],
    name?: string | null,
    /**
     * @maxLength 15
     * @format ipv6
     */
    ip?: string | null,
    /**
     * @maxLength 32
     */
    fingerprint?: string | null,
    /**
     * @maxLength 400
     */
    browser?: string | null,
    textContent?: string | null,
    consentContent?: string | null,
    /**
     * @format date-time
     */
    dateStart?: string,
    /**
     * @format date-time
     */
    dateEnd?: string | null,
    userLogin?: IUserLogin | string,
    locale: ILocale | string | null,
    cms?: ICms | string | null,
    /**
     * @description Record status
     *  0 = deleted
     *  1 = active.
     * @default 1
     */
    status?: number,
    /**
     * @description Creation timestamp.
     * @default CURRENT_TIMESTAMP
     * @format date-time
     */
    created?: string,
    /**
     * @description Last modification timestamp.
     * @format date-time
     */
    modified?: string | null
}

/** @deprecated */
export interface IRestGdpr extends IGdpr, IRestObject {
    userLogin?: IRestUserLogin | string,
    locale: IRestLocale | string | null,
    cms?: IRestCms | string | null,
}
