import {NgModule} from '@angular/core';
import {DropZoneComponent} from 'src/modules/file-upload/drop-zone/drop-zone.component';
import {FileListComponent} from 'src/modules/file-upload/file-list/file-list.component';
import {FileUploadProviderDirective} from 'src/modules/file-upload/file-upload-provider/file-upload-provider.directive';
import {FileUploadComponent} from 'src/modules/file-upload/file-upload/file-upload.component';
import {FileThumbnailsComponent} from 'src/modules/file-upload/file-thumbnails/file-thumbnails.component';
import {FilePreviewModule} from 'src/modules/global-components/file-preview-modal/file-preview.module';
import {ImgUploadComponent} from 'src/modules/file-upload/img-upload/img-upload.component';
import {ImgUploadCanvasDirective} from 'src/modules/file-upload/img-upload/img-upload-canvas.directive';
import {TranslateModule} from 'src/modules/translate/translate.module';
import {AppCommonModule} from 'src/modules/app-common/app-common.module';
import {AppTableToolsModule} from 'src/modules/app-table-tools/app-table-tools.module';
import {FileIconComponent} from 'src/modules/file-upload/file-icon/file-icon.component';


@NgModule({
    imports: [
        AppCommonModule,
        AppTableToolsModule,
        FilePreviewModule,
        TranslateModule
    ],
    declarations: [
        DropZoneComponent,
        FileIconComponent,
        FileListComponent,
        FileThumbnailsComponent,
        FileUploadComponent,
        FileUploadProviderDirective,
        ImgUploadCanvasDirective,
        ImgUploadComponent
    ],
    exports: [
        DropZoneComponent,
        FileIconComponent,
        FileListComponent,
        FileThumbnailsComponent,
        FileUploadComponent,
        FileUploadProviderDirective,
        ImgUploadCanvasDirective,
        ImgUploadComponent
    ]
})
export class FileUploadModule {
}
