import {AbstractDeleteOperation} from 'view-modules/operations/abstract/abstract-delete.operation';
import {IConfirmModalOptions} from 'src/modules/global-components/confirm-modal/confirm-modal.service';

export class MailsDeleteOperation extends AbstractDeleteOperation<'mails'> {
    readonly endpoint = 'mails';
    lang = 'DELETE';
    confirmMessage = 'DELETE_MAIL_CONFIRM';
    confirmOptions: IConfirmModalOptions = {
        primaryBtn: 'danger'
    };
}

export const mailsOperations = [
    MailsDeleteOperation
] as const;

