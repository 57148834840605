<div class="modal fade" [(bsModal)]="cmsModalService.modal">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header" *ngIf="!cmsModalService.loading">
                <h5>{{cmsModalService.cms.name }}</h5>
                <button [attr.aria-label]="'CLOSE'| translate" class="close" dismiss="modal" type="button">
                    <span aria-hidden="true">×</span>
                </button>
            </div>
            <div class="modal-body text-center" *ngIf="cmsModalService.loading">
                <i class="fas fa-circle-notch fa-spin"></i>
            </div>
            <div class="modal-body" [innerHTML]="cmsModalService.cms.content | unsafe" *ngIf="!cmsModalService.loading">
            </div>
        </div>
    </div>
</div>
