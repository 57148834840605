import {AbstractOperationDefinition} from 'src/modules/operations/interfaces';
import {AbstractDeleteOperation} from 'view-modules/operations/abstract/abstract-delete.operation';
import {IRestCollection, IRestObject} from 'src/modules/rest/objects';
import {RestClient} from 'src/modules/rest/rest-client.service';
import {IDepartment} from '_types/rest';

export interface IDepartmentAddOperationContext {
    partnerIri: string,
    departments: IRestCollection<'departments'>,
    parentDepartment?: string
}

export interface IDepartmentEditOperationContext {
    departments: IRestCollection<'departments'>,
    department: IRestObject<'departments'>,
}

export interface IUserLoginDepartmentAddContext {
    department: IDepartment;
    access: boolean
}

export class DepartmentAddOperation extends AbstractOperationDefinition<string, IDepartmentAddOperationContext> {
    readonly endpoint = 'departments';
    readonly name = 'post';
    lang = 'PARTNERS_DEPARTMENT_ADD';
    icon = 'fa-plus';
    component = (): Promise<unknown> => import('./department-form/department-form.component');

    access(context: IDepartmentAddOperationContext): boolean {
        if (RestClient.isRestCollection(context.departments)) {
            return context.departments.access(this.name);
        }

        return false;
    }
}

export class DepartmentEditOperation extends AbstractOperationDefinition<string, IDepartmentEditOperationContext> {
    readonly endpoint = 'departments';
    readonly name = 'put';
    lang = 'EDIT';
    icon = 'fa-edit';
    component = (): Promise<unknown> => import('./department-form/department-form.component');

    access(context: IDepartmentEditOperationContext): boolean {
        if (RestClient.isRestObject(context.department)) {
            return context.department.access(this.name);
        }

        return false;
    }
}

export class DepartmentDeleteOperation extends AbstractDeleteOperation<'departments'> {
    readonly endpoint = 'departments';
    lang = 'DELETE';
    confirmMessage = 'PARTNERS_DEPARTMENTS_REMOVE_DEPARTMENT_CONFIRM';
    confirmOptions = {
        langYes: 'PARTNERS_DEPARTMENTS_REMOVE_DEPARTMENT_CONFIRM_LANG_YES',
    };
}
export class UserLoginDepartmentAddOperation
    extends AbstractOperationDefinition<string, IUserLoginDepartmentAddContext> {
    readonly endpoint = 'user_login_departments';
    readonly name = 'post';
    lang = 'ADD_USER_LOGIN_DEPARTMENT';
    icon = 'fa-plus';
    component = (): Promise<unknown> =>
        import('view-modules/operations/departments/user-login-department-form/user-login-department-form.component');

    access(context: IUserLoginDepartmentAddContext): boolean {
        return context.access;
    }
}

export class UserLoginDepartmentEditOperation extends AbstractOperationDefinition<'user_login_departments'> {
    readonly endpoint = 'user_login_departments';
    readonly name = 'put';
    lang = 'EDIT_USER_LOGIN_DEPARTMENT';
    icon = 'fa-edit';
    component = (): Promise<unknown> =>
        import('view-modules/operations/departments/user-login-department-form/user-login-department-form.component');
}

export class UserLoginDepartmentDeleteOperation extends AbstractDeleteOperation<'user_login_departments'> {
    readonly endpoint = 'user_login_departments';
    lang = 'DELETE';
    confirmMessage = 'PARTNERS_DEPARTMENTS_REMOVE_USER_LOGIN_DEPARTMENT_ROLE_CONFIRM';
    confirmOptions = {
        langYes: 'PARTNERS_DEPARTMENTS_REMOVE_USER_LOGIN_DEPARTMENT_ROLE_CONFIRM_LANG_YES',
    };
}

export const departmentsOperations = [
    DepartmentAddOperation,
    DepartmentEditOperation,
    DepartmentDeleteOperation,
    UserLoginDepartmentAddOperation,
    UserLoginDepartmentEditOperation,
    UserLoginDepartmentDeleteOperation
] as const;
