import {NgModule} from '@angular/core';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {RestHttpInterceptor} from 'src/modules/rest/rest-http-interceptor.service';
import {UserPrivilegesPipe} from 'src/modules/rest/user/user-privileges.pipe';
import {TransformToRestPipe} from 'src/modules/rest/transform-to-rest.pipe';

const hasDevModule = typeof window.DevHttpResponseFakerModule !== 'undefined',
    imports: NgModule['imports'] = [
        HttpClientModule
    ];

if (hasDevModule) {
    imports.push(window.DevHttpResponseFakerModule);
}

@NgModule({
    declarations: [
        TransformToRestPipe,
        UserPrivilegesPipe
    ],
    imports,
    exports: [
        TransformToRestPipe,
        UserPrivilegesPipe
    ],
    providers: hasDevModule ? [] : [ // provide RestHttpInterceptor only when it isn't already provided by dev module
        {
            provide: HTTP_INTERCEPTORS,
            useClass: RestHttpInterceptor,
            multi: true
        }
    ]
})
export class RestModule {
}
