import {Component, OnInit} from '@angular/core';
import {ConfigService} from 'src/services/config.service';
import {CmsModalService} from 'src/modules/global-components/cms-modal/cms-modal.service';
import {LocalStorage} from 'src/services/local-storage';
import {RodoCookiesAnimations} from 'src/components/rodo-cookies/rodo-cookies-animations';

@Component({
    selector: 'rodo-cookies',
    animations: RodoCookiesAnimations,
    templateUrl: './rodo-cookies.component.html',
    styleUrls: ['./rodo-cookies.component.scss']
})
export class RodoCookiesComponent implements OnInit {
    private readonly lsProp = 'rodoCookiesConsent';
    private modal: Boolean;
    visible = false;
    cmsItemId: number;

    constructor(
        private configService: ConfigService,
        private cmsModal: CmsModalService
    ) {
    }

    ngOnInit(): void {
        if (!LocalStorage.get(this.lsProp)) {
            this.visible = true;

            const cmsItem = this.configService.get('cms').find((item) => {
                return item.cmsType === 'CMS_COOKIE_POLICY';
            });

            if (cmsItem) {
                this.cmsItemId = cmsItem.id;
            }
        }
    }

    openModal(): void {
        if (!this.modal) {
            this.cmsModal.openCmsModal(this.cmsItemId);
        }
    }

    accept(): void {
        LocalStorage.set(this.lsProp, true);
        this.visible = false;
    }
}
