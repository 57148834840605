import {EndpointName, EntityFieldDisplay} from '_types/rest';
import {ControlValueAccessor} from '@angular/forms';
import {DatePickerComponent} from 'angular-bootstrap4-datepicker';
import {EventEmitter, QueryList} from '@angular/core';
import {EsBeforeOptionDirective, EsOptionsDirective} from 'angular-bootstrap4-extended-select';
import {IFileViewMode} from 'src/modules/file-upload/file-upload/file-upload.component';
import {IAvailableToolbarNames} from 'src/modules/wysiwyg-editor/configs/toolbar-items-sections';
import {IExcludePluginNames} from 'src/modules/wysiwyg-editor/wysiwyg-editor.component';
import {IQueryObject} from 'src/services/url-parser.service';
import {Subject} from 'rxjs';
import {IRestCollection} from 'src/modules/rest/objects';
import {
    IPasswordInputValidators,
    IPasswordInputValidatorsTypes
} from 'src/modules/dynamic-fields/controls/password-input/password-input.component';
import DateExtended from 'date-extensions';

// endregion types & controls
export const DynamicFieldTypes = [
    'string',
    'email',
    'password',
    'text',
    'file',
    'color',
    'number',
    'numberShuttle',
    'checkbox',
    'durationPicker',
    'date',
    'select',
    'relation',
    'category',
    'icon',
    'copyInput'
] as const;

export type DynamicFieldType = typeof DynamicFieldTypes[number];

/**
 * Interface implemented by dynamic-field controls
 */
export interface IDynamicFieldControlValueAccessor extends ControlValueAccessor {
    fieldConfig?: IDynamicFieldConfig,
    id?: string,
    initializing?: Subject<boolean>,
    objectValue?: unknown,
    objectValueChange?: EventEmitter<unknown>,
    esOptionsList?: QueryList<EsOptionsDirective<unknown>>,
    esBeforeOption?: EsBeforeOptionDirective<unknown>
}

/**
 * Interface implemented by select controls
 */
export interface ICustomSelectControlValueAccessor extends ControlValueAccessor {
    esOptionsList?: QueryList<EsOptionsDirective<unknown>>,
    esBeforeOption?: EsBeforeOptionDirective<unknown>,
    viewType?: SelectDynamicFieldViewType
}

// endregion types & controls

// region validators
interface ICommonValidators {
    required?: boolean
}

interface IEmailValidators {
    email?: boolean
}

interface INumberValidators {
    number?: number,
    min?: number,
    max?: number,
    step?: number
}

interface IStringAndTextValidators {
    pattern?: RegExp,
    validateUrl?: boolean,
    validateHost?: boolean,
    validateJson?: boolean,
}

interface IStringValidators extends IStringAndTextValidators {
    minlength?: number,
    maxlength?: number
}

export type ValidatorMessages<T> = {
    [P in keyof T]?: string;
};

export type DynamicFieldValidators = ICommonValidators & IEmailValidators & INumberValidators
    & IStringValidators & IStringAndTextValidators;

// endregion validators

// region fieldConfig

interface IBaseDynamicFieldConfig {
    type: DynamicFieldType,
    /**
     * language key, field label
     */
    name?: string,
    /**
     * help text
     */
    help?: string,
    /**
     * default field value
     * @deprecated
     */
    default?: unknown,
    placeholder?: string,
    nameAsPlaceholder?: boolean,
    display?: typeof EntityFieldDisplay[keyof typeof EntityFieldDisplay],
    validators?: ICommonValidators,
    /**
     * custom error messages for validators
     */
    validatorMessages?: ValidatorMessages<ICommonValidators>,
    parseValueToType?: 'string' | 'number'
}

// string
export interface IStringDynamicFieldConfig extends IBaseDynamicFieldConfig {
    type: 'string',
    validators?: ICommonValidators & IStringValidators,
    validatorMessages?: ValidatorMessages<ICommonValidators & IStringValidators>
}

// email
export interface IEmailDynamicFieldConfig extends IBaseDynamicFieldConfig {
    type: 'email',
    validators?: ICommonValidators & IEmailValidators,
    validatorMessages?: ValidatorMessages<ICommonValidators & IEmailValidators>
}

// password
export interface IPasswordDynamicFieldConfig extends IBaseDynamicFieldConfig {
    type: 'password',
    passwordValidators?: Record<IPasswordInputValidatorsTypes, Partial<IPasswordInputValidators> | false> | false,
    /**
     * @default false
     */
    showPassword?: boolean

    /**
     * @default false
     */
    generate?: boolean,
    generateOptions?: {

        /**
         * @default 12
         */
        minLength: number

        /**
         * @default 16
         */
        maxLength: number
    },

    /**
     * @default null
     */
    autocomplete?: 'off' | 'on' | string | null
}

// number
export interface INumberDynamicFieldConfig extends IBaseDynamicFieldConfig {
    type: 'number',
    validators?: ICommonValidators & INumberValidators,
    validatorMessages?: ValidatorMessages<ICommonValidators & INumberValidators>
}

// numberShuttle
export interface INumberShuttleDynamicFieldConfig extends IBaseDynamicFieldConfig {
    type: 'numberShuttle',
    validators?: ICommonValidators & INumberValidators,
    validatorMessages?: ValidatorMessages<ICommonValidators & INumberValidators>,
    numberLabel?: string
}

// durationPicker
export interface IDurationPickerDynamicFieldConfig extends IBaseDynamicFieldConfig {
    type: 'durationPicker',
    validators?: ICommonValidators & INumberValidators,
    validatorMessages?: ValidatorMessages<ICommonValidators & INumberValidators>,
}

// color
export interface IColorDynamicFieldConfig extends IBaseDynamicFieldConfig {
    type: 'color',
    validators?: ICommonValidators,
    validatorMessages?: ValidatorMessages<ICommonValidators>
}

// checkbox
export const checkboxDynamicFieldViewTypes = ['checkbox', 'checkBar', 'switch'] as const;

export type CheckboxDynamicFieldViewType = typeof checkboxDynamicFieldViewTypes[number];

export interface ICheckboxDynamicFieldConfig extends IBaseDynamicFieldConfig {
    type: 'checkbox',
    viewType?: CheckboxDynamicFieldViewType,
    trueValue?: unknown,
    falseValue?: unknown,
    isIndeterminate?: boolean
}

// text
export interface ITextDynamicFieldConfig extends IBaseDynamicFieldConfig {
    type: 'text',

    /**
     * @default false
     */
    isWYSIWYG?: boolean

    configWYSIWYG?: {

        /**
         * @default []
         */
        toolbarItems?: IAvailableToolbarNames[];

        /**
         * @default []
         */
        excludeToolbarItems?: IAvailableToolbarNames[]

        /**
         * @default []
         */
        excludePlugins?: IExcludePluginNames[];
    }

    validators?: ICommonValidators & IStringAndTextValidators & IStringValidators,
    validatorMessages?: ValidatorMessages<ICommonValidators & IStringAndTextValidators>

    /**
     * @default 2
     */
    rows?: number
}


// file
export interface IFileDynamicFieldConfig extends IBaseDynamicFieldConfig {
    type: 'file',

    tableName: string,

    /**
     * @default 'file'
     */
    entityFieldName?: string,

    /**
     * @default false
     */
    multiple?: boolean,

    /**
     * @default 'list'
     */
    viewMode?: IFileViewMode
}

// date
export interface IDateDynamicFieldConfig extends IBaseDynamicFieldConfig {
    type: 'date',
    format?: string,
    modelFormat?: string,
    showIcon?: boolean,
    hideOnPick?: boolean,
    monthPicker?: boolean,
    disabledDates?: DatePickerComponent['disabledDates'],
    minDate?: string | Date,
    maxDate?: string | Date,
    weekPicker?: boolean,
    displayFormatter?: (date: DateExtended) => string,
    deselectable?: boolean
}

// array
export const radioSelectDynamicFieldViewTypes = ['radio', 'radioButtons'] as const;
export const selectDynamicFieldViewTypes = [
    'select', ...checkboxDynamicFieldViewTypes, ...radioSelectDynamicFieldViewTypes
] as const;

export type SelectDynamicFieldViewType = typeof selectDynamicFieldViewTypes[number];

export interface IDynamicFieldOption<TValue = unknown> {
    value: TValue,
    label: string
}

export interface IBaseSelectDynamicFieldConfig extends IBaseDynamicFieldConfig {
    type: 'select',
    viewType?: SelectDynamicFieldViewType,
    options?: IDynamicFieldOption[],
    validators?: ICommonValidators,
    validatorMessages?: ValidatorMessages<ICommonValidators>,
    /**
     * set to true to disable auto select of first option
     */
    noAutoSelect?: boolean
}

export interface IExtendedSelectDynamicFieldConfig extends IBaseSelectDynamicFieldConfig {
    viewType?: 'select',
    multiple?: boolean,
    typeToSearch?: number,
    searchByValue?: boolean,
    addOption?: (value: string) => void,
    deselectable?: boolean,
    deselectValue?: unknown
}

export interface ICheckboxSelectDynamicFieldConfig extends IBaseSelectDynamicFieldConfig {
    viewType?: 'checkbox' | 'switch',
    inline?: boolean
}

export interface ICheckBarSelectDynamicFieldConfig extends IBaseSelectDynamicFieldConfig {
    viewType?: 'checkBar',
    inline?: boolean
}

export type RadioSelectDynamicFieldViewType = typeof radioSelectDynamicFieldViewTypes[number];

export interface IRadioSelectDynamicFieldConfig extends IBaseSelectDynamicFieldConfig {
    viewType?: 'radio',
    inline?: boolean
}

export interface IRadioButtonsSelectDynamicFieldConfig extends IBaseSelectDynamicFieldConfig {
    viewType?: 'radioButtons'
}

export type ISelectDynamicFieldConfig = IExtendedSelectDynamicFieldConfig | ICheckboxSelectDynamicFieldConfig
    | ICheckBarSelectDynamicFieldConfig | IRadioSelectDynamicFieldConfig | IRadioButtonsSelectDynamicFieldConfig;

// relation
export interface IRelationDynamicFieldConfig extends IBaseDynamicFieldConfig {
    type: 'relation',
    endpoint: EndpointName,
    valueEndpoint?: EndpointName,
    itemEndpoint?: EndpointName,
    query?: IQueryObject,
    /**
     * entity property to be used as value
     * @default '@id'
     */
    valueKey?: '@id' | 'id',
    /**
     * values (by valueKey) to be excluded from possible relation select options
     */
    excludedValues?: string[],
    multiple?: boolean,
    deselectable?: boolean,
    deselectValue?: unknown
    /**
     * set to true to skip using other filters than searchString
     */
    searchString?: boolean,
    /**
     * set to true to disable auto select of first option
     */
    noAutoSelect?: boolean,
    /**
     * relation-selects objects with the same cacheKey will use the same configuration request.
     * The key can be, for example, the class of the parent component that contains multiple relation-selects
     * of the same type.
     */
    cacheKey?: object,
    initializationOptions?: IRestCollection<string, unknown>
}

// categories
export interface ICategoryDynamicFieldConfig extends IBaseDynamicFieldConfig {
    type: 'category',
    endpoint: EndpointName,

    /**
     * @default 'categories'
     */
    property?: string,

    /**
     * entity property to be used as value
     * @default 'iri'
     */
    valueKey?: 'id' | 'iri',

    /**
     * @default null
     */
    layoutType?: 'badge' | 'circle' | null;

    /**
     * @default false
     */
    multiple?: boolean,
    deselectable?: boolean,
    deselectValue?: unknown
}

// icon
export const AVAILABLE_FA_ICON_STYLES = {
    'regular': 'far',
    'solid': 'fas'
} as const;

export interface IIconDynamicFieldConfig extends IBaseDynamicFieldConfig {
    type: 'icon',
    deselectable?: boolean,
    deselectValue?: unknown,
    multiple?: boolean,
    iconStyles?: (typeof AVAILABLE_FA_ICON_STYLES[keyof typeof AVAILABLE_FA_ICON_STYLES])[],
    typeToSearch?: number,
    prefix?: boolean
}

export interface ICopyInputDynamicFieldConfig extends IBaseDynamicFieldConfig {
    type: 'copyInput',
    copyLang?: string,
    copiedLang?: string
}

// grouped
export type IDynamicFieldConfig = IStringDynamicFieldConfig
    | IEmailDynamicFieldConfig
    | IPasswordDynamicFieldConfig
    | INumberDynamicFieldConfig
    | INumberShuttleDynamicFieldConfig
    | IColorDynamicFieldConfig
    | ITextDynamicFieldConfig
    | IFileDynamicFieldConfig
    | ICheckboxDynamicFieldConfig
    | IDateDynamicFieldConfig
    | ISelectDynamicFieldConfig
    | IRelationDynamicFieldConfig
    | ICategoryDynamicFieldConfig
    | IIconDynamicFieldConfig
    | ICopyInputDynamicFieldConfig
    | IDurationPickerDynamicFieldConfig;

export type IDynamicFieldsConfig<T = string> = (IDynamicFieldConfig & { field: T });
// endregion fieldConfig
