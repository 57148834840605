import {Component, TemplateRef} from '@angular/core';
import {IUserLogin} from '_types/rest';
import {BootstrapColor} from 'src/modules/colors/colors.constant';

export type EntityTemplateTypes = keyof EntityTemplatesComponent;

export type IEntityTemplateContextFromType<T extends EntityTemplateTypes>
    = EntityTemplatesComponent[T] extends TemplateRef<infer C> ? Omit<C, keyof IBaseEntityTemplateContext> : never;

interface IBaseEntityTemplateContext {
    type: EntityTemplateTypes;
    value: unknown;
    $implicit: string;
}

export interface IContractItemTreeTemplateContext extends IBaseEntityTemplateContext {
    type: 'contractItemTree'
}

export interface IUserLoginBeforeTemplateContext extends IBaseEntityTemplateContext {
    type: 'userLoginBefore';
    value: IUserLogin;
}

export interface IBadgeTemplateContext extends IBaseEntityTemplateContext {
    type: 'badge',
    color: BootstrapColor
}

export interface IPlainTemplateContext extends IBaseEntityTemplateContext {
    type: 'plain'
}

export type IEntityTemplateContext = IContractItemTreeTemplateContext
    | IUserLoginBeforeTemplateContext
    | IPlainTemplateContext
    | IBadgeTemplateContext;

@Component({
    selector: 'entity-templates',
    templateUrl: './entity-templates.component.html',
    styleUrls: ['./entity-templates.component.scss']
})
export class EntityTemplatesComponent {
    plain: TemplateRef<IPlainTemplateContext>;
    contractItemTree: TemplateRef<IContractItemTreeTemplateContext>;
    userLoginBefore: TemplateRef<IUserLoginBeforeTemplateContext>;
    badge: TemplateRef<IBadgeTemplateContext>
}
