import {AbstractOperationDefinition} from 'src/modules/operations/interfaces';
import {AppTask} from 'src/modules/tasks/task-view/task-view.component';
import {Injector} from '@angular/core';
import {TaskUtilsService} from 'src/modules/tasks/task-utils.service';
import {RestClient} from 'src/modules/rest/rest-client.service';

export class TaskDateEditOperation extends AbstractOperationDefinition<'task_dates', AppTask> {
    readonly endpoint = 'task_dates';
    readonly name = 'put';
    lang = 'EDIT';
    icon = 'fa-edit';

    component = (): Promise<unknown> => import('./task-date-form/task-date-form.component');

    access(context: AppTask, injector: Injector): boolean {
        const restClient = injector.get(RestClient),
            taskUtilsService = injector.get(TaskUtilsService),
            restContext = restClient.endpoint<'tasks/item', AppTask>('tasks/item')
                .createObject(context);
        return taskUtilsService.access(restContext, 'put_item', 'dueDate');
    }
}

export const taskDatesOperations = [
    TaskDateEditOperation,
] as const;
