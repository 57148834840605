import {Inject, Injectable} from '@angular/core';
import {Comparison} from 'src/services/comparison';
import {WINDOW} from 'app-custom-providers';
import {RestError} from 'src/modules/rest/rest.error';
import {TranslateService} from 'src/modules/translate/translate.service';


type toastType = 'success' | 'error' | 'warning' | 'info' | undefined;

export interface IToast {
    type: toastType,
    title: string,
    text: string,
    visible: boolean,
    timeout: null | number,
    progress: number,
    interval: null | number,
    hide: () => void,
    uid?: unknown,
    action?: () => void,
    actionText?: string
}

export interface IToastOptions {
    autohide?: number,
    uid?: unknown,
    action?: () => void,
    actionText?: string
}

@Injectable({
    providedIn: 'root'
})
export class ToastsService {
    toasts: IToast[] = []

    constructor(
        private translateService: TranslateService,
        @Inject(WINDOW) private window: Window
    ) {
    }

    pop(
        text: string,
        title?: string,
        type?: toastType,
        options: IToastOptions = {}
    ): void {
        if (typeof options.autohide === 'undefined') {
            options.autohide = type === 'error' ? 0 : 6000;
        }

        let toast: IToast = {
            type,
            title,
            text,
            visible: true,
            timeout: null,
            progress: 0,
            interval: null,
            hide: () => {
                this.stopToastAutohide(toast);
                this.toasts.splice(this.toasts.indexOf(toast), 1);
            },
            action: options.action,
            actionText: options.actionText,
            uid: options.uid
        };
        let previousToast: IToast;

        if (
            typeof options.uid !== 'undefined'
            && (previousToast = this.toasts.find(Comparison.criteria({uid: options.uid})))
        ) {
            this.stopToastAutohide(previousToast);
            toast = previousToast;
        } else {
            this.toasts.push(toast);
        }

        if (options.autohide) {
            toast.timeout = window.setTimeout(() => {
                toast.timeout = null;
                toast.hide();
            }, options.autohide);

            // progress
            toast.progress = 100;
            const hideEta = new Date().getTime() + options.autohide;
            toast.interval = window.setInterval(() => {
                toast.progress = ((hideEta - new Date().getTime()) / options.autohide) * 100;
            }, 10);
        }
    }

    private stopToastAutohide(toast: IToast): void {
        if (toast.timeout !== null) {
            window.clearTimeout(toast.timeout);
        }
        if (toast.interval !== null) {
            window.clearInterval(toast.interval);
        }
    }

    /**
     * Show success toast
     */
    save(options?: IToastOptions): void {
        this.pop(this.translateService.get('SAVED'), undefined, 'success', options);
    }

    /**
     * Display REST error as a toast
     */
    restError(response: RestError): void {
        if (typeof response.details === 'string') {
            this.pop(response.details, response.message, 'error');
        } else {
            this.pop(response.message, undefined, 'error');
        }
    }
}
