import {
    Directive, ElementRef, OnInit, Optional, Renderer2, HostListener, Input,
} from '@angular/core';
import {ScreenSizeService} from 'src/services/screen-size.service';
import {ActionsComponent} from 'src/modules/actions/actions.component';

@Directive({
    selector: '[title]'
})
export class ActionsTitleDirective implements OnInit {
    @Input() public title: string;

    /**
     * Disable default tooltip behaviour on mobile view
     */
    @HostListener('mouseenter', ['$event'])
    @HostListener('focus', ['$event'])
    hideTooltip(): void {
        if (!this.actions) {
            return;
        }

        if (this.actions.isMobile && !this.actions.isDropdownEmpty) {
            this.elRef.nativeElement.classList.add('title-disabled');
        } else {
            this.elRef.nativeElement.classList.remove('title-disabled');
        }
    }
    constructor(
        public elRef: ElementRef<HTMLElement>,
        public renderer: Renderer2,
        public screenSize: ScreenSizeService,
        @Optional() readonly actions: ActionsComponent
    ) {
    }

    ngOnInit(): void {
        this.rendererMobileTitle(this.title);
    }

    /**
     * Display title as text content in mobile view
     */
    rendererMobileTitle(title: string): void {
        if (!this.actions) {
            return;
        }

        const el = this.renderer.createElement('span');
        const text = this.renderer.createText(title);

        this.renderer.addClass(el, 'd-block');
        if (!this.actions.isMobile) {
            this.renderer.addClass(el, 'd-md-none');
        }

        this.renderer.appendChild(el, text);
        this.renderer.appendChild(this.elRef.nativeElement, el);
    }
}
