import DateExtended from 'date-extensions';


export interface ITimeValues {
    hours: number;
    minutes: number;
    seconds?: number;
}

export abstract class DateUtils {

    static readonly HOURS_LABEL = 'h';
    static readonly MINUTES_LABEL = 'min';
    static readonly SECONDS_LABEL = 's';

    static readonly REMOVE_HOURS_REGEX = /T(([0-9]){2}:){2}([0-9]){2}/g;

    static readonly ONE_DAY_IN_MILLISECONDS = 1000 * 60 * 60 * 24;

    /**
     * Format seconds to HH:MM(:SS)?
     */
    static getTimeStringFromSeconds(totalSeconds: number | string,
        sec = true,
        format: 'default' | 'human' | 'minutes' = 'default'
    ): string  {
        if (
            typeof totalSeconds === 'undefined'
            || totalSeconds === null
        ) {
            return null;
        }

        let {hours, minutes, seconds} = this.getTimeValuesFromSeconds(totalSeconds);
        switch (format) {
            case 'human':
                return (hours ? hours + DateUtils.HOURS_LABEL : '')
                    + (!hours ? minutes + DateUtils.MINUTES_LABEL : ' ' +  minutes + DateUtils.MINUTES_LABEL)
                    + (sec ? ' ' + seconds + DateUtils.SECONDS_LABEL : '');
            case 'minutes':
                return `${Number(hours) * 60 + Number(minutes)} m`;
            default:
                minutes = String(minutes).padStart(2, '0');
                hours = String(hours).padStart(2, '0');
                seconds = String(seconds).padStart(2, '0');
                return hours + ':' + minutes + (sec ? ':' + seconds : '');
        }
    }

    static getTimeValuesFromSeconds(
        totalSeconds: number | string
    ): {hours: number | string, minutes: number | string, seconds: number | string} {
        if (typeof totalSeconds === 'string') {
            totalSeconds = parseInt(totalSeconds);
        }

        const hours = Math.floor(totalSeconds / 3600);

        totalSeconds %= 3600;
        const minutes = Math.floor(totalSeconds / 60),
            seconds = totalSeconds % 60;

        return {
            hours,
            minutes,
            seconds
        };
    }

    static getDifferenceBetweenDatesInSeconds(dateFrom?: string | Date, dateTo?: string | Date): number {
        if (typeof dateFrom === 'undefined') {
            dateFrom = new Date();
        } else if (!(dateFrom instanceof Date)) {
            dateFrom = new Date(dateFrom);
        }
        if (typeof dateTo  === 'undefined') {
            dateTo = new Date();
        } else if (!(dateTo instanceof Date)) {
            dateTo = new Date(dateTo);
        }

        return Math.floor((dateTo.getTime() - dateFrom.getTime()) / 1000);
    }

    static getSeconds(data: string | {hours: number; minutes: number; seconds?: number;}): number {
        let dataFormatted;
        if (typeof data === 'string') {
            dataFormatted = DateUtils.parseSemicolonFormat(data);

            if (dataFormatted.length < 2) {
                dataFormatted = DateUtils.parseJiraLikeFormat(data);
            }
        }
        else {
            dataFormatted = DateUtils.parseTimeValuesFormat(data);
        }

        const timeInSeconds = (parseInt(dataFormatted[0]) * 3600)
            + (parseInt(dataFormatted[1]) * 60)
            + (dataFormatted[2] ? parseInt(dataFormatted[2]) : 0);

        return isNaN(timeInSeconds) ? 0 : timeInSeconds;
    }

    private static parseSemicolonFormat(timeString: string): string[] {
        return timeString.split(':');
    }

    private static parseJiraLikeFormat(timeString: string): string[] {
        const dataFormattedParts = timeString.split(' '),
            dataFormatted = ['0', '0', '0'],
            notNumberRegExp = new RegExp(/\D/ig);

        dataFormattedParts.forEach((dataPart) => {
            if (dataPart.match(new RegExp(DateUtils.HOURS_LABEL, 'i'))) {
                dataFormatted[0] = dataPart.replace(notNumberRegExp, '');
                return;
            }

            if (dataPart.match(new RegExp(DateUtils.MINUTES_LABEL, 'i'))) {
                dataFormatted[1] = dataPart.replace(notNumberRegExp, '');
                return;
            }

            if (dataPart.match(new RegExp(DateUtils.SECONDS_LABEL, 'i'))) {
                dataFormatted[2] = dataPart.replace(notNumberRegExp, '');
            }
        });

        return dataFormatted;
    }

    private static parseTimeValuesFormat(timeValues: ITimeValues): number[] {
        return [
            timeValues.hours ?? 0,
            timeValues.minutes ?? 0,
            timeValues.seconds ?? 0
        ];
    }

    static getDateAndTime(datetime: string, seconds = true, timezone: string): {
        time: string,
        date: string
    } {
        const date = new DateExtended(
            (new DateExtended(datetime)).toLocaleString('en-us', {timeZone: timezone})
        );

        return {
            date: date.format('Y-m-d'),
            time: date.format('H:i' + (seconds ? ':s' : ''))
        };
    }

    static getStringDate(dateTime?: Date | string, timezone = true): string {
        let date;
        if (typeof dateTime !== 'undefined') {
            if (typeof dateTime === 'string') {
                if (timezone) {
                    date = new Date(dateTime);
                } else {
                    date = new Date(dateTime.substring(0, 19));
                }
            } else {
                date = dateTime;
            }
        } else {
            date = new Date();
        }

        return `${date.getFullYear()}-${('0' + (date.getMonth() + 1)).slice(-2)}-${('0' + date.getDate()).slice(-2)}`
            + `T${('0' + date.getHours()).slice(-2)}:${('0' + date.getMinutes()).slice(-2)}`
            + `:${('0' + date.getSeconds()).slice(-2)}`;
    }

    /**
     * Returns backend date based on date and time or dateTime string, if time is not provided.
     */
    static getStringDateForBackend(date: string, time?: string): string {
        const offset = Math.floor(new Date().getTimezoneOffset() / -60);
        return time
            ? `${date}T${time}${offset < 0 ? '-' : '+'}${('0' + offset).slice(-2)}:00`
            : `${date}${offset < 0 ? '-' : '+'}${('0' + offset).slice(-2)}:00`;
    }

    static getBackendDateFromExtended(date: DateExtended): string {
        return DateUtils.getStringDateForBackend(DateUtils.getStringDate(date));
    }

    /**
     * Returns true if data starts with string in format YYYY-MM-DD (naive)
     */
    static containsDate(data: string): boolean {
        return /^[0-9]{4}-[0-9]{2}-[0-9]{2}/.test(data);
    }

    static matchFirstDateTime(data: string, precision: 'full' | 'minutes' = 'full'): string {
        if (precision === 'minutes') {
            return data.match(/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}/)[0];
        } else {
            return data.match(/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\+\d{2}:\d{2}/)[0];
        }
    }

    /**
     * Get date range status
     * Use case: Check date range
     */
    static getDateRangeStatus(dateFrom: string | Date, dateTo: string | Date): string;
    static getDateRangeStatus(dateFrom: string | Date, dateTo: string | Date, stringReturn: false): number;
    static getDateRangeStatus(dateFrom: string | Date, dateTo: string | Date, stringReturn: true): string;
    static getDateRangeStatus(dateFrom: string | Date, dateTo: string | Date, stringReturn = true): string | number {
        const dateNow = new Date();
        const _dateFrom = new Date(dateFrom);
        const _dateTo = new Date(dateTo);

        if (dateNow.getTime() >= _dateFrom.getTime() && (dateTo === null || dateNow.getTime() <= _dateTo.getTime())) {
            return stringReturn ? 'current' : 1;
        }
        if (dateNow.getTime() >= _dateFrom.getTime() && dateNow.getTime() >= _dateTo.getTime()) {
            return stringReturn ? 'past' : 0;
        }
        if (dateNow.getTime() <= _dateFrom.getTime() && (dateTo === null || dateNow.getTime() <= _dateTo.getTime())) {
            return stringReturn ? 'future' : 2;
        }
    }

    /**
     * Has date inside date range
     * Use case: Check if date is inside date range
     */
    static hasDateInsideDateRange(dateFrom: string, dateTo: string, dateCurrent: string): boolean {
        const d1 = dateFrom.split('-'),
            d2 = dateTo.split('-'),
            c = dateCurrent.split('-'),
            from = new Date(Number(d1[2]), parseInt(d1[1]) - 1, Number(d1[0])),
            to = new Date(Number(d2[2]), parseInt(d2[1]) - 1, Number(d2[0])),
            check = new Date(Number(c[2]), parseInt(c[1]) - 1, Number(c[0]));

        return check >= from && check <= to;
    }

    static removeHours(value: string): string {
        return value.replace(this.REMOVE_HOURS_REGEX, '');
    }

    static getDifferenceBetweenDatesInDays(startDate: string, dueDate: string): number {
        const differenceInMilliseconds = new Date(dueDate).getTime() - new Date(startDate).getTime();
        return Math.round(differenceInMilliseconds / this.ONE_DAY_IN_MILLISECONDS);
    }
}
