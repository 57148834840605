import {NgModule} from '@angular/core';
import {CustomInputComponent} from 'src/modules/dynamic-fields/controls/custom-input/custom-input.component';
import {FileInputComponent} from 'src/modules/dynamic-fields/controls/file-input/file-input.component';
import {DynamicFieldComponent} from 'src/modules/dynamic-fields/dynamic-field/dynamic-field.component';
import {DynamicFieldHostDirective} from 'src/modules/dynamic-fields/dynamic-field/dynamic-field-host-directive.component';
import {NumberInputComponent} from 'src/modules/dynamic-fields/controls/number-input/number-input.component';
import {TextInputComponent} from 'src/modules/dynamic-fields/controls/text-input/text-input.component';
import {CustomCheckboxComponent} from 'src/modules/dynamic-fields/controls/custom-checkbox/custom-checkbox.component';
import {FileUploadModule} from 'src/modules/file-upload/file-upload.module';
import {TranslateModule} from 'src/modules/translate/translate.module';
import {ColorPickerComponent} from 'src/modules/dynamic-fields/controls/color-picker/color-picker.component';
import {NumberShuttleComponent} from 'src/modules/dynamic-fields/controls/number-shuttle/number-shuttle.component';
import {EsOptionsComponent} from 'src/modules/dynamic-fields/controls/dynamic-select/es-options/es-options.component';
import {ExtendedSelectModule} from 'angular-bootstrap4-extended-select';
import {DynamicSelectComponent} from 'src/modules/dynamic-fields/controls/dynamic-select/dynamic-select.component';
import {RadioSelectComponent} from 'src/modules/dynamic-fields/controls/dynamic-select/custom-select/radio-select.component';
import {CheckboxSelectComponent} from 'src/modules/dynamic-fields/controls/dynamic-select/custom-select/checkbox-select.component';
import {RadioButtonsSelectComponent} from 'src/modules/dynamic-fields/controls/dynamic-select/custom-select/radio-buttons-select.component';
import {RelationSelectComponent} from 'src/modules/dynamic-fields/controls/relation-select/relation-select.component';
import {AppDirectivesModule} from 'src/directives/app-directives.module';
import {AppCommonModule} from 'src/modules/app-common/app-common.module';
import {AppFormsModule} from 'src/modules/app-forms/app-forms.module';
import {EntityTemplatesComponent} from 'src/modules/dynamic-fields/controls/relation-select/entity-templates.component';
import {AvatarModule} from 'src/modules/avatar/avatar.module';
import {DynamicFieldsComponent} from 'src/modules/dynamic-fields/dynamic-fields/dynamic-fields.component';
import {SchemaFormComponent} from 'src/modules/dynamic-fields/schema-form/schema-form.component';
import {IconPickerComponent} from 'src/modules/dynamic-fields/controls/icon-picker/icon-picker.component';
import {CopyInputComponent} from 'src/modules/dynamic-fields/controls/copy-input/copy-input.component';
import {DurationPickerComponent} from 'src/modules/dynamic-fields/controls/duration-picker/duration-picker.component';
import {BsDropdownModule} from 'angular-bootstrap4';
import {PasswordInputComponent} from 'src/modules/dynamic-fields/controls/password-input/password-input.component';
import {CategorySelectComponent} from 'src/modules/dynamic-fields/controls/category-select/category-select.component';
import {WysiwygEditorModule} from 'src/modules/wysiwyg-editor/wysiwyg-editor.module';
import {
    DynamicFieldRerenderComponent
} from 'src/modules/dynamic-fields/dynamic-field-rerender/dynamic-field-rerender.component';

@NgModule({
    imports: [
        AppCommonModule,
        AppDirectivesModule,
        AppFormsModule,
        AvatarModule,
        BsDropdownModule,
        ExtendedSelectModule,
        FileUploadModule,
        TranslateModule,
        WysiwygEditorModule,
    ],
    declarations: [
        CategorySelectComponent,
        CheckboxSelectComponent,
        ColorPickerComponent,
        CopyInputComponent,
        CustomCheckboxComponent,
        CustomInputComponent,
        DurationPickerComponent,
        DynamicFieldComponent,
        DynamicFieldHostDirective,
        DynamicFieldRerenderComponent,
        DynamicFieldsComponent,
        DynamicSelectComponent,
        EntityTemplatesComponent,
        EsOptionsComponent,
        FileInputComponent,
        IconPickerComponent,
        NumberInputComponent,
        NumberShuttleComponent,
        PasswordInputComponent,
        RadioButtonsSelectComponent,
        RadioSelectComponent,
        RelationSelectComponent,
        SchemaFormComponent,
        TextInputComponent
    ],
    exports: [
        DynamicFieldComponent,
        DynamicFieldRerenderComponent,
        DynamicFieldsComponent,
        EsOptionsComponent,
        SchemaFormComponent
    ]
})
export class DynamicFieldsModule {
}
