/* eslint-disable max-len */
/**
 * This file has been auto generated. Do not edit.
 */
import {ILocale} from '_types/rest/Entity/IRestLocale';
import {IRestLocale} from '_types/rest/Entity/IRestLocale';
import {ITimezone} from '_types/rest/Entity/IRestTimezone';
import {IRestTimezone} from '_types/rest/Entity/IRestTimezone';
import {IFile} from '_types/rest/Entity/IRestFile';
import {IRestFile} from '_types/rest/Entity/IRestFile';
import {ICompany} from '_types/rest/Entity/IRestCompany';
import {IRestCompany} from '_types/rest/Entity/IRestCompany';
import {IUserPrivilegeAccessLevel} from '_types/rest/Entity/IRestUserPrivilegeAccessLevel';
import {IRestUserPrivilegeAccessLevel} from '_types/rest/Entity/IRestUserPrivilegeAccessLevel';
import {IUserPrivilege} from '_types/rest/Entity/IRestUserPrivilege';
import {IRestUserPrivilege} from '_types/rest/Entity/IRestUserPrivilege';
import {IUserHours} from '_types/rest/Entity/IRestUserHours';
import {IRestUserHours} from '_types/rest/Entity/IRestUserHours';
import {IClientOAuth2} from '_types/rest/Entity/IRestClientOAuth2';
import {IRestClientOAuth2} from '_types/rest/Entity/IRestClientOAuth2';
import {IUserWidget} from '_types/rest/Entity/IRestUserWidget';
import {IRestUserWidget} from '_types/rest/Entity/IRestUserWidget';
import {ITimeTrackerLog} from '_types/rest/Entity/IRestTimeTrackerLog';
import {IRestTimeTrackerLog} from '_types/rest/Entity/IRestTimeTrackerLog';
import {IUserLoginAuthenticationType} from '_types/rest/Entity/IRestUserLoginAuthenticationType';
import {IRestUserLoginAuthenticationType} from '_types/rest/Entity/IRestUserLoginAuthenticationType';
import {ITaskUser} from '_types/rest/Entity/IRestTaskUser';
import {IRestTaskUser} from '_types/rest/Entity/IRestTaskUser';
import {INotification} from '_types/rest/Entity/IRestNotification';
import {IRestNotification} from '_types/rest/Entity/IRestNotification';
import {INotificationUserSettings} from '_types/rest/Entity/IRestNotificationUserSettings';
import {IRestNotificationUserSettings} from '_types/rest/Entity/IRestNotificationUserSettings';
import {INotificationUserObjectSetting} from '_types/rest/Entity/IRestNotificationUserObjectSetting';
import {IRestNotificationUserObjectSetting} from '_types/rest/Entity/IRestNotificationUserObjectSetting';
import {IACLOGUserAssignment} from '_types/rest/Entity/IRestACLOGUserAssignment';
import {IRestACLOGUserAssignment} from '_types/rest/Entity/IRestACLOGUserAssignment';
import {ICategory} from '_types/rest/Entity/IRestCategory';
import {IRestCategory} from '_types/rest/Entity/IRestCategory';
import {IUserLoginDepartment} from '_types/rest/Entity/IRestUserLoginDepartment';
import {IRestUserLoginDepartment} from '_types/rest/Entity/IRestUserLoginDepartment';
import {IDepartmentRole} from '_types/rest/Entity/IRestDepartmentRole';
import {IRestDepartmentRole} from '_types/rest/Entity/IRestDepartmentRole';
import {IAbsence} from '_types/rest/Entity/IRestAbsence';
import {IRestAbsence} from '_types/rest/Entity/IRestAbsence';
import {IReplacement} from '_types/rest/Entity/IRestReplacement';
import {IRestReplacement} from '_types/rest/Entity/IRestReplacement';
import {IUserLoginDisable} from '_types/rest/Entity/IRestUserLoginDisable';
import {IRestUserLoginDisable} from '_types/rest/Entity/IRestUserLoginDisable';
import {IBaseRestEntity} from '_types/rest/IBaseRestEntity';
import {IRestObject} from 'core/rest/rest-object.service';

export const UserLoginActive = {
    USER_STATE_INACTIVE: 0,
    USER_STATE_ACTIVE: 1,
    USER_STATE_DEACTIVATED: 2
} as const;

export const UserLoginActiveOptions = [
    {
        value: UserLoginActive.USER_STATE_INACTIVE,
        label: 'USER_STATE_INACTIVE',
    },
    {
        value: UserLoginActive.USER_STATE_ACTIVE,
        label: 'USER_STATE_ACTIVE',
    },
    {
        value: UserLoginActive.USER_STATE_DEACTIVATED,
        label: 'USER_STATE_DEACTIVATED',
    }
] as const;

export const UserLoginType = {
    USER_TYPE_NORMAL: 1,
    USER_TYPE_API: 2,
    USER_TYPE_WORKER: 3,
    USER_TYPE_CONTEXT: 4
} as const;

export const UserLoginTypeOptions = [
    {
        value: UserLoginType.USER_TYPE_NORMAL,
        label: 'USER_TYPE_NORMAL',
    },
    {
        value: UserLoginType.USER_TYPE_API,
        label: 'USER_TYPE_API',
    },
    {
        value: UserLoginType.USER_TYPE_WORKER,
        label: 'USER_TYPE_WORKER',
    },
    {
        value: UserLoginType.USER_TYPE_CONTEXT,
        label: 'USER_TYPE_CONTEXT',
    }
] as const;

export interface IUserLoginConst {
    active: [{id: 0, value: 'USER_STATE_INACTIVE'}, {id: 1, value: 'USER_STATE_ACTIVE'}, {id: 2, value: 'USER_STATE_DEACTIVATED'}],
    type: [{id: 1, value: 'USER_TYPE_NORMAL'}, {id: 2, value: 'USER_TYPE_API'}, {id: 3, value: 'USER_TYPE_WORKER'}, {id: 4, value: 'USER_TYPE_CONTEXT'}]
}

export interface IUserLogin extends IBaseRestEntity {
    id?: number,
    /**
     * @maxLength 64
     */
    customId?: string | null,
    /**
     * @maxLength 128
     * @format email
     */
    email: string,
    password?: string | null,
    /**
     * @maxLength 128
     */
    firstName: string,
    /**
     * @maxLength 256
     */
    lastName: string,
    /**
     * @maxLength 64
     */
    phone: string,
    active?: typeof UserLoginActive[keyof typeof UserLoginActive],
    locale: ILocale | string | null,
    timezone: ITimezone | string | null,
    file?: IFile | string | null,
    company: ICompany | string | null,
    userPrivilegeAccessLevels?: (IUserPrivilegeAccessLevel | string)[],
    userPrivileges?: (IUserPrivilege | string)[],
    userHours?: (IUserHours | string)[],
    /**
     * @default 1
     */
    type?: typeof UserLoginType[keyof typeof UserLoginType],
    roles?: string[],
    oauthClients?: (IClientOAuth2 | string)[],
    userWidgets?: (IUserWidget | string)[],
    timeTrackerLogs?: (ITimeTrackerLog | string)[],
    /**
     * @format date-time
     */
    lastPasswordChanged?: string | null,
    mfaEnabled?: number,
    /**
     * @format date-time
     */
    workStartDate?: string | null,
    supervisor?: IUserLogin | string | null,
    subordinates?: (IUserLogin | string)[],
    authenticationTypes?: (IUserLoginAuthenticationType | string)[],
    taskUsers?: (ITaskUser | string)[],
    notifications?: (INotification | string)[],
    notificationUserSettings?: (INotificationUserSettings | string)[],
    notificationUserObjectSettings?: (INotificationUserObjectSetting | string)[],
    /**
     * @default true
     */
    notificationsEnabled?: boolean,
    ACLOGUserAssignments?: (IACLOGUserAssignment | string)[],
    workplace?: ICategory | string | null,
    skills?: (ICategory | string)[],
    languageSkills?: (ICategory | string)[],
    userLoginDepartments?: (IUserLoginDepartment | string)[],
    departmentRole?: IDepartmentRole | string | null,
    absences?: (IAbsence | string)[],
    replacements?: (IReplacement | string)[],
    /**
     * @format date-time
     */
    deactivationDate?: string | null,
    userLoginDisable?: IUserLoginDisable | string | null,
    /**
     * @description Record status
     *  0 = deleted
     *  1 = active.
     * @default 1
     */
    status?: number,
    /**
     * @description Creation timestamp.
     * @default CURRENT_TIMESTAMP
     * @format date-time
     */
    created?: string,
    /**
     * @description Last modification timestamp.
     * @format date-time
     */
    modified?: string | null
}

/** @deprecated */
export interface IRestUserLogin extends IUserLogin, IRestObject {
    locale: IRestLocale | string | null,
    timezone: IRestTimezone | string | null,
    file?: IRestFile | string | null,
    company: IRestCompany | string | null,
    userPrivilegeAccessLevels?: IRestUserPrivilegeAccessLevel[] | string[],
    userPrivileges?: IRestUserPrivilege[] | string[],
    userHours?: IRestUserHours[] | string[],
    oauthClients?: IRestClientOAuth2[] | string[],
    userWidgets?: IRestUserWidget[] | string[],
    timeTrackerLogs?: IRestTimeTrackerLog[] | string[],
    supervisor?: IRestUserLogin | string | null,
    subordinates?: IRestUserLogin[] | string[],
    authenticationTypes?: IRestUserLoginAuthenticationType[] | string[],
    taskUsers?: IRestTaskUser[] | string[],
    notifications?: IRestNotification[] | string[],
    notificationUserSettings?: IRestNotificationUserSettings[] | string[],
    notificationUserObjectSettings?: IRestNotificationUserObjectSetting[] | string[],
    ACLOGUserAssignments?: IRestACLOGUserAssignment[] | string[],
    workplace?: IRestCategory | string | null,
    skills?: IRestCategory[] | string[],
    languageSkills?: IRestCategory[] | string[],
    userLoginDepartments?: IRestUserLoginDepartment[] | string[],
    departmentRole?: IRestDepartmentRole | string | null,
    absences?: IRestAbsence[] | string[],
    replacements?: IRestReplacement[] | string[],
    userLoginDisable?: IRestUserLoginDisable | string | null,
}
