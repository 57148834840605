import {AbstractOperationDefinition} from 'src/modules/operations/interfaces';
import {IRestCollection, IRestObject} from 'src/modules/rest/objects';
import {Injector} from '@angular/core';
import {UserService} from 'src/modules/rest/user/user.service';
import {StateService} from '@uirouter/core';
import {RestClient} from 'src/modules/rest/rest-client.service';

export interface IValuationCloudAddOperationContext {
    valuationClouds: IRestCollection<'valuation_clouds'>,
    partnerIri: string
}

export class ValuationCloudAddOperation
    extends AbstractOperationDefinition<string, IValuationCloudAddOperationContext> {
    readonly endpoint = 'valuation_clouds';
    readonly name = 'post';
    lang = 'VALUATION_CLOUD_ADD';
    icon = 'fa-plus';
    readonly modalSize = 'xl';

    component = (): Promise<unknown> => import('./valuation-cloud-form/valuation-cloud-form.component');

    access(context: IValuationCloudAddOperationContext): boolean {
        if (RestClient.isRestCollection(context.valuationClouds)) {
            return context.valuationClouds.access('post');
        }

        return false;
    }
}

export class ValuationCloudEditOperation extends AbstractOperationDefinition<'valuation_clouds'> {
    readonly endpoint = 'valuation_clouds';
    readonly name = 'put';
    lang = 'VALUATION_CLOUD_EDIT';
    icon = 'fa-edit';
    readonly modalSize = 'xl';

    component = (): Promise<unknown> => import('./valuation-cloud-form/valuation-cloud-form.component');
}

export class ValuationCloudSettingsOperation extends AbstractOperationDefinition<'valuation_clouds'> {
    readonly endpoint = 'valuation_clouds';
    readonly name = 'settings';
    lang = 'EDIT_VALUATION_SETTINGS';
    icon = 'fa-cog';

    invoke(context: IRestObject<'valuation_clouds'>, injector: Injector): void {
        const partner = typeof context.partner === 'string'
            ? injector.get(RestClient).parseIri(context.partner)
            : context.partner;

        injector.get(StateService).go('panelSettings.view.valuationCloud.settings', {
            partnerId: partner.id,
            valuationCloudId: context.id
        });
    }

    access(context: IRestObject<'valuation_clouds'>, injector: Injector): boolean {
        return injector.get(UserService).hasPrivileges('panelSettings.view.valuationCloud.settings');
    }
}

export const valuationCloudsOperations = [
    ValuationCloudAddOperation,
    ValuationCloudEditOperation,
    ValuationCloudSettingsOperation
] as const;
