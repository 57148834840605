import {Component, EventEmitter, Input, Output} from '@angular/core';
import {Color, COLORS} from 'src/modules/colors/colors.constant';


@Component({
    selector: 'color-list',
    templateUrl: './color-list.component.html',
    styleUrls: ['./color-list.component.scss']
})
export class ColorListComponent {
    @Input() selectedColor: Color;
    @Output() readonly selectedColorChange = new EventEmitter<Color>();

    colors = COLORS;
}
