import {AbstractDeleteOperation} from 'view-modules/operations/abstract/abstract-delete.operation';
import {IConfirmModalOptions} from 'src/modules/global-components/confirm-modal/confirm-modal.service';
import {AbstractOperationDefinition} from 'src/modules/operations/interfaces';
import {IOAuth2Application, IPartner} from '_types/rest';

type OAuth2AppCustom = {auth: IOAuth2Application, partner: IPartner};

export class PartnerOAuthApplicationsAddOperation
    extends AbstractOperationDefinition<'o_auth2_applications_custom', OAuth2AppCustom> {
    readonly endpoint = 'o_auth2_applications_custom';
    readonly name = 'post';
    lang = 'ADD_APPLICATION';
    icon = 'fa-plus';
    component = (): Promise<unknown> => import('./partner-oauth-applications-form.component');
}

export class PartnerOAuthApplicationsEditOperation
    extends AbstractOperationDefinition<'o_auth2_applications_custom', OAuth2AppCustom> {
    readonly endpoint = 'o_auth2_applications_custom';
    readonly name = 'put';
    lang = 'EDIT';
    icon = 'fa-edit';
    component = (): Promise<unknown> => import('./partner-oauth-applications-form.component');
}

export class PartnerOAuthApplicationsDeleteOperation extends AbstractDeleteOperation<'o_auth2_applications'> {
    readonly endpoint = 'o_auth2_applications';
    lang = 'DELETE';
    confirmMessage = 'DELETE_APPLICATION_CONFIRM';
    confirmOptions: IConfirmModalOptions = {
        primaryBtn: 'danger',
        langYes: 'DELETE_APPLICATION_CONFIRM_LANG_YES',
        text: 'ACTION_CANNOT_BE_UNDONE'
    };
}

export const partnerOAuthOperations = [
    PartnerOAuthApplicationsAddOperation,
    PartnerOAuthApplicationsEditOperation,
    PartnerOAuthApplicationsDeleteOperation
] as const;
