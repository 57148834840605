import {Observable} from 'rxjs';

export const ObjectNotificationSettingStates = {
    STATE_DEFAULT: 1,
    STATE_ENABLED: 2,
    STATE_DISABLED: 3,
} as const;

export const ObjectNotificationSettingOptions = [
    {
        value: ObjectNotificationSettingStates.STATE_ENABLED,
        label: 'OBJECT_NOTIFICATION_SETTING_STATE_ENABLED',
    },
    {
        value: ObjectNotificationSettingStates.STATE_DISABLED,
        label: 'OBJECT_NOTIFICATION_SETTING_STATE_DISABLED',
    },
    {
        value: ObjectNotificationSettingStates.STATE_DEFAULT,
        label: 'OBJECT_NOTIFICATION_SETTING_STATE_DEFAULT',
    },
];

export type IObjectNotificationSettingStateValue
    = typeof ObjectNotificationSettingStates[keyof typeof ObjectNotificationSettingStates];

export type IUpdateObjectNotificationCb = (value: IObjectNotificationSettingStateValue) => Observable<object>;
