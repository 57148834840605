<div class="d-flex" (mouseover)="mouseover()" (mouseleave)="mouseleave()">
    <div #div>
        <ng-content></ng-content>
        <bs-popover *ngIf="popover"
                    [visible]="popover"
                    placement="auto bottom"
                    [parentElement]="div"
                    absoluteToDocument>
            <div title>
                <div class="d-block text-center px-3 pt-2 pb-2">
                    <avatar class="d-inline-block"
                            [user]="userSummary || user"
                            [size]="80">
                    </avatar>

                    <h3 *ngIf="userDetailsModel.name" class="mt-4 text-bold">
                        {{user.name}}
                    </h3>

                    <h3 *ngIf="userDetailsModel.firstName && userDetailsModel.lastName"
                        class="mt-4 text-bold">
                        {{userDetailsModel.lastName}} {{userDetailsModel.firstName}}
                    </h3>

                    <span *ngIf="company">
                        {{company.name}}
                    </span>

                    <div *ngIf="userDetailsModel.departmentRole">
                        <span class="font-italic">
                            {{ userDetailsModel.departmentRole.name | translate }}
                        </span>
                    </div>

                    <div class="mt-3" *ngIf="userDetailsModel.userLoginDepartments">
                        <span *ngFor="let department of userDetailsModel.userLoginDepartments;let last = last">
                            {{ department.department.name | translate }}<span *ngIf="!last">, </span>
                        </span>
                    </div>
                </div>
            </div>
            <div content>
                <ng-container *ngIf="!loadingUserSummary; else loader">
                    <div class="px-3 pb-3 pt-2"
                         [ngClass]="{'border-bottom': userDetailsModel.absences?.length}">
                        <a *ngIf="userDetailsModel.email"
                           class="d-flex align-items-center text-primary"
                           href="mailto:{{ userDetailsModel.email }}">
                            <i class="fa fa-lg fa-envelope fa-fw"></i>
                            <span class="ml-2">
                            {{userDetailsModel.email}}
                        </span>
                        </a>
                        <a *ngIf="userDetailsModel.phone || companyContactPhone"
                           class="d-flex align-items-center mt-3 text-primary"
                           href="tel:{{ userDetailsModel.phone || companyContactPhone.phoneNumber }}">
                            <i class="fa fa-lg fa-phone-alt fa-fw"></i>
                            <span class="ml-2">
                            {{userDetailsModel.phone || companyContactPhone.phoneNumber}}
                        </span>
                        </a>
                    </div>

                    <div class="px-3 pb-3 pt-2">
                        <div *ngIf="userDetailsModel.absences?.length">
                            <span class="text-bold">
                                {{ 'USER_ABSENCE_INFO' | translate }}
                            </span>

                            <ng-container *ngFor="let absence of userDetailsModel.absences;let last = last">
                                <div class="py-2"
                                     [ngClass]="{'border-bottom': !last}">
                                    <div class="danger text-nowrap pb-1">
                                        {{ absence.startDate | date:  'full' : false }}
                                        - {{ absence.endDate | date:  'full' : false  }}
                                    </div>

                                    <div class="pb-1">
                                        {{ 'ABSENCE_REPLACEMENT_USER_LOGIN' | translate }}
                                    </div>
                                    <ng-container *ngIf="absence.replacements?.length; else noReplacementsTemplate">
                                        <div *ngFor="let replacement of absence.replacements;let last = last"
                                             class="d-flex align-items-center flex-nowrap">
                                            <avatar [user]="replacement.userLogin"
                                                    [userDetails]="true">
                                            </avatar>
                                            <span class="ml-2 text-nowrap">
                                                {{replacement.userLogin.lastName}} {{replacement.userLogin.firstName}}
                                            </span>
                                        </div>
                                    </ng-container>
                                    <ng-template #noReplacementsTemplate>
                                        -
                                    </ng-template>

                                </div>
                            </ng-container>
                        </div>
                    </div>
                </ng-container>
            </div>

            <ng-template #loader>
                <div class="text-center h5 mx-2 my-4">
                    <span class="fas fa-circle-notch fa-spin" aria-hidden="true"></span>
                    {{ 'LOADING' | translate }}
                </div>
            </ng-template>

        </bs-popover>
    </div>
</div>
