import {Injector} from '@angular/core';
import {RestClient} from 'src/modules/rest/rest-client.service';
import {Observable} from 'rxjs';
import {ConfirmModalService} from 'src/modules/global-components/confirm-modal/confirm-modal.service';
import {IRestObject} from 'src/modules/rest/objects';
import {AbstractOperationDefinition} from 'src/modules/operations/interfaces';
import {AccessPipe} from 'src/pipes/access.pipe';
import {ICompany} from '_types/rest';
import {IOperationResult} from 'src/modules/operations/interfaces';

interface IUpdateClientGuardianContext {
    client: ICompany;
    guardian: IRestObject<'guardians'>;
}

export class DeleteGuardian extends AbstractOperationDefinition<'guardians_update', IUpdateClientGuardianContext> {
    readonly endpoint = 'guardians_update'
    readonly name = 'delete';
    lang = 'DELETE';
    icon = 'fa-trash-alt';
    invoke = (
        context: IUpdateClientGuardianContext,
        injector: Injector
    ): Observable<IOperationResult> => {
        const confirmModal = injector.get(ConfirmModalService),
            restClient = injector.get(RestClient);

        return confirmModal.confirmOperation('CLIENTS_GUARDIANS_CONFIRM_GUARDIAN_DELETE', () => {
            return context.guardian.delete()
                .subscribe(() => {
                    restClient.savedToast();
                });
        },
        {
            langYes: 'CLIENTS_GUARDIANS_CONFIRM_GUARDIAN_DELETE_LANG_YES'
        });
    };
    access(context: IUpdateClientGuardianContext): boolean {
        return new AccessPipe().transform(context.guardian, this.name);
    }
}

export class EditGuardian extends AbstractOperationDefinition<'guardians_update', IUpdateClientGuardianContext> {
    readonly endpoint = 'guardians_update'
    readonly name = 'put';
    lang = 'CLIENTS_GUARDIANS_EDIT';
    icon = 'fa-pencil-alt';
    component = (): Promise<unknown> =>
        import(
            'view-modules/operations/clients/client-guardians/client-guardian-form/client-guardian-form.component'
        );

    access(context: IUpdateClientGuardianContext): boolean {
        return new AccessPipe().transform(context.guardian, this.name);
    }
}


export class AddGuardian extends AbstractOperationDefinition<'guardians_update', IUpdateClientGuardianContext> {
    readonly endpoint = 'guardians_update'
    readonly name = 'post';
    lang = 'CLIENTS_GUARDIANS_ADD';
    icon = 'fa-plus';
    component = (): Promise<unknown> =>
        import(
            'view-modules/operations/clients/client-guardians/client-guardian-form/client-guardian-form.component'
        );

    access(context: IUpdateClientGuardianContext): boolean {
        if (!context) {
            return false;
        }

        if (RestClient.isRestObject(context.guardian) || RestClient.isRestCollection(context.guardian)) {
            return context?.guardian.access(this.name);
        }

        return false;
    }
}

export const guardianOperations = [
    DeleteGuardian,
    EditGuardian,
    AddGuardian
] as const;
