/* eslint-disable max-len */
/**
 * This file has been auto generated. Do not edit.
 */
import {ICompany} from '_types/rest/Entity/IRestCompany';
import {IRestCompany} from '_types/rest/Entity/IRestCompany';
import {ICountry} from '_types/rest/Entity/IRestCountry';
import {IRestCountry} from '_types/rest/Entity/IRestCountry';
import {IRegion} from '_types/rest/Entity/IRestRegion';
import {IRestRegion} from '_types/rest/Entity/IRestRegion';
import {IBaseRestEntity} from '_types/rest/IBaseRestEntity';
import {IRestObject} from 'core/rest/rest-object.service';

export const AddressType = {
    ADDRESS_TYPE_MAIN: 1,
    ADDRESS_TYPE_MAILING: 2,
    ADDRESS_TYPE_OTHER: 3
} as const;

export const AddressTypeOptions = [
    {
        value: AddressType.ADDRESS_TYPE_MAIN,
        label: 'ADDRESS_TYPE_MAIN',
    },
    {
        value: AddressType.ADDRESS_TYPE_MAILING,
        label: 'ADDRESS_TYPE_MAILING',
    },
    {
        value: AddressType.ADDRESS_TYPE_OTHER,
        label: 'ADDRESS_TYPE_OTHER',
    }
] as const;

export interface IAddressConst {
    type: [{id: 1, value: 'ADDRESS_TYPE_MAIN'}, {id: 2, value: 'ADDRESS_TYPE_MAILING'}, {id: 3, value: 'ADDRESS_TYPE_OTHER'}]
}

export interface IAddress extends IBaseRestEntity {
    id?: number,
    /**
     * @description Address type.
     * @default 3
     */
    type?: typeof AddressType[keyof typeof AddressType],
    /**
     * @description Full company name.
     * @maxLength 256
     */
    fullName: string,
    /**
     * @description Company street without number.
     * @maxLength 128
     */
    street: string,
    /**
     * @description Company street number.
     * @maxLength 64
     */
    streetNo: string,
    /**
     * @description Company City name.
     * @maxLength 128
     */
    city: string,
    /**
     * @description Company postcode.
     * @maxLength 16
     */
    postCode: string,
    /**
     * @description Additional Information about company.
     * @maxLength 1024
     */
    info: string,
    /**
     * @description Company Entity connected with this address.
     */
    company: ICompany | string | null,
    /**
     * @description Company country.
     */
    country: ICountry | string | null,
    /**
     * @description Company district.
     */
    region?: IRegion | string | null,
    /**
     * @description Record status
     *  0 = deleted
     *  1 = active.
     * @default 1
     */
    status?: number,
    /**
     * @description Creation timestamp.
     * @default CURRENT_TIMESTAMP
     * @format date-time
     */
    created?: string,
    /**
     * @description Last modification timestamp.
     * @format date-time
     */
    modified?: string | null
}

/** @deprecated */
export interface IRestAddress extends IAddress, IRestObject {
    /**
     * @description Company Entity connected with this address.
     */
    company: IRestCompany | string | null,
    /**
     * @description Company country.
     */
    country: IRestCountry | string | null,
    /**
     * @description Company district.
     */
    region?: IRestRegion | string | null,
}
