import {Component, ElementRef, Optional, OnInit, Renderer2, Inject, OnDestroy, DoCheck} from '@angular/core';
import {ActionsTableDirective} from 'src/modules/actions/actions-table.directive';
import {ScreenSizeService} from 'src/services/screen-size.service';
import {ActionsBoundaryDirective} from 'src/modules/actions/actions-boundary.directive';
import {WINDOW} from 'app-custom-providers';
import {TranslateService} from 'src/modules/translate/translate.service';

@Component({
    selector: '[actions]',
    templateUrl: './actions.component.html',
    styleUrls: ['./actions.component.scss']
})
export class ActionsComponent implements OnInit, DoCheck, OnDestroy {
    dropdown: boolean;
    isMobile: boolean;

    actionTranslation: string;
    dropdownElement: Element;
    isDropdownEmpty = false;

    private windowResizeListener: () => void;

    constructor(
        public elRef: ElementRef<HTMLElement>,
        private renderer: Renderer2,
        public screenSize: ScreenSizeService,
        @Optional() readonly table: ActionsTableDirective,
        @Optional() readonly actionsBoundary: ActionsBoundaryDirective,
        @Inject(WINDOW) private window: Window,
        private translateService: TranslateService
    ) {
    }

    ngOnInit(): void {
        this.actionTranslation = this.translateService.get('ACTIONS');

        if (this.table && this.elRef.nativeElement.tagName === 'TH') {
            this.renderer.addClass(this.elRef.nativeElement, 'ignore-export');
        }

        this._updateIsMobile();
        this.windowResizeListener = () => this._updateIsMobile();
        this.window.addEventListener('resize', this.windowResizeListener);
    }

    private _updateIsMobile(): void {
        this.isMobile = !this.screenSize.is('md')
            || this.table?.elRef.nativeElement.classList.contains('table-actions-mobile')
            || this.actionsBoundary?.elRef.nativeElement.classList.contains('actions-mobile');
    }

    ngDoCheck(): void {
        if (!this.isMobile) {
            return;
        }

        this.checkDropdownContent();
    }

    private checkDropdownContent(): void {
        this.searchDropdownElement();

        if (this.dropdownElement) {
            this.isDropdownEmpty = this.dropdownElement.textContent === this.actionTranslation;
        }
    }

    private searchDropdownElement(): void {
        if (this.dropdownElement) {
            return;
        }

        const elements = this.elRef.nativeElement.getElementsByClassName('dropdown-menu');
        if (elements.length) {
            this.dropdownElement = elements[0];
        }
    }

    ngOnDestroy(): void {
        this.window.removeEventListener('resize', this.windowResizeListener);
    }
}


