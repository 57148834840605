import {NgModule} from '@angular/core';
import {AppCommonModule} from 'src/modules/app-common/app-common.module';
import {AvatarComponent} from 'src/modules/avatar/avatar.component';
import {AvatarDetailsComponent} from 'src/modules/avatar/avatar-details/avatar-details.component';
import {BsPopoverModule} from 'angular-bootstrap4';
import {AppDirectivesModule} from 'src/directives/app-directives.module';
import {TranslateModule} from 'src/modules/translate/translate.module';

@NgModule({
    imports: [
        AppCommonModule,
        AppDirectivesModule,
        BsPopoverModule,
        TranslateModule
    ],
    declarations: [
        AvatarComponent,
        AvatarDetailsComponent,
    ],
    exports: [
        AvatarComponent
    ]
})
export class AvatarModule {
}
