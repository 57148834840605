/**
 * This file has been auto generated. Do not edit.
 */
import {IDepartment} from '_types/rest/Entity/IRestDepartment';
import {IRestDepartment} from '_types/rest/Entity/IRestDepartment';
import {IUserLoginDepartmentRole} from '_types/rest/Entity/IRestUserLoginDepartmentRole';
import {IRestUserLoginDepartmentRole} from '_types/rest/Entity/IRestUserLoginDepartmentRole';
import {IDepartmentRoleACLOGRole} from '_types/rest/Entity/IRestDepartmentRoleACLOGRole';
import {IRestDepartmentRoleACLOGRole} from '_types/rest/Entity/IRestDepartmentRoleACLOGRole';
import {ITaskAssignmentDepartmentRole} from '_types/rest/Entity/IRestTaskAssignmentDepartmentRole';
import {IRestTaskAssignmentDepartmentRole} from '_types/rest/Entity/IRestTaskAssignmentDepartmentRole';
import {IUserLogin} from '_types/rest/Entity/IRestUserLogin';
import {IRestUserLogin} from '_types/rest/Entity/IRestUserLogin';
import {IBaseRestEntity} from '_types/rest/IBaseRestEntity';
import {IRestObject} from 'core/rest/rest-object.service';

export const DepartmentRoleType = {
    DEPARTMENT_ROLE_TYPE_USER: 0,
    DEPARTMENT_ROLE_TYPE_SUPERVISOR: 1
} as const;

export const DepartmentRoleTypeOptions = [
    {
        value: DepartmentRoleType.DEPARTMENT_ROLE_TYPE_USER,
        label: 'DEPARTMENT_ROLE_TYPE_USER',
    },
    {
        value: DepartmentRoleType.DEPARTMENT_ROLE_TYPE_SUPERVISOR,
        label: 'DEPARTMENT_ROLE_TYPE_SUPERVISOR',
    }
] as const;

export interface IDepartmentRoleConst {
    type: [{id: 0, value: 'DEPARTMENT_ROLE_TYPE_USER'}, {id: 1, value: 'DEPARTMENT_ROLE_TYPE_SUPERVISOR'}]
}

export interface IDepartmentRole extends IBaseRestEntity {
    id?: number,
    /**
     * @maxLength 128
     */
    name: string,
    type: typeof DepartmentRoleType[keyof typeof DepartmentRoleType],
    /**
     * @default 1
     */
    level: number,
    department: IDepartment | string | null,
    /**
     * @description Parent roles eg. supervisor.
     */
    parentDepartmentRoles?: (IDepartmentRole | string)[],
    readonly childDepartmentRoles?: (IDepartmentRole | string)[],
    readonly userDepartmentRoles?: (IUserLoginDepartmentRole | string)[],
    departmentRoleACLOGRoles?: (IDepartmentRoleACLOGRole | string)[],
    taskAssignmentDepartmentRoles?: (ITaskAssignmentDepartmentRole | string)[],
    userLogins?: (IUserLogin | string)[],
    /**
     * @description Record status
     *  0 = deleted
     *  1 = active.
     * @default 1
     */
    status?: number,
    /**
     * @description Creation timestamp.
     * @default CURRENT_TIMESTAMP
     * @format date-time
     */
    created?: string,
    /**
     * @description Last modification timestamp.
     * @format date-time
     */
    modified?: string | null
}

/** @deprecated */
export interface IRestDepartmentRole extends IDepartmentRole, IRestObject {
    department: IRestDepartment | string | null,
    /**
     * @description Parent roles eg. supervisor.
     */
    parentDepartmentRoles?: IRestDepartmentRole[] | string[],
    readonly childDepartmentRoles?: IRestDepartmentRole[] | string[],
    readonly userDepartmentRoles?: IRestUserLoginDepartmentRole[] | string[],
    departmentRoleACLOGRoles?: IRestDepartmentRoleACLOGRole[] | string[],
    taskAssignmentDepartmentRoles?: IRestTaskAssignmentDepartmentRole[] | string[],
    userLogins?: IRestUserLogin[] | string[],
}
