import {NgModule} from '@angular/core';
import {AbsPipe} from 'src/pipes/abs.pipe';
import {AccessPipe} from 'src/pipes/access.pipe';
import {CurrencyPipe} from 'src/pipes/currency.pipe';
import {DatePipe} from 'src/pipes/date.pipe';
import {DefaultPipe} from 'src/pipes/default.pipe';
import {DomainPipe} from 'src/pipes/domain.pipe';
import {EmptyResultPipe} from 'src/pipes/empty-result.pipe';
import {HumanFileSizePipe} from 'src/pipes/human-file-size.pipe';
import {KeyValuePipe, PercentPipe} from '@angular/common';
import {SelectModelOptionsPipe} from 'src/pipes/select-model-options.pipe';
import {ShortTextPipe} from 'src/pipes/short-text.pipe';
import {SplitTextPipe} from 'src/pipes/split-text.pipe';
import {ToTimePipe} from 'src/pipes/to-time.pipe';
import {UnsafePipe} from 'src/pipes/unsafe.pipe';
import {TextColorPipe} from 'src/pipes/text-color.pipe';
import {ParseIriPipe} from 'src/pipes/parse-iri.pipe';
import {TimeLoggedPipe} from 'src/pipes/time-logged.pipe';
import {FromOptionsPipe} from 'src/pipes/from-options.pipe';
import {IsJsonObjectPipe} from 'src/pipes/is-json-object.pipe';
import {JsonTextPipe} from 'src/pipes/json-text.pipe';
import {BootstrapColorFromNumberPipe} from 'src/pipes/bootstrap-color-from-number.pipe';
import {GetIconPipe} from 'src/pipes/get-icon.pipe';
import {WordVariationPipe} from 'src/pipes/word-variation.pipe';
import {HighlightSearchPipe} from 'src/pipes/highlight-search.pipe';
import {TypeofPipe} from 'src/pipes/typeof.pipe';
import {NestedPropertyPipe} from 'src/pipes/nested-property.pipe';
import {UniqueNamePipe} from 'src/pipes/unique-name.pipe';
import {GetContractCurrencyCodePipe} from 'src/pipes/get-item-currency-code';
import {BindPipe} from 'src/pipes/bind.pipe';

@NgModule({
    exports: [
        AbsPipe,
        AccessPipe,
        BindPipe,
        BootstrapColorFromNumberPipe,
        CurrencyPipe,
        DatePipe,
        DefaultPipe,
        DomainPipe,
        EmptyResultPipe,
        FromOptionsPipe,
        GetContractCurrencyCodePipe,
        GetIconPipe,
        HighlightSearchPipe,
        HumanFileSizePipe,
        IsJsonObjectPipe,
        JsonTextPipe,
        NestedPropertyPipe,
        ParseIriPipe,
        SelectModelOptionsPipe,
        ShortTextPipe,
        SplitTextPipe,
        TextColorPipe,
        TimeLoggedPipe,
        ToTimePipe,
        TypeofPipe,
        UniqueNamePipe,
        UnsafePipe,
        WordVariationPipe
    ],
    declarations: [
        AbsPipe,
        AccessPipe,
        BindPipe,
        BootstrapColorFromNumberPipe,
        CurrencyPipe,
        DatePipe,
        DefaultPipe,
        DomainPipe,
        EmptyResultPipe,
        FromOptionsPipe,
        GetContractCurrencyCodePipe,
        GetIconPipe,
        HighlightSearchPipe,
        HumanFileSizePipe,
        IsJsonObjectPipe,
        JsonTextPipe,
        NestedPropertyPipe,
        ParseIriPipe,
        SelectModelOptionsPipe,
        ShortTextPipe,
        SplitTextPipe,
        TextColorPipe,
        TimeLoggedPipe,
        ToTimePipe,
        TypeofPipe,
        UniqueNamePipe,
        UnsafePipe,
        WordVariationPipe
    ],
    providers: [
        KeyValuePipe,
        PercentPipe
    ]
})
export class AppPipesModule { }
