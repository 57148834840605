import {Injector} from '@angular/core';
import {ITaskVisibility} from 'src/modules/tasks/task-view/task-visibility/task-visibility.component';
import {AccessPipe} from 'src/pipes/access.pipe';
import {IRestObject} from 'src/modules/rest/objects';
import {Utils} from 'src/services/utils';
import {AbstractDeleteOperation} from 'view-modules/operations/abstract/abstract-delete.operation';
import {TimeTrackerService} from 'src/modules/time-tracker/time-tracker.service';
import {Observable} from 'rxjs';
import {
    ConfirmModalService,
    IConfirmModalOptions
} from 'src/modules/global-components/confirm-modal/confirm-modal.service';
import {map, tap} from 'rxjs/operators';
import {RestClient} from 'src/modules/rest/rest-client.service';
import {
    ITask,
    ITaskActionParameterDefinition,
    ITaskChangeCompletionInputDto,
    ITaskInputDto,
    ITaskListOutputDto,
    ITaskOutputDto, TaskProcessingModelDatesRelation,
    TaskProcessingModelStateRelation,
    TaskVisibilityType,
    TaskVisibilityTypeOptions
} from '_types/rest';
import {TIME_TRACKER_CHANNEL} from 'src/modules/time-tracker/interfaces';
import {StateService} from '@uirouter/core';
import {AbstractOperationDefinition} from 'src/modules/operations/interfaces';
import {UnlinkSubtaskOperation} from 'view-modules/operations/tasks/unlink-subtask.operation';
import {
    AbstractPauseEntityTracker,
    AbstractStartEntityTracker,
    AbstractStopEntityTracker
} from 'view-modules/operations/time-tracker/abstract-time-tracker-entity.operation';
import {IOperationResult} from 'src/modules/operations/interfaces';
import {AppTask, boundTask} from 'src/modules/tasks/task-view/task-view.component';
import {
    ITaskPermission,
    permissionToAdd
} from 'src/modules/tasks/task-view/task-permission-to-add/task-permission-to-add.component';
import {ITaskViewServiceTask, TaskViewService} from 'src/modules/tasks/task-view/task-view.service';


export class TaskAddOperation extends AbstractOperationDefinition<'tasks/list', ITaskListOutputDto[]> {
    readonly endpoint = 'tasks/list';
    readonly name = 'post';
    lang = 'TASKS_ADD_TASK';
    icon = 'fa-plus';

    invoke(
        context: ITaskListOutputDto[],
        injector: Injector
    ): void {
        injector.get(StateService).go('.', {task: 'new'});
    }
}

interface ITaskAddWithInputOperationContext {
    accessObject: ITaskListOutputDto[],
    taskViewServiceTask: ITaskViewServiceTask,
}

export class TaskAddWithInputOperation
    extends AbstractOperationDefinition<'tasks/list', ITaskAddWithInputOperationContext> {

    readonly endpoint = 'tasks/list';
    readonly name = 'post';
    lang = 'TASKS_ADD_TASK';
    icon = 'fa-plus';

    invoke(
        context: ITaskAddWithInputOperationContext,
        injector: Injector
    ): void {
        const taskViewService = injector.get(TaskViewService);
        taskViewService.addNewTask(context.taskViewServiceTask);
    }

    access(context: ITaskAddWithInputOperationContext): boolean {
        if (
            RestClient.isRestObject(context.accessObject)
            || RestClient.isRestCollection(context.accessObject)
        ) {
            return context.accessObject.access(this.name);
        }

        return false;
    }
}

export class DeleteTask extends AbstractDeleteOperation<'tasks/list'> {
    readonly endpoint = 'tasks/list';
    confirmMessage = 'TASKS_DELETE_TASK_CONFIRM';
    lang = 'DELETE'

    confirmOptions = {
        langYes: 'TASKS_DELETE_TASK_CONFIRM_LANG_YES',
        primaryBtn: 'danger',
        text: 'ACTION_CANNOT_BE_UNDONE'
    } as IConfirmModalOptions

    access = (context: IRestObject<'tasks/list'>, injector: Injector): boolean => {
        const timeTrackerService = injector.get(TimeTrackerService);
        return timeTrackerService.activeEntry?.iri !== context['@id']
            && new AccessPipe().transform(context, 'delete');
    };

    invoke(
        context: IRestObject<'tasks/list', ITaskListOutputDto | ITask>,
        injector: Injector
    ): Observable<IOperationResult> {

        return injector.get(ConfirmModalService).confirmOperation(
            this.confirmMessage,
            () => {
                return context.delete()
                    .pipe(
                        tap(() => {
                            injector.get(RestClient).savedToast();
                            injector.get(TIME_TRACKER_CHANNEL)
                                .publish('delete-task', {
                                    iri: context['@id']
                                });
                        })
                    );
            },
            this.confirmOptions
        );
    }
}

class GoToTask extends AbstractOperationDefinition<string, {id?: number}> {
    readonly endpoint = 'tasks';
    readonly name = 'get';
    lang = 'VIEW';
    icon = 'fa-eye';

    invoke(
        context: ITaskOutputDto,
        injector: Injector
    ): void {
        const state = injector.get(StateService);
        if (context.id) {
            state.go('.', {task: context.id});
        }
    }

    access(): boolean {
        return true;
    }
}

export class TaskDateList extends AbstractOperationDefinition<'tasks/item', AppTask> {
    readonly endpoint = 'tasks/item';
    readonly name = 'task_date_list';
    lang = 'TASK_DATES';
    icon = 'fa-user-clock';

    component = (): Promise<unknown> => import('./task-date-list/task-date-list.component');

    access(context: AppTask): boolean {
        return context?.taskProcessingModel?.datesRelation
            === TaskProcessingModelDatesRelation.TASK_PROCESSING_MODEL_RELATION_TO_TASK_USER;
    }
}

abstract class AbstractTaskArchivization extends AbstractOperationDefinition<'tasks/list', ITaskListOutputDto> {
    readonly endpoint = 'tasks/list';

    invoke(
        context: ITaskListOutputDto,
        injector: Injector
    ): Observable<IOperationResult> {
        const restClient = injector.get(RestClient);

        return restClient.endpoint(`tasks/${this.name}`)
            .update(context.id, {})
            .pipe(
                map(() => {
                    return {success: true};
                })
            );
    }
}

export class ArchiveTask extends AbstractTaskArchivization {
    readonly name = 'archive';
    lang = 'ARCHIVE';
    icon = 'fa-archive';
}


export class RestoreTask extends AbstractTaskArchivization {
    readonly name = 'restore';
    lang = 'RESTORE';
    icon = 'fa-window-restore';
}

abstract class AbstractChangePermissionToAdd extends AbstractOperationDefinition<string, ITask | AppTask> {
    readonly endpoint = 'tasks';
    abstract changeToPermission: ITaskPermission;

    invoke(
        context: ITask | AppTask,
        injector: Injector
    ): Observable<IOperationResult> {
        const restClient = injector.get(RestClient),
            endpoint = restClient.endpoint<'tasks', Partial<ITaskInputDto>>('tasks');

        return endpoint.update(context.id, {permissionToAdd: this.changeToPermission})
            .pipe(
                map(() => {
                    return {success: true};
                })
            );
    }

    access(): boolean {
        return true;
    }
}

export class MakeTaskOpenToAssignees extends AbstractChangePermissionToAdd {
    readonly name = 'make_open_to_assignees';
    lang = 'OPEN';
    icon = 'fa-unlock';
    changeToPermission = permissionToAdd.PUBLIC;
}

export class MakeTaskClosedToAssignees extends AbstractChangePermissionToAdd {
    readonly name = 'make_closed_to_assignees';
    lang = 'CLOSE';
    icon = 'fa-lock';
    changeToPermission = permissionToAdd.PRIVATE;
}

abstract class AbstractChangeVisibilityTask extends AbstractOperationDefinition<string, ITask | AppTask> {
    readonly endpoint = 'tasks';

    abstract visibilityType: ITaskVisibility;

    invoke(
        context: ITask | AppTask,
        injector: Injector
    ): Observable<IOperationResult> {
        const restClient = injector.get(RestClient),
            endpoint = restClient.endpoint<'tasks', Partial<ITaskInputDto>>('tasks');

        return endpoint.update(context.id, {visibilityType: this.visibilityType})
            .pipe(
                map(() => {
                    return {success: true};
                })
            );
    }

    access(): boolean {
        return true;
    }
}

export class MakePublicTask extends AbstractChangeVisibilityTask {
    readonly name = 'make_public_task';
    lang = Utils.getFromOption([...TaskVisibilityTypeOptions], TaskVisibilityType.VISIBILITY_TYPE_PUBLIC);
    icon = 'fa-users';
    visibilityType = TaskVisibilityType.VISIBILITY_TYPE_PUBLIC;
}

export class MakePrivateTask extends AbstractChangeVisibilityTask {
    readonly name = 'make_private_task';
    lang = Utils.getFromOption([...TaskVisibilityTypeOptions], TaskVisibilityType.VISIBILITY_TYPE_PRIVATE);
    icon = 'fa-user-lock';
    visibilityType = TaskVisibilityType.VISIBILITY_TYPE_PRIVATE;
}

// @ts-expect-error: force type - DTOs fields are always optional
export class StartTimeTrackerTask extends AbstractStartEntityTracker<ITaskListOutputDto> {
    readonly endpoint = 'tasks/list';
}

// @ts-expect-error: force type - DTOs fields are always optional
export class PauseTimeTrackerTask extends AbstractPauseEntityTracker<ITaskListOutputDto> {
    readonly endpoint = 'tasks/list';
}

// @ts-expect-error: force type - DTOs fields are always optional
export class StopTimeTrackerTask extends AbstractStopEntityTracker<ITaskListOutputDto> {
    readonly endpoint = 'tasks/list';
}

export class ChangeCompletionOperation extends AbstractOperationDefinition<string, AppTask> {
    readonly endpoint = 'tasks/change_task_completion';
    readonly name = 'put';
    lang = 'CHANGE_COMPLETION';
    icon = 'fa-users-cog';

    component = (): Promise<unknown> => import('./change-completion/change-completion.component');

    access(task: IRestObject<'tasks/item', boundTask>): boolean {
        const {stateRelation} = task.taskProcessingModel;
        return stateRelation === TaskProcessingModelStateRelation.TASK_PROCESSING_MODEL_RELATION_TO_TASK_USER
            && task.access('change_task_completion');
    }
}

export class SetNextActionParameters extends AbstractOperationDefinition<string, ITaskActionParameterDefinition[]> {
    readonly endpoint = 'tasks/set_action';
    readonly name = 'set_next_action_parameters';
    lang = 'SET_NEXT_ACTION_PARAMETERS';
    icon = 'fa-user-clock';
    modalHeaderDisabled = true;

    component = (): Promise<unknown> =>
        import(
            './set-next-action-parameters/set-next-action-parameters.component'
        );

    access(): boolean {
        return true;
    }
}

// TODO - after 4593 BE task
export const completeReasonsOptions = [...[{value: 1, label: 'REASON_ABSENCE'}, {value: 2, label: 'REASON_OTHER'}]];
export const completeReasons = {SKIPPED_ITERATIONS_REASON_ABSENCE: 1, SKIPPED_ITERATIONS_REASON_OTHER: 2} as const;

export interface ISkipOrCompleteContext extends ITaskChangeCompletionInputDto {
    iterationsToSkip: number;
}

export class TaskSkipOrComplete extends AbstractOperationDefinition<'tasks/item', ISkipOrCompleteContext> {
    readonly endpoint = 'tasks/item';
    readonly name = 'skip_or_complete';
    lang = 'TASK_SKIP_OR_COMPLETE_TITLE';
    icon = 'fa-solid fa-forward';

    component = (): Promise<unknown> => import('./task-skip-or-complete-form/task-skip-or-complete-form.component');

    access(): boolean {
        return true;
    }
}

export class CloseAndNotReopenOperation
    extends AbstractOperationDefinition<'tasks/close_and_not_reopen', ITask | AppTask> {

    readonly endpoint = 'tasks/close_and_not_reopen';
    readonly name = 'close_and_not_reopen';
    lang = 'TASK_CLOSE_AND_NOT_REOPEN';
    icon = 'fa-times-circle';

    invoke(
        context: ITask | AppTask,
        injector: Injector
    ): Observable<IOperationResult> {
        const restClient = injector.get(RestClient);

        return restClient.endpoint('tasks/close_and_not_reopen')
            .update(context.id, {})
            .pipe(
                map(() => {
                    return {success: true};
                })
            );
    }
}

export const tasksOperations = [
    TaskAddOperation,
    TaskAddWithInputOperation,
    GoToTask,
    DeleteTask,
    MakeTaskOpenToAssignees,
    MakeTaskClosedToAssignees,
    MakePrivateTask,
    MakePublicTask,
    StartTimeTrackerTask,
    PauseTimeTrackerTask,
    StopTimeTrackerTask,
    ChangeCompletionOperation,
    TaskDateList,
    ArchiveTask,
    RestoreTask,
    SetNextActionParameters,
    TaskSkipOrComplete,
    UnlinkSubtaskOperation,
    CloseAndNotReopenOperation
] as const;
