import {Inject, Injectable} from '@angular/core';
import {WINDOW} from 'app-custom-providers';

export type rwdBreakpoint = 'xl' | 'lg' | 'md' | 'sm' | 'xs';

@Injectable({
    providedIn: 'root'
})
export class ScreenSizeService {
    // as in bootstrap $grid-breakpoints
    private breakpoints: Record<rwdBreakpoint, number> = {
        xl: 1200,
        lg: 992,
        md: 768,
        sm: 576,
        xs: 0
    };
    constructor(
        @Inject(WINDOW) private window: Window
    ) {
    }

    /**
     * Checks if window width is more or equal than given breakpoint
     */
    is(breakpoint: rwdBreakpoint): boolean {
        if (!(breakpoint in this.breakpoints)) {
            return false;
        }

        return this.window.innerWidth >= (this.breakpoints)[breakpoint];
    }

    /**
     * Gets current window breakpoint
     */
    getBreakpoint(): string {
        for (const breakpoint in this.breakpoints) {
            if (
                Object.prototype.hasOwnProperty.call(this.breakpoints, breakpoint)
                && this.window.innerWidth >= (this.breakpoints)[breakpoint]
            ) {
                return breakpoint;
            }
        }
        return 'xs';
    }
}
