/* eslint-disable max-len */
/**
 * This file has been auto generated. Do not edit.
 */
import {ITaskTemplate} from '_types/rest/Entity/IRestTaskTemplate';
import {IRestTaskTemplate} from '_types/rest/Entity/IRestTaskTemplate';
import {IContract} from '_types/rest/Entity/IRestContract';
import {IRestContract} from '_types/rest/Entity/IRestContract';
import {ICompany} from '_types/rest/Entity/IRestCompany';
import {IRestCompany} from '_types/rest/Entity/IRestCompany';
import {IPartner} from '_types/rest/Entity/IRestPartner';
import {IRestPartner} from '_types/rest/Entity/IRestPartner';
import {IBaseRestEntity} from '_types/rest/IBaseRestEntity';
import {IRestObject} from 'core/rest/rest-object.service';

export const TaskTemplateProcessingOverrideType = {
    TASK_TEMPLATE_PROCESSING_OVERRIDE_TYPE_CONTRACT: 1,
    TASK_TEMPLATE_PROCESSING_OVERRIDE_TYPE_COMPANY: 2,
    TASK_TEMPLATE_PROCESSING_OVERRIDE_TYPE_PARTNER: 3
} as const;

export const TaskTemplateProcessingOverrideTypeOptions = [
    {
        value: TaskTemplateProcessingOverrideType.TASK_TEMPLATE_PROCESSING_OVERRIDE_TYPE_CONTRACT,
        label: 'TASK_TEMPLATE_PROCESSING_OVERRIDE_TYPE_CONTRACT',
    },
    {
        value: TaskTemplateProcessingOverrideType.TASK_TEMPLATE_PROCESSING_OVERRIDE_TYPE_COMPANY,
        label: 'TASK_TEMPLATE_PROCESSING_OVERRIDE_TYPE_COMPANY',
    },
    {
        value: TaskTemplateProcessingOverrideType.TASK_TEMPLATE_PROCESSING_OVERRIDE_TYPE_PARTNER,
        label: 'TASK_TEMPLATE_PROCESSING_OVERRIDE_TYPE_PARTNER',
    }
] as const;

export interface ITaskTemplateProcessingOverrideConst {
    type: [{id: 1, value: 'TASK_TEMPLATE_PROCESSING_OVERRIDE_TYPE_CONTRACT'}, {id: 2, value: 'TASK_TEMPLATE_PROCESSING_OVERRIDE_TYPE_COMPANY'}, {id: 3, value: 'TASK_TEMPLATE_PROCESSING_OVERRIDE_TYPE_PARTNER'}]
}

export interface ITaskTemplateProcessingOverride extends IBaseRestEntity {
    id?: number,
    taskTemplate?: ITaskTemplate | string,
    baseTaskTemplate?: ITaskTemplate | string,
    /**
     * @default 1
     */
    type: typeof TaskTemplateProcessingOverrideType[keyof typeof TaskTemplateProcessingOverrideType],
    contract?: IContract | string | null,
    company?: ICompany | string | null,
    partner?: IPartner | string | null,
    /**
     * @description Record status
     *  0 = deleted
     *  1 = active.
     * @default 1
     */
    status?: number,
    /**
     * @description Creation timestamp.
     * @default CURRENT_TIMESTAMP
     * @format date-time
     */
    created?: string,
    /**
     * @description Last modification timestamp.
     * @format date-time
     */
    modified?: string | null
}

/** @deprecated */
export interface IRestTaskTemplateProcessingOverride extends ITaskTemplateProcessingOverride, IRestObject {
    taskTemplate?: IRestTaskTemplate | string,
    baseTaskTemplate?: IRestTaskTemplate | string,
    contract?: IRestContract | string | null,
    company?: IRestCompany | string | null,
    partner?: IRestPartner | string | null,
}
