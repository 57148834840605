import {Component, ElementRef, OnDestroy, OnInit} from '@angular/core';
import {SortableItemDirective} from 'src/modules/sortable/sortable-item.directive';

@Component({
    selector: 'sortable-drag-handle',
    styles: ['.fas { cursor: grab}'],
    template: '<span class="fas fa-grip-lines" aria-hidden="true"></span>'
})
export class SortableDragHandleComponent implements OnInit, OnDestroy {

    constructor(
        private elRef: ElementRef,
        private sortableItem: SortableItemDirective
    ) {
    }

    ngOnInit(): void {
        this.sortableItem.setHandle(this.elRef.nativeElement);
    }

    ngOnDestroy(): void {
        this.sortableItem.unsetHandle();
    }
}
