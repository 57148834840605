<div class="toast-container toast-bottom-left">
    <ng-container *ngFor="let t of toasts">
        <div class="toast"
             @showHideToasts
             *ngIf="t.visible"
             [ngClass]="'toast-' + t.type">
                <a class="ml-2 mb-1 close" (click)="t.hide()">
                    <i class="fa fa-times"></i>
                </a>
                <div class="toast-title">{{t.title}}</div>
                <div class="toast-message" [innerHTML]="t.text"></div>
                <a class="toast-action"
                   href="javascript:"
                   *ngIf="t.action"
                   (click)="t.action(); t.hide()">
                    {{t.actionText}}
                </a>
                <div class="toast-progress" [ngStyle]="{width: t.progress + '%'}"></div>
        </div>
        <div class="clearfix" @showHideToasts></div>
    </ng-container>
</div>
