<div class="modal fade modal-task" [(bsModal)]="openModal">
    <div class="modal-dialog modal-xl" *ngIf="openModal">
        <div class="modal-content">
            <alert *ngIf="task?.id && !(taskRest | accessTask: 'full_access')"
                   type="warning"
                   icon="ban"
                   class="col mt-3">
                {{'TASK_NO_FULL_ACCESS_WARNING' | translate}}
            </alert>

            <form class="form"
                  id="taskForm"
                  *ngIf="task && (task?.id || taskOperation); else loadingTemplate"
                  [noLegend]="true"
                  (ngSubmit)="taskOperation ? taskSave() : undefined"
                  [disableAbandonWarning]="true">
                <div class="modal-header">
                    <h5 class="modal-title">
                        {{ (task?.id ? 'TASKS_TASK_DETAILS' : 'TASKS_ADD_TASK') | translate | uppercase }}
                    </h5>

                    <button [attr.aria-label]="'CLOSE'| translate"
                            class="close"
                            dismiss="modal"
                            (click)="close()"
                            type="button">
                        <span class="fas fa-times fa-sm" aria-hidden="true"></span>
                    </button>
                </div>

                <div class="modal-body">
                    <div class="row p-2">
                        <div class="col-12 col-xl-7">
                            <div class="task-container p-1">
                                <!-- Task header section -->
                                <div class="card-body pt-0 pb-0 mb-1">
                                    <div class="row">
                                        <div class="col">
                                            <div class="row align-items-center">
                                                <!-- Title, date -->
                                                <div class="col pl-0">
                                                    <div class="row task-title">
                                                        <div class="col col-auto pr-0" *ngIf="task.parentTask">
                                                            <a href="javascript:" class="text-lighter-gray"
                                                               (mouseover)="currentParentNameLength = task.parentTask.name.length"
                                                               (mouseleave)="currentParentNameLength = BASE_PARENT_NAME_LENGTH"
                                                               [uiSref]="stateService.current.name"
                                                               [uiParams]="{ task: task.parentTask.id }">
                                                                {{ task.parentTask.name | shortText: currentParentNameLength }}
                                                            </a>
                                                            <span class="pl-2">
                                                            /
                                                        </span>
                                                        </div>
                                                        <div class="col pr-0" [ngClass]="{'task-content-col pl-2': task.id, 'pl-1': task.task}">
                                                            <dynamic-field name="name"
                                                                           class="task-dynamic-field-name"
                                                                           [(ngModel)]="task.name"
                                                                           (ngModelChange)="taskNameChangeSubject.next()"
                                                                           [fieldConfig]="{
                                                                               type: 'string',
                                                                               placeholder: 'COMMON_TASK_TITLE',
                                                                               validators: {
                                                                                   required: true,
                                                                                   maxlength: 512
                                                                               }
                                                                           }"
                                                                           [title]="!(taskRest | accessTask: 'put_item': 'name') ? ('NO_RIGHTS_TO_EDIT' | translate) : ''"
                                                                           [disabled]="!(taskRest | accessTask: 'put_item': 'name')">
                                                            </dynamic-field>
                                                        </div>
                                                    </div>

                                                    <div class="row">
                                                        <div class="col d-flex">
                                                            <p class="small blue-grey mb-2 mr-2 mt-1" *ngIf="task?.id">
                                                                <i class="far fa-clock"></i>
                                                                <span title="{{'COMMON_TASK_CREATED' | translate }}">
                                                                {{task.created | date }}
                                                            </span>
                                                            </p>

                                                            <ng-container *ngIf="(taskRest | accessTask: 'full_access') && (taskRest | accessTask: 'put_item')">
                                                                <task-permission-to-add class="d-block mx-1">
                                                                </task-permission-to-add>

                                                                <object-notification-settings [notificationObject]="task"
                                                                                              property="taskNoteNotificationSetting"
                                                                                              [updateObjectNotificationCb]="updateObjectNotificationSettingCb"
                                                                                              class="d-block mx-1">
                                                                </object-notification-settings>

                                                                <task-visibility class="d-block mx-1">
                                                                </task-visibility>

                                                                <div class="mx-1">
                                                                    <operation *ngFor="let operation of pinOperations"
                                                                               [operationClass]="operation"
                                                                               [context]="task"
                                                                               (operationSuccess)="handlePinOperationSuccess($event)"
                                                                               [loadingIcon]="true">
                                                                    </operation>
                                                                </div>
                                                            </ng-container>

                                                            <div class="mx-1">
                                                                <operation *ngFor="let operation of archiveOperations"
                                                                           [operationClass]="operation"
                                                                           [context]="task"
                                                                           (operationSuccess)="handleArchiveSuccess($event)"
                                                                           [loadingIcon]="true">
                                                                </operation>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <!-- Time tracker section -->
                                <card-collapse *ngIf="task?.id
                                && (TIMETRACKER_FEATURE_PRIVILEGE | userPrivileges)
                                && (taskRest | accessTask: 'full_access')"
                                               mode="body"
                                               headerClass="task-card-header task-card-border-top"
                                               contentClass="task-card-content"
                                               arrowClass="task-card-arrow"
                                               stateName="taskView"
                                               collapseName="taskTimeTracker">
                                    <div cardTitle>{{'COMMON_TASK_SECTION_TIMETRACKER' | translate}}</div>
                                    <div cardContent>
                                        <div class="row">
                                            <div class="col">
                                                <time-tracker [target]="task"></time-tracker>
                                            </div>
                                        </div>
                                    </div>
                                </card-collapse>

                                <!-- Actions section -->
                                <task-actions *ngIf="task?.id && (taskRest | accessTask: 'full_access')"
                                              [task]="task"
                                              [taskRest]="taskRest">
                                </task-actions>

                                <!-- Descriptions section -->
                                <card-collapse *ngIf="task && (taskRest | accessTask: 'full_access')"
                                               mode="body"
                                               headerClass="task-card-header task-card-border-top"
                                               contentClass="task-card-content"
                                               arrowClass="task-card-arrow"
                                               stateName="taskView"
                                               collapseName="taskDescription">
                                    <div cardTitle>{{'COMMON_TASK_SECTION_DESCRIPTION' | translate}}</div>
                                    <div cardContent>
                                        <task-description [task]="task"
                                                          [taskRest]="taskRest">
                                        </task-description>
                                    </div>
                                </card-collapse>

                                <!-- Acceptance criteria section -->
                                <task-acceptance-criteria *ngIf="(taskRest | accessTask: 'full_access')"
                                                          [task]="task">
                                </task-acceptance-criteria>

                                <!-- Activity section -->
                                <ng-container *ngIf="task?.id && (taskRest | accessTask: 'full_access')">
                                    <card-collapse mode="body"
                                                   headerClass="task-card-header task-card-border-top"
                                                   contentClass="task-card-content"
                                                   arrowClass="task-card-arrow"
                                                   stateName="taskView"
                                                   collapseName="taskStory">
                                        <div cardTitle>{{'COMMON_TASK_SECTION_ACTIVITY' | translate}}</div>
                                        <div cardContent>
                                            <task-story [task]="task">
                                            </task-story>
                                        </div>
                                    </card-collapse>


                                    <div class="pl-2" *ngIf="taskRest | accessTask: 'put_item': 'taskNotes'">
                                        <!-- Note Edit -->
                                        <task-note-edit [task]="task" [taskRest]="taskRest">
                                        </task-note-edit>
                                    </div>
                                </ng-container>

                            </div>
                        </div>
                        <div class="col-12 col-xl-5">
                            <div class="task-container">
                                <!-- Details section-->
                                <card-collapse mode="body"
                                               headerClass="task-card-header"
                                               contentClass="task-card-content"
                                               arrowClass="task-card-arrow"
                                               stateName="taskView"
                                               collapseName="taskDetails">
                                    <div cardTitle>{{'COMMON_TASK_SECTION_DETAILS' | translate}}</div>
                                    <div cardContent>
                                        <task-details [task]="task" [taskRest]="taskRest">
                                        </task-details>
                                    </div>
                                </card-collapse>

                                <!-- Additional options section-->
                                <card-collapse *ngIf="taskOperation || (taskRest | accessTask: 'full_access')"
                                               mode="body"
                                               headerClass="task-card-header task-card-border-top"
                                               contentClass="task-card-content"
                                               arrowClass="task-card-arrow"
                                               stateName="taskView"
                                               collapseName="taskAdditionalOptions">
                                    <div cardTitle>{{'COMMON_TASK_SECTION_ADDITIONAL_OPTIONS' | translate}}</div>
                                    <div cardContent>
                                        <ng-container *ngIf="taskOperation || (taskRest | accessTask: 'full_access')">
                                            <task-weekly [task]="task"
                                                         [taskRest]="taskRest">
                                            </task-weekly>

                                            <task-recurrency [task]="task"
                                                             [taskRest]="taskRest">
                                            </task-recurrency>
                                        </ng-container>
                                    </div>
                                </card-collapse>

                                <!-- Parent task section -->
                                <task-parent-task *ngIf="taskOperation || (taskRest | accessTask: 'full_access')"
                                                  [task]="task"
                                                  [taskRest]="taskRest">
                                </task-parent-task>

                                <!-- Subtask section -->
                                <task-subtasks *ngIf="task?.id && (taskRest | accessTask: 'full_access')"
                                               [task]="task"
                                               [taskRest]="taskRest">
                                </task-subtasks>

                                <!-- Task files section -->
                                <task-files [task]="task" [taskRest]="taskRest"
                                            [files]="taskInputFiles">
                                </task-files>

                                <!-- Author and Assignee section -->
                                <card-collapse *ngIf="taskOperation || (task?.id && !(task.taskUsers | typeof: 'undefined'))"
                                               mode="body"
                                               headerClass="task-card-header task-card-border-top"
                                               contentClass="task-card-content"
                                               arrowClass="task-card-arrow"
                                               stateName="taskView"
                                               collapseName="taskUsers">
                                    <div cardTitle>{{'COMMON_TASK_SECTION_ASSIGNEE' | translate}}</div>
                                    <div cardContent>
                                        <task-users [task]="task" [taskRest]="taskRest">
                                        </task-users>
                                    </div>
                                </card-collapse>

                                <!-- Custom fields section -->
                                <card-collapse *ngIf="task?.id && task?.taskCustomFields?.length && (taskRest | accessTask: 'full_access')"
                                               mode="body"
                                               headerClass="task-card-header task-card-border-top"
                                               contentClass="task-card-content"
                                               arrowClass="task-card-arrow"
                                               stateName="taskView"
                                               collapseName="taskCustomFields">
                                    <div cardTitle>{{'TASKS_TASK_SECTION_CUSTOM_FIELDS' | translate}}</div>
                                    <div cardContent>
                                        <task-custom-fields [task]="task" [taskRest]="taskRest" [taskCustomFields]="task.taskCustomFields">
                                        </task-custom-fields>
                                    </div>
                                </card-collapse>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="modal-footer" *ngIf="taskOperation; else mobileFooter">
                    <button class="btn" dismiss type="button" (click)="close()">
                        {{ 'CANCEL' | translate }}
                    </button>

                    <button class="btn btn-primary text-ri" type="submit" [disabled]="creatingTask">
                        <i class="fa fa-circle-notch fa-spin" *ngIf="creatingTask"></i>
                        {{ 'SAVE' | translate }}
                    </button>
                </div>

                <ng-template #mobileFooter>
                    <div class="modal-footer d-flex d-xl-none">
                        <button class="btn btn-primary" dismiss type="button" (click)="close()">
                            {{ 'CLOSE' | translate }}
                        </button>
                    </div>
                </ng-template>
            </form>

            <ng-template #loadingTemplate>
                <div class="my-4">
                    <loader size="medium" [center]="true"></loader>
                </div>
            </ng-template>
        </div>
    </div>
</div>
