import {DOMOutputSpec, MarkSpec, NodeSpec, Schema} from 'prosemirror-model';
import {bulletList, listItem, orderedList} from 'prosemirror-schema-list';
import {MentionBlockView} from 'src/modules/wysiwyg-editor/custom-nodes/mention-block';
import {COLORS} from 'src/modules/colors/colors.constant';

const nodes: Record<string, NodeSpec> = {
    doc: {
        content: 'block+'
    },
    paragraph: {
        content: 'inline*',
        group: 'block',
        parseDOM: [{tag: 'p'}],
        toDOM() { return ['p', 0] as DOMOutputSpec; }
    },
    text: {
        group: 'inline'
    },
    ordered_list: {...orderedList, content: 'list_item+', group: 'block'},
    bullet_list: {...bulletList, content: 'list_item+', group: 'block'},
    list_item: {...listItem, content: 'paragraph block*'},
    code_block: {
        group: 'block',
        attrs: {
            content: {default: ''},
            text: {default: ''},
        },
        parseDOM: [
            {
                tag: 'pre',
                getAttrs: (dom: HTMLElement & {getAttribute: (a: string) => string}) => {
                    return {
                        content: dom.innerHTML,
                        text: dom.innerText,
                    };
                }
            },
        ],
        toDOM(node) {
            const dom = document.createElement('pre');
            dom.innerHTML = node.attrs.content;
            return {dom};
        }
    },
    mention: {
        group: 'inline',
        inline: true,
        attrs: {
            content: {default: ''},
            user: {default: null},
            disabledUser: {default: false},
        },
        parseDOM: [
            {
                tag: 'span',
                getAttrs: (dom: HTMLElement & {getAttribute: (a: string) => string}) => {
                    const user = dom.getAttribute('data-user');
                    if (!user) {
                        return false;
                    }

                    return {
                        content: dom.innerHTML,
                        user: Number(user),
                        disabledUser: dom.getAttribute('data-disabled-user'),
                    };
                }
            },
        ],
        toDOM(node) {
            return {dom: MentionBlockView.createMentionDOM(node)};
        }
    },
    hard_break: {
        inline: true,
        group: 'inline',
        selectable: false,
        parseDOM: [{tag: 'br'}],
        toDOM() {
            return ['br'];
        }
    }
};

const marks: Record<string, MarkSpec> = {
    strong: {
        parseDOM: [
            {tag: 'strong'},
            // This works around a Google Docs misbehavior where
            // pasted content will be inexplicably wrapped in `<b>`
            // tags with a font-weight normal.
            {
                tag: 'b',
                getAttrs: (node: HTMLElement) => {
                    return node.style.fontWeight !== 'normal' && null;
                }
            },
            {
                style: 'font-weight',
                getAttrs: (value: string) => {
                    return /^(bold(er)?|[5-9]\d{2,})$/.test(value) && null;
                }
            }
        ],
        toDOM() {
            return ['strong', 0];
        }
    },
    color: {
        attrs: {
            color: {
                default: null
            }
        },
        parseDOM: [
            {
                tag: 'span',
                getAttrs: (node: HTMLElement) => {
                    const nodeTextColor = COLORS.find((color) => {
                        return node.classList.contains(color);
                    });

                    if (!nodeTextColor) {
                        return false;
                    }

                    return {
                        color: nodeTextColor
                    };
                }
            }
        ],
        toDOM(node) {
            return [
                'span',
                {
                    class: node.attrs.color
                },
                0
            ];
        }
    },
    em: {
        parseDOM: [
            {tag: 'i'},
            {tag: 'em'},
            {style: 'font-style=italic'}
        ],
        toDOM() {
            return ['em', 0];
        }
    },
    underline: {
        parseDOM: [
            {tag: 'u'},
        ],
        toDOM() {
            return ['u', 0];
        }
    },
    link: {
        attrs: {
            href: {},
            target: {default: '_blank'}
        },
        inclusive: false,
        parseDOM: [
            {
                tag: 'a[href]',
                getAttrs(dom: HTMLElement) {
                    return {href: dom.getAttribute('href'), target: dom.getAttribute('target')};
                }
            }
        ],
        toDOM(node) {
            const {href, target} = node.attrs;
            return ['a', {href, target}, 0];
        }
    },
};

export const AppEditorSchema = new Schema({nodes, marks});
