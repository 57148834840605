<div class="input-group">
    <div class="input-group-prepend">
        <button #subButton
                type="button"
                class="btn btn-outline-primary"
                [disabled]="numberControl.disabled">
            <i class="fas fa-minus fa-fw"></i>
        </button>
    </div>
    <number-input [fieldConfig]="numberInputFieldConfig" [formControl]="numberControl"></number-input>
    <div class="input-group-append" *ngIf="_fieldConfig.numberLabel">
        <button class="btn btn-secondary disabled number-label px-2" [disabled]="true">
            {{_fieldConfig.numberLabel}}
        </button>
    </div>
    <div class="input-group-append">
        <button #addButton
                type="button"
                class="btn btn-outline-primary rounded-right"
                [disabled]="numberControl.disabled">
            <i class="fas fa-plus fa-fw"></i>
        </button>
    </div>
</div>
