<input #fileInput
       class="file-input d-none"
       type="file"
       (change)="uploadFiles($event)"
       multiple
>
<ng-template #transclude>
    <ng-content></ng-content>
</ng-template>
<div *ngIf="!settings.hide">
    <div *ngIf="!settings.button && !settings.transclude && !settings.transcludeButton"
         [ngClass]="dragover && 'drop-zone-over'"
         (click)="fileInput.click()"
         class="drop-zone p-2">
        <div class="container">
            <div class="row pt-2 pb-2 justify-content-center align-items-center">
                <div class="col-auto p-0 d-flex align-items-center">
                    <i class="pr-3" [ngClass]="settings.image ? 'fas fa-image' : 'fa fa-cloud-upload-alt'"></i>
                </div>
                <div class="col-auto p-0">
                    <span *ngIf="!settings.files">
                        {{ (settings.image ? 'COMMON_DROP_IMAGE' : 'COMMON_DROP_FILE') | translate}}
                    </span>
                    <span *ngIf="settings.files">
                        {{'COMMON_DROP_FILES' | translate}}
                    </span>
                    <a class="ml-1" href="javascript:">{{'COMMON_DROP_FILE_SELECT' | translate}}</a>
                    <span *ngIf="width && height"
                          class="drop-zone__required-image-size d-block">
                        {{'COMMON_REQUIRED_IMAGE_SIZE' | translate}} {{width}}px x {{height}}px
                    </span>
                </div>
            </div>
        </div>
    </div>
    <button class="btn btn-primary d-block"
            *ngIf="settings.button"
            type="button"
            (click)="fileInput.click()">
        <i [ngClass]="settings.image ? 'fas fa-image' : 'fa fa-cloud-upload-alt'"></i>
        {{settings.name}}
    </button>
    <div class="position-relative">
        <div class="transclude-drop-zone"
             *ngIf="settings.transclude"
             [ngClass]="dragover && 'drop-zone-over'">
            <div class="row pt-2 pb-2 justify-content-center align-items-center">
                <div class="col-auto p-0 d-flex align-items-center">
                    <i [ngClass]="settings.image ? 'fas fa-image' : 'fa fa-cloud-upload-alt'"
                       class="pr-3">
                    </i>
                </div>
                <div class="col-auto p-0">
                    <span *ngIf="!settings.files">
                        {{ settings.image ? 'COMMON_DROP_IMAGE' : 'COMMON_DROP_FILE' | translate}}
                    </span>
                    <span *ngIf="settings.files">
                        {{'COMMON_DROP_FILES' | translate}}
                    </span>
                    <a class="ml-1" href="javascript:">{{'COMMON_DROP_FILE_SELECT' | translate}}</a>
                    <span *ngIf="width && height"
                          class="drop-zone__required-image-size d-block">
                        {{'COMMON_REQUIRED_IMAGE_SIZE' | translate}} {{width}}px x {{height}}px
                    </span>
                </div>
            </div>
        </div>
        <ng-container *ngIf="settings.transclude && !settings.button">
            <ng-container *ngTemplateOutlet="transclude"></ng-container>
        </ng-container>
    </div>
</div>
<div *ngIf="settings.transcludeButton"
     (click)="fileInput.click()"
     class="d-inline-block">
    <ng-container *ngTemplateOutlet="transclude"></ng-container>
</div>
