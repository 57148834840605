import { NgModule } from '@angular/core';
import {AbsoluteToDocumentDirective} from 'src/directives/absolute-to-document/absolute-to-document.directive';
import {CopyDirective} from 'src/directives/copy/copy.directive';
import {FocusDirective} from 'src/directives/focus/focus.directive';
import {InputLoadingDirective} from 'src/directives/input-loading/input-loading.directive';
import {InsertAtCursorDirective} from 'src/directives/insert-at-cursor/insert-at-cursor.directive';
import {LowercaseDirective} from 'src/directives/lowercase/lowercase.directive';
import {NoAnimateDirective} from 'src/directives/no-animate/no-animate.directive';
import {NumberToTextDirective} from 'src/directives/number-to-text/number-to-text.directive';
import {SelectPlaceholderDirective} from 'src/directives/select/select-placeholder.directive';
import {TitleDirective} from 'src/directives/title/title.directive';
import {RowClickDirective} from 'src/directives/row-click/row-click.directive';
import {ValidateNipDirective} from 'src/directives/validate-nip/validate-nip.directive';
import {TemplateContextDirective} from 'src/directives/template-context/template-context.directive';
import {AppFormsModule} from 'src/modules/app-forms/app-forms.module';
import {CustomValidatorDirective} from 'src/directives/custom-validator/custom-validator.directive';

@NgModule({
    imports: [
        AppFormsModule
    ],
    declarations: [
        AbsoluteToDocumentDirective,
        CopyDirective,
        CustomValidatorDirective,
        FocusDirective,
        InputLoadingDirective,
        InsertAtCursorDirective,
        LowercaseDirective,
        NoAnimateDirective,
        NumberToTextDirective,
        RowClickDirective,
        SelectPlaceholderDirective,
        TemplateContextDirective,
        TitleDirective,
        ValidateNipDirective
    ],
    exports: [
        AbsoluteToDocumentDirective,
        CopyDirective,
        CustomValidatorDirective,
        FocusDirective,
        InputLoadingDirective,
        InsertAtCursorDirective,
        LowercaseDirective,
        NoAnimateDirective,
        NumberToTextDirective,
        RowClickDirective,
        SelectPlaceholderDirective,
        TemplateContextDirective,
        TitleDirective,
        ValidateNipDirective
    ]
})
export class AppDirectivesModule {
}
