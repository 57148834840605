import {AbstractDeleteOperation} from 'view-modules/operations/abstract/abstract-delete.operation';
import {IConfirmModalOptions} from 'src/modules/global-components/confirm-modal/confirm-modal.service';
import {AbstractOperationDefinition} from 'src/modules/operations/interfaces';
import {IPartner} from '_types/rest';
import {IRestCollection, IRestObject} from 'src/modules/rest/objects';
import {RestClient} from 'src/modules/rest/rest-client.service';

export interface IPartnerContactAddContext {
    collection: IRestCollection<'company_contacts'>,
    partner: IPartner
}

export interface IPartnerContactEditContext {
    contact: IRestObject<'company_contacts'>,
    partner: IPartner
}

export class PartnerContactsAddOperation
    extends AbstractOperationDefinition<string, IPartnerContactAddContext> {
    readonly endpoint = 'company_contacts';
    readonly name = 'post';
    lang = 'PARTNER_CONTACTS_ADD';
    icon = 'fa-plus';
    component = (): Promise<unknown> => import('./partner-contacts-form.component');

    access(context: IPartnerContactAddContext): boolean {
        return RestClient.isRestCollection(context.collection) && context.collection.access('post');
    }
}

export class PartnerContactsEditOperation
    extends AbstractOperationDefinition<string, IPartnerContactEditContext> {
    readonly endpoint = 'company_contacts';
    readonly name = 'put';
    lang = 'EDIT';
    icon = 'fa-edit';
    component = (): Promise<unknown> => import('./partner-contacts-form.component');

    access(context: IPartnerContactEditContext): boolean {
        return context.contact.access('put');
    }
}

export class PartnerContactsDeleteOperation extends AbstractDeleteOperation<'company_contacts'> {
    readonly endpoint = 'company_contacts';
    lang = 'DELETE';
    confirmMessage = 'DELETE_CONTACT_CONFIRM';
    confirmOptions: IConfirmModalOptions = {
        primaryBtn: 'danger',
        langYes: 'DELETE_CONTACT_CONFIRM_LANG_YES',
        text: 'ACTION_CANNOT_BE_UNDONE'
    };
}

export const partnerContactsOperations = [
    PartnerContactsDeleteOperation,
    PartnerContactsAddOperation,
    PartnerContactsEditOperation
] as const;
