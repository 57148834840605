import {
    Component,
    ElementRef,
    EventEmitter,
    Inject, Injector,
    Input, NgZone,
    OnDestroy,
    OnInit,
    Output,
    ViewChild
} from '@angular/core';
import {EditorState, Plugin} from 'prosemirror-state';
import {EditorView} from 'prosemirror-view';
import {Command} from 'prosemirror-state';
import {
    DOMSerializer,
    MarkType,
    Node as ProseMirrorNode,
    DOMParser
} from 'prosemirror-model';
import {DOCUMENT} from '@angular/common';
import {history} from 'prosemirror-history';
import {keymap} from 'prosemirror-keymap';
import {AppEditorSchema} from 'src/modules/wysiwyg-editor/configs/app-schema';
import {getKeyMap} from 'src/modules/wysiwyg-editor/configs/app-keymap';
import {
    getToolbarSections,
    IAvailableToolbarNames,
} from 'src/modules/wysiwyg-editor/configs/toolbar-items-sections';
import {IToolbarItem, IToolbarSection, IWysiwygEditorMetadata} from 'src/modules/wysiwyg-editor/interfaces';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {AppTooltips, TooltipsOpenOnMark} from 'src/modules/wysiwyg-editor/configs/app-tooltips';
import {CodeBlockView} from 'src/modules/wysiwyg-editor/custom-nodes/code-block';
import {
    AbstractUnvalidatedControlValueAccessor
} from 'src/modules/app-forms/abstract-control-value-accessors/abstract-unvalidated-control-value-accessor';
import {MentionBlockView} from 'src/modules/wysiwyg-editor/custom-nodes/mention-block';
import {getDefaultMetadata} from 'src/modules/wysiwyg-editor/configs/default-metadata';
import {mentionFactoryPlugin} from 'src/modules/wysiwyg-editor/plugins/mention-factory/mention-factory';
import {MentionsService} from 'src/modules/wysiwyg-editor/plugins/mention-factory/mentions.service';

export type IExcludePluginNames = 'mentionFactory';

@Component({
    selector: 'wysiwyg-editor',
    templateUrl: './wysiwyg-editor.component.html',
    styleUrls: ['./wysiwyg-editor.component.scss'],
    providers: AbstractUnvalidatedControlValueAccessor
        .getProviders(WysiwygEditorComponent)
        .concat(MentionsService)
})
export class WysiwygEditorComponent extends AbstractUnvalidatedControlValueAccessor implements OnInit, OnDestroy {
    @Input() toolbarItems: IAvailableToolbarNames[];
    @Input() excludeToolbarItems: IAvailableToolbarNames[];
    @Input() excludePlugins: IExcludePluginNames[] = [];
    @Input() height: number;
    @Input() viewType: 'default' | 'no-border' = 'default';
    @Output() readonly textValue = new EventEmitter<string>();
    @Output() readonly metadataValue = new EventEmitter<IWysiwygEditorMetadata>();

    @ViewChild('editor', {static: true}) editorContainer: ElementRef;

    toolbarItemSections: IToolbarSection[];
    updateView$: Observable<EditorView>;
    selectedText$: Observable<string>;
    tooltipState: Record<string, boolean> = {};
    tooltipTransactionBlock: Record<string, boolean> = {};
    appTooltips = AppTooltips;
    tooltipsOpenOnMark: Record<string, MarkType> = TooltipsOpenOnMark;
    state: EditorState;
    editorDisabled = false;

    private viewUpdatedSubject = new Subject<void>();
    viewUpdated$ = this.viewUpdatedSubject.asObservable();

    private updateViewSubject: BehaviorSubject<EditorView>;
    private selectedTextSubject: Subject<string>;
    private view: EditorView;
    private schema = AppEditorSchema;
    private availableToolbarSections: IToolbarSection[] = getToolbarSections();
    private metadata: IWysiwygEditorMetadata = getDefaultMetadata();
    private readonly emptyEditorValue = '<p></p>';
    protected isGroupCVA = true;

    constructor(
        @Inject(DOCUMENT) private readonly document: Document,
        private readonly injector: Injector,
        private readonly zone: NgZone
    ) {
        super();
    }

    ngOnInit(): void {
        this.outputMetadata();
        this.initializeEditor();
        this.computeToolbarItems();
    }

    private outputMetadata(): void {
        this.metadataValue.emit(this.metadata);
    }

    private initializeEditor(): void {
        this.state = EditorState.create({
            schema: this.schema,
            plugins: this.getPlugins()
        });

        this.zone.runOutsideAngular(() => {
            this.view = new EditorView(
                this.editorContainer.nativeElement,
                {
                    state: this.state,
                    dispatchTransaction: (transaction) => {
                        if (this.isBlockedByTooltip()) {
                            return;
                        }

                        this.state = this.state.apply(transaction);
                        this.view.updateState(this.state);

                        this.afterViewUpdate();
                    },
                    nodeViews: {
                        code_block: (node, view, getPos) => {
                            return new CodeBlockView(node, view, getPos);
                        },
                        mention: (node, view, getPos) => {
                            return new MentionBlockView(
                                node,
                                view,
                                getPos,
                                this.getMetadataSection('mentions'),
                            );
                        },
                    },
                    editable: () => {
                        return !this.editorDisabled;
                    }
                }
            );
        });

        this.updateViewSubject = new BehaviorSubject<EditorView>(this.view);
        this.updateView$ = this.updateViewSubject.asObservable();

        this.selectedTextSubject = new Subject<string>();
        this.selectedText$ = this.selectedTextSubject.asObservable();
    }

    private getPlugins(): Plugin[] {
        const plugins: Plugin[] = [
            history(),
            keymap(getKeyMap(this))
        ];

        if (!this.excludePlugins.includes('mentionFactory')) {
            const mentionFactory = mentionFactoryPlugin(
                this.injector,
                this.getMetadataSection('mentions')
            );
            plugins.splice(1, 0, mentionFactory);
        }

        return plugins;
    }

    private isBlockedByTooltip(): boolean {
        const workingTooltip = Object.entries(this.tooltipState).find(([, state]) => {
            return state;
        });
        return !!workingTooltip && this.tooltipTransactionBlock[workingTooltip[0]];
    }

    writeValue(value: string): void {
        if (!value) {
            value = this.emptyEditorValue;
        }

        this.state = EditorState.create({
            schema: this.schema,
            doc: this.createDocument(value),
            plugins: this.state.plugins,
        });
        this.view.updateState(this.state);
        this.textValue.emit(this.getContent().text);
        this.updateToolbarStatus();
    }

    private createDocument(value: string): ProseMirrorNode {
        const element = this.document.createElement('div');
        element.innerHTML = value.trim();

        return DOMParser.fromSchema(this.schema).parse(element);
    }

    private getContent(): { text: string, html: string } {
        const fragment = DOMSerializer
                .fromSchema(this.schema)
                .serializeFragment(this.state.doc.content),
            div = this.document.createElement('div');
        div.appendChild(fragment);

        return {
            html: div.innerHTML,
            text: div.innerText
        };
    }

    private afterViewUpdate(): void {
        const value = this.getContent(),
            {from, to} = this.state.selection,
            selectedText = this.state.doc.textBetween(from, to);

        this.selectedTextSubject.next(selectedText);
        this.openTooltipOnMark();
        this.updateToolbarStatus();
        this.emitCvaChange(value.html);
        this.textValue.emit(value.text);
        this.updateViewSubject.next(this.view);
        this.viewUpdatedSubject.next();
    }

    private openTooltipOnMark(): void {
        Object.entries(this.tooltipsOpenOnMark).forEach(([tooltipName, mark]) => {
            const hasMark = this.isMarkActiveInSelection(mark);
            if (!hasMark) {
                this.tooltipState[tooltipName] = false;
                return;
            }
            this.tooltipState[tooltipName] = true;
        });
    }

    private updateToolbarStatus(): void {
        this.toolbarItemSections.forEach((toolbarItems) => {
            toolbarItems.forEach((item) => {
                this.updateActiveToolbarItem(item);
                this.updateDisabledToolbarItem(item);
            });
        });
    }

    private updateActiveToolbarItem(item: IToolbarItem): void {
        if (item.tooltip) {
            item.active = this.tooltipState[item.tooltip];
            return;
        }

        if (typeof item.activeMark === 'undefined') {
            return;
        }
        item.active = this.isMarkActiveInSelection(item.activeMark);
    }

    private isMarkActiveInSelection(type: MarkType) {
        const {from, $from, to, empty} = this.state.selection;
        if (empty) {
            return !!type.isInSet(this.state.storedMarks || $from.marks());
        }
        return !!this.state.doc.rangeHasMark(from, to, type);
    }

    private updateDisabledToolbarItem(item: IToolbarItem): void {
        item.disabled = this.editorDisabled;

        if (typeof item.disabledCallback === 'function') {
            item.disabled = item.disabledCallback(this.state) || this.editorDisabled;
        }
    }

    private emitCvaChange(value: string): void {
        if (value === this.emptyEditorValue) {
            value = '';
        }
        this._onChange(value);
        this.markAsTouched();
    }

    private computeToolbarItems(): void {
        this.toolbarItemSections = this.parseToolbarDefinitionToItems()
            .filter((section) => {
                return section.length;
            });
    }

    private parseToolbarDefinitionToItems(): IToolbarSection[] {
        return this.availableToolbarSections.map((section) => {
            let filteredItems: IToolbarSection = section;
            if (this.toolbarItems) {
                filteredItems = section.filter((item) => {
                    return this.toolbarItems.includes(item.name as IAvailableToolbarNames);
                });
            }

            if (this.excludeToolbarItems) {
                this.excludeToolbarItems.forEach((excludeName) => {
                    const itemIndex = filteredItems.findIndex((toolbarItem) => {
                        return toolbarItem.name === excludeName;
                    });

                    if (itemIndex !== -1) {
                        filteredItems.splice(itemIndex, 1);
                    }
                });
            }

            return filteredItems.map((definition) => {
                if (definition.tooltip) {
                    definition.command = this.createTooltipCommand(definition.tooltip);
                    return definition;
                }

                if (
                    !definition.command
                    && typeof definition.commandFactory === 'function'
                ) {
                    definition.command = this.createCommand(definition.commandFactory());
                }
                return definition;
            });
        });
    }

    private createTooltipCommand(tooltip: string): () => boolean {
        return () => {
            this.tooltipState[tooltip] = !this.tooltipState[tooltip];
            this.updateToolbarStatus();

            return true;
        };
    }

    private createCommand(command: Command): () => boolean {
        return () => {
            this.focusEditor();

            return command(this.view.state, this.view.dispatch);
        };
    }

    focusEditor(): void {
        this.view.focus();
    }

    private getMetadataSection<T extends keyof IWysiwygEditorMetadata>(sectionName: T): IWysiwygEditorMetadata[T] {
        return this.metadata[sectionName];
    }

    setDisabledState(isDisabled: boolean): void {
        this.editorDisabled = isDisabled;
        this.updateToolbarStatus();
        this.view.setProps({
            editable: () => {
                return !this.editorDisabled;
            }
        });
    }

    ngOnDestroy(): void {
        this.view.destroy();
    }
}
