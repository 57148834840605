import {AbstractOperationDefinition} from 'src/modules/operations/interfaces';
import {EndpointName} from '_types/rest';
import {IQueryObject} from 'src/services/url-parser.service';

interface IChangeHistoryContext {
    elementId: number;
    endpoint: EndpointName;
    query?: IQueryObject;
}

export class OpenChangeHistory extends AbstractOperationDefinition<'history', IChangeHistoryContext> {
    readonly endpoint = 'history'
    readonly name = 'get';
    lang = 'CHANGES_HISTORY';
    icon = 'fa-history';

    disableAbandonWarning = true;
    readonly modalSize = 'xl';

    component = (): Promise<unknown> => import('./change-history/change-history-operation.component');

    access(): boolean {
        return true;
    }
}

export const changeHistoryOperations = [
    OpenChangeHistory
] as const;
