import {NgModule} from '@angular/core';
// eslint-disable-next-line no-restricted-imports
import {CommonModule} from '@angular/common';
// eslint-disable-next-line no-restricted-imports
import {AppPipesModule} from 'src/pipes/app-pipes.module';
import {LocatorsModule} from 'src/modules/locators/locators.module';
import {RouteDirectivesModule} from 'src/modules/route-helper/route-directives.module';


@NgModule({
    imports: [
        CommonModule,
        // eslint-disable-next-line @angular-eslint/sort-ngmodule-metadata-arrays
        AppPipesModule,
        LocatorsModule,
        RouteDirectivesModule
    ],
    exports: [
        CommonModule,
        // eslint-disable-next-line @angular-eslint/sort-ngmodule-metadata-arrays
        AppPipesModule,
        LocatorsModule,
        RouteDirectivesModule
    ]
})
export class AppCommonModule {
}
