import {Compiler, Inject, Injectable, Injector, NgModuleRef, Type} from '@angular/core';
import {Location} from '@angular/common';
import {applyNgModule, StatesModule, UIROUTER_MODULE_TOKEN} from '@uirouter/angular';
import {from, Observable, OperatorFunction, throwError} from 'rxjs';
import {UserService} from '../rest/user/user.service';
import NamingConventions from 'naming-conventions';
import {AppStateDeclaration, IAppTransition, RouteTreeBranch} from 'src/modules/route-helper/interfaces';
import {LazyLoadResult, StateService, StateRegistry, UrlService, Transition} from '@uirouter/core';
import {EndpointName, EndpointToType} from '_types/rest';
import {IQueryObject} from 'src/services/url-parser.service';
import {RestClient} from 'src/modules/rest/rest-client.service';
import {RestEndpoint} from 'src/modules/rest/rest-endpoint';
import {catchError} from 'rxjs/operators';
import {TranslateService} from 'src/modules/translate/translate.service';
import {AppStatesModule, RouteModule} from 'src/modules/route-helper/route.module';
import {ENV, IEnvironment, WINDOW} from 'app-custom-providers';
import {Comparison} from 'src/services/comparison';
import {Utils} from 'src/services/utils';

export type QueryBuilder = (transition: IAppTransition) => IQueryObject;

export interface AppLazyLoadResult extends LazyLoadResult {
    ngModuleRef: NgModuleRef<unknown>
}

@Injectable({
    providedIn: 'root'
})
export class RouteHelper {
    shouldParseRouteTree = true;
    private _routeTree: RouteTreeBranch[] = [];
    private readonly knownModulesMap = new WeakMap<object, NgModuleRef<unknown>>();
    private loadedChildRouteModules: AppStatesModule<StatesModule>[] = [];

    constructor(
        private user: UserService,
        private location: Location,
        private compiler: Compiler,
        private injector: Injector,
        private stateService: StateService,
        private urlService: UrlService,
        private stateRegistry: StateRegistry,
        @Inject(ENV) private env: IEnvironment,
        @Inject(WINDOW) private window: Window
    ) {
        stateRegistry.onStatesChanged(() => {
            this.shouldParseRouteTree = true;
        });
    }

    checkApp(isLoginPage = false): Observable<void> {
        return new Observable((subscriber) => {
            this.user.checkAuth().subscribe((authorized) => {
                if (this.env.APP.current === 'private' && !authorized) {
                    this.window.location.href = this.env.dist.public + '#' + this.location.path();
                } else if (
                    this.env.APP.current === 'public'
                    && authorized
                    && (isLoginPage || /^\/login\/?/.test(this.location.path()))
                ) {
                    this.window.location.href = this.env.dist.private
                        + (
                            typeof this.window.location.hash === 'string'
                            && this.window.location.hash.length
                                ? decodeURIComponent(this.window.location.hash.substring(1))
                                : '/'
                        );
                } else {
                    subscriber.next();
                    subscriber.complete();
                }
            });
        });
    }

    lazyLoadImport<T>(
        importPromise: Promise<{ default?: Type<T>, [exportName: string]: Type<T> }>
    ): Promise<AppLazyLoadResult> {
        return importPromise
            .then((importedModule) => {
                const module = importedModule.default || Object.values(importedModule).pop();

                if (this.knownModulesMap.has(importedModule)) {
                    return Promise.resolve({
                        ngModuleRef: this.knownModulesMap.get(importedModule)
                    });
                }

                return this.compiler
                    .compileModuleAsync(module)
                    .then((factory) => {
                        const ngModuleRef = factory.create(this.injector),
                            routeModules = ngModuleRef.injector
                                .get<AppStatesModule<StatesModule>[]>(UIROUTER_MODULE_TOKEN);

                        // if loaded ngModule imports another ngModule with routes we need to exclude them,
                        // to avoid state declaration duplication
                        Utils.arrayCopyTo(routeModules, routeModules.filter((routeModule) => {
                            return !this.loadedChildRouteModules.includes(routeModule);
                        }));

                        routeModules.forEach((routeModule) => {
                            this.loadedChildRouteModules.push(routeModule);
                        });

                        applyNgModule(null, ngModuleRef, this.injector, {
                            name: 'null'
                        });
                        this.knownModulesMap.set(importedModule, ngModuleRef);

                        return {
                            ngModuleRef
                        };
                    });
            });
    }

    createUrlParameter(typeName: string, urls: string[]): void {
        this.urlService.config.type(typeName, {
            pattern: new RegExp(urls
                .map((x) => x.replace(/[-/]+/g, '\\$&'))
                .filter(Comparison.unique)
                .join('|')
            ),
            raw: true,
            is() {
                return true;
            },
            encode(val) {
                return val;
            },
            decode(val) {
                return val;
            },
            equals(a: string, b: string): boolean {
                return a === b;
            }
        });
    }

    configureStates(states: AppStateDeclaration[]): void {
        RouteModule.createRouterStates(states).forEach((state) => {
            this.stateRegistry.register(state);
        });
    }

    /**
     * Returns a language key based on given state name
     */
    static getStateLanguageKey(stateName: string): string {
        return 'ROUTE_'
            + NamingConventions.convert(stateName.replace(/[.-]/g, '_'))
                .to(NamingConventions.snakeCase).toUpperCase();
    }

    /**
     * Get all available routes tree or a route branch of specified name
     */
    getStatesTree(): RouteTreeBranch[];
    getStatesTree(searchStateName: string): RouteTreeBranch | false
    getStatesTree(searchStateName?: string): RouteTreeBranch | false | RouteTreeBranch[] {
        if (!this._routeTree.length || this.shouldParseRouteTree) {
            this._parseRouteTree();
        }
        if (typeof searchStateName !== 'undefined') {
            return this._getStateItem(searchStateName);
        }
        return this._routeTree;
    }

    private _parseRouteTree() {
        this._routeTree.length = 0;
        (this.stateService.get() as AppStateDeclaration[]).forEach((state) => {
            if (~[''].indexOf(state.name)) {
                return;
            }
            const split = state.name.replace(/\.\*\*/, '').split('.'),
                stateName = split.pop(),
                parentName = split.join('.'),
                routeItem = {
                    name: state.name,
                    localName: stateName,
                    lang: state.lang || RouteHelper.getStateLanguageKey(state.name),
                    url: state.url,
                    redirectTo: state.redirectTo,
                    abstract: state.abstract,
                    subs: [],
                    isFutureState: /\.\*\*/.test(state.name),
                } as RouteTreeBranch;
            if (parentName === '') {
                this._routeTree.push(routeItem);
            } else {
                const parentItem = this._getStateItem(parentName);
                if (parentItem !== false) {
                    routeItem.parent = parentItem;
                    parentItem.subs.push(routeItem);
                }
            }
        });
    }

    private _getStateItem(stateName: string, branches?: RouteTreeBranch[]): RouteTreeBranch | false {
        if (typeof branches === 'undefined') {
            branches = this._routeTree;
        }

        for (let i = 0; i < branches.length; i++) {
            if (branches[i].name === stateName || branches[i].name === `${stateName}.**`) {
                return branches[i];
            }
            if (branches[i].subs.length) {
                const subSearch = this._getStateItem(stateName, branches[i].subs);
                if (subSearch !== false) {
                    return subSearch;
                }
            }
        }
        return false;
    }

    /**
     * shortcut function for route lazy loading
     */
    static lazyLoadModule<T>(
        importPromiseCallback: () => Promise<{
            default?: Type<T>,
            [exportName: string]: Type<T>
        }>
    ): (transition: IAppTransition) => Promise<LazyLoadResult> {
        return (transition: IAppTransition) => {
            const routeHelper = transition.injector()
                .get<RouteHelper>(RouteHelper);

            return routeHelper
                .lazyLoadImport(
                    importPromiseCallback()
                )
                .then((result) => {
                    if (routeHelper.checkGoParameter()) {
                        return Promise.reject('REDIR_WITH_GO_ARGUMENT');
                    }
                    return result;
                });
        };
    }

    private checkGoParameter(): boolean {
        const go = this.urlService.parts().search._go;

        if (typeof go !== 'undefined') {
            const targetState = this.stateService.get(go);

            if (!targetState.name.endsWith('.**')) {
                this.stateService.go(targetState, this.urlService.parts().search);
                return true;
            }
        }

        return false;
    }

    static restResolveObject<T extends string = EndpointName, TObject = EndpointToType<T>>(
        endpoint: T,
        paramName?: string | ((transition: IAppTransition) => string | number),
        queryBuilder?: QueryBuilder
    ): (transition: IAppTransition) => ReturnType<RestEndpoint<T, TObject>['get']> {
        return RouteHelper._restResolve<T, 'get', TObject>(endpoint, (restEndpoint, handleError, transition) => {
            const {query, param} = this._restPrepare(transition, queryBuilder, paramName);
            return restEndpoint.get(param, query).pipe(handleError);
        });
    }

    static restResolveCollection<T extends string = EndpointName, TCollection = EndpointToType<T, true>>(
        endpoint: T,
        queryBuilder?: QueryBuilder
    ): (transition: IAppTransition) => ReturnType<RestEndpoint<T, never, TCollection>['getAll']> {
        return RouteHelper._restResolve<T, 'getAll', never, TCollection>(
            endpoint,
            (restEndpoint, handleError, transition) => {
                const {query} = this._restPrepare(transition, queryBuilder);
                return restEndpoint.getAll(query).pipe(handleError);
            });
    }

    static restResolveSchema<T extends string = EndpointName>(
        endpoint: T,
        withProperties?: boolean,
        groups?: string | string[] | boolean,
        method?: 'get' | 'post' | 'put',
        id?: number
    ): (transition: IAppTransition) => ReturnType<RestEndpoint<T>['getSchema']> {
        return RouteHelper._restResolve<T, 'getSchema'>(endpoint, (restEndpoint, handleError) => {
            return restEndpoint
                .getSchema(withProperties, groups, method, id)
                .pipe(handleError);
        });
    }

    static restResolveTranslations(
        translationModule: string
    ): (transition: IAppTransition) => ReturnType<TranslateService['addPart']> {
        return (transition: IAppTransition): ReturnType<TranslateService['addPart']> => {
            return transition.injector()
                .get<TranslateService>(TranslateService)
                .addPart(translationModule);
        };
    }

    static loadAllRoutes(): (transition: Transition) => Observable<LazyLoadResult[]> {
        return (transition: Transition): Observable<LazyLoadResult[]> => {
            const stateService = transition.injector().get<StateService>(StateService);

            return from(
                Promise.all(
                    stateService.get()
                        .filter((state) => state.name.endsWith('.**'))
                        .map((state) => state.lazyLoad(transition, state))
                )
            );
        };
    }

    private static _restResolve<T extends string = EndpointName,
        TMethod extends 'get' | 'getAll' | 'getSchema' | 'getConst' = 'get',
        TObject = EndpointToType<T>,
        TCollection = EndpointToType<T>,
        TRet = ReturnType<RestEndpoint<T, TObject, TCollection>[TMethod]> extends Observable<infer P> ? P : never>(
        endpoint: T,
        callback: (
            restEndpoint: RestEndpoint<T, TObject, TCollection>,
            handleError: OperatorFunction<TRet, TRet>,
            transition: IAppTransition
        ) => ReturnType<RestEndpoint<T, TObject, TCollection>[TMethod]>
    ): (transition: IAppTransition) => ReturnType<RestEndpoint<T, TObject, TCollection>[TMethod]> {
        return (transition: IAppTransition): ReturnType<RestEndpoint<T, TObject, TCollection>[TMethod]> => {
            const injector = transition.injector(),
                restEndpoint = injector.get<RestClient>(RestClient).endpoint<T, TObject, TCollection>(endpoint);

            return callback(restEndpoint, catchError((e) => {
                injector.get(StateService).go('error', e);
                return throwError(() => e);
            }), transition);
        };
    }

    private static _restPrepare(
        transition: IAppTransition,
        queryBuilder: QueryBuilder,
        paramName?: string | ((transition: IAppTransition) => string | number)
    ): { query: IQueryObject | null, param: number | string } {
        const query = typeof queryBuilder === 'function' ? queryBuilder(transition) : null;
        let param;
        if (typeof paramName === 'function') {
            param = paramName(transition);
        } else if (typeof paramName === 'string') {
            const params = transition.params();
            if (paramName in params) {
                param = params[paramName];
            }
        }
        return {
            query,
            param
        };
    }
}
