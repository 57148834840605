import {Injectable, Injector} from '@angular/core';
import {RestClientConfig} from 'src/modules/rest/rest-client-config.service';
import {ToastsService} from 'src/modules/global-components/toasts/toasts.service';
import {EndpointName, EndpointToType} from '_types/rest';
import {RestEndpoint} from 'src/modules/rest/rest-endpoint';
import {RestError} from './rest.error';
import {RestObject} from 'src/modules/rest/objects/rest-object';
import {RestCollection} from 'src/modules/rest/objects/rest-collection';

export interface IParsedIRI {
    endpoint: string,
    id: string
}

@Injectable({
    providedIn: 'root'
})
export class RestClient {
    constructor(
        private restClientConfig: RestClientConfig,
        // injector is needed to avoid circular dependency of ToastsService -> TranslateService -> RestClient
        private injector: Injector
    ) {
    }

    static isRestObject(value: unknown): value is RestObject<EndpointName> {
        return value instanceof RestObject;
    }

    static isRestCollection(value: unknown): value is RestCollection<EndpointName> {
        return value instanceof RestCollection;
    }

    parseIri(iri: string): IParsedIRI {
        if (typeof iri !== 'string') {
            return null;
        }
        let endpoint, id;
        if (iri.indexOf(this.restClientConfig.path) === 0) {
            iri = iri.substr(this.restClientConfig.path.length);
        }
        if (iri.length) {
            const split = iri.split('/');
            if (split.length > 1) {
                id = split.pop();
            }
            endpoint = split.join('/');
        }
        return {
            endpoint,
            id
        };
    }

    isIri(iri: string): boolean {
        if (typeof iri !== 'string' || iri.indexOf(this.restClientConfig.path) !== 0) {
            return false;
        }
        iri = iri.substr(this.restClientConfig.path.length);
        return /^([a-z0-9_]+\/)*[a-z0-9_]+/.test(iri);
    }

    handleError = (restError: RestError): void => {
        this.injector.get(ToastsService).restError(restError);
    };

    savedToast(): void {
        this.injector.get(ToastsService).save();
    }

    endpoint<T extends EndpointName | string,
        TObject = EndpointToType<T>,
        TCollection = EndpointToType<T, true>>(
        endpoint: T
    ): RestEndpoint<T, TObject, TCollection> {
        return new RestEndpoint<T, TObject, TCollection>(endpoint);
    }
}
