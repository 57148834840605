import {ComponentFactoryResolver, Inject, Injectable, Injector, TemplateRef} from '@angular/core';
import {RestClient} from 'src/modules/rest/rest-client.service';
import {IRelationDynamicFieldConfig} from 'src/modules/dynamic-fields/interfaces';
import {Observable, of, ReplaySubject, zip} from 'rxjs';
import {catchError, first, map} from 'rxjs/operators';
import {EndpointName, EndpointToType} from '_types/rest';
import {ENTITY_TO_STRING, IEntityToStringProcessors} from 'src/providers/entity-to-string.provider';
import {
    EntityTemplatesComponent, IEntityTemplateContext, EntityTemplateTypes,
} from 'src/modules/dynamic-fields/controls/relation-select/entity-templates.component';
import {TranslateService} from 'src/modules/translate/translate.service';

export interface IEntityPresentation {
    label: string,
    template: TemplateRef<IEntityTemplateContext>,
    templateContext: IEntityTemplateContext
}

@Injectable({
    providedIn: 'root'
})
export class RelationSelectService {
    private entityTemplatesComponent: EntityTemplatesComponent;
    private cache = new WeakMap<object, Map<string | number, ReplaySubject<unknown>>>();

    constructor(
        private restClient: RestClient,
        @Inject(ENTITY_TO_STRING) private entityProcessors: IEntityToStringProcessors,
        private componentFactoryResolver: ComponentFactoryResolver,
        private injector: Injector,
        private translateService: TranslateService,
    ) {
    }


    computeEntityPresentation(
        endpoint: EndpointName,
        object: EndpointToType<EndpointName>
    ): IEntityPresentation {
        const entityProcessor = this.entityProcessors[endpoint];
        let label: string,
            template: TemplateRef<IEntityTemplateContext>,
            templateContext: IEntityTemplateContext;

        if (typeof entityProcessor === 'object') {
            label = entityProcessor.label(object);

            if ('templateType' in entityProcessor) {
                template = this.getEntityTemplate(entityProcessor.templateType);

                if ('templateContext' in entityProcessor) {
                    templateContext = Object.assign(
                        {
                            type: entityProcessor.templateType,
                            value: object,
                            $implicit: label
                        },
                        entityProcessor.templateContext(object)
                    ) as unknown as IEntityTemplateContext;
                }
            }
        } else if (typeof entityProcessor === 'function') {
            label = entityProcessor(object);
        } else {
            label = this.defaultEntityToString(object);
        }

        label = this.translateLabel(label);

        if (typeof template === 'undefined') {
            template = this.getEntityTemplate();
        }

        if (typeof templateContext === 'undefined') {
            templateContext = {
                type: 'plain',
                value: object,
                $implicit: label
            };
        }

        return {
            label,
            template,
            templateContext
        };
    }

    private translateLabel(label: string): string {
        if (this.translateService.isTranslationKey(label)) {
            return this.translateService.get(label);
        }

        return label;
    }


    cacheRequest<T>(
        fieldConfig: IRelationDynamicFieldConfig,
        doRequest: () => Observable<T>,
        value?: string | number
    ): Observable<T> {
        if (typeof fieldConfig.cacheKey === 'undefined') {
            return doRequest();
        }

        let map = this.cache.get(fieldConfig.cacheKey);

        if (typeof map === 'undefined') {
            map = new Map<string | number, ReplaySubject<unknown>>();
            this.cache.set(fieldConfig.cacheKey, map);
        } else if (map.has(value)) {
            return map.get(value) as ReplaySubject<T>;
        }

        const replaySubject = new ReplaySubject<T>();
        map.set(value, replaySubject);

        doRequest()
            .subscribe((response) => {
                replaySubject.next(response);
            });

        return replaySubject.pipe(first());
    }

    valuesToObjects(
        values: (string | number)[],
        fieldConfig: IRelationDynamicFieldConfig
    ): Observable<unknown[]> {
        const valueKey = fieldConfig.valueKey || '@id',
            endpoint = this.restClient.endpoint(fieldConfig.valueEndpoint || fieldConfig.endpoint),
            itemEndpoint = this.restClient.endpoint(fieldConfig.itemEndpoint || fieldConfig.endpoint);
        return zip(
            values
                .map((value) => {
                    if (valueKey === '@id') {
                        if (typeof value !== 'string' || !this.restClient.isIri(value)) {
                            // eslint-disable-next-line no-console
                            console.error(`value is not an IRI: ${JSON.stringify(value)} | ${fieldConfig.name}`);
                            return of(null);
                        }

                        const iri = this.restClient.parseIri(value as string);

                        if (iri.endpoint !== endpoint.name) {
                            // eslint-disable-next-line no-console
                            console.error(
                                'IRI endpoint is different than required'
                                + `(${iri.endpoint} !== ${fieldConfig.endpoint})`
                            );
                            return of(null);
                        }

                        value = iri.id;
                    }

                    return this.cacheRequest(fieldConfig, () => itemEndpoint.get(value, fieldConfig.query), value)
                        .pipe(
                            catchError(() => of(null))
                        );
                })
        ).pipe(
            map((v) => {
                return v.filter((item) => item !== null);
            })
        );
    }

    private getEntityTemplate(
        templateName: EntityTemplateTypes = 'plain'
    ): TemplateRef<IEntityTemplateContext> {
        if (!this.entityTemplatesComponent) {
            this.entityTemplatesComponent = this.componentFactoryResolver
                .resolveComponentFactory(EntityTemplatesComponent)
                .create(this.injector)
                .instance;
        }

        if (!this.entityTemplatesComponent[templateName]) {
            throw new Error(`Register template with name "${templateName}" first!`);
        }
        return this.entityTemplatesComponent[templateName];
    }

    private defaultEntityToString(object: EndpointToType<EndpointName>): string {
        let ret = '';
        ['name', 'fullName', '@id'].some((key) => {
            if (typeof object[key] !== 'undefined') {
                return ret = object[key];
            }
        });

        return ret;
    }

    getBeforeTemplate(endpoint: EndpointName): TemplateRef<IEntityTemplateContext> {
        if (typeof endpoint !== 'string' || !this.entityProcessors[endpoint]) {
            return null;
        }

        const entityProcessor = this.entityProcessors[endpoint];

        if (
            typeof entityProcessor !== 'object'
            || !entityProcessor?.templateBeforeType
        ) {
            return null;
        }

        return this.getEntityTemplate(entityProcessor.templateBeforeType);
    }
}
