import {AbstractOperationDefinition} from 'src/modules/operations/interfaces';
import {With} from '_types/rest';
import {IRestObject} from 'src/modules/rest/objects';

export type IRoleWithWidget =  With<IRestObject<'a_c_l_o_g_roles'>, 'widgetACLOGRoles'>;

export class ACLWidgetRoleEditOperation extends AbstractOperationDefinition<'default_widget_roles', IRoleWithWidget> {
    readonly endpoint = 'default_widget_roles';
    readonly name = 'put';
    lang = 'ACL_WIDGET_ROLES_EDIT';
    icon = 'fa-edit';
    component = (): Promise<unknown> => import('./acl-widget-role-form/acl-widget-role-form.component');
}

export const aclWidgetRoleOperations = [
    ACLWidgetRoleEditOperation,
] as const;
