import {
    ITaskTemplateComponent,
    TaskPermissionToAddOptions, TaskPriorityOptions,
    TaskStateStateOptions
} from '_types/rest';
import {TextUtils} from 'src/services/text-utils';
import {IChangedObjectPayload, IEventPayloadProcessorFn} from 'src/modules/history/events-history/interfaces';
import {EventProcessorUtil} from 'src/modules/history/events-history/event-processor-util';
import {OptionsUtil} from 'src/services/options-util';


export const eventPayloadProcessors: Record<string, IEventPayloadProcessorFn> = {
    ACTION_ACTION_TASK_TEMPLATE_COMPONENT: (
        {before, after}: IChangedObjectPayload<ITaskTemplateComponent>,
        injector
    ) => {
        return EventProcessorUtil.createBeforeAfterLabel(
            before.name,
            after.name,
            injector
        );
    },
    ACTION_TASK_NAME: EventProcessorUtil.stringBeforeAfter,
    ACTION_SPECIAL_ASSIGNEE_CHANGE: EventProcessorUtil.userBeforeAfter,
    ACTION_TASK_STATE: ({before, after}: IChangedObjectPayload<string>, injector) => {
        return EventProcessorUtil.createBeforeAfterLabel(
            OptionsUtil.getTranslatedLabel(
                [...TaskStateStateOptions],
                Number(before),
                injector
            ),
            OptionsUtil.getTranslatedLabel(
                [...TaskStateStateOptions],
                Number(after),
                injector
            ),
            injector
        );
    },
    ACTION_TASK_DESCRIPTION: ({before, after}: IChangedObjectPayload<string>, injector) => {
        const MAX_LENGTH = 30;
        return EventProcessorUtil.createBeforeAfterLabel(
            TextUtils.shortText(before, MAX_LENGTH),
            TextUtils.shortText(after, MAX_LENGTH),
            injector
        );
    },
    ACTION_SPECIAL_DUE_DATE_INIT: EventProcessorUtil.dateBeforeAfter,
    ACTION_CUSTOM_FIELD_CHANGE: EventProcessorUtil.stringBeforeAfter,
    ACTION_SPECIAL_ITERATION_CHANGE: EventProcessorUtil.dateBeforeAfter,
    ACTION_AUTHOR_CHANGE: EventProcessorUtil.userBeforeAfter,
    ACTION_SPECIAL_ASSIGNEE_ADD: EventProcessorUtil.minimalUsersArrayAfter,
    ACTION_SPECIAL_ASSIGNEE_REMOVE: EventProcessorUtil.minimalUsersArrayAfter,
    ACTION_SPECIAL_START_DATE_CHANGE: EventProcessorUtil.dateAfter,
    ACTION_SPECIAL_DUE_DATE_CHANGE: EventProcessorUtil.dateAfter,
    ACTION_TASK_PRIORITY: EventProcessorUtil.beforeAfterOptions([...TaskPriorityOptions]),
    ACTION_TASK_COMPANY: EventProcessorUtil.beforeAfterProperty(),
    ACTION_TASK_CUSTOM_ID: EventProcessorUtil.stringBeforeAfter,
    ACTION_TASK_COMPLETION_ALLOWED_AHEAD_OF_SCHEDULE: EventProcessorUtil.booleanBeforeAfter,
    ACTION_TASK_VISIBILITY_TYPE: EventProcessorUtil.beforeAfterProperty('name', true),
    ACTION_TASK_PERMISSION_TO_ADD: EventProcessorUtil.beforeAfterOptions([...TaskPermissionToAddOptions]),
    ACTION_TASK_ARCHIVED: EventProcessorUtil.booleanBeforeAfter,
    ACTION_TASK_WEEKLY: EventProcessorUtil.booleanBeforeAfter,
    ACTION_TASK_CRITERION_NEW_ASSIGNEE: EventProcessorUtil.singleUserFromAfter,
};
