import {transition, trigger, useAnimation} from '@angular/animations';
import {fadeInAnimation} from 'src/animations/fade-in.animation';

export const ImgUploadAnimations = [
    trigger('loadImg', [
        transition(':enter', [
            useAnimation(fadeInAnimation, {
                params: {
                    time: '0.3s linear'
                }
            })
        ])
    ])
];
