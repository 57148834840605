import {ErrorHandler} from '@angular/core';
import {StateService} from '@uirouter/angular';
import {HrefOptions, StateOrName} from '@uirouter/core/lib/state/interface';
import {RawParams} from '@uirouter/core/lib/params/interface';
import {UIRouter} from '@uirouter/core';
import {ExceptionHandler} from 'src/modules/exception-handler/exception-handler';

function stateServiceFactory(
    uiRouter: UIRouter,
    errorHandler: ExceptionHandler
): StateService {
    const originalHref = uiRouter.stateService.href.bind(uiRouter.stateService);

    uiRouter.stateService.href = (
        stateOrName: StateOrName,
        params?: RawParams,
        options?: HrefOptions
    ): string => {
        const href = originalHref(stateOrName, params, options);

        let state;
        if (
            typeof stateOrName === 'string'
            && stateOrName.includes('.')
            && (state = uiRouter.stateService.get(stateOrName)) !== null
            && state.name.endsWith('.**')
        ) {
            const queryArray = ['_go=' + stateOrName];

            if (typeof params === 'object' && params !== null) {
                Object.entries(params).forEach(([key, item]) => {
                    if (Array.isArray(item)) {
                        item.forEach((subItem) => {
                            queryArray.push(key + '[]=' + subItem);
                        });
                    } else if (typeof item !== 'undefined') {
                        queryArray.push(key + '=' + item);
                    }
                });
            }

            return href + (href.includes('?') ? '&' : '?') + queryArray.join('&');
        }
        return href;
    };


    uiRouter.stateService.defaultErrorHandler((error) => {
        if (error === 'REDIR_WITH_GO_ARGUMENT') {
            return;
        }

        errorHandler.handleError(error);
    });

    return uiRouter.stateService;
}


export const APP_STATE_SERVICE_PROVIDER = {
    provide: StateService, useFactory: stateServiceFactory, deps: [UIRouter, ErrorHandler]
};
