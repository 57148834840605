<div class="input-group">
    <input type="text" class="form-control" [formControl]="inputControl" (blur)="markAsTouched()"/>
    <div class="input-group-append rounded-right">
        <button type="button" class="btn btn-primary"
                [copy]="copyUrl"
                (blur)="markAsTouched()">
            {{(!copied ? (fieldConfig.copyLang || 'COPY') : (fieldConfig.copiedLang || 'COPIED')) | translate}}
        </button>
    </div>
</div>
