import {AbstractCustomSelectComponent} from 'src/modules/dynamic-fields/controls/dynamic-select/custom-select/abstract-custom-select.component';
import {ControlValueAccessor} from '@angular/forms';
import {Component, Input, OnInit, QueryList} from '@angular/core';
import {EsBeforeOptionDirective, EsOptionsDirective, IEsOption} from 'angular-bootstrap4-extended-select';
import {CheckboxDynamicFieldViewType} from 'src/modules/dynamic-fields/interfaces';
import {
    AbstractUnvalidatedControlValueAccessor
} from 'src/modules/app-forms/abstract-control-value-accessors/abstract-unvalidated-control-value-accessor';

@Component({
    selector: 'checkbox-select',
    templateUrl: './abstract-custom-select.component.html',
    styleUrls: ['./abstract-custom-select.component.scss'],
    providers: AbstractUnvalidatedControlValueAccessor.getProviders(CheckboxSelectComponent)
})
export class CheckboxSelectComponent extends AbstractCustomSelectComponent implements ControlValueAccessor, OnInit {
    @Input() esOptionsList: QueryList<EsOptionsDirective<unknown>>;
    @Input() esBeforeOption?: EsBeforeOptionDirective<unknown>;
    @Input() viewType: CheckboxDynamicFieldViewType;
    @Input() inline: boolean;

    customControlClass: string;
    inputType = 'checkbox';

    private _currentOptions: IEsOption<unknown>[];

    ngOnInit(): void {
        switch (this.viewType) {
            case 'checkBar':
                this.customControlClass = 'custom-checkbox custom-checkbox-bar';
                break;
            case 'switch':
                this.customControlClass = 'custom-switch custom-switch-lg';
                break;
            case 'checkbox':
            default:
                this.customControlClass = 'custom-checkbox';
                break;
        }
    }

    writeValue(value: unknown): void {
        if (!Array.isArray(value)) {
            this._currentOptions = [];
            return;
        }

        this._currentOptions = this._filterOptions((option) => {
            return value.includes(option.getValue());
        });
    }

    change(option: IEsOption<unknown>, checked: boolean): void {
        if (checked) {
            this._currentOptions = this._filterOptions((item) => {
                return option === item || this._currentOptions?.includes(item);
            });
        } else {
            this._currentOptions = this._currentOptions?.filter((item) => {
                return option !== item;
            });
        }

        this._onChange(this._currentOptions.map((option) => option.getValue()));
    }

    isChecked(option: IEsOption<unknown>): boolean {
        return this._currentOptions?.includes(option);
    }

    private _filterOptions(
        filterFn: (item: IEsOption<unknown>, idx: number, array: IEsOption<unknown>[]) => boolean
    ): IEsOption<unknown>[] {
        let items: IEsOption<unknown>[] = [];
        this.esOptionsList.forEach((esOptions) => {
            items = items.concat(esOptions.options.value.filter(filterFn));
        });
        return items;
    }
}
