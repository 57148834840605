import {InjectionToken} from '@angular/core';
import {
    EndpointName,
    EndpointToType,
    IAddress, ICompany, ICompanyContact,
    IACLOGRole,
    IContract,
    IDocument,
    IPrivilegeAccessLevel,
    IProduct,
    IUserLogin,
    With, IContractItem, IContractItemTreeDetailsOutputDto
} from '_types/rest';
import DateExtended from 'date-extensions';
import {Comparison} from 'src/services/comparison';
import {
    IEntityTemplateContextFromType,
    EntityTemplateTypes
} from 'src/modules/dynamic-fields/controls/relation-select/entity-templates.component';
import {Utils} from 'src/services/utils';

const userLogins: IEntityProcessorWithTemplate<IUserLogin> = {
    templateBeforeType: 'userLoginBefore',
    label: (entity: IUserLogin) => `${entity.lastName} ${entity.firstName} (${entity.email})`
};

const aclOgRoles: IEntityProcessorWithTemplate<IACLOGRole, 'badge'> = {
    templateType: 'badge',
    templateContext: (entity: IACLOGRole) => {
        return {
            color: Utils.bootstrapColorFromNumber(entity.id)
        };
    },
    label: (entity: IACLOGRole) => entity.name
};
export type boundContractItemTree = With<IContractItemTreeDetailsOutputDto,
    'activeContractItem' | 'baseContractItem', {
    activeContractItem: With<IContractItem, 'service' | 'product' | 'contract'>,
    baseContractItem: With<IContractItem, 'contract', {
        contract: With<IContract, 'company', {
            company: With<ICompany, 'tags'>
        }>
    }>
}>;
const contractItemTrees: IEntityProcessorWithTemplate<boundContractItemTree, 'contractItemTree'> = {
    templateType: 'contractItemTree',
    label: (entity: boundContractItemTree) => {
        const label: string[] = [];
        label.push(entity.activeContractItem.service.name);

        if (entity.activeContractItem.product) {
            label.push(`[${entity.activeContractItem.product.name}]`);
        }

        return label.join(' ');
    }
};

const entityProcessors: IEntityToStringProcessors = {
    partners: (partner: With<EndpointToType<'partners'>, 'company'>) => partner.company.name,
    documents: (entity: With<IDocument, 'documentTokens'>) => {
        const domainToken = entity.documentTokens.find(Comparison.criteria({name: 'domain'}));
        if (typeof domainToken !== 'undefined') {
            return `${entity.id} - ${domainToken.value}`
                + ` - ${(new DateExtended(entity.created)).format('d/m/Y')}`;
        }
        const [year, month] = entity.created.split('-');
        return `${entity.id}/${month}/${year}`;
    },
    addresses: (entity: IAddress) => {
        return `${entity.street} ${entity.streetNo}, ${entity.postCode} ${entity.city}`;
    },
    user_logins: userLogins,
    'user_logins/access': userLogins,
    'user_logins/list': userLogins,
    company_contacts: {
        templateBeforeType: 'userLoginBefore',
        label: (entity: ICompanyContact) => {
            return entity.name;
        }
    },
    contracts: (entity: With<IContract, 'company' | 'contractItems'>) => {
        let contractName = entity.id.toString();
        if (entity.mark !== null) {
            contractName += ' - ' + entity.mark;
        }
        if (typeof entity.company === 'object' && entity.company !== null) {
            contractName += ' - ' + entity.company.name;
        }
        if (
            Array.isArray(entity.contractItems)
            && entity.contractItems.length > 0
        ) {
            const first = entity.contractItems[0];
            if (
                typeof first !== 'undefined'
                && typeof first.service === 'object'
                && first.service !== null
            ) {
                contractName += ' - ' + first.service.name;
                if (entity.contractItems.length > 1) {
                    contractName += ' (+' + (entity.contractItems.length - 1) + ')';
                }
            }
        }
        return contractName;
    },
    contract_items: (contractItem: IContractItem) => {
        const contractItemName: string[] = [];

        if (typeof contractItem.service === 'object' && contractItem.service !== null) {
            contractItemName.push(contractItem.service.name);
        }

        if (typeof contractItem.product === 'object' && contractItem.product !== null) {
            contractItemName.push(contractItem.product.name);
        }

        if (
            typeof contractItem.contract === 'object'
            && contractItem.contract !== null
            && contractItem.contract.mark
        ) {
            contractItemName.push(contractItem.contract.mark);
        }

        if (!contractItemName.length) {
            contractItemName.push(contractItem.id.toString());
        }

        return contractItemName.join(' - ');
    },
    privilege_access_levels: (entity: With<IPrivilegeAccessLevel, 'partner'>) => {
        if (
            typeof entity.partner !== 'undefined'
            && typeof entity.partner.company !== 'undefined'
        ) {
            return `${entity.label} (${(entity.partner.company as ICompany).name})`;
        }
        return entity.label;
    },
    products: (entity: With<IProduct, 'productGroups'>) => {
        if (
            typeof entity.productGroups !== 'undefined'
            && entity.productGroups.length > 0
        ) {
            return `[${entity.productGroups[0].name}] ${entity.name}`;
        }
        return entity.name;
    },
    'a_c_l_o_g_roles/list': aclOgRoles,
    'a_c_l_o_g_roles': aclOgRoles,
    'contract_item_trees/list': contractItemTrees,
    'contract_item_trees': contractItemTrees
};

//////

export type IEntityProcessorFn<T>
    = (entity: T) => string;

export interface IEntityProcessorWithTemplate<TEntity, TTemplate extends EntityTemplateTypes = EntityTemplateTypes> {
    label: IEntityProcessorFn<TEntity>,
    templateType?: TTemplate,
    templateContext?: (entity: TEntity) => IEntityTemplateContextFromType<TTemplate>,
    templateBeforeType?: EntityTemplateTypes
}

export type IEntityToStringProcessors = {
    [P in EndpointName]?: IEntityProcessorFn<EndpointToType<P>> | IEntityProcessorWithTemplate<EndpointToType<P>>;
}


export const ENTITY_TO_STRING = new InjectionToken<IEntityToStringProcessors>('entityToString', {
    providedIn: 'root',
    factory: () => entityProcessors
});
