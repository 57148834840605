import {Directive, HostBinding, Inject, OnDestroy, OnInit, Self} from '@angular/core';
import {UISref} from '@uirouter/angular';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {UserPrivileges, UserService} from 'src/modules/rest/user/user.service';
import {ENV, IEnvironment} from 'app-custom-providers';

@Directive({
    selector: '[uiSref]'
})
export class AppUiSrefDirective implements OnInit, OnDestroy {

    @HostBinding('class.disabled') disabled = false;

    private readonly _destroy$ = new Subject<void>();

    constructor(
        @Self() private readonly uiSrefDirective: UISref,
        private readonly userService: UserService,
        @Inject(ENV) private readonly environment: IEnvironment
    ) {
    }

    ngOnInit(): void {
        this.uiSrefDirective.targetState$
            .pipe(takeUntil(this._destroy$))
            .subscribe((x) => {
                if (this.environment.APP.current === 'public') {
                    this.disabled = false;
                    return;
                }

                const stateName = x.name() as UserPrivileges;

                if (stateName.includes('.**')) {
                    return;
                }

                this.disabled = !this.userService.hasPrivileges(stateName);
            });
    }

    ngOnDestroy(): void {
        this._destroy$.next();
        this._destroy$.complete();
    }
}
