import {NgModule} from '@angular/core';
import {TranslatePipe} from 'src/modules/translate/translate.pipe';
import {TranslateDynamicPipe} from 'src/modules/translate/translateDynamic.pipe';
import {KeyTranslationsComponent} from 'src/modules/translate/key-translations/key-translations.component';
import {AppCommonModule} from 'src/modules/app-common/app-common.module';
import {AppFormsModule} from 'src/modules/app-forms/app-forms.module';
import {BsDropdownModule} from 'angular-bootstrap4';
import {LoaderModule} from 'src/components/loader/loader.module';

@NgModule({
    imports: [
        AppCommonModule,
        AppFormsModule,
        BsDropdownModule,
        LoaderModule
    ],
    declarations: [
        KeyTranslationsComponent,
        TranslateDynamicPipe,
        TranslatePipe
    ],
    exports: [
        KeyTranslationsComponent,
        TranslateDynamicPipe,
        TranslatePipe
    ]
})
export class TranslateModule {

}
