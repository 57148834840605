/* eslint-disable max-len */
/**
 * This file has been auto generated. Do not edit.
 */

import {IBaseRestEntity} from '_types/rest/IBaseRestEntity';
import {IRestObject} from 'core/rest/rest-object.service';

export const TaskProcessingKeyTaskProcessingKeyType = {
    TASK_PROCESSING_KEY_TYPE_COMPONENT: '/rest/task_processing_key_types/1',
    TASK_PROCESSING_KEY_TYPE_TEMPLATE: '/rest/task_processing_key_types/2'
} as const;

export const TaskProcessingKeyTaskProcessingKeyTypeOptions = [
    {
        value: TaskProcessingKeyTaskProcessingKeyType.TASK_PROCESSING_KEY_TYPE_COMPONENT,
        label: 'TASK_PROCESSING_KEY_TYPE_COMPONENT',
    },
    {
        value: TaskProcessingKeyTaskProcessingKeyType.TASK_PROCESSING_KEY_TYPE_TEMPLATE,
        label: 'TASK_PROCESSING_KEY_TYPE_TEMPLATE',
    }
] as const;

export interface ITaskProcessingKeyConst {
    taskProcessingKeyType: [{id: '/rest/task_processing_key_types/1', value: 'TASK_PROCESSING_KEY_TYPE_COMPONENT'}, {id: '/rest/task_processing_key_types/2', value: 'TASK_PROCESSING_KEY_TYPE_TEMPLATE'}]
}

export interface ITaskProcessingKey extends IBaseRestEntity {
    id?: number,
    /**
     * @maxLength 1024
     */
    key: string,
    taskProcessingKeyType?: typeof TaskProcessingKeyTaskProcessingKeyType[keyof typeof TaskProcessingKeyTaskProcessingKeyType],
    /**
     * @description Record status
     *  0 = deleted
     *  1 = active.
     * @default 1
     */
    status?: number,
    /**
     * @description Creation timestamp.
     * @default CURRENT_TIMESTAMP
     * @format date-time
     */
    created?: string,
    /**
     * @description Last modification timestamp.
     * @format date-time
     */
    modified?: string | null
}

/** @deprecated */
export interface IRestTaskProcessingKey extends ITaskProcessingKey, IRestObject {
}
