import DateExtended from 'date-extensions';
import {ConfigService} from 'src/services/config.service';
import {Pipe, PipeTransform} from '@angular/core';
import {TranslateService} from 'src/modules/translate/translate.service';

export const DEFAULT_DATE_FORMAT = 'Y-m-d';
export const DEFAULT_TIME_FORMAT = 'H:i:s';
export const DEFAULT_DATE_START_OF_THE_DAY_FORMAT = 'Y-m-dT00:00:00';
export const DEFAULT_DATE_END_OF_THE_DAY_FORMAT = 'Y-m-dT23:59:59';

@Pipe({name: 'date'})
export class DatePipe implements PipeTransform {
    private today = new DateExtended;
    private dtRegex = /^\d{4}-\d{2}-\d{2}$/;
    private yesterday = this.today.clone().sub(1, 'day').format('Y-m-d');
    private year = this.today.format('Y');
    private todayFormatted = this.today.format('Y-m-d');

    constructor(
        private translateService: TranslateService,
        private config: ConfigService
    ) {
    }

    transform(
        value: DateExtended | Date | number | string,
        format?: string,
        hours = true
    ): string {
        if (
            typeof value !== 'string'
            && typeof value !== 'number'
            && !(value instanceof Date)
        ) {
            return value;
        }

        let inputWithHours = true;
        let date: DateExtended;

        if (!(value instanceof Date)) {
            date = new DateExtended(value); // date string or timestamp ?
            inputWithHours = !this.dtRegex.test(value as string);
            if (!date.isValid()) {
                if (typeof value === 'number') {
                    date = new DateExtended(value * 1000); // timestamp without milliseconds ?
                    if (!date.isValid()) {
                        return '-';
                    } else {
                        inputWithHours = true;
                    }
                } else {
                    return '-';
                }
            }
        } else if (!(value instanceof DateExtended)) {
            date = DateExtended.createFromDate(value);
        } else {
            date = value;
        }

        let withHours = false;
        if (date.getHours()) {
            withHours = true;
        }
        withHours = withHours && hours;

        // convert date to
        if (withHours) {
            date = new DateExtended(date.toLocaleString('en-us', {timeZone: this.config.get('timezone')}));
        }

        if (typeof format !== 'undefined' && format !== '') {
            if (format === 'full') {
                if (withHours) {
                    return date.format(`${DEFAULT_DATE_FORMAT} ${DEFAULT_TIME_FORMAT}`);
                } else {
                    return date.format(DEFAULT_DATE_FORMAT);
                }
            }
            return date.format(format);
        }

        const showHours = inputWithHours && withHours && hours;
        if (this.todayFormatted === date.format('Y-m-d')) {
            return this.translateService.get('TODAY') + (showHours ? ' ' + date.format('H:i') : '');
        } else if (date.format('Y-m-d') === this.yesterday) {
            return this.translateService.get('YESTERDAY') + (showHours ? ' ' + date.format('H:i') : '');
        } else if (date.format('Y') !== this.year) {
            return date.format('d M Y') + (showHours ? ' ' + date.format('H:i') : '');
        }
        return date.format('d M') + (showHours ? ' ' + date.format('H:i') : '');
    }
}
