<div class="table-responsive" [tableTools]="tableConfig" [ngClass]="tableConfig.tableContainerCss">
    <table class="table table-hover" [ngClass]="tableConfig.tableCss"
           [class.dynamicTable--loading]="tableConfig.loading"
           [class.dynamicTable--noResults]="tableConfig.dataLength === 0">
        <thead>
            <tr>
                <th *ngIf="tableConfig.sortable.enabled" class="sortable-column ignore-export"></th>
                <th *ngIf="tableConfig.selectedItemOperations?.length" class="text-center ignore-export">
                    <tt-select-all></tt-select-all>
                </th>
                <ng-container *ngFor="let column of tableConfig.columns">
                    <th *ngIf="!column.hidden && configured"
                        [ttSort]="column.th.sort"
                        (click)="updateTableSettings()"
                        [dynamicTableCell]="column">
                    </th>
                </ng-container>
                <th actions *ngIf="tableConfig.itemOperations?.length"></th>
            </tr>
        </thead>
        <tbody [sortable]="tableConfig.data | async" [sortableEnabled]="tableConfig.sortable.enabled"
               (dragOver)="tableConfig.sortable.animation.onDragOver($event.dragItem, $event.targetItem)"
               (dragEnd)="tableConfig.sortable.animation.onDragEnd($event.baseDragItem, $event.dragItem)"
               (dragStart)="tableConfig.sortable.animation.onDragStart($event.dragItem)"
               (sortStop)="tableConfig.sortable.onSortStop($event)">
            <tr *ngFor="let item of tableConfig.data | async"
                [ngStyle]="tableConfig.rowStyle ? rowStyleContext.get(item) :  null"
                [ngClass]="tableConfig.rowClass ? rowClassContext.get(item) :  null"
                [rowClick]="rowClickContext.get(item)"
                [sortableItem]="item">
                <td *ngIf="tableConfig.sortable.enabled" class="sortable-column">
                    <sortable-drag-handle></sortable-drag-handle>
                </td>
                <td *ngIf="tableConfig.selectedItemOperations?.length" class="text-center">
                    <tt-select [item]="item"></tt-select>
                </td>
                <ng-container *ngFor="let column of tableConfig.columns">
                    <td *ngIf="!column.hidden" [dynamicTableCell]="column" [item]="item">
                    </td>
                </ng-container>
                <td actions *ngIf="tableConfig.itemOperations?.length">
                    <operation *ngFor="let operation of tableConfig.itemOperations"
                               (operationSuccess)="handleOperationSuccess($event, operation.operationClass)"
                               [operationClass]="operation.operationClass"
                               [context]="itemOperationsContext.get(item).get(operation)">
                    </operation>
                </td>
            </tr>
            <tr ttRowPlaceholder></tr>
        </tbody>
        <tfoot *ngIf="tableConfig.selectedItemOperations?.length">
            <tr>
                <td class="text-center">
                    <tt-select-all></tt-select-all>
                </td>
                <td colspan="100%">
                    <div [(bsDropdown)]="selectedItemsDropdown">
                        <button bsDropdownToggle class="btn btn-primary dropdown-toggle" type="button"
                                [disabled]="!tableConfig.selected.hasSelected() && !hasAllItemOperations">
                            {{'COMMON_SELECTED' | translate}}
                        </button>
                        <div class="dropdown-menu">
                            <ng-container *ngFor="let operation of tableConfig.selectedItemOperations">
                                <operation *ngIf="operation.isAllItemOperation
                                                || (!operation.isAllItemOperation && tableConfig.selected.hasSelected())"
                                           (operationSuccess)="$event ? tableConfig.filterData() : null"
                                           [operationClass]="operation.operationClass"
                                           [context]="selectedItemsContext.get(operation)"
                                           viewType="text"
                                           customClass="dropdown-item">
                                </operation>
                            </ng-container>
                        </div>
                    </div>
                </td>
            </tr>
        </tfoot>
    </table>
</div>
