/**
 * This file has been auto generated. Do not edit.
 */
import {IPartner} from '_types/rest/Entity/IRestPartner';
import {IBaseRestEntity} from '_types/rest/IBaseRestEntity';

export const BsgServiceLockType = {
    SERVICE_TYPE_SEO: 1,
    SERVICE_TYPE_ADS: 2,
    SERVICE_TYPE_OTHER: 3
} as const;

export const BsgServiceLockTypeOptions = [
    {
        value: BsgServiceLockType.SERVICE_TYPE_SEO,
        label: 'SERVICE_TYPE_SEO',
    },
    {
        value: BsgServiceLockType.SERVICE_TYPE_ADS,
        label: 'SERVICE_TYPE_ADS',
    },
    {
        value: BsgServiceLockType.SERVICE_TYPE_OTHER,
        label: 'SERVICE_TYPE_OTHER',
    }
] as const;

export interface IBsgServiceLockConst {
    type: [{id: 1, value: 'SERVICE_TYPE_SEO'}, {id: 2, value: 'SERVICE_TYPE_ADS'}, {id: 3, value: 'SERVICE_TYPE_OTHER'}]
}

export interface IBsgServiceLock extends IBaseRestEntity {
    id?: number,
    url: string,
    /**
     * @default 1
     */
    type?: typeof BsgServiceLockType[keyof typeof BsgServiceLockType],
    partner?: IPartner | string | null,
    additionalInfo?: string | null,
    /**
     * @description Record status
     *  0 = deleted
     *  1 = active.
     * @default 1
     */
    status?: number,
    /**
     * @description Creation timestamp.
     * @default CURRENT_TIMESTAMP
     * @format date-time
     */
    created?: string,
    /**
     * @description Last modification timestamp.
     * @format date-time
     */
    modified?: string | null
}
