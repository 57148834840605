import {NgModule} from '@angular/core';
import {AbsenceWarningComponent} from 'src/components/absence-warning/absence-warning.component';
import {BrowserCompatModalComponent} from 'src/components/browser-compat-modal/browser-compat-modal.component';
import {BsCollapseModule, BsModalModule, BsPopoverModule, BsDropdownModule} from 'angular-bootstrap4';
import {DepartmentUserTreeComponent} from 'src/components/department-user-tree/department-user-tree.component';
import {UserPasswordComponent} from 'src/components/user-password/user-password.component';
import {ValidateModule, ValidatorsModule} from 'angular-bootstrap4-validate';
import {RodoCookiesComponent} from 'src/components/rodo-cookies/rodo-cookies.component';
import {WizardComponent} from 'src/components/wizard/wizard.component';
import {QrcodeComponent} from 'src/components/qrcode/qrcode.component';
import {CardCollapseComponent} from 'src/components/card-collapse/card-collapse.component';
import {TreeViewComponent} from 'src/components/tree-view/tree-view.component';
import {InboxComponent} from 'src/components/inbox/inbox.component';
import {AppTableToolsModule} from 'src/modules/app-table-tools/app-table-tools.module';
import {AppCommonModule} from 'src/modules/app-common/app-common.module';
import {TranslateModule} from 'src/modules/translate/translate.module';
import {SortSelectComponent} from 'src/components/sort-select/sort-select.component';
import {RestModule} from 'src/modules/rest/rest.module';
import {AppDirectivesModule} from 'src/directives/app-directives.module';
import {AppFormsModule} from 'src/modules/app-forms/app-forms.module';
import {ExtendedSelectModule} from 'angular-bootstrap4-extended-select';
import {AvatarModule} from 'src/modules/avatar/avatar.module';
import {AbsoluteOverlayComponent} from 'src/components/absolute-overlay/absolute-overlay.component';
import {BadgeComponent} from 'src/components/badge/badge.component';
import {LoaderModule} from 'src/components/loader/loader.module';
import {OperationsModule} from 'src/modules/operations/operations.module';
import {ConstValueComponent} from 'src/components/const-value/const-value.component';
import {FloatingActionsButtonComponent} from 'src/components/floating-actions-button/floating-actions-button.component';
import {GhostLoaderComponent} from 'src/components/ghost-loader/ghost-loader.component';
import {ClientContactPhonesComponent} from 'src/components/client-contact-phones/client-contact-phones.component';
import {DynamicFieldsModule} from 'src/modules/dynamic-fields/dynamic-fields.module';
import {KeyValueControlComponent} from 'src/components/key-value-control/key-value-control.component';
import {ActionsModule} from 'src/modules/actions/actions.module';
import {NotificationDisplayComponent} from 'src/components/notification-display/notification-display.component';
import {BackButtonComponent} from 'src/components/back-button/back-button.component';
import {AlertComponent} from 'src/components/alert/alert.component';
import {NotificationBadgeComponent} from 'src/components/notification-badge/notification-badge.component';
import {ClientContactEmailsComponent} from 'src/components/client-contact-emails/client-contact-emails.component';

@NgModule({
    imports: [
        ActionsModule,
        AppCommonModule,
        AppDirectivesModule,
        AppFormsModule,
        AppTableToolsModule,
        AvatarModule,
        BsCollapseModule,
        BsDropdownModule,
        BsModalModule,
        BsPopoverModule,
        DynamicFieldsModule,
        ExtendedSelectModule,
        LoaderModule,
        OperationsModule,
        RestModule,
        TranslateModule,
        ValidateModule,
        ValidatorsModule
    ],
    declarations: [
        AbsenceWarningComponent,
        AbsoluteOverlayComponent,
        AlertComponent,
        BackButtonComponent,
        BadgeComponent,
        BrowserCompatModalComponent,
        CardCollapseComponent,
        ClientContactEmailsComponent,
        ClientContactPhonesComponent,
        ConstValueComponent,
        DepartmentUserTreeComponent,
        FloatingActionsButtonComponent,
        GhostLoaderComponent,
        InboxComponent,
        KeyValueControlComponent,
        NotificationBadgeComponent,
        NotificationDisplayComponent,
        QrcodeComponent,
        RodoCookiesComponent,
        SortSelectComponent,
        TreeViewComponent,
        UserPasswordComponent,
        WizardComponent
    ],
    exports: [
        AbsenceWarningComponent,
        AbsoluteOverlayComponent,
        AlertComponent,
        AvatarModule,
        BackButtonComponent,
        BadgeComponent,
        BrowserCompatModalComponent,
        CardCollapseComponent,
        ClientContactEmailsComponent,
        ClientContactPhonesComponent,
        ConstValueComponent,
        DepartmentUserTreeComponent,
        FloatingActionsButtonComponent,
        GhostLoaderComponent,
        InboxComponent,
        KeyValueControlComponent,
        LoaderModule,
        NotificationBadgeComponent,
        NotificationDisplayComponent,
        QrcodeComponent,
        RodoCookiesComponent,
        SortSelectComponent,
        TreeViewComponent,
        UserPasswordComponent,
        WizardComponent
    ]
})
export class AppComponentsModule {
}
