import {ErrorHandler, Inject, Injectable, Injector} from '@angular/core';
import {Rejection} from '@uirouter/core';
import {ToastsService} from 'src/modules/global-components/toasts/toasts.service';
import * as Sentry from '@sentry/browser';
import {ENV, IEnvironment} from 'app-custom-providers';
import {Integrations} from '@sentry/tracing';
import {Angular as SentryAngularIntegration} from '@sentry/integrations/dist/angular';
import {RestError} from 'src/modules/rest/rest.error';
import {RestClient} from 'src/modules/rest/rest-client.service';
import {TranslateService} from 'src/modules/translate/translate.service';


// eslint-disable-next-line @angular-eslint/use-injectable-provided-in
@Injectable()
export class ExceptionHandler extends ErrorHandler {
    private readonly isSentryIntegrated: boolean = false;

    constructor(
        private injector: Injector,
        @Inject(ENV) env: IEnvironment
    ) {
        super();
        if (env.env.sentryDsn) {
            this.isSentryIntegrated = true;
        }
    }

    handleError(error: Error | RestError): void {
        super.handleError(error);

        if (error instanceof RestError) {
            this.handleRestError(error);
            return;
        }

        // Print the error to the console, but do not report it to the user.
        // Rejection is not an error preventing the use of the application.
        // Can be triggered by the user clicking too fast.
        if (error instanceof Rejection) {
            return;
        }

        this.popErrorToast();
        this.logInSentry(error);
    }

    static initSentry(sentryDsn: string): void {
        const sentryIntegration = new SentryAngularIntegration();
        Sentry.init({
            dsn: sentryDsn,
            integrations: [
                sentryIntegration,
                new Integrations.BrowserTracing(),
                new Sentry.Integrations.Breadcrumbs({
                    console: false
                })
            ],
            tracesSampleRate: 0.4
        });
    }

    private handleRestError(error: RestError): void {
        const restClient = this.injector.get(RestClient);
        restClient.handleError(error);
        if (error.code === 500) {
            this.logInSentry(error);
        }
    }

    private popErrorToast(): void {
        const toastsService = this.injector.get(ToastsService);
        const translateService = this.injector.get(TranslateService);

        toastsService.pop(
            translateService.get('EXCEPTION_HANDLER_TOAST'),
            undefined,
            'error',
            {
                uid: 'exception-handler-toast'
            }
        );
    }

    private logInSentry(error: Error): void {
        if (this.isSentryIntegrated) {
            Sentry.captureException(error);
        }
    }
}
