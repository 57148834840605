import {Component, OnInit} from '@angular/core';
import {TaskViewComponent} from 'src/modules/tasks/task-view/task-view.component';
import {RestClient} from 'src/modules/rest/rest-client.service';
import {IOperationResult} from 'src/modules/operations/interfaces';
import {MakeTaskClosedToAssignees, MakeTaskOpenToAssignees} from 'view-modules/operations/tasks/tasks.operations';


export const permissionToAdd = {
    PUBLIC: 1,
    PRIVATE: 2
} as const;

export type ITaskPermission = typeof permissionToAdd[keyof typeof permissionToAdd];

@Component({
    selector: 'task-permission-to-add',
    templateUrl: './task-permission-to-add.component.html',
})
export class TaskPermissionToAddComponent implements OnInit {
    dropdown: boolean;
    permissionToAdd = permissionToAdd;
    loading = false;

    taskPermissionOperations = {
        makeTaskOpen: MakeTaskOpenToAssignees,
        makeTaskClosed: MakeTaskClosedToAssignees
    } as const;

    constructor(
        public readonly taskViewComponent: TaskViewComponent,
        private readonly restClient: RestClient
    ) {
    }

    ngOnInit(): void {
        this.initializePermission();
    }

    private initializePermission(): void {
        if (!this.taskViewComponent.task?.id) {
            this.taskViewComponent.task.permissionToAdd = this.permissionToAdd.PUBLIC;
        }
    }

    handleSuccess(operationResult: IOperationResult, permission: ITaskPermission): void {
        if (!operationResult.success) {
            return;
        }

        this.updateTaskModel(permission);
        this.dropdown = false;
        this.restClient.savedToast();
    }

    private updateTaskModel(permission: ITaskPermission): void {
        this.taskViewComponent.task.permissionToAdd = permission;
    }

    handleNewTaskAction(permission: ITaskPermission): void {
        this.updateTaskModel(permission);
        this.dropdown = false;
    }
}
