import {AbstractOperationDefinition} from 'src/modules/operations/interfaces';
import {Injector} from '@angular/core';
import {StateService} from '@uirouter/core';
import {IRestObject} from 'src/modules/rest/objects';
import {AbstractDeleteOperation} from 'view-modules/operations/abstract/abstract-delete.operation';
import {IConfirmModalOptions} from 'src/modules/global-components/confirm-modal/confirm-modal.service';
import {UIRouterGlobals} from '@uirouter/angular';
import {UserPrivileges, UserService} from 'src/modules/rest/user/user.service';

export class AddTaskTemplateOperation extends AbstractOperationDefinition<'task_templates'> {
    readonly endpoint = 'task_templates'
    readonly name = 'post';
    lang = 'ADD';
    icon = 'fa-plus';

    invoke(
        taskTemplate: IRestObject<'task_templates'>,
        injector: Injector
    ): void {
        const state = injector.get(StateService);
        state.go(
            '.add',
            {
                taskTemplateId: taskTemplate.id,
            });
    }
}

export class EditTaskTemplateOperation extends AbstractOperationDefinition<'task_templates'> {
    readonly endpoint = 'task_templates'
    readonly name = 'put';
    lang = 'EDIT';
    icon = 'fa-pencil-alt';

    invoke(
        taskTemplate: IRestObject<'task_templates'>,
        injector: Injector
    ): void {
        const state = injector.get(StateService);
        state.go(
            '.edit',
            {
                taskTemplateId: taskTemplate.id,
            });
    }
}

export class ViewTaskTemplateOperation extends AbstractOperationDefinition<'task_templates'> {
    readonly endpoint = 'task_templates'
    readonly name = 'get';
    lang = 'VIEW';
    icon = 'fa-eye';

    invoke(
        taskTemplate: IRestObject<'task_templates'>,
        injector: Injector
    ): void {
        const state = injector.get(StateService);
        state.go(
            '.edit',
            {
                taskTemplateId: taskTemplate.id,
            });
    }

    access(context: IRestObject<'task_templates'>): boolean {
        return !context.access('put');
    }
}

export class CopyTaskTemplateOperation extends AbstractOperationDefinition<'task_templates'> {
    readonly endpoint = 'task_templates'
    readonly name = 'copy';
    lang = 'ADMIN_TASKS_TEMPLATE_ADD_COPY';
    icon = 'fa-copy';

    invoke(
        taskTemplate: IRestObject<'task_templates'>,
        injector: Injector
    ): void {
        const state = injector.get(StateService);
        state.go(
            '.addCopy',
            {
                taskTemplateId: taskTemplate.id,
            });
    }

    access(context: IRestObject<'task_templates'>, injector: Injector): boolean {
        const targetState = injector.get(UIRouterGlobals).current.name + '.addCopy' as UserPrivileges;

        return injector.get(UserService).hasPrivileges(targetState);
    }
}

export class OverrideTaskTemplateOperation extends AbstractOperationDefinition<'task_templates'> {
    readonly endpoint = 'task_templates'
    readonly name = 'override';
    lang = 'ADMIN_TASKS_TEMPLATE_ADD_OVERRIDE';
    icon = 'fa-clone';

    invoke(
        taskTemplate: IRestObject<'task_templates'>,
        injector: Injector
    ): void {
        const state = injector.get(StateService);
        state.go(
            '.addOverride',
            {
                taskTemplateId: taskTemplate.id,
            });
    }

    access(context: IRestObject<'task_templates'>, injector: Injector): boolean {
        const targetState = injector.get(UIRouterGlobals).current.name + '.addOverride' as UserPrivileges;

        return injector.get(UserService).hasPrivileges(targetState);
    }
}

export class DeleteTaskTemplateOperation extends AbstractDeleteOperation<'task_templates'> {
    readonly endpoint = 'task_templates';
    lang = 'DELETE';
    confirmMessage = 'TASKS_REMOVE_TEMPLATE_CONFIRM';
    confirmOptions: IConfirmModalOptions = {
        langYes: 'TASKS_REMOVE_TEMPLATE_CONFIRM_LANG_YES',
    };
}

export const taskTemplatesOperations = [
    AddTaskTemplateOperation,
    CopyTaskTemplateOperation,
    EditTaskTemplateOperation,
    OverrideTaskTemplateOperation,
    DeleteTaskTemplateOperation
] as const;
