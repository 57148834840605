import {Component, Input} from '@angular/core';
import {
    ControlValueAccessor,
    FormControl,
} from '@angular/forms';
import {
    AbstractUnvalidatedControlValueAccessor
} from 'src/modules/app-forms/abstract-control-value-accessors/abstract-unvalidated-control-value-accessor';
import {ICopyInputDynamicFieldConfig} from 'src/modules/dynamic-fields/interfaces';

@Component({
    selector: 'copy-input',
    templateUrl: './copy-input.component.html',
    providers: AbstractUnvalidatedControlValueAccessor.getProviders(CopyInputComponent)
})
export class CopyInputComponent extends AbstractUnvalidatedControlValueAccessor implements ControlValueAccessor {
    @Input() fieldConfig: ICopyInputDynamicFieldConfig;

    inputControl = new FormControl();
    copied = false;

    writeValue(value: unknown): void {
        this.inputControl.setValue(value);
    }

    setDisabledState(isDisabled: boolean): void {
        if (isDisabled) {
            this.inputControl.disable({emitEvent: false});
        } else {
            this.inputControl.enable({emitEvent: false});
        }
    }

    copyUrl = (): string => {
        this.copied = true;
        return this.inputControl.value;
    }
}
