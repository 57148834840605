import {EndpointName} from '_types/rest';
import {Injector} from '@angular/core';
import {Observable} from 'rxjs';
import {IRestObject} from 'src/modules/rest/objects';
import {RestClient} from 'src/modules/rest/rest-client.service';
import {OperationComponent} from 'src/modules/operations/operation-component/operation.component';


export type IOperationAbstract = AbstractOperationDefinition<EndpointName, unknown>;

export interface IOperationClass<T extends IOperationAbstract = IOperationAbstract> {
    new (): T;
}

export interface IOperationContext<TBaseContext = unknown, TAdditionalData = unknown> {
    baseContext: TBaseContext;
    additionalData?: TAdditionalData;
}

export const defaultOperationExecutionOptions = {
    allowMinimilize: false
};

export type IOperationExecutionOptions = Partial<typeof defaultOperationExecutionOptions>;

export type IInvokeReturnType = void | boolean | Observable<IOperationResult> | IOperationResult;

export interface AbstractOperationDefinition<
    TEndpoint extends EndpointName,
    TContext = IRestObject<TEndpoint>,
    TResolvedContext = TContext
> {
    readonly name: string;
    readonly endpoint: TEndpoint;
    readonly context: TContext;
    readonly resolvedContext: TResolvedContext;

    icon: string;
    lang: string;
    longLang?: string;
    modalSize?: 'sm' | 'md' | 'lg' | 'xl';
    modalHeaderDisabled: boolean;

    disableAbandonWarning?: boolean;

    component(): Promise<unknown>;

    /**
     * If invoke returns observable, make sure .complete() or .error() will be called
     * otherwise it could lead to memory leaks.
     * <pre>
     *     void - Operation is considered as successful.
     *     boolean - Operation is successful based on value.
     *     IOperationResult - Operation is successful based on returned object value.
     *     Observable<IOperationResult> - Operation is successful based on value from returned Observable next.
     * </pre>
     */
    invoke(context: TResolvedContext, injector: Injector): IInvokeReturnType;

    access(
        context: TContext,
        injector: Injector,
        operationComponent?: OperationComponent
    ): boolean;

    contextResolver?(
        context: IOperationContext<TContext>,
        injector: Injector
    ): Observable<TResolvedContext>;

    onInit(
        context: TContext,
        injector: Injector,
        operationComponent?: OperationComponent
    ): void;

    onDestroy(
        context: TContext,
        injector: Injector,
        operationComponent?: OperationComponent
    ): void;
}

export abstract class AbstractOperationDefinition<
    TEndpoint extends EndpointName,
    TContext = IRestObject<TEndpoint>
> {
    color? = 'primary';
    showHeaderCloseButton = true;
    executionOptions: IOperationExecutionOptions;

    access(
        context: TContext,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        injector: Injector, // injector may be used by derived classes
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        operationComponent?: OperationComponent
    ): boolean {
        if (RestClient.isRestObject(context) || RestClient.isRestCollection(context)) {
            return context.access(this.name);
        }

        return false;
    }

    static contextResolver<
        TStaticContext extends {id: number},
        TStaticResolvedContext = unknown
    >(
        endpoint: EndpointName,
        context: IOperationContext<TStaticContext>,
        injector: Injector
    ): Observable<TStaticResolvedContext> {
        const restClient = injector.get(RestClient);
        return restClient.endpoint<string, TStaticResolvedContext>(endpoint).get(context.baseContext.id);
    }
}

export type IOperationCustomValue<T = unknown> = T;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type IOperationResult<T = any> = {
    success: boolean,
    customValue?: IOperationCustomValue<T>
}
