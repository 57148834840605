import {IUserData, UserLoginService} from 'src/modules/rest/user/user-login.service';
import {RestClientConfig} from 'src/modules/rest/rest-client-config.service';
import {LocalStorage} from 'src/services/local-storage';
import {Observable} from 'rxjs';
import {Location} from '@angular/common';
import {Injectable} from '@angular/core';
import {Route} from '_types/route';
import {featurePrivileges} from 'src/providers/feature-privileges';

export type UserPrivileges = Route | typeof featurePrivileges[number];

@Injectable({
    providedIn: 'root'
})
export class UserService {
    get commonRoutePrivileges(): string[] {
        return this._commonRoutePrivileges;
    }

    set commonRoutePrivileges(privileges: string[]) {
        this._commonRoutePrivileges = privileges;
    }

    private _commonRoutePrivileges: string[] = [];


    constructor(
        public login: UserLoginService,
        private location: Location
    ) {
    }

    /**
     * Checks if user is authorized. If not it tries to refresh the access token
     */
    checkAuth(): Observable<boolean> {
        return new Observable<boolean>((subscriber) => {
            if (this.location.path().indexOf('/login-by-token') === 0) {
                this.login.handleLogout();
                subscriber.next(false);
                subscriber.complete();
                return;
            }

            if (!this.login.isAuthorized()) {
                // ensure user login state by trying to refresh access token
                this.login.refresh().subscribe(() => {
                    subscriber.next(this.login.isAuthorized());
                    subscriber.complete();
                });
            } else {
                subscriber.next(true);
                subscriber.complete();
            }
        });
    }

    /**
     * Return currently logged-in user data
     */
    get(): IUserData | undefined {
        return LocalStorage.get<IUserData>(RestClientConfig.USER_DATA_KEY, undefined);
    }

    /**
     * Set currently logged-in user data
     */
    set(userData: IUserData): void {
        this.login.setUserData(userData);
    }

    /**
     * Check if user has privileges to view given route or feature
     */
    hasPrivileges(privilege: UserPrivileges): boolean {
        if (privilege.startsWith('user.settings.notifications')) {
            privilege = 'feature.notification';
        }

        if (
            this._commonRoutePrivileges.filter((noPrivilegeRoute) => {
                return noPrivilegeRoute === privilege
                    || privilege.indexOf(`${noPrivilegeRoute}.`) === 0
                    || privilege.indexOf(`${noPrivilegeRoute}_`) === 0;
            }).length
        ) {
            return true;
        }
        const user = this.get();
        if (typeof user !== 'undefined' && typeof user.privileges !== 'undefined') {
            return user.privileges.includes(privilege);
        }
        return false;
    }
}
