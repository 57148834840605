import {animate, animation, style} from '@angular/animations';


export const fadeInUpAnimation = animation([
    style({ opacity: 0 , transform: 'translate3d(0, 100%, 0)'}),
    animate(
        '{{ time }}',
        style({ opacity: 1 , transform: 'none'})
    )
]);
