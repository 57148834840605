import {Inject, Injectable, Injector} from '@angular/core';
import {IGdprConsent} from '_types/custom/IGdprConsent';
import {RestClient} from 'src/modules/rest/rest-client.service';
import {catchError, map, tap} from 'rxjs/operators';
import {EMPTY, Observable, throwError} from 'rxjs';
import * as md5 from 'blueimp-md5';
import {RestError} from 'src/modules/rest/rest.error';
import {of} from 'rxjs';
import {IUserLoginAuthenticationType} from '_types/rest/Entity/IRestUserLoginAuthenticationType';
import {LocalStorage} from 'src/services/local-storage';
import {RestClientConfig} from 'src/modules/rest/rest-client-config.service';
import {ConfigService} from 'src/services/config.service';
import {ENV, IEnvironment, WINDOW} from 'app-custom-providers';
import {RouteHelper} from 'src/modules/route-helper/route-helper.service';
import {StateService} from '@uirouter/core';
import {UserService} from 'src/modules/rest/user/user.service';
import {UrlParserService} from 'src/services/url-parser.service';
import {DynamicTableService} from 'src/modules/dynamic-table/dynamic-table.service';
import {HttpStatusCode} from '@angular/common/http';
import {TimeTrackerService} from 'src/modules/time-tracker/time-tracker.service';
import {ConfirmModalService} from 'src/modules/global-components/confirm-modal/confirm-modal.service';

interface ILoginRequestData {
    'login_token': string,
    fp: string,
    code?: string
    password?: string
}

interface ILoginMfaResponse {
    MFA: {
        blocked?: boolean,
        enabled?: boolean,
        qr: string,
        token: string,
        authenticationType: string
    }
    'login_token'?: string,
    authenticationTypes?: IUserLoginAuthenticationType[],
}

type ILoginResponse = {
    error: 'PASSWORD_EXPIRED'
} | {
    error: 'GDPR_REQUIRED',
    loginToken: string,
    gdprList: IGdprConsent[]
}

export interface ILoginResponseData {
    status: boolean,
    error?: string,
    mfa?: ILoginMfaResponse,
    passwordExpired?: boolean,
    gdpr?: {
        loginToken: string
        consents: IGdprConsent[]
    }
}

interface ILoginSuccessUserResponse {
    id: number,
    email: string,
    firstName: string,
    lastName: string,
    locale: string,
    timezone: string,
    companyType: number,
    companyId: number,
    fileName: string,
    phone: string,
    partnerId: number,
    privileges: string[],
    partnerSwitch?: 1,
    userSwitch?: 1,
    notificationsEnabled: boolean,
    cooperationNoteEnabled: boolean,
    hasSubordinates: boolean,
    'original_user'?: {
        id: number,
        email: string,
        firstName: string,
        lastName: string,
        partnerId: number,
        partnerName: string,
        fileName: string,
        notificationsEnabled: boolean
    }
}

export interface ILoginSuccessResponse {
    'access_token': string,
    'access_expires': number,
    'access_expires_in': number,
    'refresh_token': string,
    'refresh_expires': number,
    'refresh_expires_in': number,
    user: ILoginSuccessUserResponse,
    'return_url'?: string
}

export interface IUserData extends ILoginSuccessUserResponse {
    currentPartner?: number,
    currentCompany?: number,
    currentPartnerObject?: object
}

@Injectable({
    providedIn: 'root'
})
export class UserLoginService {
    constructor(
        private readonly restClient: RestClient,
        private readonly config: ConfigService,
        private readonly injector: Injector,
        private readonly stateService: StateService,
        private readonly urlParser: UrlParserService,
        private readonly timeTrackerService: TimeTrackerService,
        private readonly confirmModal: ConfirmModalService,
        @Inject(WINDOW) private readonly window: Window,
        @Inject(ENV) private readonly environment: IEnvironment
    ) {
    }

    isAuthorized(): boolean {
        return LocalStorage.get(RestClientConfig.ACCESS_TOKEN_EXPIRES_KEY, 0)
            > new Date().getTime();
    }

    isRefreshTokenActive(): boolean {
        return LocalStorage.get(RestClientConfig.REFRESH_TOKEN_EXPIRES_KEY, 0)
            > new Date().getTime();
    }

    /**
     * Get login token
     */
    getToken(username: string): Observable<string> {
        return this.restClient
            .endpoint('oauth')
            .create({
                username: username
            })
            .pipe(
                map((response) => response['login_token'])
            );
    }

    getFingerPrint(): string {
        return md5([
            navigator.userAgent
        ].join('|'));
    }

    login(token: string, password: string, isMFA = false): Observable<ILoginResponseData> {
        const endpoint = isMFA ? 'user_logins/mfa/login' : 'oauth',
            data: ILoginRequestData = {
                login_token: token,
                fp: this.getFingerPrint()
            };

        if (isMFA) {
            data.code = password;
        } else {
            data.password = password;
        }

        return this.restClient
            .endpoint(endpoint)
            .create(data)
            .pipe(
                map((response) => {
                    this._handleLoginResponse(response as unknown as ILoginSuccessResponse);
                    return {status: true};
                }),
                catchError((response: RestError<ILoginMfaResponse | ILoginResponse>) => {
                    let responseData: ILoginResponseData;
                    if (response.code === 401) {
                        if (!('MFA' in response.data)) {
                            responseData = {
                                status: false,
                                error: 'LOGIN_INCORRECT'
                            };
                        } else if (response.data.MFA.blocked) {
                            responseData = {
                                status: false,
                                error: 'LOGIN_MFA_BLOCKED'
                            };
                        } else {
                            responseData = {
                                status: false,
                                mfa: response.data
                            };
                        }
                    } else if (response.code === HttpStatusCode.UnprocessableEntity && 'error' in response.data) {
                        if (response.data.error === 'PASSWORD_EXPIRED') {
                            responseData = {
                                status: false,
                                passwordExpired: true
                            };
                        } else {
                            responseData = {
                                status: false,
                                gdpr: {
                                    loginToken: response.data['loginToken'],
                                    consents: response.data['gdprList']
                                }
                            };
                        }
                    } else {
                        return throwError(() => response);
                    }
                    return of(responseData);
                })
            );
    }

    /**
     * Set currently logged-in user data
     */
    setUserData(userData: IUserData): void {
        LocalStorage.set(RestClientConfig.USER_DATA_KEY, userData);
    }

    private _handleLoginResponse(response: ILoginSuccessResponse): void {
        const time = (new Date()).getTime();
        LocalStorage.set(
            RestClientConfig.ACCESS_TOKEN_EXPIRES_KEY,
            time + (response.access_expires_in * 1000)
        );
        LocalStorage.set(RestClientConfig.REFRESH_TOKEN_EXPIRES_KEY,
            time + (response.refresh_expires_in * 1000)
        );
        LocalStorage.set(RestClientConfig.ACCESS_TOKEN_KEY,
            response.access_token
        );
        LocalStorage.set(RestClientConfig.REFRESH_TOKEN_KEY,
            response.refresh_token
        );
        DynamicTableService.clearSettings();
        this.setUserData(response.user);
        this.config.setLocale(response.user.locale).subscribe();
    }

    impersonate(token: string): Observable<boolean> {
        return this.restClient.endpoint('oauth')
            .create({impersonate_token: token})
            .pipe(
                map((response) => {
                    this._handleLoginResponse(response as unknown as ILoginSuccessResponse);
                    return true;
                }),
                catchError((e: RestError) => {
                    if (e.code === 401) {
                        return of(false);
                    }
                    return throwError(() => e);
                })
            );
    }

    loginByResponse(response: ILoginSuccessResponse): void {
        this.handleLogout();
        this._handleLoginResponse(response);
        this.window.location.href = this.environment.dist.private;
    }

    handleLogout(): void {
        LocalStorage.drop(RestClientConfig.ACCESS_TOKEN_EXPIRES_KEY);
        LocalStorage.drop(RestClientConfig.REFRESH_TOKEN_EXPIRES_KEY);
        LocalStorage.drop(RestClientConfig.ACCESS_TOKEN_KEY);
        LocalStorage.drop(RestClientConfig.REFRESH_TOKEN_KEY);
        LocalStorage.drop(RestClientConfig.USER_DATA_KEY);
        DynamicTableService.clearSettings();
    }

    /**
     * Logout & destroy all session information
     */
    logout(): void {
        if (typeof this.timeTrackerService.activeEntry === 'undefined') {
            this.logoutRequest().subscribe();
            return;
        }

        this.confirmModal.confirm(
            'LOGOUT_ACTIVE_TIME_TRACKER_CONFIRM',
            () => {
                return this.logoutRequest();
            }, {
                langYes: 'LOGOUT',
                text: 'LOGOUT_ACTIVE_TIME_TRACKER_TEXT',
            });
    }

    private logoutRequest(): Observable<void> {
        if (!this.isAuthorized()) {
            return of(undefined);
        }

        return this.restClient.endpoint('oauth')
            // @ts-expect-error: delete expects id argument but oauth endpoint doesn't expect one...
            .delete()
            .pipe(
                tap(() => {
                    this.handleLogout();
                    this.injector.get(RouteHelper).checkApp().subscribe(() => {
                        this.stateService.go('login');
                    });
                }),
                catchError(() => {
                    return EMPTY;
                })
            );
    }

    /**
     * Try to refresh an auth token
     */
    refresh(): Observable<boolean> {
        return new Observable((subscriber) => {
            const refreshToken = LocalStorage.get('refreshToken', false),
                pendingRefresh = LocalStorage.get('pendingRefresh', 0);
            if (pendingRefresh + 5000 < (new Date()).getTime()) {
                // ignore pending refresh after refreshTimeout seconds - eg. when user closed the browser
                if (
                    refreshToken === false
                    || !this.isRefreshTokenActive()
                ) { // no refreshToken available, do not send an unnecessary request
                    this.handleLogout();
                    subscriber.next(false);
                    subscriber.complete();
                } else {
                    LocalStorage.set('pendingRefresh', (new Date().getTime()));
                    this.restClient.endpoint('oauth')
                        .update(null, {
                            refresh_token: refreshToken
                        })
                        .subscribe({
                            next: (response) => {
                                LocalStorage.set(
                                    RestClientConfig.ACCESS_TOKEN_EXPIRES_KEY,
                                    (new Date()).getTime() + (response['access_expires_in'] * 1000)
                                );
                                LocalStorage.set(RestClientConfig.ACCESS_TOKEN_KEY, response['access_token']);
                                LocalStorage.drop('pendingRefresh');
                                subscriber.next(true);
                                subscriber.complete();
                            },
                            error: () => {
                                LocalStorage.drop('pendingRefresh');
                                this.logout();
                                subscriber.next(false);
                                subscriber.complete();
                            }
                        });
                }
            } else {
                const intervalId = setInterval(() => {
                    if (LocalStorage.get(RestClientConfig.ACCESS_TOKEN_KEY, false) === false) {
                        // refresh failed (access token is dropped from localStorage)
                        subscriber.next(false);
                        subscriber.complete();
                        clearInterval(intervalId);
                    } else if (LocalStorage.get('pendingRefresh', false) === false) {
                        // refresh succeeded (pendingRefresh is dropped from localStorage)
                        subscriber.next(true);
                        subscriber.complete();
                        clearInterval(intervalId);
                    }
                }, 500);
            }
        });
    }

    deimpersonate(): void {
        const user = this.injector.get(UserService).get();
        if (user.original_user) {
            this.restClient.endpoint<'user_logins/deimpersonate',
                ILoginSuccessResponse | { panel: string } | {} | null>('user_logins/deimpersonate')
                .update(user.id, {})
                .subscribe((response) => {
                    if (response === null) { // HTTP 204 No Content
                        this.handleLogout();
                        this.injector.get(RouteHelper).checkApp()
                            .subscribe(() => {
                                this.stateService.go('login');
                            });
                    } else {
                        if ('user' in response) {
                            this._handleLoginResponse(response);
                        } else {
                            this.handleLogout();
                        }

                        if ('return_url' in response) {
                            this.window.location.href = response['return_url'];
                        } else if ('panel' in response) {
                            let port = this.urlParser.parse(this.window.location.href).port;
                            port = port === '80' ? '' : `:${port}`;
                            this.window.location.href = `https://${response.panel + port + this.environment.dist.private}`;
                        } else {
                            this.window.location.reload();
                        }
                    }
                });
        }
    }
}
