import {
    baseKeymap, chainCommands,
    toggleMark
} from 'prosemirror-commands';
import {redo, undo} from 'prosemirror-history';
import {splitListItem} from 'prosemirror-schema-list';
import {AppEditorSchema} from 'src/modules/wysiwyg-editor/configs/app-schema';
import {WysiwygEditorComponent} from 'src/modules/wysiwyg-editor/wysiwyg-editor.component';
import {AppTooltips} from 'src/modules/wysiwyg-editor/configs/app-tooltips';
import {insertCodeBlock, replaceBlockFactory} from 'src/modules/wysiwyg-editor/configs/app-commands';
import {ProsemirrorCommand} from 'src/modules/wysiwyg-editor/interfaces';

export interface IKeymap {
    [key: string]: ProsemirrorCommand;
}

export function getKeyMap(editorComponent: WysiwygEditorComponent): IKeymap {
    return {
        ...baseKeymap,
        'Mod-z': undo,
        'Mod-y': redo,
        'Mod-b': toggleMark(AppEditorSchema.marks.strong),
        'Mod-i': toggleMark(AppEditorSchema.marks.em),
        'Mod-u': toggleMark(AppEditorSchema.marks.underline),
        'Mod-k': insertCodeBlock,
        'Enter': chainCommands(
            splitListItem(AppEditorSchema.nodes.list_item),
            baseKeymap['Enter']
        ),
        'Mod-l': () => {
            editorComponent.tooltipState[AppTooltips.LINK] = true;
            return true;
        },
        'Shift-Enter': replaceBlockFactory('hard_break')
    };
}
