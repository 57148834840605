import {Component, Input} from '@angular/core';
import {ITableTools} from 'angular-bootstrap4-table-tools';
import {IDynamicTable} from 'src/modules/dynamic-table/interfaces';

@Component({
    selector: 'dynamic-table-footer',
    templateUrl: './dynamic-table-footer.component.html'
})
export class DynamicTableFooterComponent<T extends object> {

    @Input() tableConfig: ITableTools<T> & IDynamicTable<T>;

    Infinity = Infinity;
}
