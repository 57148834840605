import {Observable, Subject} from 'rxjs';
import {IMentionEvent} from 'src/modules/wysiwyg-editor/plugins/mention-factory/interfaces';
import {IMentionMetadata} from 'src/modules/wysiwyg-editor/interfaces';
import {IUserLogin} from '_types/rest';


export class MentionsService {
    private userMentionedSubject: Subject<IUserLogin>;
    private mentionEventSubject = new Subject<IMentionEvent>();

    emitMentionEvent(eventType: IMentionEvent): void {
        this.mentionEventSubject.next(eventType);
    }

    getMentionEvent$(): Observable<IMentionEvent> {
        return this.mentionEventSubject.asObservable();
    }

    registerMetadata(metadata: IMentionMetadata): void {
        this.userMentionedSubject = metadata.userMentionedSubject;
    }

    newUserMention(user: IUserLogin): void {
        this.userMentionedSubject.next(user);
    }
}
