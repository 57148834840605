import {AbstractOperationDefinition} from 'src/modules/operations/interfaces';
import {IUserAccessOutputDto, IUserContextDto} from '_types/rest';

export class UserAccessesContextAddOperation extends AbstractOperationDefinition<string, IUserContextDto> {
    readonly endpoint = 'user_accesses';
    readonly name = 'post';
    lang = 'ADD';
    icon = 'fa-plus';

    component = (): Promise<unknown> => import('./user-accesses-context-form/user-accesses-context-form.component');

    access(): boolean {
        return true;
    }
}

export class UserAccessesContextEditOperation extends AbstractOperationDefinition<string, IUserAccessOutputDto> {
    readonly endpoint = 'user_accesses';
    readonly name = 'put';
    lang = 'EDIT';
    icon = 'fa-edit';

    component = (): Promise<unknown> => import('./user-accesses-context-form/user-accesses-context-form.component');

    access(): boolean {
        return true;
    }
}

export const userAccessesContextOperations = [
    UserAccessesContextAddOperation,
    UserAccessesContextEditOperation,
] as const;
