import {ComponentFactoryResolver, Injectable, Injector, TemplateRef} from '@angular/core';
import {
    AppTableToolsService,
    IAppTableToolsOptions
} from 'src/modules/app-table-tools/app-table-tools.service';
import {EndpointName, ITableConfigOutputDto} from '_types/rest';
import {ITableTools, ITableToolsOptions} from 'angular-bootstrap4-table-tools';
import {IRestCollection, IRestObject} from 'src/modules/rest/objects';
import {CellTemplatesComponent} from 'src/modules/dynamic-table/cell-templates/cell-templates.component';
import {
    IDynamicTable,
    IDynamicTableCollection, IDynamicTableColumn,
    IDynamicTableColumnDefinition,
    IDynamicTableDefinition,
    IDynamicTableEndpoint, IDynamicTableFilterDefinition
} from './interfaces';
import {ITableCellTemplateContext} from 'src/modules/dynamic-table/cell-templates/interfaces';
import {DynamicTableFilter} from 'src/modules/dynamic-table/dynamic-table-filters/dynamic-table-filter';
import {DynamicTableFilterGroup} from 'src/modules/dynamic-table/dynamic-table-filters/dynamic-table-filter-group';
import {LocalStorage} from 'src/services/local-storage';
import {Comparison} from 'src/services/comparison';
import {RestClient} from 'src/modules/rest/rest-client.service';
import {concatMap, forkJoin, noop, Observable, of, ReplaySubject} from 'rxjs';
import {RestError} from 'src/modules/rest/rest.error';
import {HttpStatusCode} from '@angular/common/http';
import {catchError, tap} from 'rxjs/operators';
import {StandardAnimationSortableService} from 'src/modules/sortable/animations/standard-animation-sortable.service';
import isEqual from 'lodash.isequal';

export type TtOptions<TOptions extends ITableToolsOptions<object>> = Omit<Partial<TOptions>, 'filters'>;
export type DynamicTableOptions<TEndpoint extends EndpointName, TObject extends object = IRestObject<TEndpoint>>
    = TtOptions<IAppTableToolsOptions<TObject>> & IDynamicTableDefinition<TObject, TEndpoint>

export interface ISerializedTableSettings {
    columns: Pick<IDynamicTableColumn<object>, 'field' | 'hidden'>[],
    filters: Record<string, unknown>,
    order: DynamicTableOptions<string>['order']
}

@Injectable({
    providedIn: 'root'
})
export class DynamicTableService {
    private cellTemplates: CellTemplatesComponent;
    private readonly tableSettingsCache = new Map<string, ReplaySubject<IRestCollection<'table_configs'>>>();

    constructor(
        private appTableToolsService: AppTableToolsService,
        private componentFactoryResolver: ComponentFactoryResolver,
        private injector: Injector,
        private restClient: RestClient,
        private standardAnimationSortableService: StandardAnimationSortableService
    ) {
    }

    createFromEndpoint<TEndpoint extends EndpointName, TObject extends object = IRestObject<TEndpoint>>(
        endpoint: TEndpoint,
        options: DynamicTableOptions<TEndpoint, TObject>
    ): IDynamicTableEndpoint<TEndpoint, TObject> {
        return this.createTableInstance(
            options,
            (ttOptions) => {
                return this.appTableToolsService.createFromEndpoint(endpoint, ttOptions);
            }
        );
    }

    createFromCollection<TObject extends object>(
        options: TtOptions<ITableToolsOptions<TObject>> & IDynamicTableDefinition<TObject>
    ): IDynamicTableCollection<TObject> {
        return this.createTableInstance(
            options,
            (ttOptions) => {
                return this.appTableToolsService.create<TObject>(ttOptions);
            }
        );
    }

    // region IDynamicTable creator
    private createTableInstance<TObject extends object, TTInstance>(
        options: TtOptions<ITableToolsOptions<TObject>> & IDynamicTableDefinition<TObject>,
        baseInstanceCreator: (options: Partial<ITableToolsOptions<TObject>>) => TTInstance
    ): TTInstance & IDynamicTable<TObject> {
        const configured = new ReplaySubject<void>(),
            ttOptions = Object.assign(options, {
                filters: this.createFilterGroup(options),
                asyncConfigurator: (dynamicTableInstance: ITableTools<TObject> & IDynamicTable<TObject>) => {
                    return this.getFilterInitialization$(dynamicTableInstance)
                        .pipe(
                            concatMap(() => this.restoreTableSettings(dynamicTableInstance)),
                            tap(() => {
                                configured.next();
                            })
                        );
                }
            });

        return Object.assign(
            baseInstanceCreator(ttOptions),
            this.extractDynamicTableOptions(options, configured.asObservable())
        );
    }

    createFilterGroupFromFilters(filters: { [p: string]: IDynamicTableFilterDefinition } | DynamicTableFilterGroup):
        DynamicTableFilterGroup | undefined {

        return new DynamicTableFilterGroup(
            Object.entries(filters)
                .reduce((map, [field, config]) => {
                    config.nameAsPlaceholder = true;
                    if (typeof config.field === 'undefined') {
                        config.field = field;
                    }
                    map[field] = new DynamicTableFilter(config);
                    return map;
                }, {})
        );
    }

    private createFilterGroup<T>(options: IDynamicTableDefinition<T>): DynamicTableFilterGroup | undefined {
        if (options.filters instanceof DynamicTableFilterGroup) {
            return options.filters;
        }
        if (typeof options.filters !== 'undefined') {
            return this.createFilterGroupFromFilters(options.filters);
        }

        return undefined;
    }

    private getFilterInitialization$(
        dynamicTableInstance: IDynamicTable<unknown>
    ): Observable<unknown> {
        if (dynamicTableInstance.filters.filterInitialized$) {
            return dynamicTableInstance.filters.filterInitialized$;
        }

        return of(null);
    }

    private extractDynamicTableOptions<T>(
        options: IDynamicTableDefinition<T>,
        configured$: Observable<void>
    ): IDynamicTable<T> {
        const selectedFilters = [];

        return {
            id: options.id,
            name: options.name,
            tableContainerCss: options.tableContainerCss,
            tableCss: options.tableCss,
            searchField: options.searchField !== false,
            columns: options.columns.map((column) => this.processColumn(column)),
            export: options.export !== false,
            collectionOperations: options.collectionOperations,
            itemOperations: options.itemOperations,
            selectedItemOperations: options.selectedItemOperations,
            rowClick: options.rowClick,
            rowClass: options.rowClass,
            rowStyle: options.rowStyle,
            quickFilters: options.quickFilters,
            hideHeader: options.hideHeader,
            hideFooter: options.hideFooter,
            hideFilters: options.hideFilters,
            get selectedFilters() {
                return selectedFilters;
            },
            sortable: {
                enabled: !!options.sortable?.enabled,
                animation: options.sortable?.animation || this.standardAnimationSortableService,
                onSortStop: options.sortable?.onSortStop || noop
            },
            operationSuccessCb: options.operationSuccessCb,
            configured$
        };
    }

    private processColumn<T>(columnDefinition: IDynamicTableColumnDefinition<T>): IDynamicTableColumn<T> {
        return Object.assign({}, columnDefinition, {
            th: Object.assign({}, columnDefinition.th, {
                template: this.getThTemplate(columnDefinition),
                sort: typeof columnDefinition.th.sort === 'string'
                    ? columnDefinition.th.sort
                    : (columnDefinition.th.sort === true ? columnDefinition.field : undefined)
            }),
            td: Object.assign({}, columnDefinition.td, {
                template: this.getTdTemplate(columnDefinition)
            }),
            hidden: !!columnDefinition.hidden
        });
    }

    private getThTemplate<T>(
        columnDefinition: IDynamicTableColumnDefinition<T>
    ): IDynamicTableColumn<T>['th']['template'] {
        if (columnDefinition.th.content instanceof TemplateRef) {
            return columnDefinition.th.content;
        }

        return this.getTemplate('plain');
    }

    private getTdTemplate<T>(
        columnDefinition: IDynamicTableColumnDefinition<T>
    ): IDynamicTableColumn<T>['td']['template'] {
        if (columnDefinition.td?.content instanceof TemplateRef) {
            return columnDefinition.td.content;
        }

        if (
            typeof columnDefinition.td === 'undefined'
            || typeof columnDefinition.td.display === 'undefined'
        ) {
            return this.getTemplate('plain');
        }

        return this.getTemplate(columnDefinition.td.display.type);
    }

    private getTemplate<T extends ITableCellTemplateContext['type']>(
        type: T
    ): TemplateRef<ITableCellTemplateContext & { type: T }> {
        if (!this.cellTemplates) {
            this.cellTemplates = this.componentFactoryResolver
                .resolveComponentFactory(CellTemplatesComponent)
                .create(this.injector)
                .instance;
        }

        return this.cellTemplates[type] as TemplateRef<ITableCellTemplateContext & { type: T }>;
    }

    // endregion IDynamicTable creator

    // region save & restore table settings
    saveTableSettings<TObject extends object>(
        tableInstance: IDynamicTable<TObject>,
        settingTemplateName?: string
    ): Observable<ITableConfigOutputDto | void> {
        if (!tableInstance.settingTemplates) {
            return of(null);
        }

        if (
            !settingTemplateName
            && tableInstance.activeSettingName
        ) {
            settingTemplateName = tableInstance.activeSettingName;
        }

        const alreadySavedIndex = tableInstance.settingTemplates.findIndex(
                Comparison.criteria({settingName: settingTemplateName})
            ),
            isAlreadySavedConfig = tableInstance.settingTemplates[alreadySavedIndex],
            payload = {
                id: isAlreadySavedConfig?.id,
                name: this.getStorageKey(tableInstance),
                settingName: settingTemplateName || null,
                config: this.serializeInstance(tableInstance)
            },
            request = isAlreadySavedConfig
                ? this.restClient.endpoint('table_configs').update(isAlreadySavedConfig.id, payload)
                : this.restClient.endpoint('table_configs').create(payload);

        return request
            .pipe(
                catchError((error) => {
                    if (error.code !== HttpStatusCode.Conflict) {
                        this.restClient.handleError(error);
                    }
                    return of(null);
                }),
                tap((response) => {
                    if (!response) {
                        return;
                    }
                    this.restClient.savedToast();

                    if (isAlreadySavedConfig) {
                        tableInstance.settingTemplates[alreadySavedIndex] = response;
                        return;
                    }
                    tableInstance.settingTemplates.push(response);

                    this.tableSettingsCache.delete(
                        this.getStorageKey(tableInstance)
                    );
                }),
            );
    }

    private serializeInstance(tableInstance: IDynamicTable<object>): ISerializedTableSettings {
        return {
            columns: tableInstance.columns.map((column) => {
                return {
                    field: column.field,
                    hidden: column.hidden
                };
            }),
            filters: Object.entries(tableInstance.filters.controls)
                .reduce((map, [filterName, filter]) => {
                    map[filterName] = filter.value;

                    return map;
                }, {}),
            order: tableInstance['order']
        };
    }

    private getStorageKey<TObject extends object>(tableInstance: IDynamicTable<TObject>): string {
        return `dt_${tableInstance.id}`;
    }

    restoreTableSettings<TObject extends object>(
        tableInstance: IDynamicTable<TObject>,
        settingTemplateName?: string
    ): Observable<void> {
        return new Observable<void>((subscriber) => {
            const storageKey = this.getStorageKey(tableInstance),
                doRestore = (settings: ISerializedTableSettings) => {
                    this.computeActiveSettingName(tableInstance, settingTemplateName);

                    this.restoreColumnSettings(tableInstance, settings);
                    this.restoreFilterSettings(tableInstance, settings);
                    this.restoreOrderSettings(tableInstance, settings);
                    subscriber.next();
                    subscriber.complete();
                };

            if (tableInstance.settingTemplates) {
                const settingToRestore = this.findSetting(settingTemplateName, tableInstance);
                doRestore(settingToRestore.config);
                return;
            }

            forkJoin([
                this.getSettingsSubject(storageKey),
                this.getFilterInitialization$(tableInstance)
            ])
                .subscribe({
                    next: ([savedSettings]) => {
                        const defaultConfig = this.serializeInstance(tableInstance),
                            defaultSettings = {
                                name: null,
                                config: defaultConfig
                            };

                        tableInstance.settingTemplates = [defaultSettings, ...savedSettings];

                        if (!settingTemplateName) {
                            settingTemplateName = LocalStorage.get<string>(storageKey);
                        }

                        const settingToRestore = this.findSetting(settingTemplateName, tableInstance);

                        if (settingToRestore) {
                            doRestore(settingToRestore.config);
                        } else {
                            subscriber.next();
                            subscriber.complete();
                        }
                    },
                    error: (error: RestError) => {
                        if (error.code !== HttpStatusCode.NotFound) {
                            this.restClient.handleError(error);
                        }
                        subscriber.next();
                        subscriber.complete();
                    }
                });
        });
    }

    private computeActiveSettingName(
        tableInstance: IDynamicTable<object>,
        settingTemplateName?: string
    ): void {
        const storageKey = this.getStorageKey(tableInstance);
        if (!settingTemplateName) {
            LocalStorage.drop(storageKey);
            tableInstance.activeSettingName = null;
            return;
        }
        LocalStorage.set(storageKey, settingTemplateName);
        tableInstance.activeSettingName = settingTemplateName;
    }

    private findSetting(
        settingTemplateName: string,
        tableInstance: IDynamicTable<object>
    ): ITableConfigOutputDto {
        const searchedSetting = tableInstance.settingTemplates.find((setting) => {
            if (settingTemplateName) {
                return setting.settingName === settingTemplateName;
            }
            return !setting.settingName;
        });

        if (searchedSetting) {
            return searchedSetting;
        }

        return tableInstance.settingTemplates.find(
            Comparison.criteria({settingName: null})
        );
    }

    private restoreColumnSettings<TObject extends object>(
        tableInstance: IDynamicTable<TObject>,
        savedSettings: ISerializedTableSettings
    ): void {
        try {
            // create reordered array of columns, with visibility settings applied
            const newColumns = [],
                restoredFields = [];
            savedSettings.columns.forEach((savedColumn) => {
                const column = tableInstance.columns.find(Comparison.criteria({field: savedColumn.field}));

                if (column) {
                    column.hidden = savedColumn.hidden;
                    newColumns.push(column);
                    restoredFields.push(column.field);
                }
            });
            // restore columns that are not present in saved settings
            // this may occur if table definition has changed in code
            tableInstance.columns
                .forEach((column, index) => {
                    if (restoredFields.includes(column.field)) {
                        return;
                    }

                    if (index < newColumns.length) {
                        newColumns.splice(index, 0, column);
                    } else {
                        newColumns.push(column);
                    }
                });

            tableInstance.columns = newColumns;
        } catch (e) {
            // eslint-disable-next-line no-console
            console.warn(
                `Error when restoring dynamic-table#${tableInstance.id} column settings, probably wrong format:`,
                e
            );
        }
    }

    private restoreFilterSettings<TObject extends object>(
        tableInstance: IDynamicTable<TObject>,
        savedSettings: ISerializedTableSettings
    ): void {
        try {
            if (typeof savedSettings.filters === 'object' && savedSettings.filters !== null) {
                Object.entries(tableInstance.filters.controls)
                    .forEach(([filterName, filter]) => {
                        if (typeof savedSettings.filters[filterName] !== 'undefined') {
                            if (isEqual(filter.value, savedSettings.filters[filterName])) {
                                return;
                            }
                            filter.setValue(savedSettings.filters[filterName]);
                        }
                    });
            }
        } catch (e) {
            // eslint-disable-next-line no-console
            console.warn(
                `Error when restoring dynamic-table#${tableInstance.id} filter settings, probably wrong format:`,
                e
            );
        }
    }

    private restoreOrderSettings<TObject extends object>(
        tableInstance: IDynamicTable<TObject>,
        savedSettings: ISerializedTableSettings
    ): void {
        try {
            if (Array.isArray(savedSettings.order)) {
                tableInstance['order'] = savedSettings.order;
            }
        } catch (e) {
            // eslint-disable-next-line no-console
            console.warn(
                `Error when restoring dynamic-table#${tableInstance.id} order settings, probably wrong format:`,
                e
            );
        }
    }

    private getSettingsSubject(
        storageKey: string
    ): ReplaySubject<IRestCollection<'table_configs'>> {
        if (this.tableSettingsCache.has(storageKey)) {
            return this.tableSettingsCache.get(storageKey);
        }

        const settingsSubject = new ReplaySubject<IRestCollection<'table_configs'>>(1);
        this.tableSettingsCache.set(storageKey, settingsSubject);
        this.restClient.endpoint('table_configs')
            .getAll({name: storageKey})
            .subscribe({
                next: (result) => {
                    settingsSubject.next(result);
                    settingsSubject.complete();
                },
                error: (e) => {
                    settingsSubject.error(e);
                    this.tableSettingsCache.delete(storageKey);
                }
            });

        return settingsSubject;
    }

    static clearSettings(): void {
        LocalStorage.keys()
            .filter((key) => key.startsWith('dt_'))
            .forEach((key) => {
                LocalStorage.drop(key);
            });
    }

    removeTableSettings(settingTemplate: ITableConfigOutputDto): Observable<void> {
        const model = this.restClient.endpoint('table_configs')
            .createObject(settingTemplate);

        return model.delete()
            .pipe(
                tap(() => {
                    this.restClient.savedToast();
                })
            );
    }

    // endregion save & restore table settings
}
