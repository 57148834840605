import {Injectable} from '@angular/core';
import {concatMap, forkJoin, Observable, of} from 'rxjs';
import {IQueryObject} from 'src/services/url-parser.service';
import {EndpointName, EndpointToType} from '_types/rest';
import {RestClient} from 'src/modules/rest/rest-client.service';
import {map} from 'rxjs/operators';
import {IRestCollection, IRestObject} from 'src/modules/rest/objects';
import {IBaseRestEntity} from '_types/rest/IBaseRestEntity';
import {RestEndpoint} from 'src/modules/rest/rest-endpoint';

@Injectable({
    providedIn: 'root'
})
export class RestSubsequentCollectionService {
    constructor(
        private readonly restClient: RestClient
    ) {
    }

    /**
     * Load all items using multiple requests in chunks based on pagination from hydra.
     * @param endpoint
     * @param query
     */
    getAll<
        TEndpoint extends EndpointName,
        TCollection = EndpointToType<TEndpoint, true>,
        TRestCollection = TCollection extends IBaseRestEntity
            ? IRestCollection<TEndpoint, IRestObject<TEndpoint, TCollection>>
            : TCollection
    >(
        endpoint: TEndpoint,
        query: IQueryObject
    ): Observable<TRestCollection> {
        const restEndpoint = this.restClient.endpoint(endpoint);
        return restEndpoint.getAll(query)
            .pipe(
                concatMap((collection) => {
                    return forkJoin({
                        collection: of(collection),
                        ...this.getSubsequentPaginatedRequestsMap(restEndpoint, collection, query)
                    });
                }),
                map((dataObject) => {
                    const collection = dataObject['collection'];
                    Object.entries(dataObject)
                        .filter(([key]) => key !== 'collection')
                        .map(([, value]) => {
                            collection.push(...value);
                        });

                    return collection as TRestCollection;
                }),
            );
    }

    private getSubsequentPaginatedRequestsMap<TEndpoint extends EndpointName>(
        endpoint: RestEndpoint<TEndpoint>,
        collection: IRestCollection<string>,
        query: IQueryObject,
    ): Record<number, Observable<unknown>> {
        const {totalItems, perPage} = collection.hydra(),
            requests$ = {};

        for (let page = 2; page <= Math.ceil(totalItems / perPage); page++) {
            const finalQuery = {
                ...query,
                page
            };
            requests$[page] = endpoint.getAll(finalQuery);
        }

        return requests$;
    }
}
