import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {ControlValueAccessor, FormControl, ValidationErrors,} from '@angular/forms';
import {IPasswordDynamicFieldConfig} from 'src/modules/dynamic-fields/interfaces';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {
    AbstractControlValueAccessor
} from 'src/modules/app-forms/abstract-control-value-accessors/abstract-control-value-accessor';
import {PasswordInputService} from 'src/modules/dynamic-fields/controls/password-input/password-input.service';
import {Validators} from 'angular-bootstrap4-validate';

export interface IPasswordInputValidators {
    pattern: RegExp,
    validatorMessage: string
}

export type IPasswordInputValidatorsTypes
    = 'smallLetters' | 'bigLetters' | 'numbers' | 'specialChars' | 'minLength' | string

@Component({
    selector: 'password-input',
    templateUrl: './password-input.component.html',
    styleUrls: ['./password-input.component.scss'],
    providers: AbstractControlValueAccessor.getProviders(PasswordInputComponent)
})
export class PasswordInputComponent
    extends AbstractControlValueAccessor
    implements ControlValueAccessor, OnInit, OnDestroy {

    passwordControl = new FormControl();

    @Input() fieldConfig: IPasswordDynamicFieldConfig;
    @Output() readonly initializing: EventEmitter<boolean> = new EventEmitter();

    togglePasswordShow = false;

    private defaultConfig: Partial<IPasswordDynamicFieldConfig> = {
        passwordValidators: {
            smallLetters: {
                pattern: /[a-z]/,
                validatorMessage: 'VALIDATOR_PASSWORD_SMALL_LETTER_MESSAGE',
            },
            bigLetters: {
                pattern: /[A-Z]/,
                validatorMessage: 'VALIDATOR_PASSWORD_BIG_LETTER_MESSAGE',
            },
            numbers: {
                pattern: /[0-9]/,
                validatorMessage: 'VALIDATOR_PASSWORD_NUMBER_MESSAGE',
            },
            specialChars: {
                // eslint-disable-next-line
                pattern: /[!@#\\$%^&*]/,
                validatorMessage: 'VALIDATOR_PASSWORD_SPECIAL_CHAR_MESSAGE',
            },
            minLength: {
                pattern: /^.{12,}$/,
                validatorMessage: 'VALIDATOR_PASSWORD_LENGTH_MESSAGE',
            }
        },
        showPassword: true,
        generate: false,
        generateOptions: {
            minLength: 12,
            maxLength: 16
        },
        autocomplete: null
    };

    private value = '';
    private readonly _destroy$ = new Subject<void>();

    constructor(
        private passwordInputService: PasswordInputService
    ) {
        super();
    }

    ngOnInit(): void {
        this.initializeConfig();
        this.subscribeFormControl();
        this.initializing.next(true);
    }

    private initializeConfig(): void {
        const temp = Object.assign(this.defaultConfig,
            this.fieldConfig);

        Object.assign(this.fieldConfig, temp);
    }

    private subscribeFormControl(): void {
        this.passwordControl.valueChanges
            .pipe(
                takeUntil(this._destroy$),
            )
            .subscribe((value) => {
                this.markAsTouched();
                this.value = value;
                this._onChange(value);
            });
    }

    togglePassword(): void {
        this.togglePasswordShow = !this.togglePasswordShow;
    }

    writeValue(value: string): void {
        this.value = value;
        this.passwordControl.setValue(value, {emitEvent: false});
    }

    randomPassword(): void {
        this.togglePasswordShow = true;
        let password = '',
            loops = 0;
        do {

            password = this.passwordInputService.generate(
                this.fieldConfig.generateOptions.minLength,
                this.fieldConfig.generateOptions.maxLength
            );
            loops++;
            if (loops > 10) {
                throw new Error('A password that met the requirements could not be generated in 10 iterations. '
                    + 'Change the password\'s requirements or implementation of the generating function.');
            }
        } while (this.validateValue(password) !== null);

        this.passwordControl.setValue(password);
    }

    setDisabledState(isDisabled: boolean): void {
        if (isDisabled) {
            this.passwordControl.disable({emitEvent: false});
        } else {
            this.passwordControl.enable({emitEvent: false});
        }
    }

    validate(): ValidationErrors | null {
        let passwordValidationErrors = this.validateValue(this.value || '');

        if (this.fieldConfig.validators?.required) {
            const required = Validators.required(this.passwordControl);

            if (required !== null) {
                passwordValidationErrors = {...passwordValidationErrors, required};
            }
        }
        return passwordValidationErrors;
    }

    private validateValue(value: string): ValidationErrors | null {
        if (this.fieldConfig.passwordValidators === false) {
            return null;
        }

        const errors = Object.entries(this.fieldConfig.passwordValidators).reduce(
            (accum, [validatorName, validator]) => {
                if (validator === false || validator.pattern.test(value)) {
                    return accum;
                }
                accum[validatorName] = true;

                return accum;
            }, {});

        return Object.keys(errors).length ? errors : null;
    }

    ngOnDestroy(): void {
        this._destroy$.next();
        this._destroy$.complete();
    }
}
