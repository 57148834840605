/* eslint-disable max-len */
/**
 * This file has been auto generated. Do not edit.
 */
import {INotificationType} from '_types/rest/Entity/IRestNotificationType';
import {IRestNotificationType} from '_types/rest/Entity/IRestNotificationType';
import {IUserLogin} from '_types/rest/Entity/IRestUserLogin';
import {IRestUserLogin} from '_types/rest/Entity/IRestUserLogin';
import {INotificationToast} from '_types/rest/Entity/IRestNotificationToast';
import {IRestNotificationToast} from '_types/rest/Entity/IRestNotificationToast';
import {IBaseRestEntity} from '_types/rest/IBaseRestEntity';
import {IRestObject} from 'core/rest/rest-object.service';

export const NotificationLevel = {
    NOTIFICATION_LEVEL_CRITICAL: 1,
    NOTIFICATION_LEVEL_IMPORTANT: 2,
    NOTIFICATION_LEVEL_INFO: 3
} as const;

export const NotificationLevelOptions = [
    {
        value: NotificationLevel.NOTIFICATION_LEVEL_CRITICAL,
        label: 'NOTIFICATION_LEVEL_CRITICAL',
    },
    {
        value: NotificationLevel.NOTIFICATION_LEVEL_IMPORTANT,
        label: 'NOTIFICATION_LEVEL_IMPORTANT',
    },
    {
        value: NotificationLevel.NOTIFICATION_LEVEL_INFO,
        label: 'NOTIFICATION_LEVEL_INFO',
    }
] as const;

export interface INotificationConst {
    level: [{id: 1, value: 'NOTIFICATION_LEVEL_CRITICAL'}, {id: 2, value: 'NOTIFICATION_LEVEL_IMPORTANT'}, {id: 3, value: 'NOTIFICATION_LEVEL_INFO'}]
}

export interface INotification extends IBaseRestEntity {
    id?: number,
    /**
     * @default 1
     */
    level: typeof NotificationLevel[keyof typeof NotificationLevel],
    data?: string[] | null,
    relatedObjectIri: string,
    notificationType: INotificationType | string,
    userLogin: IUserLogin | string,
    notificationToasts?: (INotificationToast | string)[],
    /**
     * @description Record status
     *  0 = deleted
     *  1 = active.
     * @default 1
     */
    status?: number,
    /**
     * @description Creation timestamp.
     * @default CURRENT_TIMESTAMP
     * @format date-time
     */
    created?: string,
    /**
     * @description Last modification timestamp.
     * @format date-time
     */
    modified?: string | null
}

/** @deprecated */
export interface IRestNotification extends INotification, IRestObject {
    notificationType: IRestNotificationType | string,
    userLogin: IRestUserLogin | string,
    notificationToasts?: IRestNotificationToast[] | string[],
}
