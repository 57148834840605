import {PipeTransform} from '@angular/core';
import {TranslateService} from 'src/modules/translate/translate.service';

export abstract class AbstractTranslatePipe implements PipeTransform {
    protected abstract translateService: TranslateService;

    transform(
        value: string, ignore = false
    ): string {
        if (this.translateService.isTranslationKey(value)) {
            return this.translateService.get(value, ignore);
        }

        return value;
    }
}
