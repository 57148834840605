import {Injectable} from '@angular/core';
import {
    // eslint-disable-next-line no-restricted-imports
    IFieldFilters, IFilterInstance, IOrder, ITableTools, ITableToolsOptions, ITableToolsResponse, TableToolsService
} from 'angular-bootstrap4-table-tools';
import {EndpointName} from '_types/rest';
import {IParsedHydra} from 'src/modules/rest/hydra';
import {RestClient} from 'src/modules/rest/rest-client.service';
import {IQueryObject, QuerySerializable} from 'src/services/url-parser.service';
import {Subject} from 'rxjs';
import {RestError} from 'src/modules/rest/rest.error';
import {IRestCollection, IRestObject} from 'src/modules/rest/objects';
import {TranslateService} from 'src/modules/translate/translate.service';

export interface IAppTableToolsOptions<TObject extends object> extends ITableToolsOptions<TObject> {
    endpointQuery: IQueryObject,
    dataFactory: (collection: IRestCollection<string>) => IRestCollection<string>,
    mutateQueryCallback: (query: IQueryObject) => void
}

export interface IAppTableTools<TEndpoint extends EndpointName,
    TObject extends object = IRestObject<TEndpoint>>
    extends ITableTools<TObject> {

    endpoint: TEndpoint;
    endpointQuery: IQueryObject;
    hydra: IParsedHydra;
}


@Injectable({
    providedIn: 'root'
})
export class AppTableToolsService {
    constructor(
        private tableToolsService: TableToolsService,
        private restClient: RestClient,
        private translateService: TranslateService
    ) {
    }

    createFromEndpoint<TEndpoint extends EndpointName, TObject extends object = IRestObject<TEndpoint>>(
        endpoint: TEndpoint,
        options?: Partial<IAppTableToolsOptions<TObject>>
    ): IAppTableTools<TEndpoint, TObject> {
        const isPaginationDisabled = typeof options.endpointQuery === 'object' && options.endpointQuery !== null
                && options.endpointQuery.pagination === false,
            instance = this.tableToolsService.create(
                Object.assign({
                    perPage: isPaginationDisabled ? Infinity : 30,
                    perPageOptions: isPaginationDisabled ? [] : [{number: 30, text: '30'}],
                    scrollOffset: -56 // app-header height
                }, options)
            ) as IAppTableTools<TEndpoint, TObject>;
        instance.endpoint = endpoint;
        instance.endpointQuery = options.endpointQuery;
        instance.resolver = this.getResolver<TEndpoint, TObject>(options);
        instance.loading = true;
        instance.filterData();
        return instance;
    }

    create<T extends object>(
        options?: Partial<ITableToolsOptions<T>>
    ): ITableTools<T> {
        return this.tableToolsService.create<T>(
            Object.assign({
                perPage: 30,
                perPageOptions: [{number: 30, text: '30'}],
                scrollOffset: -56 // app-header height
            }, options)
        );
    }

    private getResolver<TEndpoint extends EndpointName,
        TObject extends object = IRestObject<TEndpoint>>(
        options: Partial<IAppTableToolsOptions<TObject>>
    ): IAppTableTools<TEndpoint, TObject>['resolver'] {
        const restClient = this.restClient,
            translateService = this.translateService,
            initialPerPageOptions = options.perPageOptions;

        return function(
            // Resolver is always invoked in TableTools instance context.
            this: IAppTableTools<EndpointName, TObject>,
            limit: number,
            offset: number,
            order: IOrder[],
            search: string,
            filters: IFieldFilters
        ) {
            const response = new Subject<ITableToolsResponse<TObject>>();

            if (!this.endpoint) {
                response.error('API endpoint is not provided!');
                return response.asObservable();
            }

            const apiEndpoint = restClient.endpoint(this.endpoint);
            const query = AppTableToolsService.configureQuery(
                limit,
                offset,
                order,
                search,
                filters,
                this,
                this.endpointQuery,
                options
            );

            this.loading = true;
            apiEndpoint.getAll(query).subscribe({
                next: (endpointResponse: IRestCollection<string>) => {
                    this.hydra = endpointResponse.hydra();
                    if (!initialPerPageOptions) {
                        AppTableToolsService.setPerPage(this.hydra.perPage, this, translateService);
                    }

                    if (typeof options.dataFactory === 'function') {
                        endpointResponse = options.dataFactory(endpointResponse);
                    }

                    response.next(
                        {
                            data: endpointResponse as unknown as TObject[],
                            count: this.hydra.totalItems,
                            countFiltered: this.hydra.totalItems
                        }
                    );
                    this.loading = false;
                    response.complete();
                },
                error: (error: RestError) => {
                    restClient.handleError(error);
                    this.loading = false;
                    response.error(error);
                }
            });

            return response.asObservable();
        } as IAppTableTools<TEndpoint, TObject>['resolver'];
    }

    static configureQuery(
        limit: number,
        offset: number,
        order: IOrder[],
        search: string,
        filters: IFieldFilters,
        tableToolsInstance: ITableTools<object> | IAppTableTools<EndpointName>,
        initialQuery?: IQueryObject,
        options?: Partial<IAppTableToolsOptions<object>>
    ): IQueryObject {
        const query = AppTableToolsService.getInitialQueryObject(initialQuery);
        AppTableToolsService.setPaginationProps(query, tableToolsInstance, limit, offset);
        AppTableToolsService.setOrderProps(query, order);
        AppTableToolsService.setFiltersProps(query, filters);
        AppTableToolsService.setSearchProps(query, tableToolsInstance, search);
        AppTableToolsService.passThroughMutationCb(query, options);
        return query;
    }

    private static getInitialQueryObject(initialQuery?: IQueryObject): IQueryObject {
        return initialQuery ? {...initialQuery} : {};
    }

    private static setPaginationProps(
        query: IQueryObject,
        tableToolsInstance: ITableTools<object> | IAppTableTools<EndpointName>,
        limit: number,
        offset: number
    ): void {
        if (limit !== Infinity) {
            query.page = (offset / limit) + 1;
        }

        if (tableToolsInstance.perPage === Infinity) {
            query.pagination = false;
            return;
        }

        query.itemsPerPage = tableToolsInstance.perPage;
    }

    private static setOrderProps(query: IQueryObject, order: IOrder[]): void {
        order.forEach((item) => {
            query[`order[${item.field}]`] = item.direction;
        });
    }

    private static setFiltersProps(query: IQueryObject, filters: IFieldFilters): void {
        Object.entries(filters).forEach(([filterName, filterItems]) => {
            filterItems.or.forEach((filter) => {
                AppTableToolsService.appendQuery(
                    query,
                    filterName,
                    AppTableToolsService.trimFilterValue(filter)
                );
            });

            filterItems.and.forEach((filter) => {
                AppTableToolsService.appendQuery(
                    query,
                    filterName,
                    AppTableToolsService.trimFilterValue(filter)
                );
            });
        });
    }

    private static trimFilterValue(filter: IFilterInstance): string | number | boolean {
        if (typeof filter.value === 'string') {
            return filter.value.trim();
        }

        return filter.value as number | boolean;
    }

    private static setSearchProps(
        query: IQueryObject,
        tableToolsInstance: ITableTools<object> | IAppTableTools<EndpointName>,
        search: string
    ): void {
        if (
            typeof search === 'string'
            && search.length
            && 'hydra' in tableToolsInstance
            && typeof tableToolsInstance.hydra !== 'undefined'
        ) {
            const trimmedSearch = search.trim();
            tableToolsInstance.hydra.search.forEach((searchCol) => {
                AppTableToolsService.appendQuery(query, searchCol, trimmedSearch);
            });
        }
    }

    private static passThroughMutationCb(
        query: IQueryObject,
        options?: Partial<IAppTableToolsOptions<object>>
    ): void {
        if (typeof options?.mutateQueryCallback === 'function') {
            options.mutateQueryCallback(query);
        }
    }

    private static appendQuery(
        query: IQueryObject,
        key: string,
        value: QuerySerializable | QuerySerializable[]
    ): void {
        if (typeof query[key] === 'undefined') {
            query[key] = value as QuerySerializable;
            return;
        }

        if (!Array.isArray(value)) {
            value = [value];
        }

        if (!Array.isArray(query[key])) {
            query[key] = [query[key]] as string[] | number[];
        }

        const queries = query[key] as QuerySerializable[];
        value.forEach((flatValue) => {
            if (queries.includes(flatValue)) {
                return;
            }

            queries.push(flatValue);
        });
    }

    static setPerPage(
        perPage: number,
        tableToolsInstance: ITableTools<object>,
        translateService: TranslateService
    ): void {
        if (perPage === -1) {
            tableToolsInstance.perPage = Infinity;
            tableToolsInstance.perPageOptions = [{
                number: Infinity,
                text: translateService.get('ALL_ITEMS_PER_PAGE')
            }];
        } else {
            tableToolsInstance.perPage = perPage;
            tableToolsInstance.perPageOptions = [
                {number: perPage, text: perPage.toString()}
            ];
        }
    }
}
