import {ElementRef, Host, Inject, Input, OnDestroy, OnInit, Output, EventEmitter} from '@angular/core';
import {Directive} from '@angular/core';
import {ImgUploadComponent} from 'src/modules/file-upload/img-upload/img-upload.component';
import {scaleCanvas} from 'node/utils/scale-canvas';
import {DOCUMENT} from '@angular/common';
import {Canvas, Image as CanvasImage} from 'canvas';
import {Observable, Subject, Subscription} from 'rxjs';

export interface ICanvasImg {
    thumbnail: string;
    base64: string;
}

export type TypeMime = 'image/png' | 'image/jpeg';


@Directive({
    selector: '[imgUploadCanvas]'
})
export class ImgUploadCanvasDirective implements OnInit, OnDestroy {
    private readonly canvasElement: HTMLCanvasElement;
    private subscription: Subscription;
    @Input('imgUploadCanvas') showImg: Observable<string>;
    @Output() private readonly imgScaled = new EventEmitter<Observable<ICanvasImg>>();

    constructor(
        @Host() private imgUploadComponent: ImgUploadComponent,
        @Inject(DOCUMENT) private document: Document,
        el: ElementRef
    ) {
        if (el.nativeElement.tagName === 'CANVAS') {
            this.canvasElement = el.nativeElement as HTMLCanvasElement;
        }
    }

    ngOnInit(): void {
        this.subscription = this.showImg.subscribe((base64) => {
            const subject = new Subject<ICanvasImg>();
            this.imgScaled.next(subject.asObservable());

            this.canvasElement.width = 0;
            this.canvasElement.height = 0;

            const img = new Image();
            img.src = base64;
            img.onload = () => {
                this.handleLoadedImage(subject, img);
            };
            img.onerror = () => {
                this.handleError(subject);
            };
        });
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    private handleError(subject: Subject<ICanvasImg>) {
        const ctx = this.canvasElement.getContext('2d');
        ctx.clearRect(0, 0, this.canvasElement.width, this.canvasElement.height);
        this.canvasElement.width = 0;
        this.canvasElement.height = 0;
        subject.error('img error');
    }

    private handleLoadedImage(subject: Subject<ICanvasImg>, img: HTMLImageElement): void {
        const result = {
            thumbnail: this.resizeImage(
                img,
                this.imgUploadComponent.thumbnailWidth as number,
                this.imgUploadComponent.thumbnailHeight as number,
                this.canvasElement,
                this.imgUploadComponent.cropToFit,
                this.imgUploadComponent.mimeType
            ),
            base64: undefined
        };
        result.base64 = (
            this.imgUploadComponent.width === this.imgUploadComponent.thumbnailWidth
            && this.imgUploadComponent.height === this.imgUploadComponent.thumbnailHeight
        )
            ? result.thumbnail : this.resizeImage(
                img,
                this.imgUploadComponent.width as number,
                this.imgUploadComponent.height as number,
                undefined,
                this.imgUploadComponent.cropToFit,
                this.imgUploadComponent.mimeType
            );
        subject.next(result);
        subject.complete();
    }

    private resizeImage(
        img: HTMLImageElement,
        width: number,
        height: number,
        canvas: HTMLCanvasElement,
        cropToFit: boolean,
        mimeType: 'image/png' | 'image/jpeg'
    ): string {
        if (typeof canvas === 'undefined') {
            canvas = this.document.createElement('canvas');
        }
        return scaleCanvas(
            img as unknown as CanvasImage,
            canvas as unknown as Canvas,
            width,
            height,
            cropToFit,
            mimeType
        );
    }
}
