import {Inject, Injectable} from '@angular/core';
import {DOCUMENT} from '@angular/common';
import {IRestFileDownload} from '_types/custom/IRestFileDownload';
import {RestClient} from 'src/modules/rest/rest-client.service';
import {Observable, Subject} from 'rxjs';
import {IFile} from '_types/rest';

export const DownloadModeType = {
    DOWNLOAD_FILE: 1,
    PREVIEW_FILE: 2,
    FETCH: 3
} as const;


@Injectable({
    providedIn: 'root'
})
export class FilesService {
    constructor(
        @Inject(DOCUMENT) private document: Document,
        private restClient: RestClient
    ) {
    }

    /**
     * Create a file from base64 and dispatch it as download or open in browser
     */
    base64download(header: string, base64: string, fileName: string, download = true): void {
        const element = this.document.createElement('a');

        if (download) {
            element.setAttribute('href', 'data:' + header + ';base64,' + base64);
            element.setAttribute('download', fileName);
        } else {
            const blob = this.base64toBlob(base64, header);

            element.href = URL.createObjectURL(blob);
            element.setAttribute('target', '_blank');
        }

        element.style.display = 'none';
        this.document.body.appendChild(element);
        element.click();
        this.document.body.removeChild(element);
    }

    base64toBlob(base64: string, type = '', sliceSize = 512): Blob {
        const byteCharacters = atob(base64),
            byteArrays = [];

        for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            const slice = byteCharacters.slice(offset, offset + sliceSize);

            const byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }

            byteArrays.push(new Uint8Array(byteNumbers));
        }

        return new Blob(byteArrays, {type: type});
    }

    createAndDownloadFile(contents: string, fileName: string, mimeType: string): void {
        const blob = new Blob([`\ufeff${contents}`], {type: `${mimeType};utf-8`});
        if ('msSaveOrOpenBlob' in navigator) {
            navigator.msSaveOrOpenBlob(blob, fileName);
        } else {
            const a = this.document.createElement('a');
            a.setAttribute('style', 'display: none');
            a.href = URL.createObjectURL(blob);
            a.download = fileName;
            this.document.body.appendChild(a);
            a.click();
            URL.revokeObjectURL(a.href);
            a.remove();
        }
    }

    /**
     * Download a file
     * @param parentEntity - entity object that contains a reference to file
     * @param parentProperty - name of the file property in given parentEntity
     * @param [fileName] - optional download fileName (defaults to fileName returned with downloaded file)
     * @param downloadMode
     */
    download(
        parentEntity: unknown,
        parentProperty: string,
        fileName?: string,
        downloadMode: typeof DownloadModeType[keyof typeof DownloadModeType] = DownloadModeType.DOWNLOAD_FILE,
    ): Observable<IRestFileDownload> {
        const fileId = typeof parentEntity[parentProperty] === 'object'
            ? (parentEntity[parentProperty] as { id: number }).id
            : this.restClient.parseIri(parentEntity[parentProperty] as string).id;

        const downloadedFileSubject = new Subject<IRestFileDownload>();
        this.restClient.endpoint<'files/download', IRestFileDownload>('files/download')
            .get(
                fileId,
                {
                    item: parentEntity['@id'],
                    property: parentProperty
                })
            .subscribe({
                next: (response) => {
                    if (downloadMode === DownloadModeType.DOWNLOAD_FILE) {
                        this.base64download(
                            response.header,
                            response.content,
                            fileName || response.fileName
                        );
                    }

                    if (downloadMode === DownloadModeType.PREVIEW_FILE) {
                        this.base64download(
                            response.header,
                            response.content,
                            fileName || response.fileName,
                            false
                        );
                    }

                    downloadedFileSubject.next(response);
                },
                complete: () => {
                    downloadedFileSubject.complete();
                },
                error: (err) => {
                    downloadedFileSubject.error(err);
                }
            });
        return downloadedFileSubject.asObservable();
    }

    getExtension(file: IFile): string {
        return file.originalName.split('.').pop();
    }

    getIcon(extension: string): string {
        let icon: string;
        switch (extension) {
            case 'png': case 'jpeg': case 'jpg': case 'gif': case 'svg':
                icon = 'image';
                break;
            case 'doc': case 'docx':
                icon = 'word';
                break;
            case 'xls': case 'xlsx': case 'xlsm':
                icon = 'excel';
                break;
            case 'csv':
                icon = 'csv';
                break;
            case 'pdf':
                icon = 'pdf';
                break;
            case 'txt':
                icon = 'alt';
                break;
            case 'html': case 'xhtml': case 'css': case 'php':
                icon = 'code';
                break;
        }

        return typeof icon === 'undefined' ? 'fa-file' :  `fa-file-${icon}`;
    }
}
