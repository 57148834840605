import {AbstractDeleteOperation} from 'view-modules/operations/abstract/abstract-delete.operation';
import {IConfirmModalOptions} from 'src/modules/global-components/confirm-modal/confirm-modal.service';
import {AbstractOperationDefinition} from 'src/modules/operations/interfaces';
import {With} from '_types/rest';
import {IRestCollection, IRestObject} from 'src/modules/rest/objects';
import {RestClient} from 'src/modules/rest/rest-client.service';

export class DeleteSearchConsoleQueryOperation
    extends AbstractDeleteOperation<'search_console_template_queries'> {
    readonly endpoint = 'search_console_template_queries'

    confirmMessage = 'DELETE_QUERY_CONFIRM';
    confirmOptions: IConfirmModalOptions = {
        langYes: 'DELETE_QUERY_CONFIRM_LANG_YES',
        primaryBtn: 'danger',
        text: 'ACTION_CANNOT_BE_UNDONE'
    }
}

type IViewSearchConsoleQueries = With<
    IRestObject<'search_console_template_queries'>,
    'dimensions' | 'filters' | 'reportedFields'>

interface ISearchConsoleQueryContext {
    template: IRestObject<'search_console_templates'>;
    queries: IViewSearchConsoleQueries | IRestCollection<'search_console_template_queries'>;
}

export class AddSearchConsoleQueryOperation
    extends AbstractOperationDefinition<string, ISearchConsoleQueryContext> {
    readonly endpoint = 'search_console_template_queries';
    readonly name = 'post';
    lang = 'ADD_SEARCH_CONSOLE_QUERY';
    icon = 'fa-plus';

    component = (): Promise<unknown> => import('./template-queries-form/template-queries-form.component');

    access(
        context: ISearchConsoleQueryContext,
    ): boolean {
        const restObject = context.queries;
        if (RestClient.isRestObject(restObject) || RestClient.isRestCollection(restObject)) {
            return restObject.access(this.name);
        }

        return false;
    }
}

export class EditSearchConsoleQueryOperation
    extends AbstractOperationDefinition<string, ISearchConsoleQueryContext> {
    readonly endpoint = 'search_console_template_queries';
    readonly name = 'put';
    lang = 'EDIT';
    icon = 'fa-pencil-alt';

    component = (): Promise<unknown> => import('./template-queries-form/template-queries-form.component');

    access(
        context: ISearchConsoleQueryContext,
    ): boolean {
        const restObject = context.queries;
        if (RestClient.isRestObject(restObject) || RestClient.isRestCollection(restObject)) {
            return restObject.access(this.name);
        }

        return false;
    }
}

export const searchConsoleTemplateQueriesOperations = [
    DeleteSearchConsoleQueryOperation,
    AddSearchConsoleQueryOperation,
    EditSearchConsoleQueryOperation
] as const;
