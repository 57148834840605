<ng-container *ngIf="open">
    <div class="modal-dialog mt-1"
         *ngIf="!isTooltipInInfoState; else infoTemplate">
        <ng-form [formGroup]="linkForm"
             class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">{{'WYSIWYG_ADD_LINK' | translate}}</h5>
            </div>

            <div class="modal-body">
                <div class="form-group">
                    <label for="WYSIWYG_url">{{'URL' | translate}}:</label>
                    <input type="text"
                           formControlName="url"
                           id="WYSIWYG_url"
                           class="form-control"
                           required>
                </div>
                <div class="form-group">
                    <label for="WYSIWYG_url">{{'WYSIWYG_URL_TEXT' | translate}}:</label>
                    <input type="text"
                           formControlName="text"
                           id="WYSIWYG_text"
                           class="form-control"
                           required>
                </div>
            </div>

            <div class="modal-footer">
                <button type="button"
                        class="btn btn-link"
                        (click)="openChange.emit(false)">
                    {{'CANCEL' | translate}}
                </button>

                <button type="button"
                        (click)="addLink()"
                        [disabled]="linkForm.invalid"
                        class="btn btn-primary">
                    {{'SAVE' | translate}}
                </button>
            </div>
        </ng-form>
    </div>

    <ng-template #infoTemplate>
        <div class="modal-content">
            <div class="modal-body d-flex justify-content-around py-1">
                <button type="button"
                        class="btn py-0 my-0 ml-1"
                        (click)="edit()">
                    {{'EDIT_LINK' | translate}}
                </button>

                <div class="d-flex justify-content-center">
                    <a [href]="linkForm.controls.url.value"
                       [title]="'OPEN_IN_NEW_TAB' | translate"
                       class="btn py-0 my-0 border-left"
                       target="_blank">
                        <i class="fas fa-external-link-alt"></i>
                    </a>

                    <button type="button"
                            class="btn py-0 my-0 border-left"
                            [title]="'UNLINK' | translate"
                            (click)="unlink()">
                        <i class="fa fa-unlink"></i>
                    </button>
                </div>
            </div>
        </div>
    </ng-template>
</ng-container>
