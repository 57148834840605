import {NgModule} from '@angular/core';
import {AppCommonModule} from 'src/modules/app-common/app-common.module';
import {ColorListComponent} from 'src/modules/colors/color-list/color-list.component';


@NgModule({
    imports: [
        AppCommonModule
    ],
    declarations: [
        ColorListComponent
    ],
    exports: [
        ColorListComponent
    ]
})
export class ColorModule {
}
