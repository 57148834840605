import {With} from '_types/rest';
import {IFile} from '_types/rest/Entity/IRestFile';
import {IRestObject} from 'src/modules/rest/objects';
import {IBaseRestEntity} from '_types/rest/IBaseRestEntity';
import {RestEndpoint} from 'src/modules/rest/rest-endpoint';

export interface IFileEvent {
    dataTransfer?: {
        files: File[]
    },
    target?: {
        files: File[]
    }
}

export interface IFileModel {
    file: IFile | string;
    created?: string;
    '@operations'?: IBaseRestEntity['@operations'];
}

export type IFileRestModel = IRestObject<string, IFileModel>;

export abstract class FileUploadHelper {
    static getFilesFromEvent($event: Event | IFileEvent): File[] {
        const fileEvent = $event as IFileEvent;
        let files = fileEvent.dataTransfer ? fileEvent.dataTransfer.files : fileEvent.target?.files;
        if (!files) {
            return [];
        }

        if (!Array.isArray(files)) {
            files = Array.from(files);
        }
        return files;
    }

    static prepareFileObjectModelFromFile(file: IFile): With<IFileRestModel, 'file'> {
        return new RestEndpoint<'files/upload', With<IFileModel, 'file'>>('files/upload')
            .createObject({
                file: file
            });
    }
}
