<div>
    <ng-content select="[menuHeader]"></ng-content>

    <nav-tabs class="mb-3" *ngIf="ignoreNesting || nestLevel < 2 && tabs.length > 1" [tabToActivate$]="activateTab$">
        <nav-tab *ngFor="let tab of tabs"
                 [uiSref]="$any(tab.id)"
                 [uiParams]="stateParams"
                 [tabElement]="tab">
        </nav-tab>
    </nav-tabs>

    <back-button *ngIf="!ignoreNesting && nestLevel > 1">
    </back-button>

    <ng-content select="[contentHeader]"></ng-content>

    <ui-view>
        <div class="card">
            <div class="py-4 text-center border-info border-accent-3 card-content">
                <span class="fas fa-info-circle info fa-lg" aria-hidden="true"></span>
                {{'CHOOSE_TAB' | translate}}
            </div>
        </div>
    </ui-view>
</div>
