/* eslint-disable max-len */
/**
 * This file has been auto generated. Do not edit.
 */

import {IBaseRestEntity} from '_types/rest/IBaseRestEntity';
import {IRestObject} from 'core/rest/rest-object.service';

export const CmsTypeType = {
    CMS_TYPE_PAGE: 1
} as const;

export const CmsTypeTypeOptions = [
    {
        value: CmsTypeType.CMS_TYPE_PAGE,
        label: 'CMS_TYPE_PAGE',
    }
] as const;

export const CmsTypeGdprType = {
    CMS_TYPE_GDPR_TYPE_NONE: 0,
    CMS_TYPE_GDPR_TYPE_TERMS: 1,
    CMS_TYPE_GDPR_TYPE_PRIVACY_POLICY: 2
} as const;

export const CmsTypeGdprTypeOptions = [
    {
        value: CmsTypeGdprType.CMS_TYPE_GDPR_TYPE_NONE,
        label: 'CMS_TYPE_GDPR_TYPE_NONE',
    },
    {
        value: CmsTypeGdprType.CMS_TYPE_GDPR_TYPE_TERMS,
        label: 'CMS_TYPE_GDPR_TYPE_TERMS',
    },
    {
        value: CmsTypeGdprType.CMS_TYPE_GDPR_TYPE_PRIVACY_POLICY,
        label: 'CMS_TYPE_GDPR_TYPE_PRIVACY_POLICY',
    }
] as const;

export const CmsTypeOwnerType = {
    CMS_TYPE_OWNER_TYPE_NONE: 0,
    CMS_TYPE_OWNER_TYPE_CLIENT: 1,
    CMS_TYPE_OWNER_TYPE_PARTNER: 2
} as const;

export const CmsTypeOwnerTypeOptions = [
    {
        value: CmsTypeOwnerType.CMS_TYPE_OWNER_TYPE_NONE,
        label: 'CMS_TYPE_OWNER_TYPE_NONE',
    },
    {
        value: CmsTypeOwnerType.CMS_TYPE_OWNER_TYPE_CLIENT,
        label: 'CMS_TYPE_OWNER_TYPE_CLIENT',
    },
    {
        value: CmsTypeOwnerType.CMS_TYPE_OWNER_TYPE_PARTNER,
        label: 'CMS_TYPE_OWNER_TYPE_PARTNER',
    }
] as const;

export interface ICmsTypeConst {
    type: [{id: 1, value: 'CMS_TYPE_PAGE'}],
    gdprType: [{id: 0, value: 'CMS_TYPE_GDPR_TYPE_NONE'}, {id: 1, value: 'CMS_TYPE_GDPR_TYPE_TERMS'}, {id: 2, value: 'CMS_TYPE_GDPR_TYPE_PRIVACY_POLICY'}],
    ownerType: [{id: 0, value: 'CMS_TYPE_OWNER_TYPE_NONE'}, {id: 1, value: 'CMS_TYPE_OWNER_TYPE_CLIENT'}, {id: 2, value: 'CMS_TYPE_OWNER_TYPE_PARTNER'}]
}

export interface ICmsType extends IBaseRestEntity {
    id?: number,
    /**
     * @maxLength 128
     */
    name: string,
    /**
     * @default 1
     */
    type?: typeof CmsTypeType[keyof typeof CmsTypeType],
    gdprType?: typeof CmsTypeGdprType[keyof typeof CmsTypeGdprType],
    ownerType?: typeof CmsTypeOwnerType[keyof typeof CmsTypeOwnerType],
    /**
     * @maxLength 128
     */
    url?: string | null,
    /**
     * @maxLength 256
     */
    description?: string | null,
    required?: number | null,
    public?: number | null,
    /**
     * @description Record status
     *  0 = deleted
     *  1 = active.
     * @default 1
     */
    status?: number,
    /**
     * @description Creation timestamp.
     * @default CURRENT_TIMESTAMP
     * @format date-time
     */
    created?: string,
    /**
     * @description Last modification timestamp.
     * @format date-time
     */
    modified?: string | null
}

/** @deprecated */
export interface IRestCmsType extends ICmsType, IRestObject {
}
