import {NgModule} from '@angular/core';
import {CategoriesLabelsComponent} from 'src/modules/categories/categories-labels/categories-labels.component';
import {AppCommonModule} from 'src/modules/app-common/app-common.module';
import {AppDirectivesModule} from 'src/directives/app-directives.module';
import {AppFormsModule} from 'src/modules/app-forms/app-forms.module';
import {
    CategoriesManagementComponent
} from 'src/modules/categories/categories-management/categories-management.component';
import {EditableFieldModule} from 'src/modules/editable-field/editable-field.module';
import {DynamicFieldsModule} from 'src/modules/dynamic-fields/dynamic-fields.module';
import {ExtendedSelectModule} from 'angular-bootstrap4-extended-select';
import {TranslateModule} from 'src/modules/translate/translate.module';
import {AppComponentsModule} from 'src/components/app-components.module';
import {CategoriesCardComponent} from 'src/modules/categories/categories-card/categories-card.component';

@NgModule({
    imports: [
        AppCommonModule,
        AppComponentsModule,
        AppDirectivesModule,
        AppFormsModule,
        DynamicFieldsModule,
        EditableFieldModule,
        ExtendedSelectModule,
        TranslateModule
    ],
    declarations: [
        CategoriesCardComponent,
        CategoriesLabelsComponent,
        CategoriesManagementComponent
    ],
    exports: [
        CategoriesCardComponent,
        CategoriesLabelsComponent,
        CategoriesManagementComponent
    ]
})
export class CategoriesModule {
}
