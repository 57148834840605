import {EditorView as CodeView, KeyBinding, keymap} from '@codemirror/view';
import {NodeView, EditorView} from 'prosemirror-view';
import {Node} from 'prosemirror-model';
import {EditorState} from '@codemirror/state';
import {history, historyKeymap} from '@codemirror/history';
import {defaultKeymap} from '@codemirror/commands';
import {defaultHighlightStyle} from '@codemirror/highlight';
import {NodeSelection} from 'prosemirror-state';
import {AppEditorSchema} from 'src/modules/wysiwyg-editor/configs/app-schema';
import {javascript} from '@codemirror/lang-javascript';
import {CustomNodesUtils} from 'src/modules/wysiwyg-editor/custom-nodes/custom-nodes.utils';


export class CodeBlockView implements NodeView {
    dom: HTMLElement;
    private readonly codeView: CodeView;

    constructor(
        public node: Node,
        private view: EditorView,
        private getPos: () => number
    ) {
        this.dom = document.createElement('pre');

        const state = this.createCodeBlockState();
        this.codeView = new CodeView({
            state: state,
            parent: this.dom,
            dispatch: (transaction) => {
                this.codeView.update([transaction]);
                this.passChangesToParentEditor();
            }
        });
    }

    private createCodeBlockState(): EditorState {
        const customKeymap: KeyBinding[] = [
            {
                key: 'Backspace',
                run: () => {
                    CustomNodesUtils.removeSelfFromEditor(this.view, this.getPos);
                    return true;
                }
            },
            {
                key: 'Shift-Enter',
                run: () => {
                    CustomNodesUtils.insertNodeOutsideCodeEditor(this.view, this.getPos, 'paragraph');
                    return true;
                }
            }
        ];

        return EditorState.create({
            doc: this.getStringWithNewLines(),
            extensions: [
                history(),
                keymap.of([...defaultKeymap, ...historyKeymap, ...customKeymap]),
                javascript(),
                defaultHighlightStyle,
            ]
        });
    }

    // Create string with new lines from editor content html string.
    // It's mandatory since CodeMirror works on string.
    // On BE, we have html with color/new line classes, so we have to prepare string with new lines.
    // Simple adding '\n' to innerText, doesn't work
    private getStringWithNewLines(): string {
        const newLinePlaceIndicator = '&codeMirrorNewLinePlacement',
            helperElement = document.createElement('div');
        helperElement.innerHTML = this.node.attrs.content;

        const newLines = helperElement.querySelectorAll('.cm-line');

        newLines.forEach((newLine: HTMLElement) => {
            newLine.innerText = newLinePlaceIndicator + newLine.innerText;
        });

        return helperElement.innerText.replace(new RegExp(newLinePlaceIndicator, 'g'), '\n');
    }

    private passChangesToParentEditor(): void {
        const tr = this.view.state.tr,
            resolvedPos = tr.doc.resolve(this.getPos()),
            newNode = AppEditorSchema.nodes.code_block.create({content: this.codeView.dom.innerHTML}),
            selection = new NodeSelection(resolvedPos);

        if (!selection.eq(this.view.state.selection)) {
            tr.setSelection(new NodeSelection(resolvedPos));
        }
        tr.replaceSelectionWith(newNode);

        this.view.dispatch(tr);
    }

    update(): boolean {
        return true;
    }

    selectNode(): void {
        this.codeView.focus();
    }

    stopEvent(): boolean {
        return true;
    }

    ignoreMutation(): boolean {
        return true;
    }
}
