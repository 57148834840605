import {
    Component,
    DoCheck,
    EventEmitter,
    Injector,
    Input, KeyValueDiffers, OnDestroy,
    OnInit,
    Output,
} from '@angular/core';
import {Use} from 'src/decorators/use.decorator';
import {AbstractOperationAccessChecker} from 'src/modules/operations/abstract-operation/abstract-operation-access-checker';
import {
    OperationsService
} from 'src/modules/operations/operations.service';
import {ToastsService} from 'src/modules/global-components/toasts/toasts.service';
import {finalize} from 'rxjs/operators';
import {
    IOperationAbstract,
    IOperationContext, IOperationExecutionOptions, IOperationClass,
    IOperationResult
} from 'src/modules/operations/interfaces';
import {Utils} from 'src/services/utils';


type IOperationComponentOperation = IOperationAbstract & {
    pending?: boolean;
}

export type IOperationsViewType = 'buttons' | 'icons' | 'text' | 'buttons-normal';

export interface OperationComponent<TOperation extends IOperationAbstract = IOperationAbstract>
    extends AbstractOperationAccessChecker<TOperation>, Object {
}
@Use(AbstractOperationAccessChecker)
@Component({
    selector: 'operation',
    templateUrl: './operation.component.html'
})
export class OperationComponent<TOperation extends IOperationAbstract = IOperationAbstract>
implements OnInit, DoCheck, OnDestroy {
    @Input() operationClass: IOperationClass<TOperation>;
    @Input() context?: TOperation['context'];
    @Input() additionalData?: IOperationContext['additionalData'];
    @Input() viewType: IOperationsViewType = 'icons';
    @Input() removeTitle = false;
    @Input() cloneContext = true;
    @Input() loadingIcon = false;
    @Input() disabled = false;

    @Input() customClass?: string;
    @Input() customLang?: string;
    @Input() customIcon?: string;
    @Input() customTitle?: string;

    @Input() executionOptions: IOperationExecutionOptions = {};
    /**
     * Combine it with `executionOptions.allowMinimalize`
     * to react for operationSuccess even from outside current view.
     */
    @Input() operationSuccessCb: (operationResult: IOperationResult) => unknown;

    /**
     * Won't emit success after current view changed, when combined with `executionOptions.allowMinimalize`.
     */
    @Output() readonly operationSuccess = new EventEmitter<IOperationResult>();

    @Output() readonly operationPending = new EventEmitter<boolean>();

    operation: IOperationComponentOperation;
    access = false;

    private initialized = false;

    constructor(
        private readonly operationsService: OperationsService,
        private readonly toasts: ToastsService,
        protected readonly injector: Injector,
        protected readonly differs: KeyValueDiffers
    ) {
    }

    ngOnInit(): void {
        if (!this.operationClass) {
            return;
        }

        this.operation = this.operationsService.get(this.operationClass);
        if (!this.operation) {
            return;
        }

        this.performOnInit();
        this.updateAccess();
        this.initialized = true;
    }

    private performOnInit(): void {
        if (typeof this.operation.onInit !== 'function') {
            return;
        }
        this.operation.onInit(this.context, this.injector, this);
    }

    protected updateAccess(): void {
        this.access = typeof this.operation.access === 'function'
            ? this.operation.access(this.context, this.injector, this)
            : true;
    }

    ngDoCheck(): void {
        if (!this.initialized) {
            return;
        }

        if (typeof this.context !== 'object' || this.context === null) {
            this.updateAccessOnPrimitiveChange();
            return;
        }

        this.updateAccessOnObjectChange();
    }

    execute(): void {
        this.operation.pending = true;
        this.operationPending.emit(true);

        let operationResult: IOperationResult;
        const context = this.cloneContext ? Utils.clone(this.context) : this.context;

        this.operationsService.execute(this.operation, context, this.additionalData, this.executionOptions)
            .pipe(
                finalize(() => {
                    this.operation.pending = false;
                    this.operationPending.emit(false);
                }),
            )
            .subscribe({
                next: (value) => {
                    operationResult = value;
                },
                complete: () => {
                    this.operationSuccess.emit(operationResult);

                    if (typeof this.operationSuccessCb === 'function') {
                        this.operationSuccessCb(operationResult);
                    }
                },
                error: (err) => {
                    this.toasts.pop(err, null, 'error');
                }
            });
    }

    ngOnDestroy(): void {
        if (typeof this.operation?.onDestroy !== 'function') {
            return;
        }
        this.operation.onDestroy(this.context, this.injector, this);
    }
}
