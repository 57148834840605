import {SortableItemDirective} from 'src/modules/sortable/sortable-item.directive';
import {Injectable, RendererFactory2} from '@angular/core';
import {SortableDirective} from 'src/modules/sortable/sortable.directive';
import {IAnimationSortable} from 'src/modules/sortable/animations/interfaces';

export type SortableIndicatorElementGetter = (sortableItem: SortableItemDirective) => HTMLElement;

@Injectable({
    providedIn: 'root'
})
export class StandardAnimationSortableService implements IAnimationSortable {
    constructor(
        private rendererFactory: RendererFactory2
    ) {
    }

    onDragOver(
        dragItem: SortableItemDirective,
        targetItem: SortableItemDirective,
        sortableIndicatorElementGetter?: SortableIndicatorElementGetter
    ): void {
        const classInsertAfter = `sortable-${dragItem.sortable.sortableDirection}--insertAfter`;
        const classInsertBefore = `sortable-${dragItem.sortable.sortableDirection}--insertBefore`;

        const renderer = this.rendererFactory.createRenderer(dragItem.sortable.element, null);

        const allElements = this.getAllSortableItemsElements(
                targetItem.sortable, sortableIndicatorElementGetter
            ),
            targetElement = sortableIndicatorElementGetter
                ? sortableIndicatorElementGetter(targetItem) : targetItem.element;

        if (targetItem.insertBefore) {
            renderer.addClass(targetElement, classInsertBefore);
            renderer.removeClass(targetElement, classInsertAfter);
        } else {
            renderer.addClass(targetElement, classInsertAfter);
            renderer.removeClass(targetElement, classInsertBefore);
        }

        allElements.filter((item) => item !== targetElement).forEach((item) => {
            this.clearBeforeAfter(
                item,
                [classInsertBefore, classInsertAfter],
            );
        });
    }

    onDragStart(): void {
        // noop
    }

    onDragEnd(
        baseDragItem: SortableItemDirective,
        dragItem: SortableItemDirective,
        sortableIndicatorElementGetter?: SortableIndicatorElementGetter
    ): void {
        const classInsertAfter = `sortable-${dragItem.sortable.sortableDirection}--insertAfter`;
        const classInsertBefore = `sortable-${dragItem.sortable.sortableDirection}--insertBefore`;

        const allElements = this.getAllSortableItemsElements(dragItem.sortable, sortableIndicatorElementGetter);

        allElements.forEach((item) => {
            this.clearBeforeAfter(
                item,
                [classInsertBefore, classInsertAfter],
            );
        });
    }

    private clearBeforeAfter(
        item: HTMLElement,
        classes: string[],
    ): void {
        const renderer = this.rendererFactory.createRenderer(item, null);

        classes.forEach((className) => {
            renderer.removeClass(item, className);
        });
    }

    private getAllSortableItemsElements(
        parentElement: SortableDirective,
        sortableIndicatorElementGetter?: SortableIndicatorElementGetter
    ): HTMLElement[] {
        return parentElement.sortableItems.map((item) => {
            return sortableIndicatorElementGetter ? sortableIndicatorElementGetter(item) : item.element;
        });
    }
}
