import {
    Component, ComponentFactory,
    ComponentFactoryResolver,
    ComponentRef, EventEmitter,
    Input, OnChanges, OnDestroy,
    OnInit, Output, SimpleChanges,
    Type,
    ViewChild,
    ViewContainerRef
} from '@angular/core';
import {combineLatest, Subject} from 'rxjs';
import {startWith, takeUntil} from 'rxjs/operators';


@Component({
    selector: 'component-renderer',
    templateUrl: './component-renderer.component.html'
})
export class ComponentRendererComponent implements OnInit, OnChanges, OnDestroy {
    @Input() componentType: Type<unknown>;
    @Input() inputValues: Record<string, unknown> = {};

    @Output() readonly outputValues = new EventEmitter<Record<string, unknown>>();

    @ViewChild('componentHost', {read: ViewContainerRef, static: true}) componentHost: ViewContainerRef;

    private componentFactory: ComponentFactory<unknown>;
    private componentRef: ComponentRef<unknown>;

    private readonly _destroy$ = new Subject<void>();

    constructor(
        private readonly componentFactoryResolver: ComponentFactoryResolver,
    ) {
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (!changes.inputValues) {
            return;
        }

        this.updateComponentBindings();
    }

    private updateComponentBindings(): void {
        if (
            !this.componentRef
            || !this.componentFactory
        ) {
            return;
        }

        this.componentFactory.inputs.forEach(({propName}) => {
            if (!(propName in this.inputValues)) {
                return;
            }

            this.componentRef.instance[propName] = this.inputValues[propName];
        });
    }

    ngOnInit(): void {
        this.renderComponent();
        this.emitOutputValues();
        this.updateComponentBindings();
    }

    private renderComponent(): void {
        this.componentFactory = this.componentFactoryResolver.resolveComponentFactory(
            this.componentType
        );
        this.componentRef = this.componentHost.createComponent(
            this.componentFactory
        );
    }

    private emitOutputValues(): void {
        const outputs$ = this.componentFactory.outputs.reduce(
            (acc, {propName}) => {
                if (this.componentRef.instance[propName] instanceof EventEmitter) {
                    acc[propName] = this.componentRef.instance[propName]
                        .pipe(
                            startWith(undefined)
                        );
                }

                return acc;
            },
            {} as Record<string, EventEmitter<unknown>>
        );

        combineLatest(outputs$)
            .pipe(takeUntil(this._destroy$))
            .subscribe((outputValues) => {
                this.outputValues.emit(outputValues);
            });
    }

    ngOnDestroy(): void {
        this._destroy$.next();
        this._destroy$.complete();
    }
}
