import {Inject, Injectable, Injector} from '@angular/core';
import {IRestCollection} from 'src/modules/rest/objects';
import {UserService} from 'src/modules/rest/user/user.service';
import {
    ITimeTrackerBroadcast, ITimeTrackerDeletedTask,
    ITrackedEntry,
    TIME_TRACKER_CHANNEL,
    TrackingEntityWithTimestamp
} from 'src/modules/time-tracker/interfaces';
import {BehaviorSubject, Observable, of, ReplaySubject, Subject} from 'rxjs';
import {TIMETRACKER_FEATURE_PRIVILEGE} from 'src/providers/feature-privileges';
import {BroadcastMessage, BroadcastService} from 'src/services/broadcast.service';
import {RestClient} from 'src/modules/rest/rest-client.service';
import {IService, ITimeTrackerStartOutputDto} from '_types/rest';
import {TranslateService} from 'src/modules/translate/translate.service';
import {Comparison} from 'src/services/comparison';
import {DateUtils} from 'src/services/date-utils';
import {map, tap} from 'rxjs/operators';
import {Utils} from 'src/services/utils';

export interface ITrackingEntry extends ITrackedEntry {
    time?: string;
}

@Injectable({
    providedIn: 'root',
})
export class TimeTrackerService {
    activeEntry: ITrackedEntry = undefined;
    timeTrackerNoteAddedSubject = new Subject<void>();

    trackingEntries: ITrackingEntry[] = [];

    private entityEntriesSubject = new BehaviorSubject<ITrackedEntry[]>([]);

    private initializedSubject = new ReplaySubject<boolean>();
    initialized$ = this.initializedSubject.asObservable();

    constructor(
        private readonly restClient: RestClient,
        private readonly translateService: TranslateService,
        private readonly injector: Injector,
        @Inject(TIME_TRACKER_CHANNEL) private readonly broadcastService: BroadcastService<ITimeTrackerBroadcast>
    ) {
    }

    initialize(): Observable<IRestCollection<'time_tracker_subjects/active'>> {
        if (
            !this.injector.get(UserService).hasPrivileges(TIMETRACKER_FEATURE_PRIVILEGE)
        ) {
            return of(undefined);
        }

        this.handleBroadcastMessages();

        return this.getAndPrepareActiveTrackers();
    }

    private handleBroadcastMessages(): void {
        this.handleStartMessage();
        this.handleStopMessage();
        this.handlePauseMessage();
        this.handleDeleteTaskMessage();
    }

    private handleStartMessage(): void {
        this.broadcastService.messagesOfType('start')
            .subscribe((event: BroadcastMessage<'start', ITrackedEntry>) => {
                this.activeEntry = event.payload;

                this.addAtBeginningTimeTrackerEntriesArray(event.payload);
                this.updatePausedEntries(event.payload);
                this.updateActiveEntries();
            });
    }

    private addAtBeginningTimeTrackerEntriesArray(eventPayload: ITrackedEntry): void {
        const timeTrackerEntryIndex = this.getTimeTrackerEntryIndex(eventPayload);

        if (timeTrackerEntryIndex !== -1) {
            this.trackingEntries.splice(timeTrackerEntryIndex, 1);
        }
        this.trackingEntries.unshift(eventPayload);
    }

    private getTimeTrackerEntryIndex(eventPayload: ITrackedEntry): number {
        return this.trackingEntries.findIndex(
            (entry) => entry.id === eventPayload.id);
    }

    getTimeTrackerEntryByIri(iri: string): ITrackedEntry {
        return this.trackingEntries.find(
            (entry) => entry.iri === iri);
    }

    private updatePausedEntries(eventPayload: ITrackedEntry): void {
        this.trackingEntries
            .forEach((entry) => {
                if (entry.id !== eventPayload.id && entry.working) {
                    entry.loadingTime = true;
                    entry.working = false;

                    this.refreshEntry(entry);
                }
            });
    }

    private refreshEntry(entry: ITrackingEntry): void {
        this.getNewTrackingObjectOfEntry(entry)
            .subscribe((newEntry) => {
                const timeTrackerEntryIndex = this.getTimeTrackerEntryIndex(entry);

                this.trackingEntries[timeTrackerEntryIndex] = this.prepareAppTrackingEntry(newEntry);

                entry.loadingTime = false;
            });
    }

    private updateActiveEntries(): void {
        this.entityEntriesSubject.next(
            Utils.clone(this.trackingEntries)
        );
    }

    private handlePauseMessage(): void {
        this.broadcastService.messagesOfType(['pause'])
            .subscribe((event: BroadcastMessage<'pause', ITrackedEntry>) => {
                this.activeEntry = undefined;

                this.updateTimeTrackerEntryOrPushIfNotExisting(event.payload);
                this.updateActiveEntries();
            });
    }

    private updateTimeTrackerEntryOrPushIfNotExisting(eventPayload: ITrackedEntry): void {
        const newEntryObject = this.prepareAppTrackingEntry(eventPayload),
            timeTrackerEntryIndex = this.getTimeTrackerEntryIndex(eventPayload);

        if (timeTrackerEntryIndex !== -1) {
            this.trackingEntries[timeTrackerEntryIndex] = newEntryObject;
            return;
        }
        this.trackingEntries.push(newEntryObject);
    }

    private handleStopMessage(): void {
        this.broadcastService.messagesOfType(['stop'])
            .subscribe((event: BroadcastMessage<'stop', ITrackedEntry>) => {
                if (event.payload.id === this.activeEntry?.id) {
                    this.activeEntry = undefined;
                }

                this.deleteFromTimeTrackerEntriesIfExisting(event.payload);
                this.updateActiveEntries();
            });
    }

    private deleteFromTimeTrackerEntriesIfExisting(eventPayload: ITrackedEntry): void {
        const timeTrackerEntryIndex = this.getTimeTrackerEntryIndex(eventPayload);

        if (timeTrackerEntryIndex !== -1) {
            this.trackingEntries.splice(timeTrackerEntryIndex, 1);
        }
    }

    private handleDeleteTaskMessage(): void {
        // Removes from this.trackingEntries by event.data.data.iri
        this.broadcastService.messagesOfType('delete-task')
            .subscribe((event: BroadcastMessage<'delete-task', ITimeTrackerDeletedTask>) => {
                const index = this.trackingEntries.findIndex(Comparison.criteria(event.payload));

                if (index !== -1) {
                    this.trackingEntries.splice(index, 1);
                }
                this.updateActiveEntries();
            });
    }

    private getAndPrepareActiveTrackers(): Observable<IRestCollection<'time_tracker_subjects/active'>> {
        return this.restClient.endpoint('time_tracker_subjects/active').getAll()
            .pipe(
                tap((trackers) => {
                    this.trackingEntries = trackers
                        .map((item) => {
                            const trackingEntry = this.getTrackingEntryFromResponse(item);
                            return this.prepareAppTrackingEntry(trackingEntry);
                        })
                        .sort((x, y) => {
                            return Number(y.working) - Number(x.working);
                        });

                    this.activeEntry = this.trackingEntries.find((tracker) => {
                        return tracker.working;
                    });

                    this.initializedSubject.next(true);
                })
            );
    }

    getTrackingEntryFromResponse(response: ITimeTrackerStartOutputDto): ITrackedEntry {
        return {
            id: response.id,
            iri: response.iri,
            name: response.relatedObjectName,
            start: response.start,
            services: response.relatedObjectServices,
            userTimeLoggedTotal: response.userTimeLoggedTotal,
            working: response.working,
        };
    }

    prepareAppTrackingEntry(entry: ITrackedEntry): ITrackingEntry {
        return Object.assign(entry, {
            time: DateUtils.getTimeStringFromSeconds(entry.userTimeLoggedTotal),
        });
    }

    getNewTrackingObjectOfEntry(entry: ITrackedEntry): Observable<ITrackedEntry> {
        return this.restClient.endpoint('time_tracker_subjects/item').get(entry.id)
            .pipe(
                map((response) => {
                    return this.getTrackingEntryFromResponse(response);
                })
            );
    }

    getService(context: TrackingEntityWithTimestamp): IService {
        if (('contractItem' in context)
            && typeof context.contractItem !== 'string'
            && ('service' in context.contractItem)
            && typeof context.contractItem.service !== 'string') {
            return context.contractItem.service;
        }
        return null;
    }

    stopTimeTrackerCounting(entry: ITrackedEntry): void {
        this.broadcastService.publish('stop', entry);
        this.timeTrackerNoteAddedSubject.next();
    }

    getActiveEntries$(iri?: string): Observable<ITrackedEntry[]> {
        return this.entityEntriesSubject.asObservable()
            .pipe(
                map((entityEntries) => {
                    if (!iri) {
                        return entityEntries;
                    }

                    return entityEntries.filter((entry) => {
                        return entry.iri === iri;
                    });
                })
            );
    }
}
