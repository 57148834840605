import {AbstractOperationDefinition} from 'src/modules/operations/interfaces';
import {IACLOrganicGroup} from '_types/rest';
import {IOrganicGroupElement} from 'view-modules/operations/og-roles/og-roles.operations';
import {AbstractDeleteOperation} from 'view-modules/operations/abstract/abstract-delete.operation';

interface OgUserAssignmentContext {
    organicGroupElement: IOrganicGroupElement;
    organicGroup: IACLOrganicGroup
}

export class OgUserAssignmentAddOperation extends AbstractOperationDefinition<string, OgUserAssignmentContext> {
    readonly endpoint = 'a_c_l_o_g_user_assignments';
    readonly name = 'post';
    lang = 'OG_ASSIGN';
    icon = '';

    component = (): Promise<unknown> => import('./og-user-assignments-form.component');

    access(): boolean {
        return true;
    }
}

export class OgUserAssignmentDeleteOperation extends AbstractDeleteOperation<'a_c_l_o_g_user_assignments'> {
    readonly endpoint = 'a_c_l_o_g_user_assignments';
    lang = 'DELETE';
    confirmMessage = 'OG_DELETE_USER_ASSIGNMENT';

    access(): boolean {
        return true;
    }
}

export const ogUserAssignmentsOperations = [
    OgUserAssignmentAddOperation,
    OgUserAssignmentDeleteOperation
] as const;
