import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {
    NG_VALIDATORS,
    NG_VALUE_ACCESSOR,
    NumberValueAccessor,
    ValidationErrors,
    Validator
} from '@angular/forms';
import {
    IDynamicFieldControlValueAccessor,
    INumberDynamicFieldConfig
} from 'src/modules/dynamic-fields/interfaces';
import {IValidateAbstractControl} from 'angular-bootstrap4-validate/lib/abstract-validate-element.directive';
import {Validators} from 'angular-bootstrap4-validate';

@Component({
    selector: 'number-input',
    templateUrl: './number-input.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: NumberInputComponent
        },
        {
            provide: NG_VALIDATORS,
            multi: true,
            useExisting: NumberInputComponent
        }
    ]
})
export class NumberInputComponent
    extends NumberValueAccessor
    implements IDynamicFieldControlValueAccessor, Validator, OnInit {
    @Input() fieldConfig: INumberDynamicFieldConfig;
    @ViewChild('inputElement', {static: true}) inputElement: ElementRef<HTMLInputElement>;

    ngOnInit(): void {
        // @ts-expect-error: we need to override elementRef of BaseControlValueAccessor for proper setDisabled support
        this._elementRef = this.inputElement;

        if (typeof this.fieldConfig.validators === 'object' && this.fieldConfig.validators !== null) {
            ['min', 'max', 'step'].forEach((attr) => {
                if (attr in this.fieldConfig.validators) {
                    this.inputElement.nativeElement.setAttribute(attr, this.fieldConfig.validators[attr]);
                }
            });
        }
    }

    validate(control: IValidateAbstractControl): ValidationErrors | null {
        // support for angular-bootstrap4-validate workaround on https://github.com/angular/angular/issues/2962
        control.__elementValidity = this.inputElement.nativeElement.validity;
        return Validators.number(control);
    }
}
