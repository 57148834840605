<ng-template #default let-category>
    <span>
        {{ category.name | translate}}
   </span>
</ng-template>

<ng-template #badge let-category>
     <span class="badge badge-primary">
         {{ category.name | translate}}
     </span>
</ng-template>

<ng-template #circle let-category>
    <span>
        <i class="fas fa-circle"
           [ngClass]="{'text-primary': !category.color}"
           [style]="{color: category.color}">
        </i>
        <span class="ml-2"> {{category.name | translate}} </span>
    </span>
</ng-template>

<ng-container *ngIf="loading; else categoryTemplate">
    <dynamic-field [ngModel]="null"
                   [inputLoading]="loading"
                   [fieldConfig]="{
                       type: 'select',
                       name: fieldConfig.name,
                       placeholder: fieldConfig.placeholder,
                       nameAsPlaceholder: fieldConfig.nameAsPlaceholder,
                       options: []
                   }">
    </dynamic-field>
</ng-container>

<ng-template #categoryTemplate>
    <dynamic-field [ngClass]="'categorySelect-layoutType--'+fieldConfig.layoutType"
                   [formControl]="categoryControl"
                   [focus]="!!this.editableFieldComponent"
                   [fieldConfig]="{
                       type: 'select',
                       name: fieldConfig.name,
                       placeholder: fieldConfig.placeholder,
                       nameAsPlaceholder: fieldConfig.nameAsPlaceholder,
                       multiple: fieldConfig.multiple
                   }">
        <ng-container *esOptionGroups="let group of nestedCategories; nestedBy: 'categoryNodes'">
            <ng-container *esOptions="let category of group.categories; group: group.name | translate; value: fieldConfig.valueKey">
                <ng-container *ngTemplateOutlet="{'badge': badge, 'circle': circle}[fieldConfig.layoutType] || default; context: {$implicit: category}"></ng-container>
            </ng-container>
        </ng-container>
    </dynamic-field>
</ng-template>
