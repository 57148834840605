/* eslint-disable max-len */
/**
 * This file has been auto generated. Do not edit.
 */
import {ITaskProcessingModel} from '_types/rest/Entity/IRestTaskProcessingModel';
import {IRestTaskProcessingModel} from '_types/rest/Entity/IRestTaskProcessingModel';
import {ICompany} from '_types/rest/Entity/IRestCompany';
import {IRestCompany} from '_types/rest/Entity/IRestCompany';
import {ITaskTemplate} from '_types/rest/Entity/IRestTaskTemplate';
import {IRestTaskTemplate} from '_types/rest/Entity/IRestTaskTemplate';
import {ITaskTemplateComponent} from '_types/rest/Entity/IRestTaskTemplateComponent';
import {IRestTaskTemplateComponent} from '_types/rest/Entity/IRestTaskTemplateComponent';
import {ITaskFile} from '_types/rest/Entity/IRestTaskFile';
import {IRestTaskFile} from '_types/rest/Entity/IRestTaskFile';
import {ITaskNote} from '_types/rest/Entity/IRestTaskNote';
import {IRestTaskNote} from '_types/rest/Entity/IRestTaskNote';
import {ITaskHistory} from '_types/rest/Entity/IRestTaskHistory';
import {IRestTaskHistory} from '_types/rest/Entity/IRestTaskHistory';
import {ITaskUser} from '_types/rest/Entity/IRestTaskUser';
import {IRestTaskUser} from '_types/rest/Entity/IRestTaskUser';
import {IScheduleTask} from '_types/rest/Entity/IRestScheduleTask';
import {IRestScheduleTask} from '_types/rest/Entity/IRestScheduleTask';
import {ITaskProcessingFlag} from '_types/rest/Entity/IRestTaskProcessingFlag';
import {IRestTaskProcessingFlag} from '_types/rest/Entity/IRestTaskProcessingFlag';
import {ITaskAssignment} from '_types/rest/Entity/IRestTaskAssignment';
import {IRestTaskAssignment} from '_types/rest/Entity/IRestTaskAssignment';
import {ITaskAcceptanceCriterion} from '_types/rest/Entity/IRestTaskAcceptanceCriterion';
import {IRestTaskAcceptanceCriterion} from '_types/rest/Entity/IRestTaskAcceptanceCriterion';
import {IContractItem} from '_types/rest/Entity/IRestContractItem';
import {IRestContractItem} from '_types/rest/Entity/IRestContractItem';
import {ICategory} from '_types/rest/Entity/IRestCategory';
import {IRestCategory} from '_types/rest/Entity/IRestCategory';
import {ITaskDeadline} from '_types/rest/Entity/IRestTaskDeadline';
import {IRestTaskDeadline} from '_types/rest/Entity/IRestTaskDeadline';
import {ITaskCustomField} from '_types/rest/Entity/IRestTaskCustomField';
import {IRestTaskCustomField} from '_types/rest/Entity/IRestTaskCustomField';
import {ITaskProcessingKey} from '_types/rest/Entity/IRestTaskProcessingKey';
import {IRestTaskProcessingKey} from '_types/rest/Entity/IRestTaskProcessingKey';
import {ITaskState} from '_types/rest/Entity/IRestTaskState';
import {IRestTaskState} from '_types/rest/Entity/IRestTaskState';
import {ITaskDate} from '_types/rest/Entity/IRestTaskDate';
import {IRestTaskDate} from '_types/rest/Entity/IRestTaskDate';
import {IBaseRestEntity} from '_types/rest/IBaseRestEntity';
import {IRestObject} from 'core/rest/rest-object.service';

export const TaskType = {
    TASK_TYPE_TASK: 1,
    TASK_TYPE_DEFINITION: 2,
    TASK_TYPE_GENERATED_TASK: 3
} as const;

export const TaskTypeOptions = [
    {
        value: TaskType.TASK_TYPE_TASK,
        label: 'TASK_TYPE_TASK',
    },
    {
        value: TaskType.TASK_TYPE_DEFINITION,
        label: 'TASK_TYPE_DEFINITION',
    },
    {
        value: TaskType.TASK_TYPE_GENERATED_TASK,
        label: 'TASK_TYPE_GENERATED_TASK',
    }
] as const;

export const TaskState = {
    TASK_STATE_NEW: 1,
    TASK_STATE_IN_PROGRESS: 2,
    TASK_STATE_COMPLETED: 3,
    TASK_STATE_REOPENED: 4
} as const;

export const TaskStateOptions = [
    {
        value: TaskState.TASK_STATE_NEW,
        label: 'TASK_STATE_NEW',
    },
    {
        value: TaskState.TASK_STATE_IN_PROGRESS,
        label: 'TASK_STATE_IN_PROGRESS',
    },
    {
        value: TaskState.TASK_STATE_COMPLETED,
        label: 'TASK_STATE_COMPLETED',
    },
    {
        value: TaskState.TASK_STATE_REOPENED,
        label: 'TASK_STATE_REOPENED',
    }
] as const;

export const TaskPriority = {
    TASK_PRIORITY_LOW: 1,
    TASK_PRIORITY_MEDIUM: 2,
    TASK_PRIORITY_HIGH: 3,
    TASK_PRIORITY_CRITICAL: 4
} as const;

export const TaskPriorityOptions = [
    {
        value: TaskPriority.TASK_PRIORITY_LOW,
        label: 'TASK_PRIORITY_LOW',
    },
    {
        value: TaskPriority.TASK_PRIORITY_MEDIUM,
        label: 'TASK_PRIORITY_MEDIUM',
    },
    {
        value: TaskPriority.TASK_PRIORITY_HIGH,
        label: 'TASK_PRIORITY_HIGH',
    },
    {
        value: TaskPriority.TASK_PRIORITY_CRITICAL,
        label: 'TASK_PRIORITY_CRITICAL',
    }
] as const;

export const TaskPermissionToAdd = {
    TASK_PERMISSION_TO_ADD_USERS_BY_EVERYONE: 1,
    TASK_PERMISSION_TO_ADD_USER_BY_AUTHOR: 2
} as const;

export const TaskPermissionToAddOptions = [
    {
        value: TaskPermissionToAdd.TASK_PERMISSION_TO_ADD_USERS_BY_EVERYONE,
        label: 'TASK_PERMISSION_TO_ADD_USERS_BY_EVERYONE',
    },
    {
        value: TaskPermissionToAdd.TASK_PERMISSION_TO_ADD_USER_BY_AUTHOR,
        label: 'TASK_PERMISSION_TO_ADD_USER_BY_AUTHOR',
    }
] as const;

export const TaskVisibilityType = {
    VISIBILITY_TYPE_PUBLIC: '/rest/visibility_types/1',
    VISIBILITY_TYPE_PRIVATE: '/rest/visibility_types/2'
} as const;

export const TaskVisibilityTypeOptions = [
    {
        value: TaskVisibilityType.VISIBILITY_TYPE_PUBLIC,
        label: 'VISIBILITY_TYPE_PUBLIC',
    },
    {
        value: TaskVisibilityType.VISIBILITY_TYPE_PRIVATE,
        label: 'VISIBILITY_TYPE_PRIVATE',
    }
] as const;

export interface ITaskConst {
    type: [{id: 1, value: 'TASK_TYPE_TASK'}, {id: 2, value: 'TASK_TYPE_DEFINITION'}, {id: 3, value: 'TASK_TYPE_GENERATED_TASK'}],
    state: [{id: 1, value: 'TASK_STATE_NEW'}, {id: 2, value: 'TASK_STATE_IN_PROGRESS'}, {id: 3, value: 'TASK_STATE_COMPLETED'}, {id: 4, value: 'TASK_STATE_REOPENED'}],
    priority: [{id: 1, value: 'TASK_PRIORITY_LOW'}, {id: 2, value: 'TASK_PRIORITY_MEDIUM'}, {id: 3, value: 'TASK_PRIORITY_HIGH'}, {id: 4, value: 'TASK_PRIORITY_CRITICAL'}],
    permissionToAdd: [{id: 1, value: 'TASK_PERMISSION_TO_ADD_USERS_BY_EVERYONE'}, {id: 2, value: 'TASK_PERMISSION_TO_ADD_USER_BY_AUTHOR'}],
    visibilityType: [{id: '/rest/visibility_types/1', value: 'VISIBILITY_TYPE_PUBLIC'}, {id: '/rest/visibility_types/2', value: 'VISIBILITY_TYPE_PRIVATE'}]
}

export interface ITask extends IBaseRestEntity {
    id?: number,
    /**
     * @maxLength 512
     */
    name: string,
    description: string,
    plainText?: string | null,
    /**
     * @default 1
     */
    type: typeof TaskType[keyof typeof TaskType],
    /**
     * @default 1
     */
    state: typeof TaskState[keyof typeof TaskState],
    /**
     * @default 2
     */
    priority: typeof TaskPriority[keyof typeof TaskPriority],
    /**
     * @format date-time
     */
    startDate?: string | null,
    /**
     * @format date-time
     */
    dueDate?: string | null,
    /**
     * @description TODO: set nullable=false in next release.
     */
    taskProcessingModel?: ITaskProcessingModel | string | null,
    /**
     * @maxLength 64
     */
    customId?: string | null,
    weekly?: boolean,
    editBlocked?: boolean,
    /**
     * @default 1
     */
    permissionToAdd: typeof TaskPermissionToAdd[keyof typeof TaskPermissionToAdd],
    company?: ICompany | string | null,
    actionTaskTemplate: ITaskTemplate | string | null,
    actionTaskTemplateComponent?: ITaskTemplateComponent | string | null,
    scheduleTaskTemplateComponent?: ITaskTemplateComponent | string | null,
    taskFiles?: (ITaskFile | string)[],
    taskNotes?: (ITaskNote | string)[],
    taskHistories?: (ITaskHistory | string)[],
    taskUsers?: (ITaskUser | string)[],
    scheduleTasks?: (IScheduleTask | string)[],
    taskProcessingFlags?: (ITaskProcessingFlag | string)[],
    taskAssignments?: (ITaskAssignment | string)[],
    taskAcceptanceCriteria?: (ITaskAcceptanceCriterion | string)[],
    contractItem?: IContractItem | string | null,
    categories?: (ICategory | string)[],
    taskDeadlines?: (ITaskDeadline | string)[],
    taskCustomFields?: (ITaskCustomField | string)[],
    task?: ITask | string | null,
    subtasks?: (ITask | string)[],
    taskProcessingKey?: ITaskProcessingKey | string | null,
    taskStates?: (ITaskState | string)[],
    taskDates?: (ITaskDate | string)[],
    archived: boolean,
    visibilityType?: typeof TaskVisibilityType[keyof typeof TaskVisibilityType],
    completionAllowedAheadOfSchedule: boolean,
    /**
     * @description Record status
     *  0 = deleted
     *  1 = active.
     * @default 1
     */
    status?: number,
    /**
     * @description Creation timestamp.
     * @default CURRENT_TIMESTAMP
     * @format date-time
     */
    created?: string,
    /**
     * @description Last modification timestamp.
     * @format date-time
     */
    modified?: string | null
}

/** @deprecated */
export interface IRestTask extends ITask, IRestObject {
    /**
     * @description TODO: set nullable=false in next release.
     */
    taskProcessingModel?: IRestTaskProcessingModel | string | null,
    company?: IRestCompany | string | null,
    actionTaskTemplate: IRestTaskTemplate | string | null,
    actionTaskTemplateComponent?: IRestTaskTemplateComponent | string | null,
    scheduleTaskTemplateComponent?: IRestTaskTemplateComponent | string | null,
    taskFiles?: IRestTaskFile[] | string[],
    taskNotes?: IRestTaskNote[] | string[],
    taskHistories?: IRestTaskHistory[] | string[],
    taskUsers?: IRestTaskUser[] | string[],
    scheduleTasks?: IRestScheduleTask[] | string[],
    taskProcessingFlags?: IRestTaskProcessingFlag[] | string[],
    taskAssignments?: IRestTaskAssignment[] | string[],
    taskAcceptanceCriteria?: IRestTaskAcceptanceCriterion[] | string[],
    contractItem?: IRestContractItem | string | null,
    categories?: IRestCategory[] | string[],
    taskDeadlines?: IRestTaskDeadline[] | string[],
    taskCustomFields?: IRestTaskCustomField[] | string[],
    task?: IRestTask | string | null,
    subtasks?: IRestTask[] | string[],
    taskProcessingKey?: IRestTaskProcessingKey | string | null,
    taskStates?: IRestTaskState[] | string[],
    taskDates?: IRestTaskDate[] | string[],
}

/** @deprecated */
export type IRestTaskConst = ITaskConst & IRestObject;
