import {Component, DoCheck, Input, OnDestroy, OnInit} from '@angular/core';
import {
    ControlValueAccessor, FormControl,
} from '@angular/forms';
import {IIconDynamicFieldConfig} from 'src/modules/dynamic-fields/interfaces';
import {Observable, Subject} from 'rxjs';
import {IResolveOnSearchResult} from 'angular-bootstrap4-extended-select';
import {IconPickerService, IIconConfig} from 'src/modules/dynamic-fields/controls/icon-picker/icon-picker.service';
import {
    AbstractUnvalidatedControlValueAccessor
} from 'src/modules/app-forms/abstract-control-value-accessors/abstract-unvalidated-control-value-accessor';
import {takeUntil} from 'rxjs/operators';


@Component({
    selector: 'color-picker',
    templateUrl: './icon-picker.component.html',
    styleUrls: ['./icon-picker.component.scss'],
    providers: AbstractUnvalidatedControlValueAccessor.getProviders(IconPickerComponent)
})
export class IconPickerComponent
    extends AbstractUnvalidatedControlValueAccessor
    implements ControlValueAccessor, OnInit, DoCheck, OnDestroy {

    @Input() fieldConfig: IIconDynamicFieldConfig;

    esControl = new FormControl();
    options: IIconConfig[];
    initializing = true;
    resolveOnSearch: (search: string, page: number) => Observable<IResolveOnSearchResult>;

    private value: string | string[];
    private readonly _destroy$ = new Subject<void>();

    constructor(
        private readonly iconPickerService: IconPickerService
    ) {
        super();
    }

    ngDoCheck(): void {
        if (this.esControl?.touched && !this._wasTouched) {
            setTimeout(() => this.markAsTouched());
        }
    }

    writeValue(value: string | string[]): void {
        if (value) {
            if (!this.fieldConfig.prefix) {
                value = Array.isArray(value)
                    ? value.map((x) => `fa-${x}`)
                    : `fa-${value}`;
            }

            this.value = value;
            this.esControl.setValue(value, {emitEvent: false});
        }
    }

    ngOnInit(): void {
        this.resolveOnSearch = this._resolveOnSearch.bind(this);

        this.iconPickerService.getIcons()
            .subscribe((iconList) => {
                if (this.fieldConfig.iconStyles) {
                    this.options = iconList.filter((icon) => {
                        return this.fieldConfig.iconStyles.includes(icon.styleClass);
                    });
                } else {
                    this.options = iconList;
                }

                this.initializing = false;

                if (this.value) {
                    setTimeout(() => {
                        this.esControl.setValue(this.value, {emitEvent: false});
                    });
                }
            });

        this.esControl.valueChanges
            .pipe(takeUntil(this._destroy$))
            .subscribe((value) => {
                this.markAsTouched();

                if (!this.fieldConfig.prefix) {
                    value = Array.isArray(value)
                        ? value.map((x) => x.replace(/^fa-/, ''))
                        : value.replace(/^fa-/, '');
                }

                this._onChange(value);
            });
    }

    private _resolveOnSearch(search: string): Observable<IResolveOnSearchResult> {
        return new Observable<IResolveOnSearchResult>((subscriber) => {
            search = search.toLowerCase();

            const visibleOptions = [];
            this.options.forEach((iconConfig) => {
                if (this.isSearchedIcon(search, iconConfig)) {
                    visibleOptions.push(iconConfig.className);
                }
            });

            subscriber.next({
                hasNextPage: false,
                visibleOptions
            });
            subscriber.complete();
        });
    }

    private isSearchedIcon(search: string, icon: IIconConfig): boolean {
        if (icon.label.toLowerCase().includes(search)) {
            return true;
        }

        const isInTerms = icon.search.terms.find((term) => {
            return term.includes(search);
        });
        return !!isInTerms;
    }

    setDisabledState(isDisabled: boolean): void {
        if (isDisabled) {
            this.esControl.disable({emitEvent: false});
        } else {
            this.esControl.enable({emitEvent: false});
        }
    }

    ngOnDestroy(): void {
        this._destroy$.next();
        this._destroy$.complete();
    }
}
