import {Injectable} from '@angular/core';
import {RestClient} from 'src/modules/rest/rest-client.service';
import {IReportDisplayListOutputDto, ReportDisplayType} from '_types/rest';
import {noop, Observable, ReplaySubject} from 'rxjs';
import {map, tap} from 'rxjs/operators';
import {AppStateDeclaration, RouteTreeBranch} from 'src/modules/route-helper/interfaces';
import {RouteHelper} from 'src/modules/route-helper/route-helper.service';
import {ReportDisplayGroupViewComponent} from 'src/modules/reports/report-display-group-view/report-display-group-view.component';
import {ReportDisplayViewComponent} from 'src/modules/reports/report-display-view/report-display-view.component';
import {StateObject} from '@uirouter/core';

interface IReportDisplayStateDeclaration extends AppStateDeclaration {
    reportDisplayId: number;
}

@Injectable({
    providedIn: 'root'
})
export class ReportsService {
    private groupId = 0;
    private reportDisplayId = 0;
    private reportStatesIris: Record<string, string> = {};
    private initializedSubject = new ReplaySubject<boolean>();
    initialized$ = this.initializedSubject.asObservable();

    constructor(
        private restClient: RestClient,
        private routeHelper: RouteHelper
    ) {
    }

    initialize(): Observable<void> {
        return this.restClient.endpoint('report_displays/list').getAll({
            type: ReportDisplayType.REPORT_DISPLAY_TYPE_PAGE
        })
            .pipe(
                tap((reportDisplays) => {
                    this.initializeReportDisplays(reportDisplays);
                    this.initializedSubject.next(true);
                }),
                map(() => undefined)
            );
    }

    private initializeReportDisplays(reportDisplays: IReportDisplayListOutputDto[]): void {
        const isUniqueDisplay = this.createUniqueDisplayValidator();

        reportDisplays.forEach((reportDisplay) => {
            if (!isUniqueDisplay(reportDisplay)) {
                return;
            }

            const childStates: IReportDisplayStateDeclaration[] = [
                reportDisplay,
                ...reportDisplay.references.filter(isUniqueDisplay)
            ]
                .reduce((states: IReportDisplayStateDeclaration[], reportDisplay) => {
                    const reportDisplayStates = this.createReportDisplayRoute(reportDisplay);
                    reportDisplayStates.forEach((state) => {
                        this.reportStatesIris[state.name] = reportDisplay['@id'];
                    });
                    states.push(...reportDisplayStates);
                    return states;
                }, []);

            this.routeHelper.configureStates([
                this.createReportDisplayGroupRoute(childStates)
            ]);
        });
    }

    private createUniqueDisplayValidator(): (reportDisplay: IReportDisplayListOutputDto) => boolean {
        const processedDisplays = [];

        return (reportDisplay: IReportDisplayListOutputDto): boolean => {
            if (reportDisplay.display !== 'page' || !reportDisplay.links.length) {
                return false;
            }

            const id = reportDisplay.links.map((link) => link.url).join('|');
            if (processedDisplays.includes(id)) {
                return false;
            }

            processedDisplays.push(id);
            return true;
        };
    }

    private createReportDisplayRoute(reportDisplay: IReportDisplayListOutputDto): IReportDisplayStateDeclaration[] {
        this.reportDisplayId++;
        const stateName = `reportDisplay_${this.reportDisplayId}`;

        return reportDisplay.links.map((link, index) => {
            return {
                reportDisplayId: this.reportDisplayId,
                name: `${stateName}_${index}`,
                url: `/${link.url}`,
                lang: link.menu,
                component: ReportDisplayViewComponent,
                resolve: {
                    $language: noop,
                    structure: RouteHelper.restResolveObject(
                        reportDisplay.endpoint.name,
                        () => reportDisplay.endpoint.id,
                        () => reportDisplay.endpoint.query
                    )
                }
            };
        });
    }

    private createReportDisplayGroupRoute(childStates: IReportDisplayStateDeclaration[]): AppStateDeclaration {
        this.groupId++;

        return {
            name: `dynamicPage_${this.groupId}`,
            component: ReportDisplayGroupViewComponent,
            redirectTo: `.${childStates[0].name}`,
            lang: childStates[0].url.startsWith('/sales')
                ? 'ROUTE_LEADS_BETA'
                : (
                    childStates[0].url.startsWith('/time-tracker')
                        ? 'ROUTE_TIME_TRACKER'
                        : 'ROUTE_REPORTS'
                ),
            childs: childStates,
            resolve: {
                $language: noop
            }
        };
    }

    /**
     * returns an array of reportDisplay states that are referenced by given reportDisplay state,
     * reportDisplays with multiple urls are reduced to the first one or current
     */
    getSiblingStates(currentState: StateObject): RouteTreeBranch[] {
        const reportDisplayId = (currentState.self as IReportDisplayStateDeclaration).reportDisplayId;

        if (typeof reportDisplayId !== 'number') {
            return [];
        }

        const states = this.routeHelper.getStatesTree(currentState.parent.name);
        if (states) {
            return states.subs.filter((state) => {
                return state.name === currentState.name
                    || (
                        // first url but not an current state alias
                        state.name.endsWith('_0')
                        && !state.localName.startsWith(`reportDisplay_${reportDisplayId}`)
                    );
            });
        }

        return [];
    }

    getReportStates(): RouteTreeBranch[] {
        const states = [];

        for (let groupId = 1; groupId <= this.groupId; groupId++) {
            const groupState = this.routeHelper.getStatesTree(`dynamicPage_${groupId}`);

            if (groupState) {
                states.push(
                    ...groupState.subs
                );
            }
        }

        return states;
    }

    getReportIriByName(name: string): string {
        return this.reportStatesIris[name];
    }
}
