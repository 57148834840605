import {NgModule} from '@angular/core';
import {AppUiSrefDirective} from 'src/modules/route-helper/app-ui-sref.directive';

@NgModule({
    declarations: [
        AppUiSrefDirective
    ],
    exports: [
        AppUiSrefDirective
    ]
})
export class RouteDirectivesModule {
}
