import {Injector} from '@angular/core';
import { ITaskListOutputDto} from '_types/rest';
import {Observable, Subject} from 'rxjs';
import {ConfirmModalService} from 'src/modules/global-components/confirm-modal/confirm-modal.service';
import {AbstractOperationDefinition, IOperationResult} from 'src/modules/operations/interfaces';
import {IRestObject} from 'src/modules/rest/objects';
import {RestClient} from 'src/modules/rest/rest-client.service';
import {boundTask, ITaskInputDtoOverride} from 'src/modules/tasks/task-view/task-view.component';

export class UnlinkSubtaskOperation extends AbstractOperationDefinition<string, ITaskListOutputDto | boundTask> {

    readonly endpoint = 'tasks/item';
    readonly name = 'unlink_subtask';
    lang = 'TASK_UNLINK_SUBTASK';
    icon = 'fa-unlink';
    color = 'danger';

    invoke(
        context: IRestObject<'tasks/list', ITaskListOutputDto>,
        injector: Injector
    ): Observable<IOperationResult> {
        const confirmModal = injector.get(ConfirmModalService),
            restClient = injector.get(RestClient),
            subject = new Subject<IOperationResult>();

        confirmModal.confirm('DELETE_PARENT_TASK_CONFIRM', () => {
            restClient.endpoint<'tasks/item', Partial<ITaskInputDtoOverride>>('tasks/item')
                .update(context.id,
                    {
                        task: null
                    }
                )
                .subscribe({
                    next: () => {
                        restClient.savedToast();
                        subject.next({success: true});
                        subject.complete();
                    },
                    error: (err) => {
                        subject.next({success: false});
                        subject.error(err);
                    }
                });
        },
        {
            text: 'ACTION_CANNOT_BE_UNDONE',
            langYes: 'DELETE_PARENT_TASK_CONFIRM_YES',
            primaryBtn: 'danger',
            callbackNo: () => {
                subject.next({success: true});
                subject.complete();
            }
        });

        return subject.asObservable();
    }

    access(context: IRestObject<'tasks/list', ITaskListOutputDto | boundTask>): boolean {
        if (!context.id || !RestClient.isRestObject(context)) {
            return false;
        }

        return context.access('put_item', 'task') && !!context.parentTask;
    }
}
