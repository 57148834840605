import {Injectable} from '@angular/core';
import {IUserLogin} from '_types/rest';
import {BehaviorSubject, Observable} from 'rxjs';


@Injectable({
    providedIn: 'root'
})
export class WysiwygContentService {
    activeUsers$: Observable<IUserLogin[]>;

    private activeUsersSubject = new BehaviorSubject<IUserLogin[]>([]);

    constructor() {
        this.activeUsers$ = this.activeUsersSubject.asObservable();
    }

    changeActiveUsers(users: IUserLogin[]): void {
        this.activeUsersSubject.next(users);
    }

    getActiveUsers(): IUserLogin[] {
        return this.activeUsersSubject.value;
    }
}
