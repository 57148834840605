<!-- plain -->
<ng-template [(templateContext)]="plain" let-label>
    {{ label }}
</ng-template>

<ng-template [(templateContext)]="contractItemTree" let-value="value">
    <div class="badge badge-circle badge-circle--sm mr-1"
         [ngClass]="{'badge-success': value.active, 'badge-danger': !value.active}"
         [title]="(value.active ? 'ACTIVE' : 'INACTIVE') | translate"
         [attr.aria-label]="(value.active ? 'ACTIVE' : 'INACTIVE') | translate">
    </div>

    {{ value.activeContractItem.service?.name }}

    <span *ngIf="value.activeContractItem.product?.name"
          class="badge badge-info text-white ml-2">
        {{ value.activeContractItem.product?.name }}
    </span>
</ng-template>

<!-- userLogin -->
<ng-template [(templateContext)]="userLoginBefore" let-user="value">
    <div class="d-flex align-items-center mr-2 user-select-avatar">
        <avatar [user]="user"></avatar>
    </div>
</ng-template>

<!-- badge -->
<ng-template [(templateContext)]="badge" let-color="color" let-label>
    <span class="badge" [ngClass]="'badge-' + color">
        {{ label }}
    </span>
</ng-template>
