import {
    Component, ComponentFactory,
    ComponentFactoryResolver,
    ComponentRef, DoCheck,
    Input,
    QueryList,
    Type,
    ViewChild
} from '@angular/core';
import {
    SelectDynamicFieldViewType,
    ISelectDynamicFieldConfig, ICustomSelectControlValueAccessor,
    IDynamicFieldControlValueAccessor
} from 'src/modules/dynamic-fields/interfaces';
import {
    DynamicFieldHostDirective
} from 'src/modules/dynamic-fields/dynamic-field/dynamic-field-host-directive.component';
import {
    EsBeforeOptionDirective,
    EsOptionsDirective,
    ExtendedSelectComponent
} from 'angular-bootstrap4-extended-select';
import {EsOptionsComponent} from 'src/modules/dynamic-fields/controls/dynamic-select/es-options/es-options.component';
import {
    AbstractUnvalidatedControlValueAccessor
} from 'src/modules/app-forms/abstract-control-value-accessors/abstract-unvalidated-control-value-accessor';
import {
    RadioSelectComponent
} from 'src/modules/dynamic-fields/controls/dynamic-select/custom-select/radio-select.component';
import {
    CheckboxSelectComponent
} from 'src/modules/dynamic-fields/controls/dynamic-select/custom-select/checkbox-select.component';
import {
    RadioButtonsSelectComponent
} from 'src/modules/dynamic-fields/controls/dynamic-select/custom-select/radio-buttons-select.component';
import isEqual from 'lodash.isequal';

type CustomSelectControls = {
    [P in SelectDynamicFieldViewType]: Type<ICustomSelectControlValueAccessor>
}

const customSelectControls: CustomSelectControls = {
    select: ExtendedSelectComponent,
    checkbox: CheckboxSelectComponent,
    switch: CheckboxSelectComponent,
    checkBar: CheckboxSelectComponent,
    radio: RadioSelectComponent,
    radioButtons: RadioButtonsSelectComponent
};

@Component({
    selector: 'dynamic-select',
    templateUrl: './dynamic-select.component.html',
    providers: AbstractUnvalidatedControlValueAccessor.getProviders(DynamicSelectComponent)
})
export class DynamicSelectComponent
    extends AbstractUnvalidatedControlValueAccessor
    implements IDynamicFieldControlValueAccessor, DoCheck {

    @Input() fieldConfig: ISelectDynamicFieldConfig;
    @Input() esOptionsList: QueryList<EsOptionsDirective<unknown>>;
    @Input() esBeforeOption?: EsBeforeOptionDirective<unknown>;

    @ViewChild(DynamicFieldHostDirective, {static: true}) private _dynamicFieldHost: DynamicFieldHostDirective;

    private _customSelect: ICustomSelectControlValueAccessor;
    private modelValue: unknown;
    private _initialized = false;

    constructor(
        private componentFactoryResolver: ComponentFactoryResolver
    ) {
        super();
    }

    /**
     * write value comes before init, because of deferred esOptionsList
     */
    writeValue(obj: unknown): void {
        this.modelValue = obj;

        if (this._customSelect) {
            this._customSelect.writeValue(obj);
        }
    }

    ngDoCheck(): void {
        if (!this._onTouched || this._initialized || !this.esOptionsList) {
            return;
        }

        this._initialized = true;

        if (!this.esOptionsList.length) {
            this.esOptionsList.reset([this._getDefaultEsOptions()]);
        }

        if (!this.fieldConfig.viewType) {
            this.fieldConfig.viewType = 'select';
        }

        this._createCustomSelect(this.fieldConfig.viewType);

        if (!this.fieldConfig.noAutoSelect) {
            let firstOptionValue: unknown;

            const optionsCount = this.esOptionsList.reduce((sum, esOptions) => {
                if (!firstOptionValue && esOptions.options.value.length) {
                    firstOptionValue = esOptions.options.value[0].getValue();
                }
                return sum + esOptions.options.value.length;
            }, 0);

            if (optionsCount === 1) {
                if (
                    ['checkbox', 'switch', 'checkBar'].includes(this.fieldConfig.viewType)
                    || ('multiple' in this.fieldConfig && this.fieldConfig.multiple)
                ) {
                    firstOptionValue = [firstOptionValue];
                }

                if (!isEqual(this.modelValue, firstOptionValue)) {
                    setTimeout(() => {
                        this.writeValue(firstOptionValue);
                        this._onChange(firstOptionValue);
                    });
                }
            }
        }
    }

    setDisabledState(isDisabled: boolean): void {
        if (this._customSelect) {
            this._customSelect.setDisabledState(isDisabled);
        } else {
            setTimeout(() => {
                this.setDisabledState(isDisabled);
            });
        }
    }

    private _getDefaultEsOptions(): EsOptionsDirective<unknown> {
        const {componentRef} = this._createComponent(EsOptionsComponent);
        componentRef.instance['fieldConfig'] = this.fieldConfig;
        componentRef.changeDetectorRef.detectChanges();
        return componentRef.instance.esOptions;
    }

    private _createComponent<T>(
        component: Type<T>,
        projectableNodes?: unknown[][]
    ): {
        componentFactory: ComponentFactory<T>,
        componentRef: ComponentRef<T>
    } {
        const componentFactory = this.componentFactoryResolver.resolveComponentFactory(component);
        const componentRef = this._dynamicFieldHost.viewContainerRef.createComponent(
            componentFactory, undefined, undefined, projectableNodes
        );

        return {
            componentFactory,
            componentRef
        };
    }

    private _createCustomSelect(viewType: SelectDynamicFieldViewType): void {
        const {componentFactory, componentRef} = this._createComponent(customSelectControls[viewType]);
        componentFactory.inputs.forEach((input) => {
            if (input.templateName === 'viewType') {
                componentRef.instance[input.propName] = viewType;
            } else if (input.templateName in this.fieldConfig) {
                componentRef.instance[input.propName] = this.fieldConfig[input.templateName];
            }
        });
        // call detect changes to make ExtendedSelect pickup ContentChildren
        componentRef.changeDetectorRef.detectChanges();
        // then override it with our options
        componentRef.instance.esOptionsList = this.esOptionsList;
        componentRef.instance.esBeforeOption = this.esBeforeOption;
        // pass CVA methods
        componentRef.instance.writeValue(this.modelValue);
        componentRef.instance.registerOnChange(this._onChange);
        componentRef.instance.registerOnTouched(this._onTouched);
        this._customSelect = componentRef.instance;
    }
}
