import {ResolvedPos} from 'prosemirror-model';
import {IMentionStateValue} from 'src/modules/wysiwyg-editor/plugins/mention-factory/interfaces';


export const triggerByCharFactory = (
    char = '@',
): ($position: ResolvedPos) => Omit<IMentionStateValue, 'active'> => {
    return ($position) => {
        const regexp = new RegExp(`(?:^)?${char}[^\\s${char}]*`, 'g'),
            textFrom = $position.before(),
            textTo = $position.end();

        const text = $position.doc.textBetween(textFrom, textTo, '\0', '\0');

        let match;
        while ((match = regexp.exec(text))) {
            // Javascript doesn't have lookbehinds; this hacks a check that first character is " " or the line beginning
            const prefix = match.input.slice(Math.max(0, match.index - 1), match.index);
            if (!/^[\s\0]?$/.test(prefix)) {
                continue;
            }

            // The absolute position of the match in the document
            const from = match.index + $position.start(),
                to = from + match[0].length;

            // If the $position is located within the matched substring, return that range
            if (from < $position.pos && to >= $position.pos) {
                return { range: { from, to }, text: match[0] };
            }
        }
    };
};
