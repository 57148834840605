import {ChangeDetectionStrategy, Component, ElementRef, Input, OnInit} from '@angular/core';
import {LegendService} from 'src/modules/legend/legend.service';
import {Observable} from 'rxjs';
import {ILegendItem} from 'src/modules/legend/interfaces';
import {DomUtils} from 'src/services/dom-utils';
import {distinctUntilChanged, tap} from 'rxjs/operators';


@Component({
    selector: 'legend-subject',
    templateUrl: './legend-subject.component.html',
    styleUrls: ['./legend-subject.component.scss'],
    /**
     * This component is ready to use OnPush, but cannot use it since bs-popover won't work properly.
     * We can adapt OnPush when bs-popover will be updated to correctly handle Onpush.
     */
    changeDetection: ChangeDetectionStrategy.Default
})
export class LegendSubjectComponent implements OnInit {
    @Input() legendItemName: string;

    legendItem$: Observable<ILegendItem>;

    constructor(
        public readonly legendService: LegendService,
        private readonly elementRef: ElementRef,
        private readonly domUtils: DomUtils
    ) {
    }

    ngOnInit(): void {
        this.legendItem$ = this.legendService.getLegendItem(this.legendItemName)
            .pipe(
                distinctUntilChanged(),
                tap((legendItem) => {
                    if (!legendItem) {
                        return;
                    }

                    this.domUtils.scrollToElement(
                        this.elementRef.nativeElement
                    );
                })
            );
    }
}
