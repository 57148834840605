/**
 * This file has been auto generated. Do not edit.
 */
import {ITaskTemplateComponent} from '_types/rest/Entity/IRestTaskTemplateComponent';
import {IRestTaskTemplateComponent} from '_types/rest/Entity/IRestTaskTemplateComponent';
import {IPartner} from '_types/rest/Entity/IRestPartner';
import {IRestPartner} from '_types/rest/Entity/IRestPartner';
import {IProduct} from '_types/rest/Entity/IRestProduct';
import {IRestProduct} from '_types/rest/Entity/IRestProduct';
import {ITaskTemplateProcessingOverride} from '_types/rest/Entity/IRestTaskTemplateProcessingOverride';
import {IRestTaskTemplateProcessingOverride} from '_types/rest/Entity/IRestTaskTemplateProcessingOverride';
import {ITask} from '_types/rest/Entity/IRestTask';
import {IRestTask} from '_types/rest/Entity/IRestTask';
import {ITaskTemplateComponentTemplate} from '_types/rest/Entity/IRestTaskTemplateComponentTemplate';
import {IRestTaskTemplateComponentTemplate} from '_types/rest/Entity/IRestTaskTemplateComponentTemplate';
import {ITaskProcessingKey} from '_types/rest/Entity/IRestTaskProcessingKey';
import {IRestTaskProcessingKey} from '_types/rest/Entity/IRestTaskProcessingKey';
import {IDepartment} from '_types/rest/Entity/IRestDepartment';
import {IRestDepartment} from '_types/rest/Entity/IRestDepartment';
import {IBaseRestEntity} from '_types/rest/IBaseRestEntity';
import {IRestObject} from 'core/rest/rest-object.service';

export const TaskTemplateType = {
    TASK_TEMPLATE_TYPE_TASK: 1,
    TASK_TEMPLATE_TYPE_TEMPLATE: 2
} as const;

export const TaskTemplateTypeOptions = [
    {
        value: TaskTemplateType.TASK_TEMPLATE_TYPE_TASK,
        label: 'TASK_TEMPLATE_TYPE_TASK',
    },
    {
        value: TaskTemplateType.TASK_TEMPLATE_TYPE_TEMPLATE,
        label: 'TASK_TEMPLATE_TYPE_TEMPLATE',
    }
] as const;

export const TaskTemplateFlag = {
    TASK_TEMPLATE_FLAG_NORMAL: 1,
    TASK_TEMPLATE_FLAG_DEFAULT: 2
} as const;

export const TaskTemplateFlagOptions = [
    {
        value: TaskTemplateFlag.TASK_TEMPLATE_FLAG_NORMAL,
        label: 'TASK_TEMPLATE_FLAG_NORMAL',
    },
    {
        value: TaskTemplateFlag.TASK_TEMPLATE_FLAG_DEFAULT,
        label: 'TASK_TEMPLATE_FLAG_DEFAULT',
    }
] as const;

export interface ITaskTemplateConst {
    type: [{id: 1, value: 'TASK_TEMPLATE_TYPE_TASK'}, {id: 2, value: 'TASK_TEMPLATE_TYPE_TEMPLATE'}],
    flag: [{id: 1, value: 'TASK_TEMPLATE_FLAG_NORMAL'}, {id: 2, value: 'TASK_TEMPLATE_FLAG_DEFAULT'}]
}

export interface ITaskTemplate extends IBaseRestEntity {
    id?: number,
    /**
     * @maxLength 64
     */
    name: string,
    /**
     * @default 1
     */
    type: typeof TaskTemplateType[keyof typeof TaskTemplateType],
    /**
     * @default 1
     */
    flag?: typeof TaskTemplateFlag[keyof typeof TaskTemplateFlag],
    limitPerPerson?: number | null,
    taskTemplateComponents?: (ITaskTemplateComponent | string)[],
    partner?: IPartner | string | null,
    products?: (IProduct | string)[],
    processingOverrides?: (ITaskTemplateProcessingOverride | string)[],
    tasks?: (ITask | string)[],
    taskTemplateComponentTemplates?: (ITaskTemplateComponentTemplate | string)[],
    taskProcessingKey?: ITaskProcessingKey | string | null,
    /**
     * @default true
     */
    active?: boolean,
    /**
     * @default true
     */
    changingCompletionAllowed?: boolean,
    /**
     * @default true
     */
    visibleForEveryDepartment?: boolean,
    departments?: (IDepartment | string)[],
    /**
     * @description Record status
     *  0 = deleted
     *  1 = active.
     * @default 1
     */
    status?: number,
    /**
     * @description Creation timestamp.
     * @default CURRENT_TIMESTAMP
     * @format date-time
     */
    created?: string,
    /**
     * @description Last modification timestamp.
     * @format date-time
     */
    modified?: string | null
}

/** @deprecated */
export interface IRestTaskTemplate extends ITaskTemplate, IRestObject {
    taskTemplateComponents?: IRestTaskTemplateComponent[] | string[],
    partner?: IRestPartner | string | null,
    products?: IRestProduct[] | string[],
    processingOverrides?: IRestTaskTemplateProcessingOverride[] | string[],
    tasks?: IRestTask[] | string[],
    taskTemplateComponentTemplates?: IRestTaskTemplateComponentTemplate[] | string[],
    taskProcessingKey?: IRestTaskProcessingKey | string | null,
    departments?: IRestDepartment[] | string[],
}
