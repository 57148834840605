import {Component, OnInit} from '@angular/core';
import {UIRouter} from '@uirouter/core';
import {AppStateDeclaration, RouteTreeBranch} from 'src/modules/route-helper/interfaces';
import {ReportsService} from 'src/modules/reports/reports.service';

@Component({
    selector: 'report-display-group-view',
    templateUrl: './report-display-group-view.component.html'
})
export class ReportDisplayGroupViewComponent implements OnInit {
    states: RouteTreeBranch[];
    currentStateName: string;

    constructor(
        private uiRouter: UIRouter,
        private reportsService: ReportsService
    ) {
    }

    ngOnInit(): void {
        this.states = this.reportsService.getSiblingStates(this.uiRouter.globals.$current);
        this.currentStateName = (this.uiRouter.globals.$current.self as AppStateDeclaration).lang;
    }
}
