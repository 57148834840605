/**
 * Extend target class with methods from classes passed as decorator arguments.
 * For correct type annotations create an interface with same name as target class name
 * that extends all of used classes.
 * Please note that constructors of used classes are skipped.
 */
export function Use(...objects: { prototype: unknown }[]): (target: { prototype: unknown }) => void {
    return (target: { prototype: unknown }): void => {
        objects
            .filter((object) => typeof object !== 'undefined')
            .forEach((object) => {
                Object.getOwnPropertyNames(object.prototype).forEach((property) => {
                    if (property === 'constructor') {
                        return;
                    }

                    target.prototype[property] = object.prototype[property];
                });
            });
    };
}
