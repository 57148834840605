import {Injectable} from '@angular/core';
import {ConfigService} from 'src/services/config.service';
import {Observable} from 'rxjs';
import {first} from 'rxjs/operators';


export type SendEvent = (eventName: string, eventValue: string) => void;
export type TrackerLoad = (trackerId: string) => Observable<unknown>

export interface ITracker {
    load: TrackerLoad;
    sendEvent: SendEvent;
}

export interface IAnalyticsService {
    loadTrackers(): void;
    sendEvent: SendEvent;
}

export interface IAnalyticsTrackers {
    [name: string]: ITracker;
}

@Injectable({
    providedIn: 'root'
})
export class AnalyticsService {
    private trackers: IAnalyticsTrackers = {};
    private loadedTrackers: IAnalyticsTrackers = {};

    constructor(
        private config: ConfigService,
    ) {
    }

    loadTrackers(): void {
        Object.entries(this.config.get('ANALYTICS')).forEach(([trackerName, trackerId]) => {

            const tracker = this.getTracker(trackerId, trackerName);
            if (typeof tracker !== 'undefined') {
                tracker.load(trackerId).pipe(first()).subscribe({
                    next: () => {
                        this.loadedTrackers[trackerName] = tracker;
                    },
                    error: (e) => {
                        // eslint-disable-next-line no-console
                        console.warn(`Could not load analytics tracker ${trackerName}`, e);
                    }
                });
            }
        });
    }

    sendEvent(eventName: string, eventValue: string): void {
        Object.entries(this.loadedTrackers).forEach(([, tracker]) => {
            if (typeof tracker.sendEvent !== 'undefined') {
                tracker.sendEvent(eventName, eventValue);
            }
        });
    }

    private getTracker(trackerId: string, trackerName: string): ITracker | undefined {
        if (
            typeof trackerId === 'string'
            && trackerId.length
            && typeof this.trackers[trackerName] !== 'undefined'
        ) {
            return this.trackers[trackerName];
        }
        return undefined;
    }

    addTracker(name: string, load: TrackerLoad, sendEvent?: SendEvent): void {
        this.trackers[name] = {
            load,
            sendEvent
        };
    }
}
