import isEqual from 'lodash.isequal';
import {IDynamicFieldOption} from 'src/modules/dynamic-fields/interfaces';
import {Tree} from 'src/modules/rest/objects';
import cloneDeepWith from 'lodash.clonedeepwith';
import {RestClient} from 'src/modules/rest/rest-client.service';
import {BOOTSTRAP_COLORS, BootstrapColor} from 'src/modules/colors/colors.constant';

export type IPrimitive = string | number | bigint | boolean | undefined | symbol | null;

export type IWordVariation = { singular: string, plural1: string, plural2?: string };

export abstract class Utils {

    private static canvasContext: CanvasRenderingContext2D;

    /**
     * Recursively remove selected property
     * Use case: Remove all occurrences of _meta before POST/PUT
     */
    static removeProperty(data: object, prop: string): void {
        Object.entries(data).forEach(([key,]) => {
            if (key === prop) {
                delete data[prop];
            } else if (typeof data[key] === 'object' && data[key] !== null) {
                this.removeProperty(data[key], prop);
            }
        });
    }

    /**
     * Recursively remove properties with null value
     * Use case: Remove all empty (null) properties before POST/PUT
     */
    static removeNullProperty(data: object): void {
        Object.entries(data).forEach(([key, value]) => {
            if (value === null) {
                delete data[key];
            } else if (typeof data[key] === 'object' && data[key] !== null) {
                this.removeNullProperty(data[key]);
            }
        });
    }

    /**
     * Recursively remove empty properties
     */
    static removeEmptyProperty(data: object): void {
        Object.entries(data).forEach(([value, key]) => {
            if (value === null || value === '') {
                delete data[key];
            } else if (typeof data[key] === 'object' && data[key] !== null) {
                this.removeEmptyProperty(data[key]);
            }
        });
    }

    /**
     * Shorten selected fields to selected field (default IRI, default String)
     * Use case: Replace full objects with IRI before POST/PUT
     */
    static shortenFields(data: unknown, fields: string[], fieldName = '@id', fieldTypeString = true): void {
        let items = [];
        if (Array.isArray(data)) {
            items = data;
        } else if (typeof data === 'object' && data !== null) {
            items.push(data);
        }
        items.forEach((item) => {
            fields.forEach((field) => {
                if (typeof item[field] !== 'undefined') {
                    if (Array.isArray(item[field])) {
                        const shorted = [];
                        item[field].forEach((fieldItem) => {
                            // If it's already string (IRI), as defined
                            if (fieldTypeString && typeof fieldItem === 'string') {
                                // Just pass
                                shorted.push(fieldItem);
                            } else {
                                if (typeof fieldItem[fieldName] !== 'undefined') {
                                    // Add specific param if defined
                                    shorted.push(fieldItem[fieldName]);
                                } else {
                                    // Just pass if not
                                    shorted.push(fieldItem);
                                }
                            }
                        });
                        item[field] = shorted;
                    } else if (typeof item[field] === 'object' && item[field] !== null) {
                        item[field] = item[field][fieldName];
                    }
                }
            });
        });
    }

    /**
     * Extend selected fields to objects by matching by fieldName.
     * This function does not query API for missing objects, just working on provided data.
     * Use case: Replace IRI with full objects (ex. inner hierarchy) after GET
     */
    static extendFields(data: object[], fields: string[], fieldName = '@id'): void {
        let items = [];
        if (Array.isArray(data)) {
            items = data;
        } else if (typeof data === 'object' && data !== null) {
            items.push(data);
        }
        items.forEach((item) => {
            fields.forEach((field) => {
                if (typeof item[field] !== 'undefined') {
                    if (Array.isArray(item[field])) {
                        const extended = [];
                        item[field].forEach((fieldItem) => {
                            const extendedFieldItem = data.find((element) => {
                                return element[fieldName] === fieldItem;
                            });
                            if (typeof extendedFieldItem !== 'undefined') {
                                extended.push(extendedFieldItem);
                            }
                        });
                        item[field] = extended;
                    } else if (typeof item[field] === 'object' && item[field] !== null) {
                        const extended = data.find((element) => {
                            return element[fieldName] === item[field][fieldName];
                        });
                        if (typeof extended !== 'undefined') {
                            item[field] = extended;
                        }
                    }
                }
            });
        });
    }

    /**
     * Compare element with each data item.
     * @param {Array} data
     * @param {Object} element
     */
    static isUnique(data: unknown[], element: unknown): boolean {
        const result = data.find((item) => {
            return isEqual(element, item);
        });
        return typeof result === 'undefined';
    }

    /**
     * Make array of primitive values unique.
     * Method use Set to maximize performance.
     * @param {Array} array
     */
    static makeUnique<T extends IPrimitive>(array: T[]): T[] {
        return [...new Set(array)];
    }

    /**
     * Make array of objects.
     * Method use Map to maximize performance.
     * @param {Array} array
     * @param key
     */
    static makeUniqueObject<T extends object>(array: T[], key: string): T[] {
        return [
            ...new Map(
                array.map((item) => [Utils.getNestedProperty(item, key), item])
            ).values()
        ];
    }

    /**
     * Return data with removed duplicates
     */
    static getUniqueElements<T>(data: T[]): T[] {
        const _return = [];
        data.forEach((item) => {
            const duplicate = _return.find((element) => {
                return isEqual(item, element);
            });
            if (typeof duplicate === 'undefined') {
                _return.push(item);
            }
        });
        return _return;
    }

    /**
     * Check if array contains duplicates
     */
    static hasDuplicates<T>(array: T[]): boolean {
        return (new Set(array)).size !== array.length;
    }

    /**
     * Recursively get children from selected property (array of parents)
     */
    static getAllChildren<T>(
        array: T[],
        parent: T,
        parentsProp: string,
        compareProp: string,
        used: Set<T> | null = null,
        returnUnique = true
    ): T[] {
        if (!used) {
            used = new Set();
        }

        if (used.has(parent)) {
            return [];
        } else {
            used.add(parent);
        }

        if (Array.isArray(array)) {
            let children = array.filter((item) => {
                if (Array.isArray(item[parentsProp])) {
                    const found = item[parentsProp].find((_parent) => {
                        if (typeof _parent === 'object' && _parent !== null) {
                            return _parent[compareProp] === parent[compareProp];
                        } else {
                            return _parent === parent[compareProp];
                        }
                    });
                    if (typeof found !== 'undefined') {
                        return true;
                    }
                }
            });
            if (children.length > 0) {
                children.forEach((c) => {
                    const _children = this.getAllChildren<T>(array, c, parentsProp, compareProp, used);
                    if (_children.length > 0) {
                        children = [...children, ..._children];
                    }
                });
            }
            if (returnUnique) {
                return this.getUniqueElements(children);
            } else {
                return children;
            }
        } else {
            return [];
        }
    }

    /**
     * Check if all properties are defined in model
     */
    static areDefined(model: object, properties: string[]): boolean {
        return properties.reduce((previous, current) => {
            return previous && typeof model[current] !== 'undefined';
        }, true);
    }

    /**
     * Check if any property is defined in model
     */
    static areAnyDefined(model: object, properties: string[]): boolean {
        return properties.some((item) => typeof model[item] !== 'undefined');
    }

    /**
     * Flatten nested arrays recursively.
     * @param {Object[]} data
     * @param {String} nestedProp
     * @param {Object[]} results
     */
    static flatten<T>(data: T[] | T, nestedProp = 'children', results: T[] = []): T[] {
        if (!Array.isArray(data)) {
            data = [data];
        }
        (data as T[]).forEach((item) => {
            if (typeof item[nestedProp] !== 'undefined') {
                this.flatten(item[nestedProp], nestedProp, results);
                delete item[nestedProp];
            }
            results.push(item);
        });
        return results;
    }

    /**
     * Return "white" or "black" basing on provided color luminance.
     * @see https://www.w3.org/TR/AERT/#color-contrast
     */
    static getTextColor(backgroundColor: string, hexReturn = true): string {
        if (!backgroundColor) {
            return '#000000';
        }

        if (!Utils.isHexColor(backgroundColor)) {
            backgroundColor = Utils.colorNameToHex(backgroundColor);
        }

        let color = backgroundColor.replace('#', '');

        if (color.length === 3) {
            color = color
                .split('')
                .map((hex) => {
                    return hex + hex;
                })
                .join('');
        }

        const r = parseInt(color.substr(0, 2), 16),
            g = parseInt(color.substr(2, 2), 16),
            b = parseInt(color.substr(4, 2), 16),
            luminance = ((r * 299) + (g * 587) + (b * 114)) / 1000;

        if (luminance >= 128) {
            return hexReturn ? '#000000' : 'black';
        }

        return hexReturn ? '#FFFFFF' : 'white';
    }

    static isHexColor(color: string): boolean {
        return [4, 7].includes(color.length) && /^#[0-9A-F]$/i.test(color);
    }

    static colorNameToHex(colorName: string): string {
        if (!Utils.canvasContext) {
            Utils.canvasContext = document.createElement('canvas').getContext('2d');
        }
        Utils.canvasContext.fillStyle = colorName;
        return Utils.canvasContext.fillStyle;
    }

    /**
     * This method brighten hex color by given percent (percent of 0-255 scale).
     * */
    static brightenColor(color: string, percent: number): string {
        if (isNaN(parseInt(color, 16))) {
            color = color.replace('#', '');
        }
        const num = parseInt(color, 16),
            amt = Math.round(2.55 * percent),
            R = (num >> 16) + amt,
            G = (num >> 8 & 0x00FF) + amt,
            B = (num & 0x0000FF) + amt;
        // eslint-disable-next-line max-len
        return '#' + (0x1000000 + (R < 255 ? R < 1 ? 0 : R : 255) * 0x10000 + (G < 255 ? G < 1 ? 0 : G : 255) * 0x100 + (B < 255 ? B < 1 ? 0 : B : 255))
            .toString(16).slice(1);
    }

    /**
     * This method brighten hex color by given percent (percent of given color).
     * */
    static brightenColorRelative(color: string, percent: number): string {
        color = color.replace(/^#/, '');
        if (color.length === 3) {
            color = color[0] + color[0] + color[1] + color[1] + color[2] + color[2];
        }

        let [r, g, b] = color.match(/.{2}/g);
        const [r2, g2, b2] = [parseInt(r, 16) + percent, parseInt(g, 16) + percent, parseInt(b, 16) + percent];

        r = Math.max(Math.min(255, r2), 0).toString(16);
        g = Math.max(Math.min(255, g2), 0).toString(16);
        b = Math.max(Math.min(255, b2), 0).toString(16);

        const rr = (r.length < 2 ? '0' : '') + r;
        const gg = (g.length < 2 ? '0' : '') + g;
        const bb = (b.length < 2 ? '0' : '') + b;

        return `#${rr}${gg}${bb}`;
    }

    /**
     * @returns {*}
     */
    static pick(object: object, ...paths: string[]): unknown {
        if (!paths.length) {
            return undefined;
        }

        const result = paths.reduce((map, path) => {
            map[path] = path.split('.').reduce((o, i) => {
                return typeof o !== 'undefined' ? o[i] : o;
            }, object);
            return map;
        }, {});

        return paths.length > 1 ? result : result[paths[0]];
    }

    static initialsGenerator(name: string): string {
        name = name.toLowerCase();
        const consonants = [
            'b', 'c', 'd', 'f', 'g', 'h', 'j', 'k', 'l', 'm',
            'n', 'p', 'q', 'r', 's', 't', 'v', 'w', 'x', 'y'
        ];
        const initials = [];
        [...name].forEach((char) => {
            consonants.forEach((consonant) => {
                consonant === char && initials.push(char);
            });
        });
        const firstChar = name.substring(0, 1);
        const firstConsonant = initials.slice(0, 1).join('');
        let result: string;
        if (firstChar === firstConsonant) {
            result = initials.slice(0, 2).join('');
        } else {
            result = name.substring(0, 1) + initials.slice(0, 1).join('');
        }
        return result.toUpperCase();
    }

    /**
     * Return singular or plural variation of word
     * @see https://www.rjp.pan.pl/index.php?option=com_content&view=article&id=1011:skadnia-liczebnikow-70&catid=44&Itemid=145
     */
    static getWordVariation(
        words: IWordVariation,
        num: number
    ): string {
        if (num === 1) {
            return words.singular;
        } else if (num % 10 >= 2 && num % 10 <= 4 && (num % 100 < 10 || num % 100 >= 20)) {
            return words.plural1;
        } else {
            if (words.plural2) {
                return words.plural2;
            } else {
                return words.plural1;
            }
        }
    }

    /**
     * Recursively walk tree object
     * You may break the loop by returning true from callback
     */
    static walkTree<T, P extends string = 'children'>(
        tree: Tree<T, P>,
        callback: (treeItem: Tree<T, P> extends Array<infer I> ? I : never, parent: Tree<T, P>) => boolean | void,
        childrenProperty: P = 'children' as P
    ): void {
        tree.forEach((item) => {
            if (callback(item, tree)) {
                return;
            }
            if (childrenProperty in item) {
                this.walkTree(item[childrenProperty], callback, childrenProperty);
            }
        });
    }

    static clone<T>(value: T): T {
        try {
            return cloneDeepWith(value, (item: unknown) => {
                if (item instanceof Map) {
                    return Utils.cloneMapCustomizer(item);
                }

                if (RestClient.isRestCollection(item) || RestClient.isRestObject(item)) {
                    return item.clone();
                }

                return undefined;
            });
        } catch (error) {
            throw Error('Unsupported object for Utils.clone: ' + error);
        }
    }

    // This method was created because in lodash.cloneDeepWith Map cloning doesn't use our customizer for Map's items.
    // This behaviour leads to wrong copying of RestObject instances and creates effects very hard to understand.
    private static cloneMapCustomizer<K, V>(item: Map<K, V>): Map<K, V> {
        const newMap = new Map<K, V>();
        item.forEach((v, k) => {
            [v, k] = Utils.clone([v, k]);
            newMap.set(k, v);
        });
        return newMap;
    }

    static arrayCopyTo<T>(target: T[], source: T[]): void {
        target.length = 0;
        Object.assign(target, source);
    }

    static bootstrapColorFromNumber(number: number): BootstrapColor {
        return BOOTSTRAP_COLORS[Math.abs(number % BOOTSTRAP_COLORS.length)];
    }

    /**
     * Check if string contains JSON object that is not null.
     * @param value
     */
    static isJsonObject(value: string): boolean {
        try {
            const objectValue = JSON.parse(value);
            return typeof objectValue === 'object'
                && objectValue !== null;
        } catch (error) {
            return false;
        }
    }

    /**
     * Parse JSON data string into user-friendly key-value text.
     * @param data
     * @param objectLabel
     */
    static getKeyValueTextFromJson(data: string, objectLabel?: string): string {
        let text = '';
        if (this.isJsonObject(data)) {
            const jsonObject = JSON.parse(data);
            Object.entries(jsonObject).forEach(([key, value]) => {
                if (typeof value === 'object') {
                    value = objectLabel || 'Object';
                }
                text += `${key}: ${value}, `;
            });
        }
        return text;
    }

    static parseHtmlToText(html: string, document: Document): string {
        const element = document.createElement('div');
        element.innerHTML = html;
        return element.innerText;
    }

    static getNestedProperty(data: object, propertyChain: string): unknown | undefined {
        const properties = propertyChain.split('.');
        if (!properties.length) {
            return;
        }

        let result = data;
        for (const prop of properties) {
            if (result === null || typeof result !== 'object') {
                break;
            }

            result = result[prop];
        }

        return result;
    }

    static getFromOption<T = unknown>(
        options: IDynamicFieldOption<T>[],
        item: T,
    ): string {
        return options.find(({value}) => value === item).label;
    }

    static getIriFromObjectProperty(source: object, property: string): string | null | undefined {
        return typeof source[property] === 'object' && source[property] !== null
            ? source[property]['@id']
            : source[property];
    }
}

