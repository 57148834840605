<div *ngFor="let group of esOptionsList; index as groupId" [ngClass]="{'inline': inline}">
    <ng-container *ngFor="let option of group.options | async; index as optionId">
        <div class="custom-control" [ngClass]="[customControlClass, isDisabled ? 'disabled' : '']">
            <input [type]="inputType" class="custom-control-input" [attr.id]="getId(groupId, optionId)"
                   [checked]="isChecked(option)" [disabled]="isDisabled"
                   (change)="change(option, $event.target['checked'])"/>
            <label class="custom-control-label" [attr.for]="getId(groupId, optionId)">
                <ng-container *ngIf="esBeforeOption">
                    <ng-container *ngTemplateOutlet="esBeforeOption.template; context: {$implicit: option.item}"></ng-container>
                </ng-container>
                <ng-container *ngTemplateOutlet="group.template; context: {$implicit: option.item}"></ng-container>
            </label>
        </div>
    </ng-container>
</div>
