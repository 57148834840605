import {Pipe, PipeTransform} from '@angular/core';
import {IParsedIRI, RestClient} from 'src/modules/rest/rest-client.service';


@Pipe({name: 'parseIri'})
export class ParseIriPipe implements PipeTransform {
    constructor(
        private restClient: RestClient
    ) {
    }

    transform(iri: string): IParsedIRI {
        return this.restClient.parseIri(iri);
    }
}
