import {Injector} from '@angular/core';
import {Observable} from 'rxjs';
import {map, tap} from 'rxjs/operators';
import {AbstractOperationDefinition, IOperationResult} from 'src/modules/operations/interfaces';
import {RestClient} from 'src/modules/rest/rest-client.service';
import {IImpersonateResponse, UserActionsService} from 'src/services/user-actions.service';
import {AbstractDeleteOperation} from 'view-modules/operations/abstract/abstract-delete.operation';
import {IRestObject} from 'src/modules/rest/objects';

export class AbsenceAddOperation extends AbstractOperationDefinition<string, IRestObject<'absences'>[]> {
    readonly endpoint = 'absences';
    readonly name = 'post';
    lang = 'ABSENCE_ADD';
    icon = 'fa-plus';
    component = (): Promise<unknown> => import('./absence-form/absence-form.component');
}

export class AbsenceEditOperation extends AbstractOperationDefinition<'absences'> {
    readonly endpoint = 'absences';
    readonly name = 'put';
    lang = 'EDIT';
    icon = 'fa-pencil-alt';
    component = (): Promise<unknown> => import('./absence-form/absence-form.component');
}

export class AbsenceDeleteOperation extends AbstractDeleteOperation<'absences'> {
    readonly endpoint = 'absences';
    lang = 'DELETE';
    confirmMessage = 'ABSENCES_CONFIRM_REMOVE_MESSAGE';
    confirmOptions = {
        langYes: 'ABSENCES_CONFIRM_REMOVE_MESSAGE_LANG_YES',
    };
}

export class AbsenceImpersonateOperation extends AbstractOperationDefinition<'absences'> {
    readonly endpoint = 'absences';
    readonly name = 'impersonate';
    lang = 'USER_IMPERSONATE';
    icon = 'fa-sign-in-alt';

    invoke(
        context: IRestObject<'absences'>,
        injector: Injector
    ): Observable<IOperationResult> {
        const userActionsService = injector.get(UserActionsService),
            restClient = injector.get(RestClient);

        return restClient.endpoint<'absences/impersonate', IImpersonateResponse>('absences/impersonate')
            .update(context.id, {})
            .pipe(
                tap((response) => {
                    userActionsService.handleImpersonateResponse(response);
                }),
                map(() => {
                    return {success: true};
                })
            );
    }
}

export const absencesOperations = [
    AbsenceAddOperation,
    AbsenceEditOperation,
    AbsenceDeleteOperation,
    AbsenceImpersonateOperation
] as const;
