import {Injectable} from '@angular/core';
import {LocalStorage} from 'src/services/local-storage';
import {UserService} from 'src/modules/rest/user/user.service';
import {ReportsService} from 'src/modules/reports/reports.service';
import {TranslateService} from 'src/modules/translate/translate.service';
import {CmsService} from 'src/services/cms.service';
import {ConfigService} from 'src/services/config.service';
import {combineLatestWith, map, startWith, take, tap} from 'rxjs/operators';
import {Route} from '_types/route';
import {IMenu, IMenuElement, MenuElementType, MenuType, With} from '_types/rest';
import {Tree} from 'src/modules/rest/objects';
import {RestClient} from 'src/modules/rest/rest-client.service';
import {Utils} from 'src/services/utils';
import {BehaviorSubject, Observable} from 'rxjs';
import {RouteTreeBranch} from 'src/modules/route-helper/interfaces';

type IMenuWithElements = With<IMenu, 'elements', {
    elements: IMenuElementWithCmsType[]
}>;

export interface IMenuElementWithCmsType extends With<IMenuElement, 'cmsType'> {
    collapse?: boolean,
    children?: IMenuElement[],
    menuElements?: IMenuElementWithCmsType[]
}

@Injectable({
    providedIn: 'root'
})
export class MenuService {
    mainMenuMobile = false;

    private mainMenu: Tree<IMenuElementWithCmsType>;
    private localeChangeInitialized = false;
    private mainMenuHiddenKey = 'menuHidden';
    private mainMenuHiddenSubject = new BehaviorSubject(false);
    mainMenuHidden$ = this.mainMenuHiddenSubject.asObservable();

    get mainMenuHidden(): boolean {
        return this.mainMenuHiddenSubject.value;
    }

    constructor(
        private readonly userService: UserService,
        private readonly reportsService: ReportsService,
        private readonly cmsService: CmsService,
        private readonly configService: ConfigService,
        private readonly translateService: TranslateService,
        private readonly restClient: RestClient
    ) {
    }

    initialize(): Observable<void> {
        this.mainMenuHiddenSubject.next(
            LocalStorage.get(this.mainMenuHiddenKey, false)
        );

        const query = {
            with: [
                'elements.menuElements',
                'elements.menuElements.reportDisplay',
                'elements.cmsType'
            ]
        };

        return this.restClient
            .endpoint<'menus/active', IMenuWithElements, IMenuWithElements[]>('menus/active')
            .getAll(query)
            .pipe(
                combineLatestWith(
                    this.reportsService.initialized$
                        .pipe(take(1))
                ),
                tap(([menus]) => {
                    const mainMenu = menus.find((menu) => menu.type === MenuType.TYPE_MAIN_MENU);
                    this.mainMenu = this.convertMenus(mainMenu);
                }),
                map(() => undefined)
            );
    }

    convertMenus(menu: IMenuWithElements): Tree<IMenuElementWithCmsType> {
        const reportStates: RouteTreeBranch[] = this.reportsService.getReportStates();

        return this.getMenuElementTree(menu) // Only Main Menu
            .filter((menuElement, index, menuElements) => {
                return this.convertMenuElement(menuElement, index, menuElements, reportStates);
            });
    }

    private getMenuElementTree(menu: IMenuWithElements): Tree<IMenuElementWithCmsType> {
        return this.restClient
            .endpoint<string, IMenuElementWithCmsType, IMenuElementWithCmsType>('menu_elements')
            .createCollection(...menu.elements)
            .buildTree('parentElement')
            .sort((a, b) => a.order - b.order);
    }

    private convertMenuElement(menuElement, index, menuElements, reportStates): boolean {
        const _elements = Utils.clone(menuElement.children);
        delete menuElement.children;
        menuElement.menuElements = _elements;

        switch (menuElement.type) {
            case MenuElementType.TYPE_ELEMENT:
                if (menuElement.cmsType) {
                    menuElement.route = `cms.${menuElement.cmsType.name}` as Route;
                }

                return this.userService.hasPrivileges(menuElement.route);

            case MenuElementType.TYPE_SECTION:
                if (!menuElement?.menuElements?.length) {
                    return;
                }

                menuElement.menuElements = menuElement.menuElements
                    .filter((element: IMenuElementWithCmsType) => {
                        if (!element.reportDisplay) {
                            return this.userService.hasPrivileges(element.route);
                        }

                        const route = reportStates.find(({localName}) => {
                            const stateIri = this.reportsService.getReportIriByName(localName);

                            return stateIri === element.reportDisplay['@id'];
                        });

                        element.route = route?.name;
                        element.name = route?.lang;

                        return !!route;
                    });

                menuElement.collapse = true;
                return menuElement.menuElements.length > 0;

            case MenuElementType.TYPE_SEPARATOR:
                // remove separator if previous element is a separator
                return index > 0 && menuElements[index - 1].type !== MenuElementType.TYPE_SEPARATOR;
        }
    }

    getMenu(): Tree<IMenuElementWithCmsType> {
        this.updateOnLocaleChange();
        return this.mainMenu;
    }

    private updateOnLocaleChange(): void {
        if (this.localeChangeInitialized) {
            return;
        }

        this.localeChangeInitialized = true;
        this.configService.localeChange$
            .pipe(startWith(''))
            .subscribe(() => {
                const adminSection = this.mainMenu.find(({type, name}) => {
                    return type === MenuElementType.TYPE_SECTION && name === 'ROUTE_ADMIN';
                });

                if (adminSection) {
                    adminSection.menuElements.sort((a, b) => {
                        return this.translateService
                            .get(a.name)
                            .localeCompare(this.translateService.get(b.name));
                    });
                }
            });
    }

    toggleMainMenuVisibility(): boolean {
        const visibility = !this.mainMenuHiddenSubject.value;
        LocalStorage.set(this.mainMenuHiddenKey, visibility);
        this.mainMenuHiddenSubject.next(visibility);

        return visibility;
    }
}
