import {Inject, Injectable} from '@angular/core';
import {WINDOW} from 'app-custom-providers';

const CHARS_AVAILABLE = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz!@#\\$%^&*';

@Injectable({
    providedIn: 'root'
})
export class PasswordInputService {

    constructor(
        @Inject(WINDOW) private window: Window,
    ) {}

    private getRandomByte(): number {
        // http://caniuse.com/#feat=getrandomvalues
        if (this.window.crypto && this.window.crypto.getRandomValues) {
            const result = new Uint8Array(1);
            this.window.crypto.getRandomValues(result);
            return result[0];
        } else if (this.window['msCrypto'] && this.window['msCrypto'].getRandomValues) {
            const result = new Uint8Array(1);
            this.window['msCrypto'].getRandomValues(result);
            return result[0];
        } else {
            return Math.floor(Math.random() * 256);
        }
    }

    getRandomInt(min: number, max: number): number {
        return Math.floor(Math.random() * (max - min + 1)) + min;
    }

    generate(minLength: number, maxLength: number): string {
        const passwordLength = this.getRandomInt(minLength, maxLength);

        let stringGenerated = '';

        while (stringGenerated.length !== passwordLength) {
            const char = String.fromCharCode(this.getRandomByte());

            if (CHARS_AVAILABLE.includes(char)) {
                stringGenerated += char;
            }
        }
        return stringGenerated;
    }


}
