import {Component, Provider} from '@angular/core';
import {
    AbstractControl,
    ControlValueAccessor,
    NG_VALIDATORS,
    ValidationErrors,
    Validator
} from '@angular/forms';
import {AbstractUnvalidatedControlValueAccessor} from './abstract-unvalidated-control-value-accessor';


/* eslint-disable @angular-eslint/component-class-suffix */
@Component({
    selector: 'abstract-control-value-accessor',
    template: ''
})
/**
 * If your component doesn't contain any FormControls that are being validated nor any custom validation logic
 * then use AbstractUnvalidatedControlValueAccessor instead.
 */
export abstract class AbstractControlValueAccessor extends AbstractUnvalidatedControlValueAccessor {
    abstract validate(control: AbstractControl): ValidationErrors | null;

    static getProviders(
        targetCVA: abstract new(...args: unknown[]) => AbstractControlValueAccessor & ControlValueAccessor & Validator
    ): Provider[] {
        return AbstractUnvalidatedControlValueAccessor.getProviders(targetCVA).concat([
            {
                provide: NG_VALIDATORS,
                multi: true,
                useExisting: targetCVA
            }
        ]);
    }
}
