import {NgModule} from '@angular/core';
import {SubMenuLayoutComponent} from 'src/modules/sub-menu-layout/sub-menu-layout.component';
import {NavTabsModule} from 'src/modules/nav-tabs/nav-tabs.module';
import {UIRouterModule} from '@uirouter/angular';
import {AppCommonModule} from 'src/modules/app-common/app-common.module';
import {TranslateModule} from 'src/modules/translate/translate.module';
import {AppComponentsModule} from 'src/components/app-components.module';

@NgModule({
    imports: [
        AppCommonModule,
        AppComponentsModule,
        NavTabsModule,
        TranslateModule,
        UIRouterModule
    ],
    declarations: [
        SubMenuLayoutComponent
    ],
    exports: [
        SubMenuLayoutComponent
    ]
})
export class SubMenuLayoutModule {}
