<div class="input-with-icon-container position-relative d-flex align-items-center no-gutters">
    <div class="col">
        <button type="button" *ngIf="fieldConfig.showPassword || togglePasswordShow" class="btn p-0 toggle-password"
                (click)="togglePassword()"
                [hidden]="!passwordControl.value && !togglePasswordShow">
            <span class="fa fa-fw"
                  [ngClass]="{'fa-eye': togglePasswordShow, 'fa-eye-slash': !togglePasswordShow}"
                  [title]="(!togglePasswordShow ? 'SHOW_PASSWORD' : 'HIDE_PASSWORD') | translate">
                <span class="sr-only">{{ (!togglePasswordShow ? 'SHOW_PASSWORD' : 'HIDE_PASSWORD') | translate }}</span>
            </span>
        </button>
        <input [type]="togglePasswordShow ? 'text' : 'password'"
               class="form-control input-with-icon"
               [formControl]="passwordControl"
               [attr.placeholder]="fieldConfig.placeholder"
               [attr.autocomplete]="fieldConfig.autocomplete">
    </div>
    <div class="col-auto">
        <button type="button" *ngIf="fieldConfig.generate" (click)="randomPassword()" class="btn">
            <span class="fa fa-dice"
                  [title]="'GENERATE_PASSWORD'| translate">
                <span class="sr-only">{{ 'GENERATE_PASSWORD'| translate }}</span>
            </span>
        </button>
    </div>
</div>
