import {Pipe, PipeTransform} from '@angular/core';
import {DomSanitizer, SafeHtml} from '@angular/platform-browser';

/**
 * Marks returned html as trusted, so it can be bound with ng-bind-html directive.
 */
@Pipe({name: 'unsafe'})
export class UnsafePipe implements PipeTransform {
    constructor(
        private domSanitizer: DomSanitizer
    ) {
    }
    transform(
        input: string
    ): SafeHtml {
        if (typeof input === 'string') {
            return this.domSanitizer.bypassSecurityTrustHtml(input);
        }
    }
}
