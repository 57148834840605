import {Component, HostListener, Input, OnInit, Output, EventEmitter, ViewChild, ElementRef} from '@angular/core';
import {IFileEvent, FileUploadHelper} from 'src/modules/file-upload/file-upload-helper';

export interface IDropZoneSettings {
    button?: boolean,
    image?: boolean,
    name?: string,
    hide?: boolean,
    files?: boolean,
    transclude?: boolean,
    transcludeButton?: boolean,
    pasteScreenshotOnFocus?: boolean
}


@Component({
    selector: 'drop-zone',
    templateUrl: './drop-zone.component.html'
})
export class DropZoneComponent implements OnInit {
    @Input() settings: IDropZoneSettings = {};
    @Input() width?: number;
    @Input() height?: number;
    @Output() readonly fileUploaded = new EventEmitter<File[]>();

    @ViewChild('fileInput') fileInput: ElementRef<HTMLInputElement>;

    private isFocused = false;
    dragover = false;

    ngOnInit(): void {
        this.setDefault('button', false);
        this.setDefault('image', false);
        this.setDefault('name', '');
        this.setDefault('hide', false);
        this.setDefault('transclude', false);
    }

    @HostListener('dragover', ['$event'])
    @HostListener('dragleave', ['$event'])
    onDragChange($event: Event): false {
        this.dragover = $event.type === 'dragover';
        return false;
    }

    @HostListener('drop', ['$event'])
    onDrop($event: IFileEvent): false {
        this.uploadFiles($event);
        this.dragover = false;
        return false;
    }

    @HostListener('focusin', ['$event'])
    @HostListener('focusout', ['$event'])
    onFocusChange($event: Event): false {
        this.isFocused = $event.type === 'focusin';
        return false;
    }

    @HostListener('paste', ['$event'])
    onPaste($event: ClipboardEvent): void {
        this.pasteImageFromClipboard($event);
    }

    uploadFiles($event: Event | IFileEvent): void {
        this.fileUploaded.emit(FileUploadHelper.getFilesFromEvent($event));
        this.fileInput.nativeElement.value = '';
    }

    private pasteImageFromClipboard($event: ClipboardEvent): void {
        const fileInClipboard = $event.clipboardData.files[0];
        if (fileInClipboard && fileInClipboard.type.startsWith('image/')) {
            $event.preventDefault();
            const customEvent = {target: {files: [fileInClipboard]}} as IFileEvent;
            this.uploadFiles(customEvent);
        }
    }

    private setDefault(key: string, value: boolean | string): void {
        if (typeof this.settings[key] === 'undefined') {
            this.settings[key] = value;
        }
    }
}
