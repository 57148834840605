<div class="d-flex justify-content-end align-content-center">
    <div class="col-auto d-flex align-items-center flex-nowrap" *ngIf="tableConfig.perPageOptions.length > 1">
        <label class="m-0 text-nowrap">{{'TABLE_PER_PAGE' | translate}}&nbsp;</label>
        <select class="custom-select custom-select-sm" [formControl]="tableConfig.perPageControl">
            <option *ngFor="let o of tableConfig.perPageOptions" [ngValue]="o.number">{{o.text}}</option>
        </select>
    </div>
    <tt-results-count class="col-auto d-flex align-items-center"></tt-results-count>
    <tt-pagination class="col-auto pagination-secondary" *ngIf="tableConfig.perPage !== Infinity"></tt-pagination>
</div>
