/* eslint-disable max-len */
/**
 * This file has been auto generated. Do not edit.
 */

import {IBaseRestEntity} from '_types/rest/IBaseRestEntity';

export const TaskProcessingContextValueValueGroup = {
    TASK_PROCESSING_CONTEXT_VALUE_GROUP_ALL: 1,
    TASK_PROCESSING_CONTEXT_VALUE_GROUP_DATE: 2
} as const;

export const TaskProcessingContextValueValueGroupOptions = [
    {
        value: TaskProcessingContextValueValueGroup.TASK_PROCESSING_CONTEXT_VALUE_GROUP_ALL,
        label: 'TASK_PROCESSING_CONTEXT_VALUE_GROUP_ALL',
    },
    {
        value: TaskProcessingContextValueValueGroup.TASK_PROCESSING_CONTEXT_VALUE_GROUP_DATE,
        label: 'TASK_PROCESSING_CONTEXT_VALUE_GROUP_DATE',
    }
] as const;

export interface ITaskProcessingContextValueConst {
    valueGroup: [{id: 1, value: 'TASK_PROCESSING_CONTEXT_VALUE_GROUP_ALL'}, {id: 2, value: 'TASK_PROCESSING_CONTEXT_VALUE_GROUP_DATE'}]
}

export interface ITaskProcessingContextValue extends IBaseRestEntity {
    id?: number,
    label: string,
    value: string,
    description?: string | null,
    /**
     * @default 1
     */
    valueGroup?: typeof TaskProcessingContextValueValueGroup[keyof typeof TaskProcessingContextValueValueGroup],
    /**
     * @description Record status
     *  0 = deleted
     *  1 = active.
     * @default 1
     */
    status?: number,
    /**
     * @description Creation timestamp.
     * @default CURRENT_TIMESTAMP
     * @format date-time
     */
    created?: string,
    /**
     * @description Last modification timestamp.
     * @format date-time
     */
    modified?: string | null
}
