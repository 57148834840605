import {NgModule} from '@angular/core';
import {LocatorDirective} from 'src/modules/locators/locator.directive';
import {UiViewDirective} from 'src/modules/locators/ui-view.directive';

@NgModule({
    declarations: [
        LocatorDirective,
        UiViewDirective
    ],
    exports: [
        LocatorDirective,
        UiViewDirective
    ]
})
export class LocatorsModule {
}
