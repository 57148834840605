/**
 * This file has been auto generated. Do not edit.
 */
import {IUserLogin} from '_types/rest/Entity/IRestUserLogin';
import {IRestUserLogin} from '_types/rest/Entity/IRestUserLogin';
import {IDepartment} from '_types/rest/Entity/IRestDepartment';
import {IRestDepartment} from '_types/rest/Entity/IRestDepartment';
import {IBaseRestEntity} from '_types/rest/IBaseRestEntity';
import {IRestObject} from 'core/rest/rest-object.service';

export const UserLoginDepartmentType = {
    TYPE_USER_NORMAL: 1,
    TYPE_USER_MANAGER: 2
} as const;

export const UserLoginDepartmentTypeOptions = [
    {
        value: UserLoginDepartmentType.TYPE_USER_NORMAL,
        label: 'TYPE_USER_NORMAL',
    },
    {
        value: UserLoginDepartmentType.TYPE_USER_MANAGER,
        label: 'TYPE_USER_MANAGER',
    }
] as const;

export interface IUserLoginDepartmentConst {
    type: [{id: 1, value: 'TYPE_USER_NORMAL'}, {id: 2, value: 'TYPE_USER_MANAGER'}]
}

export interface IUserLoginDepartment extends IBaseRestEntity {
    id?: number,
    userLogin: IUserLogin | string,
    department: IDepartment | string,
    /**
     * @default 1
     */
    type: typeof UserLoginDepartmentType[keyof typeof UserLoginDepartmentType],
    /**
     * @description Record status
     *  0 = deleted
     *  1 = active.
     * @default 1
     */
    status?: number,
    /**
     * @description Creation timestamp.
     * @default CURRENT_TIMESTAMP
     * @format date-time
     */
    created?: string,
    /**
     * @description Last modification timestamp.
     * @format date-time
     */
    modified?: string | null
}

/** @deprecated */
export interface IRestUserLoginDepartment extends IUserLoginDepartment, IRestObject {
    userLogin: IRestUserLogin | string,
    department: IRestDepartment | string,
}
