import {Component} from '@angular/core';
import {ConfirmModalService} from 'src/modules/global-components/confirm-modal/confirm-modal.service';


@Component({
    selector: 'confirm-modal',
    templateUrl: './confirm-modal.component.html',
    styleUrls: ['./confirm-modal.component.scss']
})
export class ConfirmModalComponent {
    constructor(
        public confirmModal: ConfirmModalService
    ) {
    }
}
