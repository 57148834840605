import {
    AfterViewInit,
    Component, ContentChildren,
    DoCheck,
    ElementRef,
    EventEmitter,
    Inject, Input,
    OnDestroy,
    OnInit,
    Output, QueryList,
    Renderer2, ViewChild,
} from '@angular/core';
import {BsHelpers, IElementOffset} from 'angular-bootstrap4';
import {WINDOW} from 'app-custom-providers';
import {ITabElement, NavTabComponent} from 'src/modules/nav-tabs/nav-tab/nav-tab.component';
import {Observable, Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

@Component({
    selector: 'nav-tabs',
    templateUrl: './nav-tabs.component.html',
    styleUrls: ['./nav-tabs.component.scss']
})
export class NavTabsComponent implements OnInit, OnDestroy, DoCheck, AfterViewInit {
    @Input() tabToActivate$: Observable<ITabElement['id']>;
    @Input() forceUpdate$: Observable<void>;
    @Input() viewType: 'app' | 'bootstrap' = 'app';
    @Output() readonly active = new EventEmitter<NavTabComponent>();
    @ContentChildren(NavTabComponent) private navTabListComponents: QueryList<NavTabComponent>;
    @ViewChild('tabsCanvas') private tabsCanvasElement: ElementRef<HTMLDivElement>;
    @ViewChild('tabsContainer') private tabsContainerElement: ElementRef<HTMLDivElement>;
    @ViewChild('activeIndicator') private activeIndicatorElement: ElementRef<HTMLDivElement>;
    private canvasOffset: IElementOffset;
    private activeElement: ElementRef<HTMLElement>;
    private buttons: HTMLElement[];
    private enqueueRender: boolean;
    private currentContainerShift = 0;
    private maxShift: number;
    private containerWidth: number;
    private sizeWatchInterval: number;
    private _destroy$ = new Subject<void>();

    constructor(
        private angularBS: BsHelpers,
        private elRef: ElementRef,
        private renderer: Renderer2,
        @Inject(WINDOW) private window: Window
    ) {
    }

    ngOnInit(): void {
        this.sizeWatchInterval = this.window.setInterval(() => {
            if (this.elRef.nativeElement.offsetWidth !== this.containerWidth) {
                this.containerWidth = this.elRef.nativeElement.offsetWidth;
                this.enqueueRender = true;
            }
        }, 100);

        if (this.forceUpdate$) {
            this.forceUpdate$
                .pipe(takeUntil(this._destroy$))
                .subscribe(() => {
                    // Force update in next tick to make sure all changes were made.
                    setTimeout(() => {
                        this.forceUpdate();
                    });
                });
        }
    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            this.setActive(this.navTabListComponents.first);
            this.syncActiveTabWithParent();
        });
    }

    ngOnDestroy(): void {
        if (this.sizeWatchInterval) {
            this.window.clearInterval(this.sizeWatchInterval);
        }
        this._destroy$.next();
        this._destroy$.complete();
    }

    ngDoCheck(): void {
        if (
            this.enqueueRender
            && this.viewType === 'app'
        ) {
            this.enqueueRender = false;
            this.render();
        }
    }

    private syncActiveTabWithParent(): void {
        if (!this.tabToActivate$) {
            return;
        }
        this.tabToActivate$
            .pipe(takeUntil(this._destroy$))
            .subscribe((id: ITabElement['id']) => {
                if (!this.navTabListComponents) {
                    return;
                }

                const tabToActivate = this.navTabListComponents
                    .toArray()
                    .find((navTab) => {
                        return navTab.tabElement?.id === id;
                    });
                if (tabToActivate) {
                    this.setActive(tabToActivate);
                }
            });
    }

    private render(): void {
        if (!this.buttons) {
            this.buttons = Array.from(this.elRef.nativeElement.getElementsByClassName('nav-tabs-button'));
        }

        const containerWidth = this.angularBS.offset(this.elRef.nativeElement).width;

        const maxTabWidth = Math.ceil(containerWidth * 2 / 3);

        const totalWidth: number = this.navTabListComponents
            .map((navTabComponent) => {
                this.renderer.setStyle(navTabComponent.elRef.nativeElement, 'max-width', '100%');
                const width = this.angularBS.offset(navTabComponent.elRef.nativeElement).width;
                if (width > maxTabWidth) {
                    this.renderer.setStyle(navTabComponent.elRef.nativeElement, 'max-width', maxTabWidth + 'px');
                    return maxTabWidth;
                }
                return width;
            })
            .reduce((sum, width) => sum + width, 0);

        if (totalWidth > containerWidth) {
            this.buttons.forEach((btn) => this.renderer.removeClass(btn, 'd-none'));
        } else {
            this.buttons.forEach((btn) => this.renderer.addClass(btn, 'd-none'));
        }

        if (this.tabsCanvasElement) {
            this.canvasOffset = this.angularBS.offset(this.tabsCanvasElement.nativeElement);
        }

        if (this.tabsContainerElement && this.canvasOffset) {
            this.maxShift = -(this.angularBS.offset(this.tabsContainerElement.nativeElement)
                .width - this.canvasOffset.width);
        }

        this.shiftContainer(this.currentContainerShift, true);

        if (!this.setActiveElement) {
            this.setActiveElement(this.activeElement, true);
        }
    }

    private shiftContainer(shiftValue: number, reset = false): void {
        if (!this.tabsContainerElement) {
            return;
        }

        if (reset) {
            this.renderer.addClass(this.tabsContainerElement.nativeElement, 'no-transition');
        } else {
            this.renderer.removeClass(this.tabsContainerElement.nativeElement, 'no-transition');
        }
        if (shiftValue < this.maxShift) {
            shiftValue = this.maxShift;
        }
        if (shiftValue > 0) {
            shiftValue = 0;
        }

        this.renderer.setStyle(
            this.tabsContainerElement.nativeElement,
            'transform',
            `translateX(${shiftValue}px`);
        this.currentContainerShift = shiftValue;
    }

    private shiftTo(
        element: HTMLElement,
        mode: 'auto' | 'start' | 'end',
        reset = false
    ): void {
        const {left: canvasLeft, width: canvasWidth} = this.canvasOffset,
            {width, left} = this.angularBS.offset(element),
            endPosition = left - canvasLeft + width;
        let shiftValue: false | number = false;
        if (mode === 'auto') {
            if (endPosition > canvasWidth) {
                mode = 'end';
            } else if (left < canvasLeft) {
                mode = 'start';
            }
        }
        switch (mode) {
            case 'start':
                shiftValue = canvasLeft - left + this.currentContainerShift;
                break;
            case 'end':
                shiftValue = canvasWidth - endPosition + this.currentContainerShift;
                break;
        }
        if (shiftValue !== false) {
            this.shiftContainer(shiftValue, reset);
        }
    }

    setActive(tab: NavTabComponent): void {
        this.active.emit(tab);

        const currentlyActiveTab = Array.from(this.navTabListComponents).find((tab) => tab.active === true);

        if (currentlyActiveTab) {
            currentlyActiveTab.active = false;
        }

        tab.active = true;
        this.setActiveElement(tab.elRef);
    }

    doResize(): void {
        this.enqueueRender = true;
    }

    changePage(direction: 'next' | 'prev'): void {
        const {left: canvasLeft, width: canvasWidth} = this.canvasOffset;
        if (direction === 'next') {
            this.navTabListComponents.some((navTabComponent) => {
                const {width, left} = this.angularBS.offset(navTabComponent.elRef.nativeElement);
                if (left - canvasLeft + width > canvasWidth) {
                    this.shiftTo(navTabComponent.elRef.nativeElement, 'start');
                    return true;
                }
            });
        } else {
            this.navTabListComponents.toArray().reverse().some((navTabComponent) => {
                const {left} = this.angularBS.offset(navTabComponent.elRef.nativeElement);
                if (left < canvasLeft) {
                    this.shiftTo(navTabComponent.elRef.nativeElement, 'end');
                    return true;
                }
            });
        }
    }

    setActiveElement(element: ElementRef<HTMLElement>, reset = false): void {

        if (!this.canvasOffset) {
            this.render();
        }

        if (this.canvasOffset) {
            const {left: canvasLeft} = this.canvasOffset,
                {width, left} = this.angularBS.offset(element.nativeElement),
                indicatorShift = left - canvasLeft - this.currentContainerShift;


            this.shiftTo(element.nativeElement, 'auto', reset);

            if (!this.activeElement) {
                this.renderer.addClass(
                    this.activeIndicatorElement.nativeElement,
                    'no-transition');
            } else {
                this.renderer.removeClass(
                    this.activeIndicatorElement.nativeElement,
                    'no-transition');
            }

            this.renderer.setStyle(
                this.activeIndicatorElement.nativeElement,
                'transform',
                `translateX(${indicatorShift}px)`);

            this.renderer.setStyle(
                this.activeIndicatorElement.nativeElement,
                'width',
                width + 'px');

            this.activeElement = element;
        }
    }

    private forceUpdate(): void {
        this.setActiveElement(this.activeElement);
        this.doResize();
    }
}
