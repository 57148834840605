import {
    AbstractUnvalidatedControlValueAccessor
} from 'src/modules/app-forms/abstract-control-value-accessors/abstract-unvalidated-control-value-accessor';
import {QueryList} from '@angular/core';
import {EsBeforeOptionDirective, EsOptionsDirective, IEsOption} from 'angular-bootstrap4-extended-select';

let id = 0;

export abstract class AbstractCustomSelectComponent extends AbstractUnvalidatedControlValueAccessor {
    abstract esOptionsList: QueryList<EsOptionsDirective<unknown>>;
    abstract esBeforeOption?: EsBeforeOptionDirective<unknown>;
    abstract customControlClass: string;
    abstract inputType: string;
    abstract inline: boolean;

    id = ++id;
    isDisabled = false;

    registerOnChange(fn: (value: unknown) => void): void {
        super.registerOnChange((value: unknown) => {
            fn(value);
            this.markAsTouched();
        });
    }

    setDisabledState(isDisabled: boolean): void {
        this.isDisabled = isDisabled;
    }

    getId(groupId: number, optionId: number): string {
        return `cs_${this.id}_${groupId}_${optionId}`;
    }

    abstract isChecked(option: IEsOption<unknown>): boolean;

    abstract change(option: IEsOption<unknown>, checked: boolean): void;
}
