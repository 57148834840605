import {Component, DoCheck, EventEmitter, Input, OnDestroy, OnInit, Output, TemplateRef} from '@angular/core';
import {IDynamicFieldControlValueAccessor, IRelationDynamicFieldConfig} from 'src/modules/dynamic-fields/interfaces';
import {FormControl} from '@angular/forms';
import {
    AbstractUnvalidatedControlValueAccessor
} from 'src/modules/app-forms/abstract-control-value-accessors/abstract-unvalidated-control-value-accessor';
import {RestEndpoint} from 'src/modules/rest/rest-endpoint';
import {RestClient} from 'src/modules/rest/rest-client.service';
import {IParsedHydra} from 'src/modules/rest/hydra';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {IResolveOnSearchResult} from 'angular-bootstrap4-extended-select';
import {IQueryObject} from 'src/services/url-parser.service';
import {first, skipWhile, takeUntil} from 'rxjs/operators';
import {RelationSelectService} from 'src/modules/dynamic-fields/controls/relation-select/relation-select.service';
import isEqual from 'lodash.isequal';
import {Utils} from 'src/services/utils';
import {IEntityTemplateContext} from 'src/modules/dynamic-fields/controls/relation-select/entity-templates.component';

export interface IRelationSelectOption<T = unknown> {
    value: T,
    label: string,
    template: TemplateRef<IEntityTemplateContext>,
    templateContext: IEntityTemplateContext
}

@Component({
    selector: 'relation-select',
    templateUrl: './relation-select.component.html',
    styleUrls: ['./relation-select.component.scss'],
    providers: AbstractUnvalidatedControlValueAccessor.getProviders(RelationSelectComponent)
})
export class RelationSelectComponent
    extends AbstractUnvalidatedControlValueAccessor
    implements IDynamicFieldControlValueAccessor, DoCheck, OnInit, OnDestroy {

    @Input() fieldConfig: IRelationDynamicFieldConfig;

    @Input() set objectValue(value: unknown) {
        if (Array.isArray(value)) {
            value.forEach((v) => {
                this.addOption(v);
            });
            return;
        }
        if (typeof value !== 'undefined' && value !== null) {
            this.addOption(value);

        }
    }

    @Output() readonly objectValueChange = new EventEmitter<unknown>();
    @Output() readonly initializing = new BehaviorSubject<boolean>(true);

    esControl = new FormControl();
    options: IRelationSelectOption[] = [];
    beforeTemplate: TemplateRef<IEntityTemplateContext>

    endpoint: RestEndpoint<string>;
    typeToSearch = 0;
    searchFilters: IParsedHydra['search'];
    resolveOnSearch: (search: string, page: number) => Observable<IResolveOnSearchResult>;

    private _pendingInitialValues$ = new BehaviorSubject<boolean>(false);
    private readonly _destroy$ = new Subject<void>();
    private _query: IRelationDynamicFieldConfig['query'];
    private _isFirstDoCheck = true;

    private get valueKey(): string {
        return this.fieldConfig.valueKey || '@id';
    }

    constructor(
        private readonly restClient: RestClient,
        private readonly relationSelectService: RelationSelectService,
    ) {
        super();
    }

    ngDoCheck(): void {
        if (this.esControl && this.esControl.touched && !this._wasTouched) {
            setTimeout(() => this.markAsTouched());
        }

        if (!isEqual(this.fieldConfig.query, this._query)) {
            this._query = Utils.clone(this.fieldConfig.query);

            if (this._isFirstDoCheck) {
                this._isFirstDoCheck = false;
                return;
            }

            this.esControl.setValue(
                this.fieldConfig.multiple ? [] : this.fieldConfig.deselectValue
            );
            this.options.length = 0;
            this.initializing.next(true);
            this._configure();
        }
    }

    writeValue(value: unknown): void {
        let values = [];

        if (Array.isArray(value)) {
            values = value;
        } else if (
            value !== null
            && typeof value !== 'undefined'
            && !this.isDeselectValue(value)
        ) {
            values.push(value);
        }

        const options = [];

        const unknownValues = values.filter((value) => {
            const option = this.options.find((option) => {
                return option.value[this.valueKey] === value;
            });

            if (option) {
                options.push(option.value);
                return false;
            }

            return true;
        });

        const setValue = () => {
            const objectValue = this.fieldConfig.multiple ? options : options[0];
            if (!isEqual(objectValue, this.esControl.value)) {
                this.objectValueChange.emit(objectValue);
            }
            this.esControl.setValue(objectValue, {emitEvent: false});
            this._pendingInitialValues$.next(false);
        };

        if (!this._pendingInitialValues$.value) {
            this._pendingInitialValues$.next(true);
        }

        if (unknownValues.length) {
            this.relationSelectService
                .valuesToObjects(unknownValues, this.fieldConfig)
                .subscribe((response) => {
                    response.forEach((option) => {
                        const added = this.addOption(option);

                        if (added) {
                            options.push(added.value);
                        }
                    });
                    setTimeout(() => {
                        setValue();
                    });
                });
        } else {
            setTimeout(() => {
                setValue();
            });
        }
    }

    private isDeselectValue(value: unknown): boolean {
        return 'deselectValue' in this.fieldConfig
            && this.fieldConfig.deselectValue === value;
    }

    /**
     * as this component is only ever created by dynamic-field, ngOnInit gets called always after first writeValue
     */
    ngOnInit(): void {
        this.resolveOnSearch = this._resolveOnSearch.bind(this);
        this.endpoint = this.restClient.endpoint(this.fieldConfig.endpoint);
        this.beforeTemplate = this.relationSelectService.getBeforeTemplate(this.fieldConfig.endpoint);
        this._configure();

        this.esControl.valueChanges
            .pipe(takeUntil(this._destroy$))
            .subscribe((objectValue) => {
                this.markAsTouched();

                let value;

                if (this.fieldConfig.multiple) {
                    value = Array.isArray(objectValue) ? objectValue.map((item) => item[this.valueKey]) : [];
                } else {
                    value = typeof objectValue === 'object' && objectValue !== null
                        ? objectValue[this.valueKey] : this.fieldConfig.deselectValue;
                }

                this._onChange(value);
                this.objectValueChange.emit(objectValue);
            });
    }

    private _resolveOnSearch(search: string, page: number): Observable<IResolveOnSearchResult> {
        return new Observable<IResolveOnSearchResult>((subscriber) => {
            this.initializing
                .pipe(
                    skipWhile((initializing) => initializing)
                )
                .subscribe(() => {
                    if (!this.searchFilters.length) {
                        subscriber.next({
                            hasNextPage: false,
                            visibleOptions: this.options
                                .filter((option) => {
                                    return option.label.toLowerCase().includes(search);
                                })
                                .map((option) => option.value)
                        });
                        subscriber.complete();
                        return;
                    }

                    const query: IQueryObject = {page};
                    if (this.fieldConfig.searchString) {
                        query.searchString = search;
                    } else {
                        this.searchFilters.forEach((filter) => {
                            query[filter] = search;
                        });
                    }

                    this.endpoint
                        .getAll(Object.assign(query, this.fieldConfig.query))
                        .subscribe((response) => {
                            const visibleOptions = [];
                            response.forEach((option) => {
                                const added = this.addOption(option);
                                visibleOptions.push(added.value);
                            });
                            this.options = this.options.sort((a, b) => {
                                return a.label.localeCompare(b.label);
                            });
                            subscriber.next({
                                hasNextPage: !response.hydra().isLast,
                                visibleOptions
                            });
                            subscriber.complete();
                        });
                })
                .unsubscribe();
        });
    }

    private _configure(): void {
        this.relationSelectService
            .cacheRequest(this.fieldConfig, () => this.endpoint.getAll(this.fieldConfig.query))
            .subscribe((response) => {
                const hydra = response.hydra();
                if ( // non paginated
                    hydra.perPage === -1
                    || hydra.perPage >= hydra.totalItems
                ) {
                    this.typeToSearch = 0;
                    response.forEach((option) => this.addOption(option));
                    if (this.options.length === 1 && !this.fieldConfig.noAutoSelect) {
                        setTimeout(() => {
                            this.esControl.setValue(
                                this.fieldConfig.multiple ? [this.options[0].value] : this.options[0].value
                            );
                        });
                    }
                } else if (!hydra.search.length) { // paginated, no filters
                    // eslint-disable-next-line no-console
                    console.error(
                        `Relation ${this.fieldConfig.endpoint} doesn't provide any search filters and uses `
                        + 'pagination. '
                        + 'So it\'s impossible to resolve it\'s values automatically.'
                    );
                } else if (this.fieldConfig.initializationOptions) {
                    this.fieldConfig.initializationOptions.forEach((option) => this.addOption(option));
                    this.typeToSearch = 0;
                } else {
                    this.typeToSearch = 1;
                }

                this.searchFilters = hydra.search;
                this._pendingInitialValues$
                    .pipe(
                        skipWhile((status) => status),
                        first()
                    )
                    .subscribe(() => {
                        this.initializing.next(false);
                    });
            });
    }

    setDisabledState(isDisabled: boolean): void {
        if (isDisabled) {
            this.esControl.disable({emitEvent: false});
        } else {
            this.esControl.enable({emitEvent: false});
        }
    }

    addOption(newOption: unknown): IRelationSelectOption | null {
        const exists = this.options.find((option) => option.value[this.valueKey] === newOption[this.valueKey]);

        if (exists) {
            return exists;
        }

        if (
            typeof this.fieldConfig.excludedValues === 'undefined'
            || !this.fieldConfig.excludedValues.includes(newOption[this.valueKey])
        ) {
            const entityPresentation = this.relationSelectService
                    .computeEntityPresentation(this.fieldConfig.endpoint, newOption),
                option: IRelationSelectOption = {
                    value: newOption,
                    label: entityPresentation.label,
                    template: entityPresentation.template,
                    templateContext: entityPresentation.templateContext
                };

            this.options.push(option);
            return option;
        }
        return null;
    }

    ngOnDestroy(): void {
        this._destroy$.next();
        this._destroy$.complete();
    }
}
