import {ChangeDetectorRef, Directive, DoCheck, ElementRef, OnInit, Renderer2} from '@angular/core';
import {AbstractControl, NgControl} from '@angular/forms';


@Directive({
    selector: '[ngModel], [formControl], [formControlName]'
})
export class RequiredDirective implements OnInit, DoCheck {
    private requiredMark: HTMLElement;
    private targetLabel: HTMLElement;
    private isRequiredMarkRendered = false;
    private control: AbstractControl;
    public isRequired = false;
    public isDisabled = false;

    constructor(
        private el: ElementRef,
        private ngControl: NgControl,
        private renderer: Renderer2,
        private changeDetector: ChangeDetectorRef
    ) {
        this.requiredMark = this.getRequiredMark();
    }

    ngOnInit(): void {
        this.control = this.ngControl.control;
    }

    ngDoCheck(): void {
        if (
            this.isDisabledChanged()
            || this.isRequiredChanged()
        ) {
            // This is way to make sure other components using isRequired field could detect this change.
            this.changeDetector.detectChanges();
            this.updateRequiredMark(this.isRequired);
        }
    }

    private isDisabledChanged(): boolean {
        const disabledChanged = this.isDisabled !== !!this.control?.disabled;
        if (disabledChanged) {
            this.isDisabled = !!this.control?.disabled;
        }
        return disabledChanged;
    }

    private isRequiredChanged(): boolean {
        const requiredChanged = !this.isDisabled && this.isRequired !== this.hasRequiredValidator();
        if (requiredChanged) {
            this.isRequired = !this.isRequired;
        }
        return requiredChanged;
    }

    private updateRequiredMark(hasRequired: boolean): void {
        if (
            !this.isRequiredMarkRendered
            && hasRequired
        ) {
            this.targetLabel = this.getTargetLabel();
            this.renderRequiredMark(this.targetLabel, this.requiredMark);
            return;
        }

        if (
            this.isRequiredMarkRendered && (!hasRequired || this.isDisabled)
        ) {
            this.removeLegend(this.targetLabel, this.requiredMark);
        }
    }

    private getRequiredMark(): HTMLElement {
        const requiredStar = this.renderer.createElement('span');
        this.renderer.setProperty(
            requiredStar,
            'innerHTML',
            '<span class="required-star">*</span>'
        );
        return requiredStar;
    }

    private hasRequiredValidator(): boolean {
        if (typeof this.control?.validator !== 'function') {
            return false;
        }
        const validator = this.control.validator({} as AbstractControl);
        return !!(validator && validator.required);
    }

    private renderRequiredMark(targetLabelElement: HTMLElement, requiredMarkElement: HTMLElement): void {
        if (targetLabelElement) {
            this.renderer.appendChild(targetLabelElement, requiredMarkElement);
            this.isRequiredMarkRendered = true;
        }
    }

    private removeLegend(targetLabelElement: HTMLElement, requiredMarkElement: HTMLElement): void {
        this.renderer.removeChild(targetLabelElement, requiredMarkElement);
        this.isRequiredMarkRendered = false;
    }

    private getTargetLabel(): HTMLElement {
        const element = this.el.nativeElement;
        // find closest .form-group inside containing form
        let formGroup = element;
        while (
            !formGroup.classList.contains('form-group')
            && !formGroup.classList.contains('form-row')
            && formGroup.tagName !== 'FORM'
        ) {
            formGroup = formGroup.parentElement;
        }

        // abort if no .form-group
        if (!formGroup.classList.contains('form-group') && !formGroup.classList.contains('form-row')) {
            const parent = element.parentElement;
            // If dynamic-field has outer label
            if (
                parent.tagName === 'DYNAMIC-FIELD'
                && parent.previousElementSibling
            ) {
                formGroup = parent.parentElement;
            } else {
                return null;
            }
        }

        // find a label and append a star to it
        const label = formGroup.querySelector('label,.form-section > h4, .form-section > span');

        if (label && !label.classList.contains('custom-file-label')) {
            const requiredStar = label.getElementsByClassName('required-star');
            if (!requiredStar.length) {
                return label;
            } else {
                // another component have already created a star on our closest form-group,
                // so we probably shouldn't hook up onto it - component that created it should remove it too
                return null;
            }
        }
        return null;
    }
}
