import {AppEditorSchema} from 'src/modules/wysiwyg-editor/configs/app-schema';
import {EditorState, Transaction} from 'prosemirror-state';
import {IEditorNodeAttributes, ProsemirrorCommand} from 'src/modules/wysiwyg-editor/interfaces';


export function insertBlockFactory(blockName: string, attrs?: IEditorNodeAttributes): ProsemirrorCommand {
    return (state, dispatch) => {
        const tr = state.tr,
            {to} = tr.selection,
            block = AppEditorSchema.nodes[blockName].createAndFill(attrs);

        tr.insert(to, block);
        dispatch(tr);
        return true;
    };
}

export function insertCodeBlock(
    state: EditorState,
    dispatch: (tr: Transaction) => void
): boolean {
    const {from, to} = state.selection,
        selectedText = state.doc.textBetween(from, to);

    return replaceBlockFactory(
        'code_block',
        {content: selectedText}
    )(state, dispatch);
}

export function replaceBlockFactory(blockName: string, attrs?: IEditorNodeAttributes): ProsemirrorCommand {
    return (state, dispatch) => {
        const tr = state.tr,
            block = AppEditorSchema.nodes[blockName].createAndFill(attrs);

        tr.replaceSelectionWith(block);
        dispatch(tr);
        return true;
    };
}

export function insertText(text: string) : ProsemirrorCommand {
    return (state, dispatch) => {
        const tr = state.tr,
            {to} = tr.selection;

        tr.insertText(text, to);
        dispatch(tr);
        return true;
    };
}
