import {AbstractOperationDefinition} from 'src/modules/operations/interfaces';
import {IRestObject} from 'src/modules/rest/objects';
import {INoteTableItem} from 'src/modules/notes/notes-table/notes-table.component';
import {Injector} from '@angular/core';
import {StateService} from '@uirouter/core';
import {ICooperationNote, ICooperationNoteInputDto, ITimeTrackerLog, TaskAssignmentRole} from '_types/rest';
import {Observable} from 'rxjs';
import {ConfirmModalService} from 'src/modules/global-components/confirm-modal/confirm-modal.service';
import {RestClient} from 'src/modules/rest/rest-client.service';
import {NotesService} from 'src/modules/notes/notes.service';
import {CopyService} from 'src/services/copy.service';
import {IOperationResult} from 'src/modules/operations/interfaces';
import {ITaskViewServiceTask, TaskViewService} from 'src/modules/tasks/task-view/task-view.service';
import DateExtended from 'date-extensions';
import {UserService} from 'src/modules/rest/user/user.service';

export interface INotesContext {
    note: Partial<ICooperationNote | ICooperationNoteInputDto>;
    accessObject?: unknown;
    timeLog?: ITimeTrackerLog;
    disabledFields?: string[];
}

export class AddNote
    extends AbstractOperationDefinition<'cooperation_notes', INotesContext> {
    readonly endpoint = 'cooperation_notes'
    readonly name = 'post';
    lang = 'ADD';
    icon = 'fa-plus';

    component = (): Promise<unknown> =>
        import('view-modules/operations/notes/note-operation-form/note-operation-form.component');

    access(context: INotesContext): boolean {
        if (RestClient.isRestObject(context.accessObject) || RestClient.isRestCollection(context.accessObject)) {
            return context.accessObject.access('post_list');
        }

        return false;
    }
}

export class AddNoteView
    extends AbstractOperationDefinition<'cooperation_notes', INotesContext> {
    readonly endpoint = 'cooperation_notes'
    readonly name = 'post_view';
    lang = 'ADD';
    icon = 'fa-plus';

    invoke(context: INotesContext, injector: Injector): void {
        const stateService = injector.get(StateService),
            stateName = stateService.current.name;

        if (['notes', 'clients.view.cooperationLog'].includes(stateName)) {
            stateService.go('.add', {context});
            return;
        }

        stateService.go('notes.add', {context});
    }

    access(context: INotesContext): boolean {
        if (RestClient.isRestObject(context.accessObject) || RestClient.isRestCollection(context.accessObject)) {
            return context.accessObject.access('post_list');
        }

        return false;
    }
}

export class EditNote extends AbstractOperationDefinition<'cooperation_notes',
    IRestObject<'cooperation_notes', INoteTableItem>> {
    readonly endpoint = 'cooperation_notes'
    readonly name = 'put';
    lang = 'EDIT';
    icon = 'fa-pencil-alt';

    component = (): Promise<unknown> =>
        import('view-modules/operations/notes/note-operation-form/note-operation-form.component');
}

export class EditNoteView extends AbstractOperationDefinition<'cooperation_notes',
    IRestObject<'cooperation_notes', INoteTableItem>> {
    readonly endpoint = 'cooperation_notes'
    readonly name = 'put_view';
    lang = 'EDIT';
    icon = 'fa-pencil-alt';

    invoke(context: IRestObject<'cooperation_notes', INoteTableItem>, injector: Injector): boolean {
        const stateService = injector.get(StateService),
            stateName = stateService.current.name,
            params = {noteId: context.id, context};

        setTimeout(() => {
            // Make sure this transition runs after other (e.g. task-view close transition).
            if (['notes', 'clients.view.cooperationLog'].includes(stateName)) {
                stateService.go('.edit', params);
                return;
            }

            if (stateName.startsWith('clients.view')) {
                stateService.go('clients.view.cooperationLog.edit', params);
                return;
            }

            stateService.go('notes.edit', params);
        });

        return false;
    }

    access(context: IRestObject<'cooperation_notes', INoteTableItem>): boolean {
        return context.access('put');
    }
}

export class BlockNote extends AbstractOperationDefinition<'cooperation_notes',
    IRestObject<'cooperation_notes', INoteTableItem>> {
    readonly endpoint = 'cooperation_notes'
    readonly name = 'block';
    lang = 'BLOCK';
    icon = 'fa-lock';
    readonly modalSize = 'md';

    component = (): Promise<unknown> =>
        import('view-modules/operations/notes/note-operation-block/note-operation-block.component');

    access(context: IRestObject<'cooperation_notes', INoteTableItem>): boolean {
        return typeof context.blockReason !== 'number' && context.access(this.name);
    }
}

export class UnblockNote extends AbstractOperationDefinition<'cooperation_notes',
    IRestObject<'cooperation_notes', INoteTableItem>> {
    readonly endpoint = 'cooperation_notes'
    readonly name = 'unblock';
    lang = 'UNBLOCK';
    icon = 'fa-unlock';

    invoke(
        context: IRestObject<'cooperation_notes', INoteTableItem>,
        injector: Injector
    ): Observable<IOperationResult> {
        const confirmModalService = injector.get(ConfirmModalService),
            restClient = injector.get(RestClient);

        return confirmModalService.confirmOperation('UNBLOCK_NOTE_CONFIRM', () => {
            return restClient.endpoint('cooperation_notes/unblock').update(context.id, {});
        });
    }

    access(context: IRestObject<'cooperation_notes', INoteTableItem>): boolean {
        return typeof context.blockReason === 'number' && context.access(this.name);
    }
}

export class WrongNote extends AbstractOperationDefinition<'cooperation_notes',
    IRestObject<'cooperation_notes', INoteTableItem>> {
    readonly endpoint = 'cooperation_notes'
    readonly name = 'wrong_note';
    lang = 'WRONG_NOTE';
    icon = 'fa-times-circle';

    invoke(
        context: IRestObject<'cooperation_notes', INoteTableItem>,
        injector: Injector
    ): Observable<IOperationResult> {
        const confirmModalService = injector.get(ConfirmModalService),
            restClient = injector.get(RestClient);

        return confirmModalService.confirmOperation('WRONG_NOTE_CONFIRM', () => {
            return restClient.endpoint('cooperation_notes/wrong_note').update(context.id, {});
        }, {
            text: 'WRONG_NOTE_CONFIRM_TEXT',
            primaryBtn: 'danger'
        });
    }

    access(context: IRestObject<'cooperation_notes', INoteTableItem>): boolean {
        return !context.wrongNote && context.access(this.name);
    }
}

export class CreateTask extends AbstractOperationDefinition<'cooperation_notes',
    IRestObject<'cooperation_notes', INoteTableItem>> {
    readonly endpoint = 'cooperation_notes'
    readonly name = 'create_task';
    lang = 'CREATE_TASK';
    icon = 'fa-tasks';

    invoke(
        context: IRestObject<'cooperation_notes', INoteTableItem>,
        injector: Injector
    ): void {
        const taskViewService = injector.get(TaskViewService),
            currentUser = injector.get(UserService).get(),
            taskInput: ITaskViewServiceTask = {
                taskModel: {
                    name: context.title,
                    description: context.text,
                    startDate: new DateExtended(context.created).format('Y-m-d'),
                    company: context.company ?? null,
                    taskUsers: [
                        {
                            userLogin: {
                                ...currentUser
                            },
                            role: TaskAssignmentRole.TASK_ASSIGNMENT_ROLE_AUTHOR
                        }
                    ],
                    sourceIri: context['@id'],
                },
                files: context.cooperationNoteFiles.map((cooperationNoteFile) => cooperationNoteFile.file)
            };

        taskViewService.addNewTask(taskInput);
    }

    access(context: IRestObject<'cooperation_notes', INoteTableItem>): boolean {
        return context.company
            && typeof context.blockReason !== 'number'
            && !context.wrongNote;
    }
}

export class OpenNotePreview
    extends AbstractOperationDefinition<string, IRestObject<'cooperation_notes', INoteTableItem>> {
    readonly endpoint = 'cooperation_notes'
    readonly name = 'open_preview';
    lang = 'PREVIEW';
    icon = 'fa-eye';

    invoke(
        context: IRestObject<'cooperation_notes', INoteTableItem>,
        injector: Injector
    ): void {
        const stateService = injector.get(StateService);
        stateService.go('.', {noteDetails: context.id});
    }

    access(context: IRestObject<'cooperation_notes', INoteTableItem>): boolean {
        return context.access('get_item');
    }
}

export class OpenNoteDetails
    extends AbstractOperationDefinition<string, IRestObject<'cooperation_notes', INoteTableItem>> {
    readonly endpoint = 'cooperation_notes'
    readonly name = 'details';
    lang = 'DETAILS';
    icon = 'fa-eye';

    invoke(
        context: IRestObject<'cooperation_notes', INoteTableItem>,
        injector: Injector
    ): boolean {
        const stateService = injector.get(StateService);
        setTimeout(() => {
            // Make sure this transition runs after other (e.g. task-view close transition).
            stateService.go('notes.details', {noteId: context.id});
        });
        return false;
    }

    access(context: IRestObject<'cooperation_notes', INoteTableItem>): boolean {
        return context.access('preview');
    }
}

export class ShareNote extends AbstractOperationDefinition<string, IRestObject<'cooperation_notes', INoteTableItem>> {
    readonly endpoint = 'cooperation_notes'
    readonly name = 'share';
    lang = 'SHARE';
    icon = 'fa-share';

    component = (): Promise<unknown> =>
        import('view-modules/operations/notes/note-operation-share/note-operation-share.component');

    access(): boolean {
        return true;
    }
}

export class CopyNoteLink
    extends AbstractOperationDefinition<string, IRestObject<'cooperation_notes', INoteTableItem>> {
    readonly endpoint = 'cooperation_notes'
    readonly name = 'copy_link';
    lang = 'SHARE';
    icon = 'fa-share';

    invoke(context: IRestObject<'cooperation_notes', INoteTableItem>, injector: Injector): void {
        const notesService = injector.get(NotesService),
            copyService = injector.get(CopyService),
            noteLink = notesService.getNoteDetailsLink(context.id);
        copyService.copyToClipboard(noteLink);
    }

    access(): boolean {
        return true;
    }
}

export class NoteFilesShow extends AbstractOperationDefinition<'cooperation_notes',
    IRestObject<'cooperation_notes', INoteTableItem>> {
    readonly endpoint = 'cooperation_notes'
    readonly name = 'show_files';
    lang = 'NOTE_FILES';
    icon = 'fa-file';

    disableAbandonWarning = true;

    component = (): Promise<unknown> =>
        import('view-modules/operations/notes/note-operation-files-show/note-operation-files-show.component');

    access(): boolean {
        return true;
    }
}

export const notesOperations = [
    AddNote,
    AddNoteView,
    CopyNoteLink,
    EditNote,
    EditNoteView,
    OpenNotePreview,
    OpenNoteDetails,
    BlockNote,
    UnblockNote,
    WrongNote,
    CreateTask,
    ShareNote,
    NoteFilesShow
] as const;
