import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {IFile, With, Without} from '_types/rest';
import {
    AbstractUnvalidatedControlValueAccessor
} from 'src/modules/app-forms/abstract-control-value-accessors/abstract-unvalidated-control-value-accessor';
import {
    IDynamicFieldControlValueAccessor, IFileDynamicFieldConfig
} from 'src/modules/dynamic-fields/interfaces';
import {FileUploadHelper, IFileModel, IFileRestModel} from 'src/modules/file-upload/file-upload-helper';
import {IUploadFile} from 'src/modules/file-upload/file-upload/file-upload.component';
import {RestClient} from 'src/modules/rest/rest-client.service';
import { Utils } from 'src/services/utils';

type ValueType = string | Without<IFileModel, 'file'>[] | null;
type ObjectValueType = IFile | With<IFileModel, 'file'>[] | null;

@Component({
    selector: 'file-input',
    templateUrl: './file-input.component.html',
    providers: AbstractUnvalidatedControlValueAccessor.getProviders(FileInputComponent)
})
export class FileInputComponent
    extends AbstractUnvalidatedControlValueAccessor
    implements IDynamicFieldControlValueAccessor, OnInit {

    @Input() fieldConfig: IFileDynamicFieldConfig;

    objectValue: ObjectValueType;
    @Output() readonly objectValueChange = new EventEmitter<ObjectValueType>();

    value: ValueType;
    filesAdditional?: With<IFileRestModel, 'file'>[];

    private defaultConfig: Partial<IFileDynamicFieldConfig> = {
        entityFieldName: 'file',
        multiple: false,
        viewMode: 'list'
    };


    constructor(
        private readonly restClient: RestClient
    ) {
        super();
    }

    ngOnInit(): void {
        this.initializeConfig();
    }

    private initializeConfig(): void {
        const temp = Object.assign(this.defaultConfig, this.fieldConfig);
        Object.assign(this.fieldConfig, temp);
    }

    writeValue(value: ValueType): void {
        if (!value) {
            return;
        }

        if (this.isMultipleForValue(value)) {
            this.handleMultipleValue(value);
        } else {
            this.handleSingleValue(value);
        }
        this.objectValueChange.emit(this.objectValue);
        this.updateValueFromObjectValue();
    }

    private isMultipleForValue(value: ValueType): value is Without<IFileModel, 'file'>[] {
        return this.fieldConfig.multiple;
    }

    private isMultipleForObjectValue(objectValue: ObjectValueType): objectValue is With<IFileModel, 'file'>[] {
        return this.fieldConfig.multiple;
    }

    private handleMultipleValue(value): void {
        if (!Array.isArray(value)) {
            return;
        }

        this.objectValue = value.filter(
            (file) => typeof file !== 'string'
        );

        this.filesAdditional = this.objectValue.map((fileModel) => {
            return this.restClient.endpoint<'files/upload', With<IFileModel, 'file'>>('files/upload')
                .createObject(fileModel);
        });
    }

    private handleSingleValue(value): void {
        if (Array.isArray(value) || typeof value !== 'object') {
            return;
        }
        this.objectValue = value;

        this.filesAdditional = [
            this.restClient.endpoint<'files/upload', With<IFileModel, 'file'>>('files/upload')
                .createObject(FileUploadHelper.prepareFileObjectModelFromFile(value))
        ];
    }


    private updateValueFromObjectValue(): void {
        const newValue = this.getNewModelFromObjectValue();
        this.value = newValue;
        this._onChange(newValue);
    }

    private getNewModelFromObjectValue(): ValueType {
        if (!this.isMultipleForObjectValue(this.objectValue)) {
            if (typeof this.objectValue === 'object' && this.objectValue !== null) {
                return this.objectValue['@id'];
            }

            return null;
        }

        return this.objectValue.map((item) => {
            if (typeof item === 'object' && item['@id']) {
                return item['@id'];
            }
            if (typeof item === 'string') {
                return item;
            }
            const tempObjectValue = Utils.clone(item);
            Utils.shortenFields(tempObjectValue, ['file']);

            return tempObjectValue;
        });
    }

    uploadFile(file: IFile): void {
        if (this.isMultipleForObjectValue(this.objectValue)) {
            const fileObjectModel = FileUploadHelper.prepareFileObjectModelFromFile(file);

            this.objectValue.push(fileObjectModel);
        } else {
            (this.objectValue as IFile) = file;
        }

        this.objectValueChange.emit(this.objectValue);

        this.updateValueFromObjectValue();
        this.markAsTouched();
    }

    deleteFile(file: IUploadFile): void {
        if (this.isMultipleForObjectValue(this.objectValue)) {
            this.objectValue = this.objectValue.filter((object) =>
                object.file.id !== file.id
            );
        } else {
            this.objectValue = null;
        }

        this.objectValueChange.emit(this.objectValue);

        this.updateValueFromObjectValue();
        this.markAsTouched();
    }
}
