import {TtFilterGroup} from 'angular-bootstrap4-table-tools';
import {ReplaySubject} from 'rxjs';
import {DynamicTableFilter} from 'src/modules/dynamic-table/dynamic-table-filters/dynamic-table-filter';

export class DynamicTableFilterGroup extends TtFilterGroup {

    controls: Record<string, DynamicTableFilter>;

    private filterInitializedSubject = new ReplaySubject<void>(1);
    filterInitialized$ = this.filterInitializedSubject.asObservable();

    // eslint-disable-next-line @typescript-eslint/no-useless-constructor
    constructor(filters: {
        [field: string]: DynamicTableFilter
    }) {
        super(filters);
    }

    emitFilterInitialized(): void {
        this.filterInitializedSubject.next();
        this.filterInitializedSubject.complete();
    }
}
