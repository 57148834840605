

export abstract class TextUtils {
    private static readonly DOTS = '...';

    static shortText(
        text: string,
        maxLength: number
    ): string {
        if (text?.length > maxLength) {
            return text.substring(0, maxLength - TextUtils.DOTS.length) + this.DOTS;
        }
        return text;
    }
}
