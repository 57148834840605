import {Transition} from '@uirouter/core';
import {Component} from '@angular/core';

@Component({
    selector: 'app-error',
    templateUrl: './app-error.component.html'
})
export class AppErrorComponent {
    constructor(
        public transition: Transition
    ) {
    }
}
