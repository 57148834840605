import {AbstractDeleteOperation} from 'view-modules/operations/abstract/abstract-delete.operation';
import {
    ConfirmModalService,
    IConfirmModalOptions
} from 'src/modules/global-components/confirm-modal/confirm-modal.service';
import {AbstractOperationDefinition} from 'src/modules/operations/interfaces';
import {Observable} from 'rxjs';
import {IRestCollection} from 'src/modules/rest/objects';
import {Injector} from '@angular/core';
import {RestClient} from 'src/modules/rest/rest-client.service';
import {ToastsService} from 'src/modules/global-components/toasts/toasts.service';
import {TranslateService} from 'src/modules/translate/translate.service';
import {IOperationResult} from 'src/modules/operations/interfaces';

export class SearchConsoleScheduleDeleteOperation extends AbstractDeleteOperation<'search_console_schedules'> {
    readonly endpoint = 'search_console_schedules';
    lang = 'DELETE';
    confirmMessage = 'DELETE_SCHEDULE_ITEM_CONFIRM';
    confirmOptions: IConfirmModalOptions = {
        langYes: 'DELETE_SCHEDULE_ITEM_CONFIRM_LANG_YES',
        text: 'ACTION_CANNOT_BE_UNDONE',
        primaryBtn: 'danger'
    };
}

export class SearchConsoleScheduleRunOperation
    extends AbstractOperationDefinition<'search_console_schedules', IRestCollection<'search_console_schedules'>> {

    readonly endpoint = 'search_console_schedules';
    readonly name = 'run';

    icon = 'fa-run';
    lang = 'GENERATE_SCHEDULE';

    invoke(context: IRestCollection<'search_console_schedules'>, injector: Injector): Observable<IOperationResult> {
        return injector.get(ConfirmModalService).confirmOperation('GENERATE_SCHEDULE_CONFIRM', () => {
            return injector.get(RestClient).endpoint('search_console_schedules/run')
                .getAll()
                .subscribe(() => {
                    injector.get(ToastsService).pop(
                        injector.get(TranslateService).get('SCHEDULE_CRON_STARTED'),
                        null,
                        'success'
                    );
                });
        }, {
            langYes: 'GENERATE_SCHEDULE_CONFIRM_LANG_YES'
        });
    }

    access(context: IRestCollection<'search_console_schedules'>): boolean {
        if (RestClient.isRestCollection(context)) {
            return context.access('post');
        }

        return false;
    }
}

export const searchConsoleScheduleOperations = [
    SearchConsoleScheduleDeleteOperation,
    SearchConsoleScheduleRunOperation
] as const;
