/* eslint-disable max-len */
/**
 * This file has been auto generated. Do not edit.
 */
import {ITimezone} from '_types/rest/Entity/IRestTimezone';
import {IRestTimezone} from '_types/rest/Entity/IRestTimezone';
import {ILocale} from '_types/rest/Entity/IRestLocale';
import {IRestLocale} from '_types/rest/Entity/IRestLocale';
import {ICurrency} from '_types/rest/Entity/IRestCurrency';
import {IRestCurrency} from '_types/rest/Entity/IRestCurrency';
import {ICountry} from '_types/rest/Entity/IRestCountry';
import {IRestCountry} from '_types/rest/Entity/IRestCountry';
import {IPartner} from '_types/rest/Entity/IRestPartner';
import {IRestPartner} from '_types/rest/Entity/IRestPartner';
import {IFile} from '_types/rest/Entity/IRestFile';
import {IRestFile} from '_types/rest/Entity/IRestFile';
import {ICategory} from '_types/rest/Entity/IRestCategory';
import {IRestCategory} from '_types/rest/Entity/IRestCategory';
import {ICompanyContact} from '_types/rest/Entity/IRestCompanyContact';
import {IRestCompanyContact} from '_types/rest/Entity/IRestCompanyContact';
import {ISwot} from '_types/rest/Entity/IRestSwot';
import {IRestSwot} from '_types/rest/Entity/IRestSwot';
import {IDocumentRepresentation} from '_types/rest/Entity/IRestDocumentRepresentation';
import {IRestDocumentRepresentation} from '_types/rest/Entity/IRestDocumentRepresentation';
import {IMail} from '_types/rest/Entity/IRestMail';
import {IRestMail} from '_types/rest/Entity/IRestMail';
import {IAddress} from '_types/rest/Entity/IRestAddress';
import {IRestAddress} from '_types/rest/Entity/IRestAddress';
import {ICompanyIdentifier} from '_types/rest/Entity/IRestCompanyIdentifier';
import {IRestCompanyIdentifier} from '_types/rest/Entity/IRestCompanyIdentifier';
import {IDocument} from '_types/rest/Entity/IRestDocument';
import {IRestDocument} from '_types/rest/Entity/IRestDocument';
import {IOAuth2Grant} from '_types/rest/Entity/IRestOAuth2Grant';
import {IRestOAuth2Grant} from '_types/rest/Entity/IRestOAuth2Grant';
import {IUserApi} from '_types/rest/Entity/IRestUserApi';
import {IRestUserApi} from '_types/rest/Entity/IRestUserApi';
import {ITaskTemplateProcessingOverride} from '_types/rest/Entity/IRestTaskTemplateProcessingOverride';
import {IRestTaskTemplateProcessingOverride} from '_types/rest/Entity/IRestTaskTemplateProcessingOverride';
import {IUserLogin} from '_types/rest/Entity/IRestUserLogin';
import {IRestUserLogin} from '_types/rest/Entity/IRestUserLogin';
import {IContract} from '_types/rest/Entity/IRestContract';
import {IRestContract} from '_types/rest/Entity/IRestContract';
import {IService} from '_types/rest/Entity/IRestService';
import {IRestService} from '_types/rest/Entity/IRestService';
import {IGuardian} from '_types/rest/Entity/IRestGuardian';
import {IRestGuardian} from '_types/rest/Entity/IRestGuardian';
import {ICooperationNote} from '_types/rest/Entity/IRestCooperationNote';
import {IRestCooperationNote} from '_types/rest/Entity/IRestCooperationNote';
import {IEntityFieldValueFile} from '_types/rest/Entity/IEntityFieldValueFile';
import {IBaseRestEntity} from '_types/rest/IBaseRestEntity';
import {IRestObject} from 'core/rest/rest-object.service';

export const CompanyType = {
    COMPANY_TYPE_BAUER: 1,
    COMPANY_TYPE_PARTNER: 2,
    COMPANY_TYPE_CLIENT: 3
} as const;

export const CompanyTypeOptions = [
    {
        value: CompanyType.COMPANY_TYPE_BAUER,
        label: 'COMPANY_TYPE_BAUER',
    },
    {
        value: CompanyType.COMPANY_TYPE_PARTNER,
        label: 'COMPANY_TYPE_PARTNER',
    },
    {
        value: CompanyType.COMPANY_TYPE_CLIENT,
        label: 'COMPANY_TYPE_CLIENT',
    }
] as const;

export const CompanyLegalForm = {
    COMPANY_LEGAL_FORM_SOLE_TRADER: 1
} as const;

export const CompanyLegalFormOptions = [
    {
        value: CompanyLegalForm.COMPANY_LEGAL_FORM_SOLE_TRADER,
        label: 'COMPANY_LEGAL_FORM_SOLE_TRADER',
    }
] as const;

export interface ICompanyConst {
    type: [{id: 1, value: 'COMPANY_TYPE_BAUER'}, {id: 2, value: 'COMPANY_TYPE_PARTNER'}, {id: 3, value: 'COMPANY_TYPE_CLIENT'}],
    legalForm: [{id: 1, value: 'COMPANY_LEGAL_FORM_SOLE_TRADER'}]
}

export interface ICompany extends IBaseRestEntity {
    /**
     * @description ID.
     */
    id?: number,
    /**
     * @description Some custom ID.
     * @maxLength 64
     */
    customId?: string | null,
    /**
     * @description Company type (Bauer, Partner, Client).
     * @default 3
     */
    type?: typeof CompanyType[keyof typeof CompanyType],
    legalForm?: typeof CompanyLegalForm[keyof typeof CompanyLegalForm],
    /**
     * @description Company name.
     * @maxLength 128
     */
    name: string,
    /**
     * @description Company short name.
     * @maxLength 128
     */
    shortName?: string | null,
    /**
     * @description Tax number (eg NIP).
     */
    vatId?: string | null,
    /**
     * @description REGON.
     * @maxLength 14
     */
    regon?: string | null,
    /**
     * @description Timezone.
     */
    timezone: ITimezone | string | null,
    /**
     * @description Default language.
     */
    locale: ILocale | string | null,
    /**
     * @description Currency.
     */
    currency: ICurrency | string | null,
    /**
     * @description Country.
     */
    country: ICountry | string | null,
    /**
     * @description Partner to which a record is assigned (client to partner, partner to the Bauer company).
     */
    partner?: IPartner | string | null,
    /**
     * @description File (eg company logo).
     */
    file?: IFile | string | null,
    /**
     * @description Some notes.
     * @maxLength 1024
     */
    note?: string | null,
    /**
     * @description Automatic tag.
     */
    automaticTag?: ICategory | string | null,
    /**
     * @description Tags.
     */
    tags?: (ICategory | string)[],
    /**
     * @description Company contacts - only for creating new company in one POST request (eg creating company from
     * lead).
     */
    companyContacts?: (ICompanyContact | string)[],
    swots?: (ISwot | string)[],
    documentRepresentations?: (IDocumentRepresentation | string)[],
    mails?: (IMail | string)[],
    addresses?: (IAddress | string)[],
    companyIdentifiers?: (ICompanyIdentifier | string)[],
    documents?: (IDocument | string)[],
    oAuth2Grants?: (IOAuth2Grant | string)[],
    userApis?: (IUserApi | string)[],
    taskTemplateProcessingOverrides?: (ITaskTemplateProcessingOverride | string)[],
    /**
     * @description User logins - only for creating new company in one POST request (eg creating company from lead).
     */
    userLogins?: (IUserLogin | string)[],
    /**
     * @description Defining partner relation. Uses OneToMany because we don't want to use OneToOne in this case.
     */
    companyPartner?: (IPartner | string)[],
    contracts?: (IContract | string)[],
    services?: (IService | string)[],
    guardians?: (IGuardian | string)[],
    cooperationNotes?: (ICooperationNote | string)[],
    /**
     * @description Record status
     *  0 = deleted
     *  1 = active.
     * @default 1
     */
    status?: number,
    /**
     * @description Creation timestamp.
     * @default CURRENT_TIMESTAMP
     * @format date-time
     */
    created?: string,
    /**
     * @description Last modification timestamp.
     * @format date-time
     */
    modified?: string | null,
    _field?: { [key: string]: string | IEntityFieldValueFile }
}

/** @deprecated */
export interface IRestCompany extends ICompany, IRestObject {
    /**
     * @description Timezone.
     */
    timezone: IRestTimezone | string | null,
    /**
     * @description Default language.
     */
    locale: IRestLocale | string | null,
    /**
     * @description Currency.
     */
    currency: IRestCurrency | string | null,
    /**
     * @description Country.
     */
    country: IRestCountry | string | null,
    /**
     * @description Partner to which a record is assigned (client to partner, partner to the Bauer company).
     */
    partner?: IRestPartner | string | null,
    /**
     * @description File (eg company logo).
     */
    file?: IRestFile | string | null,
    /**
     * @description Automatic tag.
     */
    automaticTag?: IRestCategory | string | null,
    /**
     * @description Tags.
     */
    tags?: IRestCategory[] | string[],
    /**
     * @description Company contacts - only for creating new company in one POST request (eg creating company from
     * lead).
     */
    companyContacts?: IRestCompanyContact[] | string[],
    swots?: IRestSwot[] | string[],
    documentRepresentations?: IRestDocumentRepresentation[] | string[],
    mails?: IRestMail[] | string[],
    addresses?: IRestAddress[] | string[],
    companyIdentifiers?: IRestCompanyIdentifier[] | string[],
    documents?: IRestDocument[] | string[],
    oAuth2Grants?: IRestOAuth2Grant[] | string[],
    userApis?: IRestUserApi[] | string[],
    taskTemplateProcessingOverrides?: IRestTaskTemplateProcessingOverride[] | string[],
    /**
     * @description User logins - only for creating new company in one POST request (eg creating company from lead).
     */
    userLogins?: IRestUserLogin[] | string[],
    /**
     * @description Defining partner relation. Uses OneToMany because we don't want to use OneToOne in this case.
     */
    companyPartner?: IRestPartner[] | string[],
    contracts?: IRestContract[] | string[],
    services?: IRestService[] | string[],
    guardians?: IRestGuardian[] | string[],
    cooperationNotes?: IRestCooperationNote[] | string[],
    _field?: { [key: string]: string | IEntityFieldValueFile }
}
