/* eslint-disable max-len */
/**
 * This file has been auto generated. Do not edit.
 */
import {ICompany} from '_types/rest/Entity/IRestCompany';
import {IRestCompany} from '_types/rest/Entity/IRestCompany';
import {IContractItem} from '_types/rest/Entity/IRestContractItem';
import {IRestContractItem} from '_types/rest/Entity/IRestContractItem';
import {IDocumentTemplate} from '_types/rest/Entity/IRestDocumentTemplate';
import {IRestDocumentTemplate} from '_types/rest/Entity/IRestDocumentTemplate';
import {IContractParam} from '_types/rest/Entity/IRestContractParam';
import {IRestContractParam} from '_types/rest/Entity/IRestContractParam';
import {IBaseRestEntity} from '_types/rest/IBaseRestEntity';
import {IRestObject} from 'core/rest/rest-object.service';

export const ContractState = {
    CONTRACT_STATE_NEW: 1,
    CONTRACT_STATE_REJECTED: 2,
    CONTRACT_STATE_AWAITING: 3,
    CONTRACT_STATE_ACTIVE: 4,
    CONTRACT_STATE_TERMINATION: 5,
    CONTRACT_STATE_COMPLETED: 6,
    CONTRACT_STATE_SUSPENDED: 7,
    CONTRACT_STATE_FROZEN: 8
} as const;

export const ContractStateOptions = [
    {
        value: ContractState.CONTRACT_STATE_NEW,
        label: 'CONTRACT_STATE_NEW',
    },
    {
        value: ContractState.CONTRACT_STATE_REJECTED,
        label: 'CONTRACT_STATE_REJECTED',
    },
    {
        value: ContractState.CONTRACT_STATE_AWAITING,
        label: 'CONTRACT_STATE_AWAITING',
    },
    {
        value: ContractState.CONTRACT_STATE_ACTIVE,
        label: 'CONTRACT_STATE_ACTIVE',
    },
    {
        value: ContractState.CONTRACT_STATE_TERMINATION,
        label: 'CONTRACT_STATE_TERMINATION',
    },
    {
        value: ContractState.CONTRACT_STATE_COMPLETED,
        label: 'CONTRACT_STATE_COMPLETED',
    },
    {
        value: ContractState.CONTRACT_STATE_SUSPENDED,
        label: 'CONTRACT_STATE_SUSPENDED',
    },
    {
        value: ContractState.CONTRACT_STATE_FROZEN,
        label: 'CONTRACT_STATE_FROZEN',
    }
] as const;

export const ContractType = {
    CONTRACT_TYPE_CONTRACT: 1,
    CONTRACT_TYPE_ANNEX: 2,
    CONTRACT_TYPE_TERMINATION: 3,
    CONTRACT_TYPE_TRANSFER: 4
} as const;

export const ContractTypeOptions = [
    {
        value: ContractType.CONTRACT_TYPE_CONTRACT,
        label: 'CONTRACT_TYPE_CONTRACT',
    },
    {
        value: ContractType.CONTRACT_TYPE_ANNEX,
        label: 'CONTRACT_TYPE_ANNEX',
    },
    {
        value: ContractType.CONTRACT_TYPE_TERMINATION,
        label: 'CONTRACT_TYPE_TERMINATION',
    },
    {
        value: ContractType.CONTRACT_TYPE_TRANSFER,
        label: 'CONTRACT_TYPE_TRANSFER',
    }
] as const;

export interface IContractConst {
    state: [{id: 1, value: 'CONTRACT_STATE_NEW'}, {id: 2, value: 'CONTRACT_STATE_REJECTED'}, {id: 3, value: 'CONTRACT_STATE_AWAITING'}, {id: 4, value: 'CONTRACT_STATE_ACTIVE'}, {id: 5, value: 'CONTRACT_STATE_TERMINATION'}, {id: 6, value: 'CONTRACT_STATE_COMPLETED'}, {id: 7, value: 'CONTRACT_STATE_SUSPENDED'}, {id: 8, value: 'CONTRACT_STATE_FROZEN'}],
    type: [{id: 1, value: 'CONTRACT_TYPE_CONTRACT'}, {id: 2, value: 'CONTRACT_TYPE_ANNEX'}, {id: 3, value: 'CONTRACT_TYPE_TERMINATION'}, {id: 4, value: 'CONTRACT_TYPE_TRANSFER'}]
}

export interface IContract extends IBaseRestEntity {
    id?: number,
    info?: string | null,
    /**
     * @format date-time
     */
    date?: string | null,
    mark?: string | null,
    /**
     * @default 1
     */
    state: typeof ContractState[keyof typeof ContractState],
    /**
     * @default 1
     */
    type: typeof ContractType[keyof typeof ContractType],
    contract?: IContract | string | null,
    company?: ICompany | string,
    contractItems: (IContractItem | string)[],
    documentTemplate?: IDocumentTemplate | string | null,
    contractParams?: (IContractParam | string)[],
    baseContract?: IContract | string | null,
    /**
     * @description Record status
     *  0 = deleted
     *  1 = active.
     * @default 1
     */
    status?: number,
    /**
     * @description Creation timestamp.
     * @default CURRENT_TIMESTAMP
     * @format date-time
     */
    created?: string,
    /**
     * @description Last modification timestamp.
     * @format date-time
     */
    modified?: string | null
}

/** @deprecated */
export interface IRestContract extends IContract, IRestObject {
    contract?: IRestContract | string | null,
    company?: IRestCompany | string,
    contractItems: IRestContractItem[] | string[],
    documentTemplate?: IRestDocumentTemplate | string | null,
    contractParams?: IRestContractParam[] | string[],
    baseContract?: IRestContract | string | null,
}

/** @deprecated */
export const IRestContractConstState = ContractState;
/** @deprecated */
export const IRestContractConstType = ContractType;
/** @deprecated */
export type IRestContractConst = IContractConst & IRestObject;
