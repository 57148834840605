import {Compiler, Injectable, Injector, Type} from '@angular/core';
import {StateService, ViewConfig, ViewService} from '@uirouter/core';
// eslint-disable-next-line no-restricted-imports
import {LazyLoadService} from 'core/lazy-load/lazy-load.provider';
import {AppStateDeclaration} from 'src/modules/route-helper/interfaces';
import {AjsUiViewComponent} from 'src/modules/hybrid/ajs-ui-view/ajs-ui-view.component';
import {Utils} from 'src/services/utils';
import {Ng2ViewDeclaration} from '@uirouter/angular/interface';
import NamingConventions from 'naming-conventions';

export interface HybridAppStateDeclaration extends Omit<AppStateDeclaration, 'component' | 'childs' | 'views'> {
    component?: Type<unknown> | string,
    childs?: HybridAppStateDeclaration[],
    views?: {
        [key: string]: Omit<Ng2ViewDeclaration, 'component'> & {
            component?: Type<unknown> | string
        };
    };
}

// disable inspections as we only need types, angular will be lazy loaded later
// noinspection TypeScriptUMDGlobal
type Ajs = angular.IAngularStatic;
// noinspection TypeScriptUMDGlobal
export type AjsInjector = angular.auto.IInjectorService;
// noinspection TypeScriptUMDGlobal
type AjsModule = angular.IModule;

@Injectable({
    providedIn: 'root'
})
export class AngularHybridService {
    static stateDeclarations: Record<string, HybridAppStateDeclaration> = {};
    private ajsInjector: AjsInjector;
    private ajsHybridModule: AjsModule;
    private ajsElement: HTMLElement;
    private loadedModules: string[] = [];
    private static ajsStates: string[] = [];

    constructor(
        private compiler: Compiler,
        private injector: Injector,
        private view: ViewService,
        private stateService: StateService
    ) {
    }

    /**
     * In case when ng1 view loads ng2 view, ng2 view may need access to ng1 view resolvable
     * because of that we need to override ViewService methods to merge ng1 resolves to ng2 view
     */
    init(): void {
        const originalFn = this.view.registerUIView.bind(this.view);
        this.view.registerUIView = (uiView) => {
            const originalConfigUpdated = uiView.configUpdated.bind(uiView);
            uiView.configUpdated = (viewConfig) => {
                if (!viewConfig || !this.ajsInjector) {
                    originalConfigUpdated(viewConfig);
                    return;
                }

                const paths = viewConfig.path
                    .filter((pathItem) => AngularHybridService.ajsStates.includes(pathItem.state.name));

                if (paths.length) {
                    const ajsViewConfigs: ViewConfig[] = this.ajsInjector
                        .get<ViewService>('$view')['_viewConfigs'];

                    paths.forEach((pathItem) => {
                        ajsViewConfigs
                            .some((ajsViewConfig: ViewConfig) => {
                                const ajsPathItem = ajsViewConfig.path.find((ajsPathItem) => {
                                    return ajsPathItem.state.name === pathItem.state.name;
                                });

                                if (ajsPathItem) {
                                    pathItem.resolvables.push(
                                        ...ajsPathItem.resolvables.filter((resolvable) => {
                                            return !pathItem.resolvables.includes(resolvable);
                                        })
                                    );
                                }

                                return !!ajsPathItem;
                            });
                    });
                }

                originalConfigUpdated(viewConfig);
            };
            return originalFn(uiView);
        };
    }

    lazyLoadAjsModule(
        element: HTMLElement,
        moduleName: string
    ): Promise<{ ajsInjector: AjsInjector, isReload: boolean }> {
        return this.bootstrap(element).then((ajsInjector) => {
            return this.importAjsModule(moduleName).then((ajsModuleName) => {
                return ajsInjector.get<LazyLoadService>('lazyLoad').module(ajsModuleName)
                    .then(() => {
                        const isReload = this.loadedModules.includes(moduleName);
                        if (!isReload) {
                            this.loadedModules.push(moduleName);
                        }
                        return {
                            ajsInjector: this.ajsInjector,
                            isReload
                        };
                    });
            });
        });
    }

    private bootstrap(element: HTMLElement): Promise<AjsInjector> {
        if (this.ajsElement) {
            try {
                element.append(this.ajsElement);
            } catch (e) {
                // in some cases element may already contain our ng1 element
            }
            return Promise.resolve(this.ajsInjector);
        }

        return Promise.all([
            import('core/core.module').then((coreModule) => {
                // @angular/upgrade/static needs angularJS to be loaded, so core.module needs to be imported first
                return Promise.all([
                    import('@angular/upgrade/static'),
                    import('@uirouter/angular-hybrid')
                ])
                    .then(([upgradeStatic, uiRouterHybrid]) => {
                        return {
                            coreModule,
                            upgradeStatic,
                            uiRouterHybrid
                        };
                    });
            }),
            import('private/private.module')
        ]).then(([{coreModule, upgradeStatic, uiRouterHybrid}, privateModule]) => {
            upgradeStatic.setAngularJSGlobal(coreModule.angularJs);
            const ngModuleFactory = this.compiler.compileModuleSync(upgradeStatic.UpgradeModule);
            const upgradeModule = ngModuleFactory.create(this.injector);

            this.ajsElement = document.createElement('div');
            this.ajsElement.append(document.createElement('ui-view'));
            element.append(this.ajsElement);

            upgradeModule.instance.bootstrap(
                this.ajsElement,
                [
                    this.getAjsHybridModule(coreModule.angularJs, uiRouterHybrid.upgradeModule.name).name,
                    coreModule.appCore,
                    privateModule.appPrivate
                ],
                {strictDi: true}
            );
            return this.ajsInjector = upgradeModule.instance.$injector;
        });
    }

    private getAjsHybridModule(angularJs: Ajs, uiRouterHybridModuleName): AjsModule {
        if (this.ajsHybridModule) {
            return this.ajsHybridModule;
        }

        return this.ajsHybridModule = angularJs.module('angularHybrid', [uiRouterHybridModuleName])
            .config(['$provide', ($provide) => {
                // when ajs state changes pass it to ng2 stateService
                $provide.decorator('$state', ['$delegate', ($delegate) => {
                    $delegate.go = this.stateService.go.bind(this.stateService);
                    return $delegate;
                }]);
            }]);
    }

    private importAjsModule(moduleName: string): Promise<string> {
        /* webpackInclude: /app\/private\/([a-z]+)\/\1\.module\.ts$/ */
        const promise: Promise<{
            default?: string,
            [exportName: string]: string
        }> = import(`private/${moduleName}/${moduleName}.module`);

        return promise.then((module) => {
            return module.default || Object.values(module).pop();
        });
    }

    static overrideAjsRouteState(state: HybridAppStateDeclaration): AppStateDeclaration {
        let isAjsState = false;
        let overridesView = false;
        const stateDeclaration = Utils.clone(state);
        this.stateDeclarations[state.name] = stateDeclaration;

        if (typeof state.views === 'object' && state.views !== null) {
            Object.values(state.views).forEach((view) => {
                if (typeof view.component === 'string') {
                    view.component = AjsUiViewComponent;
                    isAjsState = true;
                    overridesView = true;
                }
            });
        } else if (typeof state.component === 'string') {
            state.component = AjsUiViewComponent;
            isAjsState = true;
        }

        if (isAjsState) {
            const isParentAjsState = !overridesView && this.isParentAjsState(state.name);
            this.ajsStates.push(state.name);
            if (typeof state.resolve !== 'object') {
                state.resolve = {};
            }

            Object.assign(state.resolve, {
                stateDeclaration: () => stateDeclaration,
                moduleName: () => NamingConventions.convert(state.name.replace(/\..*$/, ''))
                    .to(NamingConventions.kebabCase),
                isParentAjsState: () => isParentAjsState
            });
        }

        return state as AppStateDeclaration;
    }

    static isAjsState(stateName: string): boolean {
        return this.ajsStates.includes(stateName);
    }

    static isParentAjsState(stateName: string): boolean {
        return this.isAjsState(stateName.split('.').slice(0, -1).join('.'));
    }
}
