import {AbstractOperationDefinition} from 'src/modules/operations/interfaces';
import {Injector} from '@angular/core';
import {IConfirmModalOptions} from 'src/modules/global-components/confirm-modal/confirm-modal.service';
import {IRestObject} from 'src/modules/rest/objects';
import {StateService} from '@uirouter/core';
import {AbstractDeleteOperation} from 'view-modules/operations/abstract/abstract-delete.operation';

export class DeleteCommunication extends AbstractDeleteOperation<'mails'> {
    readonly endpoint = 'mails'
    lang = 'DELETE';
    confirmMessage = 'DELETE_EMAIL_CONFIRM';
    confirmOptions: IConfirmModalOptions = {
        text: 'CONFIRM_ABANDONING_CHANGES_TEXT',
        primaryBtn: 'danger'
    };
}

export class ViewCommunication extends AbstractOperationDefinition<'mails', IRestObject<'mails'>> {
    readonly endpoint = 'mails'
    readonly name = 'view';
    lang = 'VIEW';
    icon = 'fa-eye';
    invoke = (
        context: IRestObject<'mails'>,
        injector: Injector
    ): void => {
        const stateService = injector.get(StateService);

        stateService.go(
            'clients.view.communication',
            {
                mailId: context.id,
                companyId: stateService.params.companyId
            }
        );
    };

    access(): boolean {
        return true;
    }
}

export const communicationOperations = [
    DeleteCommunication,
    ViewCommunication
] as const;
