import {AbstractOperationDefinition} from 'src/modules/operations/interfaces';
import {AbstractDeleteOperation} from 'view-modules/operations/abstract/abstract-delete.operation';

export class OrganicGroupAddOperation extends AbstractOperationDefinition<'a_c_l_organic_groups'> {
    readonly endpoint = 'a_c_l_organic_groups';
    readonly name = 'post';
    lang = 'ADD';
    icon = 'fa-plus';

    component = (): Promise<unknown> => import('./organic-group-form.component');

    access(): boolean {
        return true;
    }
}

export class OrganicGroupEditOperation extends AbstractOperationDefinition<'a_c_l_organic_groups'> {
    readonly endpoint = 'a_c_l_organic_groups';
    readonly name = 'put';
    lang = 'EDIT';
    icon = 'fa-edit';

    component = (): Promise<unknown> => import('./organic-group-form.component');

    access(): boolean {
        return true;
    }
}


export class OrganicGroupDeleteOperation extends AbstractDeleteOperation<'a_c_l_organic_groups'> {
    readonly endpoint = 'a_c_l_organic_groups';
    lang = 'DELETE';
    confirmMessage = 'ORGANIC_GROUP_REMOVE_CONFIRM';
    confirmOptions = {
        langYes: 'ORGANIC_GROUP_REMOVE_CONFIRM_LANG_YES'
    };
}

export const organicGroupsOperations = [
    OrganicGroupAddOperation,
    OrganicGroupEditOperation,
    OrganicGroupDeleteOperation
] as const;
