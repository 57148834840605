/* eslint-disable max-len */
/**
 * This file has been auto generated. Do not edit.
 */
import {IDocumentType} from '_types/rest/Entity/IRestDocumentType';
import {IRestDocumentType} from '_types/rest/Entity/IRestDocumentType';
import {IBaseRestEntity} from '_types/rest/IBaseRestEntity';
import {IRestObject} from 'core/rest/rest-object.service';

export const DocumentTypeComponentSectionType = {
    DOCUMENT_TYPE_COMPONENT_SECTION_TYPE_HEADER: 1,
    DOCUMENT_TYPE_COMPONENT_SECTION_TYPE_FOOTER: 2,
    DOCUMENT_TYPE_COMPONENT_SECTION_TYPE_SUBJECT: 3,
    DOCUMENT_TYPE_COMPONENT_SECTION_TYPE_CONTENT: 4,
    DOCUMENT_TYPE_COMPONENT_SECTION_TYPE_LANDING_WRAPPER: 5,
    DOCUMENT_TYPE_COMPONENT_SECTION_TYPE_LANDING_FORM: 6,
    DOCUMENT_TYPE_COMPONENT_SECTION_TYPE_LANDING_LOADER: 7,
    DOCUMENT_TYPE_COMPONENT_SECTION_TYPE_LANDING_ERROR: 8,
    DOCUMENT_TYPE_COMPONENT_SECTION_TYPE_FORM_FIELDS: 9,
    DOCUMENT_TYPE_COMPONENT_SECTION_TYPE_FORM_SUCCESS: 10,
    DOCUMENT_TYPE_COMPONENT_SECTION_TYPE_FORM_ERROR: 11,
    DOCUMENT_TYPE_COMPONENT_SECTION_TYPE_NOTIFICATION_TOKENS: 20001,
    DOCUMENT_TYPE_COMPONENT_SECTION_TYPE_NOTIFICATION_TITLE: 20002,
    DOCUMENT_TYPE_COMPONENT_SECTION_TYPE_NOTIFICATION_CONTENT: 20003
} as const;

export const DocumentTypeComponentSectionTypeOptions = [
    {
        value: DocumentTypeComponentSectionType.DOCUMENT_TYPE_COMPONENT_SECTION_TYPE_HEADER,
        label: 'DOCUMENT_TYPE_COMPONENT_SECTION_TYPE_HEADER',
    },
    {
        value: DocumentTypeComponentSectionType.DOCUMENT_TYPE_COMPONENT_SECTION_TYPE_FOOTER,
        label: 'DOCUMENT_TYPE_COMPONENT_SECTION_TYPE_FOOTER',
    },
    {
        value: DocumentTypeComponentSectionType.DOCUMENT_TYPE_COMPONENT_SECTION_TYPE_SUBJECT,
        label: 'DOCUMENT_TYPE_COMPONENT_SECTION_TYPE_SUBJECT',
    },
    {
        value: DocumentTypeComponentSectionType.DOCUMENT_TYPE_COMPONENT_SECTION_TYPE_CONTENT,
        label: 'DOCUMENT_TYPE_COMPONENT_SECTION_TYPE_CONTENT',
    },
    {
        value: DocumentTypeComponentSectionType.DOCUMENT_TYPE_COMPONENT_SECTION_TYPE_LANDING_WRAPPER,
        label: 'DOCUMENT_TYPE_COMPONENT_SECTION_TYPE_LANDING_WRAPPER',
    },
    {
        value: DocumentTypeComponentSectionType.DOCUMENT_TYPE_COMPONENT_SECTION_TYPE_LANDING_FORM,
        label: 'DOCUMENT_TYPE_COMPONENT_SECTION_TYPE_LANDING_FORM',
    },
    {
        value: DocumentTypeComponentSectionType.DOCUMENT_TYPE_COMPONENT_SECTION_TYPE_LANDING_LOADER,
        label: 'DOCUMENT_TYPE_COMPONENT_SECTION_TYPE_LANDING_LOADER',
    },
    {
        value: DocumentTypeComponentSectionType.DOCUMENT_TYPE_COMPONENT_SECTION_TYPE_LANDING_ERROR,
        label: 'DOCUMENT_TYPE_COMPONENT_SECTION_TYPE_LANDING_ERROR',
    },
    {
        value: DocumentTypeComponentSectionType.DOCUMENT_TYPE_COMPONENT_SECTION_TYPE_FORM_FIELDS,
        label: 'DOCUMENT_TYPE_COMPONENT_SECTION_TYPE_FORM_FIELDS',
    },
    {
        value: DocumentTypeComponentSectionType.DOCUMENT_TYPE_COMPONENT_SECTION_TYPE_FORM_SUCCESS,
        label: 'DOCUMENT_TYPE_COMPONENT_SECTION_TYPE_FORM_SUCCESS',
    },
    {
        value: DocumentTypeComponentSectionType.DOCUMENT_TYPE_COMPONENT_SECTION_TYPE_FORM_ERROR,
        label: 'DOCUMENT_TYPE_COMPONENT_SECTION_TYPE_FORM_ERROR',
    },
    {
        value: DocumentTypeComponentSectionType.DOCUMENT_TYPE_COMPONENT_SECTION_TYPE_NOTIFICATION_TOKENS,
        label: 'DOCUMENT_TYPE_COMPONENT_SECTION_TYPE_NOTIFICATION_TOKENS',
    },
    {
        value: DocumentTypeComponentSectionType.DOCUMENT_TYPE_COMPONENT_SECTION_TYPE_NOTIFICATION_TITLE,
        label: 'DOCUMENT_TYPE_COMPONENT_SECTION_TYPE_NOTIFICATION_TITLE',
    },
    {
        value: DocumentTypeComponentSectionType.DOCUMENT_TYPE_COMPONENT_SECTION_TYPE_NOTIFICATION_CONTENT,
        label: 'DOCUMENT_TYPE_COMPONENT_SECTION_TYPE_NOTIFICATION_CONTENT',
    }
] as const;

export interface IDocumentTypeComponentSectionConst {
    type: [{id: 1, value: 'DOCUMENT_TYPE_COMPONENT_SECTION_TYPE_HEADER'}, {id: 2, value: 'DOCUMENT_TYPE_COMPONENT_SECTION_TYPE_FOOTER'}, {id: 3, value: 'DOCUMENT_TYPE_COMPONENT_SECTION_TYPE_SUBJECT'}, {id: 4, value: 'DOCUMENT_TYPE_COMPONENT_SECTION_TYPE_CONTENT'}, {id: 5, value: 'DOCUMENT_TYPE_COMPONENT_SECTION_TYPE_LANDING_WRAPPER'}, {id: 6, value: 'DOCUMENT_TYPE_COMPONENT_SECTION_TYPE_LANDING_FORM'}, {id: 7, value: 'DOCUMENT_TYPE_COMPONENT_SECTION_TYPE_LANDING_LOADER'}, {id: 8, value: 'DOCUMENT_TYPE_COMPONENT_SECTION_TYPE_LANDING_ERROR'}, {id: 9, value: 'DOCUMENT_TYPE_COMPONENT_SECTION_TYPE_FORM_FIELDS'}, {id: 10, value: 'DOCUMENT_TYPE_COMPONENT_SECTION_TYPE_FORM_SUCCESS'}, {id: 11, value: 'DOCUMENT_TYPE_COMPONENT_SECTION_TYPE_FORM_ERROR'}, {id: 20001, value: 'DOCUMENT_TYPE_COMPONENT_SECTION_TYPE_NOTIFICATION_TOKENS'}, {id: 20002, value: 'DOCUMENT_TYPE_COMPONENT_SECTION_TYPE_NOTIFICATION_TITLE'}, {id: 20003, value: 'DOCUMENT_TYPE_COMPONENT_SECTION_TYPE_NOTIFICATION_CONTENT'}]
}

export interface IDocumentTypeComponentSection extends IBaseRestEntity {
    id?: number,
    /**
     * @default 1
     */
    type?: typeof DocumentTypeComponentSectionType[keyof typeof DocumentTypeComponentSectionType],
    /**
     * @description TODO delete this column when new mailer system will be finished.
     * @maxLength 64
     */
    name?: string,
    /**
     * @default true
     */
    limited?: boolean | null,
    documentType: IDocumentType | string | null,
    /**
     * @description Record status
     *  0 = deleted
     *  1 = active.
     * @default 1
     */
    status?: number,
    /**
     * @description Creation timestamp.
     * @default CURRENT_TIMESTAMP
     * @format date-time
     */
    created?: string,
    /**
     * @description Last modification timestamp.
     * @format date-time
     */
    modified?: string | null
}

/** @deprecated */
export interface IRestDocumentTypeComponentSection extends IDocumentTypeComponentSection, IRestObject {
    documentType: IRestDocumentType | string | null,
}
