import {EventEmitter, inject, InjectionToken, ModuleWithProviders, Type} from '@angular/core';
import {DOCUMENT} from '@angular/common';

export interface IEnvironment {
    env: {
        API_URL?: string,
        API_PROTOCOL?: string,
        API_HOST?: string,
        API_PORT?: string,
        API_PATH?: string,
        LIVE_RELOAD_PROTOCOL?: string,
        LIVE_RELOAD_HOST?: string,
        CERTIFICATES_PATH?: string,
        secureLazyLoad?: boolean,
        sentryDsn?: string
    },
    APP: {
        current: 'public' | 'private' | 'tests',
        production: boolean,
        build: string
    },
    dist: {
        core: string,
        public: string,
        private: string,
        base: string
    },
    BROWSER_SUPPORT_REGEX: RegExp
}

export interface IDevComponent {
    destroy: EventEmitter<void>
}

declare global {
    interface Window {
        ENV: IEnvironment,
        DevModule?: Type<unknown>,
        DevHttpResponseFakerModule: ModuleWithProviders<unknown>
    }
}

export const WINDOW = new InjectionToken<Window>('A reference to the window object', {
    providedIn: 'root',
    factory: (): Window => inject<Document>(DOCUMENT).defaultView
});

export const ENV = new InjectionToken<IEnvironment>('window.ENV', {
    providedIn: 'root',
    factory: (): IEnvironment => inject<Window>(WINDOW).ENV
});

export const APP_ROOT_COMPONENT = new InjectionToken<Type<{}>>('App root component');
export const APP_DEV_COMPONENT = new InjectionToken<Type<IDevComponent>>('App root component');
