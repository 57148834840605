/**
 * This file has been auto generated. Do not edit.
 */
import {IReport} from '_types/rest/Entity/IRestReport';
import {IRestReport} from '_types/rest/Entity/IRestReport';
import {IReportDisplayRelationship} from '_types/rest/Entity/IRestReportDisplayRelationship';
import {IRestReportDisplayRelationship} from '_types/rest/Entity/IRestReportDisplayRelationship';
import {IReportDisplayField} from '_types/rest/Entity/IRestReportDisplayField';
import {IRestReportDisplayField} from '_types/rest/Entity/IRestReportDisplayField';
import {IReportDisplayOrder} from '_types/rest/Entity/IRestReportDisplayOrder';
import {IRestReportDisplayOrder} from '_types/rest/Entity/IRestReportDisplayOrder';
import {IReportDisplayFilterGroup} from '_types/rest/Entity/IRestReportDisplayFilterGroup';
import {IRestReportDisplayFilterGroup} from '_types/rest/Entity/IRestReportDisplayFilterGroup';
import {IReportDisplayContextualFilter} from '_types/rest/Entity/IRestReportDisplayContextualFilter';
import {IRestReportDisplayContextualFilter} from '_types/rest/Entity/IRestReportDisplayContextualFilter';
import {IReportDisplayHeader} from '_types/rest/Entity/IRestReportDisplayHeader';
import {IRestReportDisplayHeader} from '_types/rest/Entity/IRestReportDisplayHeader';
import {IReportDisplayFooter} from '_types/rest/Entity/IRestReportDisplayFooter';
import {IRestReportDisplayFooter} from '_types/rest/Entity/IRestReportDisplayFooter';
import {IReportDisplayNoResultsBehavior} from '_types/rest/Entity/IRestReportDisplayNoResultsBehavior';
import {IRestReportDisplayNoResultsBehavior} from '_types/rest/Entity/IRestReportDisplayNoResultsBehavior';
import {IReportDisplayGroup} from '_types/rest/Entity/IRestReportDisplayGroup';
import {IRestReportDisplayGroup} from '_types/rest/Entity/IRestReportDisplayGroup';
import {IReportDisplayUrl} from '_types/rest/Entity/IRestReportDisplayUrl';
import {IRestReportDisplayUrl} from '_types/rest/Entity/IRestReportDisplayUrl';
import {IReportDisplayPagination} from '_types/rest/Entity/IRestReportDisplayPagination';
import {IRestReportDisplayPagination} from '_types/rest/Entity/IRestReportDisplayPagination';
import {IReportDisplayOperation} from '_types/rest/Entity/IRestReportDisplayOperation';
import {IRestReportDisplayOperation} from '_types/rest/Entity/IRestReportDisplayOperation';
import {IReportDisplayRowOperation} from '_types/rest/Entity/IRestReportDisplayRowOperation';
import {IRestReportDisplayRowOperation} from '_types/rest/Entity/IRestReportDisplayRowOperation';
import {IReportDisplayReference} from '_types/rest/Entity/IRestReportDisplayReference';
import {IRestReportDisplayReference} from '_types/rest/Entity/IRestReportDisplayReference';
import {IBaseRestEntity} from '_types/rest/IBaseRestEntity';
import {IRestObject} from 'core/rest/rest-object.service';

export const ReportDisplayType = {
    REPORT_DISPLAY_TYPE_PAGE: 1,
    REPORT_DISPLAY_TYPE_FILE: 2
} as const;

export const ReportDisplayTypeOptions = [
    {
        value: ReportDisplayType.REPORT_DISPLAY_TYPE_PAGE,
        label: 'REPORT_DISPLAY_TYPE_PAGE',
    },
    {
        value: ReportDisplayType.REPORT_DISPLAY_TYPE_FILE,
        label: 'REPORT_DISPLAY_TYPE_FILE',
    }
] as const;

export interface IReportDisplayConst {
    type: [{id: 1, value: 'REPORT_DISPLAY_TYPE_PAGE'}, {id: 2, value: 'REPORT_DISPLAY_TYPE_FILE'}]
}

export interface IReportDisplay extends IBaseRestEntity {
    id?: number,
    uuid?: string,
    /**
     * @maxLength 128
     */
    name: string,
    /**
     * @default 1
     */
    type: typeof ReportDisplayType[keyof typeof ReportDisplayType],
    /**
     * @default true
     */
    default?: boolean,
    /**
     * @maxLength 64
     */
    format: string,
    formatOptions?: string[] | null,
    /**
     * @maxLength 64
     */
    baseEntity: string,
    report: IReport | string,
    reportDisplayRelationships?: (IReportDisplayRelationship | string)[],
    reportDisplayFields?: (IReportDisplayField | string)[],
    reportDisplayOrders?: (IReportDisplayOrder | string)[],
    reportDisplayFilterGroups?: (IReportDisplayFilterGroup | string)[],
    reportDisplayContextualFilters?: (IReportDisplayContextualFilter | string)[],
    reportDisplayHeaders?: (IReportDisplayHeader | string)[],
    reportDisplayFooters?: (IReportDisplayFooter | string)[],
    reportDisplayNoResultsBehaviors?: (IReportDisplayNoResultsBehavior | string)[],
    reportDisplayGroups?: (IReportDisplayGroup | string)[],
    reportDisplayUrls?: (IReportDisplayUrl | string)[],
    reportDisplayPaginations?: (IReportDisplayPagination | string)[],
    reportDisplayOperations?: (IReportDisplayOperation | string)[],
    reportDisplayRowOperations?: (IReportDisplayRowOperation | string)[],
    reportDisplayReferences?: (IReportDisplayReference | string)[],
    reverseReportDisplayReferences?: (IReportDisplayReference | string)[],
    /**
     * @description Record status
     *  0 = deleted
     *  1 = active.
     * @default 1
     */
    status?: number,
    /**
     * @description Creation timestamp.
     * @default CURRENT_TIMESTAMP
     * @format date-time
     */
    created?: string,
    /**
     * @description Last modification timestamp.
     * @format date-time
     */
    modified?: string | null
}

/** @deprecated */
export interface IRestReportDisplay extends IReportDisplay, IRestObject {
    report: IRestReport | string,
    reportDisplayRelationships?: IRestReportDisplayRelationship[] | string[],
    reportDisplayFields?: IRestReportDisplayField[] | string[],
    reportDisplayOrders?: IRestReportDisplayOrder[] | string[],
    reportDisplayFilterGroups?: IRestReportDisplayFilterGroup[] | string[],
    reportDisplayContextualFilters?: IRestReportDisplayContextualFilter[] | string[],
    reportDisplayHeaders?: IRestReportDisplayHeader[] | string[],
    reportDisplayFooters?: IRestReportDisplayFooter[] | string[],
    reportDisplayNoResultsBehaviors?: IRestReportDisplayNoResultsBehavior[] | string[],
    reportDisplayGroups?: IRestReportDisplayGroup[] | string[],
    reportDisplayUrls?: IRestReportDisplayUrl[] | string[],
    reportDisplayPaginations?: IRestReportDisplayPagination[] | string[],
    reportDisplayOperations?: IRestReportDisplayOperation[] | string[],
    reportDisplayRowOperations?: IRestReportDisplayRowOperation[] | string[],
    reportDisplayReferences?: IRestReportDisplayReference[] | string[],
    reverseReportDisplayReferences?: IRestReportDisplayReference[] | string[],
}
