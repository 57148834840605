import {Plugin, PluginKey} from 'prosemirror-state';
import {Injector} from '@angular/core';
import {MENTION_EVENT_TYPE} from './interfaces';
import {MentionsService} from 'src/modules/wysiwyg-editor/plugins/mention-factory/mentions.service';
import {
    MentionFactoryState
} from 'src/modules/wysiwyg-editor/plugins/mention-factory/mention-factory-state';
import {IMentionMetadata} from 'src/modules/wysiwyg-editor/interfaces';

export const mentionFactoryPluginKey = (outside = false): string => {
    let key = 'mentionFactory';
    if (outside) {
        key += '$';
    }
    return key;
};

export const mentionFactoryPlugin = (
    injector: Injector,
    metadataSection: IMentionMetadata
): Plugin => {
    const mentionsService = injector.get(MentionsService);
    mentionsService.registerMetadata(metadataSection);

    return new Plugin({
        key: new PluginKey(mentionFactoryPluginKey()),
        view() {
            return {
                update: (view, prevPluginState) => {
                    const prev = this.key.getState(prevPluginState),
                        next = this.key.getState(view.state),
                        emitEvent = (type) => {
                            mentionsService.emitMentionEvent({
                                type,
                                state: next
                            });
                        };

                    const moved = prev.active && next.active && prev.range.from !== next.range.from,
                        started = !prev.active && next.active,
                        stopped = prev.active && !next.active,
                        textChanged = !started && !stopped && prev.text !== next.text;

                    const exit = stopped || moved,
                        changed = textChanged && !moved,
                        open = started || moved;

                    if (open) {
                        emitEvent(MENTION_EVENT_TYPE.OPEN);
                        return;
                    }

                    if (changed) {
                        emitEvent(MENTION_EVENT_TYPE.CHANGE);
                        return;
                    }

                    if (exit) {
                        emitEvent(MENTION_EVENT_TYPE.EXIT);
                        return;
                    }
                },
            };
        },
        state: new MentionFactoryState(),
        props: {
            handleKeyDown(view, e): boolean {
                const state = this.getState(view.state);
                if (!state.active) {
                    return false;
                }

                const hijackedKeys = ['Escape', 'ArrowDown', 'ArrowUp', 'Enter'];
                if (hijackedKeys.includes(e.key)) {
                    mentionsService.emitMentionEvent({
                        type: MENTION_EVENT_TYPE.KEY,
                        state,
                        keyEvent: e
                    });
                    return true;
                }
                return false;
            },
            handleDOMEvents: {
                blur(view, event): boolean {
                    const state = this.getState(view.state);
                    if (!state.active) {
                        return false;
                    }


                    const isUserListElement = event.relatedTarget
                        && (event.relatedTarget as Element).classList.contains('mention-user-list-item');
                    if (isUserListElement) {
                        return false;
                    }

                    mentionsService.emitMentionEvent({
                        type: MENTION_EVENT_TYPE.BLUR,
                        state,
                    });
                    return false;
                },
            }
        }
    });
};
