import {RadioSelectComponent} from 'src/modules/dynamic-fields/controls/dynamic-select/custom-select/radio-select.component';
import {Component} from '@angular/core';
import {
    AbstractUnvalidatedControlValueAccessor
} from 'src/modules/app-forms/abstract-control-value-accessors/abstract-unvalidated-control-value-accessor';

@Component({
    selector: 'radio-buttons-select',
    templateUrl: './radio-buttons-select.component.html',
    providers: AbstractUnvalidatedControlValueAccessor.getProviders(RadioButtonsSelectComponent)
})
export class RadioButtonsSelectComponent extends RadioSelectComponent {
}
