import {Component, Injector, Input, OnInit} from '@angular/core';
import {IEntityEventHistoryOutputDto} from '_types/rest';
import {EventHistoryService} from 'src/modules/history/events-history/event-history.service';
import {eventPayloadProcessors} from 'src/modules/history/events-history/event-payload-processors';


@Component({
    selector: 'event-history-item',
    templateUrl: './event-history-item.component.html'
})
export class EventHistoryItemComponent implements OnInit {
    @Input() item: IEntityEventHistoryOutputDto;

    author: string;
    actionLabel: string;
    payload: string;

    constructor(
        private readonly eventHistoryService: EventHistoryService,
        private readonly injector: Injector
    ) {
    }

    ngOnInit(): void {
        this.computeAuthorLabel();
        this.computeActionLabel();
        this.computePayload();
    }

    private computeAuthorLabel(): void {
        this.author = this.eventHistoryService.getEventAuthor(this.item);
    }

    private computeActionLabel(): void {
        this.actionLabel = `EVENT_STRING_${this.item.eventHistoryAction.name}`;
    }

    private computePayload(): void {
        const eventProcessor = eventPayloadProcessors[this.item.eventHistoryAction.name];

        if (typeof eventProcessor !== 'function') {
            this.payload = this.item.payload as string;
            return;
        }

        this.payload = eventProcessor(this.item.payload, this.injector);
    }


}
