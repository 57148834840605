import {AbstractOperationDefinition} from 'src/modules/operations/interfaces';
import {IACLOGEntityDependencyDto, IACLOrganicGroup} from '_types/rest';
import {AbstractDeleteOperation} from 'view-modules/operations/abstract/abstract-delete.operation';


interface OgEntityDependenciesContext {
    organicGroup: IACLOrganicGroup;
    entityDependency: IACLOGEntityDependencyDto | {ACLOrganicGroup: string};
}

export class OGEntityDependencyAddOperation extends AbstractOperationDefinition<string, OgEntityDependenciesContext> {
    readonly endpoint = 'a_c_l_o_g_entity_dependencies';
    readonly name = 'post';
    lang = 'ADD';
    icon = 'fa-plus';

    component = (): Promise<unknown> => import('./og-entity-dependencies-form.component');

    access(): boolean {
        return true;
    }
}

export class OGEntityDependencyEditOperation extends AbstractOperationDefinition<string, OgEntityDependenciesContext> {
    readonly endpoint = 'a_c_l_o_g_entity_dependencies';
    readonly name = 'put';
    lang = 'EDIT';
    icon = 'fa-edit';

    component = (): Promise<unknown> => import('./og-entity-dependencies-form.component');

    access(): boolean {
        return true;
    }
}

export class OGEntityDependencyDeleteOperation extends AbstractDeleteOperation<'a_c_l_o_g_entity_dependencies'> {
    readonly endpoint = 'a_c_l_o_g_entity_dependencies';
    lang = 'DELETE';
    confirmMessage = 'OG_ENTITY_DEPENDENCY_REMOVE';
    confirmOptions = {
        langYes: 'OG_ENTITY_DEPENDENCY_REMOVE_CONFIRM_LANG_YES'
    };

    access(): boolean {
        return true;
    }
}

export const ogEntityDependenciesOperations = [
    OGEntityDependencyAddOperation,
    OGEntityDependencyEditOperation,
    OGEntityDependencyDeleteOperation
] as const;
