import {Injectable} from '@angular/core';
import {RestClient} from 'src/modules/rest/rest-client.service';
import {
    IFile, IScheduleTask,
    ITaskDefinitionOutputDto, ITaskListOutputDto,
    ITaskNote,
    ITaskTemplateOutputDto, ScheduleType,
    TaskAssignmentRole, TaskState, With
} from '_types/rest';
import {Comparison} from 'src/services/comparison';
import {
    ITaskTemplateComponentWithMeta
} from 'view-modules/private/admin/tasks/admin-task-template-edit/admin-task-template-edit.component';
import {IRestObject} from 'src/modules/rest/objects';
import {AppTask, boundTask} from 'src/modules/tasks/task-view/task-view.component';
import {UserService} from 'src/modules/rest/user/user.service';
import DateExtended from 'date-extensions';
import {DEFAULT_DATE_START_OF_THE_DAY_FORMAT} from 'src/pipes/date.pipe';
import {IQueryObject} from 'src/services/url-parser.service';
import {DateUtils} from 'src/services/date-utils';


@Injectable({
    providedIn: 'root'
})
export class TaskUtilsService {
    readonly MAX_DIFFERENCE_FOR_DAYS_IN_WEEK = 7;
    readonly START_DATE_WEEKLY_NUM = 1;
    readonly DUE_DATE_WEEKLY_NUM = 0;
    constructor(
        private readonly restClient: RestClient,
        private readonly userService: UserService,
    ) {
    }

    /**
     * Map existing files to {id,IRI} and move new files {IRI} to files array
     * This is fork of filesService.mergeBeforeSave()
     * @see filesService.mergeBeforeSave()
     */
    mergeFiles(
        data: ITaskDefinitionOutputDto | ITaskNote,
        newFiles = '_taskFiles',
        oldFiles = 'taskFiles'
    ): void {
        // Map existing files
        if (typeof data[oldFiles] !== 'undefined') {
            data[oldFiles] = data[oldFiles].map((file) => {
                if (typeof file.file['@id'] !== 'undefined') {
                    return {
                        id: file.id,
                        file: file.file['@id']
                    };
                } else {
                    return {
                        id: file.id,
                        file: this.restClient
                            .endpoint('files')
                            .getIri((file.file as IFile).id)
                    };
                }
            });
        }
        // Move new files
        if (
            typeof data[newFiles] !== 'undefined'
            && data[newFiles].length
        ) {
            // Initialize files array
            if (typeof data[oldFiles] === 'undefined') {
                data[oldFiles] = [];
            }
            data[newFiles].forEach((item) => {
                if (typeof item['@id'] !== 'undefined') {
                    data[oldFiles].push({
                        file: item['@id']
                    });
                }
            });
            delete data[newFiles];
        }
    }

    /**
     * Get original component from template components by meta _id.
     * @param collection Original components array
     * @param copy Copy of component
     */
    getOriginalComponent(
        collection: ITaskTemplateComponentWithMeta[],
        copy: number | (ITaskTemplateOutputDto & {_id: number})
    ): ITaskTemplateComponentWithMeta {
        const id = typeof copy === 'number' ? copy : copy._id;
        return collection.find(Comparison.criteria({_id: id}));
    }

    access(
        value: IRestObject<'tasks/item', boundTask> | IRestObject<'tasks/item', AppTask>,
        operation: string,
        property?: string
    ): boolean {
        if (!value?.id) {
            return true;
        }

        if (RestClient.isRestObject(value)) {
            return value.access(operation, property);
        }

        return false;
    }

    isCurrentUserAssignee(task: AppTask | boundTask): boolean {
        return !!task.taskUsers.find((taskUser) => {
            return taskUser.role === TaskAssignmentRole.TASK_ASSIGNMENT_ROLE_ASSIGNEE
                && taskUser.userLogin?.id === this.userService.get()?.id;
        });
    }

    isCurrentUserAuthor(task: AppTask | boundTask): boolean {
        if (!task.taskUsers) {
            return false;
        }

        const taskAuthor = task.taskUsers.find(({role}) => role === TaskAssignmentRole.TASK_ASSIGNMENT_ROLE_AUTHOR);

        return taskAuthor?.userLogin?.id === this.userService.get()?.id;
    }

    getTaskIterationsToSkip(task: AppTask | boundTask, userLoginIris: string[] = []): number {
        return task.taskUsers.reduce((sum, taskUser) => {
            if (taskUser.role === TaskAssignmentRole.TASK_ASSIGNMENT_ROLE_AUTHOR) {
                return sum;
            }

            if (userLoginIris.length && !userLoginIris.includes(taskUser.userLogin['@id'])) {
                return sum;
            }

            return sum + (taskUser.taskState?.iterationsToSkip || 0);
        }, 0);
    }

    isTaskOutdated(task: AppTask | ITaskListOutputDto): boolean {
        if (
            task.state === TaskState.TASK_STATE_COMPLETED
            || task.dueDate === null
        ) {
            return false;
        }

        const today = new DateExtended().format(DEFAULT_DATE_START_OF_THE_DAY_FORMAT),
            endDateFormatted = new DateExtended(task.dueDate).format(DEFAULT_DATE_START_OF_THE_DAY_FORMAT);
        return endDateFormatted < today;
    }

    isTaskProcessingModelSingle(task: AppTask | ITaskListOutputDto): boolean {
        return typeof task.taskProcessingModel?.multipleAssignee === 'boolean'
            && task.taskProcessingModel?.multipleAssignee === false;
    }

    isTaskProcessingModelMultiple(task: AppTask | ITaskListOutputDto): boolean {
        return !this.isTaskProcessingModelSingle(task);
    }

    isTaskCyclic(task: {scheduleTasks?: With<IScheduleTask, 'schedule'>[]}): boolean {
        return Array.isArray(task.scheduleTasks) && !!task.scheduleTasks.find((scheduleTask) => {
            return scheduleTask
            && scheduleTask.enabled
            && scheduleTask.schedule.type === ScheduleType.SCHEDULE_TYPE_SCHEDULE_PERIODICAL;
        });
    }

    static mutatePinnedTasksQuery(query: IQueryObject): void {
        const pinnedListEnabledParams = [
            'with',
            'onlyPinnedItems',
            'page',
            'itemsPerPage',
            'order',
            'contextUserId',
        ];
        Object.keys(query).forEach((param) => {
            if (!pinnedListEnabledParams.includes(param)) {
                query[param] = undefined;
            }
        });
    }

    areDatesWeekly(startDate: string, dueDate: string): boolean {
        const startDateIsMon = new Date(startDate).getDay() === this.START_DATE_WEEKLY_NUM,
            dueDateIsSun = new Date(dueDate).getDay() === this.DUE_DATE_WEEKLY_NUM,
            differenceBetweenDatesInDays = DateUtils.getDifferenceBetweenDatesInDays(startDate, dueDate),
            datesAreFromSameWeek = this.MAX_DIFFERENCE_FOR_DAYS_IN_WEEK > differenceBetweenDatesInDays;

        return startDateIsMon && dueDateIsSun && datesAreFromSameWeek;
    }

    getWeeklyRangeByDay(date: string, format?: string): { startDate: string, dueDate: string } {
        const dateExtended = new DateExtended(date),
            dayNumber = dateExtended.getDay(),
            mondayNumber = dateExtended.getDate() - dayNumber + (dayNumber === 0 ? -6 : 1),
            timestamp = dateExtended.setDate(mondayNumber),
            startDate = new DateExtended(timestamp).format(format ?? 'Y-m-d'),
            dueDate = new DateExtended(timestamp).add(4).format(format ?? 'Y-m-d');

        return {startDate, dueDate};
    }
}
