import {Component, Input, Output, EventEmitter} from '@angular/core';
import {IFileRestModel} from 'src/modules/file-upload/file-upload-helper';

export interface IFileListItem {
    name: string;
    size?: number;
    id?: null | number;
    progress?: number;
    progressHidden?: boolean;
    originalItem?: IFileRestModel;
}

@Component({
    selector: 'file-list',
    templateUrl: './file-list.component.html'
})
export class FileListComponent {
    @Input() files: IFileListItem[] = [];
    @Output() readonly fileDelete = new EventEmitter<IFileListItem>();
}
