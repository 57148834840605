import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {
    IDynamicTable, IDynamicTableOperation
} from 'src/modules/dynamic-table/interfaces';
import {ITableTools} from 'angular-bootstrap4-table-tools';
import {Subject} from 'rxjs';
import {take, takeUntil} from 'rxjs/operators';
import {Comparison} from 'src/services/comparison';
import {DynamicTableService} from 'src/modules/dynamic-table/dynamic-table.service';
import {IOperationClass, IOperationResult} from 'src/modules/operations/interfaces';

@Component({
    selector: 'dynamic-table',
    templateUrl: './dynamic-table.component.html',
    styleUrls: ['./dynamic-table.component.scss']
})
export class DynamicTableComponent<T extends object> implements OnInit, OnDestroy {
    @Input() tableConfig: ITableTools<T> & IDynamicTable<T>;

    itemOperationsContext = new WeakMap<T, Map<IDynamicTableOperation<T>, unknown>>();
    selectedItemsDropdown: boolean;
    selectedItemsContext = new Map<IDynamicTableOperation<T[]>, unknown[]>();
    rowClickContext = new WeakMap<T, ReturnType<IDynamicTable<T>['rowClick']>>();
    rowClassContext = new WeakMap<T, ReturnType<IDynamicTable<T>['rowClass']>>();
    rowStyleContext = new WeakMap<T, ReturnType<IDynamicTable<T>['rowStyle']>>();
    hasAllItemOperations = false;
    configured = false;

    private _destroy$ = new Subject<void>();

    constructor(
        private readonly dynamicTableService: DynamicTableService
    ) {
    }

    ngOnInit(): void {
        if (
            typeof this.tableConfig.itemOperations !== 'undefined'
            || typeof this.tableConfig.rowClick !== 'undefined'
            || typeof this.tableConfig.rowClass !== 'undefined'
            || typeof this.tableConfig.rowStyle !== 'undefined'
        ) {
            this.tableConfig.data
                .pipe(takeUntil(this._destroy$))
                .subscribe((items) => {
                    items.forEach((item) => {
                        if (typeof this.tableConfig.itemOperations !== 'undefined') {
                            const operationsMap = new Map<IDynamicTableOperation<T>, unknown>();

                            this.tableConfig.itemOperations.forEach((operation) => {
                                operationsMap.set(operation, operation.context ? operation.context(item) : item);
                            });

                            this.itemOperationsContext.set(item, operationsMap);
                        }

                        if (typeof this.tableConfig.rowClick !== 'undefined') {
                            this.rowClickContext.set(item, this.tableConfig.rowClick(item));
                        }

                        if (typeof this.tableConfig.rowClass !== 'undefined') {
                            this.rowClassContext.set(item, this.tableConfig.rowClass(item));
                        }

                        if (typeof this.tableConfig.rowStyle !== 'undefined') {
                            this.rowStyleContext.set(item, this.tableConfig.rowStyle(item));
                        }
                    });
                });
        }

        if (typeof this.tableConfig.selectedItemOperations !== 'undefined') {
            this.tableConfig.selected.selectedChanges
                .pipe(takeUntil(this._destroy$))
                .subscribe((items) => {
                    this.tableConfig.selectedItemOperations.forEach((operation) => {
                        this.selectedItemsContext.set(
                            operation, operation.context ? operation.context(items) as unknown[] : items
                        );
                    });
                });

            this.hasAllItemOperations = this.tableConfig.selectedItemOperations
                && this.tableConfig.selectedItemOperations.some(
                    Comparison.criteria({isAllItemOperation: true})
                );
        }

        this.tableConfig.configured$
            .pipe(take(1))
            .subscribe(() => {
                this.configured = true;
            });
    }

    updateTableSettings(): void {
        this.dynamicTableService.saveTableSettings(this.tableConfig);
    }

    handleOperationSuccess(operationResult: IOperationResult, operation: IOperationClass): void {
        if (typeof this.tableConfig.operationSuccessCb === 'function') {
            this.tableConfig.operationSuccessCb(operationResult, operation) ? this.tableConfig.filterData() : null;
            return;
        }

        if (!operationResult.success) {
            return;
        }

        this.tableConfig.filterData();
    }

    ngOnDestroy(): void {
        this._destroy$.next();
        this._destroy$.complete();
    }
}
