import {Directive, HostListener, Input} from '@angular/core';
import {CopyService} from 'src/services/copy.service';


@Directive({
    selector: '[copy]'
})
export class CopyDirective {
    @Input() copy = (): string => '';

    constructor(
        private readonly copyService: CopyService
    ) {
    }

    @HostListener('click', ['$event']) onClick(event: MouseEvent): void {
        event.preventDefault();
        this.copyService.copyToClipboard(this.copy());
    }
}
