import {Canvas, Image} from 'canvas';

export function scaleCanvas(
    img: Image,
    canvas: Canvas,
    width: number,
    height: number,
    cropToFit = true,
    mimeType: 'image/png' | 'image/jpeg' = 'image/png'
): string {
    const ctx = canvas.getContext('2d');
    let ratio = width / img.width;
    canvas.width = width;
    canvas.height = height;
    if (typeof cropToFit === 'undefined') {
        cropToFit = true;
    }
    if (cropToFit) {
        if (img.height * ratio < height) { // width is the longer edge
            const offset = (height - img.height * ratio) / 2;
            ctx.drawImage(
                img,
                0, 0, img.width, img.height,
                0, offset, width, img.height * ratio
            );
        } else { // height is the longer edge
            ratio = height / img.height;
            const offset = (width - img.width * ratio) / 2;
            ctx.drawImage(
                img,
                0, 0, img.width, img.height,
                offset, 0, img.width * ratio, height
            );
        }
    } else {
        if (img.height * ratio < height) {
            ratio = height / img.height;
            const offset = (((img.width * ratio) - width) / 2) / ratio;
            ctx.drawImage(
                img,
                offset, 0, img.width - (offset * 2), img.height,
                0, 0, width, height
            );
        } else {
            const offset = (((img.height * ratio) - height) / 2) / ratio;
            ctx.drawImage(
                img,
                0, offset, img.width, img.height - (offset * 2),
                0, 0, width, height
            );
        }
    }
    return mimeType === 'image/png'
        ? canvas.toDataURL(mimeType)
        : canvas.toDataURL('image/jpeg', undefined);
}
