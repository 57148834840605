import {NgModule} from '@angular/core';
import {DynamicTableViewComponent} from 'src/modules/dynamic-table/dynamic-table-view/dynamic-table-view.component';
import {DynamicTableComponent} from 'src/modules/dynamic-table/dynamic-table/dynamic-table.component';
import {TranslateModule} from 'src/modules/translate/translate.module';
import {AppTableToolsModule} from 'src/modules/app-table-tools/app-table-tools.module';
import {CellTemplatesComponent} from 'src/modules/dynamic-table/cell-templates/cell-templates.component';
import {AppDirectivesModule} from 'src/directives/app-directives.module';
import {OperationsModule} from 'src/modules/operations/operations.module';
import {ActionsModule} from 'src/modules/actions/actions.module';
import {DynamicTableCellDirective} from 'src/modules/dynamic-table/dynamic-table/dynamic-table-cell.directive';
import {BsDropdownModule, BsModalModule} from 'angular-bootstrap4';
import {DynamicFieldsModule} from 'src/modules/dynamic-fields/dynamic-fields.module';
import {DynamicTableFooterComponent} from 'src/modules/dynamic-table/dynamic-table-footer/dynamic-table-footer.component';
import {DynamicTableFiltersComponent} from 'src/modules/dynamic-table/dynamic-table-filters/dynamic-table-filters.component';
import {AppCommonModule} from 'src/modules/app-common/app-common.module';
import {AppFormsModule} from 'src/modules/app-forms/app-forms.module';
import {DynamicTableConfigComponent} from 'src/modules/dynamic-table/dynamic-table-config/dynamic-table-config.component';
import {ExtendedSelectModule} from 'angular-bootstrap4-extended-select';
import {SortableModule} from 'src/modules/sortable/sortable.module';
import {UIRouterModule} from '@uirouter/angular';
import {DatePickerModule} from 'angular-bootstrap4-datepicker';
import {
    DueDateFilterComponent
} from 'src/modules/dynamic-table/custom-filters/due-date-filter/due-date-filter.component';
import {AvatarModule} from 'src/modules/avatar/avatar.module';
import {
    DurationPickerFilterComponent
} from 'src/modules/dynamic-table/custom-filters/duration-picker-filter/duration-picker-filter.component';
import {LegendModule} from 'src/modules/legend/legend.module';

@NgModule({
    imports: [
        ActionsModule,
        AppCommonModule,
        AppDirectivesModule,
        AppFormsModule,
        AppTableToolsModule,
        AvatarModule,
        BsDropdownModule,
        BsModalModule,
        DatePickerModule,
        DynamicFieldsModule,
        ExtendedSelectModule,
        LegendModule,
        OperationsModule,
        SortableModule,
        TranslateModule,
        UIRouterModule
    ],
    declarations: [
        CellTemplatesComponent,
        DueDateFilterComponent,
        DurationPickerFilterComponent,
        DynamicTableCellDirective,
        DynamicTableComponent,
        DynamicTableConfigComponent,
        DynamicTableFiltersComponent,
        DynamicTableFooterComponent,
        DynamicTableViewComponent,
    ],
    exports: [
        DueDateFilterComponent,
        DurationPickerFilterComponent,
        DynamicTableComponent,
        DynamicTableConfigComponent,
        DynamicTableFiltersComponent,
        DynamicTableFooterComponent,
        DynamicTableViewComponent
    ]
})
export class DynamicTableModule {

}
