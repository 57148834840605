import {Component, Inject, Injector, OnDestroy, OnInit} from '@angular/core';
import {AbstractControlValueAccessor} from 'src/modules/app-forms/abstract-control-value-accessors/abstract-control-value-accessor';
import {
    AbstractControl,
    ControlValueAccessor,
    FormControl,
    ValidationErrors,
    Validator, Validators
} from '@angular/forms';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {AbstractValidateElementDirective, VALIDATE_ELEMENT} from 'angular-bootstrap4-validate';
import {TranslateService} from 'src/modules/translate/translate.service';
import {DOCUMENT} from '@angular/common';

@Component({
    selector: 'color-picker',
    templateUrl: './color-picker.component.html',
    providers: AbstractControlValueAccessor.getProviders(ColorPickerComponent)
})
export class ColorPickerComponent
    extends AbstractControlValueAccessor
    implements ControlValueAccessor, Validator, OnInit, OnDestroy {

    inputControl = new FormControl();
    colorControl = new FormControl();

    static COLOR_REGEX = /^#[0-9A-Fa-f]{6}$/;

    private readonly _destroy$ = new Subject<void>();
    private currentValue: unknown;

    constructor(
        private translateService: TranslateService,
        private injector: Injector,
        @Inject(DOCUMENT) private document: Document
    ) {
        super();
    }

    ngOnInit(): void {
        this.inputControl.valueChanges
            .pipe(takeUntil(this._destroy$))
            .subscribe((value) => {
                this.changeValue(value);
                this.colorControl.setValue(value, {emitEvent: false});
            });

        this.colorControl.valueChanges
            .pipe(takeUntil(this._destroy$))
            .subscribe((value) => {
                this.changeValue(value);
                this.inputControl.setValue(value, {emitEvent: false});
            });

        this.injector.get<AbstractValidateElementDirective>(VALIDATE_ELEMENT).errorMessage = {
            pattern: this.translateService.get('VALIDATE_COLOR_ERROR_MESSAGE')
        };
    }

    private changeValue(value: string): void {
        if (this.currentValue !== value) {
            this.currentValue = value;
            this._onChange(value);
        }
    }

    ngOnDestroy(): void {
        this._destroy$.next();
        this._destroy$.complete();
    }

    writeValue(value: unknown): void {
        if (typeof value === 'string' && !value.match(ColorPickerComponent.COLOR_REGEX)) {
            value = this.colorToHex(value);
        }

        this.currentValue = value;
        this.inputControl.setValue(value);
    }

    setDisabledState(isDisabled: boolean): void {
        if (isDisabled) {
            this.inputControl.disable({emitEvent: false});
            this.colorControl.disable({emitEvent: false});
        } else {
            this.inputControl.enable({emitEvent: false});
            this.colorControl.enable({emitEvent: false});
        }
    }

    validate(control: AbstractControl): ValidationErrors | null {
        return Validators.pattern(ColorPickerComponent.COLOR_REGEX)(control);
    }

    private colorToHex(color: string) {
        const element = this.document.createElement('canvas'),
            ctx = element.getContext('2d');
        ctx.fillStyle = color;
        element.remove();
        return ctx.fillStyle;
    }
}
