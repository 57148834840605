import {transition, trigger, useAnimation} from '@angular/animations';
import {fadeInToastsAnimation} from 'src/animations/fade-in-toasts.animation';
import {fadeOutToastsAnimation} from 'src/animations/fade-out-toasts.animation';

export const ToastsAnimations = [
    trigger('showHideToasts', [
        transition(':enter', [
            useAnimation(fadeInToastsAnimation, {
                params: {
                    time: '0.3s linear'
                }
            })
        ]),
        transition(':leave', [
            useAnimation(fadeOutToastsAnimation, {
                params: {
                    time: '0.3s linear'
                }
            })
        ])
    ])
];
