import {AbstractOperationDefinition} from 'src/modules/operations/interfaces';
import {AbstractDeleteOperation} from 'view-modules/operations/abstract/abstract-delete.operation';

export class NumberingPatternAddOperation extends AbstractOperationDefinition<'numbering_patterns'> {
    readonly endpoint = 'numbering_patterns';
    readonly name = 'post';
    lang = 'ADMIN_NUMBERING_PATTERNS_ADD';
    icon = 'fa-plus';
    component = (): Promise<unknown> => import('./numbering-pattern-form/numbering-pattern-form.component');
}

export class NumberingPatternEditOperation extends AbstractOperationDefinition<'numbering_patterns'> {
    readonly endpoint = 'numbering_patterns';
    readonly name = 'put';
    lang = 'ADMIN_NUMBERING_PATTERNS_EDIT';
    icon = 'fa-edit';
    component = (): Promise<unknown> => import('./numbering-pattern-form/numbering-pattern-form.component');
}

export class NumberingPatternDeleteOperation extends AbstractDeleteOperation<'numbering_patterns'> {
    readonly endpoint = 'numbering_patterns';
    lang = 'DELETE';
    confirmMessage = 'ADMIN_NUMBERING_PATTERNS_REMOVE_CONFIRM';
    confirmOptions = {
        langYes: 'ADMIN_NUMBERING_PATTERNS_REMOVE_CONFIRM_LANG_YES'
    };
}

export const numberingPatternOperations = [
    NumberingPatternAddOperation,
    NumberingPatternEditOperation,
    NumberingPatternDeleteOperation
] as const;
