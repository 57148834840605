import {AppErrorComponent} from './app-error/app-error.component';
import {AppStateDeclaration} from 'src/modules/route-helper/interfaces';

export const coreRoutes: AppStateDeclaration[] = [
    {
        name: 'error',
        component: AppErrorComponent,
        params: {
            code: 404,
            error: 'Page not found'
        }
    }
];
