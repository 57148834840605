import {Component, Input} from '@angular/core';

@Component({
    selector: 'absolute-overlay',
    templateUrl: './absolute-overlay.component.html',
    styleUrls: ['./absolute-overlay.component.scss']
})
export class AbsoluteOverlayComponent {
    @Input() showLoader = false;
    @Input() animateBackground = false;
    @Input() lighterBackground = false;
}
