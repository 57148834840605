import {AbstractOperationDefinition} from 'src/modules/operations/interfaces';
import {AbstractDeleteOperation} from 'view-modules/operations/abstract/abstract-delete.operation';
import {IConfirmModalOptions} from 'src/modules/global-components/confirm-modal/confirm-modal.service';

export class CountryAddOperation extends AbstractOperationDefinition<'countries'> {
    readonly endpoint = 'countries';
    readonly name = 'post';
    lang = 'NEW_COUNTRY';
    icon = 'fa-plus';
    component = (): Promise<unknown> => import('./country-form/country-form.component');
}

export class CountryEditOperation extends AbstractOperationDefinition<'countries'> {
    readonly endpoint = 'countries';
    readonly name = 'put';
    lang = 'EDIT_COUNTRY';
    icon = 'fa-edit';
    component = (): Promise<unknown> => import('./country-form/country-form.component');
}

export class CountryDeleteOperation extends AbstractDeleteOperation<'countries'> {
    readonly endpoint = 'countries';
    lang = 'DELETE';
    confirmMessage = 'DELETE_COUNTRY_CONFIRM';
    confirmOptions: IConfirmModalOptions = {
        langYes: 'DELETE_COUNTRY_CONFIRM_LANG_YES',
        text: 'ACTION_CANNOT_BE_UNDONE',
        primaryBtn: 'danger'
    };
}

export const countryOperations = [
    CountryAddOperation,
    CountryEditOperation,
    CountryDeleteOperation
] as const;
