import {Injector} from '@angular/core';
import {Observable} from 'rxjs';
import {TimeTrackerService} from 'src/modules/time-tracker/time-tracker.service';
import {TrackingEntityWithTimestamp} from 'src/modules/time-tracker/interfaces';
import {map} from 'rxjs/operators';
import {
    AbstractTimeTrackerOperation,
    TimeTrackerCommonOperations
} from 'view-modules/operations/time-tracker/abstract-time-tracker.operation';
import {IOperationResult} from 'src/modules/operations/interfaces';

export abstract class AbstractStartEntityTracker<T extends TrackingEntityWithTimestamp>
    extends AbstractTimeTrackerOperation<T> {
    readonly name = 'start_time_tracker';
    icon = 'fa-play';

    lang = 'COMMON_START';

    invoke(
        context: T,
        injector: Injector
    ): Observable<IOperationResult> {
        const contextTrackedEntry = this.getTrackingEntryFromEntityOrPrepareNew(context, injector);

        return TimeTrackerCommonOperations.startTrackerRequest(contextTrackedEntry, injector)
            .pipe(
                // return false prevent refresh table data. refreshing operations by registerTimestampRefresh()
                map(() => {
                    return {success: false};
                })
            );
    }

    access = (context: T, injector: Injector): boolean => {
        const activeEntry = injector.get(TimeTrackerService).activeEntry;

        return this.hasPrivilegesToTimeTrackerFeature(injector)
            && (typeof activeEntry === 'undefined' || activeEntry.iri !== this.getIri(context));
    }
}


export abstract class AbstractPauseEntityTracker<T extends TrackingEntityWithTimestamp>
    extends AbstractTimeTrackerOperation<T> {
    readonly name = 'pause_time_tracker';
    icon = 'fa-pause';

    lang = 'COMMON_PAUSE';

    invoke(
        context: T,
        injector: Injector
    ): Observable<IOperationResult> {
        const timeTrackerService = injector.get(TimeTrackerService),
            contextTrackedEntry = timeTrackerService.getTimeTrackerEntryByIri(this.getIri(context));

        return TimeTrackerCommonOperations.pauseTrackerRequest(contextTrackedEntry, injector)
            .pipe(
                // return false prevent refresh table data. refreshing operations by registerTimestampRefresh()
                map(() => {
                    return {success: false};
                })
            );
    }

    access = (context: T, injector: Injector): boolean => {
        const activeEntry = injector.get(TimeTrackerService).activeEntry;

        return this.hasPrivilegesToTimeTrackerFeature(injector)
            && (typeof activeEntry !== 'undefined' && activeEntry.iri === this.getIri(context));
    }
}

export abstract class AbstractStopEntityTracker<T extends TrackingEntityWithTimestamp>
    extends AbstractTimeTrackerOperation<T> {
    readonly name = 'stop_time_tracker';
    icon = 'fa-stop';
    color = 'danger';

    lang = 'COMMON_STOP';

    invoke(
        context: T,
        injector: Injector
    ): Observable<IOperationResult> {
        const timeTrackerService = injector.get(TimeTrackerService),
            contextTrackedEntry = timeTrackerService.getTimeTrackerEntryByIri(this.getIri(context));

        return TimeTrackerCommonOperations.executeStopOperation(contextTrackedEntry, injector)
            .pipe(
                // return false prevent refresh table data. refreshing operations by registerTimestampRefresh()
                map(() => {
                    return {success: false};
                })
            );
    }

    access = (context: T, injector: Injector): boolean => {
        const timeTrackerService = injector.get(TimeTrackerService),
            contextTrackedEntry = timeTrackerService.getTimeTrackerEntryByIri(this.getIri(context));

        return this.hasPrivilegesToTimeTrackerFeature(injector)
            && typeof contextTrackedEntry !== 'undefined'
            && (contextTrackedEntry.start !== null || contextTrackedEntry.userTimeLoggedTotal !== 0);
    }
}
