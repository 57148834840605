import {NgModule} from '@angular/core';
import {
    // eslint-disable-next-line no-restricted-imports
    TableToolsModule,
} from 'angular-bootstrap4-table-tools';
import {AppTtPerPageComponent} from 'src/modules/app-table-tools/app-tt-per-page/app-tt-per-page.component';
import {AppTtHeaderComponent} from 'src/modules/app-table-tools/app-tt-header/app-tt-header.component';
import {BsModalModule} from 'angular-bootstrap4';
import {AppTtFooterComponent} from 'src/modules/app-table-tools/app-tt-footer/app-tt-footer.component';
import {AppCommonModule} from 'src/modules/app-common/app-common.module';
import {AppFormsModule} from 'src/modules/app-forms/app-forms.module';
import {RemoteTableToolsDirective} from 'src/modules/app-table-tools/remote-table-tools/remote-table-tools.directive';
import {
    RemoteTableToolsHostDirective
} from 'src/modules/app-table-tools/remote-table-tools/remote-table-tools-host.directive';


@NgModule({
    imports: [
        AppCommonModule,
        AppFormsModule,
        BsModalModule,
        TableToolsModule
    ],
    declarations: [
        AppTtFooterComponent,
        AppTtHeaderComponent,
        AppTtPerPageComponent,
        RemoteTableToolsDirective,
        RemoteTableToolsHostDirective,
    ],
    exports: [
        AppTtFooterComponent,
        AppTtHeaderComponent,
        AppTtPerPageComponent,
        RemoteTableToolsDirective,
        RemoteTableToolsHostDirective,
        TableToolsModule
    ]
})
export class AppTableToolsModule {
}
