import {IBaseRestEntity} from '_types/rest/IBaseRestEntity';
import {BroadcastService, IBroadcastChannel} from 'src/services/broadcast.service';
import {IContractItem, IService, ITimeTrackerLog} from '_types/rest';
import {InjectionToken} from '@angular/core';

export const TIME_TRACKER_CHANNEL = new InjectionToken<BroadcastService<ITimeTrackerBroadcast>>
('timeTrackerBroadcastService', {
    providedIn: 'root',
    factory: () => {
        return new BroadcastService('time-tracker');
    },
});

export const TimeTrackerBroadcastServiceProvider = {
    provide: BroadcastService,
    useFactory: (): BroadcastService<ITimeTrackerBroadcast> => {
        return new BroadcastService('time-tracker');
    }
};

export interface ITrackedEntry {
    id: number,
    iri?: string,
    name?: string,
    start?: string,
    services?: IService[] | null,
    userTimeLoggedTotal?: number,
    working: boolean,
    loadingTime?: boolean
}

export type TimeTrackerObject<T> = T & { _timeTrackerRefreshTimestamp?: number };

export type TrackingEntity = IBaseRestEntity & { name?: string, id: number, contractItem?: string | IContractItem };

export type TrackingEntityWithTimestamp = TimeTrackerObject<TrackingEntity>;

export type ITrackedEntryWithTimestamp = TimeTrackerObject<ITrackedEntry>

export type TrackerEntryType = ITrackedEntryWithTimestamp | TrackingEntityWithTimestamp;

export interface ITimeTrackerSave extends IBroadcastChannel {
    type: 'time-tracker',
    types: 'start' | 'stop' | 'pause',
    payload: ITrackedEntry
}

export interface ITimeTrackerDeleteTask extends IBroadcastChannel {
    type: 'time-tracker',
    types: 'delete-task',
    payload: ITimeTrackerDeletedTask
}

export interface ITimeTrackerDeletedTask extends ITimeTrackerLog {
    iri: string
}

export type ITimeTrackerBroadcast = ITimeTrackerSave | ITimeTrackerDeleteTask
