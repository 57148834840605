import {
    Component,
    ElementRef,
    Input,
    OnDestroy,
    OnInit,
    ViewChild
} from '@angular/core';
import {
    IDurationPickerDynamicFieldConfig,
    IDynamicFieldControlValueAccessor
} from 'src/modules/dynamic-fields/interfaces';
import {
    AbstractUnvalidatedControlValueAccessor
} from 'src/modules/app-forms/abstract-control-value-accessors/abstract-unvalidated-control-value-accessor';
import {DateUtils} from 'src/services/date-utils';
import {FormControl, FormGroup, ValidationErrors} from '@angular/forms';
import {Subject} from 'rxjs';


interface IDurationPickerTime {
    hours: number;
    minutes: number;
}

@Component({
    selector: 'duration-picker',
    templateUrl: './duration-picker.component.html',
    styleUrls: ['./duration-picker.component.scss'],
    providers: AbstractUnvalidatedControlValueAccessor.getProviders(DurationPickerComponent)
})
export class DurationPickerComponent extends AbstractUnvalidatedControlValueAccessor
    implements OnInit, OnDestroy, IDynamicFieldControlValueAccessor {
    @Input() fieldConfig: IDurationPickerDynamicFieldConfig;

    @ViewChild('timeInput') timeInput: ElementRef<HTMLInputElement>;
    @ViewChild('dropdownMenu') dropdownMenu: ElementRef<HTMLDivElement>;

    isDropdownOpened = false;
    isDisabled = false;
    displayedTimeValue = '';
    durationPickerForm: FormGroup;

    readonly dateUtils = DateUtils;

    protected isGroupCVA = true;
    private rawValue: number;
    private readonly _destroy$ = new Subject<void>();

    ngOnInit(): void {
        this.createForm();
    }

    private createForm(): void {
        this.durationPickerForm = new FormGroup({
            hours: new FormControl(0),
            minutes: new FormControl(0)
        }, this.hoursAndMinutesValidator.bind(this));
    }

    private hoursAndMinutesValidator(formGroup: FormGroup): ValidationErrors | null {
        if (!this.isDropdownOpened) {
            return null;
        }

        const hoursControl = formGroup.get('hours'),
            minutesControl = formGroup.get('minutes');

        if (
            !hoursControl.touched
            && !minutesControl.touched
        ) {
            return null;
        }

        if (
            (hoursControl.value ?? 0) <= 0
            && (minutesControl.value ?? 0) <= 0
        ) {
            return {
                atLeastOneShouldBeNotEmpty: true
            };
        }

        return null;
    }

    writeValue(value: number | string | null): void {
        this.setRawValue(value);
        this.updateDisplayedTime();
        this.durationPickerForm.patchValue(this.convertFromRawTime(this.rawValue));
    }

    private setRawValue(value: number | string | null): void {
        if (typeof value === 'number') {
            this.rawValue = value;
            return;
        }

        if (typeof value === 'string' && !isNaN(Number(value))) {
            this.rawValue = Number(value);
            return;
        }

        this.rawValue = null;
    }

    private updateDisplayedTime(): void {
        if (typeof this.rawValue !== 'number') {
            this.displayedTimeValue = '';
            return;
        }

        this.displayedTimeValue = DateUtils.getTimeStringFromSeconds(this.rawValue, false, 'human');
    }

    private convertFromRawTime(value: number): IDurationPickerTime {
        const {hours, minutes} = DateUtils.getTimeValuesFromSeconds(value ?? 0);
        return {
            hours: Number(hours),
            minutes: Number(minutes)
        };
    }

    updateForm(displayedTime: string): void {
        const time = this.getTimeFromInput(displayedTime);
        this.durationPickerForm.patchValue(time);
    }

    private getTimeFromInput(value: string): IDurationPickerTime {
        const timeInSeconds = this.convertToRawTime(value);
        return this.convertFromRawTime(timeInSeconds);
    }

    private convertToRawTime(value: string | IDurationPickerTime): number {
        const seconds = DateUtils.getSeconds(value);
        return seconds ? seconds : null;
    }

    handleDropdownChange(open: boolean): void {
        if (!open) {
            return;
        }

        this.updateForm(this.displayedTimeValue);
    }

    handleBlur(event: FocusEvent): void {
        const isDropdownClicked = this.dropdownMenu.nativeElement.contains(
            event.relatedTarget as HTMLElement
        );

        if (!isDropdownClicked) {
            this.isDropdownOpened = false;
            this.markAsTouched();
        }
    }

    submitTimePicked(closePicker = true): void {
        const lastRawValue = this.rawValue || null;
        this.rawValue = this.convertToRawTime(this.durationPickerForm.value);
        if (this.rawValue !== lastRawValue) {
            this._onChange(this.rawValue);
        }

        this.updateDisplayedTime();

        if (closePicker) {
            this.isDropdownOpened = false;
            this.markAsTouched();
        }
    }

    setDisabledState(isDisabled: boolean): void {
        this.isDisabled = isDisabled;
    }

    ngOnDestroy(): void {
        this._destroy$.next();
        this._destroy$.complete();
    }
}
