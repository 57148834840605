/* eslint-disable max-len */
/**
 * This file has been auto generated. Do not edit.
 */
import {IContractItem} from '_types/rest/Entity/IRestContractItem';
import {IRestContractItem} from '_types/rest/Entity/IRestContractItem';
import {ISearchConsoleReport} from '_types/rest/Entity/IRestSearchConsoleReport';
import {IRestSearchConsoleReport} from '_types/rest/Entity/IRestSearchConsoleReport';
import {ISearchConsoleTemplate} from '_types/rest/Entity/IRestSearchConsoleTemplate';
import {IRestSearchConsoleTemplate} from '_types/rest/Entity/IRestSearchConsoleTemplate';
import {IOAuth2Grant} from '_types/rest/Entity/IRestOAuth2Grant';
import {IRestOAuth2Grant} from '_types/rest/Entity/IRestOAuth2Grant';
import {IBaseRestEntity} from '_types/rest/IBaseRestEntity';
import {IRestObject} from 'core/rest/rest-object.service';

export const SearchConsoleServiceSchema = {
    SERVICE_SCHEMA_HTTP: 1,
    SERVICE_SCHEMA_HTTP_WWW: 2,
    SERVICE_SCHEMA_HTTPS: 3,
    SERVICE_SCHEMA_HTTPS_WWW: 4
} as const;

export const SearchConsoleServiceSchemaOptions = [
    {
        value: SearchConsoleServiceSchema.SERVICE_SCHEMA_HTTP,
        label: 'SERVICE_SCHEMA_HTTP',
    },
    {
        value: SearchConsoleServiceSchema.SERVICE_SCHEMA_HTTP_WWW,
        label: 'SERVICE_SCHEMA_HTTP_WWW',
    },
    {
        value: SearchConsoleServiceSchema.SERVICE_SCHEMA_HTTPS,
        label: 'SERVICE_SCHEMA_HTTPS',
    },
    {
        value: SearchConsoleServiceSchema.SERVICE_SCHEMA_HTTPS_WWW,
        label: 'SERVICE_SCHEMA_HTTPS_WWW',
    }
] as const;

export const SearchConsoleServiceState = {
    SERVICE_STATE_UNDEFINED: 0,
    SERVICE_STATE_OK: 1,
    SERVICE_STATE_NO_GRANT: 2,
    SERVICE_STATE_NO_DOMAIN: 3,
    SERVICE_STATE_INVALID_GRANT: 4
} as const;

export const SearchConsoleServiceStateOptions = [
    {
        value: SearchConsoleServiceState.SERVICE_STATE_UNDEFINED,
        label: 'SERVICE_STATE_UNDEFINED',
    },
    {
        value: SearchConsoleServiceState.SERVICE_STATE_OK,
        label: 'SERVICE_STATE_OK',
    },
    {
        value: SearchConsoleServiceState.SERVICE_STATE_NO_GRANT,
        label: 'SERVICE_STATE_NO_GRANT',
    },
    {
        value: SearchConsoleServiceState.SERVICE_STATE_NO_DOMAIN,
        label: 'SERVICE_STATE_NO_DOMAIN',
    },
    {
        value: SearchConsoleServiceState.SERVICE_STATE_INVALID_GRANT,
        label: 'SERVICE_STATE_INVALID_GRANT',
    }
] as const;

export interface ISearchConsoleServiceConst {
    schema: [{id: 1, value: 'SERVICE_SCHEMA_HTTP'}, {id: 2, value: 'SERVICE_SCHEMA_HTTP_WWW'}, {id: 3, value: 'SERVICE_SCHEMA_HTTPS'}, {id: 4, value: 'SERVICE_SCHEMA_HTTPS_WWW'}],
    state: [{id: 0, value: 'SERVICE_STATE_UNDEFINED'}, {id: 1, value: 'SERVICE_STATE_OK'}, {id: 2, value: 'SERVICE_STATE_NO_GRANT'}, {id: 3, value: 'SERVICE_STATE_NO_DOMAIN'}, {id: 4, value: 'SERVICE_STATE_INVALID_GRANT'}]
}

export interface ISearchConsoleService extends IBaseRestEntity {
    id?: number,
    contractItem?: IContractItem | string | null,
    searchConsoleReports?: (ISearchConsoleReport | string)[],
    /**
     * @default true
     */
    active?: number,
    searchConsoleTemplate?: ISearchConsoleTemplate | string | null,
    oauth2Grant?: IOAuth2Grant | string | null,
    /**
     * @default 1
     */
    schema?: typeof SearchConsoleServiceSchema[keyof typeof SearchConsoleServiceSchema],
    googleAccount?: string | null,
    state?: typeof SearchConsoleServiceState[keyof typeof SearchConsoleServiceState],
    /**
     * @description Record status
     *  0 = deleted
     *  1 = active.
     * @default 1
     */
    status?: number,
    /**
     * @description Creation timestamp.
     * @default CURRENT_TIMESTAMP
     * @format date-time
     */
    created?: string,
    /**
     * @description Last modification timestamp.
     * @format date-time
     */
    modified?: string | null
}

/** @deprecated */
export interface IRestSearchConsoleService extends ISearchConsoleService, IRestObject {
    contractItem?: IRestContractItem | string | null,
    searchConsoleReports?: IRestSearchConsoleReport[] | string[],
    searchConsoleTemplate?: IRestSearchConsoleTemplate | string | null,
    oauth2Grant?: IRestOAuth2Grant | string | null,
}
