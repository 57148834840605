import {Injectable} from '@angular/core';
import {TaskTableInstanceType} from 'src/modules/tasks/tasks-table/tasks-table.interfaces';
import {IQueryObject} from 'src/services/url-parser.service';

@Injectable({
    providedIn: 'root'
})
export class TasksTableService {
    tableInstances: TaskTableInstanceType[] = [];

    addTableInstance(instance: TaskTableInstanceType): void {
        this.tableInstances.push(instance);
    }

    removeTableInstance(instance: TaskTableInstanceType): void {
        this.tableInstances = this.tableInstances.filter((item) => {
            return item !== instance;
        });
    }

    reloadLists(): void {
        this.tableInstances.forEach((instance) => {
            instance.filterData();
        });
    }

    changeDateFilterQuery(query: IQueryObject, includeOnDueDate = false): void {
        const dateFilterQuery = query['taskDate'];
        delete query['taskDate'];

        if (
            !dateFilterQuery
            || typeof dateFilterQuery !== 'object'
        ) {
            return;
        }

        query.startDate = {};
        query.dueDate = {};

        if ('after' in dateFilterQuery) {
            query.dueDate.after = dateFilterQuery.after;
        }
        if ('before' in dateFilterQuery) {
            if (includeOnDueDate) {
                query.dueDate.before = dateFilterQuery.before;
            } else {
                query.startDate.before = dateFilterQuery.before;
            }
        }
    }
}
