import {AbstractOperationDefinition} from 'src/modules/operations/interfaces';
import {AbstractDeleteOperation} from 'view-modules/operations/abstract/abstract-delete.operation';
import {IConfirmModalOptions} from 'src/modules/global-components/confirm-modal/confirm-modal.service';

export class CurrencyAddOperation extends AbstractOperationDefinition<'currencies'> {
    readonly endpoint = 'currencies';
    readonly name = 'post';
    lang = 'NEW_CURRENCY';
    icon = 'fa-plus';
    component = (): Promise<unknown> => import('./currency-form/currency-form.component');
}

export class CurrencyEditOperation extends AbstractOperationDefinition<'currencies'> {
    readonly endpoint = 'currencies';
    readonly name = 'put';
    lang = 'EDIT_CURRENCY';
    icon = 'fa-edit';
    component = (): Promise<unknown> => import('./currency-form/currency-form.component');
}

export class CurrencyDeleteOperation extends AbstractDeleteOperation<'currencies'> {
    readonly endpoint = 'currencies';
    lang = 'DELETE';
    confirmMessage = 'DELETE_CURRENCY_CONFIRM';
    confirmOptions: IConfirmModalOptions = {
        langYes: 'DELETE_CURRENCY_CONFIRM_LANG_YES',
        text: 'ACTION_CANNOT_BE_UNDONE',
        primaryBtn: 'danger'
    };
}

export const currencyOperations = [
    CurrencyAddOperation,
    CurrencyEditOperation,
    CurrencyDeleteOperation
] as const;
