/* eslint-disable max-len */
/**
 * This file has been auto generated. Do not edit.
 */
import {ITask} from '_types/rest/Entity/IRestTask';
import {IRestTask} from '_types/rest/Entity/IRestTask';
import {ITaskAssignmentDepartment} from '_types/rest/Entity/IRestTaskAssignmentDepartment';
import {IRestTaskAssignmentDepartment} from '_types/rest/Entity/IRestTaskAssignmentDepartment';
import {ITaskAssignmentDepartmentRole} from '_types/rest/Entity/IRestTaskAssignmentDepartmentRole';
import {IRestTaskAssignmentDepartmentRole} from '_types/rest/Entity/IRestTaskAssignmentDepartmentRole';
import {ITaskAssignmentUserLogin} from '_types/rest/Entity/IRestTaskAssignmentUserLogin';
import {IRestTaskAssignmentUserLogin} from '_types/rest/Entity/IRestTaskAssignmentUserLogin';
import {ITaskAssignmentGuardianType} from '_types/rest/Entity/IRestTaskAssignmentGuardianType';
import {IRestTaskAssignmentGuardianType} from '_types/rest/Entity/IRestTaskAssignmentGuardianType';
import {ITaskAcceptanceCriterion} from '_types/rest/Entity/IRestTaskAcceptanceCriterion';
import {IRestTaskAcceptanceCriterion} from '_types/rest/Entity/IRestTaskAcceptanceCriterion';
import {IBaseRestEntity} from '_types/rest/IBaseRestEntity';
import {IRestObject} from 'core/rest/rest-object.service';

export const TaskAssignmentRole = {
    TASK_ASSIGNMENT_ROLE_ASSIGNEE: 1,
    TASK_ASSIGNMENT_ROLE_AUTHOR: 2
} as const;

export const TaskAssignmentRoleOptions = [
    {
        value: TaskAssignmentRole.TASK_ASSIGNMENT_ROLE_ASSIGNEE,
        label: 'TASK_ASSIGNMENT_ROLE_ASSIGNEE',
    },
    {
        value: TaskAssignmentRole.TASK_ASSIGNMENT_ROLE_AUTHOR,
        label: 'TASK_ASSIGNMENT_ROLE_AUTHOR',
    }
] as const;

export const TaskAssignmentMethod = {
    TASK_ASSIGNMENT_METHOD_RANDOM: 1,
    TASK_ASSIGNMENT_METHOD_CONTEXT: 2,
    TASK_ASSIGNMENT_METHOD_SELECTION: 3
} as const;

export const TaskAssignmentMethodOptions = [
    {
        value: TaskAssignmentMethod.TASK_ASSIGNMENT_METHOD_RANDOM,
        label: 'TASK_ASSIGNMENT_METHOD_RANDOM',
    },
    {
        value: TaskAssignmentMethod.TASK_ASSIGNMENT_METHOD_CONTEXT,
        label: 'TASK_ASSIGNMENT_METHOD_CONTEXT',
    },
    {
        value: TaskAssignmentMethod.TASK_ASSIGNMENT_METHOD_SELECTION,
        label: 'TASK_ASSIGNMENT_METHOD_SELECTION',
    }
] as const;

export const TaskAssignmentType = {
    TASK_ASSIGNMENT_TYPE_RANDOM_BIGGEST_CAPACITY: 10001,
    TASK_ASSIGNMENT_TYPE_RANDOM_FULL: 10002,
    TASK_ASSIGNMENT_TYPE_CONTEXT_GUARDIAN_TYPE: 20001,
    TASK_ASSIGNMENT_TYPE_SELECTION_USER: 30001,
    TASK_ASSIGNMENT_TYPE_SELECTION_DEPARTMENT: 30002,
    TASK_ASSIGNMENT_TYPE_SELECTION_DEPARTMENT_ROLE: 30003
} as const;

export const TaskAssignmentTypeOptions = [
    {
        value: TaskAssignmentType.TASK_ASSIGNMENT_TYPE_RANDOM_BIGGEST_CAPACITY,
        label: 'TASK_ASSIGNMENT_TYPE_RANDOM_BIGGEST_CAPACITY',
    },
    {
        value: TaskAssignmentType.TASK_ASSIGNMENT_TYPE_RANDOM_FULL,
        label: 'TASK_ASSIGNMENT_TYPE_RANDOM_FULL',
    },
    {
        value: TaskAssignmentType.TASK_ASSIGNMENT_TYPE_CONTEXT_GUARDIAN_TYPE,
        label: 'TASK_ASSIGNMENT_TYPE_CONTEXT_GUARDIAN_TYPE',
    },
    {
        value: TaskAssignmentType.TASK_ASSIGNMENT_TYPE_SELECTION_USER,
        label: 'TASK_ASSIGNMENT_TYPE_SELECTION_USER',
    },
    {
        value: TaskAssignmentType.TASK_ASSIGNMENT_TYPE_SELECTION_DEPARTMENT,
        label: 'TASK_ASSIGNMENT_TYPE_SELECTION_DEPARTMENT',
    },
    {
        value: TaskAssignmentType.TASK_ASSIGNMENT_TYPE_SELECTION_DEPARTMENT_ROLE,
        label: 'TASK_ASSIGNMENT_TYPE_SELECTION_DEPARTMENT_ROLE',
    }
] as const;

export interface ITaskAssignmentConst {
    role: [{id: 1, value: 'TASK_ASSIGNMENT_ROLE_ASSIGNEE'}, {id: 2, value: 'TASK_ASSIGNMENT_ROLE_AUTHOR'}],
    method: [{id: 1, value: 'TASK_ASSIGNMENT_METHOD_RANDOM'}, {id: 2, value: 'TASK_ASSIGNMENT_METHOD_CONTEXT'}, {id: 3, value: 'TASK_ASSIGNMENT_METHOD_SELECTION'}],
    type: [{id: 10001, value: 'TASK_ASSIGNMENT_TYPE_RANDOM_BIGGEST_CAPACITY'}, {id: 10002, value: 'TASK_ASSIGNMENT_TYPE_RANDOM_FULL'}, {id: 20001, value: 'TASK_ASSIGNMENT_TYPE_CONTEXT_GUARDIAN_TYPE'}, {id: 30001, value: 'TASK_ASSIGNMENT_TYPE_SELECTION_USER'}, {id: 30002, value: 'TASK_ASSIGNMENT_TYPE_SELECTION_DEPARTMENT'}, {id: 30003, value: 'TASK_ASSIGNMENT_TYPE_SELECTION_DEPARTMENT_ROLE'}]
}

export interface ITaskAssignment extends IBaseRestEntity {
    id?: number,
    /**
     * @default 1
     */
    role: typeof TaskAssignmentRole[keyof typeof TaskAssignmentRole],
    /**
     * @default 1
     */
    method: typeof TaskAssignmentMethod[keyof typeof TaskAssignmentMethod],
    /**
     * @default 1
     */
    type: typeof TaskAssignmentType[keyof typeof TaskAssignmentType],
    task: ITask | string,
    taskAssignmentDepartments?: (ITaskAssignmentDepartment | string)[],
    taskAssignmentDepartmentRoles?: (ITaskAssignmentDepartmentRole | string)[],
    taskAssignmentUserLogins?: (ITaskAssignmentUserLogin | string)[],
    taskAssignmentGuardianTypes?: (ITaskAssignmentGuardianType | string)[],
    taskAcceptanceCriteria?: (ITaskAcceptanceCriterion | string)[],
    /**
     * @description Record status
     *  0 = deleted
     *  1 = active.
     * @default 1
     */
    status?: number,
    /**
     * @description Creation timestamp.
     * @default CURRENT_TIMESTAMP
     * @format date-time
     */
    created?: string,
    /**
     * @description Last modification timestamp.
     * @format date-time
     */
    modified?: string | null
}

/** @deprecated */
export interface IRestTaskAssignment extends ITaskAssignment, IRestObject {
    task: IRestTask | string,
    taskAssignmentDepartments?: IRestTaskAssignmentDepartment[] | string[],
    taskAssignmentDepartmentRoles?: IRestTaskAssignmentDepartmentRole[] | string[],
    taskAssignmentUserLogins?: IRestTaskAssignmentUserLogin[] | string[],
    taskAssignmentGuardianTypes?: IRestTaskAssignmentGuardianType[] | string[],
    taskAcceptanceCriteria?: IRestTaskAcceptanceCriterion[] | string[],
}

/** @deprecated */
export const IRestTaskAssignmentConstRole = TaskAssignmentRole;
