/* eslint-disable max-len */
/**
 * This file has been auto generated. Do not edit.
 */
import {IMail} from '_types/rest/Entity/IRestMail';
import {IRestMail} from '_types/rest/Entity/IRestMail';
import {IUserLogin} from '_types/rest/Entity/IRestUserLogin';
import {IRestUserLogin} from '_types/rest/Entity/IRestUserLogin';
import {IBaseRestEntity} from '_types/rest/IBaseRestEntity';
import {IRestObject} from 'core/rest/rest-object.service';

export const MailAddressType = {
    MAIL_ADDRESS_TYPE_FROM: 0,
    MAIL_ADDRESS_TYPE_TO: 1,
    MAIL_ADDRESS_TYPE_CC: 2,
    MAIL_ADDRESS_TYPE_BCC: 3
} as const;

export const MailAddressTypeOptions = [
    {
        value: MailAddressType.MAIL_ADDRESS_TYPE_FROM,
        label: 'MAIL_ADDRESS_TYPE_FROM',
    },
    {
        value: MailAddressType.MAIL_ADDRESS_TYPE_TO,
        label: 'MAIL_ADDRESS_TYPE_TO',
    },
    {
        value: MailAddressType.MAIL_ADDRESS_TYPE_CC,
        label: 'MAIL_ADDRESS_TYPE_CC',
    },
    {
        value: MailAddressType.MAIL_ADDRESS_TYPE_BCC,
        label: 'MAIL_ADDRESS_TYPE_BCC',
    }
] as const;

export const MailAddressState = {
    MAIL_ADDRESS_STATE_SENDING: 0,
    MAIL_ADDRESS_STATE_SENT: 1,
    MAIL_ADDRESS_STATE_FAILED: 2
} as const;

export const MailAddressStateOptions = [
    {
        value: MailAddressState.MAIL_ADDRESS_STATE_SENDING,
        label: 'MAIL_ADDRESS_STATE_SENDING',
    },
    {
        value: MailAddressState.MAIL_ADDRESS_STATE_SENT,
        label: 'MAIL_ADDRESS_STATE_SENT',
    },
    {
        value: MailAddressState.MAIL_ADDRESS_STATE_FAILED,
        label: 'MAIL_ADDRESS_STATE_FAILED',
    }
] as const;

export interface IMailAddressConst {
    type: [{id: 0, value: 'MAIL_ADDRESS_TYPE_FROM'}, {id: 1, value: 'MAIL_ADDRESS_TYPE_TO'}, {id: 2, value: 'MAIL_ADDRESS_TYPE_CC'}, {id: 3, value: 'MAIL_ADDRESS_TYPE_BCC'}],
    state: [{id: 0, value: 'MAIL_ADDRESS_STATE_SENDING'}, {id: 1, value: 'MAIL_ADDRESS_STATE_SENT'}, {id: 2, value: 'MAIL_ADDRESS_STATE_FAILED'}]
}

export interface IMailAddress extends IBaseRestEntity {
    id?: number,
    /**
     * @maxLength 128
     */
    email: string,
    type: typeof MailAddressType[keyof typeof MailAddressType],
    state: typeof MailAddressState[keyof typeof MailAddressState],
    mail: IMail | string | null,
    userLogin?: IUserLogin | string | null,
    /**
     * @description Record status
     *  0 = deleted
     *  1 = active.
     * @default 1
     */
    status?: number,
    /**
     * @description Creation timestamp.
     * @default CURRENT_TIMESTAMP
     * @format date-time
     */
    created?: string,
    /**
     * @description Last modification timestamp.
     * @format date-time
     */
    modified?: string | null
}

/** @deprecated */
export interface IRestMailAddress extends IMailAddress, IRestObject {
    mail: IRestMail | string | null,
    userLogin?: IRestUserLogin | string | null,
}
