import {IConfirmModalOptions} from 'src/modules/global-components/confirm-modal/confirm-modal.service';
import {AbstractOperationDefinition} from 'src/modules/operations/interfaces';
import {IRestCollection, IRestObject} from 'src/modules/rest/objects';
import {ICatalogWith} from 'view-modules/private/partners/partner-view/partner-catalogs/partner-catalogs.component';
import {AbstractDeleteOperation} from 'view-modules/operations/abstract/abstract-delete.operation';
import {IPartner, With} from '_types/rest';
import {RestClient} from 'src/modules/rest/rest-client.service';

export interface IPartnerCatalogAddContext {
    catalogs: IRestCollection<'catalogs', ICatalogWith>;
    partner: With<IPartner, 'company'>;
}

export interface IPartnerCatalogEditContext {
    catalog: IRestObject<'catalogs', ICatalogWith>;
    partner: With<IPartner, 'company'>;
}

export class PartnerCatalogsAddOperation extends AbstractOperationDefinition<string, IPartnerCatalogAddContext> {
    readonly endpoint = 'catalogs';
    readonly name = 'post';
    lang = 'CATALOG_ADD';
    icon = 'fa-plus';

    component = (): Promise<unknown> => import('./partner-catalog-form/partner-catalog-form.component');

    access(context: IPartnerCatalogAddContext): boolean {
        if (RestClient.isRestCollection(context.catalogs)) {
            return context.catalogs.access(this.name);
        }

        return false;
    }
}

export class PartnerCatalogsEditOperation extends AbstractOperationDefinition<string, IPartnerCatalogEditContext> {
    readonly endpoint = 'catalogs';
    readonly name = 'put';
    lang = 'EDIT';
    icon = 'fa-edit';

    component = (): Promise<unknown> => import('./partner-catalog-form/partner-catalog-form.component');

    access(context: IPartnerCatalogEditContext): boolean {
        if (RestClient.isRestObject(context.catalog)) {
            return context.catalog.access(this.name);
        }

        return false;
    }
}

export class PartnerCatalogsDeleteOperation extends AbstractDeleteOperation<'catalogs'> {
    readonly endpoint = 'catalogs';

    confirmMessage = 'DELETE_CATALOG_CONFIRM';
    confirmOptions: IConfirmModalOptions = {
        primaryBtn: 'danger',
        langYes: 'DELETE_CATALOG_CONFIRM_LANG_YES',
        text: 'ACTION_CANNOT_BE_UNDONE'
    };
}

export const partnerCatalogsOperations = [
    PartnerCatalogsDeleteOperation,
    PartnerCatalogsAddOperation,
    PartnerCatalogsEditOperation
] as const;
