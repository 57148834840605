import {animate, animation, style} from '@angular/animations';


export const fadeOutDownAnimation = animation([
    style({ opacity: 1 }),
    animate(
        '{{ time }}',
        style({ opacity: 0 , transform: 'translate3d(0, 100%, 0)'})
    )
]);
