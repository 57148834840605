import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {StateService} from '@uirouter/core';
import {IObjectSourceInfo} from '_types/custom/IObjectSourceInfo';

@Component({
    selector: 'task-source-note',
    templateUrl: './task-source-note.component.html',
    styleUrls: ['./task-source-note.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class TaskSourceNoteComponent {
    @Input() objectSource: IObjectSourceInfo;
    @Input() size?: 'lg';

    constructor(
        public readonly stateService: StateService,
    ) {}
}
