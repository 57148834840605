import {Component, ElementRef, Input, OnInit} from '@angular/core';
import {AjsInjector, AngularHybridService, HybridAppStateDeclaration} from 'src/modules/hybrid/angular-hybrid.service';
import {StateDeclaration, StateService, Transition, UrlService} from '@uirouter/core';
// eslint-disable-next-line no-restricted-imports
import {AppStateDeclaration as AjsStateDeclaration, RouteHelperService} from 'core/route-helper/route-helper.provider';
import {UIRouterGlobals} from '@uirouter/angular';
import {RouteHelper} from 'src/modules/route-helper/route-helper.service';
import {Utils} from 'src/services/utils';
import {RouteTreeBranch} from 'src/modules/route-helper/interfaces';

@Component({
    selector: 'ajs-ui-view',
    template: ''
})
export class AjsUiViewComponent implements OnInit {
    @Input() stateDeclaration: HybridAppStateDeclaration;
    @Input() moduleName: string;
    @Input() isParentAjsState: boolean;

    constructor(
        private elRef: ElementRef<HTMLElement>,
        private angularHybridService: AngularHybridService,
        private routeHelper: RouteHelper
    ) {
    }

    ngOnInit(): void {
        if (this.isParentAjsState) { // ignore nested AjsUiViews
            return;
        }

        this.angularHybridService.lazyLoadAjsModule(this.elRef.nativeElement, this.moduleName)
            .then(({ajsInjector, isReload}) => {
                if (!isReload) {
                    this.addAjsRouterStates(ajsInjector);
                } else {
                    const ajsRouterGlobals = ajsInjector.get<UIRouterGlobals>('$uiRouterGlobals'),
                        ajsStateService = ajsInjector.get<StateService>('$state');
                    // reload ajs router state when re-entering its AjsUiView
                    if (ajsRouterGlobals.current.name === this.stateDeclaration.name) {
                        ajsStateService.reload().catch();
                    }
                }
            });
    }

    private addAjsRouterStates(ajsInjector: AjsInjector): void {
        const ajsRouteHelper = ajsInjector.get<RouteHelperService>('routeHelper'),
            ajsDefinedStates = ajsInjector.get<StateService>('$state').get().map((state) => state.name),
            ajsStates: AjsStateDeclaration[] = [];

        Utils.walkTree<RouteTreeBranch, 'subs'>(
            this.routeHelper.getStatesTree(),
            (state) => {
                if (ajsDefinedStates.includes(state.name) || state.name.endsWith('.**')) {
                    return;
                }
                const mimicState = AngularHybridService.stateDeclarations[state.name];
                mimicState.childs = [];
                mimicState.name = state.name;
                if (
                    AngularHybridService.isAjsState(state.name)
                    && !AngularHybridService.isParentAjsState(state.name)
                    && typeof mimicState.views === 'undefined'
                ) {
                    mimicState.views = {
                        '@': {
                            component: mimicState.component
                        }
                    };
                    delete mimicState.component;
                }

                ajsStates.push(this.convertToAjsState(mimicState));
            },
            'subs'
        );
        ajsRouteHelper.configureStates(ajsStates);

        const $urlService = ajsInjector.get<UrlService>('$urlService');
        $urlService.listen();
        $urlService.sync();
    }

    private convertToAjsState(state: HybridAppStateDeclaration | StateDeclaration): AjsStateDeclaration {
        return Object.assign(
            {},
            state,
            {
                resolve: Object.entries(state.resolve || {})
                    .reduce((map, [token, resolve]) => {
                        map[token] = 'resolveFn' in resolve ? resolve.resolveFn : resolve;
                        if (typeof map[token]['$inject'] === 'undefined') {
                            map[token]['$inject'] = 'deps' in resolve ? resolve.deps : [Transition];
                        }
                        return map;
                    }, {})
            }
        ) as AjsStateDeclaration;
    }
}
