import {AppResolvable, resolveFunc, resolveValue} from 'src/modules/route-helper/interfaces';
import {Transition} from '@uirouter/core';
import 'reflect-metadata';

export function RouteResolve<U extends AppResolvable<Transition>>(resolveFn: U): <T extends object, K extends keyof T>(
    target: T,
    propertyKey: T[K] extends resolveValue<ReturnType<resolveFunc<U>>> ? K : never
) => void {
    return (target, propertyKey) => {
        const metadata = Reflect.getMetadata('$routeResolve', target.constructor) || {};

        if (typeof metadata[propertyKey] !== 'undefined') {
            throw new Error(`Duplicated @RouteResolve decorator on '${target.constructor.name}.${propertyKey}'!`);
        }

        metadata[propertyKey] = resolveFn;
        Reflect.defineMetadata('$routeResolve', metadata, target.constructor);
    };
}
