import {Directive, ElementRef, Input, OnInit} from '@angular/core';
import {DomUtils} from 'src/services/dom-utils';

@Directive({
    selector: '[focus]'
})
export class FocusDirective implements OnInit {
    @Input() focus: '' | boolean;
    constructor(
        private el: ElementRef,
        private domUtils: DomUtils
    ) { }

    ngOnInit(): void {
        if (
            this.el.nativeElement.tagName !== 'DYNAMIC-FIELD'
            && (this.focus || this.focus === '')
        ) {
            if (!('focus-no-scroll' in this.el.nativeElement.attributes)) {
                this.domUtils.scrollToElement(this.el.nativeElement);
            }
            this.el.nativeElement.focus({
                preventScroll: true
            });
        }
    }
}
