import {NgModule} from '@angular/core';
import {AppDirectivesModule} from 'src/directives/app-directives.module';
import {SwitchOperationComponent} from 'src/modules/operations/switch-operation/switch-operation.component';
import {TranslateModule} from 'src/modules/translate/translate.module';
import {MassOperationsComponent} from 'src/modules/operations/mass-operations/mass-operations.component';
import {ActionsModule} from 'src/modules/actions/actions.module';
import {AppCommonModule} from 'src/modules/app-common/app-common.module';
import {OperationsBarComponent} from 'src/modules/operations/operations-bar/operations-bar.component';
import {OperationComponent} from 'src/modules/operations/operation-component/operation.component';
import {DynamicFieldsModule} from 'src/modules/dynamic-fields/dynamic-fields.module';
import {AppFormsModule} from 'src/modules/app-forms/app-forms.module';

@NgModule({
    imports: [
        ActionsModule,
        AppCommonModule,
        AppDirectivesModule,
        AppFormsModule,
        DynamicFieldsModule,
        TranslateModule
    ],
    declarations: [
        MassOperationsComponent,
        OperationComponent,
        OperationsBarComponent,
        SwitchOperationComponent
    ],
    exports: [
        MassOperationsComponent,
        OperationComponent,
        OperationsBarComponent,
        SwitchOperationComponent
    ]
})
export class OperationsModule {
}
