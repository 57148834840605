/* eslint-disable max-len */
/**
 * This file has been auto generated. Do not edit.
 */
import {IPartner} from '_types/rest/Entity/IRestPartner';
import {IRestPartner} from '_types/rest/Entity/IRestPartner';
import {IForm} from '_types/rest/Entity/IRestForm';
import {IRestForm} from '_types/rest/Entity/IRestForm';
import {ICms} from '_types/rest/Entity/IRestCms';
import {IRestCms} from '_types/rest/Entity/IRestCms';
import {IBaseRestEntity} from '_types/rest/IBaseRestEntity';
import {IRestObject} from 'core/rest/rest-object.service';

export const PartnerDomainType = {
    PARTNER_DOMAIN_TYPE_PLATFORM: 1,
    PARTNER_DOMAIN_TYPE_AUDIT: 2,
    PARTNER_DOMAIN_TYPE_SERVICE: 3
} as const;

export const PartnerDomainTypeOptions = [
    {
        value: PartnerDomainType.PARTNER_DOMAIN_TYPE_PLATFORM,
        label: 'PARTNER_DOMAIN_TYPE_PLATFORM',
    },
    {
        value: PartnerDomainType.PARTNER_DOMAIN_TYPE_AUDIT,
        label: 'PARTNER_DOMAIN_TYPE_AUDIT',
    },
    {
        value: PartnerDomainType.PARTNER_DOMAIN_TYPE_SERVICE,
        label: 'PARTNER_DOMAIN_TYPE_SERVICE',
    }
] as const;

export const PartnerDomainState = {
    PARTNER_DOMAIN_STATE_NONE: 0,
    PARTNER_DOMAIN_STATE_NEW: 1,
    PARTNER_DOMAIN_STATE_READY: 2,
    PARTNER_DOMAIN_STATE_SET: 3,
    PARTNER_DOMAIN_STATE_RUNNING: 4,
    PARTNER_DOMAIN_STATE_OFF: 5
} as const;

export const PartnerDomainStateOptions = [
    {
        value: PartnerDomainState.PARTNER_DOMAIN_STATE_NONE,
        label: 'PARTNER_DOMAIN_STATE_NONE',
    },
    {
        value: PartnerDomainState.PARTNER_DOMAIN_STATE_NEW,
        label: 'PARTNER_DOMAIN_STATE_NEW',
    },
    {
        value: PartnerDomainState.PARTNER_DOMAIN_STATE_READY,
        label: 'PARTNER_DOMAIN_STATE_READY',
    },
    {
        value: PartnerDomainState.PARTNER_DOMAIN_STATE_SET,
        label: 'PARTNER_DOMAIN_STATE_SET',
    },
    {
        value: PartnerDomainState.PARTNER_DOMAIN_STATE_RUNNING,
        label: 'PARTNER_DOMAIN_STATE_RUNNING',
    },
    {
        value: PartnerDomainState.PARTNER_DOMAIN_STATE_OFF,
        label: 'PARTNER_DOMAIN_STATE_OFF',
    }
] as const;

export interface IPartnerDomainConst {
    type: [{id: 1, value: 'PARTNER_DOMAIN_TYPE_PLATFORM'}, {id: 2, value: 'PARTNER_DOMAIN_TYPE_AUDIT'}, {id: 3, value: 'PARTNER_DOMAIN_TYPE_SERVICE'}],
    state: [{id: 0, value: 'PARTNER_DOMAIN_STATE_NONE'}, {id: 1, value: 'PARTNER_DOMAIN_STATE_NEW'}, {id: 2, value: 'PARTNER_DOMAIN_STATE_READY'}, {id: 3, value: 'PARTNER_DOMAIN_STATE_SET'}, {id: 4, value: 'PARTNER_DOMAIN_STATE_RUNNING'}, {id: 5, value: 'PARTNER_DOMAIN_STATE_OFF'}]
}

export interface IPartnerDomain extends IBaseRestEntity {
    /**
     * @description ID.
     */
    id?: number,
    /**
     * @description Domain name (eg url).
     * @maxLength 128
     */
    name: string,
    /**
     * @description Domain type.
     * @default 1
     */
    type: typeof PartnerDomainType[keyof typeof PartnerDomainType],
    /**
     * @description Optional SSL certificate.
     */
    sslCertificate?: string | null,
    privateKey?: string | null,
    publicKey?: string | null,
    /**
     * @maxLength 45
     * @format ipv6
     */
    ip?: string | null,
    /**
     * @default 1
     */
    state?: typeof PartnerDomainState[keyof typeof PartnerDomainState],
    /**
     * @description Partner to which domain belongs.
     */
    partner: IPartner | string | null,
    forms?: (IForm | string)[],
    cmses?: (ICms | string)[],
    /**
     * @description Record status
     *  0 = deleted
     *  1 = active.
     * @default 1
     */
    status?: number,
    /**
     * @description Creation timestamp.
     * @default CURRENT_TIMESTAMP
     * @format date-time
     */
    created?: string,
    /**
     * @description Last modification timestamp.
     * @format date-time
     */
    modified?: string | null
}

/** @deprecated */
export interface IRestPartnerDomain extends IPartnerDomain, IRestObject {
    /**
     * @description Partner to which domain belongs.
     */
    partner: IRestPartner | string | null,
    forms?: IRestForm[] | string[],
    cmses?: IRestCms[] | string[],
}
