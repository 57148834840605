import {Inject, Injectable} from '@angular/core';
import {WINDOW} from 'app-custom-providers';
import {BsHelpers} from 'angular-bootstrap4';

@Injectable({
    providedIn: 'root'
})
export class DomUtils {
    constructor(
        @Inject(WINDOW) private window: Window,
        private angularBS: BsHelpers
    ) {
    }

    scrollToElement = (
        element: HTMLElement
    ): void => {
        const offset = this.angularBS.offset(element),
            scrollTarget = offset.top - (5 * 14), // 5rem
            currentScroll = this.window.scrollY,
            start = this.window.performance.now(),
            step = (timestamp) => {
                const elapsed = timestamp - start;
                if (elapsed >= 1000) {
                    this.window.scrollTo(0, scrollTarget);
                    return;
                }
                this.window.scrollTo(
                    0,
                    currentScroll - Math.sin((Math.PI / 2) / (1000 / elapsed)) * (currentScroll - scrollTarget)
                );
                this.window.requestAnimationFrame(step);
            };

        this.window.requestAnimationFrame(step);
    }
}
