import {Inject, Injectable} from '@angular/core';
import { DOCUMENT } from '@angular/common';
import {BehaviorSubject, Observable} from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class LazyLoadService {
    private head: HTMLHeadElement;
    private loadedCss: { [link: string]: BehaviorSubject<HTMLLinkElement>} = {};

    constructor(
        @Inject(DOCUMENT) private document: Document,
    ) {
        this.head = document.querySelector('head');
        [...this.head.querySelectorAll('link')].forEach((link) => {
            if (link.getAttribute('rel') === 'stylesheet') {
                this.loadedCss[link.getAttribute('href')] = new BehaviorSubject(link);
            }
        });
    }

    getScript(url: string): Observable<void> {
        return new Observable((observer) => {
            const script = this.document.createElement('script');
            script.src = url;
            script.onload = () => {
                observer.next();
                observer.complete();
            };
            script.onerror = () => {
                observer.error(`Could not load script '${url}'.`);
            };
            this.head.append(script);
            script.remove();
        });
    }

    getCss(url: string): BehaviorSubject<HTMLLinkElement> {
        if (typeof this.loadedCss[url] !== 'undefined') {
            return this.loadedCss[url];
        }
        this.loadedCss[url] = new BehaviorSubject(null);

        const style = this.document.createElement('link');
        style.rel = 'stylesheet';
        style.href = url;
        style.onload = () => {
            this.loadedCss[url].next(style);
        };
        style.onerror = () => {
            this.loadedCss[url].error(`Could not load stylesheet '${url}'.`);
        };
        this.head.append(style);
        return this.loadedCss[url];
    }

    unloadCss(url: string): Observable<void> {
        return new Observable((observer) => {
            if (typeof this.loadedCss[url] === 'undefined') {
                observer.error(`Could not unload CSS from '${url}' because it wasn't loaded!`);
            } else {
                this.loadedCss[url].subscribe((htmlLink) => {
                    if (htmlLink !== null) {
                        htmlLink.remove();
                        delete this.loadedCss[url];
                    }
                });
            }

            observer.next();
            observer.complete();
        });
    }
}
