import 'polyfills';
import 'enable-prod-mode-fix';
import {enableProdMode} from '@angular/core';
import {platformBrowser} from '@angular/platform-browser';
import {ExceptionHandler} from 'src/modules/exception-handler/exception-handler';
import {CoreModule} from 'view-modules/core/core.module';

if (window.ENV.APP.production) {
    enableProdMode();
}

if (window.ENV.env.sentryDsn) {
    ExceptionHandler.initSentry(window.ENV.env.sentryDsn);
}

platformBrowser().bootstrapModule(CoreModule)
    // eslint-disable-next-line no-console
    .catch((err) => console.error(err));
