/* eslint-disable max-len */
/**
 * This file has been auto generated. Do not edit.
 */
import {ICronLog} from '_types/rest/Entity/IRestCronLog';
import {IBaseRestEntity} from '_types/rest/IBaseRestEntity';

export const CronState = {
    CRON_STATE_ENABLED: 1,
    CRON_STATE_DISABLED: 2,
    CRON_STATE_PROCESSING: 3
} as const;

export const CronStateOptions = [
    {
        value: CronState.CRON_STATE_ENABLED,
        label: 'CRON_STATE_ENABLED',
    },
    {
        value: CronState.CRON_STATE_DISABLED,
        label: 'CRON_STATE_DISABLED',
    },
    {
        value: CronState.CRON_STATE_PROCESSING,
        label: 'CRON_STATE_PROCESSING',
    }
] as const;

export interface ICronConst {
    state: [{id: 1, value: 'CRON_STATE_ENABLED'}, {id: 2, value: 'CRON_STATE_DISABLED'}, {id: 3, value: 'CRON_STATE_PROCESSING'}]
}

export interface ICron extends IBaseRestEntity {
    id?: number,
    /**
     * @default 2
     */
    state?: typeof CronState[keyof typeof CronState],
    /**
     * @maxLength 64
     */
    name: string,
    /**
     * @maxLength 64
     */
    expression: string,
    /**
     * @maxLength 32
     */
    command: string,
    arguments?: string | null,
    instantInvoke?: boolean | null,
    cronLog?: (ICronLog | string)[],
    /**
     * @format date-time
     */
    lastExecutionDate?: string,
    lastReturnCode?: number | null,
    /**
     * @description When should force start cron if it will stuck in processing (in minutes).
     */
    autoRestartTime?: number | null,
    /**
     * @description Record status
     *  0 = deleted
     *  1 = active.
     * @default 1
     */
    status?: number,
    /**
     * @description Creation timestamp.
     * @default CURRENT_TIMESTAMP
     * @format date-time
     */
    created?: string,
    /**
     * @description Last modification timestamp.
     * @format date-time
     */
    modified?: string | null
}
