/* eslint-disable max-len */
/**
 * This file has been auto generated. Do not edit.
 */
import {ICountry} from '_types/rest/Entity/IRestCountry';
import {IRestCountry} from '_types/rest/Entity/IRestCountry';
import {IPartner} from '_types/rest/Entity/IRestPartner';
import {IRestPartner} from '_types/rest/Entity/IRestPartner';
import {IBaseRestEntity} from '_types/rest/IBaseRestEntity';
import {IRestObject} from 'core/rest/rest-object.service';

export const BusinessDayWorkingDays = {
    BUSINESS_DAYS_DAY_MONDAY: 1,
    BUSINESS_DAYS_DAY_TUESDAY: 2,
    BUSINESS_DAYS_DAY_WEDNESDAY: 3,
    BUSINESS_DAYS_DAY_THURSDAY: 4,
    BUSINESS_DAYS_DAY_FRIDAY: 5,
    BUSINESS_DAYS_DAY_SATURDAY: 6,
    BUSINESS_DAYS_DAY_SUNDAY: 7
} as const;

export const BusinessDayWorkingDaysOptions = [
    {
        value: BusinessDayWorkingDays.BUSINESS_DAYS_DAY_MONDAY,
        label: 'BUSINESS_DAYS_DAY_MONDAY',
    },
    {
        value: BusinessDayWorkingDays.BUSINESS_DAYS_DAY_TUESDAY,
        label: 'BUSINESS_DAYS_DAY_TUESDAY',
    },
    {
        value: BusinessDayWorkingDays.BUSINESS_DAYS_DAY_WEDNESDAY,
        label: 'BUSINESS_DAYS_DAY_WEDNESDAY',
    },
    {
        value: BusinessDayWorkingDays.BUSINESS_DAYS_DAY_THURSDAY,
        label: 'BUSINESS_DAYS_DAY_THURSDAY',
    },
    {
        value: BusinessDayWorkingDays.BUSINESS_DAYS_DAY_FRIDAY,
        label: 'BUSINESS_DAYS_DAY_FRIDAY',
    },
    {
        value: BusinessDayWorkingDays.BUSINESS_DAYS_DAY_SATURDAY,
        label: 'BUSINESS_DAYS_DAY_SATURDAY',
    },
    {
        value: BusinessDayWorkingDays.BUSINESS_DAYS_DAY_SUNDAY,
        label: 'BUSINESS_DAYS_DAY_SUNDAY',
    }
] as const;

export interface IBusinessDayConst {
    workingDays: [{id: 1, value: 'BUSINESS_DAYS_DAY_MONDAY'}, {id: 2, value: 'BUSINESS_DAYS_DAY_TUESDAY'}, {id: 3, value: 'BUSINESS_DAYS_DAY_WEDNESDAY'}, {id: 4, value: 'BUSINESS_DAYS_DAY_THURSDAY'}, {id: 5, value: 'BUSINESS_DAYS_DAY_FRIDAY'}, {id: 6, value: 'BUSINESS_DAYS_DAY_SATURDAY'}, {id: 7, value: 'BUSINESS_DAYS_DAY_SUNDAY'}]
}

export interface IBusinessDay extends IBaseRestEntity {
    id?: number,
    country?: ICountry | string,
    partner?: IPartner | string | null,
    workingDays?: typeof BusinessDayWorkingDays[keyof typeof BusinessDayWorkingDays][],
    freeDays?: string[] | null,
    /**
     * @description Record status
     *  0 = deleted
     *  1 = active.
     * @default 1
     */
    status?: number,
    /**
     * @description Creation timestamp.
     * @default CURRENT_TIMESTAMP
     * @format date-time
     */
    created?: string,
    /**
     * @description Last modification timestamp.
     * @format date-time
     */
    modified?: string | null
}

/** @deprecated */
export interface IRestBusinessDay extends IBusinessDay, IRestObject {
    country?: IRestCountry | string,
    partner?: IRestPartner | string | null,
}
