<div #tooltipContainer class="tooltip-container">
    <div class="editor-tooltip"
         [ngStyle]="{
            'left.px': left,
            'top.px': top,
            'width.px': tooltipWidth
         }">
        <ng-content></ng-content>
    </div>
</div>
