import {Injector} from '@angular/core';
import {TranslateService} from 'src/modules/translate/translate.service';
import {IDynamicFieldOption} from 'src/modules/dynamic-fields/interfaces';


export abstract class OptionsUtil {
    static getFromOption<T = unknown>(
        arg1: IDynamicFieldOption<T>[] | T,
        arg2: T | IDynamicFieldOption<T>[],
    ): string {
        if (Array.isArray(arg1)) {
            return arg1.find(({value}) => value === arg2)?.label;
        }

        if (Array.isArray(arg2)) {
            return arg2.find(({value}) => value === arg1)?.label;
        }
    }

    static getTranslatedLabel<T = unknown>(
        options: IDynamicFieldOption<T>[],
        item: T,
        injector: Injector
    ): string {
        const label = OptionsUtil.getFromOption(options, item),
            translate = injector.get(TranslateService);

        return translate.get(label);
    }
}
