import {AbstractOperationDefinition} from 'src/modules/operations/interfaces';
import {Injector} from '@angular/core';
import {IRestObject} from 'src/modules/rest/objects';
import {StateService} from '@uirouter/core';

export class AddRuleOperation extends AbstractOperationDefinition<'rules'> {
    readonly endpoint = 'rules';
    readonly name = 'post';

    icon = 'fa-plus';
    lang = 'ADD';

    invoke(
        context: IRestObject<'rules'>,
        injector: Injector
    ): void {
        const state = injector.get(StateService);
        state.go('.add');
    }
}

export class EditRuleOperation extends AbstractOperationDefinition<'rules'> {
    readonly endpoint = 'rules';
    readonly name = 'put';

    icon = 'fa-edit';
    lang = 'EDIT';

    invoke(
        context: IRestObject<'rules'>,
        injector: Injector
    ): void {
        const state = injector.get(StateService);
        state.go('.edit', {ruleId: context.id});
    }
}

export const rulesOperations = [
    AddRuleOperation,
    EditRuleOperation
] as const;
