import {NgModule} from '@angular/core';
import {AppFormDirective} from 'src/modules/app-forms/app-form.directive';
// eslint-disable-next-line no-restricted-imports
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {SubmitDirective} from 'src/modules/app-forms/submit.directive';
import {RequiredDirective} from 'src/modules/app-forms/required.directive';
import {ValidateModule} from 'angular-bootstrap4-validate';
import {TriStateCheckboxComponent} from 'src/modules/app-forms/tri-state-checkbox/tri-state-checkbox.component';
import {AppCommonModule} from 'src/modules/app-common/app-common.module';
import {LocatorsModule} from 'src/modules/locators/locators.module';


@NgModule({
    imports: [
        AppCommonModule,
        FormsModule,
        LocatorsModule,
        ReactiveFormsModule,
        ValidateModule
    ],
    declarations: [
        AppFormDirective,
        RequiredDirective,
        SubmitDirective,
        TriStateCheckboxComponent
    ],
    exports: [
        AppFormDirective,
        FormsModule,
        LocatorsModule,
        ReactiveFormsModule,
        RequiredDirective,
        SubmitDirective,
        TriStateCheckboxComponent,
        ValidateModule
    ]
})
export class AppFormsModule {
}
