import {Injectable} from '@angular/core';
import {StateService} from '@uirouter/core';

@Injectable({
    providedIn: 'root'
})
export class NotesService {
    constructor(
        private readonly stateService: StateService
    ) {}

    getNoteDetailsLink(noteId: number): string {
        // Currently params in stateService.href are not working, therefore we add it manually.
        const noteStateURL = this.stateService.href('notes', null, {absolute: true, inherit: false});
        return `${noteStateURL}/details/${noteId}`;
    }
}
