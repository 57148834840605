import {Component} from '@angular/core';
import {IToast, ToastsService} from 'src/modules/global-components/toasts/toasts.service';
import {ToastsAnimations} from 'src/modules/global-components/toasts/toasts-animations';


@Component({
    selector: 'toasts',
    templateUrl: './toasts.component.html',
    animations: ToastsAnimations,
    styleUrls: ['./toasts.component.scss']
})
export class ToastsComponent {
    toasts: IToast[];

    constructor(
        private toastsService: ToastsService
    ) {
        this.toasts = toastsService.toasts;
    }
}
