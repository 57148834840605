import {AbstractOperationDefinition} from 'src/modules/operations/interfaces';
import {AbstractDeleteOperation} from 'view-modules/operations/abstract/abstract-delete.operation';
import {IRestCollection} from 'src/modules/rest/objects';
import {ICms, With} from '_types/rest';


export type Cms = With<ICms, 'locale'>;
export interface ICmsAddContext {
    cmsType: string,
    partnerId: number,
    cms: IRestCollection<'cms', Cms>
}

export class CmsAddOperation extends AbstractOperationDefinition<string, ICmsAddContext> {
    readonly endpoint = 'cms';
    readonly name = 'post';
    lang = 'CMS_ADD';
    icon = 'fa-plus';
    component = (): Promise<unknown> => import('./cms-form/cms-form.component');

    access = (context: ICmsAddContext): boolean => {
        return context.cms.access('post');
    }
}

export class CmsEditOperation extends AbstractOperationDefinition<'cms'> {
    readonly endpoint = 'cms';
    readonly name = 'put';
    lang = 'CMS_EDIT';
    icon = 'fa-edit';
    component = (): Promise<unknown> => import('./cms-form/cms-form.component');
}

export class CmsDeleteOperation extends AbstractDeleteOperation<'cms'> {
    readonly endpoint = 'cms';
    lang = 'DELETE';
    confirmMessage = 'CMS_REMOVE_CONFIRM';
    confirmOptions = {
        langYes: 'CMS_REMOVE_CONFIRM_LANG_YES'
    };
}

export const cmsOperations = [
    CmsAddOperation,
    CmsEditOperation,
    CmsDeleteOperation
] as const;
