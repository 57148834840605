/**
 * This file has been auto generated. Do not edit.
 */
import {ICategory} from '_types/rest/Entity/IRestCategory';
import {IRestCategory} from '_types/rest/Entity/IRestCategory';
import {ICategoryNodeEntity} from '_types/rest/Entity/IRestCategoryNodeEntity';
import {IRestCategoryNodeEntity} from '_types/rest/Entity/IRestCategoryNodeEntity';
import {IBaseRestEntity} from '_types/rest/IBaseRestEntity';
import {IRestObject} from 'core/rest/rest-object.service';

export const CategoryNodeType = {
    CATEGORY_NODE_TYPE_HIERARCHICAL: 1,
    CATEGORY_NODE_TYPE_TAG: 2
} as const;

export const CategoryNodeTypeOptions = [
    {
        value: CategoryNodeType.CATEGORY_NODE_TYPE_HIERARCHICAL,
        label: 'CATEGORY_NODE_TYPE_HIERARCHICAL',
    },
    {
        value: CategoryNodeType.CATEGORY_NODE_TYPE_TAG,
        label: 'CATEGORY_NODE_TYPE_TAG',
    }
] as const;

export interface ICategoryNodeConst {
    type: [{id: 1, value: 'CATEGORY_NODE_TYPE_HIERARCHICAL'}, {id: 2, value: 'CATEGORY_NODE_TYPE_TAG'}]
}

export interface ICategoryNode extends IBaseRestEntity {
    id?: number,
    /**
     * @maxLength 64
     */
    name: string,
    /**
     * @default 1
     */
    type?: typeof CategoryNodeType[keyof typeof CategoryNodeType],
    parentCategoryNode?: ICategoryNode | string | null,
    childCategoryNodes?: (ICategoryNode | string)[],
    categories?: (ICategory | string)[],
    categoryNodeEntities?: (ICategoryNodeEntity | string)[],
    /**
     * @description Record status
     *  0 = deleted
     *  1 = active.
     * @default 1
     */
    status?: number,
    /**
     * @description Creation timestamp.
     * @default CURRENT_TIMESTAMP
     * @format date-time
     */
    created?: string,
    /**
     * @description Last modification timestamp.
     * @format date-time
     */
    modified?: string | null
}

/** @deprecated */
export interface IRestCategoryNode extends ICategoryNode, IRestObject {
    parentCategoryNode?: IRestCategoryNode | string | null,
    childCategoryNodes?: IRestCategoryNode[] | string[],
    categories?: IRestCategory[] | string[],
    categoryNodeEntities?: IRestCategoryNodeEntity[] | string[],
}
