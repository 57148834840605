<div class="form-row align-items-center form-inline mb-3" *ngIf="tableConfig.searchField || filters.length" bsDropdownBoundary>
    <tt-search class="" *ngIf="tableConfig.searchField"></tt-search>
    <ng-container *ngFor="let item of filters">
        <legend-subject [legendItemName]="item.filter.fieldConfig.field">
            <div class="col col-auto p-0" *ngIf="!item.template" [hidden]="item.filter.fieldConfig.hidden">
                <dynamic-field (initializing)="onInitializingChange(item, $event)"
                               [fieldConfig]="$any(item.filter.fieldConfig)"
                               [formControl]="item.filter"
                               (objectValueChange)="filterChange($event, item.id, item.filter)">
                </dynamic-field>
            </div>
            <div class="col col-auto p-0" *ngIf="item.template" [hidden]="item.filter.fieldConfig.hidden">
                <ng-container *ngTemplateOutlet="item.template; context: item.context"></ng-container>
            </div>
        </legend-subject>
    </ng-container>
    <button type="button" class="btn btn-default" (click)="resetFilters()" *ngIf="filters.length" [disabled]="!tableConfig.selectedFilters.length">
        <span aria-hidden="true" class="fa fa-sync mr-1"></span> {{'COMMON_RESET_FILTERS' | translate}}
    </button>
</div>

<ng-container *ngFor="let selectedFilter of tableConfig.selectedFilters; first as first;">
    <span class="badge badge-pill badge-primary mt-3"
          [hidden]="selectedFilter.hidden"
          [ngClass]="{'ml-2': !first}">
        {{selectedFilter.label}}
            <a href="javascript:" class="ml-1" (click)="selectedFilter.removeFn()" *ngIf="selectedFilter.removable">
            <i class="fa fa-times-circle"></i>
        </a>
    </span>
</ng-container>
