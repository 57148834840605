import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {RestClient} from 'src/modules/rest/rest-client.service';
import {IMercureEventData, IPubConfigMercure} from '_types/pub/config/mercure';
import {map} from 'rxjs/operators';
import {UserService} from 'src/modules/rest/user/user.service';
import {NotificationsService} from 'src/modules/notifications/notifications.service';

@Injectable({
    providedIn: 'root'
})
export class MercureService {
    private reconnectFrequencySeconds = 1;

    constructor(
        private userService: UserService,
        private restClient: RestClient,
        private notificationsService: NotificationsService
    ) {
    }


    initialize(): Observable<void> {
        if (
            !this.userService.hasPrivileges('feature.notification')
            || !(
                'URL' in self
                && 'EventSource' in self
            )
        ) {
            return of(undefined);
        }

        return this.restClient.endpoint<string, never, IPubConfigMercure>('config/mercure')
            .getAll()
            .pipe(
                map((mercureConfig) => {
                    this.connect(mercureConfig);
                })
            );
    }

    private connect(mercureConfig: IPubConfigMercure): void {
        if (!mercureConfig.ENABLED) {
            return;
        }

        // URL & EventSource availability is being checked in initialize method
        const url = new URL(mercureConfig.MERCURE_URL);
        url.searchParams.append('topic', mercureConfig.NOTIFICATION_TOPIC);

        const eventSource = new EventSource(url.toString());

        eventSource.onopen = () => {
            this.reconnectFrequencySeconds = 1;
        };

        eventSource.addEventListener('notification', (event: MessageEvent) => {
            const data: IMercureEventData = JSON.parse(event.data);
            this.notificationsService.getNotifyAndBroadcast(data.uuid);
        });

        eventSource.onerror = () => {
            eventSource.close();
            setTimeout(() => {
                this.reconnectMercure(mercureConfig);
            }, this.reconnectFrequencySeconds * 1000);
        };
    }

    private reconnectMercure(mercureConfig: IPubConfigMercure): void {
        this.connect(mercureConfig);

        this.reconnectFrequencySeconds *= 2;

        if (this.reconnectFrequencySeconds >= 64) {
            this.reconnectFrequencySeconds = 64;
        }
    }
}
