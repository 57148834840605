<card-collapse *ngIf="task
    && (taskRest | accessTask: 'full_access')
    && (taskFilesCount || (taskRest | accessTask: 'put_item': 'taskFiles'))"
               mode="body"
               class="task-border-bottom"
               headerClass="task-card-header task-card-border-top"
               contentClass="task-card-content"
               stateName="taskView"
               collapseName="taskFiles">
    <div cardTitle>
        {{'COMMON_TASK_SECTION_FILES' | translate}}
        <span *ngIf="taskFilesCount">({{taskFilesCount}})</span>
    </div>
    <div cardContent>
        <ng-container *ngIf="task?.id; else filesInNewTaskTemplate">
            <ng-container *ngIf="!taskFilesLoading; else loadingTemplate">
                <ng-container *ngIf="taskRest | accessTask: 'put_item': 'taskFiles'; else thumbnailsTemplate">
                    <drop-zone (fileUploaded)="uploadFilesInExistingTask($event)"
                               [settings]="{
                               transclude: !!taskFiles?.length
                           }">
                        <div class="mt-2">
                            <ng-container *ngTemplateOutlet="thumbnailsTemplate"></ng-container>
                        </div>
                    </drop-zone>
                </ng-container>
            </ng-container>
            <ng-container *ngIf="uploadingFiles.length">
                <file-list [files]="uploadingFiles"
                           (fileDelete)="cancelUpload($event)">
                </file-list>
            </ng-container>
        </ng-container>
    </div>
</card-collapse>

<ng-template #filesInNewTaskTemplate>
    <file-upload (uploadFile)="uploadFileInNewTask($event)"
                 (deleteFile)="deleteFileInNewTask($event)"
                 [filesAdditional]="filesRestModel"
                 [multiple]="true"
                 tableName="task_note_file"
                 entityFieldName="file">
    </file-upload>
</ng-template>
<ng-template #loadingTemplate>
    <div>
        <loader></loader>
    </div>
</ng-template>
<ng-template #thumbnailsTemplate>
    <file-thumbnails [model]="taskFiles">
    </file-thumbnails>
</ng-template>
