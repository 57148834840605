import {AbsPipe} from 'src/pipes/abs.pipe';
import {CurrencyPipe} from 'src/pipes/currency.pipe';
import {DatePipe} from 'src/pipes/date.pipe';
import {DefaultPipe} from 'src/pipes/default.pipe';
import {DomainPipe} from 'src/pipes/domain.pipe';
import {FromOptionsPipe} from 'src/pipes/from-options.pipe';
import {HumanFileSizePipe} from 'src/pipes/human-file-size.pipe';
import {ShortTextPipe} from 'src/pipes/short-text.pipe';
import {SplitTextPipe} from 'src/pipes/split-text.pipe';
import {ToTimePipe} from 'src/pipes/to-time.pipe';
import {PercentPipe, UpperCasePipe} from '@angular/common';
import {PipeTransform, TemplateRef} from '@angular/core';
import {EndpointName, ITableConfigOutputDto} from '_types/rest';
import {IRestCollection, IRestObject} from 'src/modules/rest/objects';
import {IAppTableTools} from 'src/modules/app-table-tools/app-table-tools.service';
import {ITableTools} from 'angular-bootstrap4-table-tools';
import {
    IPlainTemplateContext,
    ITableCellTemplateContext
} from 'src/modules/dynamic-table/cell-templates/interfaces';
import {IDynamicFieldConfig} from 'src/modules/dynamic-fields/interfaces';
import {DynamicTableFilterGroup} from 'src/modules/dynamic-table/dynamic-table-filters/dynamic-table-filter-group';
import {Observable} from 'rxjs';
import {IAnimationSortable} from 'src/modules/sortable/animations/interfaces';
import {SortStopEvent} from 'src/modules/sortable/sortable.directive';
import {TimeLoggedPipe} from 'src/pipes/time-logged.pipe';
import {TranslatePipe} from 'src/modules/translate/translate.pipe';
import {IOperationAbstract, IOperationClass, IOperationResult} from 'src/modules/operations/interfaces';
import {DynamicTableFilter} from 'src/modules/dynamic-table/dynamic-table-filters/dynamic-table-filter';


// region pipes
export const availablePipes = {
    abs: AbsPipe,
    currency: CurrencyPipe,
    date: DatePipe,
    default: DefaultPipe,
    domain: DomainPipe,
    fileSize: HumanFileSizePipe,
    fromOptions: FromOptionsPipe,
    percent: PercentPipe,
    shortText: ShortTextPipe,
    splitText: SplitTextPipe,
    timeLogged: TimeLoggedPipe,
    toTime: ToTimePipe,
    translate: TranslatePipe,
    upperCase: UpperCasePipe
} as const;

export type PipeArgs<T extends PipeTransform> = T['transform'] extends (value: unknown, ...args: infer P) => unknown
    ? P : never;

type PipeDefs = {
    [P in keyof typeof availablePipes]: P | {
    pipe: P,
    args: PipeArgs<InstanceType<typeof availablePipes[P]>>
}
}

export type DynamicTablePipes = PipeDefs[keyof typeof availablePipes];
// endregion pipes
// region templates
type TemplateConfig<TObject, TContext extends ITableCellTemplateContext> = TContext extends { config: unknown }
    ? { config: (value: unknown, item: TObject, index: number) => TContext['config'] } : (
        TContext extends { config?: unknown }
            ? { config?: (value: unknown, item: TObject, index: number) => TContext['config'] } : {}
        );

type TemplateDefs<TObject> = {
    [P in ITableCellTemplateContext['type']]: {
    type: P
} & TemplateConfig<TObject, Extract<ITableCellTemplateContext, { type: P }>>
}

export type DynamicTableDisplay<TObject> = TemplateDefs<TObject>[ITableCellTemplateContext['type']];
// endregion templates
// region column definition
export type DynamicTableColumnProcessor<T>
    = (item: T) => (string | string[] | TemplateRef<{field: string; item: T}>);

export interface IDynamicTableColumnDefinition<TObject> {
    field: Extract<keyof TObject, string> | string,
    hidden?: boolean,
    th: {
        content: string | TemplateRef<{ column: IDynamicTableColumn<TObject> }>,
        css?: string,
        sort?: boolean | string
    },
    td?: {
        content?: DynamicTableColumnProcessor<TObject> | TemplateRef<{ item: TObject }>,
        pipes?: DynamicTablePipes[],
        css?: string
        display?: TemplateDefs<TObject>[ITableCellTemplateContext['type']],
        separator?: string
    }
}

// endregion column definition
// processed column
export interface IDynamicTableColumn<TObject> extends IDynamicTableColumnDefinition<TObject> {
    th: IDynamicTableColumnDefinition<TObject>['th'] & {
        sort: string,
        template: TemplateRef<{ column: IDynamicTableColumn<TObject> } | IPlainTemplateContext>
    },
    td: IDynamicTableColumnDefinition<TObject>['td'] & {
        template: TemplateRef<{ item: TObject } | ITableCellTemplateContext>
    }
}

// region table definition

export interface IDynamicTableOperation<
    TObject,
    TOperation extends IOperationAbstract = IOperationAbstract
> {
    operationClass: IOperationClass<TOperation>;
    context?: (item: TObject) => TOperation['context'];
}

export type IDynamicTableSelectedItemOperation<TObject> = IDynamicTableOperation<TObject> & {
    isAllItemOperation?: boolean;
};

export interface IDynamicTableFilterTemplateContext {
    $implicit: DynamicTableFilter
}

export interface IDynamicTableFilterTemplate {
    template: TemplateRef<IDynamicTableFilterTemplateContext>,
    name: string,
    context?: unknown,
    displayValue?: (value: unknown) => string,
    default?: unknown
}

export interface IFilterCompressingOptions {
    compress: boolean;
    label?: string;
    threshold: number;
}

export type IDynamicTableFilterDefinition = (IDynamicFieldConfig | IDynamicTableFilterTemplate) & {
    field?: string;
    remember?: boolean;
    removable?: boolean;
    filterCompressingOptions?: IFilterCompressingOptions;
    hidden?: boolean;
}

export interface IQuickFilterDefinition {
    name: string,
    fields: Record<string, unknown>,
    removable?: boolean,
}

export interface IDynamicTableDefinition<TObject, TEndpoint extends EndpointName | false = false> {
    id: string,
    name: string,
    tableContainerCss?: string,
    tableCss?: string,
    hideHeader?: boolean,
    hideFooter?: boolean,
    hideFilters?: boolean,
    searchField?: boolean,
    filters?: {
        [field: string]: IDynamicTableFilterDefinition
    } | DynamicTableFilterGroup,
    quickFilters?: IQuickFilterDefinition[],
    columns: IDynamicTableColumnDefinition<TObject>[],
    export?: boolean,
    collectionOperations?: IDynamicTableOperation<
        TObject extends IRestObject<EndpointName>
            ? (
                TEndpoint extends EndpointName
                    ? IRestCollection<TEndpoint, TObject>
                    : TObject[]
                )
            : TObject[]
    >[],
    itemOperations?: IDynamicTableOperation<TObject>[],
    selectedItemOperations?: IDynamicTableSelectedItemOperation<TObject[]>[],
    rowClick?: (item) => {
        state: string,
        params?: Record<string, unknown>
    },
    rowClass?: (item: TObject) => string,
    sortable?: {
        enabled: boolean,
        onSortStop: ($event: SortStopEvent) => void
        animation?: IAnimationSortable,
    },
    rowStyle?: (item: TObject) => Record<string, string>,
    /**
     * Perform side effects on operation success and decide if data should be filtered.
     */
    operationSuccessCb?: (operationResult: IOperationResult, operation: IOperationClass) => boolean
}

// endregion table definition

// table instances

export interface ISelectedFilter {
    id: string,
    label: string,
    removeFn: (params?: unknown) => void,
    removable: boolean,
    hidden?: boolean
}

export interface IDynamicTable<TObject,
    TEndpoint extends EndpointName | false = false> extends IDynamicTableDefinition<TObject, TEndpoint> {

    filters?: DynamicTableFilterGroup,
    activeSettingName?: string,
    settingTemplates?: ITableConfigOutputDto[],
    selectedFilters: ISelectedFilter[],
    columns: IDynamicTableColumn<TObject>[],
    sortable: {
        enabled: boolean,
        animation: IAnimationSortable,
        onSortStop: ($event: SortStopEvent) => void
    },
    configured$: Observable<void>
}

export type IDynamicTableEndpoint<TEndpoint extends EndpointName,
    TObject extends object = IRestObject<TEndpoint>> =
    IAppTableTools<TEndpoint, TObject>
    & IDynamicTable<TObject, TEndpoint>

export type IDynamicTableCollection<TObject extends object> = ITableTools<TObject> & IDynamicTable<TObject>;
