/**
 * This file has been auto generated. Do not edit.
 */
import {ICompanyContact} from '_types/rest/Entity/IRestCompanyContact';
import {IRestCompanyContact} from '_types/rest/Entity/IRestCompanyContact';
import {IBaseRestEntity} from '_types/rest/IBaseRestEntity';
import {IRestObject} from 'core/rest/rest-object.service';

export const CompanyContactPhoneType = {
    COMPANY_CONTACT_PHONE_TYPE_MOBILE: 1,
    COMPANY_CONTACT_PHONE_TYPE_BUSINESS: 2
} as const;

export const CompanyContactPhoneTypeOptions = [
    {
        value: CompanyContactPhoneType.COMPANY_CONTACT_PHONE_TYPE_MOBILE,
        label: 'COMPANY_CONTACT_PHONE_TYPE_MOBILE',
    },
    {
        value: CompanyContactPhoneType.COMPANY_CONTACT_PHONE_TYPE_BUSINESS,
        label: 'COMPANY_CONTACT_PHONE_TYPE_BUSINESS',
    }
] as const;

export interface ICompanyContactPhoneConst {
    type: [{id: 1, value: 'COMPANY_CONTACT_PHONE_TYPE_MOBILE'}, {id: 2, value: 'COMPANY_CONTACT_PHONE_TYPE_BUSINESS'}]
}

export interface ICompanyContactPhone extends IBaseRestEntity {
    id?: number,
    /**
     * @minLength 6
     * @maxLength 32
     */
    phoneNumber: string,
    /**
     * @maxLength 12
     */
    extensionNumber?: string | null,
    companyContact: ICompanyContact | string,
    type: typeof CompanyContactPhoneType[keyof typeof CompanyContactPhoneType],
    /**
     * @description Record status
     *  0 = deleted
     *  1 = active.
     * @default 1
     */
    status?: number,
    /**
     * @description Creation timestamp.
     * @default CURRENT_TIMESTAMP
     * @format date-time
     */
    created?: string,
    /**
     * @description Last modification timestamp.
     * @format date-time
     */
    modified?: string | null
}

/** @deprecated */
export interface IRestCompanyContactPhone extends ICompanyContactPhone, IRestObject {
    companyContact: IRestCompanyContact | string,
}
