import {StateField, Transaction} from 'prosemirror-state';
import {IMentionStateValue} from 'src/modules/wysiwyg-editor/plugins/mention-factory/interfaces';
import {triggerByCharFactory} from 'src/modules/wysiwyg-editor/plugins/mention-factory/trigger-by-char';


export class MentionFactoryState implements StateField<IMentionStateValue> {
    init(): IMentionStateValue {
        return {
            active: false,
            range: {},
            text: null,
        };
    }

    apply(tr: Transaction, prevState: IMentionStateValue): IMentionStateValue {
        const selection = tr.selection,
            nextState = { ...prevState },
            emptyState = () => {
                nextState.active = false;
                nextState.range = {};
                nextState.text = null;
            };

        if (selection.from !== selection.to) {
            emptyState();
            return nextState;
        }

        if (selection.from < prevState.range.from || selection.from > prevState.range.to) {
            emptyState();
        }

        const match = triggerByCharFactory()(selection.$from);

        if (match) {
            nextState.active = true;
            nextState.range = match.range;
            nextState.text = match.text;
        } else {
            emptyState();
        }

        return nextState;
    }
}
