import {AbstractOperationDefinition} from 'src/modules/operations/interfaces';
import {IReportDisplayStructureReferencedDisplayOutputDto, IReportGenerateOutputDto} from '_types/rest';
import {IQueryObject} from 'src/services/url-parser.service';
import {Observable} from 'rxjs';
import {Injector} from '@angular/core';
import {UIRouter} from '@uirouter/core';
import {ReportDisplayViewComponent} from 'src/modules/reports/report-display-view/report-display-view.component';
import {RestClient} from 'src/modules/rest/rest-client.service';
import {TranslateService} from 'src/modules/translate/translate.service';
import {ToastsService} from 'src/modules/global-components/toasts/toasts.service';
import {IOperationResult} from 'src/modules/operations/interfaces';

export class ReportExportOperation extends AbstractOperationDefinition<string, IQueryObject> {
    endpoint = 'reports-module';
    lang: string;
    name: string;

    icon = 'fa-download';
    color?: string;

    constructor(
        private reference: IReportDisplayStructureReferencedDisplayOutputDto,
        private reportDisplayViewComponent: ReportDisplayViewComponent
    ) {
        super();
        this.lang = reference.name;
        this.name = 'export-' + reference.name;
    }

    invoke(
        context: unknown,
        injector: Injector
    ): Observable<IOperationResult> {
        return new Observable<IOperationResult>((subscriber) => {
            if (!this.reportDisplayViewComponent.currentQuery) {
                subscriber.next({success: false});
                subscriber.complete();
                return;
            }

            delete this.reportDisplayViewComponent.currentQuery.json;

            const restClient = injector.get(RestClient),
                translateService = injector.get(TranslateService),
                toastService = injector.get(ToastsService);

            restClient
                .endpoint(this.reference.endpoint.name)
                .get(
                    this.reference.endpoint.id, Object.assign(
                        {},
                        this.reportDisplayViewComponent.currentQuery,
                        this.reference.endpoint.query
                    )
                )
                .subscribe({
                    next: (results: IReportGenerateOutputDto) => {
                        const a = document.createElement('a'),
                            item = '\ufeff' + results.content,
                            blob = new Blob([item], {type: 'text/csv;charset=utf-8'}),
                            url = URL.createObjectURL(blob);
                        a.setAttribute('style', 'display: none');
                        a.href = url;
                        a.download = injector.get(UIRouter).globals.current.url + '.csv';
                        document.body.appendChild(a);
                        a.onclick = (e) => e.stopImmediatePropagation();
                        a.click();
                        a.remove();
                        subscriber.next({success: true});
                        subscriber.complete();
                    },
                    error: () => {
                        toastService.pop(translateService.get('DATA_FETCHING_ERROR'), undefined, 'error');
                        subscriber.error();
                        subscriber.complete();
                    }
                });
        });
    }

    access(): boolean {
        return true;
    }
}
