import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {RestClient} from 'src/modules/rest/rest-client.service';
import {IEntityEventHistoryOutputDto, UserLoginType} from '_types/rest';
import {TranslateService} from 'src/modules/translate/translate.service';
import {IRestCollection} from 'src/modules/rest/objects';
import {IQueryObject} from 'src/services/url-parser.service';


@Injectable({
    providedIn: 'root'
})
export class EventHistoryService {
    constructor(
        private readonly restClient: RestClient,
        private readonly translateService: TranslateService
    ) {
    }

    getEntityEventHistory(
        id: number,
        endpoint: string,
        query: IQueryObject = {}
    ): Observable<IRestCollection<string, IEntityEventHistoryOutputDto>> {
        const eventHistoryQuery = {
            with: ['eventHistoryAction'],
        };

        endpoint = `event_histories/${endpoint}/${id}`;
        return this.restClient.endpoint<string, never, IEntityEventHistoryOutputDto>(endpoint)
            .getAll(
                Object.assign(query, eventHistoryQuery)
            );
    }

    getEventAuthor(entry: IEntityEventHistoryOutputDto): string {
        if (
            entry.userLogin
            && typeof entry.userLogin === 'object'
        ) {
            return entry.userLogin.type === UserLoginType.USER_TYPE_WORKER
                ? this.translateService.get('COMMON_SYSTEM')
                : entry.userLogin.lastName + ' ' + entry.userLogin.firstName;
        }

        return this.translateService.get('COMMON_TASK_NOTE_LIST_AUTHOR_ANONYMOUS');
    }
}
