import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {RestClient} from 'src/modules/rest/rest-client.service';


@Injectable({
    providedIn: 'root'
})
export class PinItemsService {
    private readonly PIN_ITEM = 'pin';
    private readonly UNPIN_ITEM = 'unpin';

    constructor(
        private readonly restClient: RestClient
    ) {
    }

    changeItemPinned(iri: string, pin = true): Observable<unknown> {
        const {endpoint, id} = this.restClient.parseIri(iri),
            action = pin ? this.PIN_ITEM : this.UNPIN_ITEM;

        return this.restClient.endpoint<string>(`${endpoint}/${action}`)
            .update(id, {});
    }
}
