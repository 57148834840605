import {Injector} from '@angular/core';
import {TimeTrackerService} from 'src/modules/time-tracker/time-tracker.service';
import {Observable} from 'rxjs';
import {ITrackedEntry} from 'src/modules/time-tracker/interfaces';
import {AbstractTimeTrackerOperation, TimeTrackerCommonOperations} from './abstract-time-tracker.operation';
import {IOperationResult} from 'src/modules/operations/interfaces';

export class StartTimeTracker extends AbstractTimeTrackerOperation<ITrackedEntry> {
    readonly endpoint = 'time_tracker_subjects'

    readonly name = 'start_time_tracker';
    icon = 'fa-play';

    lang = 'COMMON_START';

    access = (context: ITrackedEntry, injector: Injector): boolean => {
        return this.hasPrivilegesToTimeTrackerFeature(injector)
            && injector.get(TimeTrackerService).activeEntry?.iri !== this.getIri(context);
    };

    invoke = (
        context: ITrackedEntry,
        injector: Injector
    ): Observable<IOperationResult> => {
        return TimeTrackerCommonOperations.startTrackerRequest(context, injector);
    };
}


export class StartNoteTracker extends AbstractTimeTrackerOperation<ITrackedEntry> {
    readonly endpoint = 'time_tracker_note'
    readonly name = 'add_new';
    lang = 'TASKS_ADD_TASK';
    icon = 'fa-plus';

    invoke(
        context: undefined,
        injector: Injector
    ): Observable<IOperationResult> {
        return TimeTrackerCommonOperations.startTrackerRequest(context, injector);
    }

    access = (context: ITrackedEntry, injector: Injector): boolean => {
        const activeEntry = injector.get(TimeTrackerService).activeEntry;

        return this.hasPrivilegesToTimeTrackerFeature(injector)
            && (typeof activeEntry === 'undefined' || activeEntry.iri !== this.getIri(context));
    }
}

export class StopNoteTracker extends AbstractTimeTrackerOperation<ITrackedEntry> {
    readonly endpoint = 'time_tracker_note'
    readonly name = 'stop';
    icon = 'fa-stop';
    color = 'danger';
    lang = 'COMMON_STOP';

    invoke(
        context: ITrackedEntry,
        injector: Injector
    ): Observable<IOperationResult> {
        return TimeTrackerCommonOperations.executeStopOperation(context, injector);
    }

    access = (context: ITrackedEntry, injector: Injector): boolean => {
        return this.hasPrivilegesToTimeTrackerFeature(injector)
            && typeof context !== 'undefined'
            && (context.start !== null || context.userTimeLoggedTotal !== 0);
    };
}


export class PauseTracker extends AbstractTimeTrackerOperation {
    readonly endpoint = 'time_tracker_note'
    readonly name = 'pause';
    icon = 'fa-pause';

    lang = 'COMMON_PAUSE';

    invoke(
        context: ITrackedEntry,
        injector: Injector
    ): Observable<IOperationResult> {
        return TimeTrackerCommonOperations.pauseTrackerRequest(context, injector);
    }

    access = (context: ITrackedEntry, injector: Injector): boolean => {
        const activeEntry = injector.get(TimeTrackerService).activeEntry;

        return this.hasPrivilegesToTimeTrackerFeature(injector)
            && (typeof activeEntry !== 'undefined' && activeEntry.iri === this.getIri(context));
    };
}

export const timeTrackerOperations = [
    StartTimeTracker,
    StartNoteTracker,
    StopNoteTracker,
    PauseTracker,
] as const;
