import {
    Component, EventEmitter,
    Input, OnChanges,
    OnDestroy, Output,
    Renderer2, SimpleChanges,
} from '@angular/core';
import {
    IDynamicTable,
    IDynamicTableColumn,
    IQuickFilterDefinition,
    ISelectedFilter
} from 'src/modules/dynamic-table/interfaces';
import {Comparison} from 'src/services/comparison';
import {DynamicTableFilter} from 'src/modules/dynamic-table/dynamic-table-filters/dynamic-table-filter';
import {combineLatestWith, skip, take, takeUntil} from 'rxjs/operators';
import {ITableTools, TtFilter} from 'angular-bootstrap4-table-tools';
import {merge, Subject} from 'rxjs';
import {FormControl, FormGroup} from '@angular/forms';
import {
    DragEndEvent,
    DragOverEvent,
    DragStartEvent,
    SortableObject
} from 'src/modules/sortable/sortable.directive';
import {StandardAnimationSortableService} from 'src/modules/sortable/animations/standard-animation-sortable.service';
import {SortableItemDirective} from 'src/modules/sortable/sortable-item.directive';
import {DynamicTableService} from 'src/modules/dynamic-table/dynamic-table.service';
import {ITableConfigOutputDto} from '_types/rest';
import {TranslateService} from 'src/modules/translate/translate.service';
import {ConfirmModalService} from 'src/modules/global-components/confirm-modal/confirm-modal.service';
import {LegendService} from 'src/modules/legend/legend.service';

interface IQuickFilterRemoveFnParams {
    fieldsToReset: string[];
}

@Component({
    selector: 'dynamic-table-config',
    templateUrl: './dynamic-table-config.component.html',
    styleUrls: ['./dynamic-table-config.component.scss']
})
export class DynamicTableConfigComponent<T extends object> implements OnChanges, OnDestroy {

    @Input() tableConfig: IDynamicTable<T> & ITableTools<T>;
    @Output() readonly savedSettings? = new EventEmitter<IDynamicTableColumn<T>[]>();

    quickFiltersDropdown: boolean;
    quickFilterModel = false;
    settingsModal: boolean;
    columnSettings: (IDynamicTableColumn<T> & SortableObject)[] = [];
    columnSettingsForm = new FormGroup({
        columnsControl: new FormControl(),
    });

    templateSettingsModal: boolean;
    templateSettingsForm = new FormGroup({
        configNameControl: new FormControl(),
    });

    configTemplatesDropdown: boolean;
    alreadyExistedConfig: ITableConfigOutputDto;

    isLegendAvailable = false;

    readonly MAX_TEMPLATE_SETTING_NAME = 40;

    private _destroy$ = new Subject<void>();

    constructor(
        private readonly renderer: Renderer2,
        private readonly sortableAnimationService: StandardAnimationSortableService,
        private readonly dynamicTableService: DynamicTableService,
        private readonly translateService: TranslateService,
        private readonly confirmModal: ConfirmModalService,
        readonly legendService: LegendService
    ) {
        this.isLegendAvailable = legendService.isLegendAvailable();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (!changes.tableConfig) {
            return;
        }

        this.removeActiveOnSettingsChange();
    }

    private removeActiveOnSettingsChange(): void {
        this.tableConfig.configured$
            .pipe(
                take(1),
                combineLatestWith(
                    this.tableConfig.filters.valueChanges,
                    this.tableConfig.orderChanges
                ),
                takeUntil(this._destroy$),
                skip(1) // Skip first change in filters to preserve activeSettingName from localstorage.
            )
            .subscribe(() => {
                this.tableConfig.activeSettingName = null;
            });
    }

    // region quick-filters
    setQuickFilter(quickFilterDefinition: IQuickFilterDefinition): void {
        const id = `qf_${quickFilterDefinition.name}`;

        if (this.tableConfig.selectedFilters.find(Comparison.criteria({id}))) {
            return;
        }

        this.removeSelectedQuickFiltersOverlappingWithNewQuickFilter(quickFilterDefinition);

        const qfDestroy = new Subject<void>(),
            selectedFilter = this._createSelectedQuickFilter(id, quickFilterDefinition, qfDestroy),
            filters = Object.entries(this.tableConfig.filters.controls);

        Object.entries(quickFilterDefinition.fields)
            .forEach(([field, value]) => {
                const findResult = filters.find(([, filter]) => {
                    return filter.filterOptions.field === field;
                });

                if (!findResult) {
                    this.tableConfig.filters.addControl(`qf_${field}`, new TtFilter({field}, value));
                    return;
                }

                const [fieldName, filter] = findResult;
                filter.setValue(value);

                if (fieldName !== `qf_${field}`) { // there is another filter that overlaps with our quick filter
                    // if the overlapping filter changes, remove our quick filter as its no longer valid
                    const subscription = filter.valueChanges
                        .pipe(
                            takeUntil(
                                merge(this._destroy$, qfDestroy)
                            )
                        )
                        .subscribe((newValue) => {
                            if (newValue !== value) {
                                subscription.unsubscribe();

                                const selectedFilter = this.tableConfig.selectedFilters.find(
                                    Comparison.criteria({id})
                                );

                                if (selectedFilter) { // in some cases filter may be already removed
                                    const fieldsToReset = Object.keys(quickFilterDefinition.fields)
                                        .filter((field) => {
                                            // ignore current field as it's already changed
                                            return field !== fieldName;
                                        });
                                    selectedFilter.removeFn({fieldsToReset});
                                }
                            }
                        });
                }
            });

        this.tableConfig.selectedFilters.push(selectedFilter);
    }

    private removeSelectedQuickFiltersOverlappingWithNewQuickFilter(
        newQuickFilterDefinition: IQuickFilterDefinition
    ): void {
        this.tableConfig.selectedFilters.forEach((selectedFilter) => {
            const isQuickFilter = selectedFilter.id.includes('qf_');
            if (!isQuickFilter) {
                return;
            }

            const selectedQuickFilterDefinition = this.tableConfig.quickFilters.find((item) => {
                    return selectedFilter.id === `qf_${item.name}`;
                }),
                selectedQuickFilterDefinitionFields = Object.keys(selectedQuickFilterDefinition.fields),
                shouldBeRemoved = !!selectedQuickFilterDefinitionFields.find((field) => {
                    return field in newQuickFilterDefinition.fields;
                });

            if (!shouldBeRemoved) {
                return;
            }

            const fieldsToReset = selectedQuickFilterDefinitionFields.filter((field) => {
                return !(field in newQuickFilterDefinition.fields);
            });
            selectedFilter.removeFn({fieldsToReset});
        });
    }

    changeSwitchFilter(quickFilter: IQuickFilterDefinition): void {
        const filterId = `qf_${quickFilter.name}`,
            selectedFilter = this.tableConfig.selectedFilters.find(({id}) => id === filterId);

        this.quickFilterModel ? this.setQuickFilter(quickFilter) : selectedFilter.removeFn();
    }

    private _createSelectedQuickFilter(
        id: string,
        quickFilterDefinition: IQuickFilterDefinition,
        destroySubject: Subject<void>
    ): ISelectedFilter {
        return {
            id,
            label: this.translateService.get(quickFilterDefinition.name),
            removeFn: (params?: IQuickFilterRemoveFnParams) => {
                this.quickFilterModel = false;

                destroySubject.next();
                this.tableConfig.selectedFilters.splice(
                    this.tableConfig.selectedFilters.findIndex(Comparison.criteria({id})),
                    1
                );

                const filters = Object.values(this.tableConfig.filters.controls);

                const fieldsToReset = params && 'fieldsToReset' in params
                    ? params.fieldsToReset
                    : Object.keys(quickFilterDefinition.fields);

                fieldsToReset
                    .forEach((field) => {
                        const filter = filters.find((filter: DynamicTableFilter) => {
                            return filter.filterOptions.field === field;
                        });
                        if (filter) {
                            filter.setValue(null);
                        }
                    });

                this.resetDefaultFiltersValue();
            },
            get removable() {
                return quickFilterDefinition.removable !== false;
            }
        };
    }

    private resetDefaultFiltersValue(): void {
        if (!this.tableConfig.filters) {
            return;
        }

        Object.entries(this.tableConfig.filters.controls).forEach(([fieldName, control]) => {
            if (!control?.fieldConfig?.default) {
                return;
            }
            this.tableConfig.filters.controls[fieldName].setValue(control.fieldConfig.default);
        });
    }

    // endregion quick-filters

    // region settings-templates
    selectSettings(template: ITableConfigOutputDto): void {
        this.dynamicTableService.restoreTableSettings(this.tableConfig, template.settingName)
            .subscribe();
        this.tableConfig.activeSettingName = template.settingName || null;
        this.configTemplatesDropdown = false;
    }

    openSettingsModal(): void {
        this.settingsModal = true;
        this.columnSettings = this.tableConfig.columns.map((c, idx) => {
            return Object.assign({order: idx}, c);
        });
        this.columnSettingsForm.get('columnsControl').setValue(
            this.columnSettings.filter((column) => !column.hidden)
        );
    }

    saveColumns(): void {
        this.columnSettings.forEach((column) => {
            column.hidden = !this.columnSettingsForm.get('columnsControl').value.includes(column);
        });
        this.tableConfig.columns = this.columnSettings;

        this.savedSettings.emit(this.columnSettings);
        this.tableConfig.activeSettingName = null;

        this.settingsModal = false;
    }

    openTemplateSettingsModal(): void {
        this.templateSettingsModal = true;
        this.templateSettingsForm.reset();

        this.templateSettingsForm.get('configNameControl').valueChanges
            .pipe(takeUntil(this.savedSettings))
            .subscribe((value) => {
                this.alreadyExistedConfig = this.getConfigTemplates().find(
                    Comparison.criteria({settingName: value})
                );
            });
    }

    private getConfigTemplates(): ITableConfigOutputDto[] {
        return this.tableConfig.settingTemplates || [];
    }

    saveCurrentSettings(configName?: string): void {
        if (typeof configName === 'undefined') {
            configName = this.templateSettingsForm.get('configNameControl').value;
        }
        this.dynamicTableService.saveTableSettings(this.tableConfig, configName)
            .subscribe(() => {
                const configFound = this.getConfigTemplates().find(
                    Comparison.criteria({settingName: configName})
                );
                if (configFound) {
                    this.tableConfig.activeSettingName = configName;
                }

                this.templateSettingsForm.reset();
                this.alreadyExistedConfig = null;
                this.templateSettingsModal = false;
            });
    }

    removeSetting(config: ITableConfigOutputDto): void {
        this.confirmModal.confirm(
            'TABLE_SETTINGS_REMOVE_TEMPLATE',
            () => {
                this.dynamicTableService.removeTableSettings(config)
                    .pipe(takeUntil(this._destroy$))
                    .subscribe(() => {
                        const settingIndex = this.getConfigTemplates().findIndex(
                            Comparison.criteria({settingName: config.settingName})
                        );
                        this.tableConfig.settingTemplates.splice(settingIndex, 1);
                        this.selectSettings(this.tableConfig.settingTemplates[0]);
                    });
            },
            {
                primaryBtn: 'danger'
            }
        );
    }

    // endregion settings-templates

    // region column-settings-sortable
    onDragOver($event: DragOverEvent): void {
        this.sortableAnimationService.onDragOver(
            $event.dragItem, $event.targetItem, this._sortableElementGetter.bind(this)
        );
    }

    onDragStart($event: DragStartEvent): void {
        const customControlElement = this._sortableElementGetter($event.dragItem);

        $event.dataTransfer.setDragImage(
            customControlElement,
            customControlElement.offsetWidth,
            customControlElement.offsetHeight / 2
        );
    }

    onDragEnd($event: DragEndEvent): void {
        this.sortableAnimationService.onDragEnd(
            $event.baseDragItem, $event.dragItem, this._sortableElementGetter.bind(this)
        );
    }

    private _sortableElementGetter(sortableItem: SortableItemDirective): HTMLElement {
        return sortableItem.element.parentElement.parentElement;
    }

    // endregion column-settings-sortable

    ngOnDestroy(): void {
        this._destroy$.next();
        this._destroy$.complete();
    }
}
