export interface IHydraResponse<T> {
    '@context': string,
    '@id': string,
    '@type': 'hydra:Collection',
    'hydra:member': T[],
    'hydra:totalItems': number,
    'hydra:view'?: {
        '@id': string,
        '@type': 'hydra:PartialCollectionView',
        'hydra:first': string,
        'hydra:last'?: string,
        'hydra:next'?: string,
        'hydra:previous'?: string
    },
    'hydra:search': {
        '@type': 'hydra:IriTemplate',
        'hydra:template': string,
        'hydra:variableRepresentation': 'BasicRepresentation',
        'hydra:mapping': {
            '@type': 'IriTemplateMapping',
            variable: string,
            property: string,
            required: boolean,
            filter: string
        }[]
    },
    'hydra:itemsPerPage': number,
    'hydra:collectionOperation': Record<string, true>,
    '@const': Record<string, IHydraConstValue[]>
}

export interface IHydraConstValue {
    id: number,
    lang: string
}

export interface IParsedHydra {
    isFirst: boolean,
    isLast: boolean,
    totalItems: number,
    perPage: number,
    order: string[],
    search: string[],
    const: Record<string, IHydraConstValue[]>,
    operations: { [operation: string]: true }
}

/**
 * Parse ld+json & hydra response
 */
export function parseHydra(responseData: IHydraResponse<unknown>): IParsedHydra {
    const hydra: IParsedHydra = {
        isFirst: true,
        isLast: true,
        totalItems: responseData['hydra:totalItems'],
        perPage: responseData['hydra:itemsPerPage'],
        order: [],
        search: [],
        const: {},
        operations: responseData['hydra:collectionOperation']
    };

    if (typeof responseData['hydra:view'] !== 'undefined') {
        hydra.isFirst = responseData['hydra:view']['hydra:first'] === responseData['hydra:view']['@id'];
        if (typeof responseData['hydra:view']['hydra:last'] !== 'undefined') {
            hydra.isLast = responseData['hydra:view']['hydra:last'] === responseData['hydra:view']['@id'];
        }
    }
    if (typeof responseData['hydra:search'] !== 'undefined') {
        responseData['hydra:search']['hydra:mapping'].forEach((mapping) => {
            if (mapping['filter'] === 'OrderFilter') {
                if (!hydra.order.includes(mapping.property)) {
                    hydra.order.push(mapping.property);
                }
            }
            if (['SearchFilter', 'OrSearchFilter'].includes(mapping['filter'])) {
                if (!hydra.search.includes(mapping.property)) {
                    hydra.search.push(mapping.property ?? mapping.variable);
                }
            }
        });
    }
    if (typeof responseData['@const'] !== 'undefined') {
        hydra.const = responseData['@const'];
    }
    return hydra;
}
