import {InjectionToken} from '@angular/core';
import {BroadcastService, IBroadcastChannel} from 'src/services/broadcast.service';
import {INotificationToastOutputDto} from '_types/rest';

export const NOTIFICATIONS_CHANNEL = new InjectionToken<BroadcastService<INotificationsBroadcast>>
('notificationsBroadcastService', {
    providedIn: 'root',
    factory: () => {
        return new BroadcastService('notifications');
    },
});

export const NotificationsBroadcastServiceProvider = {
    provide: BroadcastService,
    useFactory: (): BroadcastService<INotificationsBroadcast> => {
        return new BroadcastService('notifications');
    }
};


export interface INewNotifyBroadcast extends IBroadcastChannel {
    type: 'notifications',
    types: 'new_notify' | 'toggle_read_state',
    payload:  INotificationToastOutputDto
}

export interface ISetReadAllBroadcast extends IBroadcastChannel {
    type: 'notifications',
    types: 'set_read_all',
    payload: never
}

export type INotificationsBroadcast = INewNotifyBroadcast | ISetReadAllBroadcast
