import {EndpointName, EndpointToType} from '_types/rest';
import {Use} from 'src/decorators/use.decorator';
import {AbstractRestCommon} from 'src/modules/rest/objects/abstract-rest-common';
import {IRestObject, RestObject} from 'src/modules/rest/objects/rest-object';
import {Comparison} from 'src/services/comparison';
import {IHydraResponse, IParsedHydra, parseHydra} from '../hydra';
import {Utils} from 'src/services/utils';

export type TreeBranch<T, U extends string = 'children'> = (T & {
    [P in U]?: TreeBranch<T, U>[]
});

export type Tree<T, U extends string = 'children'> = TreeBranch<T, U>[];

const endpointMap = new WeakMap(),
    hydraMap = new WeakMap<object, IParsedHydra>();

export interface RestCollection<TEndpoint extends EndpointName,
    TRestObject = IRestObject<TEndpoint, EndpointToType<TEndpoint, true>>>
    extends AbstractRestCommon<TEndpoint>, Array<TRestObject> {
}

@Use(AbstractRestCommon)
export class RestCollection<TEndpoint extends EndpointName,
    TRestObject = IRestObject<TEndpoint, EndpointToType<TEndpoint, true>>>
    extends Array<TRestObject> {

    private constructor(endpoint: TEndpoint, ...items: TRestObject[]) {
        super(...items);
        endpointMap.set(this, endpoint);
    }

    static fromHydra<T extends EndpointName, TCollection = EndpointToType<T, true>>(
        endpoint: T,
        hydra: IHydraResponse<TCollection>
    ): RestCollection<T, IRestObject<T, TCollection>> {
        const instance = RestCollection.getInstance(endpoint, ...hydra['hydra:member']);
        hydraMap.set(instance, parseHydra(hydra));

        return instance;
    }

    static getInstance<T extends EndpointName, TCollection = EndpointToType<T, true>>(
        endpoint: T,
        ...items: TCollection[]
    ): RestCollection<T, IRestObject<T, TCollection>> {
        return new RestCollection<T, IRestObject<T, TCollection>>(
            endpoint,
            ...(items.map((x) => RestObject.getInstance(endpoint, x)))
        );
    }

    endpointName(): TEndpoint {
        return endpointMap.get(this);
    }

    /**
     * Get parsed hydra response
     */
    hydra(): IParsedHydra | undefined {
        return hydraMap.get(this);
    }

    clone(): RestCollection<TEndpoint, TRestObject> {
        return this._clone(this);
    }

    private _clone<U>(items: U[]): RestCollection<TEndpoint, U> {
        const clone = new RestCollection<TEndpoint, U>(this.endpointName()),
            hydra = this.hydra();

        if (hydra) {
            hydraMap.set(clone, hydra);
        }

        items.forEach((item) => {
            clone.push(Utils.clone(item));
        });

        return clone;
    }

    filter(
        predicate: (value: TRestObject, index: number, array: RestCollection<TEndpoint, TRestObject>) => unknown,
        thisArg?: unknown
    ): RestCollection<TEndpoint, TRestObject> {
        return this._clone(
            super.filter(predicate, thisArg)
        );
    }

    map<U>(
        callbackfn: (value: TRestObject, index: number, array: RestCollection<TEndpoint, TRestObject>) => U,
        thisArg?: unknown
    ): RestCollection<TEndpoint, U> {
        return this._clone(
            super.map<U>(callbackfn, thisArg)
        );
    }

    /**
     * Build a tree from an array of self-referencing records
     * If an item referenceColumn property is an array it will be assigned to many parents.
     */
    buildTree(referenceColumn: string, comparisonKey = '@id'): Tree<TRestObject> {
        const tree: Tree<TRestObject> = [],
            items: Tree<TRestObject> = this.clone();
        items.forEach((item) => {
            const parentIds = Array.isArray(item[referenceColumn])
                ? item[referenceColumn] : [item[referenceColumn]];
            parentIds.forEach((parentId) => {
                if (typeof parentId === 'object' && parentId !== null) {
                    parentId = parentId[comparisonKey];
                }
                let parent;
                if (parentId === null) {
                    parent = tree;
                } else {
                    parent = items.find(Comparison.criteria({[comparisonKey]: parentId}));
                    if (!parent) {
                        throw new Error(
                            `Item ${item[comparisonKey]} is an orphan!`
                            + `Could not find it's parent - ${item[referenceColumn]}`
                        );
                    }
                    if (typeof parent.children === 'undefined') {
                        parent.children = [];
                    }
                    parent = parent.children;
                }
                parent.push(item);
            });
            if (!parentIds.length) {
                tree.push(item);
            }
        });
        return tree;
    }

    access(operation: string): boolean {
        const hydra = this.hydra();
        return typeof hydra !== 'undefined'
            && typeof hydra.operations !== 'undefined'
            && typeof hydra.operations[operation] !== 'undefined';
    }
}
