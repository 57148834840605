import {ElementRef, Injectable} from '@angular/core';
import {LocalStorage} from 'src/services/local-storage';

export type LocatorMode = 'DISABLED' | 'ENABLED' | 'DEBUG';

export interface ILocatorOptions {
    root?: boolean,
    locator?: string
}

export interface LocatorElement extends HTMLElement {
    __locatorData?: {
        root: boolean,
        id: string
    }
}

@Injectable({
    providedIn: 'root'
})
export class LocatorsService {
    static readonly LOCAL_STORAGE_KEY = 'locators-mode';

    static get mode(): LocatorMode {
        return LocatorsService._mode || LocalStorage.get(LocatorsService.LOCAL_STORAGE_KEY, 'DISABLED');
    }

    private static _mode: LocatorMode;

    private readonly LOCATOR_ATTRIBUTE = 'id';

    addElementLocator(
        elementRef: ElementRef<LocatorElement>,
        options: ILocatorOptions = {}
    ): void {
        if (
            LocatorsService.mode === 'DISABLED'
            || elementRef.nativeElement.hasAttribute(this.LOCATOR_ATTRIBUTE)
        ) {
            return;
        }

        elementRef.nativeElement.__locatorData = {
            root: !!options.root,
            id: this.getLocatorForElement(elementRef.nativeElement, options)
        };

        elementRef.nativeElement.setAttribute(this.LOCATOR_ATTRIBUTE, elementRef.nativeElement.__locatorData.id);
        elementRef.nativeElement.setAttribute('locator-selector', '');

        if (LocatorsService.mode === 'DEBUG') {
            // eslint-disable-next-line no-console
            console.info(
                `Added locator '${elementRef.nativeElement.__locatorData.id}' to`, elementRef.nativeElement
            );
        }
    }

    private getLocatorForElement(element: LocatorElement, options: ILocatorOptions): string {
        let id = options.locator || element.tagName.toLowerCase();

        if (!options.root) {
            const parent = this.getParentLocator(element);

            if (parent) {
                id = parent.__locatorData.id + '_' + id;
            }
        }

        return id;
    }

    private getParentLocator(element: LocatorElement): LocatorElement | null {
        return element.closest<LocatorElement>('[locator-selector]');
    }
}
