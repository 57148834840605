import {IQueryObject, UrlParserService} from 'src/services/url-parser.service';
import {Observable} from 'rxjs';
import {RestClientConfig} from 'src/modules/rest/rest-client-config.service';
import {HttpClient, HttpContext, HttpEvent, HttpEventType, HttpProgressEvent, HttpResponse} from '@angular/common/http';
import {EndpointName, EndpointToType, IFile} from '_types/rest';
import {REST_REQUEST_CONTEXT} from 'src/modules/rest/rest-http-interceptor.service';
import {IBaseRestEntity} from '_types/rest/IBaseRestEntity';
import {Utils} from 'src/services/utils';
import {EndpointConstName, EndpointToConst} from '_types/rest/endpoint-to-type';
import {last, map, tap} from 'rxjs/operators';
import {EntitySchemaFields, IRestCollection, IRestObject, IRestSchema} from './objects';
import {RestObject} from 'src/modules/rest/objects/rest-object';
import {RestCollection} from 'src/modules/rest/objects/rest-collection';
import {HttpMethod} from 'src/modules/rest/interfaces';

interface IRequestConfig<T = unknown> {
    id?: number | string,
    body?: T,
    query?: IQueryObject,
    reportProgress?: boolean
}

export class RestEndpoint<TEndpoint extends EndpointName,
    TObject = EndpointToType<TEndpoint>,
    TCollection = EndpointToType<TEndpoint, true>,
    TRestObject = TObject extends IBaseRestEntity ? IRestObject<TEndpoint, TObject> : TObject,
    TRestCollection = TCollection extends IBaseRestEntity
        ? IRestCollection<TEndpoint, IRestObject<TEndpoint, TCollection>> : TCollection> {

    get name(): TEndpoint {
        return this._endpoint;
    }

    private restClientConfig: RestClientConfig;
    private http: HttpClient;
    private urlParser: UrlParserService;

    constructor(
        private _endpoint: TEndpoint
    ) {
        this.restClientConfig = RestClientConfig.injector.get(RestClientConfig);
        this.http = RestClientConfig.injector.get(HttpClient);
        this.urlParser = RestClientConfig.injector.get(UrlParserService);
    }

    getIri(id: number): string {
        return this.restClientConfig.path + this._endpoint + '/' + id;
    }

    createObject(value: TObject): IRestObject<TEndpoint, TObject> {
        return RestObject.getInstance(this._endpoint, value);
    }

    createCollection(...values: TCollection[]): IRestCollection<TEndpoint, IRestObject<TEndpoint, TCollection>> {
        return RestCollection.getInstance(this._endpoint, ...values);
    }

    get(
        id: number | string,
        query?: IQueryObject
    ): Observable<TRestObject> {
        return this._request('GET', {id, query});
    }

    getAll(
        query?: IQueryObject
    ): Observable<TRestCollection> {
        return this._request('GET', {query});
    }

    getConst(): Observable<TEndpoint extends EndpointConstName ? EndpointToConst<TEndpoint> : unknown> {
        return this._request('GET', {id: 'const'});
    }

    /**
     * Get EntitySchema for current endpoint
     * Can be used with dynamicFields component
     * @see dynamicFields
     * @param [withProperties=true] - return entity properties in schema?
     * @param [groups] - which entityField groups to return
     * @param [method] - REST method context - get/post/put
     * @param [id] - id for method=put
     */
    getSchema(
        withProperties?: boolean, groups?: string | string[] | boolean, method?: 'get' | 'post' | 'put', id?: number
    ): Observable<IRestSchema<TEndpoint, EntitySchemaFields<keyof TObject>>> {
        const query = {
            properties: withProperties,
            method: method,
            id,
            group: undefined
        };

        if (typeof groups !== 'undefined') {
            query.group = groups;
        }

        return this._request('GET', {
            id: 'schema',
            query
        });
    }

    create(
        body: TObject,
        query?: IQueryObject
    ): Observable<TRestObject> {
        return this._request('POST', {body, query});
    }

    update(
        id: number | string,
        body: TObject,
        query?: IQueryObject
    ): Observable<TRestObject> {
        if (typeof body['modified'] !== 'undefined') {
            body = Utils.clone(body);
        }

        return this._request('PUT', {id, body, query});
    }

    delete(
        id: number | string
    ): Observable<void> {
        return this._request('DELETE', {id});
    }

    upload(
        file: File,
        formData: {
            tableName: string,
            entityFieldKey?: string,
            entityFieldName?: string
        },
        progressHandler: (event: HttpProgressEvent) => void
    ): Observable<IFile> {
        const body = new FormData();
        body.append('file', file);
        Object.entries(formData).forEach(([k, v]) => {
            body.append(k, v);
        });

        return this._request<FormData, HttpEvent<unknown>>('POST', {
            body,
            reportProgress: true
        })
            .pipe(
                tap((event) => {
                    if (event.type === HttpEventType.UploadProgress) {
                        progressHandler(event);
                    }
                }),
                last(),
                map((event: HttpResponse<IFile>) => event.body)
            );
    }

    private _request<T, R = T>(
        method: HttpMethod,
        config: IRequestConfig<T>
    ) {
        const headers: {
            [header: string]: string | string[];
        } = {
            Accept: 'application/ld+json'
        };

        if (!this.restClientConfig.isPublicEndpoint(this._endpoint, method)) {
            const authorization = RestClientConfig.getAuthorizationHeader();
            if (authorization) {
                headers.Authorization = authorization;
            }
        }

        return this.http.request<R>(
            method,
            this._getRequestUrl(config),
            {
                body: config.body,
                headers,
                context: new HttpContext().set(
                    REST_REQUEST_CONTEXT,
                    {
                        endpoint: this._endpoint,
                        id: config.id,
                        query: config.query
                    }
                ),
                reportProgress: !!config.reportProgress,
                observe: config.reportProgress ? 'events' : 'body'
            } as unknown
        );
    }

    private _getRequestUrl(config: IRequestConfig): string {
        return this.restClientConfig.getApiUrl(!this._endpoint.startsWith('pub/'))
            + this._endpoint
            + (config.id ? `/${config.id}` : '')
            + this.urlParser.buildQuery(config.query, this._endpoint);
    }
}
