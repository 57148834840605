import {AbstractOperationDefinition} from 'src/modules/operations/interfaces';

export class JsonPreviewOperation
    extends AbstractOperationDefinition<string> {
    readonly endpoint = 'generic';
    readonly name = 'json-preview';
    lang = 'VIEW';
    icon = 'fa-eye';

    component
        = (): Promise<unknown> => import('./json-preview/json-preview.component');

    access = (): boolean => true;
}

export const genericOperations = [
    JsonPreviewOperation
] as const;
