<div class="card-body ml-0 pl-0" *ngIf="task.id">
    <div class="row">
        <div class="d-none d-sm-block col-12 task-iconic-col mb-2">
            <avatar [user]="user" class="avatar-sm"></avatar>
        </div>
        <div class="col-12">
            <ng-form class="form" #noteForm="ngForm">
                <div class="task-note-textarea"
                     [ngClass]="{'p-0': taskHasActiveTracker}">
                    <div class="row">
                        <div class="col-12">
                            <drop-zone (fileUploaded)="fileDropped($event)"
                                       [settings]="{transclude: true, pasteScreenshotOnFocus: true}">
                                <wysiwyg-editor [(ngModel)]="note.text"
                                                name="text"
                                                [height]="80"
                                                viewType="no-border"
                                                (metadataValue)="handleMentionMetadata($event.mentions)"
                                                required>
                                </wysiwyg-editor>
                            </drop-zone>
                        </div>
                    </div>
                    <ng-container *ngIf="!taskHasActiveTracker">
                        <div class="row mt-3 mb-3">
                            <div class="col">
                                <file-list [files]="files"
                                           (fileDelete)="removeFile($event)">
                                </file-list>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-auto mt-3">
                                <dynamic-field class="form-group"
                                               *ngIf="user.cooperationNoteEnabled"
                                               [ngModelOptions]="{standalone: true}"
                                               [disabled]="!noteForm.valid"
                                               [(ngModel)]="addNote"
                                               [fieldConfig]="{
                                                 type: 'checkbox',
                                                 viewType: 'switch',
                                                 name: 'COMMON_ADD_AS_NOTE'}">
                                </dynamic-field>
                            </div>
                            <div class="col mt-2 text-right" no-animate>
                                <drop-zone *ngIf="taskRest | accessTask: 'put_item': 'taskFiles'"
                                        (fileUploaded)="fileDropped($event)"
                                           [settings]="{transcludeButton: true}">
                                    <a href="javascript:" class="btn btn-default">
                                        <i class="fas fa-paperclip"></i>
                                        {{ 'COMMON_ATTACH_FILE' | translate }}
                                    </a>
                                </drop-zone>
                                <a *ngIf="note.id" href="javascript:" class="btn btn-default" (click)="initNote()">
                                    <i class="fas fa-times"></i>
                                    {{ 'CANCEL' | translate }}
                                </a>
                                <button class="btn btn-primary" (click)="saveNote()" [disabled]="noteSaving">
                                <span *ngIf="noteSaving" class="fas fa-circle-notch fa-spin"
                                      [attr.aria-label]="'SAVING' | translate" [title]="'SAVING' | translate"></span>
                                    <span *ngIf="!note.id">{{ 'COMMON_ADD_COMMENT' | translate }}</span>
                                    <span *ngIf="note.id">{{ 'SAVE' | translate }}</span>
                                </button>
                            </div>
                        </div>
                    </ng-container>
                </div>
            </ng-form>
        </div>
    </div>

    <div *ngIf="taskHasActiveTracker"
         class="text-info mt-2">
        <i class="fa fa-info-circle" aria-hidden="true"></i> {{'TASK_NOTE_REMOVE_TRACKERS_FIRST' | translate}}
    </div>
</div>
