import {Component, DoCheck, Input, OnInit} from '@angular/core';
import {StateParams, StateService, UIRouter} from '@uirouter/core';
import {UserService} from 'src/modules/rest/user/user.service';
import {RouteHelper} from 'src/modules/route-helper/route-helper.service';
import {RouteTreeBranch} from 'src/modules/route-helper/interfaces';
import {BehaviorSubject, Observable} from 'rxjs';
import {ITabElement} from 'src/modules/nav-tabs/nav-tab/nav-tab.component';

@Component({
    selector: 'sub-menu-layout',
    templateUrl: './sub-menu-layout.component.html',
    styleUrls: ['./sub-menu-layout.component.scss']
})
export class SubMenuLayoutComponent implements OnInit, DoCheck {
    @Input() parentState: string;
    @Input() states: RouteTreeBranch[];
    @Input() excludedRoutes?: (ITabElement['id'])[];
    @Input() additionalLinks: string;
    @Input() ignoreNesting = false;

    tabs: ITabElement[];
    nestLevel = 0;
    stateParams: StateParams;
    activeTabSubject: BehaviorSubject<string>;
    activateTab$: Observable<string>;
    private currentState: string;

    constructor(
        private routeHelper: RouteHelper,
        private user: UserService,
        private stateService: StateService,
        private uiRouter: UIRouter
    ) {
        this.stateParams = uiRouter.globals.params;
        this.activeTabSubject = new BehaviorSubject<string>(this.uiRouter.globals.current.name);
        this.activateTab$ = this.activeTabSubject.asObservable();
    }

    ngOnInit(): void {
        this.tabs = (this.parentState ? this.getStates(this.parentState) : this.states).map((state) => {
            return {
                id: state.name,
                name: state.lang
            };
        });

        if (this.excludedRoutes) {
            this.tabs = this.tabs.filter((state) => {
                return !this.excludedRoutes.includes(state.id);
            });
        }
        this.updateCurrentTab();
    }

    ngDoCheck(): void {
        if (this.uiRouter.globals.current.name === this.currentState) {
            return;
        }
        this.currentState = this.uiRouter.globals.current.name;

        this.updateNestLevel();

        this.updateCurrentTab();
    }

    goBack(): void {
        this.stateService.go('^', this.uiRouter.globals.params);
    }

    private updateCurrentTab(): void {
        this.activeTabSubject.next(this.currentState);
    }

    private updateNestLevel(): void {
        if (!this.parentState) {
            this.nestLevel = 0;
        } else {
            this.nestLevel = this.currentState
                .substring(this.parentState.length)
                .split('.')
                .length - 1;
        }
    }

    private getStates(parentStateName: string): RouteTreeBranch[] {
        const states = this.routeHelper.getStatesTree(parentStateName);
        if (states !== false && states.subs) {
            return states.subs.filter((item) => {
                return this.user.hasPrivileges(item.name);
            });
        } else {
            return [];
        }
    }
}
