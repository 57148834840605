<ng-template #avatarTemplate>
    <div class="avatar bg-{{color}}"
         [ngStyle]="{'width.px': size, 'height.px': size}"
         [ngClass]="{
            'avatar-shadow': shadow
         }"
         noAnimate>

        <i *ngIf="!user; else userExists"
           class="fa fa-user-circle"
           [ngStyle]="{'font-size.px': size}">
        </i>

        <ng-template #userExists>
            <span *ngIf="initials; else fileAvatarTemplate"
                  [ngStyle]="{'font-size': (size / 2.5) + 'px', 'color': textColor}">
                {{ initials }}
            </span>
            <ng-template #fileAvatarTemplate>
                <img (error)="onAvatarLoadingError()"
                     [width]="size"
                     [src]="fileName"
                     alt="">
            </ng-template>
        </ng-template>
    </div>
</ng-template>

<avatar-details *ngIf="userDetails"
                [loadAsyncUserDetails]="loadAsyncUserDetails"
                [user]="user"
                [company]="company"
                [companyContactPhone]="companyContactPhone">
    <ng-container *ngTemplateOutlet="avatarTemplate"></ng-container>
</avatar-details>

<ng-container *ngIf="!userDetails">
    <ng-container *ngTemplateOutlet="avatarTemplate"></ng-container>
</ng-container>
