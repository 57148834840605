import {Pipe, PipeTransform} from '@angular/core';


@Pipe({name: 'splitText'})
export class SplitTextPipe implements PipeTransform {
    transform(
        input: string,
        charInsert: string,
        charCount: number
    ): string {
        const result = [];
        for (let i = 0, len = input.length; i < len; i += charCount) {
            result.push(input.substr(i, charCount));
        }

        return result.join(charInsert);
    }
}
