import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {ITableTools} from 'angular-bootstrap4-table-tools';
import {IDynamicTableOperation, IDynamicTable} from 'src/modules/dynamic-table/interfaces';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {IRestObject} from 'src/modules/rest/objects';

@Component({
    selector: 'dynamic-table-view',
    templateUrl: './dynamic-table-view.component.html',
    styleUrls: ['./dynamic-table-view.component.scss']
})
export class DynamicTableViewComponent<T extends object> implements OnInit, OnDestroy {

    @Input() tableConfig: ITableTools<T> & IDynamicTable<T>;

    hasFilters: boolean;
    collectionContext = new Map<IDynamicTableOperation<T[]>, unknown>();

    private _destroy$ = new Subject<void>();

    ngOnInit(): void {
        this.hasFilters = this.tableConfig.searchField
            || !!Object.keys(this.tableConfig.filters.controls).length;

        if (!this.tableConfig.collectionOperations) {
            return;
        }
        this.tableConfig.data
            .pipe(takeUntil(this._destroy$))
            .subscribe((value) => {
                this.tableConfig.collectionOperations.forEach((operation) => {
                    this.collectionContext.set(
                        operation,
                        operation.context
                            ? operation.context(value as T extends IRestObject<string> ? T[] : T[])
                            : value
                    );
                });
            });
    }

    ngOnDestroy(): void {
        this._destroy$.next();
        this._destroy$.complete();
    }
}
