/* eslint-disable max-len */
/**
 * This file has been auto generated. Do not edit.
 */
import {ITaskUser} from '_types/rest/Entity/IRestTaskUser';
import {IRestTaskUser} from '_types/rest/Entity/IRestTaskUser';
import {ITask} from '_types/rest/Entity/IRestTask';
import {IRestTask} from '_types/rest/Entity/IRestTask';
import {ITaskTemplateComponent} from '_types/rest/Entity/IRestTaskTemplateComponent';
import {IRestTaskTemplateComponent} from '_types/rest/Entity/IRestTaskTemplateComponent';
import {ITaskActionParameterValue} from '_types/rest/Entity/IRestTaskActionParameterValue';
import {IRestTaskActionParameterValue} from '_types/rest/Entity/IRestTaskActionParameterValue';
import {IBaseRestEntity} from '_types/rest/IBaseRestEntity';
import {IRestObject} from 'core/rest/rest-object.service';

export const TaskStateState = {
    TASK_STATE_NEW: 1,
    TASK_STATE_IN_PROGRESS: 2,
    TASK_STATE_COMPLETED: 3,
    TASK_STATE_REOPENED: 4
} as const;

export const TaskStateStateOptions = [
    {
        value: TaskStateState.TASK_STATE_NEW,
        label: 'TASK_STATE_NEW',
    },
    {
        value: TaskStateState.TASK_STATE_IN_PROGRESS,
        label: 'TASK_STATE_IN_PROGRESS',
    },
    {
        value: TaskStateState.TASK_STATE_COMPLETED,
        label: 'TASK_STATE_COMPLETED',
    },
    {
        value: TaskStateState.TASK_STATE_REOPENED,
        label: 'TASK_STATE_REOPENED',
    }
] as const;

export interface ITaskStateConst {
    state: [{id: 1, value: 'TASK_STATE_NEW'}, {id: 2, value: 'TASK_STATE_IN_PROGRESS'}, {id: 3, value: 'TASK_STATE_COMPLETED'}, {id: 4, value: 'TASK_STATE_REOPENED'}]
}

export interface ITaskState extends IBaseRestEntity {
    id?: number,
    /**
     * @default 1
     */
    state: typeof TaskStateState[keyof typeof TaskStateState],
    taskUser?: ITaskUser | string | null,
    task?: ITask | string | null,
    actionTaskTemplateComponent?: ITaskTemplateComponent | string | null,
    taskActionParameterValues?: (ITaskActionParameterValue | string)[],
    /**
     * @description Record status
     *  0 = deleted
     *  1 = active.
     * @default 1
     */
    status?: number,
    /**
     * @description Creation timestamp.
     * @default CURRENT_TIMESTAMP
     * @format date-time
     */
    created?: string,
    /**
     * @description Last modification timestamp.
     * @format date-time
     */
    modified?: string | null
}

/** @deprecated */
export interface IRestTaskState extends ITaskState, IRestObject {
    taskUser?: IRestTaskUser | string | null,
    task?: IRestTask | string | null,
    actionTaskTemplateComponent?: IRestTaskTemplateComponent | string | null,
    taskActionParameterValues?: IRestTaskActionParameterValue[] | string[],
}
