// eslint-disable-next-line no-restricted-imports
import 'core/vendor.scss';
import './vendor.scss';
// eslint-disable-next-line no-restricted-imports
import 'core/core.scss';
import {ErrorHandler, NgModule} from '@angular/core';
import {coreRoutes} from './core.routes';
import {AppErrorComponent} from './app-error/app-error.component';
import {AppComponentsModule} from 'src/components/app-components.module';
import {RestModule} from 'src/modules/rest/rest.module';
import {AppFormsModule} from 'src/modules/app-forms/app-forms.module';
import {BsModalModule} from 'angular-bootstrap4';
import {TranslateModule} from 'src/modules/translate/translate.module';
import {AppTableToolsModule} from 'src/modules/app-table-tools/app-table-tools.module';
import {AppCommonModule} from 'src/modules/app-common/app-common.module';
import {RouteModule} from 'src/modules/route-helper/route.module';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {BrowserModule, HammerModule} from '@angular/platform-browser';
import {AppCoreComponent} from 'view-modules/core/app-core/app-core.component';
import {ExceptionHandler} from 'src/modules/exception-handler/exception-handler';
import {ToastsModule} from 'src/modules/global-components/toasts/toasts.module';
import {CmsModalModule} from 'src/modules/global-components/cms-modal/cms-modal.module';
import {ConfirmModalModule} from 'src/modules/global-components/confirm-modal/confirm-modal.module';
import {AppCmsComponent} from 'view-modules/core/app-cms/app-cms.component';
import {APP_STATE_SERVICE_PROVIDER} from 'src/modules/route-helper/state-service.provider';
import {ReportsModule} from 'src/modules/reports/reports.module';

@NgModule({
    imports: [
        AppCommonModule,
        AppComponentsModule,
        AppFormsModule,
        AppTableToolsModule,
        BrowserAnimationsModule,
        BrowserModule,
        BsModalModule,
        CmsModalModule,
        ConfirmModalModule,
        HammerModule,
        RouteModule.forRoot({
            states: coreRoutes
        }),
        ReportsModule,
        RestModule,
        ToastsModule,
        TranslateModule
    ],
    declarations: [
        AppCmsComponent,
        AppCoreComponent,
        AppErrorComponent
    ],
    providers: [
        APP_STATE_SERVICE_PROVIDER,
        {provide: ErrorHandler, useClass: ExceptionHandler},
    ],
    bootstrap: [
        AppCoreComponent
    ]
})
export class CoreModule {

}
