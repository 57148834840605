import {ControlValueAccessor, NG_VALUE_ACCESSOR, NgControl} from '@angular/forms';
import {Component, HostBinding, Injector, Provider} from '@angular/core';

/* eslint-disable @angular-eslint/component-class-suffix */
@Component({
    selector: 'abstract-unvalidated-control-value-accessor',
    template: ''
})
/**
 * If your component contains any FormControls that are being validated or any custom validation logic
 * then use AbstractControlValueAccessor instead.
 */
export abstract class AbstractUnvalidatedControlValueAccessor {
    /**
     * Use it if CVA has multiple controls as its children, 'ignore-group-validation'
     * assures angular-bootstrap4-validate properly target ng-invalid status of child controls.
     */
    @HostBinding('class.ignore-group-validation') protected isGroupCVA = false;

    protected _pendingChange = {
        hasPendingChange: false,
        value: undefined
    };
    protected _onChange = (value: unknown): void => {
        this._pendingChange = {
            hasPendingChange: true,
            value
        };
    };
    public _onTouched?: () => void;
    protected _wasTouched = false;

    abstract writeValue(value: unknown): void;

    registerOnChange(fn: (value: unknown) => void): void {
        this._onChange = fn;
        if (this._pendingChange.hasPendingChange) {
            this._onChange(this._pendingChange.value);
        }
    }

    registerOnTouched(fn: () => void): void {
        this._onTouched = fn;
    }

    markAsTouched(): void {
        if (!this._wasTouched && this._onTouched) {
            this._wasTouched = true;
            this._onTouched();
        }
    }

    // It is necessary in order to update parent form validation status
    // When fields are dynamically removed or added, then sometimes form doesn't see validation status properly.
    // Form doesn't include new control validation status to its cumulative validation status
    // Control is grabbed from Injector to avoid circular dependency error
    updateCVAControlValidity(injector: Injector): void {
        setTimeout(() => {
            const control = injector.get(NgControl).control;
            control.updateValueAndValidity();
        });
    }

    static getProviders(
        targetCVA: abstract new(...args: unknown[]) => AbstractUnvalidatedControlValueAccessor & ControlValueAccessor
    ): Provider[] {
        return [
            {
                provide: NG_VALUE_ACCESSOR,
                multi: true,
                useExisting: targetCVA
            }
        ];
    }
}
