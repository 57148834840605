<div class="modal modal-browser-compat fade" [(bsModal)]="isBrowserIncompatible">
    <div class="modal-dialog modal-dialog-centered" *ngIf="isBrowserIncompatible">
        <div class="modal-content">
            <div class="modal-header border-0">
                <button [attr.aria-label]="'CLOSE'| translate"
                        class="close"
                        dismiss="modal"
                        type="button">
                    <span aria-hidden="true">×</span>
                </button>
            </div>
            <div class="modal-body text-center">
                <i class="fa fa-exclamation fa-3x text-danger"></i>
                <p class="mt-4 fw-600">{{'UNSUPPORTED_BROWSER' | translate}}</p>
                <small class="d-block mx-4 pb-2">{{'UNSUPPORTED_BROWSER_INFO' | translate}}</small>
                <div class="d-flex justify-content-center mt-4 mb-3">
                    <a *ngFor="let browser of recommendedBrowsers; let last=last"
                       [ngClass]="{'mr-5': !last}"
                       [href]="browser.url"
                       target="_blank"
                       rel="nofollow"
                       class="d-block recommended-browser">
                        <img [src]="browser.logo"
                             width="48"
                             [alt]="browser.name"/>
                        <p class="mt-3">{{browser.name}}</p>
                    </a>
                </div>
            </div>
        </div>
    </div>
</div>
