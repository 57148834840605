/* eslint-disable max-len */
/**
 * This file has been auto generated. Do not edit.
 */
import {ITaskTemplate} from '_types/rest/Entity/IRestTaskTemplate';
import {IRestTaskTemplate} from '_types/rest/Entity/IRestTaskTemplate';
import {ITaskTemplateComponentHierarchy} from '_types/rest/Entity/IRestTaskTemplateComponentHierarchy';
import {IRestTaskTemplateComponentHierarchy} from '_types/rest/Entity/IRestTaskTemplateComponentHierarchy';
import {ITaskTemplateComponentFilter} from '_types/rest/Entity/IRestTaskTemplateComponentFilter';
import {IRestTaskTemplateComponentFilter} from '_types/rest/Entity/IRestTaskTemplateComponentFilter';
import {ITaskTemplateComponentFilterBehaviour} from '_types/rest/Entity/IRestTaskTemplateComponentFilterBehaviour';
import {IRestTaskTemplateComponentFilterBehaviour} from '_types/rest/Entity/IRestTaskTemplateComponentFilterBehaviour';
import {ITaskTemplateComponentAction} from '_types/rest/Entity/IRestTaskTemplateComponentAction';
import {IRestTaskTemplateComponentAction} from '_types/rest/Entity/IRestTaskTemplateComponentAction';
import {ITaskTemplateComponentTask} from '_types/rest/Entity/IRestTaskTemplateComponentTask';
import {IRestTaskTemplateComponentTask} from '_types/rest/Entity/IRestTaskTemplateComponentTask';
import {ITaskTemplateComponentTemplate} from '_types/rest/Entity/IRestTaskTemplateComponentTemplate';
import {IRestTaskTemplateComponentTemplate} from '_types/rest/Entity/IRestTaskTemplateComponentTemplate';
import {IBaseRestEntity} from '_types/rest/IBaseRestEntity';
import {IRestObject} from 'core/rest/rest-object.service';

export const TaskTemplateComponentType = {
    TASK_TEMPLATE_COMPONENT_TYPE_ACTION: 1,
    TASK_TEMPLATE_COMPONENT_TYPE_TASK: 2,
    TASK_TEMPLATE_COMPONENT_TYPE_TEMPLATE: 3
} as const;

export const TaskTemplateComponentTypeOptions = [
    {
        value: TaskTemplateComponentType.TASK_TEMPLATE_COMPONENT_TYPE_ACTION,
        label: 'TASK_TEMPLATE_COMPONENT_TYPE_ACTION',
    },
    {
        value: TaskTemplateComponentType.TASK_TEMPLATE_COMPONENT_TYPE_TASK,
        label: 'TASK_TEMPLATE_COMPONENT_TYPE_TASK',
    },
    {
        value: TaskTemplateComponentType.TASK_TEMPLATE_COMPONENT_TYPE_TEMPLATE,
        label: 'TASK_TEMPLATE_COMPONENT_TYPE_TEMPLATE',
    }
] as const;

export const TaskTemplateComponentState = {
    TASK_TEMPLATE_COMPONENT_STATE_NORMAL: 1,
    TASK_TEMPLATE_COMPONENT_STATE_START: 2,
    TASK_TEMPLATE_COMPONENT_STATE_FAIL: 3,
    TASK_TEMPLATE_COMPONENT_STATE_SUCCESS: 4,
    TASK_TEMPLATE_COMPONENT_STATE_DEFAULT_SUCCESS: 5
} as const;

export const TaskTemplateComponentStateOptions = [
    {
        value: TaskTemplateComponentState.TASK_TEMPLATE_COMPONENT_STATE_NORMAL,
        label: 'TASK_TEMPLATE_COMPONENT_STATE_NORMAL',
    },
    {
        value: TaskTemplateComponentState.TASK_TEMPLATE_COMPONENT_STATE_START,
        label: 'TASK_TEMPLATE_COMPONENT_STATE_START',
    },
    {
        value: TaskTemplateComponentState.TASK_TEMPLATE_COMPONENT_STATE_FAIL,
        label: 'TASK_TEMPLATE_COMPONENT_STATE_FAIL',
    },
    {
        value: TaskTemplateComponentState.TASK_TEMPLATE_COMPONENT_STATE_SUCCESS,
        label: 'TASK_TEMPLATE_COMPONENT_STATE_SUCCESS',
    },
    {
        value: TaskTemplateComponentState.TASK_TEMPLATE_COMPONENT_STATE_DEFAULT_SUCCESS,
        label: 'TASK_TEMPLATE_COMPONENT_STATE_DEFAULT_SUCCESS',
    }
] as const;

export interface ITaskTemplateComponentConst {
    type: [{id: 1, value: 'TASK_TEMPLATE_COMPONENT_TYPE_ACTION'}, {id: 2, value: 'TASK_TEMPLATE_COMPONENT_TYPE_TASK'}, {id: 3, value: 'TASK_TEMPLATE_COMPONENT_TYPE_TEMPLATE'}],
    state: [{id: 1, value: 'TASK_TEMPLATE_COMPONENT_STATE_NORMAL'}, {id: 2, value: 'TASK_TEMPLATE_COMPONENT_STATE_START'}, {id: 3, value: 'TASK_TEMPLATE_COMPONENT_STATE_FAIL'}, {id: 4, value: 'TASK_TEMPLATE_COMPONENT_STATE_SUCCESS'}, {id: 5, value: 'TASK_TEMPLATE_COMPONENT_STATE_DEFAULT_SUCCESS'}]
}

export interface ITaskTemplateComponent extends IBaseRestEntity {
    id?: number,
    /**
     * @maxLength 64
     */
    name: string,
    /**
     * @default 1
     */
    type: typeof TaskTemplateComponentType[keyof typeof TaskTemplateComponentType],
    description: string,
    /**
     * @default 1
     */
    state: typeof TaskTemplateComponentState[keyof typeof TaskTemplateComponentState],
    parentTaskTemplate: ITaskTemplate | string | null,
    previousTaskTemplateComponentHierarchies?: (ITaskTemplateComponentHierarchy | string)[],
    nextTaskTemplateComponentHierarchies?: (ITaskTemplateComponentHierarchy | string)[],
    taskTemplateComponentFilters?: (ITaskTemplateComponentFilter | string)[],
    taskTemplateComponentFilterBehaviours?: (ITaskTemplateComponentFilterBehaviour | string)[],
    taskTemplateComponentActions?: (ITaskTemplateComponentAction | string)[],
    taskTemplateComponentTasks?: (ITaskTemplateComponentTask | string)[],
    taskTemplateComponentTemplates?: (ITaskTemplateComponentTemplate | string)[],
    /**
     * @description Record status
     *  0 = deleted
     *  1 = active.
     * @default 1
     */
    status?: number,
    /**
     * @description Creation timestamp.
     * @default CURRENT_TIMESTAMP
     * @format date-time
     */
    created?: string,
    /**
     * @description Last modification timestamp.
     * @format date-time
     */
    modified?: string | null
}

/** @deprecated */
export interface IRestTaskTemplateComponent extends ITaskTemplateComponent, IRestObject {
    parentTaskTemplate: IRestTaskTemplate | string | null,
    previousTaskTemplateComponentHierarchies?: IRestTaskTemplateComponentHierarchy[] | string[],
    nextTaskTemplateComponentHierarchies?: IRestTaskTemplateComponentHierarchy[] | string[],
    taskTemplateComponentFilters?: IRestTaskTemplateComponentFilter[] | string[],
    taskTemplateComponentFilterBehaviours?: IRestTaskTemplateComponentFilterBehaviour[] | string[],
    taskTemplateComponentActions?: IRestTaskTemplateComponentAction[] | string[],
    taskTemplateComponentTasks?: IRestTaskTemplateComponentTask[] | string[],
    taskTemplateComponentTemplates?: IRestTaskTemplateComponentTemplate[] | string[],
}

/** @deprecated */
export type IRestTaskTemplateComponentConst = ITaskTemplateComponentConst & IRestObject;
