import {Injectable} from '@angular/core';
import {
    HttpContextToken,
    HttpErrorResponse,
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
    HttpResponse,
    HttpStatusCode
} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError, map, mergeMap} from 'rxjs/operators';
import {Comparison} from 'src/services/comparison';
import {RestError} from 'src/modules/rest/rest.error';
import {RestClient} from 'src/modules/rest/rest-client.service';
import {RestSchema} from 'src/modules/rest/objects/rest-schema';
import {RestCollection} from './objects/rest-collection';
import {IHydraResponse} from 'src/modules/rest/hydra';
import {UserService} from 'src/modules/rest/user/user.service';
import {TranslateService} from 'src/modules/translate/translate.service';
import {RealEndpointName} from '_types/rest/endpoint-to-type';
import {RestClientConfig} from 'src/modules/rest/rest-client-config.service';
import {IQueryObject} from 'src/services/url-parser.service';
import {getReasonPhrase} from 'http-status-codes';

export interface IRestRequestContext {
    endpoint?: string,
    id?: number | string,
    query?: IQueryObject
}

export const REST_REQUEST_CONTEXT = new HttpContextToken<IRestRequestContext>(() => {
    return {};
});

@Injectable({
    providedIn: 'root'
})
export class RestHttpInterceptor implements HttpInterceptor {
    constructor(
        private translateService: TranslateService,
        private restClient: RestClient,
        private userService: UserService
    ) {
    }

    intercept(
        httpRequest: HttpRequest<unknown>,
        next: HttpHandler,
        retries = 0
    ): Observable<HttpEvent<unknown>> {
        const restContext = httpRequest.context.get(REST_REQUEST_CONTEXT);

        /**
         * check if it's a request coming from RestEndpoint
         * @see RestEndpoint._request
         * */
        if (typeof restContext.endpoint === 'string') {
            return next.handle(
                httpRequest
            )
                .pipe(
                    map((event) => {
                        if (event instanceof HttpResponse) {
                            return this.handleResponse(event, httpRequest.method, restContext);
                        }
                        return event;
                    }),
                    catchError((response) => this.retry(httpRequest, response, next, restContext, retries)),
                    catchError((response) => this.handleError(response))
                );

        }
        return next.handle(httpRequest);
    }

    private handleResponse(response: HttpResponse<unknown>, method: string, restContext: IRestRequestContext) {
        if (
            typeof RestClientConfig.VALID_RESPONSE_CODES[method] === 'undefined'
            || !RestClientConfig.VALID_RESPONSE_CODES[method].includes(response.status)
        ) {
            throw this.getHttpError(response);
        }

        if (
            typeof response.body === 'object'
            && response.body !== null
            && !Array.isArray(response.body)
            && typeof response.body['@id'] === 'string'
        ) {
            const {endpoint} = this.restClient.parseIri(response.body['@id']);

            let body;

            if (
                typeof response.body['@type'] === 'string'
                && response.body['@type'] === 'hydra:Collection'
            ) {
                if (!Array.isArray(response.body['hydra:member'])) {
                    throw this.getHttpError(
                        response, 'Wrong hydra:Collection format - hydra:member is not an array!'
                    );
                }

                body = RestCollection.fromHydra(endpoint, response.body as IHydraResponse<unknown>);
            } else {
                body = this.restClient.endpoint(endpoint).createObject(response.body);
            }

            return response.clone({body});
        } else if (
            restContext.id === 'schema'
            && Array.isArray(response.body)
        ) {
            return response.clone({body: new RestSchema<RealEndpointName>(response.body)});
        }

        return response;
    }

    private getHttpError(response: HttpResponse<unknown>, error?: unknown): HttpErrorResponse {
        return new HttpErrorResponse({
            error: error || response.body,
            headers: response.headers,
            status: response.status,
            statusText: response.statusText,
            url: response.url
        });
    }

    private retry(
        request: HttpRequest<unknown>,
        response: HttpErrorResponse,
        next: HttpHandler,
        restContext: IRestRequestContext,
        retries: number
    ): Observable<HttpEvent<unknown> | never> {
        if (
            retries > 0
            || response.status !== HttpStatusCode.Unauthorized
            || ['oauth', 'user_logins/mfa/login'].includes(restContext.endpoint)
        ) {
            return throwError(() => response);
        }

        return this.userService.login.refresh()
            .pipe(
                mergeMap((success) => {
                    if (!success) {
                        return throwError(() => response);
                    }

                    const authorization = RestClientConfig.getAuthorizationHeader();

                    return this.intercept(
                        request.clone({
                            headers: authorization
                                ? request.headers.set('Authorization', authorization)
                                : request.headers.delete('Authorization')
                        }),
                        next,
                        ++retries
                    );
                })
            );
    }

    private handleError(response: HttpErrorResponse): Observable<never> {
        const responseError = response.error;

        return throwError(() => new RestError(
            this.getErrorMessage(response, responseError),
            response.status,
            response.error,
            this.getErrorDetails(responseError)
        ));
    }

    private getErrorMessage(
        response: HttpErrorResponse,
        responseError: Record<string, unknown>
    ): string {
        if (
            responseError !== null
            && typeof responseError === 'object'
            && Array.isArray(responseError.violations)
            && responseError.violations.length
        ) {
            return responseError.violations
                .map((violation: { message: string, propertyPath: string, code: string }) => violation.message)
                .filter(Comparison.unique)
                .map((message: string) => this.translateService.get(message))
                .join('<br/>');
        }

        if (response.status === 0) {
            return 'Could not connect to API server';
        }

        /**
         * This function is required since `response.statusText` would be empty in HTTP/2,
         * therefore Angular always uses 'OK' as default value.
         * https://github.com/angular/angular/issues/23334
         */
        try {
            return getReasonPhrase(response.status);
        }
        catch (e) {
            return 'An error occurred';
        }
    }

    private getErrorDetails(responseError: Record<string, unknown>): string | null {
        if (
            responseError !== null
            && typeof responseError === 'object'
            && typeof responseError.detail === 'string'
        ) {
            return responseError.detail;
        }

        if (typeof responseError === 'string') {
            return responseError;
        }

        return null;
    }
}
