import {Directive, ElementRef, Injector, Input, OnInit, Renderer2, TemplateRef, ViewContainerRef} from '@angular/core';
import {availablePipes, DynamicTablePipes, IDynamicTableColumn} from 'src/modules/dynamic-table/interfaces';
import {IPlainTemplateContext, ITableCellTemplateContext} from 'src/modules/dynamic-table/cell-templates/interfaces';
import {TranslateService} from 'src/modules/translate/translate.service';

@Directive({
    selector: '[dynamicTableCell]',
    providers: Object.values(availablePipes)
})
export class DynamicTableCellDirective<T extends object> implements OnInit {
    @Input() dynamicTableCell: IDynamicTableColumn<T>;
    @Input() item: T;
    @Input() textOnly: boolean;

    constructor(
        private renderer: Renderer2,
        private elementRef: ElementRef<HTMLTableCellElement>,
        private viewContainerRef: ViewContainerRef,
        private translateService: TranslateService,
        private injector: Injector
    ) {
    }

    ngOnInit(): void {
        const mode = typeof this.item === 'undefined' ? 'th' : 'td';

        // CSS
        if (!this.textOnly && typeof this.dynamicTableCell[mode].css === 'string') {
            this.dynamicTableCell[mode].css
                .split(' ')
                .map((x) => x.trim())
                .filter((x) => x.length)
                .forEach((css) => {
                    this.renderer.addClass(this.elementRef.nativeElement, css);
                });
        }

        // template
        if (mode === 'th') {
            this.render(
                this.dynamicTableCell[mode].template,
                this.getThContext(this.dynamicTableCell)
            );
        } else {
            const value = this.getTdValue(this.dynamicTableCell, this.item);

            let values = Array.isArray(value) ? value : [value];

            if (Array.isArray(this.dynamicTableCell.td?.pipes)) {
                values = values.map((v) => this.pipeTransform(v, this.dynamicTableCell.td.pipes));
            }

            values.forEach((value, idx) => {
                if (value instanceof TemplateRef) {
                    this.render(
                        value,
                        this.getTdContext(this.dynamicTableCell, this.item, value, idx),
                        idx > 0,
                        mode === 'td' ? this.dynamicTableCell.td.separator : undefined
                    );
                    return;
                }

                this.render(
                    this.dynamicTableCell[mode].template,
                    this.getTdContext(this.dynamicTableCell, this.item, value, idx),
                    idx > 0,
                    mode === 'td' ? this.dynamicTableCell.td.separator : undefined
                );
            });
        }
    }

    getThContext(column: IDynamicTableColumn<T>): { column: IDynamicTableColumn<T> } | IPlainTemplateContext {
        if (column.th.content instanceof TemplateRef) {
            return {
                column
            };
        }

        return {
            type: 'plain',
            value: this.translateService.get(column.th.content)
        };
    }

    getTdValue(
        column: IDynamicTableColumn<T>,
        row: T
    ): unknown {
        return typeof column.td?.content === 'function'
            ? column.td.content(row)
            : row[column.field as string];
    }

    pipeTransform(value: unknown, pipes: DynamicTablePipes[]): unknown {
        pipes.forEach((pipeDefinition) => {
            let pipeName,
                pipeArgs = [];

            if (typeof pipeDefinition === 'string') {
                pipeName = pipeDefinition;
            } else {
                pipeName = pipeDefinition.pipe;
                pipeArgs = pipeDefinition.args;
            }

            value = this.injector.get(availablePipes[pipeName]).transform(value, ...pipeArgs);
        });

        return value;
    }

    getTdContext(
        column: IDynamicTableColumn<T>,
        row: T,
        value: unknown,
        index: number
    ): { item: T, field: string } | ITableCellTemplateContext {
        if (
            column.td?.content instanceof TemplateRef
            || value instanceof TemplateRef
        ) {
            return {
                item: row,
                field: column.field
            };
        }

        if (value instanceof TemplateRef) {
            return {
                item: row,
                field: column.field
            };
        }

        if (
            typeof column.td === 'undefined'
            || typeof column.td.display === 'undefined'
        ) {
            return {
                type: 'plain',
                value
            };
        }

        return {
            value,
            type: column.td.display.type,
            config: 'config' in column.td.display ? column.td.display.config(value, row, index) : undefined
        } as ITableCellTemplateContext;
    }

    render<C>(
        template: TemplateRef<C>,
        context: C,
        leftSpacing = false,
        separator = ''
    ): void {
        const embeddedViewRef = this.viewContainerRef.createEmbeddedView(template, context);

        if (this.textOnly) {
            embeddedViewRef.detectChanges();
            const text = embeddedViewRef.rootNodes.map((node) => node.textContent).join(' ');
            this.renderer.appendChild(this.elementRef.nativeElement, this.renderer.createText(text));
            embeddedViewRef.destroy();
            return;
        }

        embeddedViewRef.rootNodes.forEach((rootNode) => {
            if (separator && leftSpacing) {
                const separatorElement = this.renderer.createElement('span');
                this.renderer.appendChild(separatorElement, this.renderer.createText(separator));
                this.renderer.appendChild(this.elementRef.nativeElement, separatorElement);
            } else {
                if (rootNode instanceof HTMLElement) {
                    this.renderer.addClass(rootNode, 'mr-2');
                }
            }
            this.renderer.appendChild(this.elementRef.nativeElement, rootNode);
        });
    }
}
