import {NgModule} from '@angular/core';
import {SortableDirective} from 'src/modules/sortable/sortable.directive';
import {SortableItemDirective} from 'src/modules/sortable/sortable-item.directive';
import {SortableDragHandleComponent} from 'src/modules/sortable/sortable-drag-handle/sortable-drag-handle.component';
import {SortableItemPlaceholderDirective} from 'src/modules/sortable/sortable-item-placeholder/sortable-item-placeholder.directive';
import {AppCommonModule} from 'src/modules/app-common/app-common.module';


@NgModule({
    imports: [
        AppCommonModule
    ],
    declarations: [
        SortableDirective,
        SortableDragHandleComponent,
        SortableItemDirective,
        SortableItemPlaceholderDirective
    ],
    exports: [
        SortableDirective,
        SortableDragHandleComponent,
        SortableItemDirective,
        SortableItemPlaceholderDirective
    ]
})
export class SortableModule {
}
