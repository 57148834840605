import {ChangeDetectionStrategy, Component} from '@angular/core';
import {LegendService} from 'src/modules/legend/legend.service';


@Component({
    selector: 'legend-toggle',
    templateUrl: './legend-toggle.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class LegendToggleComponent {
    constructor(
        public readonly legendService: LegendService
    ) {
    }
}
