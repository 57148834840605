<extended-select [formControl]="esControl"
                 [multiple]="fieldConfig.multiple"
                 [resolveOnSearch]="resolveOnSearch"
                 [typeToSearch]="fieldConfig.typeToSearch"
                 [deselectable]="fieldConfig.deselectable"
                 [deselectValue]="fieldConfig.deselectValue"
                 [inputLoading]="initializing"
                 [placeholder]="fieldConfig.placeholder">
    <ng-container *esBeforeOption="let o; options: options">
        <span class="mr-2">
            <i class="fa" *ngIf="o; else placeholderIcon"
               [ngClass]="o.styleClass + ' ' + o.className">
            </i>

            <ng-template #placeholderIcon>
                <i class="fa fa-icons disabled-icon" *ngIf="!o"></i>
            </ng-template>
        </span>
    </ng-container>

    <ng-container *esOptions="let o of options; value: 'className'">
        {{o.label}}
    </ng-container>
</extended-select>
