import {IRestCollection, IRestObject} from 'src/modules/rest/objects';
import {AbstractOperationDefinition} from 'src/modules/operations/interfaces';
import {RestClient} from 'src/modules/rest/rest-client.service';
import {AbstractDeleteOperation} from 'view-modules/operations/abstract/abstract-delete.operation';

export interface IDepartmentRoleAddOperationContext {
    departments: IRestCollection<'departments'>,
    department: IRestObject<'departments'>
}

export class DepartmentRoleAddOperation
    extends AbstractOperationDefinition<string, IDepartmentRoleAddOperationContext> {

    readonly endpoint = 'department_roles';
    readonly name = 'post';
    lang = 'PARTNERS_DEPARTMENT_ROLE_ADD';
    icon = 'fa-plus';
    component = (): Promise<unknown> => import('./department-role-form/department-role-form.component');

    access(): boolean {
        return true;
    }
}

export interface IDepartmentRoleEditOperationContext {
    departments: IRestCollection<'departments'>,
    departmentRole: IRestObject<'department_roles'>,
}

export class DepartmentRoleEditOperation
    extends AbstractOperationDefinition<string, IDepartmentRoleEditOperationContext> {

    readonly endpoint = 'department_roles';
    readonly name = 'put';
    lang = 'PARTNERS_DEPARTMENT_ROLE_EDIT';
    icon = 'fa-edit';
    component = (): Promise<unknown> => import('./department-role-form/department-role-form.component');

    access(context: IDepartmentRoleEditOperationContext): boolean {
        if (RestClient.isRestObject(context.departmentRole)) {
            return context.departmentRole.access(this.name);
        }

        return false;
    }
}

export class DepartmentRoleDeleteOperation extends AbstractDeleteOperation<'department_roles'> {
    readonly endpoint = 'department_roles';
    lang = 'DELETE';
    confirmMessage = 'PARTNERS_DEPARTMENTS_REMOVE_DEPARTMENT_ROLE_CONFIRM';
    confirmOptions = {
        langYes: 'PARTNERS_DEPARTMENTS_REMOVE_DEPARTMENT_ROLE_CONFIRM_LANG_YES',
    };
}
export const departmentRolesOperations = [
    DepartmentRoleAddOperation,
    DepartmentRoleEditOperation,
    DepartmentRoleDeleteOperation,
] as const;
