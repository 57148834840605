import {absencesOperations} from 'view-modules/operations/absences/absences.operations';
import {devOperations} from 'view-modules/operations/dev/dev.operations';
import {credentialsOperations} from 'view-modules/operations/credentials/credentials.operations';
import {clientContactsOperations} from 'view-modules/operations/clients/client-contacts/client-contacts.operations';
import {guardianOperations} from 'view-modules/operations/clients/client-guardians/client-guardians.operations';
import {userOperations} from 'view-modules/operations/users/users.operations';
import {reportOperations} from 'view-modules/operations/reports/report.operations';
import {cronsOperations} from 'view-modules/operations/crons/crons.operations';
import {tasksOperations} from 'view-modules/operations/tasks/tasks.operations';
import {timeTrackerOperations} from 'view-modules/operations/time-tracker/time-tracker.operations';
import {timeTrackerAbstractOperations} from 'view-modules/operations/time-tracker/abstract-time-tracker.operation';
import {numberingPatternOperations} from 'view-modules/operations/numbering-patterns/numbering-patterns.operations';
import {apiListOperations} from 'view-modules/operations/api-list/api-list.operations';
import {partnerContactsOperations} from 'view-modules/operations/partner-contacts/partner-contacts.operations';
import {
    partnerOAuthOperations
} from 'view-modules/operations/partner-oauth-applications/partner-oauth-applications.operations';
import {partnerMailboxOperations} from 'view-modules/operations/partner-mailbox/partner-mailbox.operations';
import {
    searchConsoleTemplateOperations
} from 'view-modules/operations/search-console-templates/search-console-templates.operations';
import {
    searchConsoleTemplateQueriesOperations
} from 'view-modules/operations/search-console-template-queries/search-console-template-queries.operations';
import {mailsOperations} from 'view-modules/operations/mails/mails.operations';
import {organicGroupsOperations} from 'view-modules/operations/organic-groups/organic-groups.operations';
import {ogRolesOperations} from 'view-modules/operations/og-roles/og-roles.operations';
import {ogUserAssignmentsOperations} from 'view-modules/operations/og-user-assignments/og-user-assignments.operations';
import {userLoginsOperations} from 'view-modules/operations/user-logins/user-logins.operations';
import {
    ogEntityDependenciesOperations
} from 'view-modules/operations/og-entity-dependencies/og-entity-dependencies.operations';
import {cmsTypeOperations} from 'view-modules/operations/cms-types/cms-types.operations';
import {countryOperations} from 'view-modules/operations/countries/countries.operations';
import {currencyOperations} from 'view-modules/operations/currencies/currencies.operations';
import {
    searchConsoleScheduleOperations
} from 'view-modules/operations/search-console-schedule/search-console-schedule.operations';
import {cmsOperations} from 'view-modules/operations/cms/cms.operations';
import {valuationCloudsOperations} from 'view-modules/operations/valuation-clouds/valuation-clouds.operations';
import {gscReportsOperations} from 'view-modules/operations/gsc-reports/gsc-reports.operations';
import {gscServices} from 'view-modules/operations/gsc-services/gsc-services.operations';
import {companiesOperations} from 'view-modules/operations/companies/companies.operations';
import {
    userAccessesContextOperations
} from 'view-modules/operations/user-accesses-context/user-accesses-context.operations';
import {partnersOperations} from 'view-modules/operations/partners/partners.operations';
import {
    customerServiceTeamTypesOperations
} from 'view-modules/operations/customer-service-team-types/customer-service-team-types.operations';
import {partnersListOperations} from 'view-modules/operations/partners-list/partners-list.operations';
import {
    companyContactTypeOperations
} from 'view-modules/operations/company-contact-types/company-contact-types.operations';
import {notificationTypeOperations} from 'view-modules/operations/notifications/notifications.operations';
import {aclApiScopesOperations} from 'view-modules/operations/acl-api-scopes/acl-api-scopes.operations';
import {aclWidgetRoleOperations} from 'view-modules/operations/acl-widget-role/acl-widget-role.operations';
import {departmentsOperations} from 'view-modules/operations/departments/departments.operations';
import {departmentRolesOperations} from 'view-modules/operations/department-roles/department-roles.operations';
import {
    communicationOperations
} from 'view-modules/operations/clients/client-communication/client-communication.operations';
import {rulesOperations} from 'view-modules/operations/rules/rules.operations';
import {partnerCatalogsOperations} from 'view-modules/operations/partner-catalogs/partner-catalogs.operations';
import {taskTemplatesOperations} from 'view-modules/operations/task-templates/task-templates.operation';
import {clientDocumentsOperations} from 'view-modules/operations/clients/client-documents/client.documents.operations';
import {notesOperations} from 'view-modules/operations/notes/notes.operations';
import {changeHistoryOperations} from 'view-modules/operations/change-history/change-history.operations';
import {clientDomainsOperations} from 'view-modules/operations/client-domains/client-domains.operations';
import {partnerProductsOperations} from 'view-modules/operations/partner-products/partner-products.operations';
import {
    taskAcceptanceCriteriaOperations
} from 'view-modules/operations/task-acceptance-criteria/task-acceptance-criteria.operations';
import {taskUsersOperations} from 'view-modules/operations/task-users/task-users.operations';
import {pinItemOperations} from 'view-modules/operations/pin-items/pin-items.operations';
import {taskDatesOperations} from 'view-modules/operations/task-dates/task-dates.operations';
import {sapOperations} from 'view-modules/operations/sap/sap.operations';
import {IOperationClass} from 'src/modules/operations/interfaces';
import {genericOperations} from 'view-modules/operations/_generic/generic.operations';


export const reportOperationsLedger: IOperationClass[] = [
    ...absencesOperations,
    ...devOperations,
    ...credentialsOperations,
    ...clientContactsOperations,
    ...guardianOperations,
    ...userOperations,
    ...cronsOperations,
    ...tasksOperations,
    ...timeTrackerOperations,
    ...timeTrackerAbstractOperations,
    ...numberingPatternOperations,
    ...apiListOperations,
    ...partnerContactsOperations,
    ...partnerOAuthOperations,
    ...partnerMailboxOperations,
    ...searchConsoleTemplateOperations,
    ...searchConsoleTemplateQueriesOperations,
    ...mailsOperations,
    ...organicGroupsOperations,
    ...ogRolesOperations,
    ...ogUserAssignmentsOperations,
    ...userLoginsOperations,
    ...ogEntityDependenciesOperations,
    ...cmsTypeOperations,
    ...countryOperations,
    ...currencyOperations,
    ...searchConsoleScheduleOperations,
    ...cmsOperations,
    ...valuationCloudsOperations,
    ...gscReportsOperations,
    ...gscServices,
    ...reportOperations,
    ...companiesOperations,
    ...userAccessesContextOperations,
    ...partnersOperations,
    ...customerServiceTeamTypesOperations,
    ...partnersListOperations,
    ...companyContactTypeOperations,
    ...notificationTypeOperations,
    ...aclApiScopesOperations,
    ...aclWidgetRoleOperations,
    ...departmentsOperations,
    ...departmentRolesOperations,
    ...communicationOperations,
    ...rulesOperations,
    ...communicationOperations,
    ...partnerCatalogsOperations,
    ...taskTemplatesOperations,
    ...clientDocumentsOperations,
    ...notesOperations,
    ...changeHistoryOperations,
    ...clientDomainsOperations,
    ...partnerProductsOperations,
    ...sapOperations,
    ...genericOperations,
    ...partnerProductsOperations,
    ...taskAcceptanceCriteriaOperations,
    ...taskUsersOperations,
    ...pinItemOperations,
    ...taskDatesOperations,
];
