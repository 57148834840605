import {EndpointSchemaFields, EntitySchemaField, EntitySchemaOperations, IEntitySchemaField} from './rest-schema.interfaces';
import {
    DynamicFieldValidators, ICategoryDynamicFieldConfig,
    ICheckboxDynamicFieldConfig,
    IDateDynamicFieldConfig,
    IDynamicFieldConfig,
    IDynamicFieldsConfig,
    IEmailDynamicFieldConfig,
    INumberDynamicFieldConfig,
    IRelationDynamicFieldConfig, ISelectDynamicFieldConfig,
    IStringDynamicFieldConfig, ITextDynamicFieldConfig,
    ValidatorMessages
} from 'src/modules/dynamic-fields/interfaces';
import {Comparison} from 'src/services/comparison';
import {EndpointName} from '_types/rest';

interface IValidationDefinitions {
    validators: DynamicFieldValidators,
    validatorMessages: ValidatorMessages<DynamicFieldValidators>
}

export class RestSchema<TEndpoint extends EndpointName,
    TFields extends string = EndpointSchemaFields<TEndpoint>> {

    fieldConfigs: IDynamicFieldsConfig<TFields>[];

    constructor(
        public schema: EntitySchemaField<TEndpoint, TFields>[]
    ) {
        this.fieldConfigs = RestSchema.schemaToDynamicFieldConfigs<TEndpoint, TFields>(schema);
    }

    static schemaToDynamicFieldConfigs<TEndpoint extends EndpointName,
        TFields extends string = EndpointSchemaFields<TEndpoint>>(
        schema: EntitySchemaField<TEndpoint, TFields>[]
    ): IDynamicFieldsConfig<TFields>[] {
        return schema
            .filter((item) => !item.field.startsWith('_field')) // TODO RDP-74 entity fields will be tokens
            .map((item) => {
                return Object.assign(
                    this.entitySchemaToDynamicFieldConfig(item),
                    {
                        field: item.field
                    }
                );
            });
    }

    static entitySchemaToDynamicFieldConfig<TEndpoint extends EndpointName,
        TFields extends string = EndpointSchemaFields<TEndpoint>>(
        entitySchema: EntitySchemaField<TEndpoint, TFields>
    ): IDynamicFieldConfig {
        const validationDefinitions = 'validators' in entitySchema ? this.extractValidators(entitySchema) : {
            validators: {},
            validatorMessages: {}
        };
        const fieldConfig: Partial<IDynamicFieldConfig> = Object.assign({
            name: entitySchema.name,
            help: entitySchema.help
        }, validationDefinitions);

        if (
            'options' in entitySchema
            && Array.isArray(entitySchema.options)
            && entitySchema.options.length
        ) {
            entitySchema.type = 'array';
        }

        if ('email' in validationDefinitions.validators) {
            return Object.assign(
                {
                    type: 'email',
                    default: 'default' in entitySchema && entitySchema.default ? entitySchema.default : undefined
                },
                fieldConfig
            ) as IEmailDynamicFieldConfig;
        }

        switch (entitySchema.type) {
            case 'relation':
            case 'relation:ManyToOne':
                if (entitySchema.targetEntity === 'files') {
                    return Object.assign({
                        type: 'string'
                    }, fieldConfig) as IStringDynamicFieldConfig;
                }

                return Object.assign({
                    type: 'relation',
                    endpoint: entitySchema.targetEntity
                }, fieldConfig) as IRelationDynamicFieldConfig;
            case 'tinyint':
            case 'smallint':
            case 'integer':
            case 'bigint':
            case 'decimal':
                return Object.assign(
                    {
                        type: 'number',
                        default: entitySchema.default !== '' ? parseInt(entitySchema.default) : undefined
                    },
                    fieldConfig
                ) as INumberDynamicFieldConfig;
            case 'boolean':
            case 'bool':
                return Object.assign(
                    {
                        type: 'checkbox',
                        default: !!entitySchema.default,
                        trueValue: 1,
                        falseValue: 0
                    },
                    fieldConfig
                ) as ICheckboxDynamicFieldConfig;
            case 'datetime':
                return Object.assign(
                    {
                        type: 'date',
                        default: entitySchema.default || undefined
                    },
                    fieldConfig
                ) as IDateDynamicFieldConfig;
            case 'string':
            case 'text':
                return Object.assign(
                    {
                        type: entitySchema.type,
                        default: entitySchema.default || undefined
                    },
                    fieldConfig
                ) as IStringDynamicFieldConfig | ITextDynamicFieldConfig;
            case 'array': {
                const options = entitySchema.options.map((option) => {
                    return {
                        value: option.id,
                        label: option.value
                    };
                });

                let defaultValue;

                if (entitySchema.default) {
                    const defaultOption = options.find((option) => {
                        // @ts-expect-error: compare without type on purpose
                        // eslint-disable-next-line eqeqeq
                        return option.value == entitySchema.default;
                    });

                    if (defaultOption) {
                        defaultValue = defaultOption.value;
                    }
                }

                return Object.assign(
                    {
                        type: 'select',
                        options,
                        default: defaultValue
                    },
                    fieldConfig
                ) as ISelectDynamicFieldConfig;
            }
            case 'category':
                return Object.assign({
                    type: 'category',
                    endpoint: entitySchema.targetEntity,
                    property: entitySchema.property
                }, fieldConfig) as ICategoryDynamicFieldConfig;
        }
    }

    private static extractValidators<TEndpoint extends EndpointName,
        TFields extends string = EndpointSchemaFields<TEndpoint>>(
        entitySchema: IEntitySchemaField<TEndpoint, TFields>
    ): IValidationDefinitions {
        const validators: DynamicFieldValidators = {},
            validatorMessages: ValidatorMessages<DynamicFieldValidators> = {};

        if (entitySchema.validators !== null) {
            Object.entries(entitySchema.validators).forEach(([validator, value]) => {
                if (validator.endsWith('Message')) {
                    validatorMessages[validator.substring(0, validator.length - 7)] = value;
                } else {
                    validators[validator] = value;
                }
            });
        }

        return {
            validators,
            validatorMessages
        };
    }

    hasAccess(fieldName: string, method?: EntitySchemaOperations): boolean {
        const field = this.schema.find(Comparison.criteria({field: fieldName}));
        if (typeof field === 'undefined') {
            return false;
        }
        return method === 'get'
            || field.operations.includes(method);
    }
}
