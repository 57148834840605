import {Directive} from '@angular/core';
import {BsTooltipToggleDirective} from 'angular-bootstrap4';

@Directive({
    selector: '[title]:not([bsPopoverToggle]):not([bsTooltipToggle])'
})
export class TitleDirective extends BsTooltipToggleDirective {
    /**
     * Method from BsTooltipToggleDirective
     */
    showEvent(type: 'mouseenter' | 'focus'): void {
        if (!this.elementRef.nativeElement.classList.contains('title-disabled')) {
            super.showEvent(type);
        }
    }
}
