import {Directive, ElementRef, Inject, OnInit, Renderer2} from '@angular/core';
import {DOCUMENT} from '@angular/common';
import {TranslateService} from 'src/modules/translate/translate.service';

@Directive({
    selector: 'select'
})
export class SelectPlaceholderDirective implements OnInit {
    constructor(
        private el: ElementRef,
        private renderer: Renderer2,
        private translateService: TranslateService,
        @Inject(DOCUMENT) private document: Document
    ) {
    }

    ngOnInit(): void {
        const defaultOptionTemplate = this.document.createElement('option');
        defaultOptionTemplate.setAttribute('value', '');
        defaultOptionTemplate.disabled = true;
        defaultOptionTemplate.selected = true;
        const isMultiple  = 'multiple' in this.el.nativeElement.attributes;
        if (isMultiple) {
            defaultOptionTemplate.textContent = this.translateService.get('SELECT_OPTIONS');
        } else {
            defaultOptionTemplate.textContent = this.translateService.get('SELECT_OPTION');
            defaultOptionTemplate.style.display = 'none';
        }
        this.renderer.appendChild(this.el.nativeElement, defaultOptionTemplate);
    }
}
