<div class="card" [tableTools]="tableConfig">
    <div class="card-header" *ngIf="!tableConfig.hideHeader">
        <h4 class="card-title">{{tableConfig.name | translate}}</h4>
        <div class="heading-elements d-flex" *ngIf="tableConfig.collectionOperations?.length || tableConfig.export">
            <operation *ngFor="let operation of tableConfig.collectionOperations"
                       [context]="collectionContext.get(operation)"
                       (operationSuccess)="$event ? tableConfig.filterData() : null"
                       [operationClass]="operation.operationClass"
                       viewType="buttons">
            </operation>
            <tt-export *ngIf="tableConfig.export"></tt-export>
        </div>
        <div class="ml-auto">
            <dynamic-table-config [tableConfig]="tableConfig"></dynamic-table-config>
        </div>
    </div>
    <div class="card-body" *ngIf="(hasFilters || tableConfig.selectedFilters.length) && !tableConfig.hideFilters">
        <dynamic-table-filters [tableConfig]="tableConfig"></dynamic-table-filters>
    </div>
    <dynamic-table [tableConfig]="tableConfig">
    </dynamic-table>
    <div class="card-footer" *ngIf="!tableConfig.loading && !tableConfig.hideFooter">
        <dynamic-table-footer [tableConfig]="tableConfig"></dynamic-table-footer>
    </div>
</div>
