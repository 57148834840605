import {AbstractOperationDefinition} from 'src/modules/operations/interfaces';
import {AbstractDeleteOperation} from 'view-modules/operations/abstract/abstract-delete.operation';
import {Injector} from '@angular/core';
import {StateService} from '@uirouter/core';
import {IRestCollection, IRestObject} from 'src/modules/rest/objects';
import {INotificationType} from '_types/rest';

export class NotificationAddOperation extends
    AbstractOperationDefinition<'notification_types', IRestCollection<'notification_types'>> {
    readonly endpoint = 'notification_types';
    readonly name = 'post';
    lang = 'ADD';
    icon = 'fa-plus';

    invoke(
        context: IRestCollection<'notification_types'>,
        injector: Injector
    ): void {
        injector.get(StateService).go('admin.notifications.types.add');
    }
}

export class NotificationEditOperation extends AbstractOperationDefinition<string, INotificationType> {
    readonly endpoint = 'notification_types';
    readonly name = 'put';
    lang = 'EDIT';
    icon = 'fa-edit';

    invoke(
        context: IRestObject<'notification_types'>,
        injector: Injector
    ): void {
        injector.get(StateService).go('admin.notifications.types.edit', {notificationTypeId: context.id});
    }
}

export class NotificationDeleteOperation extends AbstractDeleteOperation<'notification_types'> {
    readonly endpoint = 'notification_types';
    lang = 'DELETE';
    confirmMessage = 'ADMIN_NOTIFICATION_TYPE_REMOVE_CONFIRM';
    confirmOptions = {
        langYes: 'YES'
    };
}

export const notificationTypeOperations = [
    NotificationAddOperation,
    NotificationEditOperation,
    NotificationDeleteOperation
] as const;
