
import {Component, Input} from '@angular/core';

@Component({
    selector: 'loader',
    templateUrl: './loader.component.html'
})
export class LoaderComponent {
    @Input() center: boolean;
    @Input() size: 'large' | 'medium';
}
