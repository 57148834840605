import {Component, Inject} from '@angular/core';
import {ENV, IEnvironment, WINDOW} from 'app-custom-providers';
import chromeLogo from './logos/chrome-logo.png';
import firefoxLogo from './logos/firefox-logo.png';
import safariLogo from './logos/safari-logo.png';

@Component({
    selector: 'browser-compat-modal',
    templateUrl: './browser-compat-modal.component.html',
    styleUrls: ['./browser-compat-modal.component.scss']
})
export class BrowserCompatModalComponent {
    isBrowserIncompatible: boolean;
    readonly recommendedBrowsers = [
        {
            name: 'Chrome',
            url: 'https://www.google.com/intl/pl_pl/chrome/',
            logo: chromeLogo
        },
        {
            name: 'Firefox',
            url: 'https://www.mozilla.org/pl/firefox/new/',
            logo: firefoxLogo
        },
        {
            name: 'Safari',
            url: 'https://support.apple.com/pl_PL/downloads/safari',
            logo: safariLogo
        }
    ] as const;

    constructor(
        @Inject(ENV) env: IEnvironment,
        @Inject(WINDOW) window: Window
    ) {
        this.isBrowserIncompatible = !env.BROWSER_SUPPORT_REGEX.test(window.navigator.userAgent);
    }
}
