import {AbstractOperationDefinition} from 'src/modules/operations/interfaces';
import {AbstractDeleteOperation} from 'view-modules/operations/abstract/abstract-delete.operation';
import {IRestCollection} from 'src/modules/rest/objects';
import {RestClient} from 'src/modules/rest/rest-client.service';

export class ACLApiScopeAddOperation extends AbstractOperationDefinition<'a_c_l_api_scopes'> {
    readonly endpoint = 'a_c_l_api_scopes';
    readonly name = 'post';
    lang = 'ACL_API_SCOPE_ADD';
    icon = 'fa-plus';
    component = (): Promise<unknown> => import('./acl-api-scope-form/acl-api-scope-form.component');
}

export class ACLApiScopeEditOperation extends AbstractOperationDefinition<'a_c_l_api_scopes'> {
    readonly endpoint = 'a_c_l_api_scopes';
    readonly name = 'put';
    lang = 'ACL_API_SCOPE_EDIT';
    icon = 'fa-edit';
    component = (): Promise<unknown> => import('./acl-api-scope-form/acl-api-scope-form.component');
}

export class ACLApiScopeDeleteOperation extends AbstractDeleteOperation<'a_c_l_api_scopes'> {
    readonly endpoint = 'a_c_l_api_scopes';
    lang = 'DELETE';
    confirmMessage = 'ACL_API_SCOPE_REMOVE_CONFIRM';
    confirmOptions = {
        langYes: 'ACL_API_SCOPE_REMOVE_CONFIRM_LANG_YES'
    };
}

export interface IACLApiScopePartnerAddContext {
    partnerIri: string,
    collection: IRestCollection<'a_c_l_api_scope_partners'>
}

export class ACLApiScopePartnerAddOperation
    extends AbstractOperationDefinition<string, IACLApiScopePartnerAddContext> {

    readonly endpoint = 'a_c_l_api_scope_partners';
    readonly name = 'post';
    lang = 'NEW_SCOPE';
    icon = 'fa-plus';
    component = (): Promise<unknown> => import('./acl-api-scope-partner-form/acl-api-scope-partner-form.component');

    access(context: IACLApiScopePartnerAddContext): boolean {
        if (RestClient.isRestCollection(context.collection)) {
            return context.collection.access(this.name);
        }

        return false;
    }
}

export class ACLApiScopePartnerEditOperation extends AbstractOperationDefinition<'a_c_l_api_scope_partners'> {
    readonly endpoint = 'a_c_l_api_scope_partners';
    readonly name = 'put';
    lang = 'EDIT_SCOPE';
    icon = 'fa-edit';
    component = (): Promise<unknown> => import('./acl-api-scope-partner-form/acl-api-scope-partner-form.component');
}

export class ACLApiScopePartnerDeleteOperation extends AbstractDeleteOperation<'a_c_l_api_scope_partners'> {
    readonly endpoint = 'a_c_l_api_scope_partners';
    lang = 'DELETE';
    confirmMessage = 'DELETE_SCOPE_CONFIRM';
    confirmOptions = {
        langYes: 'DELETE_SCOPE_CONFIRM_LANG_YES',
        text: 'ACTION_CANNOT_BE_UNDONE'
    };
}

export const aclApiScopesOperations = [
    ACLApiScopeAddOperation,
    ACLApiScopeEditOperation,
    ACLApiScopeDeleteOperation,
    ACLApiScopePartnerAddOperation,
    ACLApiScopePartnerEditOperation,
    ACLApiScopePartnerDeleteOperation
] as const;
