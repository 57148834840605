<div class="task-story">
    <div class="card-body">
        <dynamic-field [(ngModel)]="displayHistories"
                       (ngModelChange)="displayHistoriesToggle()"
                       [disabled]="loading"
                       [fieldConfig]="{
                           type: 'checkbox',
                           viewType: 'switch',
                           name: 'COMMON_TASK_VIEW_SHOW_CHANGE_HISTORY'
                       }">
        </dynamic-field>
    </div>

    <div class="card-body ml-0 pl-0 task-note-show-all-toggle text-center" *ngIf="taskStoryTotal > initialStoryLimit">
        <a href="javascript:" (click)="showAllToggle()" [attr.disabled]="loading">
            {{ (showAll ? 'COMMON_TASK_ACTIVITIES_HIDE_PREVIOUS' : 'COMMON_TASK_ACTIVITIES_SHOW_PREVIOUS') | translate }}
            <span *ngIf="!showAll">
                ({{ taskStoryTotal - initialStoryLimit }})
            </span>
        </a>
    </div>

    <div class="card-body ml-0 pl-0 task-story-element task-story-element-{{story.type}}"
            *ngFor="let story of taskStoryFiltered">
        <div class="row">
            <div class="d-none d-sm-block col-1 task-iconic-col">
                <avatar [user]="story.userLogin" class="avatar-sm"></avatar>
            </div>
            <div class="col-11 col-xs-12 task-content-col">
                <div class="row">
                    <div class="col">
                        <p class="small">
                            {{ story.author }}, {{ story.date | date }}
                            <span *ngIf="story.modified">
                                ({{ 'COMMON_EDITED' | translate | lowercase }}
                                {{ story.modified | date }})
                            </span>

                            <task-source-note *ngIf="story.to?.length"
                                              [objectSource]="story.to[0]">
                            </task-source-note>

                            <a href="javascript:"
                               class="ml-2"
                               *ngIf="story.canEdit"
                               (click)="taskViewComponent.taskNoteEditComponent.editNote(story.original)">
                                {{ 'EDIT' | translate }}
                            </a>
                        </p>
                        <div *ngIf="story.type == 'note'">
                            <div *ngIf="!story.showMore">
                                <div class="task-story-content">
                                    <wysiwyg-content [content]="story.text | shortText: 250"
                                                     [markDisabledMentions]="true">
                                    </wysiwyg-content>
                                </div>
                                <a href="javascript:" *ngIf="story.text.length > 250"
                                   (click)="story.showMore = !story.showMore">
                                    <p class="text-center task-content-show-more"><i class="fas fa-chevron-down"></i>
                                    </p>
                                </a>
                            </div>
                            <div *ngIf="story.showMore">
                                <div class="task-story-content">
                                    <wysiwyg-content [content]="story.text"
                                                     [markDisabledMentions]="true">
                                    </wysiwyg-content>
                                </div>
                                <a href="javascript:" (click)="story.showMore = !story.showMore">
                                    <p class="text-center task-content-show-more"><i class="fas fa-chevron-up"></i></p>
                                </a>
                            </div>
                        </div>

                        <div class="small" *ngIf="story.type === 'history'">
                            <event-history-item [item]="story.original">
                            </event-history-item>

                            <event-history-item *ngFor="let sub of story.group"
                                                [item]="sub.original">
                            </event-history-item>
                        </div>
                    </div>
                </div>
                <div class="row mt-2" *ngIf="story.files?.length > 0">
                    <div class="col">
                        <span *ngFor="let storyFile of story.files" class="task-attachment mr-2">
                            <i class="fas fa-paperclip"></i>
                            <a href="javascript:" (click)="downloadFile(storyFile)">
                                {{ storyFile.file.originalName }}
                            </a>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="card-body ml-0 pl-0" *ngIf="!taskStoryFiltered.length">
        <p class="blue-grey">
            {{'COMMON_LIST_IS_EMPTY' | translate }}
        </p>
    </div>

    <absolute-overlay [showLoader]="true" [lighterBackground]="true" *ngIf="loading"></absolute-overlay>
</div>
