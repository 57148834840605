import {Inject, Injectable, Injector} from '@angular/core';
import {ENV, IEnvironment, WINDOW} from 'app-custom-providers';
import {UrlParserService} from 'src/services/url-parser.service';
import {LocalStorage} from 'src/services/local-storage';
import {HttpMethod, IShortArrayNotationConditions} from 'src/modules/rest/interfaces';
import {HttpStatusCode} from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class RestClientConfig {
    protocol: string;
    host: string;
    port: string;
    path: string;
    static ACCESS_TOKEN_KEY = 'accessToken';
    static ACCESS_TOKEN_EXPIRES_KEY = 'accessExpires';
    static REFRESH_TOKEN_KEY = 'refreshToken';
    static REFRESH_TOKEN_EXPIRES_KEY = 'refreshExpires';
    static USER_DATA_KEY = 'user';
    static VALID_RESPONSE_CODES = {
        GET: [HttpStatusCode.Ok],
        POST: [HttpStatusCode.Created, HttpStatusCode.NoContent],
        PUT: [HttpStatusCode.Ok, HttpStatusCode.NoContent],
        DELETE: [HttpStatusCode.NoContent]
    };
    /**
     * Array of conditions for query builder which constructs request's query params.
     * When endpoint and queryProperty from condition matches and value is array,
     * then query builder reduces array to semicolon notation.
     * It is for reducing query params chars' length in GET request.
     * Max chars' length is set by HTTP protocol
    */
    static readonly SHORT_ARRAY_NOTATION_CONDITIONS: IShortArrayNotationConditions = [
        {
            endpoint: 'tasks/list',
            queryProperty: 'authorId'
        },
        {
            endpoint: 'tasks/list',
            queryProperty: 'assigneeId'
        },
        {
            endpoint: 'cooperation_notes/list',
            queryProperty: 'userLogin.id'
        }
    ];
    static injector: Injector;

    constructor(
        private urlParser: UrlParserService,
        @Inject(WINDOW) window: Window,
        @Inject(ENV) environment: IEnvironment,
        injector: Injector
    ) {
        RestClientConfig.injector = injector;
        this.protocol = environment.env.API_PROTOCOL || window.location.protocol;
        this.host = environment.env.API_HOST || window.location.hostname;
        this.port = environment.env.API_PORT || window.location.port;
        this.path = environment.env.API_PATH || '/';


        if (environment.env.API_URL) {
            this.setApiUrl(environment.env.API_URL);
        }
    }

    setApiUrl(url: string): this {
        const parsed = this.urlParser.parse(url);
        this.protocol = parsed.protocol;
        this.host = parsed.hostname;
        this.port = parsed.port;
        this.path = parsed.pathname;
        return this;
    }

    getApiUrl(withPath = true): string {
        return `${this.protocol}//${this.host}:${this.port}${withPath ? this.path : '/'}`;
    }

    isPublicEndpoint(endpoint: string, method: HttpMethod): boolean {
        return (['user_logins/update_password', 'oauth'].includes(endpoint) && method !== 'DELETE')
            || (method === 'GET' && /^translations\/[a-z]{2}\/common$/.test(endpoint));
    }

    /**
     * Returns Authorization header value if its available
     */
    static getAuthorizationHeader(): string | undefined {
        const token = LocalStorage.get(RestClientConfig.ACCESS_TOKEN_KEY, false);
        if (token !== false) {
            return `Bearer ${token}`;
        }
    }
}
