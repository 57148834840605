import {Inject, Injectable} from '@angular/core';
import {DOCUMENT} from '@angular/common';
import {EndpointName} from '_types/rest';
import {RestClientConfig} from 'src/modules/rest/rest-client-config.service';


export interface IParsedURL {
    hash: string,
    host: string,
    hostname: string,
    href: string,
    origin: string,
    pathname: string,
    port: string,
    protocol: string,
    search: string
}

export type QuerySerializable = string | number | boolean;

export interface IQueryObject {
    [key: string]:  string[] | number[] | {[subKey: string]: QuerySerializable} | QuerySerializable
}

@Injectable({
    providedIn: 'root'
})
export class UrlParserService {
    private _anchor: HTMLAnchorElement;

    constructor(
        @Inject(DOCUMENT) private document: Document
    ) {
        this._anchor = this.document.createElement('a');
    }

    parse(url: string): IParsedURL {
        this._anchor.href = this.href(url);
        return {
            hash: this._anchor.hash,
            host: this._anchor.host,
            hostname: this._anchor.hostname,
            href: this._anchor.href,
            origin: this._anchor.origin,
            pathname: this._anchor.pathname,
            port: this._anchor.port,
            protocol: this._anchor.protocol,
            search: this._anchor.search
        };
    }

    /**
     * Add "http://" if URL isn't relative or protocol is missing
     */
    href(url: string): string {
        if (/^(https?:)?\/{1,2}/.test(url)) {
            return url;
        } else {
            return `http://${url}`;
        }
    }

    /**
     * Convert HTTP query from object to string notation
     */
    buildQuery<TEndpoint extends EndpointName>(queryObject: IQueryObject | string, endpoint: TEndpoint): string {
        if (typeof queryObject === 'string') {
            return queryObject;
        }
        if (queryObject !== null && typeof queryObject === 'object' && Object.keys(queryObject).length) {
            const queryArray = [];
            Object.entries(queryObject).forEach(([key, item]) => {
                if (Array.isArray(item)) {
                    this.parseArray(key, item, endpoint, queryArray);
                } else if (typeof item === 'object' && item !== null) {
                    this.parseObject(key, item, queryArray);
                } else if (typeof item !== 'undefined') {
                    this.parsePrimitiveValue(key, item as QuerySerializable, queryArray);
                }
            });
            return queryArray.length ? '?' + queryArray.join('&') : '';
        }
        return '';
    }

    private parseArray<TEndpoint extends EndpointName>(
        queryProperty: keyof IQueryObject,
        queryValue: (string | number)[],
        endpoint: TEndpoint,
        queryArray: string[]
    ): void {
        if (this.shouldUseShortNotation(queryProperty, endpoint)) {
            const arrayAsString = queryValue.join(';');
            queryArray.push(`${queryProperty}=${arrayAsString}`);
            return;
        }

        queryValue.forEach((subItem) => {
            queryArray.push(`${queryProperty}[]=${subItem}`);
        });
    }

    private shouldUseShortNotation<TEndpoint extends EndpointName>(
        queryProperty: keyof IQueryObject,
        endpoint: TEndpoint
    ): boolean {
        return RestClientConfig.SHORT_ARRAY_NOTATION_CONDITIONS.some((condition) => {
            return condition.endpoint === endpoint && condition.queryProperty === queryProperty;
        });
    }

    private parseObject(
        queryProperty: keyof IQueryObject,
        queryValue: {[subKey: string]: QuerySerializable},
        queryArray: string[]
    ): void {
        Object.entries(queryValue).forEach(([subKey, subItem]) => {
            queryArray.push(`${queryProperty}[${subKey}]=${subItem}`);
        });
    }

    private parsePrimitiveValue(
        queryProperty: keyof IQueryObject,
        queryValue: QuerySerializable,
        queryArray: string[]
    ): void {
        queryArray.push(`${queryProperty}=${queryValue}`);
    }
}
