import {Inject, Injectable} from '@angular/core';
import {AnalyticsService} from 'src/modules/analytics/analytics.service';
import {WINDOW} from 'app-custom-providers';
import {LazyLoadService} from 'src/services/lazy-load.service';


@Injectable({
    providedIn: 'root'
})
export class AnalyticsTrackersService {
    constructor(
        private analyticsService: AnalyticsService,
        @Inject(WINDOW) private window: Window,
        private lazyLoad: LazyLoadService
    ) {
    }


    configTrackers(): void {
        const window = (this.window as Window & {
            // eslint-disable-next-line  @typescript-eslint/no-explicit-any
            [key: string]: any;
        });

        this.analyticsService.addTracker(
            'gtm',
            (trackerId: string) => {
                window.dataLayer = window.dataLayer || [];
                window.dataLayer.push({
                    'gtm.start':
                        new Date().getTime(), event: 'gtm.js'
                });
                return this.lazyLoad.getScript(`https://www.googletagmanager.com/gtm.js?id=${trackerId}`); // GTM-57CG3XK
            }
        );

        this.analyticsService.addTracker(
            'hotjar',
            (trackerId) => {
                window.hj = window.hj || function(...args) {
                    (window.hj.q = window.hj.q || []).push(args);
                };
                // noinspection SpellCheckingInspection
                window._hjSettings = {
                    hjid: trackerId,
                    hjsv: 6
                };
                return this.lazyLoad.getScript(
                    `https://static.hotjar.com/c/hotjar-${window._hjSettings.hjid}.js?sv=${window._hjSettings.hjsv}`
                );
            },
            (eventName, eventValue) => {
                window.hj('trigger', `${eventName}-${eventValue}`);
            }
        );

        this.analyticsService.addTracker(
            'lucky_orange',
            (trackerId: string) => {
                window['__lo_site_id'] = trackerId;
                return this.lazyLoad.getScript(
                    'https://d10lpsik1i8c69.cloudfront.net/w.js'
                );
            }
        );

        this.analyticsService.addTracker(
            'useberry',
            () => {
                return this.lazyLoad.getScript(
                    'https://api.useberry.com/integrations/liveUrl/scripts/useberryScript.js'
                );
            }
        );
    }
}
