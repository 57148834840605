import {Component, TemplateRef} from '@angular/core';
import {
    IBadgeTemplateContext,
    IDynamicFieldTemplateContext,
    IIconTemplateContext,
    IPlainTemplateContext,
    IStateUrlContext,
    IUserTemplateContext,
    ITableCellTemplateContext,
    ITitleContext
} from './interfaces';
import {BOOTSTRAP_COLORS, BootstrapColor} from 'src/modules/colors/colors.constant';


type ICellTemplatesComponent = {
    [P in ITableCellTemplateContext['type']]: TemplateRef<Extract<ITableCellTemplateContext, { type: P }>>
}

type IBootstrapColorMap = {
    [key in BootstrapColor]: boolean;
}

@Component({
    selector: 'cell-templates',
    templateUrl: './cell-templates.component.html'
})
export class CellTemplatesComponent implements ICellTemplatesComponent {
    plain: TemplateRef<IPlainTemplateContext>;
    badge: TemplateRef<IBadgeTemplateContext>;
    icon: TemplateRef<IIconTemplateContext>;
    dynamicField: TemplateRef<IDynamicFieldTemplateContext>;
    stateUrl: TemplateRef<IStateUrlContext>;
    user: TemplateRef<IUserTemplateContext>;
    title: TemplateRef<ITitleContext>;

    bootstrapColors = CellTemplatesComponent.getBootstrapColors();

    private static getBootstrapColors(): IBootstrapColorMap {
        return BOOTSTRAP_COLORS.reduce((finalObject, color) => {
            finalObject[color] = true;
            return finalObject;
        }, {}) as IBootstrapColorMap;
    }
}
