import {NgModule} from '@angular/core';
import {CmsModalComponent} from 'src/modules/global-components/cms-modal/cms-modal.component';
import {BrowserModule} from '@angular/platform-browser';
import {BsModalModule} from 'angular-bootstrap4';
import {TranslateModule} from 'src/modules/translate/translate.module';
import {AppCommonModule} from 'src/modules/app-common/app-common.module';


@NgModule({
    imports: [
        AppCommonModule,
        BrowserModule,
        BsModalModule,
        TranslateModule
    ],
    declarations: [
        CmsModalComponent
    ],
    exports: [
        CmsModalComponent
    ]
})
export class CmsModalModule { }
