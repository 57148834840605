import {NgModule} from '@angular/core';
import {ActionsTableDirective} from './actions-table.directive';
import {BsDropdownModule, BsModalModule} from 'angular-bootstrap4';
import {ActionsComponent} from 'src/modules/actions/actions.component';
import {ActionsTitleDirective} from 'src/modules/actions/actions-title.directive';
import {ActionsBoundaryDirective} from 'src/modules/actions/actions-boundary.directive';
import {TranslateModule} from 'src/modules/translate/translate.module';
import {AppCommonModule} from 'src/modules/app-common/app-common.module';
import {AppDirectivesModule} from 'src/directives/app-directives.module';

@NgModule({
    imports: [
        AppCommonModule,
        AppDirectivesModule,
        BsDropdownModule,
        BsModalModule,
        TranslateModule
    ],
    declarations: [
        ActionsBoundaryDirective,
        ActionsComponent,
        ActionsTableDirective,
        ActionsTitleDirective
    ],
    exports: [
        ActionsBoundaryDirective,
        ActionsComponent,
        ActionsTableDirective,
        ActionsTitleDirective
    ]
})

export class ActionsModule { }
