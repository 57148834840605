import {NgModule} from '@angular/core';
import {FilePreviewModalComponent} from 'src/modules/global-components/file-preview-modal/file-preview-modal.component';
import {BsModalModule} from 'angular-bootstrap4';
import {TranslateModule} from 'src/modules/translate/translate.module';
import {AppCommonModule} from 'src/modules/app-common/app-common.module';


@NgModule({
    declarations: [
        FilePreviewModalComponent
    ],
    imports: [
        AppCommonModule,
        BsModalModule,
        TranslateModule,
    ],
    exports: [
        FilePreviewModalComponent
    ]
})
export class FilePreviewModule {
}
