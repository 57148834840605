<ng-template #iconTemplate>
    <i class="fa"
       [ngClass]="(operation.pending && loadingIcon)
           ? 'fa-circle-notch fa-spin'
           : (customIcon || operation.icon)">
    </i>
</ng-template>

<ng-template #langTemplate>
    <span class="ml-1">
        {{(customLang || operation.lang) | translate}}
    </span>
</ng-template>

<ng-container *ngIf="access" [ngSwitch]="viewType">
    <ng-container *ngSwitchCase="'icons'">
        <a [title]="!removeTitle
               ? ((customTitle || operation.lang) | translate)
               : ''"
           [ngClass]="[
               operation.color,
               operation.pending || disabled ? 'disabled' : '',
               customClass ? customClass : ''
           ]"
           [locator]="'operation_' + operation.endpoint + '_' + operation.name"
           (click)="execute()"
           noAnimate>
            <ng-container *ngTemplateOutlet="iconTemplate"></ng-container>
        </a>
    </ng-container>

    <ng-container *ngSwitchCase="'buttons'">
        <a class="btn btn-sm mr-2"
           [ngClass]="[
               'btn-outline-' + operation.color,
               operation.pending || disabled ? 'disabled' : '',
               customClass ? customClass : ''
           ]"
           [locator]="'operation_' + operation.endpoint + '_' + operation.name"
           (click)="execute()"
           noAnimate>
            <ng-container *ngTemplateOutlet="iconTemplate"></ng-container>
            <ng-container *ngTemplateOutlet="langTemplate"></ng-container>
        </a>
    </ng-container>

    <ng-container *ngSwitchCase="'buttons-normal'">
        <a class="btn btn-sm mr-2"
           [title]="!removeTitle
               ? ((customTitle || operation.lang ) | translate)
               : ''"
           [ngClass]="[
               'btn-' + operation.color,
               operation.pending || disabled ? 'disabled' : '',
               customClass ? customClass : ''
           ]"
           [locator]="'operation_' + operation.endpoint + '_' + operation.name"
           (click)="execute()"
           noAnimate>
            <ng-container *ngTemplateOutlet="iconTemplate"></ng-container>
            <ng-container *ngTemplateOutlet="langTemplate"></ng-container>
        </a>
    </ng-container>

    <ng-container *ngSwitchCase="'text'">
        <a [title]="!removeTitle
               ? ((customTitle || operation.lang ) | translate)
               : ''"
           [ngClass]="[
               operation.color,
               operation.pending || disabled ? 'disabled' : '',
               customClass ? customClass : ''
           ]"
           [locator]="'operation_' + operation.endpoint + '_' + operation.name"
           (click)="execute()"
           noAnimate>
            <ng-container *ngTemplateOutlet="iconTemplate"></ng-container>
            <ng-container *ngTemplateOutlet="langTemplate"></ng-container>
        </a>
    </ng-container>
</ng-container>
