import {
    AfterContentInit,
    ContentChildren,
    Directive,
    DoCheck, ElementRef,
    Inject,
    Input,
    Optional,
    QueryList, Renderer2, Self, SkipSelf
} from '@angular/core';
import {BsModalDirective} from 'angular-bootstrap4';
import {FormGroup, FormGroupDirective, NgForm} from '@angular/forms';
import {InputLoadingDirective} from 'src/directives/input-loading/input-loading.directive';
import {RequiredDirective} from 'src/modules/app-forms/required.directive';
import {TranslateService} from 'src/modules/translate/translate.service';

export interface AppModalForm {
    ngForm?: NgForm
    formGroup?: FormGroupDirective,
    _confirmed: boolean
}

export interface AppModalDirective extends BsModalDirective {
    form?: AppModalForm;
    disableAbandonWarning: boolean;
    operationalModalExtras?: {
        performedModalAction?: {
            minimized?: boolean;
        };
    };
}


@Directive({
    selector: 'form:not([ngNoForm]),ng-form,[ngForm],[formGroup]:not([ngNoForm]),[formGroupName]'
})
export class AppFormDirective implements AfterContentInit, DoCheck {
    @Input() formBeingSubmitted = false;
    @Input() noLegend = false;
    @Input() disableAbandonWarning = false;
    private legend: HTMLElement;
    private legendParent: HTMLElement;
    private form: FormGroup;
    private _hasRequired = false;
    public hasRequiredChildren = false;

    @ContentChildren(InputLoadingDirective, {descendants: true})
    private inputLoadings: QueryList<InputLoadingDirective>;
    @ContentChildren(RequiredDirective, {descendants: true})
    private requiredDirectives: QueryList<RequiredDirective>;

    constructor(
        @Optional() @Inject(BsModalDirective) private modalDirective: AppModalDirective,
        @Optional() @Self() private ngForm: NgForm,
        @Optional() @Self() private formGroup: FormGroupDirective,
        private elementRef: ElementRef,
        private renderer: Renderer2,
        private translateService: TranslateService,
        @Optional() @SkipSelf() private parentForm: AppFormDirective
    ) {
        if (modalDirective) {
            this.assignFormToModalDirective();
        }
    }

    ngAfterContentInit(): void {
        this.form = this.formGroup ? this.formGroup.form : this.ngForm.form;
        if (this.modalDirective) {
            this.modalDirective.disableAbandonWarning = this.disableAbandonWarning;
        }
        if (!this.noLegend) {
            let target = null;
            ['.modal-body', '.card-body'].some((selector) => {
                target = this.elementRef.nativeElement.querySelector(selector);
                return target !== null;
            });
            this.legendParent = target ? target : this.elementRef.nativeElement;
            this.prepareLegend();
        }
    }

    ngDoCheck(): void {
        if (this.noLegend || !this.form) {
            return;
        }

        const hasRequired = this.hasRequiredChildren || this.hasRequiredFields();

        if (this.parentForm) {
            this.updateParentFormLegend(hasRequired);
            return;
        }

        if (
            this._hasRequired !== hasRequired
            && !this.parentForm
        ) {
            this._hasRequired = hasRequired;
            this.setLegendVisibility(hasRequired);
        }
    }

    private updateParentFormLegend(required: boolean): void {
        if (this.parentForm) {
            this.parentForm.hasRequiredChildren = required;
        }
    }

    prepareLegend(): void {
        const legend = this.renderer.createElement('div');
        this.renderer.addClass(legend, 'legend');
        this.renderer.addClass(legend, 'form-group');
        this.renderer.setProperty(
            legend,
            'innerHTML',
            '<span class="required-star">*</span> - '
            + this.translateService.get('INDICATE_REQUIRED_FIELDS')
        );
        this.legend = legend;
    }

    private setLegendVisibility(isLegendVisible: boolean): void {
        if (isLegendVisible) {
            this.renderLegend();
        } else {
            this.removeLegend();
        }
    }

    private renderLegend(): void {
        const parentElement = this.legendParent ? this.legendParent : this.elementRef.nativeElement;
        this.renderer.insertBefore(
            parentElement,
            this.legend,
            parentElement.children?.item(0)
        );
    }

    private removeLegend(): void {
        this.renderer.removeChild(this.elementRef.nativeElement, this.legend);
    }

    hasRequiredFields(): boolean {
        if (!this.form.controls) {
            return false;
        }
        return this.requiredDirectives.toArray().some((item) => {
            return item.isRequired;
        });
    }

    shouldDisableFormSubmission(): boolean {
        return this.isInitializing()
            || !this.isValid()
            || this.isBeingSubmitted();
    }

    /**
     * Check if form has any child input which is still initializing.
     * */
    private isInitializing(): boolean {
        const inputLoadings = this.inputLoadings?.toArray();
        return inputLoadings ? inputLoadings.some((element) => element.initializing) : false;
    }

    private isBeingSubmitted(): boolean {
        return this.formBeingSubmitted;
    }

    private isValid(): boolean {
        if (this.form) {
            return this.form.valid;
        }
        return true;
    }

    /**
     * Assign AppModalForm to parent modal Directive
     * */
    private assignFormToModalDirective(): void {
        if (this.parentForm) {
            return;
        }

        this.modalDirective.form = {
            ngForm: this.ngForm,
            formGroup: this.formGroup,
            _confirmed: false
        };
    }
}
