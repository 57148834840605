import {AbstractOperationDefinition, IOperationResult} from 'src/modules/operations/interfaces';
import {IRestObject} from 'src/modules/rest/objects';
import {Observable} from 'rxjs';
import {Injector} from '@angular/core';
import {RestClient} from 'src/modules/rest/rest-client.service';
import {map, tap} from 'rxjs/operators';

export class AddSapBusinessPartnerOperation
    extends AbstractOperationDefinition<'sap_business_partners'> {
    readonly endpoint = 'sap_business_partners';
    readonly name = 'post';
    lang = 'ADD';
    icon = 'fa-plus';

    component = (): Promise<unknown> => import('./sap-business-partner-form/sap-business-partner-form.component');
}

export class ViewSapBusinessPartnerOperation
    extends AbstractOperationDefinition<'sap_business_partners'> {
    readonly endpoint = 'sap_business_partners';
    readonly name = 'view';
    lang = 'VIEW_SAP_CUSTOMER';
    icon = 'fa-eye';

    component = (): Promise<unknown> => import('./sap-business-partner-form/sap-business-partner-form.component');

    access(): boolean {
        return true;
    }
}

export class ReplicateSapBusinessPartnerOperation
    extends AbstractOperationDefinition<'sap_business_partners'> {

    readonly endpoint = 'sap_business_partners';
    readonly name = 'replicate';
    lang = 'REPLICATE_SAP_CUSTOMER';
    icon = 'fa-sync';

    invoke(
        context: IRestObject<'sap_business_partners'>,
        injector: Injector
    ): void | boolean | Observable<IOperationResult> {
        const restClient = injector.get(RestClient);

        return restClient
            .endpoint('sap_business_partners/replicate')
            .get(context.id)
            .pipe(tap(() => {
                restClient.savedToast();
            }))
            .pipe(map(() => {
                return {
                    success: true
                };
            }));
    }

    access(context: IRestObject<'sap_business_partners'>): boolean {
        return context.access(this.name) && !context.businessPartner;
    }
}

export class ViewSapSalesOrderOperation
    extends AbstractOperationDefinition<'sap_sales_orders'> {
    readonly endpoint = 'sap_sales_orders';
    readonly name = 'view';
    lang = 'VIEW';
    icon = 'fa-eye';

    component = (): Promise<unknown> => import('./sap-sales-order-form/sap-sales-order-form.component');

    access(): boolean {
        return true;
    }
}

export class AddSapSalesOrderOperation extends AbstractOperationDefinition<'sap_sales_orders'> {
    readonly endpoint = 'sap_sales_orders';
    readonly name = 'post';
    lang = 'ADD';
    icon = 'fa-plus';

    component = (): Promise<unknown> => import('./sap-sales-order-form/sap-sales-order-form.component');
}

export class EditSapSalesOrderOperation extends AbstractOperationDefinition<'sap_sales_orders'> {
    readonly endpoint = 'sap_sales_orders';
    readonly name = 'put';
    lang = 'EDIT';
    icon = 'fa-edit';

    component = (): Promise<unknown> => import('./sap-sales-order-form/sap-sales-order-form.component');

    access(context: IRestObject<'sap_sales_orders'>): boolean {
        return context.access(this.name) && !context.salesOrder;
    }
}

export class CopySapSalesOrderOperation extends AbstractOperationDefinition<'sap_sales_orders'> {
    readonly endpoint = 'sap_sales_orders';
    readonly name = 'copy';
    lang = 'COPY';
    icon = 'fa-copy';

    component = (): Promise<unknown> => import('./sap-sales-order-form/sap-sales-order-form.component');

    access(): boolean {
        return true;
    }
}

export class SendSapSalesOrderOperation
    extends AbstractOperationDefinition<'sap_sales_orders'> {

    readonly endpoint = 'sap_sales_orders';
    readonly name = 'send';
    lang = 'SEND_SAP_SALES_ORDER';
    icon = 'fa-sync';

    invoke(
        context: IRestObject<'sap_sales_orders'>,
        injector: Injector
    ): void | boolean | Observable<IOperationResult> {
        const restClient = injector.get(RestClient);

        return restClient
            .endpoint('sap_sales_orders/send')
            .get(context.id)
            .pipe(tap(() => {
                restClient.savedToast();
            }))
            .pipe(map(() => {
                return {
                    success: true
                };
            }));
    }

    access(context: IRestObject<'sap_sales_orders'>): boolean {
        return context.access(this.name) && !context.salesOrder;
    }
}

export const sapOperations = [
    AddSapBusinessPartnerOperation,
    ViewSapBusinessPartnerOperation,
    ReplicateSapBusinessPartnerOperation,
    AddSapSalesOrderOperation,
    ViewSapSalesOrderOperation,
    EditSapSalesOrderOperation,
    CopySapSalesOrderOperation,
    SendSapSalesOrderOperation
] as const;
