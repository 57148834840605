import {Injectable} from '@angular/core';
import {ICms} from '_types/rest/Entity/IRestCms';
import {RestClient} from 'src/modules/rest/rest-client.service';
import {IPubConfigFrontCms} from '_types/pub/config/front';
import {ConfigService} from 'src/services/config.service';
import {IGdprConsent} from '_types/custom/IGdprConsent';

@Injectable({
    providedIn: 'root'
})
export class CmsModalService {
    loading = true;
    modal = false;
    cms: ICms | IPubConfigFrontCms| IGdprConsent;

    constructor(
        private readonly restClient: RestClient,
        private readonly configService: ConfigService
    ) {
    }

    showGdprConsentModal(consent: IGdprConsent): void {
        this.modal = true;
        this.cms = consent;
        this.loading = false;
    }

    openCmsModal(id: number): void {
        this.loading = true;
        this.modal = true;

        if (typeof this.cms !== 'undefined' && this.cms.id === id) {
            this.loading = false;
            return;
        }

        this.cms = this.configService.get('cms').find((cms) => cms.id === id);

        if (this.cms) {
            this.loading = false;
        } else {
            this.restClient.endpoint('cms').get(id)
                .subscribe((response) => {
                    this.cms = response;
                    this.loading = false;
                });
        }
    }
}
