import {Component} from '@angular/core';
import {CmsModalService} from 'src/modules/global-components/cms-modal/cms-modal.service';


@Component({
    selector: 'cms-modal',
    templateUrl: './cms-modal.component.html'
})
export class CmsModalComponent {
    constructor(
        public cmsModalService: CmsModalService
    ) {
    }
}
