import {AbstractOperationDefinition} from 'src/modules/operations/interfaces';
import {AbstractDeleteOperation} from 'view-modules/operations/abstract/abstract-delete.operation';
import {IConfirmModalOptions} from 'src/modules/global-components/confirm-modal/confirm-modal.service';

export class CmsTypeAddOperation extends AbstractOperationDefinition<'cms_types'> {
    readonly endpoint = 'cms_types';
    readonly name = 'post';
    lang = 'NEW_CMS_TYPE';
    icon = 'fa-plus';
    component = (): Promise<unknown> => import('./cms-type-form/cms-type-form.component');
}

export class CmsTypeEditOperation extends AbstractOperationDefinition<'cms_types'> {
    readonly endpoint = 'cms_types';
    readonly name = 'put';
    lang = 'EDIT_CMS_TYPE';
    icon = 'fa-edit';
    component = (): Promise<unknown> => import('./cms-type-form/cms-type-form.component');
}

export class CmsTypeDeleteOperation extends AbstractDeleteOperation<'cms_types'> {
    readonly endpoint = 'cms_types';
    lang = 'DELETE';
    confirmMessage = 'DELETE_CMS_TYPES_CONFIRM';
    confirmOptions: IConfirmModalOptions = {
        langYes: 'DELETE_CMS_TYPES_CONFIRM_LANG_YES',
        text: 'ACTION_CANNOT_BE_UNDONE',
        primaryBtn: 'danger'
    };
}

export const cmsTypeOperations = [
    CmsTypeAddOperation,
    CmsTypeEditOperation,
    CmsTypeDeleteOperation
] as const;
