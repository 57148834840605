<div #popoverParent>
    <ng-content></ng-content>
</div>

<ng-container *ngIf="legendItem$ | async as legendItem">
    <bs-popover placement="auto bottom"
                [parentElement]="popoverParent"
                absoluteToDocument>
        <div title>
            {{legendItem.name | translate}}
        </div>

        <div class="legend-content" content>
            <div *ngIf="legendItem.description"
                 class="mb-3">
                {{legendItem.description | translate}}
            </div>

            <div class="d-flex justify-content-between">
                <button class="btn btn-sm"
                        (click)="legendService.toggleLegend()">
                    {{'LEGEND_CLOSE' | translate}}
                </button>

                <button *ngIf="!legendItem.lastItem"
                        class="btn btn-sm btn-primary text-nowrap"
                        (click)="legendService.nextLegendItem()">
                    {{'LEGEND_NEXT' | translate}}
                </button>
            </div>
        </div>
    </bs-popover>
</ng-container>
