import {
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Optional,
    Output
} from '@angular/core';
import {ICategoryDynamicFieldConfig, IDynamicFieldControlValueAccessor} from 'src/modules/dynamic-fields/interfaces';
import {FormControl} from '@angular/forms';
import {
    AbstractUnvalidatedControlValueAccessor
} from 'src/modules/app-forms/abstract-control-value-accessors/abstract-unvalidated-control-value-accessor';
import {BehaviorSubject, ReplaySubject, Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import isEqual from 'lodash.isequal';
import {CategoriesService} from 'src/modules/categories/categories.service';
import {ICategory} from '_types/rest';
import {IEntityCategory, IEntityCategoryNode} from '_types/custom/IEntityCategory';
import { EditableFieldComponent } from 'src/modules/editable-field/editable-field.component';

type CategorySelectValueType = string | number | (string | number)[];

@Component({
    selector: 'category-select',
    templateUrl: './category-select.component.html',
    styleUrls: ['./category-select.component.scss'],
    providers: AbstractUnvalidatedControlValueAccessor.getProviders(CategorySelectComponent)
})
export class CategorySelectComponent
    extends AbstractUnvalidatedControlValueAccessor
    implements IDynamicFieldControlValueAccessor, OnInit, OnDestroy {

    @Input() fieldConfig: ICategoryDynamicFieldConfig;

    objectValue: IEntityCategory | IEntityCategory[];
    @Output() readonly objectValueChange = new EventEmitter<IEntityCategory | IEntityCategory[]>();
    @Output() readonly initializing = new BehaviorSubject<boolean>(true);

    private defaultConfig: Partial<ICategoryDynamicFieldConfig> = {
        valueKey: 'iri',
        property: 'categories',
        layoutType: null,
        multiple: false
    };

    nestedCategories: IEntityCategoryNode[];
    loading = true;
    categoryControl = new FormControl(null);

    value: CategorySelectValueType;
    private gettingCategoriesSubject: ReplaySubject<IEntityCategoryNode[]>;

    private readonly _destroy$ = new Subject<void>();

    constructor(
        @Optional() public editableFieldComponent: EditableFieldComponent<ICategory>,
        private readonly categoriesService: CategoriesService,
        private readonly changeDetector: ChangeDetectorRef
    ) {
        super();
    }

    ngOnInit(): void {
        this.initializeConfig();
        this.subscribeFormControl();
        this.prepareData();
    }


    private initializeConfig(): void {
        const temp = Object.assign(this.defaultConfig, this.fieldConfig);

        Object.assign(this.fieldConfig, temp);
    }


    private subscribeFormControl(): void {
        this.categoryControl.valueChanges
            .pipe(
                takeUntil(this._destroy$),
            )
            .subscribe((value: CategorySelectValueType) => {
                this.markAsTouched();
                this.updateControlValue(value);
                this._onChange(value);
            });
    }


    private updateControlValue(value: CategorySelectValueType) {
        const newObjectValue: IEntityCategory | IEntityCategory[] = this.getObjectValue(value);

        this.objectValue = newObjectValue;
        this.objectValueChange.emit(newObjectValue);

        this.value = value;
    }

    private prepareData(): void {
        this.gettingCategoriesSubject = this.categoriesService
            .getCategories(this.fieldConfig.endpoint, this.fieldConfig.property);

        this.gettingCategoriesSubject.subscribe((nestedCategories) => {
            this.nestedCategories = nestedCategories;

            this.loading = false;

            this.initializing.next(false);
            this.changeDetector.detectChanges();
        });
    }

    writeValue(value: CategorySelectValueType | ICategory | ICategory[] | null): void {
        let newValue: CategorySelectValueType;
        const modelKey = this.fieldConfig.valueKey === 'iri' ? '@id' : this.fieldConfig.valueKey;

        if (!value) {
            this.categoryControl.setValue(null, {emitEvent: false});
            return;
        }

        if (this.fieldConfig.multiple) {
            newValue = (value as []).map((item) => {
                const isObject = item !== null && typeof item === 'object';
                return isObject ? item[modelKey] : (item as string | number);
            });
        } else {
            const isObject = typeof value === 'object';
            newValue = isObject ? value[modelKey] : (value as string | number);
        }

        // set value & objectValue when categories are fetched
        this.gettingCategoriesSubject.subscribe(() => {
            this.updateControlValue(newValue);

            if (!isEqual(this.categoryControl.value, newValue)) {
                this.categoryControl.setValue(newValue, {emitEvent: false});
            }
        });
    }

    private getObjectValue(value: CategorySelectValueType): IEntityCategory | IEntityCategory[] {
        if (Array.isArray(value)) {
            return value.map((value) => {
                return this.getObjectValueByValue(value);
            });
        }
        return this.getObjectValueByValue(value);
    }

    private getObjectValueByValue(value: number | string): IEntityCategory {
        return typeof value === 'number'
            ? this.categoriesService.getCategoryById(value)
            : this.categoriesService.getCategoryByIri(value);
    }

    setDisabledState(isDisabled: boolean): void {
        if (isDisabled) {
            this.categoryControl.disable({emitEvent: false});
            return;
        }
        this.categoryControl.enable({emitEvent: false});
    }

    ngOnDestroy(): void {
        this._destroy$.next();
        this._destroy$.complete();
    }
}
