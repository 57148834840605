import {Component, Input, ViewChild} from '@angular/core';
import {ISelectDynamicFieldConfig} from 'src/modules/dynamic-fields/interfaces';
import {EsOptionsDirective} from 'angular-bootstrap4-extended-select';
import {TranslateService} from 'src/modules/translate/translate.service';

@Component({
    selector: 'es-options',
    templateUrl: './es-options.component.html'
})
export class EsOptionsComponent {
    @Input() fieldConfig: ISelectDynamicFieldConfig;
    @ViewChild(EsOptionsDirective, {static: true}) esOptions?: EsOptionsDirective<unknown>;

    constructor(
        public translateService: TranslateService
    ) {
    }
}
