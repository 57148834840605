import {Inject, Injectable} from '@angular/core';
import {Utils} from 'src/services/utils';
import {DOCUMENT} from '@angular/common';
import {ENV, IEnvironment} from 'app-custom-providers';
import {LazyLoadService} from 'src/services/lazy-load.service';
import {from, Observable, of} from 'rxjs';
import {tap} from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class FontService {
    private googleFonts = [];
    private systemFonts = [];
    private appFonts = [];
    private fontSizes = [];
    private fontFamilyFallback = 'sans-serif';
    private APP: IEnvironment['APP'];

    constructor(
        private readonly lazyLoadService: LazyLoadService,
        @Inject(DOCUMENT) private readonly document: Document,
        @Inject(ENV) private readonly env: IEnvironment
    ) {
        this.APP = env.APP;
    }

    setGoogleFonts(fonts: string[]): void {
        this.googleFonts = Utils.clone(fonts);
    }

    setSystemFonts(fonts: string[]): void {
        this.systemFonts = Utils.clone(fonts);
    }

    setAppFonts(fonts: string[]): void {
        this.appFonts = Utils.clone(fonts);
    }

    setAvailableFontSizes(fonts: string[]): void {
        this.fontSizes = Utils.clone(fonts);
    }

    setFontFamilyFallback(fontFamilyFallback: string): void {
        this.fontFamilyFallback = fontFamilyFallback;
    }

    getFonts(): string[] {
        return this.googleFonts;
    }

    getSystemFonts(): string[] {
        return this.systemFonts;
    }

    getAppFonts(): string[] {
        return this.appFonts;
    }

    getFontSizes(): string[] {
        return this.fontSizes;
    }

    getDefaultFont(): string {
        return this.googleFonts[0];
    }

    getGoogleFontUrl(font: string): string {
        return `https://fonts.googleapis.com/css?family=${font.replace(/ /g, '+')}:${this.fontSizes.join(',')}`;
    }

    loadFont(font: string): Observable<unknown> {
        if (this.systemFonts.includes(font)) {
            return of(undefined);
        }

        if (this.appFonts.includes(font)) {
            return from(import(`./app-fonts/${font.toLowerCase().replace(/ /g, '-')}.lazy.scss`))
                .pipe(tap((module) => {
                    module.default.use();
                }));
        }

        return this.lazyLoadService.getCss(this.getGoogleFontUrl(font));
    }

    setBodyFont(font: string): void {
        this.loadFont(font).subscribe();
        this.document.querySelector('body').style.fontFamily = `"${font}", ${this.fontFamilyFallback}`;
    }
}
