/* eslint-disable max-len */
/**
 * This file has been auto generated. Do not edit.
 */
import {ITask} from '_types/rest/Entity/IRestTask';
import {IRestTask} from '_types/rest/Entity/IRestTask';
import {IBaseRestEntity} from '_types/rest/IBaseRestEntity';
import {IRestObject} from 'core/rest/rest-object.service';

export const TaskProcessingFlagFlag = {
    TASK_FLAG_FLAG_COMPLETE_TASK_WHEN_SUBTASKS_ARE_DONE: 1,
    TASK_FLAG_FLAG_USE_REOPEN_STATE: 2,
    TASK_FLAG_FLAG_PREVIOUS_BUSINESS_DAY_INSTEAD_OF_NEXT: 3
} as const;

export const TaskProcessingFlagFlagOptions = [
    {
        value: TaskProcessingFlagFlag.TASK_FLAG_FLAG_COMPLETE_TASK_WHEN_SUBTASKS_ARE_DONE,
        label: 'TASK_FLAG_FLAG_COMPLETE_TASK_WHEN_SUBTASKS_ARE_DONE',
    },
    {
        value: TaskProcessingFlagFlag.TASK_FLAG_FLAG_USE_REOPEN_STATE,
        label: 'TASK_FLAG_FLAG_USE_REOPEN_STATE',
    },
    {
        value: TaskProcessingFlagFlag.TASK_FLAG_FLAG_PREVIOUS_BUSINESS_DAY_INSTEAD_OF_NEXT,
        label: 'TASK_FLAG_FLAG_PREVIOUS_BUSINESS_DAY_INSTEAD_OF_NEXT',
    }
] as const;

export interface ITaskProcessingFlagConst {
    flag: [{id: 1, value: 'TASK_FLAG_FLAG_COMPLETE_TASK_WHEN_SUBTASKS_ARE_DONE'}, {id: 2, value: 'TASK_FLAG_FLAG_USE_REOPEN_STATE'}, {id: 3, value: 'TASK_FLAG_FLAG_PREVIOUS_BUSINESS_DAY_INSTEAD_OF_NEXT'}]
}

export interface ITaskProcessingFlag extends IBaseRestEntity {
    id?: number,
    /**
     * @default 1
     */
    flag: typeof TaskProcessingFlagFlag[keyof typeof TaskProcessingFlagFlag],
    task: ITask | string,
    /**
     * @description Record status
     *  0 = deleted
     *  1 = active.
     * @default 1
     */
    status?: number,
    /**
     * @description Creation timestamp.
     * @default CURRENT_TIMESTAMP
     * @format date-time
     */
    created?: string,
    /**
     * @description Last modification timestamp.
     * @format date-time
     */
    modified?: string | null
}

/** @deprecated */
export interface IRestTaskProcessingFlag extends ITaskProcessingFlag, IRestObject {
    task: IRestTask | string,
}
