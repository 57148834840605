import {IConfirmModalOptions} from 'src/modules/global-components/confirm-modal/confirm-modal.service';
import {AbstractDeleteOperation} from 'view-modules/operations/abstract/abstract-delete.operation';
import {AbstractOperationDefinition} from 'src/modules/operations/interfaces';
import {IRestCollection, IRestObject} from 'src/modules/rest/objects';


export interface IPartnerProductContext {
    product: IRestObject<'products'>;
    partnerProducts: IRestCollection<'products'>;
}

export class PartnerProductsEditOperation
    extends AbstractOperationDefinition<string, IPartnerProductContext> {
    readonly endpoint = 'products';
    readonly name = 'put';
    lang = 'EDIT_PRODUCT';
    icon = 'fa-edit';
    component = (): Promise<unknown> => import('./partner-product-form/partner-product-form.component');

    access(context: IPartnerProductContext): boolean {
        return context.product.access(this.name);
    }
}

export class PartnerProductsDeleteOperation
    extends AbstractDeleteOperation<'products'>  {
    readonly endpoint = 'products';
    lang = 'DELETE_PRODUCT_FROM_PARTNER';
    confirmMessage = 'CONFIRM_DELETE_PRODUCT_FROM_PARTNER';
    confirmOptions: IConfirmModalOptions = {
        primaryBtn: 'danger',
        langYes: 'CONFIRM_DELETE_PRODUCT_FROM_PARTNER_LANG_YES'
    };
}

export const partnerProductsOperations = [
    PartnerProductsEditOperation,
    PartnerProductsDeleteOperation
] as const;
