import {EndpointName, With} from '_types/rest';
import {Injector} from '@angular/core';
import {RestClient} from 'src/modules/rest/rest-client.service';
import {Observable, Subject} from 'rxjs';
import {ConfirmModalService} from 'src/modules/global-components/confirm-modal/confirm-modal.service';
import {IRestObject} from 'src/modules/rest/objects';
import {AbstractOperationDefinition} from 'src/modules/operations/interfaces';
import {AccessPipe} from 'src/pipes/access.pipe';
import {tap} from 'rxjs/operators';
import {IEntityCategory} from '_types/custom/IEntityCategory';
import {IOperationResult} from 'src/modules/operations/interfaces';

type contactOmit = Omit<IRestObject<'company_contacts'>, 'roles' | 'buyerPersona'>
type ICompanyClientContact = {
    buyerPersona: IEntityCategory | string;
    roles: IEntityCategory[];
} & With<contactOmit, 'companyContactTypes' | 'companyContactPhones'>;
type ICompanyClientContext = {contact: ICompanyClientContact, companyId?: number};

abstract class AbstractPinContact extends AbstractOperationDefinition<'client_contacts', ICompanyClientContext> {
    readonly endpoint = 'client_contacts';
    invoke = (
        context: ICompanyClientContext,
        injector: Injector
    ) => {
        const success = new Subject<IOperationResult>(),
            restClient = injector.get(RestClient);

        restClient
            .endpoint('company_contacts/pin')
            .update(context.contact.id, {})
            .subscribe({
                next: () => {
                    restClient.savedToast();
                    success.next({success: true});
                    success.complete();
                },
                error: (err) => {
                    success.next({success: false});
                    success.error(err);
                }
            });
        return success.asObservable();
    };
}

export class PinClientContact extends AbstractPinContact {
    readonly name = 'pin_contact';
    lang = 'CONTACT_PIN';
    icon = 'fa-thumbtack primary';
    access = (context: ICompanyClientContext): boolean => {
        if (!context) {
            return false;
        }
        return !context.contact.pinOnTop;
    };
}

export class UnPinClientContact extends AbstractPinContact {
    readonly name = 'unpin_contact';
    lang = 'CONTACT_UNPIN';
    icon = 'fa-thumbtack danger';
    access = (context: ICompanyClientContext): boolean => {
        if (!context) {
            return false;
        }
        return context.contact.pinOnTop;
    };
}

export class ChangeClientPinStatus extends AbstractPinContact {
    readonly endpoint = 'client_contacts'
    readonly name = 'reverse_pin_contact';
    lang = '';
    icon = 'fa-thumbtack';
    access = (): boolean => true;
}

interface ICompanyClientFormContext {
    contact: ICompanyClientContact;
    companyId: number;
}

abstract class AbstractActiveClientContact
    extends AbstractOperationDefinition<'client_contacts', ICompanyClientFormContext> {
    readonly endpoint = 'client_contacts'
    abstract endpointName: EndpointName;
    abstract confirmModalTranslations: string;

    invoke = (
        context: ICompanyClientFormContext,
        injector: Injector
    ): Observable<IOperationResult> => {
        const confirmModal = injector.get(ConfirmModalService),
            restClient = injector.get(RestClient);

        return confirmModal.confirmOperation(
            this.confirmModalTranslations,
            () => {
                return restClient.endpoint(this.endpointName).update(context.contact.id, {})
                    .pipe(
                        tap(() => restClient.savedToast())
                    );
            },
            {
                primaryBtn: 'danger'
            });
    };
}

export class ActivateClientContact extends AbstractActiveClientContact {
    readonly name = 'activate';
    icon = 'fa-user-plus';
    lang = 'CONTACT_ACTIVATE';
    color = 'success';
    endpointName = 'company_contacts/activate';
    confirmModalTranslations = 'ACTIVATE_CONTACT_CONFIRM';

    access(context: ICompanyClientFormContext): boolean {
        return context.contact.active === false;
    }
}

export class DeactivateClientContact extends AbstractActiveClientContact {
    readonly name = 'deactivate';
    icon = 'fa-user-times';
    lang = 'CONTACT_DEACTIVATE';
    color = 'danger';
    endpointName = 'company_contacts/deactivate';
    confirmModalTranslations = 'DEACTIVATE_CONTACT_CONFIRM';

    access(context: ICompanyClientFormContext): boolean {
        return context.contact.active === true;
    }
}

export class EditClientContact extends AbstractOperationDefinition<'client_contacts', ICompanyClientFormContext> {
    readonly endpoint = 'client_contacts'
    readonly name = 'put';
    lang = 'EDIT';
    icon = 'fa-pencil-alt';
    component = (): Promise<unknown> =>
        import('view-modules/operations/companies/update-company-contact/update-company-contact.component');

    access(context: ICompanyClientFormContext): boolean {
        if (!context.contact) {
            return false;
        }

        return new AccessPipe().transform(context.contact, this.name);
    }
}


export class AddClientContact extends AbstractOperationDefinition<'client_contacts', ICompanyClientFormContext> {
    readonly endpoint = 'client_contacts'
    readonly name = 'post';
    lang = 'ADD';
    icon = 'fa-plus';
    component = (): Promise<unknown> =>
        import('view-modules/operations/companies/update-company-contact/update-company-contact.component');

    access(context: ICompanyClientFormContext): boolean {
        if (!context.contact) {
            return false;
        }

        return new AccessPipe().transform(context.contact, this.name);
    }
}

export const clientContactsOperations = [
    PinClientContact,
    UnPinClientContact,
    ChangeClientPinStatus,
    EditClientContact,
    AddClientContact,
    ActivateClientContact,
    DeactivateClientContact
] as const;
