import './absolute-to-document.directive.scss';
import {Directive, HostBinding, Inject, OnDestroy, OnInit} from '@angular/core';
import {DOCUMENT} from '@angular/common';
import {WINDOW} from 'app-custom-providers';
import {fromEvent, Subject} from 'rxjs';
import {distinctUntilChanged, takeUntil} from 'rxjs/operators';

@Directive({
    selector: '[absoluteToDocument]'
})
export class AbsoluteToDocumentDirective implements OnInit, OnDestroy {
    @HostBinding('style.top') top = '0';
    @HostBinding('class.absolute-to-document') directiveClass = true;

    private _destroy$ = new Subject<void>();

    constructor(
        @Inject(DOCUMENT) private document: Document,
        @Inject(WINDOW) private window: Window
    ) {
        // It's required to calculate top position as soon as it's possible,
        // otherwise element can be shown in standard position for brief moment.
        this.recalculateTopAttribute();
    }

    ngOnInit(): void {
        this.bindScrollEvent();
    }

    private bindScrollEvent(): void {
        fromEvent(this.window, 'scroll')
            .pipe(
                takeUntil(this._destroy$),
                distinctUntilChanged()
            )
            .subscribe(() => {
                this.recalculateTopAttribute();
            });
    }

    private recalculateTopAttribute(): void {
        this.top = -(this.window.pageYOffset) + 'px';
    }

    ngOnDestroy(): void {
        this._destroy$.next();
        this._destroy$.complete();
    }
}
