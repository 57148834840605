/* eslint-disable max-len */
/**
 * This file has been auto generated. Do not edit.
 */

import {IBaseRestEntity} from '_types/rest/IBaseRestEntity';

export const HistoryType = {
    HISTORY_TYPE_CREATE: 1,
    HISTORY_TYPE_UPDATE: 2,
    HISTORY_TYPE_DELETE: 3
} as const;

export const HistoryTypeOptions = [
    {
        value: HistoryType.HISTORY_TYPE_CREATE,
        label: 'HISTORY_TYPE_CREATE',
    },
    {
        value: HistoryType.HISTORY_TYPE_UPDATE,
        label: 'HISTORY_TYPE_UPDATE',
    },
    {
        value: HistoryType.HISTORY_TYPE_DELETE,
        label: 'HISTORY_TYPE_DELETE',
    }
] as const;

export interface IHistoryConst {
    type: [{id: 1, value: 'HISTORY_TYPE_CREATE'}, {id: 2, value: 'HISTORY_TYPE_UPDATE'}, {id: 3, value: 'HISTORY_TYPE_DELETE'}]
}

export interface IHistory extends IBaseRestEntity {
    id?: number,
    type?: typeof HistoryType[keyof typeof HistoryType],
    entityName?: string,
    entityId?: number | null,
    iri?: string | null,
    relatedEntityName?: string | null,
    relatedEntityId?: number | null,
    relatedIri?: string | null,
    /**
     * @format date-time
     */
    date?: string,
    changes?: string,
    identity?: string | null,
    userLoginId?: number | null,
    originalUserLoginId?: number | null,
    dbConnectionId?: number
}
