import {AbstractOperationDefinition} from 'src/modules/operations/interfaces';

export class EditServiceParametersOperation extends AbstractOperationDefinition<'search_console_services'> {
    readonly endpoint = 'search_console_services'
    readonly name = 'put';
    lang = 'EDIT_REPORT_PARAMETERS';
    icon = 'fa-pencil-alt';

    component = (): Promise<unknown> => import('./gsc-service-form/gsc-service-form.component');
}

export class GrantPermissionToGscAccountOperation extends AbstractOperationDefinition<'search_console_services'> {
    readonly endpoint = 'search_console_services'
    readonly name = 'authorize-gsc-account';
    lang = 'GRANT_GSC_PERMISSION';
    icon = 'fa-key';

    component = (): Promise<unknown> => import('./gsc-service-grant-permission/gsc-service-grant-permission.component');

    access(): boolean {
        return true;
    }
}


export const gscServices = [
    EditServiceParametersOperation,
    GrantPermissionToGscAccountOperation,
] as const;
