import {TtFilter} from 'angular-bootstrap4-table-tools';
import {IDynamicTableFilterDefinition} from 'src/modules/dynamic-table/interfaces';

export class DynamicTableFilter extends TtFilter {
    constructor(
        public fieldConfig: IDynamicTableFilterDefinition
    ) {
        super({field: fieldConfig.field});
    }
}
