import {Pipe, PipeTransform} from '@angular/core';
import {IRestCollection, IRestObject} from 'src/modules/rest/objects';
import {RestClient} from 'src/modules/rest/rest-client.service';


@Pipe({name: 'access'})
export class AccessPipe implements PipeTransform {
    transform(
        value: IRestObject<string> | IRestCollection<string>,
        operation: string,
        property?: string
    ): boolean {
        if (!RestClient.isRestObject(value) && !RestClient.isRestCollection(value)) {
            return false;
        }

        return value.access(operation, property);
    }
}
