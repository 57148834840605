import {AfterViewInit, Component, ElementRef, Inject, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {AbstractControlValueAccessor} from 'src/modules/app-forms/abstract-control-value-accessors/abstract-control-value-accessor';
import {
    AbstractControl,
    FormControl,
    ValidationErrors,
    Validator
} from '@angular/forms';
import {
    IDynamicFieldControlValueAccessor, INumberDynamicFieldConfig,
    INumberShuttleDynamicFieldConfig
} from 'src/modules/dynamic-fields/interfaces';
import {NumberInputComponent} from 'src/modules/dynamic-fields/controls/number-input/number-input.component';
import {fromEvent, interval, Subject} from 'rxjs';
import {takeUntil, switchMap} from 'rxjs/operators';
import {DOCUMENT} from '@angular/common';


@Component({
    selector: 'number-shuttle',
    templateUrl: './number-shuttle.component.html',
    styleUrls: ['./number-shuttle.component.scss'],
    providers: AbstractControlValueAccessor.getProviders(NumberShuttleComponent)
})
export class NumberShuttleComponent
    extends AbstractControlValueAccessor
    implements IDynamicFieldControlValueAccessor, Validator, OnInit, AfterViewInit, OnDestroy {

    _fieldConfig: INumberShuttleDynamicFieldConfig;
    @Input() set fieldConfig(value: INumberShuttleDynamicFieldConfig) {
        this._fieldConfig = value;
        this.setNumberInputFieldConfig(value);
    }

    numberInputFieldConfig: INumberDynamicFieldConfig;

    @ViewChild(NumberInputComponent, {static: true}) numberInput: NumberInputComponent;
    @ViewChild('subButton') subButton: ElementRef<HTMLButtonElement>;
    @ViewChild('addButton') addButton: ElementRef<HTMLButtonElement>;

    numberControl = new FormControl();
    private min = -Infinity;
    private max = Infinity;
    private step = 1;

    private readonly _destroy$ = new Subject<void>();

    constructor(
        @Inject(DOCUMENT) private document: Document
    ) {
        super();
    }

    private setNumberInputFieldConfig(value: INumberShuttleDynamicFieldConfig): void {
        this.numberInputFieldConfig = {
            type: 'number',
            validators: value.validators,
            validatorMessages: value.validatorMessages
        };
    }

    ngOnInit(): void {
        if (typeof this._fieldConfig.validators === 'object' && this._fieldConfig.validators !== null) {
            if ('min' in this._fieldConfig.validators) {
                this.min = this._fieldConfig.validators.min;
            }

            if ('max' in this._fieldConfig.validators) {
                this.max = this._fieldConfig.validators.max;
            }

            if ('step' in this._fieldConfig.validators) {
                this.step = this._fieldConfig.validators.step;
            }
        }

        this.numberControl.valueChanges
            .pipe(takeUntil(this._destroy$))
            .subscribe((value) => {
                this._onChange(value);
                this.markAsTouched();
            });
    }

    ngAfterViewInit(): void {
        this.addButtonEventListeners(this.subButton.nativeElement, this.sub.bind(this));
        this.addButtonEventListeners(this.addButton.nativeElement, this.add.bind(this));
    }

    private addButtonEventListeners(buttonElement: HTMLButtonElement, callback: () => void): void {
        const buttonMouseUpEvent = fromEvent(this.document, 'mouseup');

        fromEvent(buttonElement, 'mousedown')
            .pipe(
                takeUntil(this._destroy$),
                switchMap(() => interval(250)
                    .pipe(
                        takeUntil(buttonMouseUpEvent)
                    )
                )
            )
            .subscribe(() => {
                callback();
            });

        fromEvent(buttonElement, 'click')
            .pipe(
                takeUntil(this._destroy$)
            )
            .subscribe(() => {
                callback();
            });
    }

    private sub(): void {
        this.changeValue((value) => {
            if (value - this.step >= this.min) {
                return value - this.step;
            }
            return value;
        });
    }

    private add(): void {
        this.changeValue((value) => {
            if (value + this.step <= this.max) {
                return value + this.step;
            }
            return value;
        });
    }

    private changeValue(cb: (value: number) => number): void {
        let value = this.numberControl.value;

        if (isNaN(value)) {
            value = 0;
        }

        value = cb(value);

        if (value !== this.numberControl.value) {
            this.numberControl.setValue(value);
        }
    }

    writeValue(value: unknown): void {
        this.numberControl.setValue(value, {emitEvent: false});
    }

    setDisabledState(isDisabled: boolean): void {
        if (isDisabled) {
            this.numberControl.disable({emitEvent: false});
        } else {
            this.numberControl.enable({emitEvent: false});
        }
    }

    validate(control: AbstractControl): ValidationErrors | null {
        return this.numberInput.validate(control) === null ? null : {
            numberShuttle: true
        };
    }

    ngOnDestroy(): void {
        this._destroy$.next();
        this._destroy$.complete();
    }
}
