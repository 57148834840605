import {Injectable} from '@angular/core';
import {ILegend, ILegendItem} from 'src/modules/legend/interfaces';
import {BehaviorSubject, Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {UIRouterGlobals} from '@uirouter/angular';
import {LEGEND_CONFIG} from 'src/modules/legend/legend-config';
import {TransitionService} from '@uirouter/core';


@Injectable({
    providedIn: 'root'
})
export class LegendService {
    private legendChangeSubject = new BehaviorSubject<ILegend>(null);

    private currentLegendQueue: string[];
    private currentLegendItemName: string;

    private isClosingOnStateChange = false;

    constructor(
        private readonly uiRouterGlobals: UIRouterGlobals,
        private readonly transitionService: TransitionService
    ) {
    }

    getLegendItem(name: string): Observable<ILegendItem | null> {
        this.closeOnStateChange();

        return this.legendChangeSubject
            .pipe(
                map((item) => {
                    if (
                        !(item && name in item)
                        || name !== this.currentLegendItemName
                    ) {
                        return null;
                    }

                    const legendItem = item[name];
                    legendItem.lastItem = !this.currentLegendQueue?.length;

                    return legendItem;
                })
            );
    }

    private closeOnStateChange(): void {
        if (this.isClosingOnStateChange) {
            return;
        }

        this.transitionService.onSuccess(null, () => this.closeLegend());
        this.isClosingOnStateChange = true;
    }

    toggleLegend(): void {
        if (this.currentLegendQueue) {
            this.closeLegend();
            return;
        }

        this.openLegend();
    }

    private openLegend(): void {
        if (!this.isLegendAvailable()) {
            return;
        }

        const legendName = this.uiRouterGlobals.current.name,
            currentLegend = LEGEND_CONFIG[legendName];
        this.initializeLegendQueue(currentLegend);
        this.legendChangeSubject.next(currentLegend);
    }

    isLegendAvailable(): boolean {
        return this.uiRouterGlobals.current.name in LEGEND_CONFIG;
    }

    private initializeLegendQueue(legend: ILegend): void {
        this.currentLegendQueue = Object.keys(legend);
        this.currentLegendItemName = this.currentLegendQueue.shift();
    }

    private closeLegend(): void {
        this.legendChangeSubject.next(null);
        this.currentLegendQueue = null;
    }

    nextLegendItem(): void {
        this.currentLegendItemName = this.currentLegendQueue.shift();

        if (this.currentLegendItemName) {
            this.refreshLegend();
            return;
        }

        this.closeLegend();
    }

    private refreshLegend(): void {
        this.legendChangeSubject.next(
            this.legendChangeSubject.value
        );
    }
}
