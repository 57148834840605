import {ConfigService} from 'src/services/config.service';
import {Pipe, PipeTransform} from '@angular/core';

@Pipe({name: 'currency'})
export class CurrencyPipe implements PipeTransform {
    constructor(
        private config: ConfigService
    ) {
    }

    transform(
        value: string | number,
        currency: string
    ): string {
        if (typeof value === 'string') {
            value = parseFloat(value);
        }
        return (value as number).toLocaleString(this.config.get('locale'), {style: 'currency', currency: currency});
    }
}

