import {Injectable} from '@angular/core';
import {AppModalDirective, AppModalForm} from 'src/modules/app-forms/app-form.directive';
import {FormGroupDirective, NgForm} from '@angular/forms';
import {BsModalConfigService} from 'angular-bootstrap4';
import {ConfirmModalService} from 'src/modules/global-components/confirm-modal/confirm-modal.service';


@Injectable({
    providedIn: 'root'
})
export class CoreModalConfigService {

    constructor(
        private modalConfig: BsModalConfigService,
        private confirmModal: ConfirmModalService
    ) {
    }

    /**
     * Add backdrop to modals and show confirmation dialog if modal contain formGroup or ngForm.
     * */
    public initGlobalModalConfig(): void {
        this.modalConfig.backdrop = 'static';
        this.modalConfig.keyboard = false;
        this.modalConfig.onBeforeChange = (appModalDirective: AppModalDirective) => {
            if (!appModalDirective.form || appModalDirective.disableAbandonWarning) {
                return true;
            }

            if (appModalDirective.form._confirmed) {
                appModalDirective.form._confirmed = false;
                return true;
            }

            if (appModalDirective.operationalModalExtras?.performedModalAction?.minimized) {
                return true;
            }

            if (
                appModalDirective.bsModal
                && this.isFormAbandoned(appModalDirective)
            ) {
                this.confirmAbandoningForm(appModalDirective, appModalDirective.form);
                return false;
            }
            return true;
        };
    }

    private isFormAbandoned(appModalDirective: AppModalDirective): boolean {
        const formObject = appModalDirective.form.ngForm
            ? appModalDirective.form.ngForm
            : appModalDirective.form.formGroup;
        return this.isFormAlreadyEngaged(formObject);
    }

    private isFormAlreadyEngaged(formObject: FormGroupDirective | NgForm): boolean {
        return !formObject.submitted && !formObject.form.pristine;
    }

    private confirmAbandoningForm(modalControl: AppModalDirective, appModalForm: AppModalForm): void {
        this.confirmModal
            .confirm('CONFIRM_ABANDONING_CHANGES', () => {
                appModalForm._confirmed = true;
                modalControl.hide();
            }, {
                text: 'CONFIRM_ABANDONING_CHANGES_TEXT',
                langYes: 'CONFIRM_ABANDONING_CHANGES_LANG_YES',
                langNo: 'CONFIRM_ABANDONING_CHANGES_LANG_NO',
                primaryBtn: 'danger'
            });
    }
}
