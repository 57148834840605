import {Injector} from '@angular/core';
import {AbstractOperationDefinition, IOperationResult} from 'src/modules/operations/interfaces';
import {IRestObject} from 'src/modules/rest/objects';
import {RestClient} from 'src/modules/rest/rest-client.service';
import {ConfirmModalService} from 'src/modules/global-components/confirm-modal/confirm-modal.service';
import {TranslateService} from 'src/modules/translate/translate.service';
import {ToastsService} from 'src/modules/global-components/toasts/toasts.service';
import {Observable, of, Subject} from 'rxjs';
import {OperationsService} from 'src/modules/operations/operations.service';
import {IPartner} from '_types/rest';

class ActivatePartnerOperation extends AbstractOperationDefinition<'partners'> {
    readonly endpoint = 'partners'
    readonly name = 'activate';
    lang = 'ACTIVATE_PARTNER';
    icon = 'fa-check';

    invoke = (
        context: IRestObject<'partners'>,
        injector: Injector
    ): Observable<IOperationResult> => {
        const restClient = injector.get(RestClient),
            toasts = injector.get(ToastsService),
            translate = injector.get(TranslateService),
            confirmModal = injector.get(ConfirmModalService),
            successSubject = new Subject<IOperationResult>();

        confirmModal.confirm(
            'PARTNER_ACTIVATION_CONFIRM',
            () => {
                restClient.endpoint('partners/activate').update(context.id, {})
                    .subscribe({
                        next: () => {
                            toasts.pop(translate.get('PARTNER_ACTIVATED'), null, 'success');
                            successSubject.next({success: true});
                            successSubject.complete();
                        },
                        error: () => {
                            successSubject.next({success: false});
                            successSubject.complete();
                        }
                    });
            },
            {
                langYes: 'PARTNER_ACTIVATION_CONFIRM_LANG_YES',
                callbackNo: () => {
                    successSubject.next({success: false});
                    successSubject.complete();
                }
            });

        return successSubject.asObservable();
    };

    access(): boolean {
        return true;
    }
}

interface IPartnerValidateResponse {
    valid: boolean,
    errors?: string[]
}

class ValidatePartnerOperation extends AbstractOperationDefinition<'partners'> {
    readonly endpoint = 'partners'
    readonly name = 'validate';
    lang = 'VALIDATE_PARTNER';
    icon = 'fa-check';

    invoke = (
        context: IRestObject<'partners'>,
        injector: Injector
    ): Observable<IOperationResult> => {
        const restClient = injector.get(RestClient),
            translate = injector.get(TranslateService),
            toasts = injector.get(ToastsService),
            validationSubject = new Subject<IOperationResult>(),
            validationCallback = (success) => {
                validationSubject.next(success);
                validationSubject.complete();
            };

        restClient.endpoint<string, IPartnerValidateResponse>('partners/validate').get(context.id)
            .subscribe({
                next: (response) => {
                    if (response.valid) {
                        validationCallback(true);
                        return;
                    }

                    const messages = response.errors.map((item) => {
                        return translate.get(item);

                    });
                    toasts.pop(
                        messages.join(',<br/>'),
                        translate.get('PARTNER_ACTIVATION_ERROR'),
                        'error'
                    );

                    validationCallback(false);
                    return;
                },
                error: (err) => {
                    restClient.handleError(err);
                    validationCallback(false);
                }
            });

        return validationSubject.asObservable();
    };

    access(): boolean {
        return true;
    }
}

export class ValidateAndActivatePartnerOperation extends AbstractOperationDefinition<string, IPartner> {
    readonly endpoint = 'partners'
    readonly name = 'validate_and_activate';
    lang = 'ACTIVATE_PARTNER';
    icon = 'fa-check';

    invoke = (
        context: IRestObject<'partners'>,
        injector: Injector
    ): Observable<IOperationResult> => {
        const operationsService = injector.get(OperationsService),
            validateOperation = operationsService.get(ValidatePartnerOperation),
            activateOperation = operationsService.get(ActivatePartnerOperation),
            validationSubject = new Subject<IOperationResult>(),
            validationCallback = (operationSuccess) => {
                validationSubject.next(operationSuccess);
                validationSubject.complete();
            };

        if (
            !validateOperation
            || !activateOperation
        ) {
            return of({success: false});
        }

        operationsService.execute(validateOperation, context)
            .subscribe((operationResult) => {
                if (operationResult.success) {
                    operationsService.execute(activateOperation, context)
                        .subscribe((operationResult) => {
                            validationCallback(operationResult.success);
                        });
                    return;
                }
                validationCallback(false);
            });

        return validationSubject.asObservable();
    };

    access(): boolean {
        return true;
    }
}

export const partnersOperations = [
    ActivatePartnerOperation,
    ValidatePartnerOperation,
    ValidateAndActivatePartnerOperation
] as const;
