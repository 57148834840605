/* eslint-disable max-len */
/**
 * This file has been auto generated. Do not edit.
 */
import {ITaskTemplateComponent} from '_types/rest/Entity/IRestTaskTemplateComponent';
import {IRestTaskTemplateComponent} from '_types/rest/Entity/IRestTaskTemplateComponent';
import {ITaskTemplateAction} from '_types/rest/Entity/IRestTaskTemplateAction';
import {IRestTaskTemplateAction} from '_types/rest/Entity/IRestTaskTemplateAction';
import {ITask} from '_types/rest/Entity/IRestTask';
import {IRestTask} from '_types/rest/Entity/IRestTask';
import {IBaseRestEntity} from '_types/rest/IBaseRestEntity';
import {IRestObject} from 'core/rest/rest-object.service';

export const TaskDeadlineType = {
    TASK_DEADLINE_TYPE_START_OFFSET: 1,
    TASK_DEADLINE_TYPE_OTHER_TASK_ACTION_OFFSET: 2,
    TASK_DEADLINE_TYPE_CONTEXT_OFFSET: 3,
    TASK_DEADLINE_TYPE_DATE: 4
} as const;

export const TaskDeadlineTypeOptions = [
    {
        value: TaskDeadlineType.TASK_DEADLINE_TYPE_START_OFFSET,
        label: 'TASK_DEADLINE_TYPE_START_OFFSET',
    },
    {
        value: TaskDeadlineType.TASK_DEADLINE_TYPE_OTHER_TASK_ACTION_OFFSET,
        label: 'TASK_DEADLINE_TYPE_OTHER_TASK_ACTION_OFFSET',
    },
    {
        value: TaskDeadlineType.TASK_DEADLINE_TYPE_CONTEXT_OFFSET,
        label: 'TASK_DEADLINE_TYPE_CONTEXT_OFFSET',
    },
    {
        value: TaskDeadlineType.TASK_DEADLINE_TYPE_DATE,
        label: 'TASK_DEADLINE_TYPE_DATE',
    }
] as const;

export const TaskDeadlineOffsetUnit = {
    TASK_DEADLINE_UNIT_MINUTES: 1,
    TASK_DEADLINE_UNIT_HOURS: 2,
    TASK_DEADLINE_UNIT_DAYS: 3,
    TASK_DEADLINE_UNIT_WEEKS: 4,
    TASK_DEADLINE_UNIT_MONTHS: 5,
    TASK_DEADLINE_UNIT_YEARS: 6
} as const;

export const TaskDeadlineOffsetUnitOptions = [
    {
        value: TaskDeadlineOffsetUnit.TASK_DEADLINE_UNIT_MINUTES,
        label: 'TASK_DEADLINE_UNIT_MINUTES',
    },
    {
        value: TaskDeadlineOffsetUnit.TASK_DEADLINE_UNIT_HOURS,
        label: 'TASK_DEADLINE_UNIT_HOURS',
    },
    {
        value: TaskDeadlineOffsetUnit.TASK_DEADLINE_UNIT_DAYS,
        label: 'TASK_DEADLINE_UNIT_DAYS',
    },
    {
        value: TaskDeadlineOffsetUnit.TASK_DEADLINE_UNIT_WEEKS,
        label: 'TASK_DEADLINE_UNIT_WEEKS',
    },
    {
        value: TaskDeadlineOffsetUnit.TASK_DEADLINE_UNIT_MONTHS,
        label: 'TASK_DEADLINE_UNIT_MONTHS',
    },
    {
        value: TaskDeadlineOffsetUnit.TASK_DEADLINE_UNIT_YEARS,
        label: 'TASK_DEADLINE_UNIT_YEARS',
    }
] as const;

export interface ITaskDeadlineConst {
    type: [{id: 1, value: 'TASK_DEADLINE_TYPE_START_OFFSET'}, {id: 2, value: 'TASK_DEADLINE_TYPE_OTHER_TASK_ACTION_OFFSET'}, {id: 3, value: 'TASK_DEADLINE_TYPE_CONTEXT_OFFSET'}, {id: 4, value: 'TASK_DEADLINE_TYPE_DATE'}],
    offsetUnit: [{id: 1, value: 'TASK_DEADLINE_UNIT_MINUTES'}, {id: 2, value: 'TASK_DEADLINE_UNIT_HOURS'}, {id: 3, value: 'TASK_DEADLINE_UNIT_DAYS'}, {id: 4, value: 'TASK_DEADLINE_UNIT_WEEKS'}, {id: 5, value: 'TASK_DEADLINE_UNIT_MONTHS'}, {id: 6, value: 'TASK_DEADLINE_UNIT_YEARS'}]
}

export interface ITaskDeadline extends IBaseRestEntity {
    id?: number,
    /**
     * @default 1
     */
    type: typeof TaskDeadlineType[keyof typeof TaskDeadlineType],
    /**
     * @format date-time
     */
    date?: string | null,
    taskTemplateComponent?: ITaskTemplateComponent | string | null,
    taskTemplateAction?: ITaskTemplateAction | string | null,
    dateContext?: string | null,
    offsetValue?: number | null,
    /**
     * @default 1
     */
    offsetUnit?: typeof TaskDeadlineOffsetUnit[keyof typeof TaskDeadlineOffsetUnit],
    /**
     * @default 1
     */
    order?: number,
    task: ITask | string,
    /**
     * @description Record status
     *  0 = deleted
     *  1 = active.
     * @default 1
     */
    status?: number,
    /**
     * @description Creation timestamp.
     * @default CURRENT_TIMESTAMP
     * @format date-time
     */
    created?: string,
    /**
     * @description Last modification timestamp.
     * @format date-time
     */
    modified?: string | null
}

/** @deprecated */
export interface IRestTaskDeadline extends ITaskDeadline, IRestObject {
    taskTemplateComponent?: IRestTaskTemplateComponent | string | null,
    taskTemplateAction?: IRestTaskTemplateAction | string | null,
    task: IRestTask | string,
}
