import {Injectable} from '@angular/core';
import {IPubConfigFrontCms} from '_types/pub/config/front';
import {RouteHelper} from 'src/modules/route-helper/route-helper.service';
import {AppCmsComponent} from 'view-modules/core/app-cms/app-cms.component';
import {StateService} from '@uirouter/core';
import {AppStateDeclaration, RouteTreeBranch} from 'src/modules/route-helper/interfaces';

@Injectable({
    providedIn: 'root'
})
export class CmsService {

    constructor(
        private routeHelper: RouteHelper,
        private stateService: StateService
    ) {
    }

    setCmsStates(cms: IPubConfigFrontCms[]): void {
        if (!this.stateService.get('cms')) {
            this.createCmsStates(cms);
        } else {
            this.updateCmsStates(cms);
        }
    }

    private createCmsStates(cms: IPubConfigFrontCms[]): void {
        this.routeHelper.configureStates([
            {
                name: 'cms',
                abstract: true,
                childs: cms.map((cmsPage) => {
                    return {
                        name: cmsPage.cmsType,
                        url: `/${cmsPage.url}`,
                        params: {
                            cmsType: cmsPage.cmsType
                        },
                        lang: cmsPage.name,
                        views: {
                            '@': {
                                component: AppCmsComponent
                            }
                        }
                    };
                })
            }
        ]);
    }

    private updateCmsStates(cms: IPubConfigFrontCms[]): void {
        cms.forEach((cmsPage) => {
            const state = this.stateService.get(`cms.${cmsPage.cmsType}`) as AppStateDeclaration;

            state.lang = cmsPage.name;
        });
        this.routeHelper.shouldParseRouteTree = true;
    }

    getCmsState(cmsType: string): RouteTreeBranch | false {
        return this.routeHelper.getStatesTree(`cms.${cmsType}`);
    }
}
