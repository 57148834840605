/* eslint-disable max-len */
/**
 * This file has been auto generated. Do not edit.
 */
import {ITaskTemplateComponent} from '_types/rest/Entity/IRestTaskTemplateComponent';
import {IRestTaskTemplateComponent} from '_types/rest/Entity/IRestTaskTemplateComponent';
import {ITaskTemplateComponentFilterComponentAction} from '_types/rest/Entity/IRestTaskTemplateComponentFilterComponentAction';
import {IRestTaskTemplateComponentFilterComponentAction} from '_types/rest/Entity/IRestTaskTemplateComponentFilterComponentAction';
import {ITaskTemplateComponentFilterContextValue} from '_types/rest/Entity/IRestTaskTemplateComponentFilterContextValue';
import {IRestTaskTemplateComponentFilterContextValue} from '_types/rest/Entity/IRestTaskTemplateComponentFilterContextValue';
import {IBaseRestEntity} from '_types/rest/IBaseRestEntity';
import {IRestObject} from 'core/rest/rest-object.service';

export const TaskTemplateComponentFilterType = {
    TASK_TEMPLATE_COMPONENT_FILTER_TYPE_COMPONENT_ACTION: 1,
    TASK_TEMPLATE_COMPONENT_FILTER_TYPE_CONTEXT_VALUE: 2
} as const;

export const TaskTemplateComponentFilterTypeOptions = [
    {
        value: TaskTemplateComponentFilterType.TASK_TEMPLATE_COMPONENT_FILTER_TYPE_COMPONENT_ACTION,
        label: 'TASK_TEMPLATE_COMPONENT_FILTER_TYPE_COMPONENT_ACTION',
    },
    {
        value: TaskTemplateComponentFilterType.TASK_TEMPLATE_COMPONENT_FILTER_TYPE_CONTEXT_VALUE,
        label: 'TASK_TEMPLATE_COMPONENT_FILTER_TYPE_CONTEXT_VALUE',
    }
] as const;

export interface ITaskTemplateComponentFilterConst {
    type: [{id: 1, value: 'TASK_TEMPLATE_COMPONENT_FILTER_TYPE_COMPONENT_ACTION'}, {id: 2, value: 'TASK_TEMPLATE_COMPONENT_FILTER_TYPE_CONTEXT_VALUE'}]
}

export interface ITaskTemplateComponentFilter extends IBaseRestEntity {
    id?: number,
    /**
     * @default 1
     */
    type: typeof TaskTemplateComponentFilterType[keyof typeof TaskTemplateComponentFilterType],
    taskTemplateComponent: ITaskTemplateComponent | string,
    taskTemplateComponentFilterComponentActions?: (ITaskTemplateComponentFilterComponentAction | string)[],
    taskTemplateComponentFilterContextValues?: (ITaskTemplateComponentFilterContextValue | string)[],
    /**
     * @description Record status
     *  0 = deleted
     *  1 = active.
     * @default 1
     */
    status?: number,
    /**
     * @description Creation timestamp.
     * @default CURRENT_TIMESTAMP
     * @format date-time
     */
    created?: string,
    /**
     * @description Last modification timestamp.
     * @format date-time
     */
    modified?: string | null
}

/** @deprecated */
export interface IRestTaskTemplateComponentFilter extends ITaskTemplateComponentFilter, IRestObject {
    taskTemplateComponent: IRestTaskTemplateComponent | string,
    taskTemplateComponentFilterComponentActions?: IRestTaskTemplateComponentFilterComponentAction[] | string[],
    taskTemplateComponentFilterContextValues?: IRestTaskTemplateComponentFilterContextValue[] | string[],
}
