<extended-select [formControl]="esControl" [resolveOnSearch]="resolveOnSearch" [typeToSearch]="typeToSearch"
                 [inputLoading]="initializing | async"
                 [multiple]="fieldConfig.multiple" [deselectable]="fieldConfig.deselectable"
                 [deselectValue]="fieldConfig.deselectValue" [placeholder]="fieldConfig.placeholder"
                 class="show-full-labels">
    <ng-container *esBeforeOption="let o; options: options">
        <ng-container *ngIf="beforeTemplate"
                      [ngTemplateOutlet]="beforeTemplate"
                      [ngTemplateOutletContext]="{$implicit: o, value: o?.value}">
        </ng-container>
    </ng-container>
    <ng-container *esOptions="let o of options; value: 'value'">
        <ng-container [ngTemplateOutlet]="o.template"
                      [ngTemplateOutletContext]="o.templateContext">
        </ng-container>
    </ng-container>
</extended-select>
