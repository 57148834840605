export interface IComparisonOptions {
    or?: boolean,
    neq?: boolean
}

export abstract class Comparison {
    /**
     * Returns a comparator that checks if given item fulfills given criteria
     * @param {Object} criteria
     * @param {Object} [options={or: false, neq: false}]
     * @returns {Function}
     */
    static criteria(criteria: object, options?: IComparisonOptions): (item: unknown) => boolean {
        options = options || {};
        const criteriaKeys = Object.keys(criteria);
        return (item) => {
            if (options.or) {
                return Array.prototype.some.call(criteriaKeys, _compare);
            }
            return Array.prototype.every.call(criteriaKeys, _compare);

            //////

            function _compare(key) {
                if (typeof (criteria[key]) === 'function') {
                    return criteria[key](item[key]);
                }
                return options.neq ? criteria[key] !== item[key] : criteria[key] === item[key];
            }
        };
    }

    /**
     * Checks if item is unique
     * @param {*} item
     * @param {number} index
     * @param {Array} array
     * @returns {boolean}
     */
    static unique(item: never, index: number, array: []): boolean {
        return array.indexOf(item) === index;
    }

    /**
     * Returns a comparator that checks if given item id property is equal to id parameter
     */
    static byId(id: number): (value: object) => boolean {
        return this.criteria({id: id});
    }

    /**
     * Returns a comparator that checks if given item @id property is equal to iri parameter
     * @param {string} iri
     * @returns {Function}
     */
    static byIri(iri: string): (value: object) => boolean {
        return this.criteria({'@id': iri});
    }
}

