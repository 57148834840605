import {animate, animation, style} from '@angular/animations';


export const fadeInToastsAnimation = animation([
    style({ opacity: 0 }),
    animate(
        '{{ time }}',
        style({ opacity: 0.9 })
    ),
    style({ opacity: 1 })
]);
