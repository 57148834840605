import {NgModule} from '@angular/core';
import {ReportDisplayGroupViewComponent} from 'src/modules/reports/report-display-group-view/report-display-group-view.component';
import {ReportDisplayViewComponent} from 'src/modules/reports/report-display-view/report-display-view.component';
import {AppComponentsModule} from 'src/components/app-components.module';
import {DynamicTableModule} from 'src/modules/dynamic-table/dynamic-table.module';
import {SubMenuLayoutModule} from 'src/modules/sub-menu-layout/sub-menu-layout.module';
import {AppDirectivesModule} from 'src/directives/app-directives.module';
import {AppFormsModule} from 'src/modules/app-forms/app-forms.module';
import {CategoriesModule} from 'src/modules/categories/categories.module';
import {AppCommonModule} from 'src/modules/app-common/app-common.module';
import {REPORT_OPERATIONS_LEDGER} from 'src/modules/reports/report-operations.provider';
import {reportOperationsLedger} from 'src/modules/reports/reports-operations-ledger';

@NgModule({
    imports: [
        AppCommonModule,
        AppComponentsModule,
        AppDirectivesModule,
        AppFormsModule,
        CategoriesModule,
        DynamicTableModule,
        SubMenuLayoutModule,
    ],
    declarations: [
        ReportDisplayGroupViewComponent,
        ReportDisplayViewComponent
    ],
    exports: [
        ReportDisplayGroupViewComponent,
        ReportDisplayViewComponent
    ],
    providers: [
        {
            provide: REPORT_OPERATIONS_LEDGER,
            useValue: reportOperationsLedger
        }
    ]
})
export class ReportsModule {

}
