import {Directive, ElementRef, HostBinding, HostListener, Input, OnDestroy, OnInit} from '@angular/core';
import {SortableDirective, SortableObject} from 'src/modules/sortable/sortable.directive';
import {SortableService} from 'src/modules/sortable/sortable.service';
import {BsHelpers} from 'angular-bootstrap4';


@Directive({
    selector: '[sortableItem]'
})
export class SortableItemDirective<T extends SortableObject = SortableObject> implements OnInit, OnDestroy {
    @Input() sortableItem: T;
    @HostBinding('class.sortable-item') sortableItemClassName = true;
    insertBefore = false;
    insertAfter = false;
    dragging = false;
    element: HTMLElement;
    handle: HTMLElement;
    target: Node;

    constructor(
        public sortable: SortableDirective,
        private sortableService: SortableService,
        private angularBS: BsHelpers,
        elRef: ElementRef
    ) {
        this.element = elRef.nativeElement;
    }

    ngOnInit(): void {
        this.sortable.addSortableItem(this);
    }

    ngOnDestroy(): void {
        this.sortable.removeSortableItem(this);
    }

    setHandle(handle: HTMLElement): void {
        this.handle = handle;
    }

    unsetHandle(): void {
        this.handle = undefined;
    }

    setInsertBefore(): void {
        this.insertBefore = true;
        this.insertAfter = false;
    }

    setInsertAfter(): void {
        this.insertBefore = false;
        this.insertAfter = true;
    }

    @HostListener('dragstart', ['$event'])
    handleDragStart($event: DragEvent): void {
        if (
            !this.sortable.enabled
            || (
                this.handle
                && !this.handle.contains(this.target)
            )
        ) {
            $event.preventDefault();
            return;
        }

        this.dragging = true;

        this.sortableService.setCurrentDragItem(this);
        this.sortable.dragStart.emit(Object.assign($event, {dragItem: this}));
    }

    @HostListener('dragend', ['$event'])
    handleDragEnd($event: DragEvent): void {
        if (!this.sortable.enabled) {
            $event.preventDefault();
            return;
        }

        if (!this.sortableService.isTargetEqualDropItem() && this.sortableService.isAllowedToDrop()) {
            this.sortableService.apply();
            this.sortable.sortStop.emit(
                {
                    dragItem: this.sortableService.currentDragItem,
                    collection: this.sortableService.targetSortable.collection,
                }
            );
        }
        this.sortableService.currentDragItem.dragging = false;
        this.sortableService.refreshScopes();

        this.sortable.dragEnd.emit(
            {
                baseDragItem: this,
                dragItem: this.sortableService.currentDragItem,
                targetItem: this.sortableService.targetItem
            }
        );
        this.sortableService.clear();
    }

    @HostListener('dragover', ['$event'])
    handleDragOver($event: DragEvent): void {
        if (!this.sortable.enabled) {
            $event.preventDefault();
            return;
        }

        $event.stopPropagation();
        if (!this.dragging) {
            this.sortableService.setTargetItem(this);
        }

        if (this.sortableService.isAllowedToDrop()) {
            $event.preventDefault();
            if (!this.dragging) {

                if (this.sortable.collection.includes(this.sortableService.currentDragItem.sortableItem)) {
                    if (
                        typeof this.sortableService.baseDragItem !== 'undefined'
                        && this.sortable.sortableItems.includes(this.sortableService.baseDragItem)
                    ) {
                        this.sortableService.removeFromDragCollection(this.sortableService.currentDragItem);
                        this.sortableService.refreshScopes();
                        this.sortableService.setCurrentDragItem(this.sortableService.baseDragItem);
                        this.sortableService.setBaseDragItem(undefined);
                    }
                }

                if (!this.sortable.collection.includes(this.sortableService.currentDragItem.sortableItem)) {
                    this.sortableService.currentDragItem.element.style.display = 'none';

                    if (typeof this.sortableService.baseDragItem !== 'undefined') {
                        this.sortableService.removeFromDragCollection(this.sortableService.currentDragItem);
                        this.sortableService.refreshScopes();
                    }
                    this.sortableService.setBaseDragItem(this.sortableService.currentDragItem);


                    this.sortableService.insertIntoTargetCollection(this.sortable.sortableItems.indexOf(this));
                    this.sortableService.refreshScopes();

                    const newDragItem = this.sortable.sortableItems.find(
                        (i) => i.sortableItem === this.sortableService.currentDragItem.sortableItem);

                    this.sortable.dragStart.emit(Object.assign($event, {dragItem: newDragItem}));
                    newDragItem.dragging = true;
                    this.sortableService.setCurrentDragItem(newDragItem);

                }

                let mousePos, position, dimension;
                if (this.sortable.sortableDirection === 'vertical') {
                    const {height, top} = this.angularBS.offset(this.element);

                    mousePos = $event.pageY;
                    position = top;
                    dimension = height;
                } else {
                    const {width, left} = this.angularBS.offset(this.element);

                    mousePos = $event.pageX;
                    position = left;
                    dimension = width;
                }


                if (mousePos - position < dimension / 2) {
                    this.setInsertBefore();
                } else {
                    this.setInsertAfter();
                }
                this.sortable.dragOver.emit({dragItem: this.sortableService.currentDragItem, targetItem: this});
            }
        }
    }

    @HostListener('mousedown', ['$event'])
    handleMouseDown($event: DragEvent): void {
        if (!this.sortable.enabled) {
            return;
        }

        this.target = $event.target as Node;
        if (!this.handle) {
            this.element.setAttribute('draggable', 'true');
        } else if (this.handle.contains(this.target)) {
            this.element.setAttribute('draggable', 'true');
        }
    }

    @HostListener('mouseup', ['$event'])
    handleMouseUp($event: DragEvent): void {
        if (!this.sortable.enabled) {
            $event.preventDefault();
            return;
        }

        this.element.setAttribute('draggable', 'false');
    }
}
