<div class="row w-100 m-0 p-0">
    <div *ngFor="let color of colors"
         (click)="selectedColorChange.emit(color)"
         class="col-3 px-0 mb-2 d-flex align-items-center">
        <div [ngClass]="{
                'active': selectedColor === color
             }"
             class="cursor-pointer color-circle bg-{{color}}">
        </div>
    </div>
</div>
