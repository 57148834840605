<textarea *ngIf="!fieldConfig.isWYSIWYG; else wysiwygEditor" class="form-control"
          [formControl]="textInputControl"
          [attr.placeholder]="fieldConfig.placeholder"
          [rows]="fieldConfig.rows">
</textarea>

<ng-template #wysiwygEditor>
    <!-- [height] is hardcoded based on line-height & paddings -->
    <wysiwyg-editor [formControl]="textInputControl"
                    [toolbarItems]="fieldConfig.configWYSIWYG.toolbarItems"
                    [excludeToolbarItems]="fieldConfig.configWYSIWYG.excludeToolbarItems"
                    [excludePlugins]="fieldConfig.configWYSIWYG.excludePlugins"
                    [height]="(fieldConfig.rows * 21) + 21">
    </wysiwyg-editor>
</ng-template>
