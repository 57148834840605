import {AbstractOperationDefinition} from 'src/modules/operations/interfaces';
import {AppTask} from 'src/modules/tasks/task-view/task-view.component';
import {IUserLogin} from '_types/rest';

export type ITaskUsersContext = {
    task: AppTask,
    mentions?: IUserLogin[]
}

export class TaskUsersAssigneeOperation extends AbstractOperationDefinition<string, ITaskUsersContext> {
    readonly endpoint = 'task_users';
    readonly name = 'assignee';

    lang = 'TASK_USER_ASSIGNEE';
    icon = 'fa-plus';
    readonly modalSize = 'xl';

    modalHeaderDisabled = true;

    component
        = (): Promise<unknown> => import('./task-assignee-modal/task-assignee-modal.component');

    access(): boolean {
        return true;
    }
}

export const taskUsersOperations = [
    TaskUsersAssigneeOperation
] as const;
