/* eslint-disable max-len */
/**
 * This file has been auto generated. Do not edit.
 */
import {IDocumentTemplateComponentWrapper} from '_types/rest/Entity/IRestDocumentTemplateComponentWrapper';
import {IRestDocumentTemplateComponentWrapper} from '_types/rest/Entity/IRestDocumentTemplateComponentWrapper';
import {IDocumentTemplateComponentFilter} from '_types/rest/Entity/IRestDocumentTemplateComponentFilter';
import {IRestDocumentTemplateComponentFilter} from '_types/rest/Entity/IRestDocumentTemplateComponentFilter';
import {IDocumentTemplateComponentToken} from '_types/rest/Entity/IRestDocumentTemplateComponentToken';
import {IRestDocumentTemplateComponentToken} from '_types/rest/Entity/IRestDocumentTemplateComponentToken';
import {IDocumentTypeComponentSection} from '_types/rest/Entity/IRestDocumentTypeComponentSection';
import {IRestDocumentTypeComponentSection} from '_types/rest/Entity/IRestDocumentTypeComponentSection';
import {IBaseRestEntity} from '_types/rest/IBaseRestEntity';
import {IRestObject} from 'core/rest/rest-object.service';

export const DocumentTemplateComponentType = {
    DOCUMENT_TEMPLATE_COMPONENT_TYPE_MAIN: 1,
    DOCUMENT_TEMPLATE_COMPONENT_TYPE_EXTENDED: 2
} as const;

export const DocumentTemplateComponentTypeOptions = [
    {
        value: DocumentTemplateComponentType.DOCUMENT_TEMPLATE_COMPONENT_TYPE_MAIN,
        label: 'DOCUMENT_TEMPLATE_COMPONENT_TYPE_MAIN',
    },
    {
        value: DocumentTemplateComponentType.DOCUMENT_TEMPLATE_COMPONENT_TYPE_EXTENDED,
        label: 'DOCUMENT_TEMPLATE_COMPONENT_TYPE_EXTENDED',
    }
] as const;

export interface IDocumentTemplateComponentConst {
    type: [{id: 1, value: 'DOCUMENT_TEMPLATE_COMPONENT_TYPE_MAIN'}, {id: 2, value: 'DOCUMENT_TEMPLATE_COMPONENT_TYPE_EXTENDED'}]
}

export interface IDocumentTemplateComponent extends IBaseRestEntity {
    id?: number,
    /**
     * @default 1
     */
    type?: typeof DocumentTemplateComponentType[keyof typeof DocumentTemplateComponentType],
    /**
     * @maxLength 256
     */
    name?: string | null,
    content?: string | null,
    css?: string | null,
    order?: number | null,
    documentTemplateComponentWrapper?: IDocumentTemplateComponentWrapper | string | null,
    documentTemplateComponentFilters?: (IDocumentTemplateComponentFilter | string)[],
    documentTemplateComponentTokens?: (IDocumentTemplateComponentToken | string)[],
    documentTypeComponentSection?: IDocumentTypeComponentSection | string | null,
    /**
     * @description Record status
     *  0 = deleted
     *  1 = active.
     * @default 1
     */
    status?: number,
    /**
     * @description Creation timestamp.
     * @default CURRENT_TIMESTAMP
     * @format date-time
     */
    created?: string,
    /**
     * @description Last modification timestamp.
     * @format date-time
     */
    modified?: string | null
}

/** @deprecated */
export interface IRestDocumentTemplateComponent extends IDocumentTemplateComponent, IRestObject {
    documentTemplateComponentWrapper?: IRestDocumentTemplateComponentWrapper | string | null,
    documentTemplateComponentFilters?: IRestDocumentTemplateComponentFilter[] | string[],
    documentTemplateComponentTokens?: IRestDocumentTemplateComponentToken[] | string[],
    documentTypeComponentSection?: IRestDocumentTypeComponentSection | string | null,
}

/** @deprecated */
export const IRestDocumentTemplateComponentConstType = DocumentTemplateComponentType;
/** @deprecated */
export type IRestDocumentTemplateComponentConst = IDocumentTemplateComponentConst & IRestObject;
