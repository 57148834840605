import {IFile, ITaskUserOutputDto} from '_types/rest';
import {TaskFilesComponent} from 'src/modules/tasks/task-view/task-files/task-files.component';
import {TaskNoteEditComponent} from 'src/modules/tasks/task-view/task-note-edit/task-note-edit.component';
import {TaskStoryComponent} from 'src/modules/tasks/task-view/task-story/task-story.component';
import {Utils} from 'src/services/utils';
import {Component, DoCheck, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {RestClient} from 'src/modules/rest/rest-client.service';
import {StateParams, StateService} from '@uirouter/core';
import {FilesService} from 'src/services/files.service';
import isEqual from 'lodash.isequal';
import {concatMap, forkJoin, mergeMap, Observable, of, Subject, switchMap} from 'rxjs';
import {IScheduleTask} from '_types/rest/Entity/IRestScheduleTask';
import {IContractItem} from '_types/rest/Entity/IRestContractItem';
import {
    ICategory,
    ICompany,
    IService,
    ITask,
    ITaskCustomField,
    ITaskFile,
    ITaskInputDto,
    ITaskNoteFile,
    ITaskNoteOutputDto,
    ITaskOutputDto,
    ITaskTemplate,
    With,
    TaskState,
    ITaskAcceptanceCriterionDto,
    ITaskAcceptanceCriterion,
    TaskAssignmentRole
} from '_types/rest';
import {IRestObject} from 'src/modules/rest/objects';
import {FormControl, NgForm} from '@angular/forms';
import {TasksTableService} from 'src/modules/tasks/tasks-table/tasks-table.service';
import DateExtended from 'date-extensions';
import {TIMETRACKER_FEATURE_PRIVILEGE} from 'src/providers/feature-privileges';
import {finalize, tap, takeUntil, map, catchError, debounceTime, distinctUntilChanged} from 'rxjs/operators';
import {ITaskViewModelServiceTask, TaskViewService} from 'src/modules/tasks/task-view/task-view.service';
import {
    IUpdateObjectNotificationCb, ObjectNotificationSettingStates
} from 'src/modules/notification-settings/notification-settings.interfaces';
import {RestEndpoint} from 'src/modules/rest/rest-endpoint';
import {PinItemOperation, UnPinItemOperation} from 'view-modules/operations/pin-items/pin-items.operations';
import {IOperationClass, IOperationResult} from 'src/modules/operations/interfaces';
import {RestError} from 'src/modules/rest/rest.error';
import {RestSubsequentCollectionService} from 'src/modules/rest/rest-subsequent-collection.service';
import {ArchiveTask, RestoreTask} from 'view-modules/operations/tasks/tasks.operations';
import {TimeTrackerService} from 'src/modules/time-tracker/time-tracker.service';


export type ITaskContractItemWith = With<IContractItem, 'service', {
    service: With<IService, 'company', {
        company: With<ICompany, 'tags'>
    }>
}>;

export type boundTask = Omit<With<ITaskOutputDto,
        'actionTaskTemplate' |
        'taskCustomFields' |
        'company' |
        'taskProcessingModel',
        {
            scheduleTasks: With<IScheduleTask, 'schedule'>[]
        }>, 'task'>
    & {
    company: With<ICompany, 'tags'>,
    initiative?: ICategory | null,
    sourceIri?: string,
    taskUsers: With<ITaskUserOutputDto, 'userLogin'>[]
};

export type AppTask = Partial<Omit<boundTask, 'actionTaskTemplate' | 'taskCustomFields'> & {
    actionTaskTemplate: ITaskTemplate | string,
    taskAcceptanceCriteria: (ITaskAcceptanceCriterion | ITaskAcceptanceCriterionDto)[],
    taskCustomFields?: (TaskCustomFieldsWithControl)[],
    task: string
}>;

// It must be overwritten. Backend - Requires full object type for permissions to work properly. Only IRI can be sent.
export type ITaskInputDtoOverride = Omit<ITaskInputDto, 'contractItem' | 'task' | 'company'> & {
    contractItem?: IContractItem | string | null,
    task?: ITask | string | null,
    company?: ICompany | string | null,
    state?: typeof TaskState[keyof typeof TaskState],
    initiative?: ICategory | null,
    scheduleTasks: IScheduleTask[]
}

export interface TaskCustomFieldsWithControl extends ITaskCustomField {
    _formControl?: FormControl
}

export type TaskViewOperations = 'new' | 'subtask';

const taskOperations: TaskViewOperations[] = ['new', 'subtask'];

export type boundTaskNote = With<ITaskNoteOutputDto, 'taskNoteFiles', {
    taskNoteFiles: With<ITaskNoteFile, 'file'>[]
}>;

export type boundTaskFile = With<ITaskFile, 'file'>;

export type boundNoteFile = With<ITaskNoteFile, 'file'>;

@Component({
    selector: 'task-view',
    templateUrl: './task-view.component.html',
    styleUrls: ['./task-view.component.scss']
})
export class TaskViewComponent implements OnInit, OnDestroy, DoCheck {
    viewInitialized = false;

    taskRest: IRestObject<'tasks/item', boundTask>;
    private parentTask: boundTask;

    private taskInput: ITaskViewModelServiceTask;
    taskInputFiles: IFile[];

    task: AppTask;
    private taskCopy: Partial<AppTask>;
    taskParam: number | string;
    taskId: number;
    taskOperation: TaskViewOperations;

    @ViewChild(NgForm) taskForm: NgForm;
    @ViewChild(TaskNoteEditComponent) taskNoteEditComponent: TaskNoteEditComponent;
    @ViewChild(TaskStoryComponent) taskStoryComponent: TaskStoryComponent;
    @ViewChild(TaskFilesComponent) taskFilesComponent: TaskFilesComponent;

    pinOperations: IOperationClass[] = [
        PinItemOperation,
        UnPinItemOperation
    ];

    archiveOperations: IOperationClass[] = [
        ArchiveTask,
        RestoreTask
    ];

    creatingTask = false;

    readonly BASE_PARENT_NAME_LENGTH = 16;
    currentParentNameLength = 16;

    readonly TIMETRACKER_FEATURE_PRIVILEGE = TIMETRACKER_FEATURE_PRIVILEGE;

    taskNameChangeSubject = new Subject<void>();

    private readonly stateParams: StateParams;
    private readonly _destroy$ = new Subject<void>();

    openModal = false;

    constructor(
        private readonly restClient: RestClient,
        public readonly stateService: StateService,
        private readonly filesService: FilesService,
        private readonly tasksTableService: TasksTableService,
        private readonly taskViewService: TaskViewService,
        private readonly restSubsequentCollectionService: RestSubsequentCollectionService,
        private readonly timeTrackerService: TimeTrackerService
    ) {
        this.stateParams = this.stateService.params;
    }

    ngOnInit(): void {
        this.reloadOnTrackerNoteAddition();
        this.enableAnimationAfterInit();
        this.initTasksFromService();
        this.updateTaskName();
    }

    private reloadOnTrackerNoteAddition(): void {
        this.timeTrackerService.timeTrackerNoteAddedSubject
            .pipe(
                takeUntil(this._destroy$),
                switchMap(() => this.taskReload())
            )
            .subscribe();
    }

    private enableAnimationAfterInit(): void {
        // Hide task-view while initializing to disable animation which would occur even if task is not specified.
        // Use setTimeout instead of afterContentInit to avoid NG100 error.
        setTimeout(() => {
            this.viewInitialized = !this.viewInitialized;
        });
    }

    private initTasksFromService(): void {
        this.taskViewService.getNewTask$()
            .pipe(takeUntil(this._destroy$))
            .subscribe((taskInput) => {
                this.taskInput = taskInput.taskModel;
                this.taskInputFiles = taskInput.files;

                this.stateService.go('.', {task: 'new'});
            });
    }

    private updateTaskName(): void {
        this.taskNameChangeSubject
            .pipe(
                debounceTime(500),
                distinctUntilChanged(),
                takeUntil(this._destroy$)
            )
            .subscribe(() => {
                this.fieldsChanged(['name']);
            });
    }

    ngDoCheck(): void {
        if (this.taskParam === this.stateParams.task) {
            return;
        }

        this.taskParam = this.stateParams.task;

        this.clearTaskObjects();
        this.parseTaskParam();

        if (!this.taskId && !this.taskOperation) {
            this.openModal = false;
            return;
        }

        this.openModal = true;
        this.loadRestData()
            .subscribe({
                next: () => {
                    this.prepareTaskObjects();
                },
                error: (error: RestError) => {
                    this.close();
                    throw error;
                }
            });
    }

    ngOnDestroy(): void {
        this._destroy$.next();
        this._destroy$.complete();
    }

    /**
     * Parse task param in search of ID or Operation name
     */
    private parseTaskParam(): void {
        if (this.stateParams.task) {
            if (parseInt(this.stateParams.task)) {
                this.taskId = parseInt(this.stateParams.task);
                this.taskOperation = undefined;
            } else if (taskOperations.includes(this.stateParams.task)) {
                this.taskOperation = this.stateParams.task;
            } else {
                this.taskId = undefined;
                this.taskOperation = undefined;
            }
        } else {
            this.taskId = undefined;
            this.taskOperation = undefined;
            this.taskInput = undefined;

            this.clearTaskObjects();
        }
    }

    /**
     * Queue REST data for Task or Operation
     */
    private loadRestData(): Observable<unknown> {
        return forkJoin([
            (typeof this.taskId === 'number')
                ? this.loadTaskRest()
                : of(undefined),
            (this.stateParams.parentTask && this.taskOperation === 'subtask')
                ? this.loadParentTask()
                : of(undefined)
        ]);
    }

    /**
     * Load taskRest
     */
    private loadTaskRest(): Observable<IRestObject<'tasks/item', boundTask>> {
        const task = this.restClient.endpoint<'tasks/item', boundTask>('tasks/item').get(this.taskId),
            users = this.restSubsequentCollectionService.getAll('task_users/list', {'task.id': this.taskId});

        return forkJoin([task, users])
            .pipe(
                map(([task, users]) => {
                    task.taskUsers = users;
                    return task;
                }),
                tap((response) => {
                    this.taskRest = response;
                })
            );
    }

    /**
     * Load parent Task
     */
    loadParentTask(): Observable<boundTask> {
        const id = this.stateParams.parentTask;

        return this.restClient
            .endpoint<'tasks', boundTask>('tasks')
            .get(
                id,
                {with: ['taskUsers.userLogin']}
            )
            .pipe(
                tap((response) => {
                    this.parentTask = response;
                })
            );
    }

    private clearTaskObjects(): void {
        // Clear complex objects
        this.taskId = undefined;
        this.taskOperation = undefined;

        this.task = undefined;
        this.taskCopy = undefined;
        this.taskRest = undefined;
        this.parentTask = undefined;
    }

    /**
     * Copy taskRest to internal object use
     */
    private prepareTaskObjects(): void {
        if (this.taskRest) {
            this.task = Utils.clone(this.taskRest);

            // TODO move to task-view components (task-actions)
            if (typeof this.task.actionTaskTemplate !== 'undefined') {
                Utils.shortenFields(this.task, ['actionTaskTemplate']);
            }

            // TODO move to task-view components (task-details & reccurency)
            if (typeof this.task.taskDates !== 'undefined') {
                this.task.startDate = typeof this.task.startDate === 'string'
                    ? new DateExtended(this.task.startDate).format('Y-m-d')
                    : null;
                this.task.dueDate = typeof this.task.dueDate === 'string'
                    ? new DateExtended(this.task.dueDate).format('Y-m-d')
                    : null;
            }
            this.taskCopy = Utils.clone(this.task) as boundTask;
            // x2 else if reduced to one - logic was redundant. I don't know if this || condition is necessary, but I
            // merged condition from first elseif and this redundant elseif, because I don't want to change logic
            // during refactor
        } else if (this.parentTask && (!this.task || !this.taskRest)) {
            this.task = {
                task: this.parentTask['@id'],
                parentTask: {
                    id: this.parentTask.id,
                    name: this.parentTask.name
                },
                taskAcceptanceCriteria: []
            };
        } else {
            this.task = this.restClient.endpoint<'tasks', AppTask>('tasks').createObject({
                startDate: null,
                taskDates: [{}],
                taskProcessingModel: null,
                taskAcceptanceCriteria: []
            });
        }

        if (this.taskInput) {
            Object.assign(this.task, this.taskInput);
        }
    }


    /**
     * Trigger fields changed related actions like input change
     */
    fieldsChanged(fields: (keyof ITaskInputDtoOverride)[]): void {
        if (!this.fieldsAreValid(fields)) {
            return;
        }

        if (!this.task.id) {
            return;
        }

        const payload = {};
        fields.forEach((field) => {
            if (!isEqual(this.task[field], this.taskCopy[field])) {
                payload[field] = this.task[field];
            }
        });

        this.partialSave({
            ...payload,
        }).subscribe();
    }

    private fieldsAreValid(fields: (keyof ITaskInputDtoOverride)[]): boolean {
        return !fields.some((field) => {
            return !this.taskForm.controls[field]?.valid;
        });
    }

    /**
     * Save selected part of model
     */
    partialSave(
        payload: Partial<ITaskInputDtoOverride>,
        reload = false
    ): Observable<IRestObject<'tasks/item', boundTask>> {
        if (!this.task.id) {
            throw new Error('partialSave cannot be used to create new task');
        }

        const request$ = this.getItemEndpoint().update(
            this.task.id,
            payload,
            {
                with: 'taskStates.taskUser.userLogin'
            }
            // forced typing is required - input and output DTOs are different
        ) as unknown as Observable<IRestObject<'tasks/item', boundTask>>;

        if (reload) {
            return request$
                .pipe(
                    concatMap(() => {
                        return this.taskReload();
                    }),
                    tap(() => {
                        this.restClient.savedToast();
                    }),
                    finalize(() => {
                        this.tasksTableService.reloadLists();
                    })
                );
        }

        return request$
            .pipe(
                mergeMap((response) => {
                    return this.restSubsequentCollectionService
                        .getAll('task_users/list', {'task.id': this.taskId})
                        .pipe(map((users) => {
                            response.taskUsers = users;
                            return response;
                        }));
                }),
                tap((response) => {
                    this.prepareViewByResponse(response);
                    this.restClient.savedToast();
                }),
                finalize(() => {
                    this.tasksTableService.reloadLists();
                })
            );
    }

    getItemEndpoint(): RestEndpoint<'tasks/item', Partial<ITaskInputDtoOverride>> {
        return this.restClient.endpoint<'tasks/item', Partial<ITaskInputDtoOverride>>('tasks/item');
    }

    private prepareViewByResponse(response: IRestObject<'tasks/item', boundTask>) {
        // this.task can be undefined.
        // It happens when someone changes field and immediately closes component.
        // Then response handling is fired when this.task is already cleared
        if (!this.task?.id) {
            return;
        }
        this.taskRest = response;
        this.resetForm(this.taskForm);
        this.prepareTaskObjects();
    }

    private createTask(payload: ITaskInputDtoOverride): Observable<IRestObject<'tasks/item', boundTask>> {
        const request$ = this.restClient.endpoint<'tasks/list', Partial<ITaskInputDtoOverride>>('tasks/list')
            .create(payload);

        // forced typing is required - input and output DTOs are different
        return (request$ as unknown as Observable<IRestObject<'tasks/item', boundTask>>)
            .pipe(
                concatMap((task) => {
                    return this.getChangeNotificationSetting$(task);
                }),
                tap((response) => {
                    this.prepareViewByResponse(response);
                    this.restClient.savedToast();
                }),
                finalize(() => {
                    this.tasksTableService.reloadLists();
                })
            );
    }

    private getChangeNotificationSetting$(
        task: IRestObject<'tasks/item', boundTask>
    ): Observable<IRestObject<'tasks/item', boundTask>> {
        if (this.task.taskNoteNotificationSetting === ObjectNotificationSettingStates.STATE_DEFAULT) {
            return of(task);
        }

        return this.getItemEndpoint()
            .update(
                task.id,
                {taskNoteNotificationSetting: this.task.taskNoteNotificationSetting}
            )
            .pipe(
                map(() => task)
            );
    }

    /**
     * Save all visible fields from taskForm
     */
    taskSave(): void {
        this.creatingTask = true;

        const taskToSave = this.getTaskInputDtoFromAppTask();
        this.taskInput = null;

        this.createTask(taskToSave)
            .pipe(
                finalize(() => {
                    this.creatingTask = false;
                })
            )
            .subscribe((response) => {
                // Redirect to edit form
                this.stateService.go(
                    '.',
                    {
                        task: response.id,
                        note: null,
                        parentTask: null
                    },
                    {
                        reload: true
                    }
                );

            });
    }

    private getTaskInputDtoFromAppTask(): ITaskInputDtoOverride {
        const userLoginsEndpoint = this.restClient.endpoint('user_logins'),
            taskAuthor = this.task.taskUsers.find((taskUser) =>
                taskUser.role === TaskAssignmentRole.TASK_ASSIGNMENT_ROLE_AUTHOR
            ),
            taskDto = {
                name: this.task.name,
                description: this.task.description,
                priority: this.task.priority,
                permissionToAdd: this.task.permissionToAdd,
                visibilityType: this.task.visibilityType,
                startDate: this.task.startDate,
                dueDate: this.task.weekly ? undefined : this.task.dueDate,
                task: Utils.getIriFromObjectProperty(this.task, 'task'),
                company: Utils.getIriFromObjectProperty(this.task, 'company'),
                contractItem: Utils.getIriFromObjectProperty(this.task, 'contractItem'),
                actionTaskTemplate: this.task.actionTaskTemplate,
                weekly: this.task.weekly,
                taskAcceptanceCriteria: this.task.taskAcceptanceCriteria,
                userLoginsAssignee: this.task.taskUsers
                    .filter((taskUser) =>
                        taskUser.role === TaskAssignmentRole.TASK_ASSIGNMENT_ROLE_ASSIGNEE)
                    .map((taskUser) => {
                        return userLoginsEndpoint.getIri(taskUser.userLogin.id);
                    }),
                userLoginAuthor: taskAuthor ? userLoginsEndpoint.getIri(taskAuthor.userLogin.id) : undefined,
                scheduleTasks: this.task.scheduleTasks?.[0].enabled ? this.task.scheduleTasks : undefined,
                completionAllowedAheadOfSchedule: this.task.completionAllowedAheadOfSchedule
            } as unknown as ITaskInputDtoOverride;

        if (this.task.sourceIri) {
            taskDto.sourceIri = this.task.sourceIri;
        }

        this.addNewTaskFields(taskDto);

        return taskDto;
    }

    private addNewTaskFields(task: ITaskInputDtoOverride): void {
        if (this.task.id) {
            return;
        }

        const taskDto = this.task as unknown as ITaskInputDto;
        if (taskDto.files) {
            task.files = taskDto.files;
        }
    }

    downloadFile(itemWithFile: ITaskFile): void {
        this.filesService.download({
            '@id': itemWithFile['@id'],
            file: itemWithFile.file
        }, 'file');
    }

    taskReload(): Observable<IRestObject<'tasks/item', boundTask>> {
        if (!this.task) {
            return of(undefined);
        }

        const currenTaskId = this.task.id,
            currentTaskOperation = this.taskOperation;
        this.task.id = null;
        this.taskOperation = undefined;

        return this.loadTaskRest()
            .pipe(
                finalize(() => {
                    this.task.id = currenTaskId;
                    this.taskOperation = currentTaskOperation;
                }),
                tap(() => {
                    this.prepareTaskObjects();
                }),
                catchError((error) => {
                    this.close();
                    throw error;
                })
            );
    }

    /**
     * Nullify related params - this will close Task view
     */
    close(): void {
        const params = this.stateParams;
        if ('task' in params) {
            params.task = null;
        }
        if ('note' in params) {
            params.note = null;
        }
        if ('parentTask' in params) {
            params.parentTask = null;
        }
        this.stateService.go('.', params);
    }

    private resetForm(taskForm?: NgForm): void {
        if (!taskForm) {
            return;
        }

        taskForm.form.markAsPristine();
        taskForm.form.markAsUntouched();
    }

    updateObjectNotificationSettingCb: IUpdateObjectNotificationCb = (value) => {
        if (!this.task.id) {
            this.task.taskNoteNotificationSetting = value;
            return of(this.task);
        }

        return this.partialSave({taskNoteNotificationSetting: value});
    }

    handlePinOperationSuccess(result: IOperationResult): void {
        if (!result.success) {
            return;
        }

        if (typeof result.customValue === 'boolean') {
            this.task.pinnedItem = result.customValue;
        }
        this.tasksTableService.reloadLists();
        this.restClient.savedToast();
    }

    handleArchiveSuccess(result: IOperationResult): void {
        if (!result.success) {
            return;
        }

        this.taskReload().subscribe();
        this.restClient.savedToast();
    }
}
