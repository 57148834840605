import {Component, Inject, Input, OnDestroy, OnInit} from '@angular/core';
import {
    IReportDisplayOperationOutputDto,
    IReportDisplayStructureFieldOutputDto,
    IReportDisplayStructureOutputDto, IReportDisplayStructureReferencedDisplayOutputDto,
    IReportGenerateOutputDto
} from '_types/rest';
import {DynamicTableService} from 'src/modules/dynamic-table/dynamic-table.service';
import {
    DynamicTableDisplay, IDynamicTableOperation,
    DynamicTablePipes,
    IDynamicTableCollection, IDynamicTableColumnDefinition, IDynamicTableDefinition
} from 'src/modules/dynamic-table/interfaces';
import {
    ReportDisplayGroupViewComponent
} from 'src/modules/reports/report-display-group-view/report-display-group-view.component';
import {IFieldFilters, IOrder, ITableToolsResponse} from 'angular-bootstrap4-table-tools';
import {Observable} from 'rxjs';
import {RestError} from 'src/modules/rest/rest.error';
import {AppTableToolsService} from 'src/modules/app-table-tools/app-table-tools.service';
import {TableToolsResolver} from 'angular-bootstrap4-table-tools/lib/table-tools-config.service';
import {RestClient} from 'src/modules/rest/rest-client.service';
import {TranslateService} from 'src/modules/translate/translate.service';
import {Utils} from 'src/services/utils';
import {ReportExportOperation} from 'src/modules/reports/report-export.operation';
import {IQueryObject} from 'src/services/url-parser.service';
import {REPORT_OPERATIONS_LEDGER} from 'src/modules/reports/report-operations.provider';
import {IOperationClass} from 'src/modules/operations/interfaces';
import {OperationsService} from 'src/modules/operations/operations.service';


@Component({
    selector: 'report-display-view',
    templateUrl: './report-display-view.component.html'
})
export class ReportDisplayViewComponent implements OnInit, OnDestroy {
    @Input() structure: IReportDisplayStructureOutputDto;

    tableInstance: IDynamicTableCollection<object>;

    private exportOperations: IOperationClass[];
    currentQuery: IQueryObject;

    constructor(
        @Inject(REPORT_OPERATIONS_LEDGER) private readonly reportOperations: IOperationClass[],
        private readonly dynamicTableService: DynamicTableService,
        private readonly displayGroup: ReportDisplayGroupViewComponent,
        private readonly restClient: RestClient,
        private readonly translateService: TranslateService,
        private readonly operationsService: OperationsService
    ) {
    }

    ngOnInit(): void {
        if (this.structure.format !== 'table') {
            throw new Error(`Unsupported report format '${this.structure.format}'`);
        }

        this.exportOperations = this.createExportOperations();

        this.tableInstance = this.dynamicTableService.createFromCollection(
            {
                id: this.structure['@id'].replace(/\//g, '_'),
                name: this.displayGroup.currentStateName,
                order: [{
                    field: 'created',
                    direction: 'desc'
                }],
                collectionOperations: this.getCollectionOperations(),
                itemOperations: [], // itemOperations are configured in resolver
                columns: this.getColumns(),
                perPage: 30,
                perPageOptions: [{number: 30, text: '30'}],
                resolver: this.getResolver(),
                filters: this.getFilters(),
                searchField: false,
                export: false
            }
        );
        if (!Object.keys(this.tableInstance.filters.controls).length) {
            this.tableInstance.filters.emitFilterInitialized();
        }
    }

    private createExportOperations(): IOperationClass[] {
        return this.structure.references
            .filter((reference) => {
                return reference.type === 'REPORT_DISPLAY_TYPE_FILE';
            })
            .map((reference) => {
                const dynamicOperationClass = this.defineDynamicReportOperationClass(reference, this);
                this.operationsService.registerDynamicOperation(dynamicOperationClass);
                return dynamicOperationClass;
            });
    }

    private defineDynamicReportOperationClass(
        reference: IReportDisplayStructureReferencedDisplayOutputDto,
        thisArg: ReportDisplayViewComponent
    ): IOperationClass {
        return class extends ReportExportOperation {
            constructor() {
                super(reference, thisArg);
            }
        };
    }

    private getCollectionOperations(): IDynamicTableOperation<object>[] {
        return this.mapOperations(this.structure.operations)
            .concat(this.mapExportOperations());
    }

    private mapOperations(
        operations: IReportDisplayOperationOutputDto[],
        contextCreator?: (operation: IReportDisplayOperationOutputDto) => (item: object) => unknown
    ): IDynamicTableOperation<object>[] {
        return operations.map((operation) => {
            const operationClass = this.findOperationClass(operation.name, operation.endpoint.name);
            return {
                operationClass,
                context: typeof contextCreator === 'function' ? contextCreator(operation) : undefined
            };
        }) as IDynamicTableOperation<object>[];
        // typecast needed because endpoint & operation are of type 'string'
        // so operation validation would fail
    }

    private findOperationClass(name: string, endpoint: string): IOperationClass {
        return this.reportOperations.find((operationClass) => {
            const tempInstance = this.operationsService.get(operationClass);
            return tempInstance.name === name && tempInstance.endpoint === endpoint;
        });
    }

    private mapExportOperations(): IDynamicTableOperation<object>[] {
        return this.exportOperations.map((operation) => {
            return {
                operationClass: operation,
                context: () => this.currentQuery
            } as IDynamicTableOperation<object>;
        });
    }

    private getColumns(): IDynamicTableColumnDefinition<object>[] {
        return this.structure.fields.map((field) => {
            return {
                field: field.field,
                th: {
                    content: field.name,
                    sort: field.sortable
                },
                td: {
                    pipes: this.getPipesConfig(field),
                    display: this.getDisplayConfig(field),
                    separator: field.format === null ? ', ' : undefined
                }
            };
        });
    }

    private getPipesConfig(
        field: IReportDisplayStructureFieldOutputDto
    ): DynamicTablePipes[] | undefined {
        if (['datetime', 'date'].includes(field.type)) {
            if (
                typeof field.format !== 'undefined'
                && field.format === 'no_hours'
            ) {
                return [{
                    pipe: 'date',
                    args: ['full', false]
                }];
            }
            return ['date'];
        } else if (field.type === 'currency') {
            return ['currency'];
        }

        return undefined;
    }

    private getDisplayConfig(
        field: IReportDisplayStructureFieldOutputDto
    ): DynamicTableDisplay<object> | undefined {
        switch (field.format) {
            case 'label': {
                let backgroundColor = '#404e67', color = '#ffffff';

                if (field.formatOptions.color) {
                    backgroundColor = field.formatOptions.color as string;
                    color = Utils.getTextColor(backgroundColor);
                }

                return {
                    type: 'badge',
                    config: (item) => {
                        if (Array.isArray(item)) { // ['color', 'value'] tuple
                            backgroundColor = item[0];
                            color = Utils.getTextColor(backgroundColor);
                            item.splice(0, 1); // remove color from value
                        }

                        return {
                            backgroundColor,
                            color
                        };
                    }
                };
            }
            default:
                return undefined;
        }
    }

    private getResolver(): TableToolsResolver<object> {
        const restClient = this.restClient,
            translateService = this.translateService,
            structure = this.structure,
            saveQuery = (query: IQueryObject) => {
                this.currentQuery = query;
            },
            mapOperations = this.mapOperations.bind(this);

        return function(
            // Resolver is always invoked in TableTools instance context.
            this: IDynamicTableCollection<object>,
            limit: number,
            offset: number,
            order: IOrder[],
            search: string,
            filters: IFieldFilters
        ) {
            return new Observable<ITableToolsResponse<object>>((subscriber) => {
                const query = AppTableToolsService.configureQuery(
                    limit,
                    offset,
                    order,
                    search,
                    filters,
                    this,
                    structure.endpoint.query
                );
                saveQuery(query);

                this.loading = true;

                restClient.endpoint<string, IReportGenerateOutputDto>(structure.endpoint.name)
                    .get(structure.endpoint.id, query)
                    .subscribe({
                        next: (response) => {
                            if (response.content.length) {
                                this.itemOperations = mapOperations(
                                    response.content[0]['$operations'],
                                    (operation) => {
                                        return (item) => {
                                            // extract context (id) for current operation, on current row
                                            const currentOperation = item.$operations.find((o) => {
                                                return o.name === operation.name;
                                            });

                                            if (currentOperation) {
                                                return {
                                                    id: currentOperation.endpoint.id
                                                };
                                            }

                                            return null;
                                        };
                                    }
                                );
                            }
                            AppTableToolsService.setPerPage(response.pagination.perPage, this, translateService);
                            subscriber.next(
                                {
                                    data: response.content,
                                    count: response.pagination.resultsCount,
                                    countFiltered: response.pagination.resultsCount,
                                }
                            );
                            this.loading = false;
                            subscriber.complete();
                        },
                        error: (error: RestError) => {
                            restClient.handleError(error);
                            this.loading = false;
                            subscriber.error(error);
                        }
                    });
            });
        };
    }

    private getFilters(): IDynamicTableDefinition<object>['filters'] {
        return this.structure.filters.reduce<IDynamicTableDefinition<object>['filters']>(
            (map, item) => {
                let options;
                if (Array.isArray(item.options)) {
                    options = item.options.map((option) => {
                        return {
                            value: option.id,
                            label: option.value
                        };
                    });
                }

                map[item.field] = {
                    type: this.translateType(item.type),
                    name: item.name,
                    options,
                    query: item.query,
                    endpoint: item.relation,
                    property: item.property,
                    valueKey: 'id',
                    deselectable: true
                };

                return map;
            }, {});
    }

    private translateType(type: string): string {
        switch (type) {
            case 'array':
                return 'select';
            case 'int':
            case 'tinyint':
            case 'smallint':
            case 'integer':
            case 'bigint':
            case 'decimal':
                return 'number';
            case 'datetime':
                return 'date';
            case 'bool':
            case 'boolean':
                return 'checkbox';
            default:
                return type;
        }
    }

    ngOnDestroy(): void {
        this.exportOperations.forEach((operationClass) => {
            this.operationsService.unregisterDynamicOperation(operationClass);
        });
    }
}
