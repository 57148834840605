import {AbstractOperationDefinition} from 'src/modules/operations/interfaces';
import {AbstractDeleteOperation} from 'view-modules/operations/abstract/abstract-delete.operation';
import {IRestCollection, IRestObject} from 'src/modules/rest/objects';
import {ICompany} from '_types/rest';
import {Injector} from '@angular/core';
import {ConfirmModalService} from 'src/modules/global-components/confirm-modal/confirm-modal.service';
import {Observable} from 'rxjs';
import {tap} from 'rxjs/operators';
import {RestClient} from 'src/modules/rest/rest-client.service';
import {IOperationResult} from 'src/modules/operations/interfaces';

export interface ClientDomainContextItem {
    service: IRestObject<'services'>;
    company: ICompany['@id'];
}

export interface ClientDomainContextCollection {
    service: IRestCollection<'services'>;
    company: ICompany['@id'];
}


export class ClientDomainsAddOperation extends AbstractOperationDefinition<string, ClientDomainContextCollection> {
    readonly endpoint = 'services';
    readonly name = 'post';
    lang = 'ADD';
    icon = 'fa-plus';
    component = (): Promise<unknown> => import('./client-domains-form/client-domains-form.component');

    access(context: ClientDomainContextCollection): boolean {
        if (!context.service) {
            return false;
        }

        if (RestClient.isRestObject(context.service) || RestClient.isRestCollection(context.service)) {
            return context.service.access(this.name);
        }

        return false;
    }
}

export class ClientDomainsEditOperation extends AbstractOperationDefinition<string, ClientDomainContextItem> {
    readonly endpoint = 'services';
    readonly name = 'put';
    lang = 'EDIT';
    icon = 'fa-edit';
    component = (): Promise<unknown> => import('./client-domains-form/client-domains-form.component');

    access = (context: ClientDomainContextItem): boolean => {
        return context.service.access('put');
    }
}

export class ClientDomainsArchiveOperation extends AbstractOperationDefinition<string, ClientDomainContextItem> {
    readonly endpoint = 'services'
    readonly name = 'archive';
    lang = 'ARCHIVE_DOMAIN';
    icon = 'fa-archive';

    invoke(
        context: ClientDomainContextItem,
        injector: Injector
    ): Observable<IOperationResult> {
        const confirmModalService = injector.get(ConfirmModalService),
            restClient = injector.get(RestClient);

        return confirmModalService.confirmOperation('DOMAIN_ARCHIVE_CONFIRM', () => {
            return restClient.endpoint('services/archive')
                .update(context.service.id, {})
                .pipe(
                    tap(() => {
                        restClient.savedToast();
                    })
                );
        }, {
            langYes: 'ARCHIVE_DOMAIN_CONFIRM_LANG_YES',
            text: 'ACTION_CANNOT_BE_UNDONE',
            primaryBtn: 'danger'
        });
    }

    access = (context: ClientDomainContextItem): boolean => {
        return !context.service.archive && context.service.access(this.name);
    }
}

export class ClientDomainsDeleteOperation extends AbstractDeleteOperation<'services'> {
    readonly endpoint = 'services';
    lang = 'DELETE';
    confirmMessage = 'COMPANY_REMOVE_CONFIRM';
    confirmOptions = {
        langYes: 'COMPANY_REMOVE_CONFIRM_LANG_YES'
    };
}

export const clientDomainsOperations = [
    ClientDomainsAddOperation,
    ClientDomainsEditOperation,
    ClientDomainsArchiveOperation,
    ClientDomainsDeleteOperation
] as const;
