import {Component, Input, OnDestroy, OnInit, TemplateRef} from '@angular/core';
import {
    IDynamicTable,
    IDynamicTableFilterDefinition,
    IDynamicTableFilterTemplateContext
} from 'src/modules/dynamic-table/interfaces';
import {DynamicTableFilter} from 'src/modules/dynamic-table/dynamic-table-filters/dynamic-table-filter';
import {Subject} from 'rxjs';
import {startWith, takeUntil} from 'rxjs/operators';
import {Comparison} from 'src/services/comparison';
import {RelationSelectService} from 'src/modules/dynamic-fields/controls/relation-select/relation-select.service';
import {TranslateService} from 'src/modules/translate/translate.service';
import {DateUtils} from 'src/services/date-utils';
import {EntityFieldDisplay} from '_types/rest';
import {CategoriesService} from 'src/modules/categories/categories.service';
import {IEntityCategory} from '_types/custom/IEntityCategory';

interface IFilter {
    id: string,
    filter: DynamicTableFilter,
    initialValue: unknown,
    template: TemplateRef<IDynamicTableFilterTemplateContext>,
    context?: unknown,
    initializing: boolean
}

@Component({
    selector: 'dynamic-table-filters',
    templateUrl: './dynamic-table-filters.component.html',
    styleUrls: ['./dynamic-table-filters.component.scss']
})
export class DynamicTableFiltersComponent implements OnInit, OnDestroy {

    @Input() tableConfig: IDynamicTable<object>;

    filters: IFilter[] = [];

    private _destroy$ = new Subject<void>();

    constructor(
        private readonly relationSelectService: RelationSelectService,
        private readonly categoriesService: CategoriesService,
        private readonly translateService: TranslateService,
    ) {
    }

    ngOnInit(): void {
        Object.entries(this.tableConfig.filters.controls)
            .filter(([, filter]) => typeof filter.fieldConfig !== 'undefined')
            .forEach(([id, filter]) => {
                this.filters.push({
                    id,
                    filter,
                    initialValue: filter.fieldConfig.default,
                    template: 'template' in filter.fieldConfig ? filter.fieldConfig.template : undefined,
                    context: Object.assign(
                        {$implicit: filter},
                        'context' in filter.fieldConfig ? filter.fieldConfig.context : {}
                    ),
                    initializing: !('template' in filter.fieldConfig)
                });

                if (
                    !('type' in filter.fieldConfig)
                    || filter.fieldConfig.type !== 'relation' && filter.fieldConfig.type !== 'category'
                ) { // relations & categories are handled via objectValueChange
                    filter.valueChanges
                        .pipe(
                            startWith(filter.value),
                            takeUntil(this._destroy$)
                        )
                        .subscribe((value) => {
                            this.filterChange(value, id, filter);
                        });
                }
            });

        setTimeout(() => {
            this.checkInitialization();
        });

        this.initTemplateFiltersDefaultValue();
    }

    private initTemplateFiltersDefaultValue(): void {
        this.filters.forEach((filter) => {
            if (typeof filter.template === 'undefined') {
                return;
            }

            this.setInitialValueForFilter(filter);
        });
    }

    private checkInitialization(): void {
        if (this.filters.some((filter) => filter.initializing)) {
            return;
        }

        if (typeof this.tableConfig.filters.emitFilterInitialized === 'function') {
            this.tableConfig.filters.emitFilterInitialized();
        }
    }

    onInitializingChange(filter: IFilter, initializing: boolean): void {
        filter.initializing = initializing;
        this.checkInitialization();
    }

    resetFilters(): void {
        this.removeAllQuickFilters();

        this.filters.forEach((filter, index, array) => {
            this.setInitialValueForFilter(filter);

            const isLastFilter = index === array.length - 1;
            if (isLastFilter) {
                // why only once? resetFilter changes all filters, so we want to notify about changes only once.
                // of course, someone can listen on specific this.filters' element, and then he can not be
                // notified, but for now, it seems to be correct implementation
                filter.filter.updateValueAndValidity();
            }
        });
    }

    private setInitialValueForFilter(item: IFilter): void {
        if (item.initialValue === item.filter.value) {
            return;
        }

        if (
            !item.initialValue
            && 'multiple' in item.filter.fieldConfig
            && item.filter.fieldConfig.multiple
        ) {
            item.initialValue = [];
        }

        item.filter.setValue(item.initialValue, {emitEvent: false});
        if (
            !('type' in item.filter.fieldConfig)
            || item.filter.fieldConfig.type !== 'relation'
        ) {
            this.filterChange(item.initialValue, item.id, item.filter);
        }
    }

    private removeAllQuickFilters(): void {
        this.tableConfig.selectedFilters.forEach((filter) => {
            if (filter.id.startsWith('qf_')) {
                filter.removeFn();
            }
        });
    }

    filterChange(value: unknown, id: string, filter: DynamicTableFilter): void {
        if (this.shouldBeTreatedAsMultiple(value, filter)) {
            // remove all selectedFilters coming from this filter
            this.tableConfig.selectedFilters
                .slice()
                .reverse()
                .forEach((filter, idx, arr) => {
                    if (filter.id.startsWith(id)) {
                        this.tableConfig.selectedFilters.splice(arr.length - 1 - idx, 1);
                    }
                });

            if (!Array.isArray(value) || !value.length) {
                return;
            }

            if (this.shouldFilterBeCompressed(value.length, filter.fieldConfig)) {
                const label = this._getSelectedFilterLabel(value, filter.fieldConfig);
                this.pushNewSelectedFilter(id, label, value, filter);
                return;
            }
            // process each item individually
            value.forEach((v, idx) => {
                this.filterChange(v, `${id}_${idx}`, filter);
            });
            return;
        }

        const isEmpty = value === null
            || typeof value === 'undefined'
            || value === ''
            || this.isDeselectValue(value, filter.fieldConfig);

        const currentItem = this.tableConfig.selectedFilters.find(Comparison.criteria({id}));

        if (!isEmpty) {
            const label = this._getSelectedFilterLabel(value, filter.fieldConfig);

            if (!currentItem) {
                // relations & categories value comes as objectValue here, map it back to scalar value
                if (
                    'type' in filter.fieldConfig
                    && (filter.fieldConfig.type === 'relation' || filter.fieldConfig.type === 'category')
                ) {
                    value = value[filter.fieldConfig.valueKey || '@id'];
                }

                this.pushNewSelectedFilter(id, label, value, filter);
            } else {
                currentItem.label = label;
            }
        } else if (currentItem) {
            this.tableConfig.selectedFilters.splice(this.tableConfig.selectedFilters.indexOf(currentItem), 1);
        }
    }

    private shouldBeTreatedAsMultiple(value: unknown, filter: DynamicTableFilter): boolean {
        return Array.isArray(value)
            || (!value && !Array.isArray(value) && 'multiple' in filter.fieldConfig);
    }

    private shouldFilterBeCompressed(valueLength: number, fieldConfig: IDynamicTableFilterDefinition): boolean {
        return fieldConfig.filterCompressingOptions?.compress
            && valueLength >= fieldConfig.filterCompressingOptions.threshold;
    }

    private pushNewSelectedFilter(id: string, label: string, value: unknown, filter: DynamicTableFilter) {
        if (
            'display' in filter.fieldConfig
            && filter.fieldConfig.display !== EntityFieldDisplay.ENTITY_FIELD_DISPLAY_TRUE
        ) {
            return;
        }

        this.tableConfig.selectedFilters.push({
            hidden: filter.fieldConfig.hidden,
            id,
            label,
            removeFn: () => {
                if (Array.isArray(filter.value)) {
                    // just deselect this value from filter, selectedFilter will be removed by filterChange
                    if (this.isCompressedFilter(value)) {
                        value.forEach((valueItem) => {
                            this.removeValueFromFilter(valueItem, filter);
                        });
                    } else {
                        this.removeValueFromFilter(value, filter);
                    }
                } else {
                    filter.setValue(null);
                }
            },
            get removable() {
                return filter.fieldConfig.removable !== false;
            }
        });
    }

    private isCompressedFilter(value: unknown): value is Array<unknown> {
        return Array.isArray(value);
    }

    private removeValueFromFilter(value: unknown, filter: DynamicTableFilter): void {
        if (filter.value.includes(value)) {
            filter.setValue(filter.value.filter((v) => v !== value));
        }
    }

    private isDeselectValue(value: unknown, fieldConfig: IDynamicTableFilterDefinition): boolean {
        return 'deselectValue' in fieldConfig
            && fieldConfig.deselectValue === value;
    }

    private _getSelectedFilterLabel(
        value: unknown,
        fieldConfig: IDynamicTableFilterDefinition
    ): string {
        if (this.isCompressedFilter(value)) {
            const translatedName = this.translateService.get(fieldConfig.name);

            if (fieldConfig.filterCompressingOptions?.label) {
                return `${translatedName} (${value.length}): ${fieldConfig.filterCompressingOptions?.label}`;
            }

            const selectedItemsTranslated = this.translateService.get('SELECTED_ITEMS');
            return `${translatedName}: ${value.length} ${selectedItemsTranslated}`;
        }

        if ('template' in fieldConfig) {
            const translatedName = this.translateService.get(fieldConfig.name),
                displayValue = typeof fieldConfig.displayValue === 'function'
                    ? fieldConfig.displayValue(value)
                    : value;

            return `${translatedName}: ` + DateUtils.removeHours(String(displayValue));
        }

        if (fieldConfig.type === 'checkbox') {
            return fieldConfig.name;
        }

        let label = '';
        if (fieldConfig.name) {
            if (this.translateService.isTranslationKey(fieldConfig.name)) {
                // this may not yet be done by dynamic-field
                fieldConfig.name = this.translateService.get(fieldConfig.name);
            }
            label = `${fieldConfig.name}: `;
        }

        if (fieldConfig.type === 'relation') {
            label += this.relationSelectService.computeEntityPresentation(fieldConfig.endpoint, value).label;
        } else if (fieldConfig.type === 'select') {
            let optionLabel = fieldConfig.options.find(Comparison.criteria({value})).label as string;
            if (this.translateService.isTranslationKey(optionLabel)) {
                optionLabel = this.translateService.get(optionLabel);
            }
            label += optionLabel;
        } else if (fieldConfig.type === 'category') {
            label += this.translateService.get((value as IEntityCategory).name);
        } else {
            label += value;
        }

        return label;
    }

    ngOnDestroy(): void {
        this._destroy$.next();
        this._destroy$.complete();
    }
}
