/**
 * This file has been auto generated. Do not edit.
 */
import {IRuleLog} from '_types/rest/Entity/IRestRuleLog';
import {IRuleEvent} from '_types/rest/Entity/IRestRuleEvent';
import {IRuleAction} from '_types/rest/Entity/IRestRuleAction';
import {IRuleCondition} from '_types/rest/Entity/IRestRuleCondition';
import {IBaseRestEntity} from '_types/rest/IBaseRestEntity';

export const RuleState = {
    RULE_STATE_ENABLED: 1,
    RULE_STATE_DISABLED: 2
} as const;

export const RuleStateOptions = [
    {
        value: RuleState.RULE_STATE_ENABLED,
        label: 'RULE_STATE_ENABLED',
    },
    {
        value: RuleState.RULE_STATE_DISABLED,
        label: 'RULE_STATE_DISABLED',
    }
] as const;

export const RuleExecutionType = {
    RULE_EXECUTION_TYPE_WORKER: 1,
    RULE_EXECUTION_TYPE_SYNC: 2
} as const;

export const RuleExecutionTypeOptions = [
    {
        value: RuleExecutionType.RULE_EXECUTION_TYPE_WORKER,
        label: 'RULE_EXECUTION_TYPE_WORKER',
    },
    {
        value: RuleExecutionType.RULE_EXECUTION_TYPE_SYNC,
        label: 'RULE_EXECUTION_TYPE_SYNC',
    }
] as const;

export interface IRuleConst {
    state: [{id: 1, value: 'RULE_STATE_ENABLED'}, {id: 2, value: 'RULE_STATE_DISABLED'}],
    executionType: [{id: 1, value: 'RULE_EXECUTION_TYPE_WORKER'}, {id: 2, value: 'RULE_EXECUTION_TYPE_SYNC'}]
}

export interface IRule extends IBaseRestEntity {
    id?: number,
    /**
     * @default 1
     */
    state?: typeof RuleState[keyof typeof RuleState],
    /**
     * @default 1
     */
    executionType?: typeof RuleExecutionType[keyof typeof RuleExecutionType],
    /**
     * @default 10
     */
    priority: number,
    name: string,
    ruleLogs?: (IRuleLog | string)[],
    ruleEvent?: IRuleEvent | string | null,
    ruleActions?: (IRuleAction | string)[],
    ruleConditions?: (IRuleCondition | string)[],
    /**
     * @description Record status
     *  0 = deleted
     *  1 = active.
     * @default 1
     */
    status?: number,
    /**
     * @description Creation timestamp.
     * @default CURRENT_TIMESTAMP
     * @format date-time
     */
    created?: string,
    /**
     * @description Last modification timestamp.
     * @format date-time
     */
    modified?: string | null
}
