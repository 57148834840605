export abstract class LocalStorage {
    /**
     * Serialize value as JSON and store it under given key in localStorage.
     */
    static set(key: string, value: unknown): void {
        localStorage.setItem(key, JSON.stringify(value));
    }

    /**
     * Get data stored under given key in localStorage and unserialize it from JSON.
     * If it's not set, defaultValue would be returned instead.
     */
    static get<T>(key: string, defaultValue?: T): T {
        const ret = localStorage.getItem(key);
        if (ret === null) {
            return defaultValue;
        }
        return JSON.parse(ret);
    }

    /**
     * Drop data stored under given key in localStorage
     */
    static drop(key: string): void {
        localStorage.removeItem(key);
    }

    static keys(): string[] {
        return Object.keys(localStorage);
    }
}
