import {Observable, Subject} from 'rxjs';
import {filter} from 'rxjs/operators';
import {Inject, Injectable, OnDestroy} from '@angular/core';
import {ITimeTrackerBroadcast} from 'src/modules/time-tracker/interfaces';
import {INotificationsBroadcast} from 'src/modules/notifications/interfaces';

export interface IBroadcastChannel {
    type: string,
    types: string
}

type BroadCastTypes = ITimeTrackerBroadcast | INotificationsBroadcast;

export interface BroadcastMessage<Type, Payload> {
    type: Type;
    payload: Payload;
}

// eslint-disable-next-line @angular-eslint/use-injectable-provided-in
@Injectable()
export class BroadcastService<T extends BroadCastTypes,
    U extends BroadCastTypes = Extract<BroadCastTypes, { type: T['type'] }>>
implements OnDestroy {

    private broadcastChannel: BroadcastChannel;
    private onMessage = new Subject<BroadcastMessage<U['types'], U['payload']>>();

    static countInstances: Partial<Record<string, number>> = {};

    constructor(@Inject(String) private broadcastChannelName: T['type']) {
        if ('BroadcastChannel' in self) {
            this.broadcastChannel = new BroadcastChannel(broadcastChannelName);
        }
        this.broadcastChannel.onmessage = (message) => {
            this.onMessage.next(message.data);
        };

        if (typeof BroadcastService.countInstances[broadcastChannelName] === 'undefined') {
            BroadcastService.countInstances[broadcastChannelName] = 1;
        } else {
            BroadcastService.countInstances[broadcastChannelName]++;
        }
    }

    publish(type: U['types'], message?: U['payload']): void {
        this.onMessage.next({type: type, payload: message});
        this.broadcastChannel.postMessage({type: type, payload: message});
    }

    messagesOfType(types: U['types'][] | U['types']): Observable<BroadcastMessage<U['types'], U['payload']>> {
        return this.onMessage.pipe(
            filter((message) => {
                if (Array.isArray(types)) {
                    return types.includes(message.type);
                } else {
                    return message.type === types;
                }
            })
        );
    }

    ngOnDestroy(): void {
        this.destroy();
    }

    destroy(): void {
        BroadcastService.countInstances[this.broadcastChannelName]--;

        if (BroadcastService.countInstances[this.broadcastChannelName] === 0) {
            this.onMessage.complete();
            this.broadcastChannel.close();
        }
    }
}
