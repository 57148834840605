import {Observable, Subject} from 'rxjs';
import {AbstractOperationDefinition} from 'src/modules/operations/interfaces';
import {IRestObject} from 'src/modules/rest/objects';
import {Injector} from '@angular/core';
import {RestClient} from 'src/modules/rest/rest-client.service';
import {ConfirmModalService} from 'src/modules/global-components/confirm-modal/confirm-modal.service';
import {ILoginSuccessResponse} from 'src/modules/rest/user/user-login.service';
import {IOperationResult} from 'src/modules/operations/interfaces';
import {UserActionsService} from 'src/services/user-actions.service';

export interface IImpersonateResponse {
    token?: string,
    panel?: string,
    user?: unknown
}

/**
 *  User operation works with context of type:
 *  - IRestObject<'user_logins'>
 *  - unknown & {user: IRestObject<'user_logins'>}
 * */
class AbstractUserOperation
    extends AbstractOperationDefinition<'user_logins', IRestObject<'user_logins'>> {
    readonly endpoint = 'user_logins'
    readonly name: string;
    confirmPrimaryBtn?: 'danger' | 'success';
    restEndpoint?: string;

    invoke(
        context: IRestObject<'user_logins'>,
        injector: Injector
    ): Observable<IOperationResult> {
        const invokeSubject = new Subject<IOperationResult>(),
            restClient = injector.get(RestClient),
            confirmModal = injector.get(ConfirmModalService),
            withUser = (context as unknown as {user: typeof context}),
            user = withUser.user ? withUser.user : context,
            endpointName = this.restEndpoint ? this.restEndpoint : `${this.endpoint}/${this.name}`;

        confirmModal.confirmOperation(
            `${this.lang}_CONFIRM`,
            () => {
                restClient.endpoint(endpointName).update(user.id, {})
                    .subscribe({
                        next: (response) => {
                            if (typeof this.handleResponse === 'function') {
                                this.handleResponse(response, injector);
                            }
                            invokeSubject.next({success: true});
                            invokeSubject.complete();
                            restClient.savedToast();
                        },
                        error: (err) => {
                            invokeSubject.error(err);
                        }
                    });
            },
            {
                langYes: `${this.lang}_CONFIRM_LANG_YES`,
                primaryBtn: this.confirmPrimaryBtn || 'success'
            }
        ).subscribe((operationSuccess) => {
            invokeSubject.next(operationSuccess);
            invokeSubject.complete();
        });

        return invokeSubject.asObservable();
    }

    access(context: IRestObject<'user_logins'>): boolean {
        const withUser = (context as unknown as {user: typeof context}),
            user = withUser.user ? withUser.user : context;

        let hasAccess = !('access' in user) || user.access(this.name);
        if (typeof this.additionalAccessCheck === 'function') {
            hasAccess = hasAccess && this.additionalAccessCheck(user);
        }
        return hasAccess;
    }
    additionalAccessCheck: (user: IRestObject<'user_logins'>) => boolean;
    handleResponse: (
        response,
        injector: Injector
    ) => void;
}

export class ResendActivationMail extends AbstractUserOperation {
    readonly name = 'resend_activation_mail';
    restEndpoint = 'user_logins/resend_activation_email';
    lang = 'USER_RESEND_ACTIVATION';
    icon = 'fa-envelope';
    additionalAccessCheck = (user: IRestObject<'user_logins'>): boolean => {
        return user.active === 0;
    }
    readonly confirmPrimaryBtn = 'danger';
}

export class ForceMfaDisable extends AbstractUserOperation {
    readonly name = 'force_mfa_disable';
    lang = 'USER_MFA_DISABLE';
    icon = 'fa-unlock-alt';
    additionalAccessCheck = (user: IRestObject<'user_logins'>): boolean => {
        return !!user.mfaEnabled;
    }
    readonly confirmPrimaryBtn = 'danger';
    restEndpoint = 'user_logins/mfa/force_disable';
}

export class ForcePrivileges extends AbstractUserOperation {
    readonly name = 'force_privileges';
    lang = 'USER_RECREATE_PRIVILEGES';
    icon = 'fa-sync';
    restEndpoint = 'user_logins/refresh_privileges';
}

export class ImpersonateUser extends AbstractUserOperation {
    readonly name = 'impersonate';
    lang = 'USER_IMPERSONATE';
    icon = 'fa-sign-in-alt';

    additionalAccessCheck = (user: IRestObject<'user_logins'>): boolean => {
        return user.active === 1;
    }

    handleResponse = (
        response: IRestObject<'user_logins/impersonate', IImpersonateResponse> | ILoginSuccessResponse,
        injector: Injector
    ): void => {
        const userActionsService = injector.get(UserActionsService);

        userActionsService.handleImpersonateResponse(response);
    }
}

export class ForceLogout extends AbstractUserOperation {
    readonly name = 'force_logout';
    lang = 'USER_LOGOUT';
    icon = 'fa-sign-out-alt';
    restEndpoint = 'user_logins/logout';
}

export const userOperations = [
    ResendActivationMail,
    ForceMfaDisable,
    ForcePrivileges,
    ImpersonateUser,
    ForceLogout
] as const;
