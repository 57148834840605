<div class="file-list" *ngIf="files.length">
    <div class="mt-4" *ngFor="let file of files">
        <div class="file-upload__files-progress container">
            <div class="row">
                <div class="col-8 text-left">
                    <i class="fa fa-file position-relative pr-2"></i>
                    <span>{{file.name | shortText: 35}}</span>
                </div>
                <div class="col-4 text-right actions">
                    <span class="pr-3">{{file.size | humanFileSize}}</span>
                    <!-- TODO intentionally I left this wrong first part of condition to avoid changes in BSP behaviour. Will be fixed in BSP-3972 -->
                    <a href="javascript:"
                       *ngIf="(file.id && file.originalItem && !(file.originalItem | access: 'delete'))
                                  || (!file.originalItem)"
                       (click)="fileDelete.emit(file)">
                        <i class="fas fa-times text-danger position-relative"></i>
                    </a>
                </div>
            </div>
            <div class="progress ng-fade" *ngIf="!file.progressHidden && file.progress">
                <div class="progress-bar" [ngStyle]="{width: file.progress + '%'}"></div>
            </div>
        </div>
    </div>
</div>
