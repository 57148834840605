<div class="task-icons">
    <div [(bsDropdown)]="dropdown">
        <p class="text-primary mb-2 cursor-pointer"
           [title]="!dropdown ? ('PERMISSION_TO_ADD' | translate) : ''">
            <i class="far font-weight-bold"
               *ngIf="!loading; else loadingTemplate"
               [ngClass]="taskViewComponent.task.permissionToAdd === permissionToAdd.PRIVATE ? 'fa-lock' : 'fa-unlock'"
               bsDropdownToggle>
            </i>

            <ng-template #loadingTemplate>
                <loader></loader>
            </ng-template>
        </p>
        <div class="dropdown-menu">
            <ng-container *ngIf="taskViewComponent.task?.id; else newTask">
                <operation *ngIf="taskViewComponent.task.permissionToAdd !== permissionToAdd.PUBLIC"
                           [operationClass]="taskPermissionOperations.makeTaskOpen"
                           viewType="text"
                           [context]="taskViewComponent.task"
                           (operationSuccess)="handleSuccess($event, permissionToAdd.PUBLIC)"
                           (operationPending)="loading = $event"
                           customClass="dropdown-item task-icons-dropdown-item py-1 pl-2">
                </operation>

                <operation *ngIf="taskViewComponent.task.permissionToAdd !== permissionToAdd.PRIVATE"
                           [operationClass]="taskPermissionOperations.makeTaskClosed"
                           viewType="text"
                           [context]="taskViewComponent.task"
                           (operationSuccess)="handleSuccess($event, permissionToAdd.PRIVATE)"
                           (operationPending)="loading = $event"
                           customClass="dropdown-item task-icons-dropdown-item py-1 pl-2">
                </operation>
            </ng-container>

            <ng-template #newTask>
                <a *ngIf="taskViewComponent.task.permissionToAdd !== permissionToAdd.PUBLIC"
                   class="dropdown-item task-icons-dropdown-item py-1 pl-2"
                   (click)="handleNewTaskAction(permissionToAdd.PUBLIC)">
                    <i class="fa fa-unlock text-primary"></i>
                    <span class="ml-1">{{'OPEN' | translate}}</span>
                </a>

                <a *ngIf="taskViewComponent.task.permissionToAdd !== permissionToAdd.PRIVATE"
                   class="dropdown-item task-icons-dropdown-item py-1 pl-2"
                   (click)="handleNewTaskAction(permissionToAdd.PRIVATE)">
                    <i class="fa fa-lock text-primary"></i>
                    <span class="ml-1">{{'CLOSE' | translate}}</span>
                </a>
            </ng-template>
        </div>
    </div>
</div>
