import {animate, animation, style} from '@angular/animations';


export const fadeInAnimation = animation([
    style({ opacity: 0 }),
    animate(
        '0.4s ease-out',
        style({ opacity: 1 })
    )
]);
