import {Pipe, PipeTransform} from '@angular/core';

@Pipe({name: 'default'})
export class DefaultPipe implements PipeTransform {
    transform(value: string | number, defaultValue:  string | number): string | number {
        if (typeof value === 'undefined' || value === null || value === '') {
            return defaultValue;
        }
        return value;
    }
}
