/* eslint-disable max-len */
/**
 * This file has been auto generated. Do not edit.
 */
import {IDocumentTemplate} from '_types/rest/Entity/IRestDocumentTemplate';
import {IRestDocumentTemplate} from '_types/rest/Entity/IRestDocumentTemplate';
import {IDocumentFile} from '_types/rest/Entity/IRestDocumentFile';
import {IRestDocumentFile} from '_types/rest/Entity/IRestDocumentFile';
import {IBaseRestEntity} from '_types/rest/IBaseRestEntity';
import {IRestObject} from 'core/rest/rest-object.service';

export const DocumentTemplateFormatFormat = {
    DOCUMENT_TEMPLATE_FORMAT_HTML: 1,
    DOCUMENT_TEMPLATE_FORMAT_PDF: 2,
    DOCUMENT_TEMPLATE_FORMAT_PUBLIC_HTML: 3,
    DOCUMENT_TEMPLATE_FORMAT_JSON: 4
} as const;

export const DocumentTemplateFormatFormatOptions = [
    {
        value: DocumentTemplateFormatFormat.DOCUMENT_TEMPLATE_FORMAT_HTML,
        label: 'DOCUMENT_TEMPLATE_FORMAT_HTML',
    },
    {
        value: DocumentTemplateFormatFormat.DOCUMENT_TEMPLATE_FORMAT_PDF,
        label: 'DOCUMENT_TEMPLATE_FORMAT_PDF',
    },
    {
        value: DocumentTemplateFormatFormat.DOCUMENT_TEMPLATE_FORMAT_PUBLIC_HTML,
        label: 'DOCUMENT_TEMPLATE_FORMAT_PUBLIC_HTML',
    },
    {
        value: DocumentTemplateFormatFormat.DOCUMENT_TEMPLATE_FORMAT_JSON,
        label: 'DOCUMENT_TEMPLATE_FORMAT_JSON',
    }
] as const;

export interface IDocumentTemplateFormatConst {
    format: [{id: 1, value: 'DOCUMENT_TEMPLATE_FORMAT_HTML'}, {id: 2, value: 'DOCUMENT_TEMPLATE_FORMAT_PDF'}, {id: 3, value: 'DOCUMENT_TEMPLATE_FORMAT_PUBLIC_HTML'}, {id: 4, value: 'DOCUMENT_TEMPLATE_FORMAT_JSON'}]
}

export interface IDocumentTemplateFormat extends IBaseRestEntity {
    id?: number,
    /**
     * @default 1
     */
    format?: typeof DocumentTemplateFormatFormat[keyof typeof DocumentTemplateFormatFormat],
    documentTemplate: IDocumentTemplate | string | null,
    documentFile?: (IDocumentFile | string)[],
    /**
     * @description Record status
     *  0 = deleted
     *  1 = active.
     * @default 1
     */
    status?: number,
    /**
     * @description Creation timestamp.
     * @default CURRENT_TIMESTAMP
     * @format date-time
     */
    created?: string,
    /**
     * @description Last modification timestamp.
     * @format date-time
     */
    modified?: string | null
}

/** @deprecated */
export interface IRestDocumentTemplateFormat extends IDocumentTemplateFormat, IRestObject {
    documentTemplate: IRestDocumentTemplate | string | null,
    documentFile?: IRestDocumentFile[] | string[],
}

/** @deprecated */
export const IRestDocumentTemplateFormatConstFormat = DocumentTemplateFormatFormat;
/** @deprecated */
export type IRestDocumentTemplateFormatConst = IDocumentTemplateFormatConst & IRestObject;
