import {ITaskNoteFile} from '_types/rest/Entity/IRestTaskNoteFile';
import {IUserLogin, UserLoginType} from '_types/rest/Entity/IRestUserLogin';
import {ITaskHistoryOutputDto} from '_types/rest/Dto/IRestTaskHistoryTaskHistoryOutputDto';
import {DateUtils} from 'src/services/date-utils';
import {FilesService} from 'src/services/files.service';
import {RestClient} from 'src/modules/rest/rest-client.service';
import {TranslateService} from 'src/modules/translate/translate.service';
import {Component, Host, Input, OnInit} from '@angular/core';
import {IQueryObject} from 'src/services/url-parser.service';
import {forkJoin, Observable, of} from 'rxjs';
import {AppTask, boundTaskNote, TaskViewComponent} from 'src/modules/tasks/task-view/task-view.component';
import orderBy from 'lodash.orderby';
import {DatePipe} from 'src/pipes/date.pipe';
import {IEntityEventHistoryOutputDto, With} from '_types/rest';
import {IRestObject} from 'src/modules/rest/objects';
import {EventHistoryService} from 'src/modules/history/events-history/event-history.service';

interface ITaskStory {
    id: number;
    original: IRestObject<string, ITaskHistoryOutputDto | boundTaskNote>
    type: string;
    author: string;
    userLogin: IUserLogin | { name: string };
    date: string;
    canEdit?: boolean;
    text?: string;
    files?: With<ITaskNoteFile, 'file'>[];
    group?: ITaskStory[];
    grouped?: boolean;
    modified?: string;
    showMore?: boolean;
    to?: boundTaskNote['to'];
}

@Component({
    selector: 'task-story',
    templateUrl: './task-story.component.html',
    styleUrls: ['./task-story.component.scss'],
    providers: [DatePipe]
})
export class TaskStoryComponent implements OnInit {
    @Input() task: AppTask;

    private taskNotes: boundTaskNote[] = [];

    private taskHistories: IEntityEventHistoryOutputDto[] = [];
    private taskStory: ITaskStory[] = [];

    taskStoryFiltered: ITaskStory[] = [];

    private taskNotesTotal = 0;
    private taskHistoriesTotal = 0;
    taskStoryTotal = 0;

    readonly initialStoryLimit = 3;
    storyDisplayLimit = this.initialStoryLimit;

    loading = false;
    showAll = false;
    displayHistories = false;

    private iterator = 0;

    constructor(
        @Host() public readonly taskViewComponent: TaskViewComponent,
        private readonly restClient: RestClient,
        private readonly filesService: FilesService,
        private readonly translateService: TranslateService,
        private readonly eventHistoryService: EventHistoryService
    ) {
    }

    ngOnInit(): void {
        this.loadStory().subscribe(() => {
            this.computeTaskStoryFilter();
        });
    }

    /**
     * Handle reload request from outside of component
     * (now it's after note add)
     */
    reloadStoryFromOutside(): void {
        this.loadStory().subscribe(() => {
            this.computeTaskStoryFilter();
        });
    }

    /**
     * Load taskNotes/taskHistories and merge them to story feed
     */
    loadStory(): Observable<void> {
        return new Observable((observer) => {
            if (this.task.id) {
                const query: IQueryObject = {
                    'task.id': this.task.id
                };
                if (this.showAll) {
                    query.pagination = false;
                } else {
                    query.itemsPerPage = this.initialStoryLimit;
                }
                query.public = 1;

                if (this.displayHistories) {
                    // To avoid history time-continuum bugs, set pagination=false
                    query.pagination = false;
                }
                this.loading = true;

                this.iterator = 0;

                forkJoin([
                    this.restClient.endpoint<'task_notes', never, boundTaskNote>('task_notes').getAll(query),
                    (this.displayHistories
                        ? this.eventHistoryService.getEntityEventHistory(
                            this.task.id,
                            'tasks',
                            {
                                pagination: !this.showAll
                            }
                        )
                        : of(undefined))
                ]).subscribe({
                    next: ([taskNotes, taskHistories]) => {
                        this.taskNotes = taskNotes;
                        this.taskNotesTotal = taskNotes.hydra().totalItems;

                        const taskNotesStory = this.taskNotes.map((note: IRestObject<'task_notes', boundTaskNote>) => {
                            return this.mapNoteToStory(note);
                        });

                        this.taskStoryTotal = this.taskNotesTotal;

                        let taskHistoriesStory: ITaskStory[] = [];

                        if (taskHistories) {
                            this.taskHistories = taskHistories;
                            this.taskHistoriesTotal = taskHistories.hydra().totalItems;

                            // Map task histories to story
                            taskHistoriesStory = this.taskHistories
                                .map((history: IRestObject<string, IEntityEventHistoryOutputDto>) => {
                                    return this.mapHistoryToStory(history);
                                });
                            this.taskStoryTotal = this.taskNotesTotal + this.taskHistoriesTotal;
                        }
                        this.prepareTaskStory(taskNotesStory, taskHistoriesStory);
                    },
                    complete: () => {
                        this.loading = false;
                        observer.next();
                        observer.complete();
                    }
                });
            } else {
                this.taskStory = [];
                observer.next();
                observer.complete();
            }
        });
    }

    private mapNoteToStory(note: IRestObject<'task_notes', boundTaskNote>): ITaskStory {
        const author = this.computeEntryAuthor(note);
        return {
            id: ++this.iterator,
            original: note,
            type: 'note',
            author: author,
            userLogin: this.computeEntryUserLogin(note, author),
            date: note.date,
            canEdit: note.canEdit,
            text: note.text,
            files: note.taskNoteFiles,
            to: note.to
        };
    }

    private mapHistoryToStory(history: IRestObject<string, ITaskHistoryOutputDto>): ITaskStory {
        const author = this.computeEntryAuthor(history);
        return {
            id: ++this.iterator,
            original: history,
            type: 'history',
            author: author,
            userLogin: this.computeEntryUserLogin(history, author),
            date: history.date,
        };
    }

    private prepareTaskStory(taskNotesStory: ITaskStory[], taskHistoriesStory: ITaskStory[]) {
        this.taskStory = [
            ...taskNotesStory,
            ...taskHistoriesStory
        ];

        this.taskStory = orderBy(
            this.taskStory,
            ['date'],
            false
        );

        if (taskHistoriesStory.length) {
            this.groupStoryEntries('history');
        }
    }

    private groupStoryEntries(type: 'note' | 'history'): void {
        if (Array.isArray(this.taskStory)) {
            // Group by userLogin + date (up to minute)
            this.taskStory.forEach((item) => {
                if (item.type === type && !item.grouped) {
                    const itemDate = DateUtils.matchFirstDateTime(item.date, 'minutes');
                    const group = this.taskStory.filter((element) => {
                        if (element.type === type) {
                            const elementDate = DateUtils.matchFirstDateTime(element.date, 'minutes');
                            const result
                                = elementDate === itemDate
                                && element.author === item.author
                                && item.id !== element.id;
                            if (result) {
                                element.grouped = true;
                            }
                            return result;
                        }
                    });
                    if (group && group.length) {
                        item.group = group;
                    }
                }
            });

            // Remove grouped items
            this.taskStory = this.taskStory.filter((item) => {
                return item.type !== type || !item.grouped;
            });

        }
    }

    showAllToggle(): void {
        this.showAll = !this.showAll;
        if (this.showAll) {
            this.storyDisplayLimit = undefined;
            this.loadStory().subscribe(() => {
                this.computeTaskStoryFilter();
            });
        } else {
            this.storyDisplayLimit = this.initialStoryLimit;
            this.computeTaskStoryFilter();
        }

    }

    displayHistoriesToggle(): void {
        this.loadStory().subscribe(() => {
            this.computeTaskStoryFilter();
        });
    }

    private computeTaskStoryFilter(): void {
        if (!this.displayHistories) {
            this.taskStoryFiltered = this.taskStory.filter((item) => {
                return item.type === 'note';
            });
        } else {
            this.taskStoryFiltered = this.taskStory;
        }

        this.taskStoryFiltered = this.taskStoryFiltered.slice(-this.storyDisplayLimit);
    }

    private computeEntryAuthor(
        entry: boundTaskNote | ITaskHistoryOutputDto
    ): string {
        if (typeof entry.userLogin === 'object' && entry.userLogin !== null) {
            if (entry.userLogin.type === UserLoginType.USER_TYPE_WORKER) {
                return this.translateService.get('COMMON_SYSTEM');
            } else {
                return entry.userLogin.lastName + ' ' + entry.userLogin.firstName;
            }
        } else if (entry.customUserLoginName) {
            return entry.customUserLoginName;
        } else {
            return this.translateService.get('COMMON_TASK_NOTE_LIST_AUTHOR_ANONYMOUS');
        }
    }

    private computeEntryUserLogin(
        entry: boundTaskNote | ITaskHistoryOutputDto,
        author: string
    ): IUserLogin | { name: string } {
        return (typeof entry.userLogin === 'object' && entry.userLogin !== null) ? entry.userLogin : {name: author};
    }

    downloadFile(itemWithFile: ITaskNoteFile): void {
        this.filesService.download({
            '@id': itemWithFile['@id'],
            file: itemWithFile.file
        }, 'file');
    }

}
