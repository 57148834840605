<ng-template #content>
    <ng-content></ng-content>
</ng-template>

<ng-container [ngSwitch]="viewType">
    <ng-container *ngSwitchCase="'bootstrap'">
        <div class="nav nav-tabs">
            <ng-container *ngTemplateOutlet="content">
            </ng-container>
        </div>
    </ng-container>

    <ng-container *ngSwitchDefault>
        <nav class="nav">
            <a class="nav-link nav-tabs-button" (click)="changePage('prev');">
                <i class="fa fa-chevron-left"></i>
            </a>
            <div #tabsCanvas class="nav-tabs-canvas flex-grow-1">
                <div #tabsContainer (swiperight)="changePage('prev')" (swipeleft)="changePage('next')" class="nav-tabs-container">
                    <div class="d-flex flex-nowrap">
                        <ng-container *ngTemplateOutlet="content">
                        </ng-container>
                    </div>
                    <div #activeIndicator
                         class="nav-tabs-active-indicator">
                    </div>
                </div>
            </div>
            <a class="nav-link nav-tabs-button" (click)="changePage('next')">
                <i class="fa fa-chevron-right"></i>
            </a>
        </nav>
    </ng-container>
</ng-container>
