import {Injectable} from '@angular/core';
import {Observable, of, Subscriber} from 'rxjs';
import {zip} from 'rxjs';
import {MissingTranslationsService} from 'src/modules/translate/missing-translations.service';
import {RestClient} from 'src/modules/rest/rest-client.service';

export interface ITranslateKeyValuePair {
    [translationKey: string]: string
}

interface ITranslateModuleLoad {
    [moduleName: string]: Subscriber<ITranslateKeyValuePair[]>
}

@Injectable({
    providedIn: 'root'
})
export class TranslateService {
    private localeActive = 'en';

    private _translationsTable: { [langName: string]: ITranslateKeyValuePair } = {};
    get translationsTable(): { [langName: string]: ITranslateKeyValuePair } {
        return this._translationsTable;
    }

    private loadedModules: { [langName: string]: ITranslateModuleLoad } = {};
    private knownModules: string[] = ['common'];

    constructor(private restClient: RestClient,
        private missingTranslationsService: MissingTranslationsService) {
    }

    reloadPart(translationModuleName: string): void {
        this.deletePart(translationModuleName);
        this.addPart(translationModuleName).subscribe();
    }

    addPart(translationModuleName: string): Observable<ITranslateKeyValuePair[]> {
        if (!this.knownModules.includes(translationModuleName)) {
            this.knownModules.push(translationModuleName);
        }
        return this.performRequest(this.localeActive, translationModuleName);
    }

    deletePart(translationModuleName: string): void {
        Object.keys(this.loadedModules).forEach((lang) => {
            delete this.loadedModules[lang][translationModuleName];
        });
    }

    private performRequest(localeCode: string, translationModuleName: string):
        Observable<ITranslateKeyValuePair[]> {

        return new Observable((subscriber) => {
            if (typeof this.loadedModules[localeCode][translationModuleName] === 'undefined') {
                this.loadedModules[localeCode][translationModuleName] = subscriber;

                this.restClient.endpoint<string, ITranslateKeyValuePair, ITranslateKeyValuePair>
                (`translations/${localeCode}/${translationModuleName}`).getAll()
                    .subscribe({
                        next: (response) => {
                            const result = response;
                            Object.assign(this._translationsTable[localeCode], result);
                            subscriber.next(result);
                        },
                        complete: () => {
                            subscriber.complete();
                        }
                    });
            } else {
                subscriber.next();
                subscriber.complete();
            }
        });
    }

    setLanguage(localeCode: string): Observable<ITranslateKeyValuePair[]> {
        this.localeActive = localeCode;
        let modulesToLoad;
        const requests$ = [];
        if (typeof this.loadedModules[localeCode] !== 'undefined') {
            modulesToLoad = this.knownModules.filter(
                (name) => typeof this.loadedModules[localeCode][name] === 'undefined');
        } else {
            this.loadedModules[localeCode] = {};
            this._translationsTable[localeCode] = {};
            modulesToLoad = this.knownModules;
        }

        if (modulesToLoad.length > 0) {
            modulesToLoad.forEach((moduleName) => {
                requests$.push((this.performRequest(localeCode, moduleName)));
            });
        }

        if (!requests$.length) {
            return of([]);
        }

        return zip<ITranslateKeyValuePair[]>(...requests$);
    }


    has(translationKey: string, localeCode: string = null): boolean {
        if (localeCode === null) {
            localeCode = this.localeActive;
        }

        return localeCode in this._translationsTable && translationKey in this._translationsTable[localeCode]
            && this._translationsTable[localeCode][translationKey] !== null;
    }

    get(translationKey: string, ignore = false, data: { [key: string]: string } = {}): string | undefined {
        if (this.has(translationKey)) {
            let translation = this._translationsTable[this.localeActive][translationKey];

            Object.entries(data).forEach(([key, value]) => {
                translation = translation.replace(`{{${key}}}`, value);
            });

            return translation;
        }

        if (!ignore) {
            const hasLoadedModule = Object.values(this.loadedModules[this.localeActive])
                .filter((subscriber) => !subscriber.closed);

            if (hasLoadedModule.length === 0) {
                this.missingTranslationsService.addMissingTranslation(translationKey);
            }
            return translationKey;
        }

        return undefined;
    }

    isTranslationKey(translationKey: string): boolean {
        return /^[A-Z][A-Z_0-9]+$/.test(translationKey)
            && translationKey.indexOf('ROUTE_CMS') !== 0;
    }
}
