import {NgModule} from '@angular/core';
import {LegendSubjectComponent} from 'src/modules/legend/legend-subject/legend-subject.component';
import {AppCommonModule} from 'src/modules/app-common/app-common.module';
import {AppDirectivesModule} from 'src/directives/app-directives.module';
import {BsPopoverModule} from 'angular-bootstrap4';
import {TranslateModule} from 'src/modules/translate/translate.module';
import {LegendToggleComponent} from 'src/modules/legend/legend-toggle/legend-toggle.component';


@NgModule({
    imports: [
        AppCommonModule,
        AppDirectivesModule,
        BsPopoverModule,
        TranslateModule,
    ],
    declarations: [
        LegendSubjectComponent,
        LegendToggleComponent,
    ],
    exports: [
        LegendSubjectComponent,
        LegendToggleComponent,
    ]
})
export class LegendModule {
}
