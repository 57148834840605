import {Injectable} from '@angular/core';
import {from, isObservable, Observable, Subject} from 'rxjs';
import {TranslateService} from 'src/modules/translate/translate.service';
import {IOperationResult} from 'src/modules/operations/interfaces';
import {map} from 'rxjs/operators';

export type ConfirmCallback = () => unknown;

export interface IConfirmModalOptions {
    callbackNo?: ConfirmCallback,
    text?: string,
    langYes?: string,
    langNo?: string,
    primaryBtn?: 'danger' | 'success';
}

export interface ICurrentModal {
    message: string,
    callbackYes: ConfirmCallback,
    callbackNo: ConfirmCallback,
    options: IConfirmModalOptions,
    confirmationSubject: Subject<boolean>,
    disabled?: boolean
}

@Injectable({
    providedIn: 'root'
})
export class ConfirmModalService {
    public isOpen = false;
    private modals: ICurrentModal[] = [];
    public currentModal: ICurrentModal | null = null;

    constructor(
        private translateService: TranslateService
    ) {
    }

    /**
     * Show confirmation modal
     */
    confirm(
        message: string,
        callbackYes: ConfirmCallback,
        options?: IConfirmModalOptions
    ): Observable<boolean> {
        if (this.translateService.isTranslationKey(message)) {
            message = this.translateService.get(message);
        }
        let callbackNo;
        if (options) {
            if (this.translateService.isTranslationKey(options.text)) {
                options.text = this.translateService.get(options.text);
            }
            if (this.translateService.isTranslationKey(options.langYes)) {
                options.langYes = this.translateService.get(options.langYes);
            }
            if (this.translateService.isTranslationKey(options.langNo)) {
                options.langNo = this.translateService.get(options.langNo);
            }
            if (options.callbackNo) {
                callbackNo = options.callbackNo;
            }
        }

        const confirmationSubject = new Subject<boolean>(),
            confirm = {
                message, callbackYes, callbackNo, options, confirmationSubject
            };
        if (this.currentModal === null) {
            this.currentModal = confirm;
            this.isOpen = true;
        } else {
            this.modals.push(confirm);
        }
        return confirmationSubject.asObservable();
    }

    confirmOperation(
        message: string,
        callbackYes: ConfirmCallback,
        options?: IConfirmModalOptions
    ): Observable<IOperationResult>  {
        return this.confirm(message, callbackYes, options)
            .pipe(map((success) => {
                return {success};
            }));
    }

    /**
     * Handle user response (yes/no) on confirm modal
     */
    _response(success: boolean): void {
        const callback = this.currentModal[success ? 'callbackYes' : 'callbackNo'],
            callbackResult = typeof callback === 'function' ? callback() : null;
        this.currentModal.disabled = true;

        const callback$ = isObservable(callbackResult) ? callbackResult : from(Promise.resolve(callbackResult));

        callback$.subscribe({
            next: () => {
                this.currentModal.disabled = false;
                this.currentModal.confirmationSubject.next(success);
                this.currentModal.confirmationSubject.complete();
                if (this.modals.length) {
                    this.currentModal = this.modals.shift();
                } else {
                    this.isOpen = false;
                    this.currentModal = null;
                }
            },
            error: (e: Error) => {
                this.currentModal.disabled = false;
                throw e;
            }
        });
    }
}
