import {ILegendConfig} from 'src/modules/legend/interfaces';


export const LEGEND_CONFIG: ILegendConfig = {
    'tasks': {
        'contextUserId': {
            name: 'CONTEXT_USER',
            description: 'LEGEND_CONTEXT_USER_DESCRIPTION'
        },
        'assigneeId': {
            name: 'TASK_ASSIGNMENT_ROLE_ASSIGNEE',
            description: 'LEGEND_TASK_ASSIGNEE_DESCRIPTION'
        },
        'taskDate': {
            name: 'COMMON_TASK_DATE',
            description: 'LEGEND_TASK_DATE_DESCRIPTION'
        },
        'state': {
            name: 'COMMON_STATE',
            description: 'LEGEND_TASK_STATE_DESCRIPTION'
        },
        'archived': {
            name: 'COMMON_FILTER_BY_ARCHIVE'
        },
    }
};
