import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {EditorView} from 'prosemirror-view';
import {Mark, MarkType, Node as ProseMirrorNode} from 'prosemirror-model';
import {AppEditorSchema} from 'src/modules/wysiwyg-editor/configs/app-schema';
import {TextSelection, Transaction} from 'prosemirror-state';
import {Observable, Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {
    SelectionTooltipComponent
} from 'src/modules/wysiwyg-editor/tooltips/selection-tooltip/selection-tooltip.component';
import {Validators} from 'angular-bootstrap4-validate';


@Component({
    selector: 'link-tooltip',
    templateUrl: './link-tooltip.component.html'
})
export class LinkTooltipComponent implements OnInit, OnChanges, OnDestroy {
    @Input() view: EditorView;
    @Input() selectedText: string;
    @Input() refreshLink$: Observable<void>;
    @Input() open = false;
    @Output() readonly openChange = new EventEmitter<boolean>();
    @Output() readonly blockTransactions = new EventEmitter<boolean>();

    isTooltipInInfoState = true;
    linkForm = new FormGroup({
        url: new FormControl('', Validators.url()),
        text: new FormControl(),
    });

    private readonly INFO_TOOLTIP_WIDTH = 200;
    private readonly EDIT_TOOLTIP_WIDTH = 400;
    private _destroy$ = new Subject<void>();

    constructor(
        private readonly selectionTooltip: SelectionTooltipComponent
    ) {
    }

    ngOnInit(): void {
        if (!this.refreshLink$) {
            return;
        }

        this.refreshLink$
            .pipe(takeUntil(this._destroy$))
            .subscribe(() => {
                this.loadFromSelection();
            });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.selectedText) {
            this.loadFromSelection();
        }
    }

    private loadFromSelection(): void {
        const formData = {
            text: this.selectedText,
            url: this.selectedText
        };

        const linkInfo = this.getSelectedLinkInfo(AppEditorSchema.marks.link);
        if (linkInfo) {
            formData.url = linkInfo.mark.attrs.href;
            formData.text = linkInfo.text;

            this.blockTransactions.emit(false);
            this.changeTooltipState(true);
        } else {
            this.blockTransactions.emit(true);
            this.changeTooltipState(false);
        }

        this.linkForm.reset(formData);
    }

    private getSelectedLinkInfo(
        markType: MarkType,
        document?: ProseMirrorNode
    ): { mark: Mark, text: string } {
        const searchedNode = this.getLinkFromSelection(markType, document);
        if (!searchedNode || !searchedNode.marks.length) {
            return;
        }
        return {
            mark: searchedNode.marks[0],
            text: searchedNode.text
        };
    }

    private getLinkFromSelection(
        markType: MarkType,
        document?: ProseMirrorNode
    ): ProseMirrorNode {
        const {from, to} = this.view.state.selection,
            doc = document || this.view.state.doc;

        let searchedNode;
        doc.nodesBetween(from, to, (node) => {
            if (searchedNode) {
                return false;
            }

            if (markType.isInSet(node.marks)) {
                searchedNode = node;
            }
        });

        return searchedNode;
    }

    private changeTooltipState(isInfoState: boolean): void {
        this.isTooltipInInfoState = isInfoState;

        const tooltipWidth = this.isTooltipInInfoState ? this.INFO_TOOLTIP_WIDTH : this.EDIT_TOOLTIP_WIDTH;
        this.selectionTooltip.updateTooltipWidth(tooltipWidth);
    }

    addLink(): void {
        const {url, text} = this.linkForm.value,
            transaction = this.view.state.tr,
            schema = this.view.state.schema,
            link = schema.marks.link.create({
                href: url,
            }),
            textNode = schema.text(text, [link]);

        this.setSelectionToLink(transaction);
        transaction.replaceSelectionWith(textNode, false);

        this.openChange.emit(false);
        this.view.dispatch(transaction);
    }

    private setSelectionToLink(transaction: Transaction): void {
        const linkInSelection = this.getLinkFromSelection(AppEditorSchema.marks.link, transaction.doc);

        if (linkInSelection) {
            const position = this.getPositionOfMarkedNode(AppEditorSchema.marks.link, transaction.doc),
                $position = transaction.doc.resolve(position),
                $anchor = transaction.doc.resolve(position + linkInSelection.textContent.length),
                selection = new TextSelection($position, $anchor);
            transaction.setSelection(selection);
        }
    }

    private getPositionOfMarkedNode(
        markType: MarkType,
        document?: ProseMirrorNode
    ): number {
        const {from, to} = this.view.state.selection,
            doc = document || this.view.state.doc;

        let positionOfNode;
        doc.nodesBetween(from, to, (node, pos) => {
            if (typeof positionOfNode !== 'undefined') {
                return false;
            }

            if (markType.isInSet(node.marks)) {
                positionOfNode = pos;
            }
        });

        return positionOfNode;
    }

    edit(): void {
        this.changeTooltipState(false);
        this.blockTransactions.emit(true);
    }

    unlink(): void {
        const transaction = this.view.state.tr;
        this.setSelectionToLink(transaction);

        const {from, to} = transaction.selection;
        transaction.removeMark(from, to, AppEditorSchema.marks.link);

        this.openChange.emit(false);
        this.view.dispatch(transaction);
    }

    ngOnDestroy(): void {
        this._destroy$.next();
        this._destroy$.complete();
    }
}
