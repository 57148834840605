<div class="toolbar w-100 mb-1 d-flex">
    <div *ngFor="let section of toolbarItemSections"
          class="toolbar-section d-flex">

        <ng-container *ngFor="let item of section;let first=first">
            <button *ngIf="!item.toolDropdownComponent;else dropdownTemplate"
                    (click)="item.command()"
                    [ngClass]="{
                        'active': item.active && !item.disabled,
                        'ml-1': !first
                    }"
                    [title]="item.lang ? (item.lang | translate) : ''"
                    [disabled]="item.disabled"
                    type="button"
                    class="btn">
                <i class="fas" [ngClass]="item.icon"></i>
            </button>

            <ng-template #dropdownTemplate>
                <div [(bsDropdown)]="item.active">
                    <button [ngClass]="{
                                'active': item.active && !item.disabled,
                                'ml-1': !first
                            }"
                            [title]="item.lang ? (item.lang | translate) : ''"
                            [disabled]="item.disabled"
                            bsDropdownToggle
                            type="button"
                            class="btn">
                        <i class="fas" [ngClass]="item.icon"></i>
                    </button>

                    <div class="dropdown-menu" (click)="item.active = false">
                        <component-renderer *ngIf="item.active"
                                            [componentType]="item.toolDropdownComponent"
                                            [inputValues]="{
                                                view: updateView$ | async,
                                                selectedText: selectedText$ | async
                                            }">
                        </component-renderer>
                    </div>
                </div>
            </ng-template>
        </ng-container>
    </div>
</div>

<selection-tooltip [view$]="updateView$">
    <link-tooltip [(open)]="tooltipState[appTooltips.LINK]"
                  (blockTransactions)="tooltipTransactionBlock[appTooltips.LINK] = $event"
                  [view]="updateView$ | async"
                  [refreshLink$]="viewUpdated$"
                  [selectedText]="selectedText$ | async">
    </link-tooltip>
</selection-tooltip>

<mention-user-list [view$]="updateView$">
</mention-user-list>


<div #editor
     class="editor-container form-control"
     [ngClass]="[
        editorDisabled ? 'disabled' : '',
        viewType === 'default' ? '' : viewType
     ]"
     [ngStyle]="{'height.px': height}">
    <!-- Editor is generated here -->
</div>
