/* eslint-disable max-len */
/**
 * This file has been auto generated. Do not edit.
 */
import {IUserLogin} from '_types/rest/Entity/IRestUserLogin';
import {IRestUserLogin} from '_types/rest/Entity/IRestUserLogin';
import {IMailAddress} from '_types/rest/Entity/IRestMailAddress';
import {IRestMailAddress} from '_types/rest/Entity/IRestMailAddress';
import {IDocument} from '_types/rest/Entity/IRestDocument';
import {IRestDocument} from '_types/rest/Entity/IRestDocument';
import {IPartner} from '_types/rest/Entity/IRestPartner';
import {IRestPartner} from '_types/rest/Entity/IRestPartner';
import {ICompany} from '_types/rest/Entity/IRestCompany';
import {IRestCompany} from '_types/rest/Entity/IRestCompany';
import {IScheduleMail} from '_types/rest/Entity/IRestScheduleMail';
import {IRestScheduleMail} from '_types/rest/Entity/IRestScheduleMail';
import {IBaseRestEntity} from '_types/rest/IBaseRestEntity';
import {IRestObject} from 'core/rest/rest-object.service';

export const MailState = {
    MAIL_STATE_WAITING_FOR_FILE: 0,
    MAIL_STATE_WAITING_FOR_QUEUE: 1,
    MAIL_STATE_SCHEDULED: 2,
    MAIL_STATE_IN_QUEUE: 3,
    MAIL_STATE_SENDING: 4,
    MAIL_STATE_SENT: 5,
    MAIL_STATE_ERROR: 6
} as const;

export const MailStateOptions = [
    {
        value: MailState.MAIL_STATE_WAITING_FOR_FILE,
        label: 'MAIL_STATE_WAITING_FOR_FILE',
    },
    {
        value: MailState.MAIL_STATE_WAITING_FOR_QUEUE,
        label: 'MAIL_STATE_WAITING_FOR_QUEUE',
    },
    {
        value: MailState.MAIL_STATE_SCHEDULED,
        label: 'MAIL_STATE_SCHEDULED',
    },
    {
        value: MailState.MAIL_STATE_IN_QUEUE,
        label: 'MAIL_STATE_IN_QUEUE',
    },
    {
        value: MailState.MAIL_STATE_SENDING,
        label: 'MAIL_STATE_SENDING',
    },
    {
        value: MailState.MAIL_STATE_SENT,
        label: 'MAIL_STATE_SENT',
    },
    {
        value: MailState.MAIL_STATE_ERROR,
        label: 'MAIL_STATE_ERROR',
    }
] as const;

export const MailType = {
    MAIL_TYPE_SYSTEM: 0,
    MAIL_TYPE_INSTANT: 1,
    MAIL_TYPE_SCHEDULED: 2,
    MAIL_TYPE_COMMUNICATION: 3
} as const;

export const MailTypeOptions = [
    {
        value: MailType.MAIL_TYPE_SYSTEM,
        label: 'MAIL_TYPE_SYSTEM',
    },
    {
        value: MailType.MAIL_TYPE_INSTANT,
        label: 'MAIL_TYPE_INSTANT',
    },
    {
        value: MailType.MAIL_TYPE_SCHEDULED,
        label: 'MAIL_TYPE_SCHEDULED',
    },
    {
        value: MailType.MAIL_TYPE_COMMUNICATION,
        label: 'MAIL_TYPE_COMMUNICATION',
    }
] as const;

export interface IMailConst {
    state: [{id: 0, value: 'MAIL_STATE_WAITING_FOR_FILE'}, {id: 1, value: 'MAIL_STATE_WAITING_FOR_QUEUE'}, {id: 2, value: 'MAIL_STATE_SCHEDULED'}, {id: 3, value: 'MAIL_STATE_IN_QUEUE'}, {id: 4, value: 'MAIL_STATE_SENDING'}, {id: 5, value: 'MAIL_STATE_SENT'}, {id: 6, value: 'MAIL_STATE_ERROR'}],
    type: [{id: 0, value: 'MAIL_TYPE_SYSTEM'}, {id: 1, value: 'MAIL_TYPE_INSTANT'}, {id: 2, value: 'MAIL_TYPE_SCHEDULED'}, {id: 3, value: 'MAIL_TYPE_COMMUNICATION'}]
}

export interface IMail extends IBaseRestEntity {
    id?: number,
    state: typeof MailState[keyof typeof MailState],
    type: typeof MailType[keyof typeof MailType],
    /**
     * @description EML filename.
     * @maxLength 32
     */
    hash?: string | null,
    /**
     * @description Mail sent date.
     * @format date-time
     */
    date: string,
    userLogin?: IUserLogin | string | null,
    senderUserLogin?: IUserLogin | string | null,
    mailAddresses?: (IMailAddress | string)[],
    documents?: (IDocument | string)[],
    partner?: IPartner | string | null,
    company?: ICompany | string | null,
    scheduleMails?: (IScheduleMail | string)[],
    /**
     * @description Record status
     *  0 = deleted
     *  1 = active.
     * @default 1
     */
    status?: number,
    /**
     * @description Creation timestamp.
     * @default CURRENT_TIMESTAMP
     * @format date-time
     */
    created?: string,
    /**
     * @description Last modification timestamp.
     * @format date-time
     */
    modified?: string | null
}

/** @deprecated */
export interface IRestMail extends IMail, IRestObject {
    userLogin?: IRestUserLogin | string | null,
    senderUserLogin?: IRestUserLogin | string | null,
    mailAddresses?: IRestMailAddress[] | string[],
    documents?: IRestDocument[] | string[],
    partner?: IRestPartner | string | null,
    company?: IRestCompany | string | null,
    scheduleMails?: IRestScheduleMail[] | string[],
}
