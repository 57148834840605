import {AbstractOperationDefinition} from 'src/modules/operations/interfaces';

export class CustomerServiceTeamTypeAddOperation extends AbstractOperationDefinition<'guardian_types'> {
    readonly endpoint = 'guardian_types';
    readonly name = 'post';
    lang = 'ADD';
    icon = 'fa-plus';
    component =
        (): Promise<unknown> => import('./customer-service-team-type-form/customer-service-team-type-form.component');
}

export class CustomerServiceTeamTypeEditOperation extends AbstractOperationDefinition<'guardian_types'> {
    readonly endpoint = 'guardian_types';
    readonly name = 'put';
    lang = 'EDIT';
    icon = 'fas fa-edit text-primary';
    component =
        (): Promise<unknown> => import('./customer-service-team-type-form/customer-service-team-type-form.component');
}

export const customerServiceTeamTypesOperations = [
    CustomerServiceTeamTypeAddOperation,
    CustomerServiceTeamTypeEditOperation,
] as const;
