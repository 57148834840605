import {finalize} from 'rxjs/operators';
import {InjectionToken, Provider} from '@angular/core';
import {MonoTypeOperatorFunction} from 'rxjs';


type ILoadingProperty = typeof FormLoading['FORMS_LOADING_PROPERTY'];

export abstract class FormLoading {
    static HOST_TOKEN = new InjectionToken('Component containing form that require loading.');
    static readonly FORMS_LOADING_PROPERTY = 'formsLoadingOperator';

    static getFormLoadingProvider(target: unknown): Provider {
        return {
            provide: FormLoading.HOST_TOKEN,
            useExisting: target
        };
    }

    static updateOperator<T extends object, Type = unknown>(
        host: T,
        loadingProperty: (keyof T | ILoadingProperty) = FormLoading.FORMS_LOADING_PROPERTY
    ): MonoTypeOperatorFunction<Type> {
        const property = loadingProperty as string;
        host[property] = true;

        return (source) => {
            return source.pipe(finalize(() => host[property] = false));
        };
    }
}
