

export interface IMentionStateValue {
    active: boolean,
    range: {from?: number, to?: number},
    text: string,
}


export const MENTION_EVENT_TYPE = {
    OPEN: 'open',
    CHANGE: 'change',
    EXIT: 'exit',
    KEY: 'key',
    BLUR: 'blur',
} as const;

export type IMentionEvent = {
    type: typeof MENTION_EVENT_TYPE[keyof typeof MENTION_EVENT_TYPE],
    state: IMentionStateValue,
    keyEvent?: KeyboardEvent
};
