import {
    Component,
    ComponentFactoryResolver, ComponentRef,
    Inject,
    NgModuleRef,
    OnInit,
    Renderer2,
    Type, ViewChild,
    ViewContainerRef
} from '@angular/core';
import {TimeTrackerService} from 'src/modules/time-tracker/time-tracker.service';
import {CoreConfigService} from 'view-modules/core/config/core-config.service';
import {forkJoin, Observable, of} from 'rxjs';
import {IPubConfigFront, IPubConfigFrontCms} from '_types/pub/config/front';
import {RestClient} from 'src/modules/rest/rest-client.service';
import {map, mergeMap} from 'rxjs/operators';
import {APP_DEV_COMPONENT, APP_ROOT_COMPONENT, ENV, IEnvironment, WINDOW} from 'app-custom-providers';
import {RouteHelper} from 'src/modules/route-helper/route-helper.service';
import {ConfigService} from 'src/services/config.service';
import {DOCUMENT} from '@angular/common';
import {UIView} from '@uirouter/angular';
import {StateService, UrlService} from '@uirouter/core';
import {TranslateService} from 'src/modules/translate/translate.service';
import {ThemeService} from 'src/services/theme.service';
import {FontService} from 'src/services/font.service';
import {MissingTranslationsService} from 'src/modules/translate/missing-translations.service';
import {ReportsService} from 'src/modules/reports/reports.service';
import {AngularHybridService} from 'src/modules/hybrid/angular-hybrid.service';
import {MercureService} from 'src/services/mercure.service';
import {AnalyticsService} from 'src/modules/analytics/analytics.service';
import {MenuService} from 'src/services/menu.service';

interface IConfigureResult {
    frontConfig: IPubConfigFront,
    ngModuleRef: NgModuleRef<unknown>
}

@Component({
    selector: 'app-core',
    templateUrl: './app-core.component.html'
})
export class AppCoreComponent implements OnInit {
    loading = true;

    private APP: IEnvironment['APP'];
    @ViewChild('viewContainer', {static: true, read: ViewContainerRef}) private viewContainerRef: ViewContainerRef;

    constructor(
        private coreConfigService: CoreConfigService,
        private missingTranslationsService: MissingTranslationsService,
        private restClient: RestClient,
        private routeHelper: RouteHelper,
        private configService: ConfigService,
        private componentFactoryResolver: ComponentFactoryResolver,
        private stateService: StateService,
        private translateService: TranslateService,
        private urlService: UrlService,
        @Inject(ENV) env: IEnvironment,
        @Inject(DOCUMENT) private document: Document,
        @Inject(WINDOW) private window: Window,
        private renderer: Renderer2,
        private themeService: ThemeService,
        private fontService: FontService,
        private reportsService: ReportsService,
        private mercureService: MercureService,
        private angularHybridService: AngularHybridService,
        private analyticsService: AnalyticsService,
        private menuService: MenuService,
        private timeTrackerService: TimeTrackerService,
    ) {
        this.APP = env.APP;
    }

    ngOnInit(): void {
        this.angularHybridService.init();
        this.urlService.deferIntercept();
        this.coreConfigService.init();
        this.missingTranslationsService.init();
        this.loadDevModule();
        this.routeHelper.checkApp()
            .pipe(
                mergeMap(() => this.configure())
            )
            .pipe(
                mergeMap((configResult) => {
                    return this.configService.init(configResult.frontConfig)
                        .pipe(map(() => configResult));
                })
            )
            .subscribe({
                next: (configResult) => this.initializeApp(configResult.ngModuleRef),
                error: (error) => this.displayError(error)
            });
    }

    private loadDevModule(): void {
        if (!this.APP.production && typeof this.window.DevModule !== 'undefined') {
            this.routeHelper.lazyLoadImport(
                Promise.resolve({default: this.window.DevModule})
            ).then(({ngModuleRef}) => {
                const componentRef = this.createRootComponent(ngModuleRef.injector.get(APP_DEV_COMPONENT));

                componentRef.instance.destroy.subscribe(() => {
                    componentRef.destroy();
                });
            });
        }
    }

    private configure(): Observable<IConfigureResult> {
        return forkJoin([
            this.restClient.endpoint<string, IPubConfigFront, IPubConfigFront>('pub/config/front').getAll(),
            this.routeHelper.lazyLoadImport(
                this.APP.current === 'private'
                    ? import('view-modules/private/private.module')
                    : import('view-modules/public/public.module')
            ),
            this.APP.current === 'private' ? this.getPrivateAppConfig() : of(undefined)
        ])
            .pipe(map(([frontConfig, lazyLoadResult, privateCms]) => {
                if (typeof privateCms !== 'undefined') {
                    frontConfig.CMS = privateCms;
                }

                return {
                    frontConfig,
                    ngModuleRef: lazyLoadResult.ngModuleRef
                };
            }));
    }

    private getPrivateAppConfig(): Observable<IPubConfigFrontCms[]> {
        return forkJoin([
            this.restClient.endpoint<string, never, IPubConfigFrontCms[]>('cms/list').getAll(),
            this.reportsService.initialize(),
            this.mercureService.initialize(),
            this.menuService.initialize(),
            this.timeTrackerService.initialize(),
        ])
            .pipe(map(([cms]) => cms));
    }

    private initializeApp(ngModuleRef: NgModuleRef<unknown>): void {
        try {
            if (this.APP.production) {
                this.analyticsService.loadTrackers();
            }
            this.applyVisualSettings();
            this.createRootComponent(ngModuleRef.injector.get(APP_ROOT_COMPONENT));
            this.urlService.rules.otherwise(() => {
                this.stateService.go('error');
            });
            this.urlService.listen();
            this.urlService.sync();
            this.hideLoader();
        } catch (error) {
            this.displayError(error);
        }
    }

    private applyVisualSettings(): void {
        const visualSettings = this.configService.get('visual');

        if (visualSettings.favicon) {
            const linkElement: HTMLLinkElement = this.renderer.createElement('link');

            this.renderer.setAttribute(linkElement, 'rel', 'shortcut icon');
            this.renderer.setAttribute(linkElement, 'type', 'image/x-icon');
            this.renderer.setAttribute(linkElement, 'href', visualSettings.favicon);
            this.renderer.appendChild(this.document.head, linkElement);
        }

        if (visualSettings.customTheme) {
            this.themeService.setTheme(visualSettings.customTheme, true);
        } else {
            this.themeService.setTheme(visualSettings.layout);
        }

        if (visualSettings.font_family) {
            this.fontService.setBodyFont(visualSettings.font_family);
        }
    }

    private createRootComponent<T>(component: Type<T>): ComponentRef<T> {
        return this.viewContainerRef.createComponent(
            this.componentFactoryResolver.resolveComponentFactory(component),
            0 // as dev bar is always created first, insert other components before it
        );
    }

    private hideLoader(): void {
        this.document.getElementById('loadingBackdrop').remove();
        this.loading = false;
    }

    private displayError(error: unknown): void {
        let errorMessage: string;

        try {
            errorMessage = this.translateService.get('EXCEPTION_HANDLER_TOAST');
        } catch (e) {
            // in some cases error may occur before translateService initialization
            errorMessage = 'EXCEPTION_HANDLER_TOAST';
        }

        this.createRootComponent(UIView);
        this.stateService.go(
            'error',
            Object.assign(
                {
                    code: ' ',
                    error: errorMessage
                },
                error
            )
        );
        this.hideLoader();
        throw error;
    }
}
