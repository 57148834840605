import {Injector} from '@angular/core';
import {TranslateService} from 'src/modules/translate/translate.service';
import {DatePipe} from 'src/pipes/date.pipe';
import {ConfigService} from 'src/services/config.service';
import DateExtended from 'date-extensions';
import {
    IChangedObjectAfterPayload,
    IChangedObjectPayload,
    IEventPayloadProcessorFn
} from 'src/modules/history/events-history/interfaces';
import {IMinimalUser} from 'src/modules/history/events-history/interfaces';
import {ITaskTemplateComponent, IUserLogin} from '_types/rest';
import {IDynamicFieldOption} from 'src/modules/dynamic-fields/interfaces';
import {OptionsUtil} from 'src/services/options-util';

export abstract class EventProcessorUtil {
    static createBeforeAfterLabel(before: string, after: string, injector: Injector): string {
        const translate = injector.get(TranslateService);
        return `${EventProcessorUtil.boldString(before)} 
                ${translate.get('COMMON_TASK_STORY_AFTER')} 
                ${EventProcessorUtil.boldString(after)}`;
    }

    static boldString(value: string): string {
        return `<span class="text-bold">${value}</span>`;
    }

    static parseDate(
        value: DateExtended | Date | number | string | null,
        injector: Injector,
        format?: string,
        hours = true
    ): string {
        if (!value) {
            return '-';
        }

        const datePipe = new DatePipe(
            injector.get(TranslateService),
            injector.get(ConfigService)
        );

        return datePipe.transform(value, format, hours);
    }

    static userBeforeAfter({before, after}: IChangedObjectPayload<IUserLogin>, injector: Injector): string {
        const unassignedTranslation = injector.get(TranslateService).get('USER_UNASSIGNED');
        return EventProcessorUtil.createBeforeAfterLabel(
            `${before?.firstName ?? unassignedTranslation} ${before?.lastName ?? ''}`,
            `${after?.firstName ?? unassignedTranslation} ${after?.lastName ?? ''}`,
            injector
        );
    }

    static stringBeforeAfter({before, after}: IChangedObjectPayload<string>, injector: Injector): string {
        return EventProcessorUtil.createBeforeAfterLabel(
            before ?? '-',
            after ?? '-',
            injector
        );
    }

    static dateAfter({after}: IChangedObjectAfterPayload<string>, injector: Injector): string {
        return EventProcessorUtil.boldString(EventProcessorUtil.parseDate(after, injector, undefined,  false));
    }

    static dateBeforeAfter({before, after}: IChangedObjectPayload<string>, injector: Injector): string {
        return EventProcessorUtil.createBeforeAfterLabel(
            EventProcessorUtil.parseDate(before, injector),
            EventProcessorUtil.parseDate(after, injector),
            injector
        );
    }

    static minimalUsersArrayAfter(minimalUsers: IMinimalUser[], injector: Injector): string {
        const unassignedTranslation = injector.get(TranslateService).get('USER_UNASSIGNED');
        let users = '';
        minimalUsers.forEach(({firstName, lastName}, index) => {
            users += `${firstName ?? unassignedTranslation} ${lastName ?? ''}
                      ${index !== (minimalUsers.length - 1) ? ', ' : ''}`;
        });
        return users;
    }

    static beforeAfterProperty(property = 'name', isTranslation = false): IEventPayloadProcessorFn {
        return (
            {before, after}: IChangedObjectPayload<ITaskTemplateComponent>,
            injector
        ) => {
            const translateService = injector.get(TranslateService),
                translate = (value: string) => {
                    if (!isTranslation || !value) {
                        return value;
                    }

                    return translateService.get(
                        value
                    );
                };

            return EventProcessorUtil.createBeforeAfterLabel(
                before?.[property] ? translate(before[property]) : '-',
                after?.[property] ? translate(after[property]) : '-',
                injector
            );
        };
    }

    static booleanBeforeAfter({before, after}: IChangedObjectPayload<string>, injector: Injector): string {
        return EventProcessorUtil.createBeforeAfterLabel(
            (!!before).toString(),
            (!!after).toString(),
            injector
        );
    }

    static beforeAfterOptions(options: IDynamicFieldOption[]): IEventPayloadProcessorFn {
        return (
            {before, after}: IChangedObjectPayload<ITaskTemplateComponent>,
            injector
        ) => {
            const translateService = injector.get(TranslateService);

            return EventProcessorUtil.createBeforeAfterLabel(
                translateService.get(
                    OptionsUtil.getFromOption(Number(before), options) ?? '-'
                ),
                translateService.get(
                    OptionsUtil.getFromOption(Number(after), options) ?? '-'
                ),
                injector
            );
        };
    }

    static singleUserFromAfter({after}: IChangedObjectPayload<IUserLogin>): string {
        return EventProcessorUtil.boldString(
            `${after?.firstName} ${after?.lastName ?? ''}`
        );
    }
}
