import {Injector} from '@angular/core';
import {ICron} from '_types/rest';
import {StateService} from '@uirouter/core';
import {IRestObject} from 'src/modules/rest/objects';
import {AbstractOperationDefinition} from 'src/modules/operations/interfaces';
import {AbstractDeleteOperation} from 'view-modules/operations/abstract/abstract-delete.operation';
import {UserService} from 'src/modules/rest/user/user.service';


export class OpenCronLogs extends AbstractOperationDefinition<'crons'> {
    readonly endpoint = 'crons'
    readonly name = 'open_logs';
    lang = 'LOGS';
    icon = 'fa-eye';
    invoke = (
        context: ICron,
        injector: Injector
    ): void => {
        const state = injector.get(StateService);
        state.go('admin.cron.view', {cronId: context.id});
    };

    access(context: IRestObject<'crons'>, injector: Injector): boolean {
        return injector.get(UserService).hasPrivileges('admin.cron.view');
    }
}

export class AddCron extends AbstractOperationDefinition<'crons'> {
    readonly endpoint = 'crons'
    readonly name = 'post';
    lang = 'CRON_ADD';
    icon = 'fa-plus';
    component = (): Promise<unknown> => import('view-modules/operations/crons/cron-form/cron-form.component');
}

export class EditCron extends AbstractOperationDefinition<'crons'> {
    readonly endpoint = 'crons'
    readonly name = 'put';
    lang = 'EDIT'
    icon = 'fa-pencil-alt';
    component = (): Promise<unknown> => import('view-modules/operations/crons/cron-form/cron-form.component');
}

export class DeleteCron extends AbstractDeleteOperation<'crons'> {
    readonly endpoint = 'crons';
    confirmMessage = 'DELETE_CRON_CONFIRM';
    lang = 'DELETE'
}


export const cronsOperations = [
    AddCron,
    OpenCronLogs,
    EditCron,
    DeleteCron
] as const;
