import {Directive, ElementRef, HostBinding, Input, OnChanges, SimpleChanges} from '@angular/core';

@Directive({
    selector: '[inputLoading]'
})
export class InputLoadingDirective implements OnChanges {
    @HostBinding('class.input-loading') loadingClass: boolean;
    @Input() disabled: boolean;
    @Input() inputLoading;
    initializing: boolean;


    constructor(
        private el: ElementRef
    ) {
        this.initializing = true;
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.inputLoading) {
            this.loadingClass = this.inputLoading;
            this.initializing = this.inputLoading;
            if (this.inputLoading || this.disabled) {
                this.el.nativeElement.setAttribute('disabled', true);
            } else {
                this.el.nativeElement.removeAttribute('disabled');
            }
        }
    }
}
