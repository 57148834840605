import {ModuleWithProviders} from '@angular/core';
import {
    Ng2StateDeclaration,
    Ng2ViewDeclaration,
    RootModule,
    StatesModule, UIRouterModule
} from '@uirouter/angular';
import {Utils} from 'src/services/utils';
import {ParamDeclaration, StateService, Transition} from '@uirouter/core';
import {RouteHelper} from './route-helper.service';
import {firstValueFrom, Observable} from 'rxjs';
import {Comparison} from 'src/services/comparison';
import {ResolvableLiteral} from '@uirouter/core/lib/resolve/interface';
import {AppStateDeclaration} from 'src/modules/route-helper/interfaces';
import {UserService} from 'src/modules/rest/user/user.service';
import {AngularHybridService, HybridAppStateDeclaration} from 'src/modules/hybrid/angular-hybrid.service';
import {Route} from '_types/route';

export type AppGlobalParamDeclaration = ParamDeclaration & {
    query: boolean
};

type AppGlobalParams = {
    [param: string]: AppGlobalParamDeclaration
}

export type AppStatesModule<T extends RootModule | StatesModule> = Omit<T, 'states'> & {
    states: HybridAppStateDeclaration[],
    globalParams?: AppGlobalParams
}

interface IComponentRouteConfig extends AppStateDeclaration {
    parsed?: boolean
}

export abstract class RouteModule {

    static get globalParams(): AppGlobalParams {
        return this._globalParams;
    }

    private static _globalParams: AppGlobalParams = {};

    static forRoot(module: AppStatesModule<RootModule>): ModuleWithProviders<UIRouterModule> {
        return UIRouterModule.forRoot(this.alterModule(module));
    }

    static forChild(module: AppStatesModule<StatesModule>): ModuleWithProviders<UIRouterModule> {
        return UIRouterModule.forChild(this.alterModule(module));
    }

    static getComponentRouteConfig(component: abstract new(...args: unknown[]) => unknown): AppStateDeclaration {
        const routeConfig: IComponentRouteConfig = Reflect.getMetadata('$routeConfig', component) || {};

        if (routeConfig.parsed) {
            return routeConfig;
        }

        routeConfig.parsed = true;

        const routeResolveMetadata: AppStateDeclaration['resolve']
            = Reflect.getMetadata('$routeResolve', component);
        if (
            typeof routeResolveMetadata === 'object'
            && routeResolveMetadata !== null
        ) {
            if (typeof routeConfig.resolve !== 'object' || routeConfig.resolve === null) {
                routeConfig.resolve = {};
            }

            Object.entries(routeResolveMetadata)
                .forEach(([token, resolveFn]) => {
                    if (typeof routeConfig.resolve[token] !== 'undefined') {
                        throw new Error(
                            `Resolve token '${token}' is defined in both @RouteConfig and @RouteResolve`
                            + ` decorators of '${component.name}' class!`
                        );
                    }

                    routeConfig.resolve[token] = resolveFn;
                });
        }
        return routeConfig;
    }

    /**
     * Convert an array of AppStateDeclaration to Ng2StateDeclaration
     */
    static createRouterStates(states: HybridAppStateDeclaration[]): Ng2StateDeclaration[] {
        const alteredStates: Ng2StateDeclaration[] = [];

        this.forEachState(states, (state, parent) => {
            if (typeof parent !== 'undefined') {
                if (typeof parent.name === 'undefined') {
                    throw new Error('Tried to add child states on an unnamed state');
                }

                state.name = `${parent.name}.${state.name}`;
            }

            const overriddenState = AngularHybridService.overrideAjsRouteState(state);

            this.extendState(overriddenState);

            alteredStates.push(
                this.getRouterState(overriddenState)
            );
        });

        return alteredStates;
    }

    /**
     * Get given state config and override some of its properties.
     * Returned definition may be used to display the same state in other route.
     */
    static mimicState(
        imitatedState: string,
        routesWithImitatedState: HybridAppStateDeclaration[],
        override?: Partial<HybridAppStateDeclaration>
    ): HybridAppStateDeclaration | null {
        const rdo = Utils.clone(this.getStateItem(imitatedState, routesWithImitatedState));

        if (rdo === false) {
            return null;
        }

        if (typeof override !== 'undefined') {
            Object.entries(override).forEach(([key, value]) => {
                if (typeof value === 'object' && value !== null) {
                    rdo[key] = Object.assign(
                        Array.isArray(value) ? [] : {},
                        rdo[key],
                        value
                    );
                } else {
                    rdo[key] = value;
                }
            });
        }

        return rdo;
    }

    private static getStateItem(
        stateName: string,
        routes: HybridAppStateDeclaration[],
        parentStateName?: string
    ): HybridAppStateDeclaration | false {
        if (typeof parentStateName === 'undefined') {
            parentStateName = '';
        }
        for (let i = 0; i < routes.length; i++) {
            const state = routes[i];
            if (stateName === `${parentStateName}${state.name}`) {
                return state;
            }

            if (Array.isArray(state.childs) && state.childs.length) {
                const subSearch = this.getStateItem(
                    stateName, state.childs, `${parentStateName}${state.name}.`
                );
                if (subSearch !== false) {
                    return subSearch;
                }
            }
        }
        return false;
    }

    /**
     * Create UIRouter states from RouteHelper states tree
     */
    private static alterModule<T extends AppStatesModule<RootModule | StatesModule>>(
        module: T
    ): RootModule | StatesModule {
        if (typeof module.globalParams !== 'undefined') {
            Object.assign(this.globalParams, module.globalParams);
        }

        return Object.assign(
            {}, module, {states: this.createRouterStates(Utils.clone(module.states))}
        );
    }

    private static forEachState(
        states: HybridAppStateDeclaration[],
        callback: (state: HybridAppStateDeclaration, parent?: HybridAppStateDeclaration) => void,
        parentState?: HybridAppStateDeclaration
    ): void {
        states.forEach((state) => {
            callback(state, parentState);

            if (Array.isArray(state.childs) && state.childs.length) {
                this.forEachState(state.childs, callback, state);
            }
        });
    }

    /**
     * Add default behaviour for each state:
     * - definitions from @RouteConfig & @RouteResolve
     * - default resolve tokens (eg. auth)
     */
    private static extendState(state: AppStateDeclaration): void {
        if (typeof state.resolve === 'undefined') {
            state.resolve = {};
        }
        // extend with RouteConfig's from state.views
        const components = [];
        if (typeof state.views !== 'undefined') {
            Object.values(state.views).forEach((view: Ng2ViewDeclaration) => {
                if (typeof view.component !== 'undefined') {
                    components.push(view.component);
                }
            });
        }
        if (typeof state.component !== 'undefined') {
            components.push(state.component);
        }

        components
            .filter(Comparison.unique)
            .forEach((component) => {
                const routeConfig = this.getComponentRouteConfig(component);
                if (typeof routeConfig !== 'undefined') {
                    Object.entries(routeConfig)
                        .forEach(([key, value]) => {
                            if (typeof value === 'object') {
                                state[key] = Object.assign(
                                    Array.isArray(value) ? [] : {},
                                    value,
                                    state[key]
                                );
                            } else {
                                state[key] = value;
                            }
                        });
                }
            });
        // auth
        if (typeof state.resolve.$auth === 'undefined') {
            state.resolve.$auth = (transition: Transition): Observable<void> => {
                if (window.ENV.APP.current === 'public') {
                    return;
                }
                return new Observable((subscriber) => {
                    const injector = transition.injector();
                    injector.get<RouteHelper>(RouteHelper).checkApp().subscribe(() => {
                        if (injector.get<UserService>(UserService).hasPrivileges(state.name as Route)) {
                            subscriber.next();
                            subscriber.complete();
                        } else {
                            injector.get<StateService>(StateService).go('default', {}, {reload: true});
                        }
                    });
                });
            };
        }
        // language
        if (typeof state.resolve.$language === 'undefined' && !state.name.startsWith('login')) {
            state.resolve.$language = RouteHelper.restResolveTranslations(state.name.replace(/\..*$/, ''));
        }
        // add observable support for resolves
        if (typeof state.resolvePolicy === 'undefined') {
            state.resolvePolicy = {
                async: (data) => {
                    if (data instanceof Observable) {
                        return firstValueFrom(data);
                    }

                    return data;
                }
            };
        }
        // relative redirectTo support
        if (
            typeof state.redirectTo === 'string'
            && (state.redirectTo.indexOf('.') === 0 || state.redirectTo.indexOf('^') === 0) // relative state
        ) {
            const _relativeRedirect = state.redirectTo;
            state.redirectTo = (transition) => {
                return transition.router.stateRegistry.matcher.resolvePath(
                    _relativeRedirect, transition.targetState().name()
                );
            };
        }
        // global params
        const globalParams = Object.entries(this.globalParams);

        if (globalParams.length && !state.name.includes('.')) {
            if (typeof state.params !== 'object') {
                state.params = {};
            }

            if (typeof state.url === 'undefined') {
                state.url = '';
            }

            globalParams.forEach(([param, declaration]) => {
                if (typeof state.params[param] === 'undefined') {
                    state.params[param] = declaration;

                    if (declaration.query) {
                        const prefix = state.url.includes('?') ? '&' : '?';
                        state.url += prefix + param;
                    }
                }
            });
        }
    }

    /**
     * Map App resolve tokens to UIRouter resolve tokens
     */
    private static getRouterState(state: AppStateDeclaration): Ng2StateDeclaration {
        return Object.assign(
            {},
            state,
            {
                resolve: Object.entries(state.resolve).map<ResolvableLiteral>(([token, resolve]) => {
                    return {
                        token,
                        deps: 'deps' in resolve ? resolve.deps : [Transition],
                        resolveFn: 'resolveFn' in resolve ? resolve.resolveFn : resolve
                    };
                })
            }
        );
    }
}
