/* eslint-disable max-len */
/**
 * This file has been auto generated. Do not edit.
 */
import {ICron} from '_types/rest/Entity/IRestCron';
import {IBaseRestEntity} from '_types/rest/IBaseRestEntity';

export const CronLogState = {
    CRON_LOG_STATE_SUCCESS: 1,
    CRON_LOG_STATE_FAILURE: 2,
    CRON_LOG_STATE_RUNNING: 3
} as const;

export const CronLogStateOptions = [
    {
        value: CronLogState.CRON_LOG_STATE_SUCCESS,
        label: 'CRON_LOG_STATE_SUCCESS',
    },
    {
        value: CronLogState.CRON_LOG_STATE_FAILURE,
        label: 'CRON_LOG_STATE_FAILURE',
    },
    {
        value: CronLogState.CRON_LOG_STATE_RUNNING,
        label: 'CRON_LOG_STATE_RUNNING',
    }
] as const;

export interface ICronLogConst {
    state: [{id: 1, value: 'CRON_LOG_STATE_SUCCESS'}, {id: 2, value: 'CRON_LOG_STATE_FAILURE'}, {id: 3, value: 'CRON_LOG_STATE_RUNNING'}]
}

export interface ICronLog extends IBaseRestEntity {
    id?: number,
    /**
     * @default 2
     */
    state?: typeof CronLogState[keyof typeof CronLogState],
    message?: string | null,
    returnCode?: number | null,
    cron?: ICron | string | null,
    /**
     * @description Record status
     *  0 = deleted
     *  1 = active.
     * @default 1
     */
    status?: number,
    /**
     * @description Creation timestamp.
     * @default CURRENT_TIMESTAMP
     * @format date-time
     */
    created?: string,
    /**
     * @description Last modification timestamp.
     * @format date-time
     */
    modified?: string | null
}
