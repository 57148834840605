import {Inject, Injectable} from '@angular/core';
import {DOCUMENT} from '@angular/common';
import {WINDOW} from 'app-custom-providers';
import {TranslateService} from 'src/modules/translate/translate.service';
import {ToastsService} from 'src/modules/global-components/toasts/toasts.service';

@Injectable({
    providedIn: 'root'
})
export class CopyService {
    constructor(
        @Inject(DOCUMENT) private document: Document,
        @Inject(WINDOW) private window: Window,
        private translateService: TranslateService,
        private toastsService: ToastsService
    ) {
    }

    copyToClipboard(data: string): void {
        const copyCallback = (e: ClipboardEvent): void => {
            const clipboard = e.clipboardData || this.window['clipboardData'];
            clipboard.setData('text', data);
            e.preventDefault();
        };
        document.addEventListener('copy', copyCallback);
        document.execCommand('copy');
        document.removeEventListener('copy', copyCallback);
        this.toastsService.pop(this.translateService.get('COPIED_ALERT'), null, 'info');
    }
}
