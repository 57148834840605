<div class="d-flex align-items-center">
    <div *ngIf="tableConfig.quickFilters?.length > 1" [(bsDropdown)]="quickFiltersDropdown">
        <i class="fa fa-filter" bsDropdownToggle></i>
        <div class="dropdown-menu dropdown-menu-right">
            <h6 class="dropdown-header">{{'COMMON_QUICK_FILTERS' | translate}}</h6>
            <a class="dropdown-item" href="javascript:" *ngFor="let filter of tableConfig.quickFilters"
               (click)="quickFiltersDropdown=false; setQuickFilter(filter)">
                {{filter.name | translate}}
            </a>
        </div>
    </div>
    <div class="mr-2" *ngIf="tableConfig.quickFilters?.length === 1">
        <dynamic-field [(ngModel)]="quickFilterModel"
                       (ngModelChange)="changeSwitchFilter(tableConfig.quickFilters[0])"
                       [fieldConfig]="{type: 'checkbox', viewType: 'switch', name: tableConfig.quickFilters[0].name}">
        </dynamic-field>
    </div>
    <div *ngIf="tableConfig.settingTemplates"
         [ngClass]="{'ml-3': tableConfig.quickFilters?.length}"
         [(bsDropdown)]="configTemplatesDropdown">
        <i class="fa fa-table"
           bsDropdownToggle
           [title]="!configTemplatesDropdown ? ('COMMON_TABLE_TEMPLATE' | translate) : ''">
        </i>

        <div class="dropdown-menu dropdown-menu-right">
            <h6 class="dropdown-header">
                {{'COMMON_TABLE_TEMPLATE' | translate}}
            </h6>

            <ng-container *ngFor="let template of tableConfig.settingTemplates">
                <a class="dropdown-item d-flex justify-content-between align-items-center"
                   href="javascript:"
                   [title]="template.settingName?.length > MAX_TEMPLATE_SETTING_NAME ? template.settingName : ''"
                   [ngClass]="{
                        'active': tableConfig.activeSettingName === template.settingName
                            && tableConfig.activeSettingName
                   }"
                   (click)="selectSettings(template)">
                    {{(template.settingName | shortText : MAX_TEMPLATE_SETTING_NAME) || 'COMMON_DEFAULT_TABLE_SETTINGS' | translate}}

                    <div *ngIf="template.settingName"
                         class="ml-2">
                        <i class="fa fa-save"
                           [ngClass]="{
                                'text-primary': tableConfig.activeSettingName !== template.settingName
                           }"
                           [title]="'UPDATE_CURRENT_TABLE_SETTINGS' | translate"
                           (click)="saveCurrentSettings(template.settingName);$event.stopPropagation()">
                        </i>

                        <i class="fa fa-trash ml-2"
                           *ngIf="template.settingName"
                           [ngClass]="{
                                'text-danger': tableConfig.activeSettingName !== template.settingName
                           }"
                           [title]="'REMOVE_TABLE_SETTINGS' | translate"
                           (click)="removeSetting(template);$event.stopPropagation()">
                        </i>
                    </div>
                </a>

                <div aria-hidden="true" class="dropdown-divider"
                     *ngIf="!template.settingName && tableConfig.settingTemplates.length > 1">
                </div>
            </ng-container>


            <div aria-hidden="true" class="dropdown-divider"></div>

            <a class="dropdown-item"
               (click)="openTemplateSettingsModal()"
               href="javascript:">
                {{'SAVE_AS_TEMPLATE' | translate}}
            </a>
        </div>
    </div>
    <div [ngClass]="{'ml-3': tableConfig.settingTemplates}">
        <i class="fa fa-cog" (click)="openSettingsModal()"
           [title]="'TABLE_SETTINGS' | translate">
        </i>
    </div>
    <div *ngIf="isLegendAvailable"
         class="ml-3">
        <legend-toggle>
        </legend-toggle>
    </div>
</div>


<div class="modal fade" [(bsModal)]="settingsModal">
    <div class="modal-dialog" *ngIf="settingsModal">
        <div class="modal-content">
            <form (ngSubmit)="saveColumns()" [formGroup]="columnSettingsForm">
                <div class="modal-header">
                    <h5>{{'TABLE_SETTINGS' | translate}}</h5>
                    <button [attr.aria-label]="'CLOSE'| translate" class="close" dismiss="modal" type="button">
                        <span aria-hidden="true">×</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div [sortable]="columnSettings"
                         (dragOver)="onDragOver($event)"
                         (dragStart)="onDragStart($event)"
                         (dragEnd)="onDragEnd($event)">
                        <dynamic-field formControlName="columnsControl"
                                       [fieldConfig]="{type: 'select', viewType: 'checkBar', noAutoSelect: true}">
                            <ng-container *esOptions="let column of columnSettings">
                                <div [sortableItem]="column" class="d-flex">
                                    <div [dynamicTableCell]="column" [textOnly]="true"></div>
                                    <sortable-drag-handle class="ml-auto"></sortable-drag-handle>
                                </div>
                            </ng-container>
                        </dynamic-field>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-default" dismiss>
                        {{'CANCEL' | translate}}
                    </button>
                    <button type="submit" class="btn btn-primary">
                        {{'SAVE' | translate}}
                    </button>
                </div>
            </form>
        </div>
    </div>
</div>


<div class="modal fade" [(bsModal)]="templateSettingsModal">
    <div class="modal-dialog" *ngIf="templateSettingsModal">
        <div class="modal-content">
            <form (ngSubmit)="saveCurrentSettings()"
                  [formGroup]="templateSettingsForm">
                <div class="modal-header">
                    <h5>{{'SAVE_TABLE_SETTINGS' | translate}}</h5>
                    <button [attr.aria-label]="'CLOSE'| translate" class="close" dismiss="modal" type="button">
                        <span aria-hidden="true">×</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="mb-3">
                        <dynamic-field formControlName="configNameControl"
                                       class="form-group"
                                       [fieldConfig]="{
                                            name: 'TEMPLATE_NAME',
                                            type: 'string',
                                            validators: {
                                                required: true
                                            }
                                        }">
                        </dynamic-field>

                        <span *ngIf="alreadyExistedConfig">
                            {{'TABLE_CONFIG_ALREADY_EXISTS' | translate}}
                        </span>
                    </div>

                    <div class="d-flex align-items-center text-info mt-3">
                        <i class="fa fa-info-circle mr-2">
                        </i>
                        <span>{{'SELECTED_FILTERS_WILL_BE_SAVED' | translate}}</span>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-default" dismiss>
                        {{'CANCEL' | translate}}
                    </button>
                    <button type="submit" class="btn btn-primary">
                        {{'SAVE' | translate}}
                    </button>
                </div>
            </form>
        </div>
    </div>
</div>
