import {AbstractDeleteOperation} from 'view-modules/operations/abstract/abstract-delete.operation';
import {IConfirmModalOptions} from 'src/modules/global-components/confirm-modal/confirm-modal.service';
import {AbstractOperationDefinition} from 'src/modules/operations/interfaces';
import {Injector} from '@angular/core';
import {StateService} from '@uirouter/core';
import {IPartner} from '_types/rest';
import {RestClient} from 'src/modules/rest/rest-client.service';
import {IRestCollection, IRestObject} from 'src/modules/rest/objects';


export class DeleteSearchConsoleTemplateOperation
    extends AbstractDeleteOperation<'search_console_templates'> {
    readonly endpoint = 'search_console_templates'

    confirmMessage = 'ACTION_CANNOT_BE_UNDONE';
    confirmOptions: IConfirmModalOptions = {
        langYes: 'DELETE_TEMPLATE_CONFIRM_LANG_YES',
        primaryBtn: 'danger',
        text: 'ACTION_CANNOT_BE_UNDONE'
    }
}

interface ViewSearchConsoleTemplateContextCollection {
    template: IRestCollection<'search_console_templates'>;
    partner: IPartner;
}

export class AddSearchConsoleTemplate
    extends AbstractOperationDefinition<string, ViewSearchConsoleTemplateContextCollection> {

    readonly endpoint = 'search_console_templates';
    readonly name = 'post';
    lang = 'SEARCH_CONSOLE_TEMPLATE_ADD';
    icon = 'fa-plus';

    component
        = (): Promise<unknown> => import('./search-console-templates-form/search-console-templates-form.component');

    access(
        context: ViewSearchConsoleTemplateContextCollection,
    ): boolean {
        const restObject = context.template;
        if (RestClient.isRestObject(restObject) || RestClient.isRestCollection(restObject)) {
            return restObject.access(this.name);
        }

        return false;
    }
}

interface ViewSearchConsoleTemplateContext {
    template: IRestObject<'search_console_templates'>;
    partner: IPartner;
}

export class EditSearchConsoleTemplate
    extends AbstractOperationDefinition<string, ViewSearchConsoleTemplateContext> {
    readonly endpoint = 'search_console_templates';
    readonly name = 'put';
    lang = 'ROUTE_PARTNERS_CONFIG_SEARCH_CONSOLE_TEMPLATES_EDIT';
    icon = 'fa-pencil-alt';

    component
        = (): Promise<unknown> => import('./search-console-templates-form/search-console-templates-form.component');

    access(
        context: ViewSearchConsoleTemplateContext,
    ): boolean {
        const restObject = context.template;
        if (RestClient.isRestObject(restObject) || RestClient.isRestCollection(restObject)) {
            return restObject.access(this.name);
        }

        return false;
    }
}

export class ViewSearchConsoleTemplateOperation
    extends AbstractOperationDefinition<string, ViewSearchConsoleTemplateContext> {
    readonly endpoint = 'search_console_templates';
    readonly name = 'view_search_console_template';
    lang = 'VIEW';
    icon = 'fa-eye';

    invoke(
        context: ViewSearchConsoleTemplateContext,
        injector: Injector
    ): void {
        const stateService = injector.get(StateService);
        stateService.go(
            '.view',
            {
                partnerId: context.partner.id,
                searchConsoleTemplateId: context.template.id
            });
    }

    access(): boolean {
        return true;
    }
}


export const searchConsoleTemplateOperations = [
    DeleteSearchConsoleTemplateOperation,
    AddSearchConsoleTemplate,
    EditSearchConsoleTemplate,
    ViewSearchConsoleTemplateOperation
] as const;
