import {Directive, DoCheck, HostBinding, HostListener, Inject, Input, Optional} from '@angular/core';
import {AppFormDirective} from 'src/modules/app-forms/app-form.directive';
import {FormLoading} from 'src/modules/app-forms/form-loading.operator';


@Directive({
    selector: 'input[type=submit],button[type=submit],button:not([type])'
})
export class SubmitDirective implements DoCheck {
    @HostBinding('disabled') isDisabled: boolean;
    @Input() disabled: boolean;

    @HostListener('click', ['$event'])
    private submitForm = ($event) => {
        if (this.formDirective) {
            this.preventSubmit($event);
        }
    };


    constructor(
        @Optional() private formDirective: AppFormDirective,
        @Optional() @Inject(FormLoading.HOST_TOKEN) private formLoadingHost: object
    ) {
    }

    ngDoCheck(): void {
        this.isDisabled = this.disabled
            || this.formDirective?.shouldDisableFormSubmission()
            || this.isLoading();
    }

    private isLoading(): boolean {
        if (
            !this.formLoadingHost
            || !(FormLoading.FORMS_LOADING_PROPERTY in this.formLoadingHost)
        ) {
            return false;
        }

        return this.formLoadingHost[FormLoading.FORMS_LOADING_PROPERTY];
    }

    preventSubmit($event: Event): void {
        if (this.formDirective.shouldDisableFormSubmission()) {
            $event.stopImmediatePropagation();
            $event.preventDefault();
        }
    }
}
