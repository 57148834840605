import {AbstractOperationDefinition} from 'src/modules/operations/interfaces';
import {Injector} from '@angular/core';
import {StateService} from '@uirouter/core';
import {IRestObject} from 'src/modules/rest/objects';
import {With} from '_types/rest';
import {UIRouterGlobals} from '@uirouter/angular';
import {UserPrivileges, UserService} from 'src/modules/rest/user/user.service';

export class ViewReportOperation extends AbstractOperationDefinition<'search_console_reports'> {
    readonly endpoint = 'search_console_reports'
    readonly name = 'view-report';
    lang = 'VIEW_REPORT';
    icon = 'fa-eye';

    invoke(
        report: With<IRestObject<'search_console_reports'>, 'searchConsoleService'>,
        injector: Injector
    ): void {
        const state = injector.get(StateService);
        state.go(
            '.gscReport',
            {
                gscReportId: report.id,
                searchConsoleServiceId: report.searchConsoleService.id
            });
    }

    access(context: unknown, injector: Injector): boolean {
        return injector.get(UserService).hasPrivileges(
            injector.get(UIRouterGlobals).current.name + '.gscReport' as UserPrivileges
        );
    }
}

export class FetchReportOperation extends AbstractOperationDefinition<'search_console_reports'> {
    readonly endpoint = 'search_console_reports'
    readonly name = 'fetch';
    lang = 'FETCH';
    icon = 'fa-sync';

    component = (): Promise<unknown> => import('./gsc-reports-fetch/gsc-reports-fetch.component');
}


export const gscReportsOperations = [
    ViewReportOperation,
    FetchReportOperation
] as const;
