import {AbstractOperationDefinition, IOperationResult} from 'src/modules/operations/interfaces';
import {AbstractDeleteOperation} from 'view-modules/operations/abstract/abstract-delete.operation';
import {
    ConfirmModalService,
    IConfirmModalOptions
} from 'src/modules/global-components/confirm-modal/confirm-modal.service';
import {
    ITask,
    ITaskAcceptanceCriterion,
    ITaskAcceptanceCriterionDto,
    TaskAcceptanceCriterionState
} from '_types/rest';
import {AppTask} from 'src/modules/tasks/task-view/task-view.component';
import {Injector} from '@angular/core';
import {Observable} from 'rxjs';
import {map, tap} from 'rxjs/operators';
import {RestClient} from 'src/modules/rest/rest-client.service';
import {IRestObject} from 'src/modules/rest/objects';
import {IEmptyDto} from '_types/rest/Dto/IRestTaskAcceptanceCriterionEmptyDto';
import {TaskUtilsService} from 'src/modules/tasks/task-utils.service';

export interface IAcceptanceCriterionAddContext {
    task: ITask | AppTask;
}

interface IAcceptanceCriterionEditContext {
    task: AppTask;
    acceptanceCriterion: ITaskAcceptanceCriterionDto;
}

export class AcceptanceCriterionAddOperation
    extends AbstractOperationDefinition<string, IAcceptanceCriterionAddContext> {
    readonly endpoint = 'task_acceptance_criteria';
    readonly name = 'post';
    lang = 'ADD';
    icon = 'fa-plus';

    component
        = (): Promise<unknown> => import('./task-acceptance-criteria-form/task-acceptance-criteria-form.component');

    access(context: IAcceptanceCriterionAddContext, injector: Injector): boolean {
        const taskUtils = injector.get(TaskUtilsService);
        return !context.task.id
            || (
                taskUtils.isCurrentUserAuthor(context.task as AppTask)
                && !context.task.editBlocked
            );
    }
}

export class AcceptanceCriterionEditOperation
    extends AbstractOperationDefinition<string, IAcceptanceCriterionEditContext> {
    readonly endpoint = 'task_acceptance_criteria';
    readonly name = 'put';
    lang = 'EDIT';
    icon = 'fa-pencil-alt';

    component
        = (): Promise<unknown> => import('./task-acceptance-criteria-form/task-acceptance-criteria-form.component');

    access(context: IAcceptanceCriterionEditContext, injector: Injector): boolean {
        const taskUtils = injector.get(TaskUtilsService);
        return !context.task.id
            || (
                taskUtils.isCurrentUserAuthor(context.task as AppTask)
                && !context.task.editBlocked
            );
    }
}

export class AcceptanceCriterionDeleteOperation extends AbstractDeleteOperation<'task_acceptance_criteria'> {
    readonly endpoint = 'task_acceptance_criteria';
    confirmMessage = 'TASKS_ACCEPTANCE_DELETE_CONFIRM';
    confirmOptions: IConfirmModalOptions = {
        primaryBtn: 'danger',
        text: 'TASKS_ACCEPTANCE_DELETE_TEXT'
    };

    invoke(
        context: IRestObject<'task_acceptance_criteria', ITaskAcceptanceCriterionDto>,
        injector: Injector
    ): Observable<IOperationResult> | IOperationResult {
        if (context.task) {
            return injector.get(ConfirmModalService).confirmOperation(
                this.confirmMessage,
                () => {
                    return context.delete()
                        .pipe(
                            tap(() => {
                                injector.get(RestClient).savedToast();
                            })
                        );
                },
                this.confirmOptions
            );
        }

        return {success: true, customValue: context};
    }

    access(context: IRestObject<'task_acceptance_criteria'>, injector: Injector): boolean {
        return !context.id || super.access(context, injector);
    }
}

export interface IAcceptanceCheckContext {
    checkedCriteria: ITaskAcceptanceCriterionDto[];
    task: ITask | AppTask;
}

export class AcceptanceCriterionCheckOperation
    extends AbstractOperationDefinition<string, IAcceptanceCheckContext> {
    readonly endpoint = 'task_acceptance_criteria';
    readonly name = 'check';
    lang = 'EDIT';
    icon = 'fa-pencil-alt';
    modalHeaderDisabled = true;

    component
        = (): Promise<unknown> => import('./task-acceptance-criteria-check/task-acceptance-criteria-check.component');

    access(): boolean {
        return true;
    }
}

const CompletedEndpoint = {
    UNCOMPLETED: 'task_acceptance_criteria/uncompleted',
    COMPLETED: 'task_acceptance_criteria/completed'
} as const;

abstract class AbstractCompletedOperation<TEndpoint extends string>
    extends AbstractOperationDefinition<'task_acceptance_criteria', ITaskAcceptanceCriterion> {
    readonly endpoint= 'task_acceptance_criteria';
    abstract endpointName: TEndpoint;
    abstract state: typeof TaskAcceptanceCriterionState[keyof typeof TaskAcceptanceCriterionState];

    invoke = (
        { id }:  ITaskAcceptanceCriterion,
        injector: Injector
    ): Observable<IOperationResult> => {
        return injector.get(RestClient).endpoint<TEndpoint, IEmptyDto>(this.endpointName).update(id, {})
            .pipe(
                map(() => ({ success: true }))
            );
    };

    access = (context: IRestObject<'task_acceptance_criteria', ITaskAcceptanceCriterion>): boolean => {
        if (!context?.id) {
            return false;
        }
        return context.access(this.name) && context.state === this.state;
    };
}

export class AcceptanceCriterionCompletedOperation
    extends AbstractCompletedOperation<'task_acceptance_criteria/completed'> {
    readonly name = 'completed';
    icon = 'fa-check-circle';
    lang = 'TASK_ACCEPTANCE_CRITERIA_COMPLETED';
    endpointName = CompletedEndpoint.COMPLETED;
    state = TaskAcceptanceCriterionState.TASK_ACCEPTANCE_CRITERION_NEW;
}

export class AcceptanceCriterionUncompletedOperation
    extends AbstractCompletedOperation<'task_acceptance_criteria/uncompleted'> {
    readonly name = 'uncompleted';
    icon = 'fa-times-circle';
    lang = 'TASK_ACCEPTANCE_CRITERIA_UNCOMPLETED';
    endpointName = CompletedEndpoint.UNCOMPLETED;
    state = TaskAcceptanceCriterionState.TASK_ACCEPTANCE_CRITERION_COMPLETED;
}


export const taskAcceptanceCriteriaOperations = [
    AcceptanceCriterionAddOperation,
    AcceptanceCriterionEditOperation,
    AcceptanceCriterionDeleteOperation,
    AcceptanceCriterionCheckOperation,
    AcceptanceCriterionCompletedOperation,
    AcceptanceCriterionUncompletedOperation
] as const;
