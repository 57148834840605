import {AbstractOperationDefinition} from 'src/modules/operations/interfaces';
import {Injector} from '@angular/core';
import {StateService} from '@uirouter/core';
import {IPartner, IUserLogin, IUserLoginOutputDto, UserLoginType, With} from '_types/rest';
import {IUserAction, UserActionsService} from 'src/services/user-actions.service';
import {noop, Observable, Subject} from 'rxjs';
import {ConfirmModalService} from 'src/modules/global-components/confirm-modal/confirm-modal.service';
import {RestClient} from 'src/modules/rest/rest-client.service';
import {IRestCollection, IRestObject} from 'src/modules/rest/objects';
import {boundUserLoginList} from 'view-modules/_shared/users-list/users-list.component';
import {IOperationResult} from 'src/modules/operations/interfaces';
import {
    IUserLoginActivateInput,
    IUserLoginEnableInput,
    IUserLoginStateOutput
} from '_types/custom/IUserState';

type UserLoginOrArray = IRestObject<string, IUserLoginOutputDto> | IRestObject<string, IUserLoginOutputDto>[];

abstract class AbstractActionOperationDefinition
    extends AbstractOperationDefinition<string, IRestObject<string, IUserLoginOutputDto>> {

    invoke(
        context: UserLoginOrArray,
        injector: Injector
    ): Observable<IOperationResult> {
        return this.getAction(context, injector).execute(context, noop);
    }

    access(context: UserLoginOrArray, injector: Injector): boolean {
        return !!this.getAction(context, injector);
    }

    private getAction(context: UserLoginOrArray, injector: Injector): IUserAction {
        return injector.get(UserActionsService)
            .get(Array.isArray(context) ? null : context)
            .find((action) => action.operation === this.name);
    }
}

export type IAppUserLoginFormType = 'client' | 'employee' | 'admin';

interface IDefaultLoginContext {
    formType: 'client' | 'employee' | 'admin';
    accessObject?: IRestCollection<'user_logins' | 'user_logins/list'> | IRestCollection<string,  boundUserLoginList>;
    userLogin?: With<IRestObject<'user_logins'>, 'company'> | IUserLoginOutputDto | IRestObject<'user_logins'>;
    access?: true;
}

export interface ClientUserLoginContext extends IDefaultLoginContext {
    formType: 'client';
    client: string;
}

export interface EmployeeUserLoginContext extends IDefaultLoginContext {
    formType: 'employee';
    partner?: With<IPartner, 'company'>;
}

export interface AdminUserLoginContext extends IDefaultLoginContext {
    formType: 'admin';
    partner?: With<IPartner, 'company'>;
}

export type AppUserLoginContext = ClientUserLoginContext | EmployeeUserLoginContext | AdminUserLoginContext;

export class AppUserLoginAddOperation extends AbstractOperationDefinition<string, AppUserLoginContext> {
    readonly endpoint = 'user_logins';
    readonly name = 'post';
    lang = 'ADD_NEW_USER';
    icon = 'fa-plus';

    component = (): Promise<unknown> => import('./user-login-form/user-login-form.component');

    access(context: AppUserLoginContext): boolean {
        if (!context) {
            return false;
        }

        if (
            'access' in context
             && context.access === true
        ) {
            return true;
        } else if (
            'accessObject' in context
             && (RestClient.isRestObject(context.accessObject) || RestClient.isRestCollection(context.accessObject))
        ) {
            const operationName = context.formType === 'client'
                ? 'client_create'
                : 'post';
            return context.accessObject.access(operationName);
        }

        return false;
    }
}

export class AppUserLoginEditOperation extends AbstractOperationDefinition<'user_logins', AppUserLoginContext> {
    readonly endpoint = 'user_logins';
    readonly name = 'put';
    lang = 'EDIT';
    icon = 'fa-edit';

    component = (): Promise<unknown> => import('./user-login-form/user-login-form.component');

    access(context: AppUserLoginContext): boolean {
        if (!context) {
            return false;
        }

        if (
            'userLogin' in context
            && (RestClient.isRestObject(context.userLogin) || RestClient.isRestCollection(context.userLogin))
        ) {
            return context.userLogin.access(this.name);
        }

        return false;
    }
}

export class UserLoginViewOperation
    extends AbstractOperationDefinition<string, IRestObject<string, IUserLoginOutputDto>> {

    readonly endpoint = 'user_logins';
    readonly name = 'view_user_login';
    lang = 'VIEW';
    icon = 'fa-eye';

    invoke(
        context: IUserLoginOutputDto,
        injector: Injector
    ): boolean {
        injector.get(StateService).go('.view', {userLoginId: context.id});
        return false;
    }

    access(context: IUserLoginOutputDto): boolean {
        return context.type === UserLoginType.USER_TYPE_NORMAL;
    }
}

export class UserLoginResendActivationMailOperation extends AbstractActionOperationDefinition {
    readonly endpoint = 'user_logins';
    readonly name = 'resend_activation_mail';
    lang = 'USER_RESEND_ACTIVATION';
    icon = 'fa-envelope';

    invoke(
        context: UserLoginOrArray,
        injector: Injector
    ): Observable<IOperationResult> {
        return super.invoke(context, injector);
    }
}

export class UserLoginForceMFADisableOperation extends AbstractActionOperationDefinition {
    readonly endpoint = 'user_logins';
    readonly name = 'force_mfa_disable';
    lang = 'USER_MFA_DISABLE';
    icon = 'fa-unlock-alt';

    invoke(
        context: UserLoginOrArray,
        injector: Injector
    ): Observable<IOperationResult> {
        return super.invoke(context, injector);
    }
}

export class UserLoginForcePrivilegesOperation extends AbstractActionOperationDefinition {
    readonly endpoint = 'user_logins';
    readonly name = 'force_privileges';
    lang = 'USER_RECREATE_PRIVILEGES';
    icon = 'fa-sync';

    invoke(
        context: UserLoginOrArray,
        injector: Injector
    ): Observable<IOperationResult> {
        return super.invoke(context, injector);
    }
}

export class UserLoginImpersonateOperation extends AbstractActionOperationDefinition {
    readonly endpoint = 'user_logins';
    readonly name = 'impersonate';
    lang = 'USER_IMPERSONATE';
    icon = 'fa-sign-in-alt';

    invoke(
        context: UserLoginOrArray,
        injector: Injector
    ): Observable<IOperationResult> {
        return super.invoke(context, injector);
    }
}

export class UserLoginForceLogoutOperation extends AbstractActionOperationDefinition {
    readonly endpoint = 'user_logins';
    readonly name = 'force_logout';
    lang = 'USER_LOGOUT';
    icon = 'fa-sign-out-alt';

    invoke(
        context: UserLoginOrArray,
        injector: Injector
    ): Observable<IOperationResult> {
        return super.invoke(context, injector);
    }
}

export class UserLoginLogoutAllOperation
    extends AbstractOperationDefinition<string, IRestObject<string, IUserLoginOutputDto>[]> {
    readonly endpoint = 'user_logins';
    readonly name = 'logout_all';
    lang = 'LOGOUT_ALL_USERS';
    icon = 'TODO';

    invoke(
        context: IUserLoginOutputDto[],
        injector: Injector
    ): Observable<IOperationResult> {
        const restClient = injector.get(RestClient),
            confirmModal = injector.get(ConfirmModalService);

        return confirmModal.confirmOperation(
            'USER_LOGOUT_ALL_CONFIRM',
            () => {
                return restClient.endpoint('user_logins/logout/all').create({});
            },
            {
                text: 'USER_LOGOUT_ALL_CONFIRM_TEXT',
                langYes: 'USER_LOGOUT_ALL_CONFIRM_LANG_YES',
            }
        );
    }

    access(): boolean {
        return true;
    }
}

export class UserLoginEnableOperation
    extends AbstractOperationDefinition<string,
        IRestObject<string, IUserLoginOutputDto | With<IUserLogin, 'userLoginDisable'>>> {

    readonly endpoint = 'user_logins';
    readonly name = 'user_enable';
    lang = 'USER_ENABLE';
    icon = 'fa-user-plus';

    invoke(
        context: IRestObject<string, IUserLoginOutputDto | With<IUserLogin, 'userLoginDisable'>>,
        injector: Injector
    ): Observable<IOperationResult> {
        const restClient = injector.get(RestClient),
            subject = new Subject<IOperationResult>();

        (restClient.endpoint<'user_logins/enable', IUserLoginEnableInput>('user_logins/enable')
            .createObject({
                id: context.id
            })
            .persist() as Observable<IUserLoginStateOutput>)
            .subscribe({
                next: (user) => {
                    restClient.savedToast();
                    subject.next({success: true, customValue: user});
                    subject.complete();
                },
                error: (err) => {
                    subject.next({success: false});
                    subject.error(err);
                }
            });

        return subject.asObservable();
    }
}

export class UserLoginDisableOperation
    extends AbstractOperationDefinition<string,
    IRestObject<string, IUserLoginOutputDto | With<IUserLogin, 'userLoginDisable'>>> {

    readonly endpoint = 'user_logins';
    readonly name = 'user_disable';
    lang = 'USER_DISABLE';
    longLang = 'USER_DISABLE_PROMPT';
    icon = 'fa-user-minus';

    component = ():
        Promise<unknown> => import('view-modules/operations/user-logins/user-state-form/user-state-form.component');
}

export class UserLoginActivateOperation
    extends AbstractOperationDefinition<string,
    IRestObject<string, IUserLoginOutputDto | With<IUserLogin, 'userLoginDisable'>>> {

    readonly endpoint = 'user_logins';
    readonly name = 'user_activate';
    lang = 'USER_ACTIVATE';
    icon = 'fa-check text-success';

    invoke(
        context: IRestObject<string, IUserLoginOutputDto | With<IUserLogin, 'userLoginDisable'>>,
        injector: Injector
    ): Observable<IOperationResult> {
        const restClient = injector.get(RestClient),
            subject = new Subject<IOperationResult>();

        (restClient.endpoint<'user_logins/activate', IUserLoginActivateInput>('user_logins/activate')
            .createObject({
                id: context.id
            })
            .persist() as Observable<IUserLoginStateOutput>)
            .subscribe({
                next: (user) => {
                    restClient.savedToast();
                    subject.next({success: true, customValue: user});
                    subject.complete();
                },
                error: (err) => {
                    subject.next({success: false});
                    subject.error(err);
                }
            });

        return subject.asObservable();
    }
}

export class UserLoginDeactivateOperation
    extends AbstractOperationDefinition<string,
    IRestObject<string, IUserLoginOutputDto | With<IUserLogin, 'userLoginDisable'>>> {

    readonly endpoint = 'user_logins';
    readonly name = 'user_deactivate';
    lang = 'USER_DEACTIVATE';
    longLang = 'USER_DEACTIVATE_PROMPT';
    icon = 'fa-times text-danger';

    component = ():
        Promise<unknown> => import('view-modules/operations/user-logins/user-state-form/user-state-form.component');
}

export const userLoginsOperations = [
    AppUserLoginAddOperation,
    AppUserLoginEditOperation,
    UserLoginViewOperation,
    UserLoginActivateOperation,
    UserLoginDeactivateOperation,
    UserLoginResendActivationMailOperation,
    UserLoginForceMFADisableOperation,
    UserLoginForcePrivilegesOperation,
    UserLoginImpersonateOperation,
    UserLoginForceLogoutOperation,
    UserLoginLogoutAllOperation,
    UserLoginDisableOperation,
    UserLoginEnableOperation
] as const;
