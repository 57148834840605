import {ITaskNoteFile} from '_types/rest/Entity/IRestTaskNoteFile';
import {IFile} from '_types/rest/Entity/IRestFile';
import {Utils} from 'src/services/utils';
import {IUserData} from 'src/modules/rest/user/user-login.service';
import {UserService} from 'src/modules/rest/user/user.service';
import {RestClient} from 'src/modules/rest/rest-client.service';
import {Component, Host, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {NgForm} from '@angular/forms';
import {TaskUtilsService} from 'src/modules/tasks/task-utils.service';
import {AppTask, boundTask, boundTaskNote, TaskViewComponent} from 'src/modules/tasks/task-view/task-view.component';
import {IFileListItem} from 'src/modules/file-upload/file-list/file-list.component';
import {NgFormValidateDirective} from 'angular-bootstrap4-validate';
import {IRestObject} from 'src/modules/rest/objects';
import {RestEndpoint} from 'src/modules/rest/rest-endpoint';
import {
    ICompany,
    ICooperationNoteInputDto,
    ITaskHistoryOutputDto,
    ITaskNote,
    IUserLogin,
    With
} from '_types/rest';
import {OperationsService} from 'src/modules/operations/operations.service';
import {concatMap, identity, Observable, Subject} from 'rxjs';
import {finalize, takeUntil} from 'rxjs/operators';
import {IWysiwygEditorMetadata} from 'src/modules/wysiwyg-editor/interfaces';
import {TaskMentionsService} from 'src/modules/tasks/task-view/task-users/task-mentions.service';
import {TimeTrackerService} from 'src/modules/time-tracker/time-tracker.service';
import {IOperationResult} from 'src/modules/operations/interfaces';
import {AddNote} from 'view-modules/operations/notes/notes.operations';

type ITaskNoteWith = With<
    IRestObject<'task_notes', ITaskNote>,
    never,
    {
        taskNoteFiles: With<ITaskNoteFile, 'file'>[]
    }
>;

@Component({
    selector: 'task-note-edit',
    templateUrl: './task-note-edit.component.html',
    styleUrls: ['./task-note-edit.component.scss']
})
export class TaskNoteEditComponent implements OnInit, OnDestroy {
    @Input() task: AppTask;
    @Input() taskRest: IRestObject<'tasks/item', boundTask>;

    @ViewChild(NgForm) noteForm: NgForm;
    @ViewChild(NgFormValidateDirective) noteFormValidate: NgFormValidateDirective;

    user: IUserData;

    note: IRestObject<'task_notes', boundTaskNote>;
    files: IFileListItem[];
    noteSaving = false;
    addNote = false;
    taskHasActiveTracker = false;

    mentionMetadata: IWysiwygEditorMetadata['mentions'];
    mentionedUsers: IUserLogin[] = [];

    private readonly _destroy$ = new Subject<void>();

    constructor(
        @Host() private readonly taskViewComponent: TaskViewComponent,
        private readonly restClient: RestClient,
        private readonly taskUtils: TaskUtilsService,
        private readonly operationsService: OperationsService,
        private readonly userService: UserService,
        private readonly timeTrackerService: TimeTrackerService,
        private readonly taskMentionsService: TaskMentionsService
    ) {
        this.user = userService.get();
    }

    ngOnInit(): void {
        this.initNote();
        this.updateFileList();
        this.disableTaskNoteOnActiveTracker();
    }

    fileDropped(files: File[]): void {

        Array.from(files).forEach((file: File) => {
            const fileObject: IFileListItem = {
                id: null,
                name: file.name,
                progress: 0
            };
            this.restClient.endpoint('files/upload').upload(
                file,
                {
                    tableName: 'task_note_file',
                    entityFieldName: 'file'
                },
                (e) => {
                    fileObject.progress = (e.loaded / e.total) * 100;
                }
            ).subscribe((response) => {
                fileObject.progress = 100;
                this.files.push(fileObject);
                this.uploadFile(response);
            });
        });
    }

    /**
     * Initialize empty note object
     */
    initNote(): void {
        this.note = new RestEndpoint<'task_notes', boundTaskNote>('task_notes').createObject({
            public: 1,
            text: undefined,
            task: undefined
        });
        if (typeof this.noteForm !== 'undefined') {
            this.noteForm.reset();
            this.noteFormValidate.resetValidation();
        }
    }

    /**
     * Assign copy of note to note object
     */
    editNote(note: IRestObject<string, ITaskHistoryOutputDto | boundTaskNote>): void {
        // condition check if note is instance of boundTaskNote
        if (!('action' in note)) {
            this.note = Utils.clone(note as IRestObject<'task_notes', boundTaskNote>);
            this.updateFileList();
        }
    }

    /**
     * Remove file from note (saved file)
     */
    removeFile(file: ITaskNoteFile): void {
        const index = this.note.taskNoteFiles.findIndex((item) => {
            return (item.file as IFile).id === file.id;
        });
        if (index !== -1) {
            this.note.taskNoteFiles.splice(index, 1);
        }
        this.updateFileList();
    }

    uploadFile(file: IFile): void {
        if (!Array.isArray(this.note.taskNoteFiles)) {
            this.note.taskNoteFiles = [];
        }
        this.note.taskNoteFiles.push({file});
        this.updateFileList();
    }

    updateFileList(): void {
        if (!this.note.taskNoteFiles) {
            this.files = [];
            return;
        }
        this.files = this.note.taskNoteFiles.map((taskNoteFile) => {
            const file: IFile = taskNoteFile.file as IFile;
            return {
                id: file.id,
                name: file.originalName,
                originalItem: this.restClient.endpoint('task_note_files').createObject(taskNoteFile)
            };
        }) as IFileListItem[];
    }

    private disableTaskNoteOnActiveTracker(): void {
        this.timeTrackerService.getActiveEntries$(this.task['@id'])
            .pipe(takeUntil(this._destroy$))
            .subscribe((entries) => {
                // Make sure status is updated after completed cycle.
                setTimeout(() => {
                    this.taskHasActiveTracker = !!entries.length;
                });
            });
    }

    /**
     * Save new or edit note
     */
    saveNote(): void {
        this.noteSaving = true;

        this.taskUtils.mergeFiles(this.note, '_taskNoteFiles', 'taskNoteFiles');

        const noteToSave = Utils.clone(this.note as ITaskNote);

        // Shorten nested objects to IRI
        Utils.shortenFields(noteToSave, [
            'userLogin',
            'task'
        ]);

        noteToSave.taskNoteFiles?.map((file) => Utils.shortenFields(file, ['file']));

        if (!this.note.id) {
            noteToSave.task = this.task['@id'];
            noteToSave.userLogin = this.restClient.endpoint('user_logins').getIri(this.user.id);
        }

        const addNoteOperator = concatMap((taskNote: ITaskNoteWith): Observable<IOperationResult | ITaskNote> => {
            const noteFromTaskNote = this.restClient
                    .endpoint<'/cooperation_notes/list', ICooperationNoteInputDto>('/cooperation_notes/list')
                    .createObject({
                        company: this.task.company as ICompany,
                        title: this.task.name,
                        text: taskNote.text,
                        inReport: true,
                        tags: [],
                        type: null,
                        initiative: null,
                        sourceIri: taskNote['@id'],
                        contractItems: this.task.contractItem ? [this.task.contractItem] : [],
                        cooperationNoteFiles: taskNote.taskNoteFiles.map((taskNoteFile) => {
                            return {
                                file: taskNoteFile.file,
                                cooperationNote: undefined
                            };
                        })
                    }),
                addNoteOperation = this.operationsService.get(AddNote);

            // Override standard access method since we don't have note collection to check privileges.
            addNoteOperation.access = () => true;
            return this.operationsService.execute(addNoteOperation, {note: noteFromTaskNote});
        });

        const payload = this.restClient.endpoint('task_notes').createObject(noteToSave),
            query = {with: 'taskNoteFiles.file'};

        payload.persist(query)
            .pipe(
                this.addNote ? addNoteOperator : identity,
                finalize(() => {
                    this.noteSaving = false;
                    this.addNote = false;
                })
            )
            .subscribe(() => {
                this.emitMentionedUsers();

                this.taskViewComponent.taskStoryComponent.reloadStoryFromOutside();

                this.taskViewComponent.taskFilesComponent.loadFilesFromApi();

                this.initNote();
                this.updateFileList();

                if (!this.addNote) {
                    this.restClient.savedToast();
                }
            });
    }

    private emitMentionedUsers(): void {
        const stillMentioned = TaskMentionsService.getStillMentioned(this.mentionedUsers, this.mentionMetadata);
        this.taskMentionsService.mentionUsers(stillMentioned, this.task['@id']);
        this.mentionedUsers = [];
    }

    handleMentionMetadata(mentionMetadata: IWysiwygEditorMetadata['mentions']): void {
        if (this.mentionMetadata) {
            return;
        }

        this.mentionMetadata = mentionMetadata;

        this.mentionMetadata.userMentionedSubject
            .pipe(takeUntil(this._destroy$))
            .subscribe((mentionedUser) => {
                this.mentionedUsers.push(mentionedUser);
            });
    }

    ngOnDestroy(): void {
        this._destroy$.next();
        this._destroy$.complete();
    }
}
