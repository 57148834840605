import {Injector, KeyValueDiffer, KeyValueDiffers} from '@angular/core';
import {IOperationAbstract} from 'src/modules/operations/interfaces';

export abstract class AbstractOperationAccessChecker<TOperation extends IOperationAbstract = IOperationAbstract> {

    abstract context?: TOperation['context'];
    private contextDiffer: KeyValueDiffer<unknown, unknown>;
    private _contextToCompare: unknown;
    private _contextConstructorName: string;

    protected abstract readonly differs: KeyValueDiffers;
    protected abstract readonly injector: Injector;
    protected updateAccessOnPrimitiveChange(): void {
        if (this._contextToCompare === this.context) {
            return;
        }

        this.updateAccess();
        this._contextToCompare = this.context;
    }

    protected updateAccessOnObjectChange(): void {
        if (!this.contextDiffer) {
            this.contextDiffer = this.differs.find(this.context).create();
        }
        const changes = this.contextDiffer.diff(this.context as { [key: string]: unknown });
        if (changes) {
            this.updateAccess();
            return;
        }

        if (
            this.context?.constructor?.name
            && this._contextConstructorName !== this.context.constructor?.name
        ) {
            this.updateAccess();
            this._contextConstructorName = this.context.constructor.name;
        }
    }

    protected abstract updateAccess(): void;
}
