import {Pipe, PipeTransform} from '@angular/core';
import {UserPrivileges, UserService} from 'src/modules/rest/user/user.service';

@Pipe({name: 'userPrivileges'})
export class UserPrivilegesPipe implements PipeTransform {
    constructor(
        private userService: UserService
    ) {
    }

    transform(routeName: UserPrivileges): boolean {
        return this.userService.hasPrivileges(routeName);
    }
}
