import {Injectable} from '@angular/core';
import {Title} from '@angular/platform-browser';

@Injectable({
    providedIn: 'root'
})
export class TitleService {
    private title = {
        appTitle: '',
        subpage: '',
        entityName: ''
    };

    constructor(
        private titleService: Title
    ) {
    }

    checkAndApplyTitle(): void {
        if (this.getTitle() !== this.getTitleString()) {
            this.setAppTitle();
        }
    }

    setAppTitle(): void {
        const titleString = this.getTitleString();
        this.titleService.setTitle(titleString);
    }

    setTitle(entityName: string = null, subpage: string = null, appTitle: string = null): void {
        if (entityName !== null) {
            this.title.entityName = entityName;
        }

        if (subpage !== null) {
            this.title.subpage = subpage;
        }

        if (appTitle !== null) {
            this.title.appTitle = appTitle;
        }
        this.checkAndApplyTitle();
    }


    getTitle(): string {
        return this.titleService.getTitle();
    }

    getTitleString(): string {
        return `${this.title.entityName ? `${this.title.entityName} - ` : ''}
            ${this.title.subpage ? `${this.title.subpage} |  ` : ''}
            ${this.title.appTitle ? this.title.appTitle : ''}`;
    }
}
