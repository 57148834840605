/**
 * This file has been auto generated. Do not edit.
 */
import {IOAuth2Scope} from '_types/rest/Entity/IRestOAuth2Scope';
import {IRestOAuth2Scope} from '_types/rest/Entity/IRestOAuth2Scope';
import {IOAuth2Service} from '_types/rest/Entity/IRestOAuth2Service';
import {IRestOAuth2Service} from '_types/rest/Entity/IRestOAuth2Service';
import {IPartner} from '_types/rest/Entity/IRestPartner';
import {IRestPartner} from '_types/rest/Entity/IRestPartner';
import {IOAuth2Grant} from '_types/rest/Entity/IRestOAuth2Grant';
import {IRestOAuth2Grant} from '_types/rest/Entity/IRestOAuth2Grant';
import {IBaseRestEntity} from '_types/rest/IBaseRestEntity';
import {IRestObject} from 'core/rest/rest-object.service';

export const OAuth2ApplicationType = {
    APPLICATION_TYPE_GSC: 1
} as const;

export const OAuth2ApplicationTypeOptions = [
    {
        value: OAuth2ApplicationType.APPLICATION_TYPE_GSC,
        label: 'APPLICATION_TYPE_GSC',
    }
] as const;

export interface IOAuth2ApplicationConst {
    type: [{id: 1, value: 'APPLICATION_TYPE_GSC'}]
}

export interface IOAuth2Application extends IBaseRestEntity {
    id?: number,
    /**
     * @maxLength 32
     */
    name: string,
    /**
     * @maxLength 128
     */
    clientId: string,
    clientSecret?: string,
    /**
     * @maxLength 255
     */
    redirectUrl: string,
    scopes?: (IOAuth2Scope | string)[],
    service?: IOAuth2Service | string | null,
    description?: string,
    /**
     * @description Defines if there is an offline access grant for this app.
     * @default true
     */
    offlineAccess?: boolean,
    partner?: IPartner | string | null,
    /**
     * @default 1
     */
    type?: typeof OAuth2ApplicationType[keyof typeof OAuth2ApplicationType],
    oAuth2Grants?: (IOAuth2Grant | string)[],
    /**
     * @description Record status
     *  0 = deleted
     *  1 = active.
     * @default 1
     */
    status?: number,
    /**
     * @description Creation timestamp.
     * @default CURRENT_TIMESTAMP
     * @format date-time
     */
    created?: string,
    /**
     * @description Last modification timestamp.
     * @format date-time
     */
    modified?: string | null
}

/** @deprecated */
export interface IRestOAuth2Application extends IOAuth2Application, IRestObject {
    scopes?: IRestOAuth2Scope[] | string[],
    service?: IRestOAuth2Service | string | null,
    partner?: IRestPartner | string | null,
    oAuth2Grants?: IRestOAuth2Grant[] | string[],
}
