import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {FormControl} from '@angular/forms';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {
    AbstractUnvalidatedControlValueAccessor
} from 'src/modules/app-forms/abstract-control-value-accessors/abstract-unvalidated-control-value-accessor';
import {
    IDynamicFieldControlValueAccessor, ITextDynamicFieldConfig
} from 'src/modules/dynamic-fields/interfaces';
import isEqual from 'lodash.isequal';

@Component({
    selector: 'text-input',
    templateUrl: './text-input.component.html',
    providers: AbstractUnvalidatedControlValueAccessor.getProviders(TextInputComponent)
})
export class TextInputComponent
    extends AbstractUnvalidatedControlValueAccessor
    implements IDynamicFieldControlValueAccessor, OnInit, OnDestroy {

    @Input() fieldConfig: ITextDynamicFieldConfig;

    private defaultConfig: Partial<ITextDynamicFieldConfig> = {
        isWYSIWYG: false,
        configWYSIWYG: {
            toolbarItems: [],
            excludeToolbarItems: [],
            excludePlugins: []
        },
        rows: 2
    };

    textInputControl = new FormControl(null);
    value: string;

    private readonly _destroy$ = new Subject<void>();


    ngOnInit(): void {
        this.initializeConfig();
        this.subscribeFormControl();
    }

    private initializeConfig(): void {
        const temp = Object.assign(this.defaultConfig, this.fieldConfig);

        Object.assign(this.fieldConfig, temp);
    }

    private subscribeFormControl(): void {
        this.textInputControl.valueChanges
            .pipe(
                takeUntil(this._destroy$),
            )
            .subscribe((value: string) => {
                this.markAsTouched();
                this.value = value;
                this._onChange(value);
            });
    }

    writeValue(value: string): void {
        if (!isEqual(this.textInputControl.value, value)) {
            this.updateControlValue(value);
        }
    }

    private updateControlValue(value: string) {
        this.textInputControl.setValue(value, {emitEvent: false});
        this.value = value;
    }

    setDisabledState(isDisabled: boolean): void {
        if (isDisabled) {
            this.textInputControl.disable({emitEvent: false});
            return;
        }
        this.textInputControl.enable({emitEvent: false});
    }

    ngOnDestroy(): void {
        this._destroy$.next();
        this._destroy$.complete();
    }
}
