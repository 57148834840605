/**
 * This file has been auto generated. Do not edit.
 */
import {ITask} from '_types/rest/Entity/IRestTask';
import {IRestTask} from '_types/rest/Entity/IRestTask';
import {IService} from '_types/rest/Entity/IRestService';
import {IRestService} from '_types/rest/Entity/IRestService';
import {ICategory} from '_types/rest/Entity/IRestCategory';
import {IRestCategory} from '_types/rest/Entity/IRestCategory';
import {IUserLogin} from '_types/rest/Entity/IRestUserLogin';
import {IRestUserLogin} from '_types/rest/Entity/IRestUserLogin';
import {ICompany} from '_types/rest/Entity/IRestCompany';
import {IRestCompany} from '_types/rest/Entity/IRestCompany';
import {ICompanyContact} from '_types/rest/Entity/IRestCompanyContact';
import {IRestCompanyContact} from '_types/rest/Entity/IRestCompanyContact';
import {ICompanyContactPhone} from '_types/rest/Entity/IRestCompanyContactPhone';
import {IRestCompanyContactPhone} from '_types/rest/Entity/IRestCompanyContactPhone';
import {IExternalSource} from '_types/rest/Entity/IRestExternalSource';
import {IRestExternalSource} from '_types/rest/Entity/IRestExternalSource';
import {ICooperationNoteFile} from '_types/rest/Entity/IRestCooperationNoteFile';
import {IRestCooperationNoteFile} from '_types/rest/Entity/IRestCooperationNoteFile';
import {IContractItem} from '_types/rest/Entity/IRestContractItem';
import {IRestContractItem} from '_types/rest/Entity/IRestContractItem';
import {IBaseRestEntity} from '_types/rest/IBaseRestEntity';
import {IRestObject} from 'core/rest/rest-object.service';

export const CooperationNoteBlockReason = {
    BLOCK_REASON_ACCESS_DATA: 1,
    BLOCK_REASON_OTHER: 2
} as const;

export const CooperationNoteBlockReasonOptions = [
    {
        value: CooperationNoteBlockReason.BLOCK_REASON_ACCESS_DATA,
        label: 'BLOCK_REASON_ACCESS_DATA',
    },
    {
        value: CooperationNoteBlockReason.BLOCK_REASON_OTHER,
        label: 'BLOCK_REASON_OTHER',
    }
] as const;

export interface ICooperationNoteConst {
    blockReason: [{id: 1, value: 'BLOCK_REASON_ACCESS_DATA'}, {id: 2, value: 'BLOCK_REASON_OTHER'}]
}

export interface ICooperationNote extends IBaseRestEntity {
    id?: number,
    task?: ITask | string | null,
    service?: IService | string | null,
    categories?: (ICategory | string)[],
    text: string,
    plainText?: string | null,
    /**
     * @maxLength 128
     */
    title: string,
    userLogin: IUserLogin | string,
    blockReason?: typeof CooperationNoteBlockReason[keyof typeof CooperationNoteBlockReason],
    /**
     * @maxLength 1024
     */
    customBlockReason?: string | null,
    /**
     * @default true
     */
    inReport: boolean,
    wrongNote?: boolean,
    company?: ICompany | string | null,
    companyContact?: ICompanyContact | string | null,
    companyContactPhone?: ICompanyContactPhone | string | null,
    externalSource?: IExternalSource | string | null,
    cooperationNoteFiles?: (ICooperationNoteFile | string)[],
    services?: (IService | string)[],
    contractItems?: (IContractItem | string)[],
    tags?: (ICategory | string)[],
    type: ICategory | string | null,
    initiative?: ICategory | string | null,
    /**
     * @description Record status
     *  0 = deleted
     *  1 = active.
     * @default 1
     */
    status?: number,
    /**
     * @description Creation timestamp.
     * @default CURRENT_TIMESTAMP
     * @format date-time
     */
    created?: string,
    /**
     * @description Last modification timestamp.
     * @format date-time
     */
    modified?: string | null
}

/** @deprecated */
export interface IRestCooperationNote extends ICooperationNote, IRestObject {
    task?: IRestTask | string | null,
    service?: IRestService | string | null,
    categories?: IRestCategory[] | string[],
    userLogin: IRestUserLogin | string,
    company?: IRestCompany | string | null,
    companyContact?: IRestCompanyContact | string | null,
    companyContactPhone?: IRestCompanyContactPhone | string | null,
    externalSource?: IRestExternalSource | string | null,
    cooperationNoteFiles?: IRestCooperationNoteFile[] | string[],
    services?: IRestService[] | string[],
    contractItems?: IRestContractItem[] | string[],
    tags?: IRestCategory[] | string[],
    type: IRestCategory | string | null,
    initiative?: IRestCategory | string | null,
}
