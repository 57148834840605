import {NgModule} from '@angular/core';
import {AppDirectivesModule} from 'src/directives/app-directives.module';
import {NavTabsComponent} from 'src/modules/nav-tabs/nav-tabs.component';
import {NavTabComponent} from 'src/modules/nav-tabs/nav-tab/nav-tab.component';
import {TranslateModule} from 'src/modules/translate/translate.module';
import {AppCommonModule} from 'src/modules/app-common/app-common.module';

@NgModule({
    imports: [
        AppCommonModule,
        AppDirectivesModule,
        TranslateModule
    ],
    declarations: [
        NavTabComponent,
        NavTabsComponent
    ],
    exports: [
        NavTabComponent,
        NavTabsComponent
    ]
})

export class NavTabsModule {}
