import {Injectable} from '@angular/core';
import {IUserLogin} from '_types/rest';
import {Observable, Subject} from 'rxjs';
import {Comparison} from 'src/services/comparison';
import {IMentionMetadata} from 'src/modules/wysiwyg-editor/interfaces';
import {RestClient} from 'src/modules/rest/rest-client.service';

export const MentionActionType = {
    MENTION_ACTION_ENABLE: 1,
    MENTION_ACTION_DISABLE: 2,
    MENTION_ACTION_NEW: 3,
} as const;

export interface IMentionAction {
    name: typeof MentionActionType[keyof typeof MentionActionType],
    userLogins: IUserLogin[]
}


@Injectable({
    providedIn: 'root'
})
export class TaskMentionsService {
    mentionedInTask$: Observable<IUserLogin[]>;

    private readonly mentionedInTaskSubject = new Subject<IUserLogin[]>();
    private readonly mentionActionSubject = new Subject<IMentionAction>();

    constructor(
        private readonly restClient: RestClient
    ) {
        this.mentionedInTask$ = this.mentionedInTaskSubject.asObservable();
    }

    mentionUsers(users: IUserLogin[], source: string): void {
        const mentions = users.map((user) => {
            return {
                source,
                receiverUserLogin: user['@id']
            };
        });
        this.restClient.endpoint('mentions/bulk').create({mentions})
            .subscribe(() => {
                this.mentionedInTaskSubject.next(users);
            });
    }

    mentionAction(mention?: IMentionAction): Observable<IMentionAction> {
        if (mention) {
            this.mentionActionSubject.next(mention);
        }
        return this.mentionActionSubject.asObservable();
    }

    static getStillMentioned(
        users: IUserLogin[],
        metadata: IMentionMetadata
    ): IUserLogin[] {
        return users.filter((user) => {
            return metadata.users.find(Comparison.criteria({userId: user.id}));
        });
    }
}
