import {
    AfterContentInit,
    Component,
    ComponentFactory,
    ComponentFactoryResolver,
    ComponentRef, ContentChild,
    ContentChildren, EventEmitter,
    HostBinding,
    Injector,
    Input, OnChanges, OnDestroy,
    OnInit, Output,
    QueryList, SimpleChanges,
    Type,
    ViewChild
} from '@angular/core';
import {CustomInputComponent} from 'src/modules/dynamic-fields/controls/custom-input/custom-input.component';
import {FileInputComponent} from 'src/modules/dynamic-fields/controls/file-input/file-input.component';
import {
    DynamicFieldHostDirective
} from 'src/modules/dynamic-fields/dynamic-field/dynamic-field-host-directive.component';
import {
    AbstractControl,
    ControlValueAccessor,
    NG_VALIDATORS,
    NG_VALUE_ACCESSOR, NgControl, ValidationErrors, Validator,
    ValidatorFn
} from '@angular/forms';
import {
    DynamicFieldType,
    DynamicFieldValidators,
    IDynamicFieldConfig,
    IDynamicFieldControlValueAccessor
} from 'src/modules/dynamic-fields/interfaces';
import {AbstractValidateElementDirective, VALIDATE_ELEMENT, Validators} from 'angular-bootstrap4-validate';
import {EntityFieldDisplay} from '_types/rest';
import {TranslateService} from 'src/modules/translate/translate.service';
import {TextInputComponent} from 'src/modules/dynamic-fields/controls/text-input/text-input.component';
import {NumberInputComponent} from 'src/modules/dynamic-fields/controls/number-input/number-input.component';
import {NumberShuttleComponent} from 'src/modules/dynamic-fields/controls/number-shuttle/number-shuttle.component';
import {ColorPickerComponent} from 'src/modules/dynamic-fields/controls/color-picker/color-picker.component';
import {CustomCheckboxComponent} from 'src/modules/dynamic-fields/controls/custom-checkbox/custom-checkbox.component';
import {DatePickerComponent} from 'angular-bootstrap4-datepicker';
import {DynamicSelectComponent} from 'src/modules/dynamic-fields/controls/dynamic-select/dynamic-select.component';
import {EsBeforeOptionDirective, EsOptionsDirective} from 'angular-bootstrap4-extended-select';
import {RelationSelectComponent} from 'src/modules/dynamic-fields/controls/relation-select/relation-select.component';
import {Subject} from 'rxjs';
import {first, skipWhile, takeUntil} from 'rxjs/operators';
import {IconPickerComponent} from 'src/modules/dynamic-fields/controls/icon-picker/icon-picker.component';
import {CopyInputComponent} from 'src/modules/dynamic-fields/controls/copy-input/copy-input.component';
import {DurationPickerComponent} from 'src/modules/dynamic-fields/controls/duration-picker/duration-picker.component';
import {PasswordInputComponent} from 'src/modules/dynamic-fields/controls/password-input/password-input.component';
import {CategorySelectComponent} from 'src/modules/dynamic-fields/controls/category-select/category-select.component';

type ControlType = Type<IDynamicFieldControlValueAccessor | ControlValueAccessor>;

type IDynamicFieldControls = {
    [P in DynamicFieldType]: ControlType
}

const dynamicFieldControls: IDynamicFieldControls = {
    category: CategorySelectComponent,
    checkbox: CustomCheckboxComponent,
    color: ColorPickerComponent,
    copyInput: CopyInputComponent,
    date: DatePickerComponent,
    durationPicker: DurationPickerComponent,
    email: CustomInputComponent,
    file: FileInputComponent,
    icon: IconPickerComponent,
    number: NumberInputComponent,
    numberShuttle: NumberShuttleComponent,
    password: PasswordInputComponent,
    relation: RelationSelectComponent,
    select: DynamicSelectComponent,
    string: CustomInputComponent,
    text: TextInputComponent,
};

let id = 0;

@Component({
    selector: 'dynamic-field',
    templateUrl: './dynamic-field.component.html',
    styles: [':host { display: block; }'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: DynamicFieldComponent
        },
        {
            provide: NG_VALIDATORS,
            multi: true,
            useExisting: DynamicFieldComponent
        }
    ]
})
export class DynamicFieldComponent<T>
implements OnInit, OnDestroy, ControlValueAccessor, Validator, AfterContentInit, OnChanges {
    @Input() fieldConfig: IDynamicFieldConfig;
    @Input() focus: boolean;
    @Input() objectValue: T;
    @HostBinding('hidden') hidden = false;

    static readonly DYNAMIC_FIELD_ID_PREFIX = 'df_';
    readonly id = `${DynamicFieldComponent.DYNAMIC_FIELD_ID_PREFIX}${++id}`;

    @Output() readonly initializing = new EventEmitter<boolean>();
    @Output() readonly objectValueChange = new EventEmitter<T>();

    @ViewChild(DynamicFieldHostDirective, {static: true}) private _dynamicFieldHost: DynamicFieldHostDirective;

    @ContentChildren(EsOptionsDirective, {descendants: true}) esOptionsList?: QueryList<EsOptionsDirective<unknown>>;
    @ContentChild(EsBeforeOptionDirective) esBeforeOption?: EsBeforeOptionDirective<unknown>;

    private _componentRef: ComponentRef<ControlValueAccessor | IDynamicFieldControlValueAccessor>;
    private _deferredInputs: ComponentFactory<unknown>['inputs'] = [];
    private readonly _destroy$ = new Subject<void>();
    protected _onChange?: (value: unknown) => void;

    constructor(
        private componentFactoryResolver: ComponentFactoryResolver,
        private injector: Injector,
        private translateService: TranslateService
    ) {
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.focus?.currentValue) {
            // Wait for template to render and then set focus on element.
            setTimeout(() => {
                this._doFocus();
            });
        }
    }

    /**
     * methods below are defined in order in which they are called by angular:
     * ngOnInit -> create componentRef -> writeValue -> registerOnChange -> registerOnTouched -> ngAfterContentInit
     */
    ngOnInit(): void {
        if (this.translateService.isTranslationKey(this.fieldConfig.name)) {
            this.fieldConfig.name = this.translateService.get(this.fieldConfig.name);
        }

        if (this.fieldConfig.help && this.translateService.isTranslationKey(this.fieldConfig.help)) {
            this.fieldConfig.help = this.translateService.get(this.fieldConfig.help, true);
        }

        if (typeof this.fieldConfig.validatorMessages === 'object') {
            this.injector
                .get<AbstractValidateElementDirective>(VALIDATE_ELEMENT)
                .errorMessage = this.fieldConfig.validatorMessages;
        }

        if (this.fieldConfig.nameAsPlaceholder) {
            this.fieldConfig.placeholder = this.fieldConfig.name;
        } else if (
            this.fieldConfig.placeholder
            && this.translateService.isTranslationKey(this.fieldConfig.placeholder)
        ) {
            this.fieldConfig.placeholder = this.translateService.get(this.fieldConfig.placeholder);
        }

        this._createComponent(dynamicFieldControls[this.fieldConfig.type]);
    }

    ngOnDestroy(): void {
        this._destroy$.next();
        this._destroy$.complete();
    }

    writeValue(value: unknown): void {
        if (this.fieldConfig.parseValueToType) {
            switch (this.fieldConfig.parseValueToType) {
                case 'number':
                    value = Number(value);
                    break;
                case 'string':
                    value = String(value);
                    break;
            }
        }
        this._componentRef.instance.writeValue(value);
    }

    registerOnChange(fn: (value: unknown) => void): void {
        this._onChange = fn;
        this._componentRef.instance.registerOnChange(fn);
    }

    registerOnTouched(fn: () => void): void {
        this._componentRef.instance.registerOnTouched(fn);
    }

    ngAfterContentInit(): void {
        this._setDefaultValue();

        if (this._deferredInputs.length) {
            this._deferredInputs.forEach((input) => {
                this._componentRef.instance[input.propName] = this[input.templateName];
            });
            this._componentRef.changeDetectorRef.detectChanges();
        }

        switch (this.fieldConfig.display) {
            case EntityFieldDisplay.ENTITY_FIELD_DISPLAY_NONE:
                this.hidden = true;
                break;
            case EntityFieldDisplay.ENTITY_FIELD_DISPLAY_READONLY:
            case EntityFieldDisplay.ENTITY_FIELD_DISPLAY_DISABLED:
                this._componentRef.instance.setDisabledState(true);
                break;
        }

        this.initializing
            .pipe(
                skipWhile((initializing) => initializing),
                first()
            )
            .subscribe(() => {
                if (this.focus) {
                    this._doFocus();
                }
            });
    }

    setDisabledState(isDisabled: boolean): void {
        setTimeout(() => {
            this._componentRef.instance.setDisabledState(isDisabled);
        });
    }

    validate(control: AbstractControl): ValidationErrors | null {
        const result: ValidationErrors = {};

        if (this._componentRef && 'validate' in this._componentRef.instance) {
            // run validators defined on ControlValueAccessor
            Object.assign(result, (this._componentRef.instance as Validator).validate(control));
        }

        this._getValidators(this.fieldConfig.validators, this.fieldConfig.type)
            .forEach((validator) => {
                const validatorResult = validator(control);
                if (validatorResult !== null) {
                    Object.assign(result, validatorResult);
                }
            });

        return result;
    }

    private _getValidators(
        dynamicFieldValidators: DynamicFieldValidators | undefined,
        dynamicFieldType: DynamicFieldType
    ): ValidatorFn[] {
        if (typeof dynamicFieldValidators === 'undefined') {
            return [];
        }

        return Object.entries(dynamicFieldValidators)
            .map(([validator, value]) => {
                switch (validator as keyof DynamicFieldValidators) {
                    case 'required':
                        return value ? Validators.required : undefined;
                    case 'min':
                        return Validators.min(value);
                    case 'max':
                        return Validators.max(value);
                    case 'pattern':
                        return Validators.pattern(value);
                    case 'minlength':
                        return Validators.minLength(value);
                    case 'maxlength':
                        return Validators.maxLength(value);
                    case 'validateUrl':
                        return value
                            ? Validators.url(dynamicFieldType === 'text' ? 'multiple' : 'single')
                            : undefined;
                    case 'validateHost':
                        return value
                            ? Validators.host(dynamicFieldType === 'text' ? 'multiple' : 'single')
                            : undefined;
                    case 'validateJson':
                        return this._validateJson;

                }
            })
            .filter((validator) => typeof validator !== 'undefined');
    }

    private _validateJson(
        control: AbstractControl
    ): ValidationErrors | null {
        try {
            JSON.parse(control.value);
        } catch (e) {
            return {
                jsonError: true,
            };
        }
        return null;
    }

    private _createComponent<T extends ControlValueAccessor | IDynamicFieldControlValueAccessor>(
        component: Type<T>
    ): void {
        const componentFactory = this.componentFactoryResolver.resolveComponentFactory(component);
        this._dynamicFieldHost.viewContainerRef.clear();
        this._componentRef = this._dynamicFieldHost.viewContainerRef.createComponent(componentFactory);

        // set inputs of created component
        componentFactory.inputs.forEach((input) => {
            if (
                // ContentChildren is not available yet, we need to pass it later (in AfterContentInit)
                ['esOptionsList', 'esBeforeOption'].includes(input.templateName)
            ) {
                this._deferredInputs.push(input);
            } else if (
                // direct inputs
                ['id', 'fieldConfig', 'objectValue'].includes(input.templateName)
            ) {
                if (typeof this[input.templateName] !== 'undefined') {
                    this._componentRef.instance[input.propName] = this[input.templateName];
                }
            } else if (
                // fieldConfig props
                input.templateName in this.fieldConfig
            ) {
                this._componentRef.instance[input.propName] = this.fieldConfig[input.templateName];
            }
        });

        // set outputs of created component
        let hasInitializingOutput = false;
        componentFactory.outputs.forEach((output) => {
            switch (output.templateName) {
                case 'initializing':
                    this._componentRef.instance[output.propName]
                        .pipe(takeUntil(this._destroy$))
                        .subscribe((status) => {
                            this.initializing.next(status);
                        });
                    hasInitializingOutput = true;
                    break;
                case 'objectValueChange':
                    this._componentRef.instance[output.propName]
                        .pipe(takeUntil(this._destroy$))
                        .subscribe((value) => {
                            this.objectValueChange.emit(value);
                        });
                    break;
            }
        });

        if (!hasInitializingOutput) {
            this.initializing.next(false);
        }

        // trigger changeDetector so ngOnInit hook is called before writeValue
        if (!this._deferredInputs.length) {
            this._componentRef.changeDetectorRef.detectChanges();
        }
    }

    private _doFocus(): void {
        const element = this._getFocusElement();

        if (element) {
            if (!element.id) {
                element.id = this.id;
            }

            const stopPropagation = ($event: Event) => {
                $event.stopPropagation();
            };

            element.addEventListener('click', stopPropagation);
            element.click();
            element.focus();
            element.removeEventListener('click', stopPropagation);

        }
    }

    private _getFocusElement(): HTMLInputElement | HTMLTextAreaElement {
        let element: HTMLInputElement | HTMLTextAreaElement;

        ['extended-select', 'input', 'textarea'].some((selector) => {
            element = this._componentRef.location.nativeElement.querySelector(selector);

            if (selector === 'extended-select' && element !== null) {
                element = element.querySelector('.dropdown');
            }

            return element !== null;
        });

        return element;
    }

    private _setDefaultValue(): void {
        const controlValue = this.injector.get(NgControl).control.value;

        if (
            typeof controlValue !== 'undefined'
            && controlValue !== null
            && controlValue !== ''
        ) {
            return;
        }

        if (
            typeof this.fieldConfig.default !== 'undefined'
            && this.fieldConfig.default !== null
            && this.fieldConfig.default !== ''
        ) {
            if (
                this.fieldConfig.type === 'number'
                && typeof this.fieldConfig.default === 'string'
            ) {
                this.fieldConfig.default = parseInt(this.fieldConfig.default);
            }

            this.writeValue(this.fieldConfig.default);
            if (
                controlValue !== this.fieldConfig.default
            ) {
                this.passDefaultToOnChange();
            }
        }
    }

    private passDefaultToOnChange(): void {
        if (typeof this._onChange !== 'function') {
            throw new Error(
                'Use ngModel/FormControl initial value to set default value since'
                + ' default value is not working properly under certain circumstances.'
            );
        }

        this._onChange(this.fieldConfig.default);
    }
}
