import {Pipe, PipeTransform} from '@angular/core';
import {TranslateService} from 'src/modules/translate/translate.service';


@Pipe({name: 'toTime'})
export class ToTimePipe implements PipeTransform {
    constructor(
        private translateService: TranslateService,
    ) {
    }

    transform(
        seconds: number
    ): string {
        const minus = seconds < 0;
        if (minus) {
            seconds *= -1;
        }
        const days = Math.floor(seconds / 86400);
        seconds -= days * 86400;
        let hours: string | number = Math.floor(seconds / 3600);
        seconds -= hours * 3600;
        let minutes: string | number = Math.floor(seconds / 60);
        seconds -= minutes * 60;
        if (days > 0) {
            return (minus ? '-' : '') + days + ' ' + this.translateService.get(days === 1 ? 'DAY' : 'DAYS');
        } else {
            if (minutes > 0 || hours > 0) {
                hours = hours === 0 ? '' : hours + 'h ';
                minutes = minutes === 0 ? '00m ' : (minutes < 10 ? '0' + minutes + 'm ' : minutes + 'm');
                return (minus ? '-' : '') + hours + minutes;
            } else {
                return (minus ? '-' : '') + '00:'
                    + (seconds === 0 ? '00' : (seconds < 10 ? '0' + seconds : seconds)) + 's';
            }
        }
    }
}
