import {EndpointName, EndpointToType} from '_types/rest';
import {AbstractRestCommon} from 'src/modules/rest/objects/abstract-rest-common';
import {Observable} from 'rxjs';
import {Use} from 'src/decorators/use.decorator';
import {IBaseRestEntity} from '_types/rest/IBaseRestEntity';
import {Utils} from 'src/services/utils';
import {IQueryObject} from 'src/services/url-parser.service';

const endpointMap = new WeakMap();

export interface RestObject<TEndpoint extends EndpointName> extends AbstractRestCommon<TEndpoint>, Object {
}

@Use(AbstractRestCommon)
export class RestObject<TEndpoint extends EndpointName,
    TObject = EndpointToType<TEndpoint>> extends Object {
    id?: number;
    '@operations'?: IBaseRestEntity['@operations'];

    private constructor(endpoint: TEndpoint, value: TObject) {
        super();
        Object.entries(value)
            .forEach(([k, v]) => {
                this[k] = v;
            });
        endpointMap.set(this, endpoint);
    }

    static getInstance<T extends EndpointName, U = EndpointToType<T>>(endpoint: T, value: U): IRestObject<T, U> {
        return new RestObject(endpoint, value) as IRestObject<T, U>;
    }

    endpointName(): TEndpoint {
        return endpointMap.get(this);
    }

    clone(): IRestObject<TEndpoint, TObject> {
        return new RestObject<TEndpoint, TObject>(
            this.endpointName(),
            Object.entries(this)
                .reduce((clone, [key, value]) => {
                    clone[key] = Utils.clone(value);
                    return clone;
                }, {} as TObject)
        ) as IRestObject<TEndpoint, TObject>;
    }

    delete(): Observable<void> {
        return this.endpoint().delete(this.id);
    }

    persist(
        query?: IQueryObject
    ): Observable<TObject extends IBaseRestEntity ? IRestObject<TEndpoint, TObject> : TObject> {
        const endpoint = this.endpoint<TObject>(),
            payload = Utils.clone(this).removeFields([], true);

        delete payload.id; // delete only root object id, not the nested ones

        return this.id
            ? endpoint.update(this.id, payload as unknown as TObject, query)
            : endpoint.create(payload as unknown as TObject, query);
    }

    access(operation: string, property?: string): boolean {
        const hasAccessToOperation = typeof this['@operations'] !== 'undefined'
            && typeof this['@operations'][operation] !== 'undefined';

        if (!property) {
            return hasAccessToOperation;
        }

        return hasAccessToOperation && this['@operations'][operation][property];
    }
}

export type IRestObject<T extends EndpointName, U = EndpointToType<T>> = RestObject<T, U> & {
    [P in keyof U]: U[P];
};
