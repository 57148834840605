import {Injectable, Injector} from '@angular/core';
import {
    IPubConfigFront,
    IPubConfigFrontAnalytics,
    IPubConfigFrontCms,
    IPubConfigFrontVisual
} from '_types/pub/config/front';
import {LocalStorage} from 'src/services/local-storage';
import {UIRouter} from '@uirouter/core';
import DateExtended from 'date-extensions';
import {forkJoin, Observable, Subject} from 'rxjs';
import {TranslateService} from 'src/modules/translate/translate.service';
import {MissingTranslationsService} from 'src/modules/translate/missing-translations.service';
import {TableToolsConfigService} from 'angular-bootstrap4-table-tools';
import {ValidateConfigService} from 'angular-bootstrap4-validate';
import {DatePickerConfigService} from 'angular-bootstrap4-datepicker';
import {map, tap} from 'rxjs/operators';
import {ExtendedSelectConfigService} from 'angular-bootstrap4-extended-select';
import {Utils} from 'src/services/utils';
import {CmsService} from 'src/services/cms.service';

@Injectable({
    providedIn: 'root'
})
export class ConfigService {
    private originalCms: IPubConfigFrontCms[];
    private config: IPubConfigFront;
    private localeChangeSubject = new Subject<string>();

    get localeChange$(): Observable<string> {
        return this.localeChangeSubject.asObservable();
    }

    constructor(
        private translateService: TranslateService,
        private missingTranslationsService: MissingTranslationsService,
        private uiRouter: UIRouter,
        private injector: Injector
    ) {
    }

    init(configData: IPubConfigFront): Observable<unknown> {
        this.config = configData;
        this.originalCms = Utils.clone(this.config.CMS);

        return this.updateTranslations(this.get('locale'));
    }

    /**
     * Get config value
     */
    get(item: 'locale'): string;
    get(item: 'timezone'): string;
    get(item: 'visual'): IPubConfigFrontVisual;
    get(item: 'analytics'): IPubConfigFrontAnalytics;
    get(item: 'cms'): IPubConfigFrontCms[];
    get(item: 'app_env'): string;
    get(item: string): unknown;
    get(item: string): unknown {
        return LocalStorage.get(item, this.config[item.toUpperCase()]);
    }

    setTimezone(timezone: string): void {
        LocalStorage.set('timezone', timezone);
    }

    setLocale(localeCode: string): Observable<void> {
        return new Observable<void>((subscriber) => {
            const currentLocale = this.get('locale');
            LocalStorage.set('locale', localeCode);

            if (currentLocale !== localeCode) {
                this.updateTranslations(localeCode).subscribe(() => {
                    subscriber.next();
                    subscriber.complete();
                    this.localeChangeSubject.next(localeCode);
                });
            } else {
                subscriber.next();
                subscriber.complete();
            }
        });
    }

    /**
     * Update translations in all plugins
     */
    private updateTranslations(localeCode: string): Observable<void> {
        this.missingTranslationsService.clearMissingTranslations();
        this.updateCmsPages(localeCode);

        return forkJoin([
            this.translateService.setLanguage(localeCode)
                .pipe(
                    tap(() => {
                        this.updateValidateTranslations();
                        this.updateTableToolsTranslations();
                        this.updateExtendedSelectTranslations();
                    })
                ),
            this.updateDateExtendedTranslations(localeCode)
        ]).pipe(
            map(() => undefined)
        );
    }

    private updateCmsPages(localeCode: string): void {
        const cms = {};
        if (typeof this.originalCms !== 'undefined') {
            this.originalCms.forEach((item) => {
                if (
                    !(item.cmsType in cms)
                    || item.locale === localeCode
                    || (item.locale === this.config['LOCALE'] && cms[item.cmsType].locale !== localeCode)
                ) {
                    cms[item.cmsType] = item;
                }
            });
        }

        if (this.config.CMS) {
            this.config.CMS.length = 0;
            Object.assign(
                this.config.CMS,
                Utils.clone(Object.keys(cms).map((i) => {
                    return cms[i];
                }))
            );

            this.injector.get(CmsService).setCmsStates(this.config.CMS);
        }
    }

    private updateDateExtendedTranslations(localeCode: string): Observable<void> {
        if (localeCode === 'en') {
            localeCode = 'en-us';
        }

        return new Observable<void>((subscriber) => {
            import(`date-extensions/dist/i18n/${localeCode}.js`)
                // use finally instead of then because date-extensions i18n files does not provide any exports
                .finally(() => {
                    try {
                        DateExtended.setDefaultLocale(localeCode);
                        this.injector.get(DatePickerConfigService).updateDateTranslations();
                    } finally {
                        subscriber.next();
                        subscriber.complete();
                    }
                });
        });
    }

    private updateValidateTranslations(): void {
        const messages = this.injector.get(ValidateConfigService).errorMessages;
        messages.date = '';
        Object.keys(messages).forEach((validator) => {
            messages[validator] = this.translateService.get(
                `VALIDATOR_${validator.replace(/^validate/, '').toUpperCase()}_MESSAGE`
            );
        });
    }

    private updateTableToolsTranslations(): void {
        const tableToolsConfig = this.injector.get(TableToolsConfigService);
        Object.entries(tableToolsConfig.lang).forEach(([key]) => {
            tableToolsConfig.lang[key] = this.translateService.get(
                `TABLE_${key.replace(/([A-Z])/g, '_$1').toUpperCase()}`
            );
        });
        tableToolsConfig.exportTypes.copy.lang = tableToolsConfig.lang.copy;
        tableToolsConfig.exportTypes.csv.lang = tableToolsConfig.lang.csv;
    }

    private updateExtendedSelectTranslations(): void {
        const extendedSelectConfig = this.injector.get(ExtendedSelectConfigService);
        extendedSelectConfig.typeToSearchText = this.translateService.get('SELECT_TYPE_TO_SEARCH');
        extendedSelectConfig.addOptionLang = this.translateService.get('SELECT_ADD_OPTION');
        extendedSelectConfig.placeholder = this.translateService.get('SELECT_OPTION');
        extendedSelectConfig.placeholderMultiple = this.translateService.get('SELECT_OPTIONS');
        extendedSelectConfig.loadMoreResultsLang = this.translateService.get('LOAD_MORE_RESULTS');
    }
}
