<div class="custom-control"
     [ngClass]="[customControlClass, isDisabled ? 'disabled' : '']">
    <input type="checkbox" class="custom-control-input" [attr.id]="id"
           (change)="_onChange($event.target['checked'])"
           (blur)="onTouched()"
           #inputElement/>
    <label class="custom-control-label" [attr.for]="id">
        {{fieldConfig.name}}
    </label>
</div>
