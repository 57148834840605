import {Injectable} from '@angular/core';
import {SortableDirective, SortableObject} from 'src/modules/sortable/sortable.directive';
import {SortableItemDirective} from 'src/modules/sortable/sortable-item.directive';

@Injectable({
    providedIn: 'root'
})
export class SortableService {
    currentDragItem: SortableItemDirective;
    baseDragItem: SortableItemDirective;
    targetSortable: SortableDirective;
    targetItem: SortableItemDirective;

    constructor() {
        this.clear();
    }

    setCurrentDragItem(sortableItem: SortableItemDirective): void {
        this.currentDragItem = sortableItem;
    }

    setBaseDragItem(sortableItem: SortableItemDirective): void {
        this.baseDragItem = sortableItem;
    }

    setTargetSortable(targetSortable: SortableDirective): void {
        this.targetSortable = targetSortable;
    }

    setTargetItem(targetController: SortableItemDirective): void {
        if (targetController !== this.targetItem) {
            if (typeof targetController !== 'undefined') {
                this.setTargetSortable(targetController.sortable);
            } else {
                this.setTargetSortable(undefined);
            }
            this.targetItem = targetController;
        }
    }

    clear(): void {
        this.currentDragItem = undefined;
        this.baseDragItem = undefined;
        this.targetItem = undefined;
        this.targetSortable = undefined;
    }

    isAllowedToDrop(): boolean {
        if (typeof this.targetSortable === 'undefined') {
            return false;
        }

        return (this.targetSortable === this.currentDragItem.sortable
                && (
                    typeof this.targetItem === 'undefined'
                    || this.targetItem.sortable === this.currentDragItem.sortable
                ))
            || (typeof this.targetSortable.sortableGroup !== 'undefined'
                && typeof this.currentDragItem.sortable.sortableGroup !== 'undefined'
                && this.targetSortable.sortableGroup === this.currentDragItem.sortable.sortableGroup);
    }

    isTargetEqualDropItem(): boolean {
        return typeof this.targetItem !== 'undefined'
            && (this.targetItem.sortable === this.targetSortable && this.targetItem === this.currentDragItem);
    }

    reorder(collection: SortableObject[]): SortableObject[] {
        const min = Math.min(1,
            collection.reduce((min, item) => (item.order < min ? item.order : min), collection[0].order));

        collection.reduce((order, item, index, array) => {
            array[index].order = order;
            return order + 1;
        }, min);
        return collection;
    }

    checkAndSetOrder(): void {
        if (
            typeof this.currentDragItem.sortable.collection[0] !== 'undefined'
            && typeof this.currentDragItem.sortable.collection[0].order !== 'undefined'
        ) {
            this.currentDragItem.sortable.collection = this.reorder(this.currentDragItem.sortable.collection);
        }

        if (this.targetSortable === this.currentDragItem.sortable) {
            return;
        }

        if (
            typeof this.targetSortable.collection[0] !== 'undefined'
            && typeof this.targetSortable.collection[0].order !== 'undefined'
        ) {
            this.targetSortable.collection = this.reorder(this.targetSortable.collection);
        }
    }

    removeFromDragCollection(item: SortableItemDirective): void {
        const index = item.sortable.collection.indexOf(item.sortableItem);
        if (index > -1) {
            item.sortable.collection.splice(index, 1);
        }
    }

    insertIntoTargetCollection(insertIndex: number): void {
        const newCollectionTarget = this.targetSortable.collection;

        newCollectionTarget.splice(insertIndex, 0,
            this.currentDragItem.sortableItem);

        this.targetSortable.collection = newCollectionTarget;
    }

    apply(): void {
        this.removeFromDragCollection(this.currentDragItem);

        if (typeof this.baseDragItem !== 'undefined') {
            this.removeFromDragCollection(this.baseDragItem);
        }

        const newCollectionTarget = this.targetSortable.collection;
        let insertIndex = 0;

        if (typeof this.targetItem !== 'undefined') {
            const index = newCollectionTarget.findIndex((item) => this.targetItem.sortableItem === item);

            insertIndex = this.targetItem.insertBefore ? index : index + 1;
        }

        this.insertIntoTargetCollection(insertIndex);

        this.checkAndSetOrder();
    }

    refreshScopes(): void {
        if (typeof this.targetSortable !== 'undefined') {
            this.targetSortable.changeDetector.detectChanges();

            if (this.currentDragItem.sortable !== this.targetSortable) {
                this.currentDragItem.sortable.changeDetector.detectChanges();
            }
        }
    }
}
