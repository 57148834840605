import {
    Directive,
    ViewContainerRef
} from '@angular/core';

@Directive({
    selector: '[dynamicFieldHost]',
})
export class DynamicFieldHostDirective {
    constructor(
        public viewContainerRef: ViewContainerRef,
    ) {
    }
}
