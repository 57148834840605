import {transition, trigger, useAnimation} from '@angular/animations';
import {fadeInUpAnimation} from 'src/animations/fade-in-up.animation';
import {fadeOutDownAnimation} from 'src/animations/fade-out-down.animation';

export const RodoCookiesAnimations = [
    trigger('showHideCookies', [
        transition(':enter', [
            useAnimation(fadeInUpAnimation, {
                params: {
                    time: '0.3s ease-in'
                }
            })
        ]),
        transition(':leave', [
            useAnimation(fadeOutDownAnimation, {
                params: {
                    time: '0.3s ease-in'
                }
            })
        ])
    ])
];
