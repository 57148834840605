import {Injector} from '@angular/core';
import {AbstractOperationDefinition} from 'src/modules/operations/interfaces';
import {StateService} from '@uirouter/core';
import {IRestObject} from 'src/modules/rest/objects';
import {AccessPipe} from 'src/pipes/access.pipe';
import {AbstractDeleteOperation} from 'view-modules/operations/abstract/abstract-delete.operation';

export class AddReport extends AbstractOperationDefinition<'reports'> {
    readonly endpoint = 'reports'
    readonly name = 'post';
    lang = 'ADD';
    icon = 'fa-plus';

    invoke = (
        context: IRestObject<'reports'>,
        injector: Injector
    ): void => {
        const state = injector.get(StateService);
        state.go('admin.reports.add');
    };

    access(context: IRestObject<'reports'>): boolean {
        if (!context || typeof context.access !== 'function') {
            return false;
        }
        return new AccessPipe().transform(context, this.name);
    }
}

export class EditReport extends AbstractOperationDefinition<'reports'> {
    readonly endpoint = 'reports'
    readonly name = 'put';
    lang = 'EDIT';
    icon = 'fa-pencil-alt';

    invoke = (
        context: IRestObject<'reports'>,
        injector: Injector
    ): void => {
        const state = injector.get(StateService);
        state.go('admin.reports.edit', {reportId: context.id});
    };

    access(context: IRestObject<'reports'>): boolean {
        return new AccessPipe().transform(context, this.name);
    }
}

export class DeleteReport extends AbstractDeleteOperation<'reports'> {
    readonly endpoint = 'reports';
    confirmMessage = 'ADMIN_REPORTS_REPORT_REMOVE_CONFIRM';
    lang = 'DELETE'
}

export const reportOperations = [
    AddReport,
    EditReport,
    DeleteReport
] as const;
