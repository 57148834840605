import {Inject, Injectable} from '@angular/core';
import {FontService} from 'src/services/font.service';
import {ThemeService} from 'src/services/theme.service';
import {CoreModalConfigService} from 'view-modules/core/config/core-modal-config.service';
import {UserService} from 'src/modules/rest/user/user.service';
import {AnalyticsTrackersService} from 'src/modules/analytics/analytics-trackers.service';
import {ENV, IEnvironment} from 'app-custom-providers';
import {StateDeclaration, StateService, Transition, TransitionService} from '@uirouter/core';
import {ConfigService} from 'src/services/config.service';
import {TitleService} from 'src/services/title.service';
import {AppStateDeclaration} from 'src/modules/route-helper/interfaces';
import {RouteHelper} from 'src/modules/route-helper/route-helper.service';
import {TranslateService} from 'src/modules/translate/translate.service';
import {DatePickerConfigService} from 'angular-bootstrap4-datepicker';

@Injectable({
    providedIn: 'root'
})
export class CoreConfigService {
    constructor(
        private fontService: FontService,
        private themeService: ThemeService,
        private coreModalConfig: CoreModalConfigService,
        private userService: UserService,
        private analyticsTrackersService: AnalyticsTrackersService,
        private transitionService: TransitionService,
        private stateService: StateService,
        private configService: ConfigService,
        private titleService: TitleService,
        private translateService: TranslateService,
        private datePickerConfigService: DatePickerConfigService,
        @Inject(ENV) private env: IEnvironment
    ) {
    }

    init(): void {
        if (this.env.APP.production) {
            this.analyticsTrackersService.configTrackers();
        }

        this.userService.commonRoutePrivileges = [
            '', 'default', 'home', 'user', 'cms', 'dev', 'error', 'dynamicPage'
        ];
        this.themeService.setDefaultTheme('semi-light');
        this.fontService.setGoogleFonts([
            'Open Sans', 'Roboto', 'Lato', 'Montserrat', 'Oswald', 'Assistant', 'Source Sans Pro'
        ]);
        this.fontService.setSystemFonts([
            'Helvetica', 'Arial'
        ]);
        this.fontService.setAppFonts([
            'Futura Com'
        ]);
        this.fontService.setAvailableFontSizes(['300', '400', '400i', '600', '700']);
        this.fontService.setFontFamilyFallback(
            '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif'
        );
        this.datePickerConfigService.hideOnPick = true;

        this.coreModalConfig.initGlobalModalConfig();

        this.routerTransitionsConfig();
    }

    private routerTransitionsConfig(): void {
        this.transitionService.onSuccess({}, (transition: Transition) => {
            const transitionTo = transition.to() as AppStateDeclaration,
                {appName} = this.configService.get('visual'),
                subpageTranslationKey = transitionTo.lang || RouteHelper.getStateLanguageKey(transitionTo.name);

            this.titleService.setTitle(
                '',
                this.translateService.get(subpageTranslationKey),
                appName
            );
        });
        // disable state retain when going back to parent state (forces view reloading)
        this.transitionService.onRetain({}, (transition: Transition, state: StateDeclaration) => {
            const targetState = transition.targetState();

            if (
                state.name === targetState.name() // this view state is being reloaded
                && transition.from().name !== state.name // and transition is coming from another state
            ) {
                return this.stateService.target(state, targetState.params(), {reload: true});
            }
        });
    }
}
