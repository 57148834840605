/**
 * This file has been auto generated. Do not edit.
 */
import {IPartner} from '_types/rest/Entity/IRestPartner';
import {IMenuElement} from '_types/rest/Entity/IRestMenuElement';
import {IBaseRestEntity} from '_types/rest/IBaseRestEntity';

export const MenuType = {
    TYPE_MAIN_MENU: 1
} as const;

export const MenuTypeOptions = [
    {
        value: MenuType.TYPE_MAIN_MENU,
        label: 'TYPE_MAIN_MENU',
    }
] as const;

export interface IMenuConst {
    type: [{id: 1, value: 'TYPE_MAIN_MENU'}]
}

export interface IMenu extends IBaseRestEntity {
    id?: number,
    partner?: IPartner | string | null,
    /**
     * @default 1
     */
    type: typeof MenuType[keyof typeof MenuType],
    elements?: (IMenuElement | string)[],
    /**
     * @description Record status
     *  0 = deleted
     *  1 = active.
     * @default 1
     */
    status?: number,
    /**
     * @description Creation timestamp.
     * @default CURRENT_TIMESTAMP
     * @format date-time
     */
    created?: string,
    /**
     * @description Last modification timestamp.
     * @format date-time
     */
    modified?: string | null
}
