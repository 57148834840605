import {Inject, Injectable} from '@angular/core';
import {Observable, of, share} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {ENV, IEnvironment} from 'app-custom-providers';
import {map, tap} from 'rxjs/operators';
import {AVAILABLE_FA_ICON_STYLES} from '../../interfaces';


export interface IIconConfig {
    changes: string[];
    label: string;
    search: {
        terms: string[];
    }
    styles: string[];
    styleClass: typeof AVAILABLE_FA_ICON_STYLES[keyof typeof AVAILABLE_FA_ICON_STYLES];
    unicode: string;
    voted: boolean;
    className: string;
}


@Injectable({
    providedIn: 'root'
})
export class IconPickerService {
    private readonly ICON_PATH: string;

    private iconList: IIconConfig[];
    private iconList$: Observable<IIconConfig[]>;

    constructor(
        private http: HttpClient,
        @Inject(ENV) env: IEnvironment
    ) {
        this.ICON_PATH = 'fontawesome/icons-list.json' + (env.APP.production ? '?' + env.APP.build : '');
    }

    getIcons(): Observable<IIconConfig[]> {
        if (this.iconList) {
            return of(this.iconList);
        }

        if (this.iconList$) {
            return this.iconList$;
        }

        this.iconList$ = this.http.get<Record<string, IIconConfig>>(this.ICON_PATH)
            .pipe(
                map((iconObject) => {
                    const availableIcons =  Object.entries(iconObject)
                        .map(([className, config]) => {
                            return {
                                ...config,
                                className: 'fa-' + className
                            };
                        })
                        .filter((iconConfig) => {
                            const availableStyleNames = Object.keys(AVAILABLE_FA_ICON_STYLES);

                            iconConfig.styles = iconConfig.styles.filter((styleName) => {
                                return availableStyleNames.includes(styleName);
                            });

                            return iconConfig.styles.length;
                        });

                    this.divideIconsBasedOnStyles(availableIcons);

                    return availableIcons;
                }),
                tap((iconList) => {
                    this.iconList = iconList;
                }),
                share()
            );
        return this.iconList$;
    }

    private divideIconsBasedOnStyles(icons: IIconConfig[]): void {
        const additionalIcons = [];
        icons.forEach((config, configIndex) => {
            config.styles.forEach((styleName, styleIndex) => {
                if (styleIndex === 0) {
                    config.styleClass = AVAILABLE_FA_ICON_STYLES[styleName];
                    return;
                }

                additionalIcons.push({
                    config: {
                        ...config,
                        styleClass: AVAILABLE_FA_ICON_STYLES[styleName]
                    },
                    index: configIndex
                });
            });
        });

        additionalIcons.forEach((additionalIcon, index) => {
            icons.splice(additionalIcon.index + index, 0, additionalIcon.config);
        });
    }
}
