import {Pipe, PipeTransform} from '@angular/core';
import {AbstractTranslatePipe} from 'src/modules/translate/abstract-translate.pipe';
import {TranslateService} from './translate.service';

@Pipe({
    name: 'translate'
})
export class TranslatePipe extends AbstractTranslatePipe implements PipeTransform {
    constructor(
        protected translateService: TranslateService
    ) {
        super();
    }
}

