import {Inject, Injectable, Injector} from '@angular/core';
import {StateService} from '@uirouter/core';
import {noop, Subject, Subscription} from 'rxjs';
import {ENV, IEnvironment} from 'app-custom-providers';
import {bufferTime} from 'rxjs/operators';
import {LocalStorage} from 'src/services/local-storage';
import {UserService} from 'src/modules/rest/user/user.service';
import {RestClient} from 'src/modules/rest/rest-client.service';

export interface IMissingTranslation {
    name: string;
    moduleName: string;
}

@Injectable({
    providedIn: 'root'
})
export class MissingTranslationsService {
    private missingTranslations: IMissingTranslation[] = [];
    private missingTranslationsSubject = new Subject<IMissingTranslation>();
    private missingTranslationsSubscription: Subscription;
    private APP: IEnvironment['APP'];

    constructor(
        private stateService: StateService,
        private restClient: RestClient,
        @Inject(ENV) env: IEnvironment,
        private injector: Injector,
    ) {
        this.APP = env.APP;
    }


    init(): void {

        this.missingTranslationsSubscription = this.missingTranslationsSubject.pipe(
            bufferTime(3000)
        ).subscribe((data: IMissingTranslation[]) => {
            if (data.length > 0) {
                const toSend = (LocalStorage.get('missingTranslations', []) as IMissingTranslation[])
                    .concat(data)
                    .filter((item, index, array) => {
                        return array.findIndex((subItem) => {
                            return subItem.name === item.name;
                        }) === index;
                    });
                if (!toSend.length) {
                    return;
                }
                if (this.injector.get(UserService).login.isAuthorized()) {
                    LocalStorage.drop('missingTranslations');
                    this.restClient.endpoint<string, IMissingTranslation[]>('translations/missing')
                        .create(toSend).subscribe({next:noop,error:noop});
                } else {
                    LocalStorage.set('missingTranslations', toSend);
                }
            }
        });
    }

    clearHandlerInterval(): void {
        this.missingTranslationsSubscription.unsubscribe();
    }

    clearMissingTranslations(): void {
        this.missingTranslations.length = 0;
    }

    getMissingTranslations(): IMissingTranslation[] {
        return this.missingTranslations;
    }

    addMissingTranslation(translationKey: string): void {
        if (typeof translationKey === 'undefined') {
            return;
        }

        const translation = this.missingTranslations.find((translation) => {
            return translation.name === translationKey;
        });
        let currentModule, state = this.stateService.$current;
        if (this.APP.current === 'private') {
            while (state.name !== '' && state.name !== null) {
                currentModule = state.name;
                state = state.parent;
            }
        }

        if (typeof translation === 'undefined') {
            const missingTranslation: IMissingTranslation = {
                name: translationKey,
                moduleName: currentModule || 'common'
            };

            this.missingTranslations.push(missingTranslation);

            this.missingTranslationsSubject.next(missingTranslation);
        } else if (translation.moduleName !== currentModule) {
            translation.moduleName = typeof translation.moduleName === 'undefined' ? currentModule : 'common';
        }
    }
}
