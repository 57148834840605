<div [(bsDropdown)]="isDropdownOpened"
     (bsDropdownChange)="handleDropdownChange($event)">
    <div class="input-with-icon-container">
        <i class="far fa-clock"></i>
        <input #timeInput
               bsDropdownToggle
               (blur)="handleBlur($event)"
               [(ngModel)]="displayedTimeValue"
               (ngModelChange)="updateForm($event)"
               [disabled]="isDisabled"
               class="form-control input-with-icon"
               [ngClass]="{'bg-white': !isDisabled}"
               [placeholder]="'TIME' | translate"
               type="text"
        />
    </div>
    <div class="dropdown-menu dropdown-menu-right p-3"
         #dropdownMenu
         [formGroup]="durationPickerForm">
        <div class="d-flex">
            <div class="w-50 mr-2">
                <dynamic-field formControlName="hours"
                               [fieldConfig]="{
                                   type: 'numberShuttle',
                                   numberLabel: dateUtils.HOURS_LABEL,
                                   validators: {
                                       min: 0
                                   }
                               }">
                </dynamic-field>
            </div>
            <div class="w-50">
                <dynamic-field formControlName="minutes"
                               [fieldConfig]="{
                                   type: 'numberShuttle',
                                   numberLabel: dateUtils.MINUTES_LABEL,
                                   validators: {
                                       min: 0,
                                       max: 59
                                   }
                               }">
                </dynamic-field>
            </div>
        </div>

        <div *ngIf="durationPickerForm.errors?.atLeastOneShouldBeNotEmpty" class="invalid-feedback d-block">
            {{'AT_LEAST_ONE_SHOULD_BE_NOT_EMPTY' | translate}}!
        </div>

        <div class="d-flex justify-content-end mt-3">
            <button type="button"
                    class="btn ml-2"
                    [attr.aria-label]="'CANCEL' | translate"
                    (click)="isDropdownOpened = false">
                {{'CANCEL' | translate}}
            </button>
            <button type="button"
                    class="btn btn-primary"
                    [attr.aria-label]="'APPLY' | translate"
                    (click)="submitTimePicked()"
                    [disabled]="durationPickerForm.invalid">
                {{'APPLY' | translate}}
            </button>
        </div>
    </div>
</div>

