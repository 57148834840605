import {NgModule} from '@angular/core';
import {ComponentRendererComponent} from 'src/components/component-renderer/component-renderer.component';


@NgModule({
    declarations: [
        ComponentRendererComponent
    ],
    exports: [
        ComponentRendererComponent
    ]
})
export class ComponentRendererModule {
}
