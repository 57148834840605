import {Injectable} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import { IFile } from '_types/rest';
import {boundTask} from './task-view.component';

export type ITaskViewModelServiceTask = Omit<Partial<boundTask>, 'company'> & {
    company?: string,
}

export type ITaskViewServiceTask =
    {
        taskModel: ITaskViewModelServiceTask,
        files: IFile[]
    };

@Injectable({
    providedIn: 'root'
})
export class TaskViewService {
    private newTaskSubject = new Subject<ITaskViewServiceTask>();

    getNewTask$(): Observable<ITaskViewServiceTask> {
        return this.newTaskSubject.asObservable();
    }

    /**
     * Forced typing is required - input and output DTOs are different.
     */
    addNewTask(taskInput: ITaskViewServiceTask): void {
        this.newTaskSubject.next(taskInput);
    }
}
