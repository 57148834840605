import './sortable.directive.scss';
import {Directive, Output, EventEmitter, HostListener, Input, ChangeDetectorRef, ElementRef} from '@angular/core';
import {SortableItemDirective} from 'src/modules/sortable/sortable-item.directive';
import {SortableService} from 'src/modules/sortable/sortable.service';

export type SortableObject = unknown & { order?: number };

export interface SortStopEvent<T extends SortableObject = SortableObject> {
    dragItem: SortableItemDirective<T>;
    collection: T[];
}

export interface DragStartEvent<T extends SortableObject = SortableObject> extends DragEvent {
    dragItem: SortableItemDirective<T>
}

export interface DragEndEvent<T extends SortableObject = SortableObject> {
    dragItem: SortableItemDirective<T>;
    baseDragItem: SortableItemDirective<T>;
    targetItem: SortableItemDirective<T>;
}

export interface DragOverEvent<T extends SortableObject = SortableObject> {
    dragItem: SortableItemDirective<T>;
    targetItem: SortableItemDirective<T>;
}

@Directive({
    selector: '[sortable]'
})
export class SortableDirective<T extends SortableObject = SortableObject> {
    @Input('sortable') collection: SortableObject[];
    sortableItems: SortableItemDirective<T>[] = [];
    @Input() sortableGroup: string;
    @Input() sortableDirection: 'vertical' | 'horizontal' = 'vertical';
    @Input('sortableEnabled') enabled = true;
    @Output() readonly dragStart = new EventEmitter<DragStartEvent>();
    @Output() readonly sortStop = new EventEmitter<SortStopEvent>();
    @Output() readonly dragOver = new EventEmitter<DragOverEvent>();
    @Output() readonly dragEnd = new EventEmitter<DragEndEvent>();
    element: HTMLElement;

    constructor(
        private sortableService: SortableService,
        public changeDetector: ChangeDetectorRef,
        elRef: ElementRef
    ) {
        this.element = elRef.nativeElement;
    }

    @HostListener('dragover', ['$event'])
    handleDragOver($event: DragEvent): void {
        if (!this.enabled) {
            $event.preventDefault();
            return;
        }
        this.sortableService.setTargetSortable(this);

        if (this.sortableService.isAllowedToDrop()) {
            $event.preventDefault();
            if (!this.collection.includes(this.sortableService.currentDragItem.sortableItem)) {
                this.sortableService.currentDragItem.element.style.display = 'none';
                this.sortableService.insertIntoTargetCollection(0);
                this.sortableService.refreshScopes();

                const newDragItem = this.sortableItems.find(
                    (i) => i.sortableItem === this.sortableService.currentDragItem.sortableItem
                );

                this.dragStart.emit(Object.assign($event, {dragItem: newDragItem}));
                newDragItem.dragging = true;
                this.sortableService.setBaseDragItem(this.sortableService.currentDragItem);
                this.sortableService.setCurrentDragItem(newDragItem);
            }
        }
    }

    addSortableItem(field: SortableItemDirective<T>): void {
        this.sortableItems.push(field);
    }

    removeSortableItem(field: SortableItemDirective<T>): void {
        this.sortableItems = this.sortableItems.filter((fieldFind) => {
            return fieldFind !== field;
        });
    }

}
