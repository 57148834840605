import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {
    ICompany,
    ICompanyContactPhone,
    IDepartmentRole,
    IUserLoginDepartment, IUserLoginSummaryOutputDto,
    With
} from '_types/rest';
import {finalize, map, tap} from 'rxjs/operators';
import {AvatarUser} from 'src/modules/avatar/avatar.component';
import {RestClient} from 'src/modules/rest/rest-client.service';
import {IAbsenceWithReplacements} from 'src/modules/absences/interfaces';


type boundUserSummary = With<IUserLoginSummaryOutputDto, 'userLoginDepartments', {
    userLoginDepartments: With<IUserLoginDepartment, 'department'>[],
    absences: IAbsenceWithReplacements[]
}>

interface IUserDetailsModel {
    name?: string,
    firstName?: string,
    lastName?: string,
    phone?: string,
    email?: string,
    userLoginDepartments?: With<IUserLoginDepartment, 'department'>[];
    departmentRole?: IDepartmentRole;
    absences?: IAbsenceWithReplacements[];
}

@Component({
    selector: 'avatar-details',
    templateUrl: './avatar-details.component.html',
    styleUrls: ['./avatar-details.component.scss']
})
export class AvatarDetailsComponent implements OnChanges {
    @Input() user: AvatarUser & {
        firstName?: string,
        lastName?: string,
        phone?: string,
        email?: string,
        name?: string
    };
    @Input() loadAsyncUserDetails? = false;
    @Input() company: ICompany;
    @Input() companyContactPhone?: ICompanyContactPhone;

    popover = false;

    userSummary: IUserLoginSummaryOutputDto;
    userDetailsModel?: IUserDetailsModel = {};

    loadingUserSummary = false;

    constructor(
        private readonly restClient: RestClient,
    ) {
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (typeof changes['user'] === 'undefined') {
            return;
        }

        if (!this.user) {
            return;
        }

        this.syncUserDetailsModelWithUserInput();
    }


    private syncUserDetailsModelWithUserInput(): void {
        if ('userLoginDepartments' in this.user && typeof this.user.userLoginDepartments[0] !== 'string') {
            this.userDetailsModel.userLoginDepartments
                = this.user.userLoginDepartments as With<IUserLoginDepartment, 'department'>[];
        }

        if ('departmentRole' in this.user && typeof this.user.departmentRole !== 'string') {
            this.userDetailsModel.departmentRole = this.user.departmentRole;
        }

        if ('firstName' in this.user) {
            this.userDetailsModel.firstName = this.user.firstName;
        }

        if ('lastName' in this.user) {
            this.userDetailsModel.lastName = this.user.lastName;
        }

        if ('name' in this.user) {
            this.userDetailsModel.name = this.user.name;
        }

        if ('email' in this.user) {
            this.userDetailsModel.email = this.user.email;
        }

        if ('phone' in this.user) {
            this.userDetailsModel.phone = this.user.phone;
        }
    }

    mouseover(): void {
        if (this.popover) {
            return;
        }

        if (this.loadAsyncUserDetails && !this.userSummary) {
            this.loadUserSummary();
        }

        this.popover = true;
    }

    mouseleave(): void {
        if (!this.popover) {
            return;
        }

        this.popover = false;
    }

    private loadUserSummary(): void {
        if (!this.user || !('id' in this.user)) {
            return;
        }

        this.loadingUserSummary = true;

        const query = {
            with: ['absences'],
            weeksAbsencesFind: 2,
        };
        this.restClient
            .endpoint<'user_logins/summary', boundUserSummary>('user_logins/summary')
            .get(this.user.id, query)
            .pipe(
                tap((userSummary) => {
                    this.userSummary = userSummary;
                }),
                map((userSummary) => {
                    return this.mapUserSummaryToUserDetailsModel(userSummary);
                }),
                finalize(() => {
                    this.loadingUserSummary = false;
                })
            )
            .subscribe((userSummary) => {
                this.userDetailsModel = userSummary;
            });
    }

    private mapUserSummaryToUserDetailsModel(userSummary: boundUserSummary): IUserDetailsModel {
        return {
            firstName: userSummary.firstName,
            lastName: userSummary.lastName,
            phone: userSummary.phone,
            email: userSummary.email,
            userLoginDepartments: userSummary.userLoginDepartments,
            departmentRole: userSummary.departmentRole,
            absences: userSummary.absences
        };
    }
}
