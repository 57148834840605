<div class="modal modal-confirm fade" [(bsModal)]="confirmModal.isOpen" [backdrop]="false">
    <div class="modal-dialog modal-dialog-centered" *ngIf="confirmModal.currentModal">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title" [innerHTML]="confirmModal.currentModal.message | unsafe">
                </h4>
            </div>
            <div *ngIf="confirmModal.currentModal.options?.text" class="modal-body" [innerHTML]="confirmModal.currentModal.options.text"></div>
            <div class="modal-footer">
                <button type="button" class="btn btn-link"
                        (click)="confirmModal._response(false)"
                        [disabled]="confirmModal.currentModal.disabled">
                    {{confirmModal.currentModal.options?.langNo || ('CANCEL' | translate)}}
                </button>
                <button type="button" class="btn"
                        (click)="confirmModal._response(true)"
                        [ngClass]="'btn-' + (confirmModal.currentModal.options?.primaryBtn || 'success')"
                        [disabled]="confirmModal.currentModal.disabled">
                    {{confirmModal.currentModal.options?.langYes || ('YES' | translate)}}
                </button>
            </div>
        </div>
    </div>
</div>
