import {AbstractOperationDefinition} from 'src/modules/operations/interfaces';
import {AbstractDeleteOperation} from 'view-modules/operations/abstract/abstract-delete.operation';
import {IConfirmModalOptions} from 'src/modules/global-components/confirm-modal/confirm-modal.service';

export class CompanyContactTypeAddOperation extends AbstractOperationDefinition<'company_contact_types'> {
    readonly endpoint = 'company_contact_types';
    readonly name = 'post';
    lang = 'COMPANY_CONTACT_TYPES_ADD';
    icon = 'fa-plus';
    component = (): Promise<unknown> => import('./company-contact-type-form/company-contact-type-form.component');
}

export class CompanyContactTypeEditOperation extends AbstractOperationDefinition<'company_contact_types'> {
    readonly endpoint = 'company_contact_types';
    readonly name = 'put';
    lang = 'COMPANY_CONTACT_TYPES_EDIT';
    icon = 'fa-edit';
    component = (): Promise<unknown> => import('./company-contact-type-form/company-contact-type-form.component');
}

export class CompanyContactTypeDeleteOperation extends AbstractDeleteOperation<'company_contact_types'> {
    readonly endpoint = 'company_contact_types';
    lang = 'DELETE';
    confirmMessage = 'DELETE_COMPANY_CONTACT_TYPES_CONFIRM';
    confirmOptions: IConfirmModalOptions = {
        langYes: 'DELETE_COMPANY_CONTACT_TYPES_CONFIRM_LANG_YES',
        text: 'ACTION_CANNOT_BE_UNDONE',
        primaryBtn: 'danger'
    };
}

export const companyContactTypeOperations = [
    CompanyContactTypeAddOperation,
    CompanyContactTypeEditOperation,
    CompanyContactTypeDeleteOperation
] as const;
