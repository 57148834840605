import {Component, Input, OnChanges, OnDestroy, OnInit, Renderer2, SimpleChanges} from '@angular/core';
import {Comparison} from 'src/services/comparison';
import {WysiwygContentService} from 'src/modules/wysiwyg-editor/wysiwyg-content/wysiwyg-content.service';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';


@Component({
    selector: 'wysiwyg-content',
    templateUrl: './wysiwyg-content.component.html',
    styleUrls: ['./wysiwyg-content.component.scss']
})
export class WysiwygContentComponent implements OnInit, OnDestroy, OnChanges {
    @Input() content: string;
    @Input() markDisabledMentions = false;

    parsedContent: string;

    private _destroy$ = new Subject<void>();

    constructor(
        private readonly renderer: Renderer2,
        private readonly wysiwygContentService: WysiwygContentService
    ) {
    }

    ngOnInit(): void {
        this.wysiwygContentService.activeUsers$
            .pipe(takeUntil(this._destroy$))
            .subscribe(() => {
                this.parseContent();
            });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.content) {
            this.parseContent();
        }
    }

    private parseContent(): void {
        const contentDOM = this.renderer.createElement('div');
        contentDOM.innerHTML = this.content;

        if (this.markDisabledMentions) {
            this.checkDisabledMentions(contentDOM);
        }

        this.parsedContent = contentDOM.innerHTML;
    }

    private checkDisabledMentions(contentDOM: HTMLElement): void {
        const mentions = contentDOM.querySelectorAll('span[data-user]'),
            activeUsers = this.wysiwygContentService.getActiveUsers();

        mentions.forEach((mention) => {
            const userId = Number(mention.getAttribute('data-user'));
            if (
                !activeUsers.find(Comparison.byId(userId))
            ) {
                mention.classList.add('disabled-mention');
            }
        });
    }

    ngOnDestroy(): void {
        this._destroy$.next();
        this._destroy$.complete();
    }
}
