import {Component, Input, OnDestroy, OnInit, TemplateRef} from '@angular/core';
import {Observable, of, Subject, switchMap} from 'rxjs';
import {debounceTime, map, takeUntil, tap} from 'rxjs/operators';
import {EditorView} from 'prosemirror-view';
import {AppEditorSchema} from 'src/modules/wysiwyg-editor/configs/app-schema';
import {RestClient} from 'src/modules/rest/rest-client.service';
import {IRestCollection} from 'src/modules/rest/objects';
import {IParsedHydra} from 'src/modules/rest/hydra';
import {RelationSelectService} from 'src/modules/dynamic-fields/controls/relation-select/relation-select.service';
import {IEntityTemplateContext} from 'src/modules/dynamic-fields/controls/relation-select/entity-templates.component';
import {IRelationSelectOption} from 'src/modules/dynamic-fields/controls/relation-select/relation-select.component';
import {IUserLogin} from '_types/rest';
import {IMentionStateValue, MENTION_EVENT_TYPE} from 'src/modules/wysiwyg-editor/plugins/mention-factory/interfaces';
import {MentionsService} from 'src/modules/wysiwyg-editor/plugins/mention-factory/mentions.service';


@Component({
    selector: 'mention-user-list',
    templateUrl: './mention-user-list.component.html',
    styleUrls: ['./mention-user-list.component.scss']
})
export class MentionUserListComponent implements OnInit, OnDestroy {
    @Input() view$: Observable<EditorView>;

    open = false;
    view: EditorView;
    options: IRelationSelectOption<IUserLogin>[];
    selectedOptionIndex = 0;
    loading = true;
    state: IMentionStateValue;

    beforeTemplate: TemplateRef<IEntityTemplateContext>;

    private alreadyClosed = false;
    private defaultOptions: IRelationSelectOption<IUserLogin>[] = [];
    private resolveSearchSubject = new Subject<void>();
    private searchFilters: IParsedHydra['search'];
    private readonly _destroy$ = new Subject<void>();

    constructor(
        private readonly mentionsService: MentionsService,
        private readonly restClient: RestClient,
        private readonly relationSelectService: RelationSelectService
    ) {
    }

    ngOnInit(): void {
        this.beforeTemplate = this.relationSelectService.getBeforeTemplate('user_logins');

        this.loadDefaultOptions();
        this.viewUpdated();
        this.handleMentionEvents();
        this.resolveOnChange();
    }

    private loadDefaultOptions(): void {
        this.getUsers(null, true)
            .subscribe((users) => {
                this.defaultOptions = this.getOptions(users);
                this.options = this.defaultOptions;
            });
    }

    private getUsers(search: string = null, pagination = false): Observable<IRestCollection<'user_logins'>> {
        const query = {
            pagination,
            'disabled[userLoginDisable]': false
        };

        if (this.searchFilters && search) {
            this.searchFilters.forEach((filter) => {
                query[filter] = search;
            });
        }

        this.loading = true;
        return this.restClient.endpoint('user_logins').getAll(query)
            .pipe(
                tap((users) => {
                    this.searchFilters = users.hydra().search;
                })
            );
    }

    private getOptions(users: IRestCollection<'user_logins'>): IRelationSelectOption<IUserLogin>[] {
        return [...users].map((user) => {
            const entityPresentation
                = this.relationSelectService.computeEntityPresentation('user_logins', user);

            return {
                value: user,
                label: entityPresentation.label,
                template: entityPresentation.template,
                templateContext: entityPresentation.templateContext
            };
        });
    }

    private viewUpdated(): void {
        this.view$
            .pipe(takeUntil(this._destroy$))
            .subscribe((view) => {
                this.view = view;
            });
    }

    private handleMentionEvents(): void {
        this.mentionsService.getMentionEvent$()
            .pipe(takeUntil(this._destroy$))
            .subscribe((event) => {
                this.state = event.state;

                switch (event.type) {
                    case MENTION_EVENT_TYPE.OPEN: {
                        this.alreadyClosed = false;

                        this.showResults();
                        break;
                    }
                    case MENTION_EVENT_TYPE.CHANGE: {
                        if (this.alreadyClosed) {
                            return;
                        }

                        this.showResults();
                        break;
                    }
                    case MENTION_EVENT_TYPE.BLUR:
                    case MENTION_EVENT_TYPE.EXIT: {
                        this.closeList();
                        break;
                    }
                    case MENTION_EVENT_TYPE.KEY: {
                        this.handleKeyEvent(event.keyEvent);
                        break;
                    }
                }
            });
    }

    private showResults(): void {
        this.open = true;
        this.loading = true;
        this.resolveSearchSubject.next();
    }

    private closeList(): void {
        this.open = false;
        this.selectedOptionIndex = null;
        this.alreadyClosed = true;
    }


    private handleKeyEvent(keyEvent: KeyboardEvent): void {
        switch (keyEvent.key) {
            case 'Escape': {
                this.closeList();
                break;
            }
            case 'Enter': {
                this.insertMentionBlock();
                break;
            }
            case 'ArrowUp': {
                this.selectedOptionIndex--;

                if (this.selectedOptionIndex < 0) {
                    this.selectedOptionIndex = this.options.length - 1;
                }
                break;
            }
            case 'ArrowDown': {
                this.selectedOptionIndex++;

                if (this.selectedOptionIndex >= this.options.length) {
                    this.selectedOptionIndex = 0;
                }
                break;
            }
        }
    }

    private insertMentionBlock(): void {
        if (
            typeof this.selectedOptionIndex !== 'number'
            || !this.options?.length
        ) {
            return;
        }

        const selectedOption = this.options[this.selectedOptionIndex],
            tr = this.view.state.tr,
            {from, to} = this.state.range,
            attrs = {
                content: `@${selectedOption.value.firstName} ${selectedOption.value.lastName}`,
                user: selectedOption.value.id
            },
            block = AppEditorSchema.nodes.mention.createAndFill(attrs);

        tr.replaceWith(from, to, block);
        this.view.dispatch(tr);
        this.mentionsService.newUserMention(selectedOption.value);
        this.closeList();
    }

    private resolveOnChange(): void {
        this.resolveSearchSubject
            .pipe(
                takeUntil(this._destroy$),
                debounceTime(500),
                switchMap(() => {
                    if (this.isStateTextEmpty()) {
                        return of(null);
                    }

                    const searchString = this.state.text.substring(1);
                    return this.getUsers(searchString);
                }),
                map((users) => {
                    if (!users) {
                        return this.defaultOptions;
                    }

                    return this.getOptions(users);
                })
            )
            .subscribe((options) => {
                this.options = options;
                this.resetOption();
                this.loading = false;
            });
    }

    private isStateTextEmpty(): boolean {
        return !this.state.text
            || this.state.text.length === 1;
    }

    private resetOption(): void {
        if (this.options.length) {
            this.selectedOptionIndex = 0;
        }
    }

    handleUserClick(index: number): void {
        this.selectedOptionIndex = index;
        this.insertMentionBlock();
    }

    ngOnDestroy(): void {
        this._destroy$.next();
        this._destroy$.complete();
    }
}
