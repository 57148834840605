import {Directive, InjectFlags, Injector} from '@angular/core';
import {UIView} from '@uirouter/angular';
import {LocatorsService} from 'src/modules/locators/locators.service';

@Directive({
    selector: 'ui-view'
})
export class UiViewDirective {

    constructor(
        private readonly injector: Injector
    ) {
        if (LocatorsService.mode !== 'DISABLED') {
            this.decorateUiView();
        }
    }

    private decorateUiView(): void {
        const uiView = this.injector.get(UIView, undefined, InjectFlags.Self);

        const originalVCU = uiView._viewConfigUpdated;

        uiView._viewConfigUpdated = (...args) => {
            originalVCU.apply(uiView, args);

            if (uiView._componentRef) {
                this.injector
                    .get(LocatorsService)
                    .addElementLocator(uiView._componentRef.location, {root: true});
            }
        };
    }
}
