import {Injectable, Injector} from '@angular/core';
import {isObservable, Observable, of, Subject} from 'rxjs';
import {
    defaultOperationExecutionOptions,
    IOperationAbstract,
    IOperationContext,
    IOperationCustomValue, IOperationExecutionOptions, IOperationClass,
    IOperationResult, IInvokeReturnType
} from 'src/modules/operations/interfaces';


interface ICurrentOperation<T extends IOperationAbstract = IOperationAbstract> {
    operation: T;
    context: T['resolvedContext'];
    execution?: Subject<IOperationResult>;
    executionOptions?: IOperationExecutionOptions;
}

@Injectable({
    providedIn: 'root'
})
export class OperationsService {
    private operationsInstances = new Map<IOperationClass, IOperationAbstract>();
    private currentOperationSubject = new Subject<ICurrentOperation>();
    private closeOperationModalSubject = new Subject<IOperationResult>();

    constructor(
        private readonly injector: Injector
    ) {
    }

    get<T extends IOperationAbstract>(operationClass: IOperationClass<T>): T {
        let operationInstance = this.operationsInstances.get(operationClass);
        if (operationInstance) {
            return operationInstance as T;
        }

        operationInstance = new operationClass();
        this.operationsInstances.set(operationClass, operationInstance);
        return operationInstance as T;
    }

    execute<T extends IOperationAbstract>(
        operation: T,
        context: T['context'],
        additionalData: unknown = null,
        executionOptions: IOperationExecutionOptions = {}
    ): Observable<IOperationResult> {
        const execution = new Subject<IOperationResult>(),
            mergedOptions = {...defaultOperationExecutionOptions,...operation.executionOptions, ...executionOptions};

        if (
            operation.access
            && !operation.access(context, this.injector)
        ) {
            execution.error('Access to operation blocked.');
            return execution.asObservable();
        }

        const operationContext = {
            baseContext: context,
            additionalData
        };
        this.resolveOperationContext<T>(operation, operationContext).subscribe({
            next: (resolvedContext) => {
                if (operation.component) {
                    this.currentOperationSubject.next({
                        operation,
                        context: resolvedContext,
                        execution,
                        executionOptions: mergedOptions
                    });
                } else if (operation.invoke) {
                    const invokedOperationResult
                        = operation.invoke(resolvedContext, this.injector);

                    this.handleInvokedOperation(
                        invokedOperationResult,
                        execution
                    );
                } else {
                    execution.error(
                        `Trying to execute operation '${operation.name}'`
                        + ' that does not have \'component\' nor \'invoke\' defined.'
                    );
                }
            },
            error: () => {
                execution.error('Context resolving failed.');
            }
        });

        return execution.asObservable();
    }

    private resolveOperationContext<T extends IOperationAbstract>(
        operation: T,
        context: IOperationContext<T['context']>
    ): Observable<unknown> {
        if (!(typeof operation.contextResolver === 'function')) {
            return of(context.baseContext);
        }

        return operation.contextResolver(context, this.injector);
    }

    private handleInvokedOperation(
        invokedOperationReturnValue: IInvokeReturnType,
        execution: Subject<IOperationResult>
    ): void {
        if (typeof invokedOperationReturnValue === 'undefined') {
            // If invoked operation doesn't provide observable or boolean, we assume that is completed successfully.
            this.emitOperationResult({success: true}, execution);
            return;
        }

        if (isObservable(invokedOperationReturnValue)) {
            invokedOperationReturnValue.subscribe({
                next: (success) => {
                    execution.next(success);
                },
                complete: () => {
                    execution.complete();
                },
                error: (e) => {
                    execution.error(e);
                }
            });
            return;
        }

        if (typeof invokedOperationReturnValue === 'boolean') {
            invokedOperationReturnValue = {success: invokedOperationReturnValue};
        }

        this.emitOperationResult(invokedOperationReturnValue, execution);
    }

    private emitOperationResult(success: IOperationResult, execution: Subject<IOperationResult>): void {
        // Wait with complete to make sure components receive value.
        setTimeout(() => {
            execution.next(success);
            execution.complete();
        });
    }

    closeModal(success: boolean, customValue?: IOperationCustomValue): void {
        this.closeOperationModalSubject.next({ success, customValue });
    }

    get currentOperation$(): Observable<ICurrentOperation> {
        return this.currentOperationSubject.asObservable();
    }

    get closeOperationModal$(): Observable<IOperationResult> {
        return this.closeOperationModalSubject.asObservable();
    }

    registerDynamicOperation(operationClass: IOperationClass): void {
        this.get(operationClass);
    }

    unregisterDynamicOperation(operationClass: IOperationClass): void {
        this.operationsInstances.delete(operationClass);
    }
}
