import {NodeView, EditorView} from 'prosemirror-view';
import {Node as ProseMirrorNode} from 'prosemirror-model';
import {IUserLogin} from '_types/rest';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {IMentionMetadata, IUserMetadataEntry} from 'src/modules/wysiwyg-editor/interfaces';


export class MentionBlockView implements NodeView {
    dom: HTMLElement;

    private readonly mentionBlockId: number;
    private static mentionIdCounter = 1;

    private users: IUserMetadataEntry[];
    private user: IUserMetadataEntry;
    private readonly _destroy$ = new Subject<void>();

    constructor(
        private node: ProseMirrorNode,
        private view: EditorView,
        private getPos: () => number,
        private metadataSection: IMentionMetadata
    ) {
        this.mentionBlockId = MentionBlockView.mentionIdCounter++;
        this.dom = MentionBlockView.createMentionDOM(node);
        this.initializeUsers();
        this.handleDisabledStatus();
    }

    static createMentionDOM(node: ProseMirrorNode): HTMLElement {
        const dom = document.createElement('span');

        dom.classList.add('mention-node', 'text-primary', 'text-bold');
        if (
            typeof node.attrs.disabledUser === 'string' && node.attrs.disabledUser === 'true'
            || typeof node.attrs.disabledUser === 'boolean' && node.attrs.disabledUser
        ) {
            dom.classList.add('disabled-mention');
        }

        dom.setAttribute('data-user', node.attrs.user);
        dom.setAttribute('data-disabled-user', node.attrs.disabledUser);

        dom.innerText = node.attrs.content;
        return dom;
    }

    private initializeUsers(): void {
        this.users = this.metadataSection.users;
        this.user = {
            userId: this.node.attrs.user,
            mentionBlockId: this.mentionBlockId,
            disabledUser: this.node.attrs.disabledUser
        };
        this.users.push(this.user);
    }

    private handleDisabledStatus(): void {
        this.metadataSection.disableUserSubject
            .pipe(takeUntil(this._destroy$))
            .subscribe((user) => {
                this.changeDisabledStatus(user);
            });

        this.metadataSection.enableUserSubject
            .pipe(takeUntil(this._destroy$))
            .subscribe((user) => {
                this.changeDisabledStatus(user, false);
            });
    }

    private changeDisabledStatus(user: IUserLogin, requestedStatus = true): void {
        if (user.id !== this.user.userId) {
            return;
        }

        this.user.disabledUser = requestedStatus;

        if (this.user.disabledUser) {
            this.dom.classList.add('disabled-mention');
        } else {
            this.dom.classList.remove('disabled-mention');
        }

        const transaction = this.view.state.tr;
        transaction.setNodeMarkup(
            this.getPos(),
            null,
            {
                ...this.node.attrs,
                disabledUser: this.user.disabledUser
            }
        );
        this.view.dispatch(transaction);
    }

    update(): boolean {
        return true;
    }

    stopEvent(): boolean {
        return true;
    }

    ignoreMutation(): boolean {
        return true;
    }

    destroy(): void {
        const metadataUserIndex = this.users.findIndex((userEntry) => {
            return userEntry.mentionBlockId === this.mentionBlockId;
        });
        this.users.splice(metadataUserIndex, 1);
        this._destroy$.next();
        this._destroy$.complete();
    }
}
