import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {IDropZoneSettings} from 'src/modules/file-upload/drop-zone/drop-zone.component';
import {IFileListItem} from 'src/modules/file-upload/file-list/file-list.component';
import {IFileRestModel} from 'src/modules/file-upload/file-upload-helper';
import {IRestObject} from 'src/modules/rest/objects';
import {RestClient} from 'src/modules/rest/rest-client.service';
import isEqual from 'lodash.isequal';
import {IFile, With} from '_types/rest';


export interface IUploadFile {
    name: string;
    size: number;
    progress: number;
    progressHidden: boolean;
    id: null | number;
    header: string;
    created: string;
    path: string | null;
    originalItem?: With<IFileRestModel, 'file'>;
}

type IRestUploadFile = IRestObject<string, IUploadFile>;

export type IFileViewMode = 'list' | 'none';

@Component({
    selector: 'file-upload',
    templateUrl: './file-upload.component.html'
})
export class FileUploadComponent implements OnInit, OnChanges {
    @Input() uploadReset = false;
    @Input() multiple = false;
    @Input() viewMode: IFileViewMode = 'list';
    @Input() tableName: string;
    @Input() entityFieldName? = 'file';
    @Input() entityFieldKey?: string;

    @Input() filesAdditional?: With<IFileRestModel, 'file'>[] = [];

    @Output() readonly uploadFile = new EventEmitter<IFile>();
    @Output() readonly deleteFile = new EventEmitter<IUploadFile>();

    currentFiles: IRestUploadFile[] = [];
    loading = false;
    dropZoneSettings: IDropZoneSettings = {};

    constructor(
        private readonly restClient: RestClient
    ) {
    }

    ngOnInit(): void {
        this.initDropZoneSettings();
    }

    private initDropZoneSettings(): void {
        this.dropZoneSettings = {
            files: !!this.multiple
        };
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (typeof changes['filesAdditional'] !== 'undefined'
            && !isEqual(changes['filesAdditional'].previousValue, changes['filesAdditional'].currentValue)) {
            this.updateFiles(this.filesAdditional);
        }
    }

    inputChange($event: File[]): void {
        if (!this.multiple) {
            $event = [$event[0]];
        }
        this.addNewFiles($event);
    }

    removeFile(fileToRemove: IFileListItem): void {
        this.currentFiles.splice(this.currentFiles.findIndex((item) => {
            return item.id === fileToRemove.id;
        }), 1);

        // Type cast is possible because file-list component events return objects from [files] input.
        this.deleteFile.emit(fileToRemove as IUploadFile);
    }

    private updateFiles(model: With<IFileRestModel, 'file'>[]): void {
        const currentFilesTemp = [];

        model.forEach((fileModel) => {
            const itemInCurrentFiles = this.currentFiles.find((currentFile) => {
                return currentFile.id === fileModel.file.id;
            });
            if (itemInCurrentFiles) {
                this.updateFileProperties(itemInCurrentFiles, fileModel);
                currentFilesTemp.push(itemInCurrentFiles);
            } else {
                currentFilesTemp.push(this.getNewFile(fileModel));
            }
        });

        this.currentFiles = currentFilesTemp;
        this.loading = false;
    }

    private updateFileProperties(file: IUploadFile, originalItem: With<IFileRestModel, 'file'>): void {
        if (file) {
            file.progressHidden = true;
            file.originalItem = originalItem;
        }
    }

    private getNewFile(item: With<IFileRestModel, 'file'>): IUploadFile | null {
        return {
            name: item.file.originalName,
            size: parseInt(item.file.size),
            progress: 100,
            progressHidden: true,
            id: item.file.id,
            header: item.file.header,
            created: item.created,
            path: item.file.path,
            originalItem: item,
        };
    }

    private addNewFiles(files: File[]): void {
        if (this.uploadReset) {
            this.currentFiles.forEach((file) => {
                this.removeFile(file as IFileListItem);
            });
        }

        this.loading = true;
        if (!this.multiple) {
            const uploadFile = this.createNewUploadFile(files[0]);
            this.uploadNewFile(files[0], uploadFile);
            return;
        }

        files.forEach((file) => {
            const uploadFile = this.createNewUploadFile(file);
            this.uploadNewFile(file, uploadFile);
        });
    }

    private uploadNewFile(file: File, uploadFile: IUploadFile): void {
        this.restClient.endpoint('files/upload').upload(
            file,
            {
                tableName: this.tableName,
                entityFieldKey: this.entityFieldKey,
                entityFieldName: this.entityFieldName
            },
            (e) => {
                uploadFile.progress = (e.loaded / e.total) * 100;
                uploadFile.progressHidden = false;
            }
        )
            .subscribe((response) => {
                uploadFile.progress = 100;
                uploadFile.progressHidden = true;
                uploadFile.id = response.id;
                uploadFile.header = response.header;
                uploadFile.created = response.created;
                uploadFile.path = response.path;
                if (this.multiple) {
                    this.currentFiles.push(this.getRestUploadFile(uploadFile));
                } else {
                    this.currentFiles = [this.getRestUploadFile(uploadFile)];
                }
                this.uploadFile.emit(response);
            });
    }

    getRestUploadFile(uploadFile: IUploadFile): IRestUploadFile {
        return this.restClient.endpoint<string, IUploadFile>('files/upload').createObject(uploadFile);
    }

    private createNewUploadFile(file: File): IUploadFile {
        return {
            name: file.name,
            size: file.size,
            progress: 0,
            progressHidden: false,
            id: null,
            created: new Date().toISOString(),
            path: null
        } as IUploadFile;
    }
}
