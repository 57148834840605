/* eslint-disable max-len */
/**
 * This file has been auto generated. Do not edit.
 */
import {IReportDisplay} from '_types/rest/Entity/IRestReportDisplay';
import {IRestReportDisplay} from '_types/rest/Entity/IRestReportDisplay';
import {IReportDisplayFilter} from '_types/rest/Entity/IRestReportDisplayFilter';
import {IRestReportDisplayFilter} from '_types/rest/Entity/IRestReportDisplayFilter';
import {IBaseRestEntity} from '_types/rest/IBaseRestEntity';
import {IRestObject} from 'core/rest/rest-object.service';

export const ReportDisplayFilterGroupType = {
    REPORT_DISPLAY_FILTER_GROUP_TYPE_AND: 1,
    REPORT_DISPLAY_FILTER_GROUP_TYPE_OR: 2
} as const;

export const ReportDisplayFilterGroupTypeOptions = [
    {
        value: ReportDisplayFilterGroupType.REPORT_DISPLAY_FILTER_GROUP_TYPE_AND,
        label: 'REPORT_DISPLAY_FILTER_GROUP_TYPE_AND',
    },
    {
        value: ReportDisplayFilterGroupType.REPORT_DISPLAY_FILTER_GROUP_TYPE_OR,
        label: 'REPORT_DISPLAY_FILTER_GROUP_TYPE_OR',
    }
] as const;

export interface IReportDisplayFilterGroupConst {
    type: [{id: 1, value: 'REPORT_DISPLAY_FILTER_GROUP_TYPE_AND'}, {id: 2, value: 'REPORT_DISPLAY_FILTER_GROUP_TYPE_OR'}]
}

export interface IReportDisplayFilterGroup extends IBaseRestEntity {
    id?: number,
    /**
     * @default 1
     */
    type: typeof ReportDisplayFilterGroupType[keyof typeof ReportDisplayFilterGroupType],
    /**
     * @maxLength 128
     */
    joinAlias?: string | null,
    /**
     * @default 1
     */
    order: number,
    reportDisplay?: IReportDisplay | string | null,
    parentReportDisplayFilterGroup?: IReportDisplayFilterGroup | string | null,
    childReportDisplayFilterGroups?: (IReportDisplayFilterGroup | string)[],
    reportDisplayFilters?: (IReportDisplayFilter | string)[],
    /**
     * @description Record status
     *  0 = deleted
     *  1 = active.
     * @default 1
     */
    status?: number,
    /**
     * @description Creation timestamp.
     * @default CURRENT_TIMESTAMP
     * @format date-time
     */
    created?: string,
    /**
     * @description Last modification timestamp.
     * @format date-time
     */
    modified?: string | null
}

/** @deprecated */
export interface IRestReportDisplayFilterGroup extends IReportDisplayFilterGroup, IRestObject {
    reportDisplay?: IRestReportDisplay | string | null,
    parentReportDisplayFilterGroup?: IRestReportDisplayFilterGroup | string | null,
    childReportDisplayFilterGroups?: IRestReportDisplayFilterGroup[] | string[],
    reportDisplayFilters?: IRestReportDisplayFilter[] | string[],
}
