import {Inject, Pipe, PipeTransform} from '@angular/core';
import {DOCUMENT} from '@angular/common';


@Pipe({name: 'domain'})
export class DomainPipe implements PipeTransform {
    constructor(
        @Inject(DOCUMENT) private document: Document
    ) {
    }

    transform(value: string): string {
        // Use ng2+ url parser service
        const parser = this.document.createElement('a');
        parser.href = value;
        let hostname = parser.hostname;
        if (hostname.match(/^www\./)) {
            hostname = hostname.substr(4);
        }
        return hostname;
    }
}
