export class RestError<T = unknown> extends Error {
    constructor(
        message: string,
        public code: number,
        public data: T,
        public details?: string
    ) {
        super(message);
        this.name = 'RestError';
    }
}
