import {RestEndpoint} from 'src/modules/rest/rest-endpoint';
import {EndpointName, EndpointToType} from '_types/rest';

export abstract class AbstractRestCommon<TEndpoint extends EndpointName> {

    abstract endpointName(): TEndpoint;

    endpoint<TObject = EndpointToType<TEndpoint>,
        TCollection = EndpointToType<TEndpoint, true>>(): RestEndpoint<TEndpoint, TObject, TCollection> {
        return new RestEndpoint<TEndpoint, TObject, TCollection>(this.endpointName());
    }

    walkRecursive(callback: (object: object) => void, object = this): this {
        callback(object);
        Object.values(object).forEach((item) => {
            if (Array.isArray(item)) {
                item.forEach((subItem) => {
                    if (typeof subItem === 'object' && subItem !== null) {
                        this.walkRecursive(callback, subItem);
                    }
                });
            } else if (typeof item === 'object' && item !== null) {
                this.walkRecursive(callback, item);
            }
        });
        return this;
    }

    /**
     * Recursively delete given fields that contain given values from current object
     * @param values - array of values to delete
     * @param [onlyFromEntities=true] - remove values only from objects with @id property
     */
    removeValues(values: unknown[], onlyFromEntities = true): this {
        return this.walkRecursive((object) => {
            if (onlyFromEntities === false || typeof object['@id'] !== 'undefined') {
                Object.entries(object).forEach(([key, item]) => {
                    if (values.includes(item)) {
                        delete object[key];
                    }
                });
            }
        });
    }

    /**
     * Recursively delete given fields from current object
     * @param fields - array of field keys to delete
     * @param [removeDefaultFields=false] - if set to true, removes:
     *                  'created', 'modified', 'status', '@id', '@type', '@operations' fields
     * @param [onlyFromEntities=true] - remove fields only from objects with @id property
     */
    removeFields(fields: string[], removeDefaultFields = false, onlyFromEntities = true): this {
        return this.walkRecursive((object) => {
            if (onlyFromEntities === false || typeof object['@id'] !== 'undefined') {
                fields.forEach((item) => {
                    delete object[item];
                });
                if (removeDefaultFields) {
                    ['created', 'modified', 'status', '@id', '@type', '@operations'].forEach((item) => {
                        delete object[item];
                    });
                }
            }
        });
    }
}
