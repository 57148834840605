/* eslint-disable max-len */
/**
 * This file has been auto generated. Do not edit.
 */
import {IReportDisplay} from '_types/rest/Entity/IRestReportDisplay';
import {IRestReportDisplay} from '_types/rest/Entity/IRestReportDisplay';
import {IBaseRestEntity} from '_types/rest/IBaseRestEntity';
import {IRestObject} from 'core/rest/rest-object.service';

export const ReportDisplayContextualFilterProcessAction = {
    REPORT_CONTEXTUAL_FILTER_PROCESS_ACTION_NOTHING: 1
} as const;

export const ReportDisplayContextualFilterProcessActionOptions = [
    {
        value: ReportDisplayContextualFilterProcessAction.REPORT_CONTEXTUAL_FILTER_PROCESS_ACTION_NOTHING,
        label: 'REPORT_CONTEXTUAL_FILTER_PROCESS_ACTION_NOTHING',
    }
] as const;

export interface IReportDisplayContextualFilterConst {
    processAction: [{id: 1, value: 'REPORT_CONTEXTUAL_FILTER_PROCESS_ACTION_NOTHING'}]
}

export interface IReportDisplayContextualFilter extends IBaseRestEntity {
    id?: number,
    /**
     * @maxLength 128
     */
    type: string,
    options?: string[] | null,
    /**
     * @default 1
     */
    processAction: typeof ReportDisplayContextualFilterProcessAction[keyof typeof ReportDisplayContextualFilterProcessAction],
    /**
     * @default 1
     */
    order: number,
    reportDisplay: IReportDisplay | string,
    /**
     * @description Record status
     *  0 = deleted
     *  1 = active.
     * @default 1
     */
    status?: number,
    /**
     * @description Creation timestamp.
     * @default CURRENT_TIMESTAMP
     * @format date-time
     */
    created?: string,
    /**
     * @description Last modification timestamp.
     * @format date-time
     */
    modified?: string | null
}

/** @deprecated */
export interface IRestReportDisplayContextualFilter extends IReportDisplayContextualFilter, IRestObject {
    reportDisplay: IRestReportDisplay | string,
}
