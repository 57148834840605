import {Directive, ElementRef} from '@angular/core';

@Directive({
    selector: 'table'
})
/**
 * Helper directive that exposes <table> element in a controller to be later required by other directives
 */
export class ActionsTableDirective  {
    constructor(public elRef: ElementRef<HTMLTableElement>) {
    }
}
