import {AbstractDeleteOperation} from 'view-modules/operations/abstract/abstract-delete.operation';
import {IConfirmModalOptions} from 'src/modules/global-components/confirm-modal/confirm-modal.service';
import {AbstractOperationDefinition} from 'src/modules/operations/interfaces';
import {IPartner, PartnerEmailType} from '_types/rest';
import {IRestCollection, IRestObject} from 'src/modules/rest/objects';
import {RestClient} from 'src/modules/rest/rest-client.service';

export interface IPartnerMailboxAddContext {
    collection: IRestCollection<'partner_emails'>
    partner: IPartner,
    config: {
        mailType: typeof PartnerEmailType[keyof typeof PartnerEmailType],
        modalType: 'main_mailbox' | 'mailbox'
    }
}

export interface IPartnerMailboxEditContext {
    email: IRestObject<'partner_emails'>
    partner: IPartner,
    config: {
        mailType: typeof PartnerEmailType[keyof typeof PartnerEmailType],
        modalType: 'main_mailbox' | 'mailbox'
    }
}

export class PartnerMailboxAddOperation
    extends AbstractOperationDefinition<string, IPartnerMailboxAddContext> {
    readonly endpoint = 'partner_emails';
    readonly name = 'post';
    lang = 'EMAILS_ADD';
    icon = 'fa-plus';
    component = (): Promise<unknown> => import('./partner-mailbox-form.component');

    access(context: IPartnerMailboxAddContext): boolean {
        return RestClient.isRestCollection(context.collection) && context.collection.access('post');
    }

}

export class PartnerMailboxEditOperation
    extends AbstractOperationDefinition<string, IPartnerMailboxEditContext> {
    readonly endpoint = 'partner_emails';
    readonly name = 'put';
    lang = 'EDIT';
    icon = 'fa-edit';
    component = (): Promise<unknown> => import('./partner-mailbox-form.component');

    access(context: IPartnerMailboxEditContext): boolean {
        return context.email.access('put');
    }

}

export class PartnerMailboxDeleteOperation extends AbstractDeleteOperation<'partner_emails'> {
    readonly endpoint = 'partner_emails';
    lang = 'DELETE';
    confirmMessage = 'DELETE_EMAIL_CONFIRM';
    confirmOptions: IConfirmModalOptions = {
        primaryBtn: 'danger',
        langYes: 'DELETE_EMAIL_CONFIRM_LANG_YES',
        text: 'ACTION_CANNOT_BE_UNDONE'
    };
}

export const partnerMailboxOperations = [
    PartnerMailboxDeleteOperation,
    PartnerMailboxAddOperation,
    PartnerMailboxEditOperation
] as const;
