/* eslint-disable max-len */
/**
 * This file has been auto generated. Do not edit.
 */
import {IPartner} from '_types/rest/Entity/IRestPartner';
import {IRestPartner} from '_types/rest/Entity/IRestPartner';
import {IBaseRestEntity} from '_types/rest/IBaseRestEntity';
import {IRestObject} from 'core/rest/rest-object.service';

export const TaskProcessingModelStateRelation = {
    TASK_PROCESSING_MODEL_RELATION_TO_TASK: 1,
    TASK_PROCESSING_MODEL_RELATION_TO_TASK_USER: 2,
    TASK_PROCESSING_MODEL_RELATION_DATE_TO_TASK_AND_RELATION_STATE_TO_TASK_USER: 3,
    TASK_PROCESSING_MODEL_RELATION_TO_TASK_WITH_MULTIPLE_ASSIGNEE: 4
} as const;

export const TaskProcessingModelStateRelationOptions = [
    {
        value: TaskProcessingModelStateRelation.TASK_PROCESSING_MODEL_RELATION_TO_TASK,
        label: 'TASK_PROCESSING_MODEL_RELATION_TO_TASK',
    },
    {
        value: TaskProcessingModelStateRelation.TASK_PROCESSING_MODEL_RELATION_TO_TASK_USER,
        label: 'TASK_PROCESSING_MODEL_RELATION_TO_TASK_USER',
    },
    {
        value: TaskProcessingModelStateRelation.TASK_PROCESSING_MODEL_RELATION_DATE_TO_TASK_AND_RELATION_STATE_TO_TASK_USER,
        label: 'TASK_PROCESSING_MODEL_RELATION_DATE_TO_TASK_AND_RELATION_STATE_TO_TASK_USER',
    },
    {
        value: TaskProcessingModelStateRelation.TASK_PROCESSING_MODEL_RELATION_TO_TASK_WITH_MULTIPLE_ASSIGNEE,
        label: 'TASK_PROCESSING_MODEL_RELATION_TO_TASK_WITH_MULTIPLE_ASSIGNEE',
    }
] as const;

export const TaskProcessingModelDatesRelation = {
    TASK_PROCESSING_MODEL_RELATION_TO_TASK: 1,
    TASK_PROCESSING_MODEL_RELATION_TO_TASK_USER: 2,
    TASK_PROCESSING_MODEL_RELATION_DATE_TO_TASK_AND_RELATION_STATE_TO_TASK_USER: 3,
    TASK_PROCESSING_MODEL_RELATION_TO_TASK_WITH_MULTIPLE_ASSIGNEE: 4
} as const;

export const TaskProcessingModelDatesRelationOptions = [
    {
        value: TaskProcessingModelDatesRelation.TASK_PROCESSING_MODEL_RELATION_TO_TASK,
        label: 'TASK_PROCESSING_MODEL_RELATION_TO_TASK',
    },
    {
        value: TaskProcessingModelDatesRelation.TASK_PROCESSING_MODEL_RELATION_TO_TASK_USER,
        label: 'TASK_PROCESSING_MODEL_RELATION_TO_TASK_USER',
    },
    {
        value: TaskProcessingModelDatesRelation.TASK_PROCESSING_MODEL_RELATION_DATE_TO_TASK_AND_RELATION_STATE_TO_TASK_USER,
        label: 'TASK_PROCESSING_MODEL_RELATION_DATE_TO_TASK_AND_RELATION_STATE_TO_TASK_USER',
    },
    {
        value: TaskProcessingModelDatesRelation.TASK_PROCESSING_MODEL_RELATION_TO_TASK_WITH_MULTIPLE_ASSIGNEE,
        label: 'TASK_PROCESSING_MODEL_RELATION_TO_TASK_WITH_MULTIPLE_ASSIGNEE',
    }
] as const;

export interface ITaskProcessingModelConst {
    stateRelation: [{id: 1, value: 'TASK_PROCESSING_MODEL_RELATION_TO_TASK'}, {id: 2, value: 'TASK_PROCESSING_MODEL_RELATION_TO_TASK_USER'}, {id: 3, value: 'TASK_PROCESSING_MODEL_RELATION_DATE_TO_TASK_AND_RELATION_STATE_TO_TASK_USER'}, {id: 4, value: 'TASK_PROCESSING_MODEL_RELATION_TO_TASK_WITH_MULTIPLE_ASSIGNEE'}],
    datesRelation: [{id: 1, value: 'TASK_PROCESSING_MODEL_RELATION_TO_TASK'}, {id: 2, value: 'TASK_PROCESSING_MODEL_RELATION_TO_TASK_USER'}, {id: 3, value: 'TASK_PROCESSING_MODEL_RELATION_DATE_TO_TASK_AND_RELATION_STATE_TO_TASK_USER'}, {id: 4, value: 'TASK_PROCESSING_MODEL_RELATION_TO_TASK_WITH_MULTIPLE_ASSIGNEE'}]
}

export interface ITaskProcessingModel extends IBaseRestEntity {
    id?: number,
    /**
     * @maxLength 512
     */
    name: string,
    /**
     * @default true
     */
    multipleAssignee: boolean,
    /**
     * @default 1
     */
    stateRelation?: typeof TaskProcessingModelStateRelation[keyof typeof TaskProcessingModelStateRelation],
    /**
     * @default 1
     */
    datesRelation?: typeof TaskProcessingModelDatesRelation[keyof typeof TaskProcessingModelDatesRelation],
    partner?: IPartner | string | null,
    /**
     * @description Record status
     *  0 = deleted
     *  1 = active.
     * @default 1
     */
    status?: number,
    /**
     * @description Creation timestamp.
     * @default CURRENT_TIMESTAMP
     * @format date-time
     */
    created?: string,
    /**
     * @description Last modification timestamp.
     * @format date-time
     */
    modified?: string | null
}

/** @deprecated */
export interface IRestTaskProcessingModel extends ITaskProcessingModel, IRestObject {
    partner?: IRestPartner | string | null,
}
