<div class="actions">
    <ng-container *ngIf="!isMobile">
        <div class="actions-desktop">
            <ng-container *ngTemplateOutlet="contentActions"></ng-container>
        </div>
    </ng-container>
    <ng-container *ngIf="isMobile">
        <div class="actions-mobile dropdown"
             [(bsDropdown)]="dropdown"
             [title]="isDropdownEmpty ? ('COMMON_LIST_IS_EMPTY' | translate) : ''"
             [boundaryElement]="table?.elRef || actionsBoundary?.elRef">
            <a class="p-4"
               bsDropdownToggle
               [ngClass]="{'disabled': isDropdownEmpty}">
                <i class="fa fa-ellipsis-v"></i>
            </a>
            <div class="dropdown-menu dropdown-menu-right" (click)="dropdown=false">
                <div class="dropdown-header">{{actionTranslation}}</div>
                <ng-container *ngTemplateOutlet="contentActions"></ng-container>
            </div>
        </div>
    </ng-container>
</div>

<ng-template #contentActions>
    <ng-content></ng-content>
</ng-template>
