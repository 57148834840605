import {AbstractDeleteOperation} from 'view-modules/operations/abstract/abstract-delete.operation';
import {AbstractOperationDefinition} from 'src/modules/operations/interfaces';

export class PartnersListAddOperation extends AbstractOperationDefinition<'partners'> {
    readonly endpoint = 'partners';
    readonly name = 'post';
    lang = 'PARTNER_ADD';
    icon = 'fa-plus';
    component = (): Promise<unknown> => import('./partners-list-form.component');
}

export class PartnersListEditOperation extends AbstractOperationDefinition<'partners'> {
    readonly endpoint = 'partners';
    readonly name = 'put';
    lang = 'EDIT';
    icon = 'fa-edit';
    component = (): Promise<unknown> => import('./partners-list-form.component');
}

export class PartnersListDeleteOperation extends AbstractDeleteOperation<'partners'> {
    readonly endpoint = 'partners';
    lang = 'DELETE';
    confirmMessage = 'PARTNERS_LIST_REMOVE_CONFIRM';
    confirmOptions = {
        langYes: 'PARTNERS_LIST_REMOVE_CONFIRM_LANG_YES'
    };
}

export const partnersListOperations = [
    PartnersListAddOperation,
    PartnersListEditOperation,
    PartnersListDeleteOperation
] as const;
