/* eslint-disable max-len */
/**
 * This file has been auto generated. Do not edit.
 */
import {IEntityFieldInfo} from '_types/rest/Entity/IRestEntityFieldInfo';
import {IRestEntityFieldInfo} from '_types/rest/Entity/IRestEntityFieldInfo';
import {IBaseRestEntity} from '_types/rest/IBaseRestEntity';
import {IRestObject} from 'core/rest/rest-object.service';

export const EntityFieldDisplay = {
    ENTITY_FIELD_DISPLAY_NONE: 0,
    ENTITY_FIELD_DISPLAY_TRUE: 1,
    ENTITY_FIELD_DISPLAY_READONLY: 2,
    ENTITY_FIELD_DISPLAY_DISABLED: 3
} as const;

export const EntityFieldDisplayOptions = [
    {
        value: EntityFieldDisplay.ENTITY_FIELD_DISPLAY_NONE,
        label: 'ENTITY_FIELD_DISPLAY_NONE',
    },
    {
        value: EntityFieldDisplay.ENTITY_FIELD_DISPLAY_TRUE,
        label: 'ENTITY_FIELD_DISPLAY_TRUE',
    },
    {
        value: EntityFieldDisplay.ENTITY_FIELD_DISPLAY_READONLY,
        label: 'ENTITY_FIELD_DISPLAY_READONLY',
    },
    {
        value: EntityFieldDisplay.ENTITY_FIELD_DISPLAY_DISABLED,
        label: 'ENTITY_FIELD_DISPLAY_DISABLED',
    }
] as const;

export interface IEntityFieldConst {
    display: [{id: 0, value: 'ENTITY_FIELD_DISPLAY_NONE'}, {id: 1, value: 'ENTITY_FIELD_DISPLAY_TRUE'}, {id: 2, value: 'ENTITY_FIELD_DISPLAY_READONLY'}, {id: 3, value: 'ENTITY_FIELD_DISPLAY_DISABLED'}]
}

export interface IEntityField extends IBaseRestEntity {
    id?: number,
    /**
     * @maxLength 128
     */
    tableName?: string | null,
    /**
     * @maxLength 128
     */
    name?: string | null,
    /**
     * @maxLength 128
     */
    groupName?: string | null,
    /**
     * @maxLength 128
     */
    key?: string | null,
    /**
     * @maxLength 256
     */
    description?: string | null,
    validators?: string[] | null,
    options?: string[] | null,
    /**
     * @default 1
     */
    display?: typeof EntityFieldDisplay[keyof typeof EntityFieldDisplay],
    /**
     * @maxLength 128
     */
    default?: string | null,
    order?: number | null,
    entityFieldInfo?: IEntityFieldInfo | string | null,
    /**
     * @description Number of values (possibilities in EntityFieldValue) for entityId. NULL for no limit.
     */
    maxValues?: number | null,
    /**
     * @description Record status
     *  0 = deleted
     *  1 = active.
     * @default 1
     */
    status?: number,
    /**
     * @description Creation timestamp.
     * @default CURRENT_TIMESTAMP
     * @format date-time
     */
    created?: string,
    /**
     * @description Last modification timestamp.
     * @format date-time
     */
    modified?: string | null
}

/** @deprecated */
export interface IRestEntityField extends IEntityField, IRestObject {
    entityFieldInfo?: IRestEntityFieldInfo | string | null,
}
