import {Pipe, PipeTransform} from '@angular/core';
import {TextUtils} from 'src/services/text-utils';

@Pipe({name: 'shortText'})
export class ShortTextPipe implements PipeTransform {
    transform(
        text: string,
        maxLength: number
    ): string {
        return TextUtils.shortText(text, maxLength);
    }
}
