<div *ngIf="isLoading && visualSettings"
     class="img-thumbnail d-inline-block align-middle mr-1 mb-1 mt-1"
     [ngStyle]="{height: visualSettingsHeight + 'px', width : visualSettingsWidth+ 'px'}">
    <div class="image-upload__container image-upload__visual-settings"></div>
    <div class="visual-settings__item">
        <canvas @loadImg
                [imgUploadCanvas]="imgUploadObservable"
                (imgScaled)="scaleImage($event)">
        </canvas>
        <div class="image-upload__image-info p-4 d-flex flex-column">
            <div>{{currentFile.name | shortText: 45}}</div>
            <div class="d-flex align-items-center mt-auto">
                <i class="fa fa-file mr-3"></i>
                <div class="progress progress-infinite">
                    <div class="progress-bar"></div>
                </div>
            </div>
        </div>
    </div>
</div>
<div [ngClass]="visualSettings ? 'img-thumbnail d-inline-block align-middle mr-1 mb-1 mt-1 upload-thumbnail' : ''"
     [ngStyle]="visualSettings && {height: visualSettingsHeight + 'px', width : visualSettingsWidth+ 'px'}">
    <drop-zone (fileUploaded)="inputChange($event)"
               [settings]="dropZoneSettings"
               [width]="width"
               [height]="height">
    </drop-zone>
    <p class="mt-2" *ngIf="imageAlert">{{'COMMON_DROP_IMAGE_ALERT' | translate}}</p>
    <div *ngIf="!visualSettings"
         [hidden]="!isLoading && !model?.fileName"
         class="image-upload__container mt-4 mb-2"
         [ngStyle]="{height: thumbnailHeight + 'px', width : thumbnailWidth + 'px'}">
        <div [ngClass]="{'image-upload__uploading': isLoading}">
            <canvas @loadImg
                    *ngIf="isLoading || model?.state === -1"
                    [imgUploadCanvas]="imgUploadObservable"
                    (imgScaled)="scaleImage($event)">
            </canvas>
            <img class="image-upload__preview"
                 *ngIf="model?.fileName"
                 [hidden]="isLoading || model.state === -1"
                 src="{{model.fileName}}"
                 alt="img"/>
            <div class="image-upload__image-info p-4 d-flex flex-column"  *ngIf="isLoading">
                <div>{{currentFile.name | shortText: 45}}</div>
                <div class="d-flex align-items-center mt-auto">
                    <i class="fa fa-file mr-3"></i>
                    <div class="progress progress-infinite">
                        <div class="progress-bar"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
