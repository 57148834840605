/* eslint-disable max-len */
/**
 * This file has been auto generated. Do not edit.
 */
import {IReportDisplay} from '_types/rest/Entity/IRestReportDisplay';
import {ICmsType} from '_types/rest/Entity/IRestCmsType';
import {IMenu} from '_types/rest/Entity/IRestMenu';
import {Route} from '_types/route';
import {IBaseRestEntity} from '_types/rest/IBaseRestEntity';

export const MenuElementType = {
    TYPE_SECTION: 1,
    TYPE_ELEMENT: 2,
    TYPE_SEPARATOR: 3
} as const;

export const MenuElementTypeOptions = [
    {
        value: MenuElementType.TYPE_SECTION,
        label: 'TYPE_SECTION',
    },
    {
        value: MenuElementType.TYPE_ELEMENT,
        label: 'TYPE_ELEMENT',
    },
    {
        value: MenuElementType.TYPE_SEPARATOR,
        label: 'TYPE_SEPARATOR',
    }
] as const;

export const MenuElementViewType = {
    TYPE_VIEW_ROUTE: 1,
    TYPE_VIEW_REPORT_DISPLAY: 2,
    TYPE_VIEW_URL: 3,
    TYPE_VIEW_CMS: 4
} as const;

export const MenuElementViewTypeOptions = [
    {
        value: MenuElementViewType.TYPE_VIEW_ROUTE,
        label: 'TYPE_VIEW_ROUTE',
    },
    {
        value: MenuElementViewType.TYPE_VIEW_REPORT_DISPLAY,
        label: 'TYPE_VIEW_REPORT_DISPLAY',
    },
    {
        value: MenuElementViewType.TYPE_VIEW_URL,
        label: 'TYPE_VIEW_URL',
    },
    {
        value: MenuElementViewType.TYPE_VIEW_CMS,
        label: 'TYPE_VIEW_CMS',
    }
] as const;

export interface IMenuElementConst {
    type: [{id: 1, value: 'TYPE_SECTION'}, {id: 2, value: 'TYPE_ELEMENT'}, {id: 3, value: 'TYPE_SEPARATOR'}],
    viewType: [{id: 1, value: 'TYPE_VIEW_ROUTE'}, {id: 2, value: 'TYPE_VIEW_REPORT_DISPLAY'}, {id: 3, value: 'TYPE_VIEW_URL'}, {id: 4, value: 'TYPE_VIEW_CMS'}]
}

export interface IMenuElement extends IBaseRestEntity {
    id?: number,
    /**
     * @default 2
     */
    type: typeof MenuElementType[keyof typeof MenuElementType],
    /**
     * @maxLength 255
     */
    icon?: string | null,
    /**
     * @maxLength 255
     */
    name?: string | null,
    /**
     * @default 1
     */
    order?: number,
    /**
     * @default 1
     */
    viewType?: typeof MenuElementViewType[keyof typeof MenuElementViewType],
    reportDisplay?: IReportDisplay | string | null,
    cmsType?: ICmsType | string | null,
    route?: Route,
    /**
     * @maxLength 255
     */
    url?: string | null,
    menu: IMenu | string | null,
    parentElement?: IMenuElement | string | null,
    menuElements?: (IMenuElement | string)[],
    /**
     * @description Record status
     *  0 = deleted
     *  1 = active.
     * @default 1
     */
    status?: number,
    /**
     * @description Creation timestamp.
     * @default CURRENT_TIMESTAMP
     * @format date-time
     */
    created?: string,
    /**
     * @description Last modification timestamp.
     * @format date-time
     */
    modified?: string | null
}
