import {
    ChangeDetectorRef,
    Component,
    ContentChild, ContentChildren, EventEmitter,
    Inject,
    Input,
    OnDestroy,
    OnInit, Output, QueryList,
    Renderer2,
    TemplateRef
} from '@angular/core';
import {IDynamicFieldConfig} from 'src/modules/dynamic-fields/interfaces';
import {
    ControlValueAccessor,
    FormControl,
    ValidationErrors, Validator
} from '@angular/forms';
import {DOCUMENT} from '@angular/common';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {EsOptionsDirective} from 'angular-bootstrap4-extended-select';
import {AbstractControlValueAccessor} from 'src/modules/app-forms/abstract-control-value-accessors/abstract-control-value-accessor';

export interface IEditableSelectOptionContext<T = unknown> {
    $implicit: T;
}

export interface IEditableFieldTemplateContext<T = unknown> {
    $implicit: T;
    objectValue: T;
}

@Component({
    selector: 'editable-field',
    templateUrl: './editable-field.component.html',
    styleUrls: ['./editable-field.component.scss'],
    providers: AbstractControlValueAccessor.getProviders(EditableFieldComponent)
})
export class EditableFieldComponent<T> extends AbstractControlValueAccessor
    implements OnInit, OnDestroy, ControlValueAccessor, Validator {

    @Input() config: IDynamicFieldConfig;
    @Input() customClass?: string;
    @Input() objectValue: T;
    @Input() alwaysInitializeDynamicField = false;
    @Output() readonly objectValueChange = new EventEmitter<T>();
    @Output() readonly initializing = new EventEmitter<boolean>();
    @Output() readonly isEditing = new EventEmitter<boolean>();

    control = new FormControl(null);
    editing = false;
    focus = false;
    mouseOver = false;
    initializeDynamicField = false;
    isDeselectActive = false;
    disableDeselect = false;

    private cancelClickListener: () => void;
    private _destroy$ = new Subject<void>();

    @ContentChild('template', {read: TemplateRef}) template: TemplateRef<IEditableFieldTemplateContext>
    @ContentChild('optionTemplate', {read: TemplateRef, static: true})
    optionTemplate: TemplateRef<IEditableSelectOptionContext<T> | void>
    @ContentChildren(EsOptionsDirective, {descendants: true}) esOptionsList?: QueryList<EsOptionsDirective<unknown>>;

    constructor(
        @Inject(DOCUMENT) private document: Document,
        private renderer: Renderer2,
        private changeDetector: ChangeDetectorRef
    ) {
        super();
    }

    ngOnInit(): void {
        /**
         * Initialize dynamic fields which have required validator to assure form legend is properly generated
         * or if field is manually marked to be initialized.
         * */
        if (this.config.validators?.required || this.alwaysInitializeDynamicField) {
            this.initializeDynamicField = true;
        }

        this.initializeValueChangeActions();
    }

    private initializeValueChangeActions(): void {
        const closeOnChangeTypes = ['select', 'relation', 'date'],
            excludedViewTypes = ['radio', 'radioButtons', 'switch'],
            needClosing
                = closeOnChangeTypes.includes(this.config.type)
                && !(this.config.type === 'select' && excludedViewTypes.includes(this.config.viewType));

        this.control.valueChanges
            .pipe(takeUntil(this._destroy$))
            .subscribe((value) => {
                this.isDeselectActive = !!this.control.value && !this.control.disabled;

                if (needClosing) {
                    this.edit(false);
                }

                if (typeof this._onChange === 'function') {
                    this._onChange(value);
                }
            });
    }

    edit(edit = true): void {
        if ((!edit && this.control.errors) || this.control.disabled) {
            return;
        }

        this.initializeDynamicField = true;

        this.focus = true;
        this.editing = edit;
        this.manageClickListener(edit);
        this.isEditing.emit(edit);

        // Focus need's to be reset to default state in next tick.
        setTimeout(() => {
            this.focus = false;
        });
    }

    private manageClickListener(isEdited: boolean): void {
        if (isEdited && !this.cancelClickListener) {
            this.initializeClickControl();
        } else if (!isEdited && this.cancelClickListener) {
            this.cancelClickListener();
            this.cancelClickListener = null;
        }
    }

    private initializeClickControl(): void {
        this.cancelClickListener = this.renderer.listen(
            this.document,
            'click',
            () => {
                if (!this.mouseOver) {
                    this.edit(false);
                }
            });
    }

    handleMouseEnter(): void {
        this.mouseOver = true;
    }

    handleMouseLeave(): void {
        this.mouseOver = false;
    }

    handleDeselect($event: MouseEvent): void {
        $event.stopPropagation();
        this.deselectValue();
    }

    private deselectValue(): void {
        if (this.config.type === 'relation') {
            this.objectValue = null;
        }

        // setValue causes writeValue call on dynamic-field, which causes it's objectValueChange to emit
        this.control.setValue(null);
        this.edit(false);
    }

    handleObjectValueChange(objectValue: T): void {
        this.isDeselectActive = objectValue && !!Object.keys(objectValue).length && !this.control.disabled;

        this.objectValueChange.emit(objectValue);
        this.objectValue = objectValue;
    }

    writeValue(value: unknown): void {
        this.control.setValue(value, {emitEvent: false});

        if (!value) {
            return;
        }
        this.setDeselectable();
    }

    private setDeselectable(): void {
        if ('deselectable' in this.config) {
            this.disableDeselect = !this.config.deselectable;
            this.isDeselectActive = !!this.control.value && !this.control.disabled;
        }
    }

    registerOnTouched(fn: () => unknown): void {
        this.control['_markAsTouched'] = this.control.markAsTouched;
        this.control.markAsTouched = () => {
            this.control['_markAsTouched']();
            fn();
        };
    }

    setDisabledState(isDisabled: boolean): void {
        if (isDisabled) {
            this.control.disable({emitEvent: false});
            this.isDeselectActive = false;
            return;
        }
        this.control.enable({emitEvent: false});
        this.isDeselectActive = true;
        this.changeDetector.detectChanges();
    }

    validate(): ValidationErrors | null {
        return this.control.valid ? null : {editableField: true};
    }

    ngOnDestroy(): void {
        this._destroy$.next();
        this._destroy$.complete();
    }
}
