/* eslint-disable max-len */
/**
 * This file has been auto generated. Do not edit.
 */
import {IPartner} from '_types/rest/Entity/IRestPartner';
import {IBaseRestEntity} from '_types/rest/IBaseRestEntity';

export const PartnerEmailType = {
    PARTNER_EMAIL_TYPE_GENERAL: 1,
    PARTNER_EMAIL_TYPE_USER: 2,
    PARTNER_EMAIL_TYPE_MAILER: 3
} as const;

export const PartnerEmailTypeOptions = [
    {
        value: PartnerEmailType.PARTNER_EMAIL_TYPE_GENERAL,
        label: 'PARTNER_EMAIL_TYPE_GENERAL',
    },
    {
        value: PartnerEmailType.PARTNER_EMAIL_TYPE_USER,
        label: 'PARTNER_EMAIL_TYPE_USER',
    },
    {
        value: PartnerEmailType.PARTNER_EMAIL_TYPE_MAILER,
        label: 'PARTNER_EMAIL_TYPE_MAILER',
    }
] as const;

export const PartnerEmailAuthType = {
    PARTNER_EMAIL_AUTH_LOGIN: 1,
    PARTNER_EMAIL_AUTH_OAUTH: 2
} as const;

export const PartnerEmailAuthTypeOptions = [
    {
        value: PartnerEmailAuthType.PARTNER_EMAIL_AUTH_LOGIN,
        label: 'PARTNER_EMAIL_AUTH_LOGIN',
    },
    {
        value: PartnerEmailAuthType.PARTNER_EMAIL_AUTH_OAUTH,
        label: 'PARTNER_EMAIL_AUTH_OAUTH',
    }
] as const;

export const PartnerEmailInType = {
    PARTNER_EMAIL_INTYPE_IMAP: 1,
    PARTNER_EMAIL_INTYPE_POP3: 2
} as const;

export const PartnerEmailInTypeOptions = [
    {
        value: PartnerEmailInType.PARTNER_EMAIL_INTYPE_IMAP,
        label: 'PARTNER_EMAIL_INTYPE_IMAP',
    },
    {
        value: PartnerEmailInType.PARTNER_EMAIL_INTYPE_POP3,
        label: 'PARTNER_EMAIL_INTYPE_POP3',
    }
] as const;

export const PartnerEmailInEncryption = {
    PARTNER_EMAIL_ENCRYPTION_TLS: 1,
    PARTNER_EMAIL_ENCRYPTION_SSL: 2,
    PARTNER_EMAIL_ENCRYPTION_NONE: 3
} as const;

export const PartnerEmailInEncryptionOptions = [
    {
        value: PartnerEmailInEncryption.PARTNER_EMAIL_ENCRYPTION_TLS,
        label: 'PARTNER_EMAIL_ENCRYPTION_TLS',
    },
    {
        value: PartnerEmailInEncryption.PARTNER_EMAIL_ENCRYPTION_SSL,
        label: 'PARTNER_EMAIL_ENCRYPTION_SSL',
    },
    {
        value: PartnerEmailInEncryption.PARTNER_EMAIL_ENCRYPTION_NONE,
        label: 'PARTNER_EMAIL_ENCRYPTION_NONE',
    }
] as const;

export const PartnerEmailOutEncryption = {
    PARTNER_EMAIL_ENCRYPTION_TLS: 1,
    PARTNER_EMAIL_ENCRYPTION_SSL: 2,
    PARTNER_EMAIL_ENCRYPTION_NONE: 3
} as const;

export const PartnerEmailOutEncryptionOptions = [
    {
        value: PartnerEmailOutEncryption.PARTNER_EMAIL_ENCRYPTION_TLS,
        label: 'PARTNER_EMAIL_ENCRYPTION_TLS',
    },
    {
        value: PartnerEmailOutEncryption.PARTNER_EMAIL_ENCRYPTION_SSL,
        label: 'PARTNER_EMAIL_ENCRYPTION_SSL',
    },
    {
        value: PartnerEmailOutEncryption.PARTNER_EMAIL_ENCRYPTION_NONE,
        label: 'PARTNER_EMAIL_ENCRYPTION_NONE',
    }
] as const;

export interface IPartnerEmailConst {
    type: [{id: 1, value: 'PARTNER_EMAIL_TYPE_GENERAL'}, {id: 2, value: 'PARTNER_EMAIL_TYPE_USER'}, {id: 3, value: 'PARTNER_EMAIL_TYPE_MAILER'}],
    authType: [{id: 1, value: 'PARTNER_EMAIL_AUTH_LOGIN'}, {id: 2, value: 'PARTNER_EMAIL_AUTH_OAUTH'}],
    inType: [{id: 1, value: 'PARTNER_EMAIL_INTYPE_IMAP'}, {id: 2, value: 'PARTNER_EMAIL_INTYPE_POP3'}],
    inEncryption: [{id: 1, value: 'PARTNER_EMAIL_ENCRYPTION_TLS'}, {id: 2, value: 'PARTNER_EMAIL_ENCRYPTION_SSL'}, {id: 3, value: 'PARTNER_EMAIL_ENCRYPTION_NONE'}],
    outEncryption: [{id: 1, value: 'PARTNER_EMAIL_ENCRYPTION_TLS'}, {id: 2, value: 'PARTNER_EMAIL_ENCRYPTION_SSL'}, {id: 3, value: 'PARTNER_EMAIL_ENCRYPTION_NONE'}]
}

export interface IPartnerEmail extends IBaseRestEntity {
    /**
     * @description ID.
     */
    id?: number,
    /**
     * @maxLength 128
     * @format email
     */
    emailFrom: string,
    /**
     * @maxLength 128
     * @format email
     */
    name: string,
    /**
     * @description Email account type (general account or a specific user account).
     * @default 1
     */
    type: typeof PartnerEmailType[keyof typeof PartnerEmailType],
    /**
     * @description Authorization type (login/password or OAuth2).
     * @default 1
     */
    authType: typeof PartnerEmailAuthType[keyof typeof PartnerEmailAuthType],
    /**
     * @description Type of the incoming mail (imap or pop3).
     * @default 1
     */
    inType: typeof PartnerEmailInType[keyof typeof PartnerEmailInType],
    /**
     * @description Login to the incoming mail server.
     * @maxLength 128
     */
    inLogin: string,
    /**
     * @description Password to the incoming mail server.
     * @maxLength 128
     */
    inPassword: string,
    /**
     * @description Host of the incoming mail server.
     * @maxLength 128
     */
    inHost: string,
    /**
     * @description Port of the incoming mail server.
     */
    inPort: number,
    /**
     * @description Encryption of the incoming mail (TLS or SSL or None).
     * @default 1
     */
    inEncryption: typeof PartnerEmailInEncryption[keyof typeof PartnerEmailInEncryption],
    /**
     * @description Login to the outgoing mail server (when null or empty, then $inLogin is used).
     * @maxLength 128
     */
    outLogin?: string | null,
    /**
     * @description Password to the outgoing mail server (when null or empty, then $inPassword is used).
     * @maxLength 128
     */
    outPassword?: string | null,
    /**
     * @description Host of the outgoing mail server.
     * @maxLength 128
     */
    outHost: string,
    /**
     * @description Port of the outgoing mail server.
     */
    outPort: number,
    /**
     * @description Encryption of outgoing mail (TLS or SSL or None).
     * @default 1
     */
    outEncryption: typeof PartnerEmailOutEncryption[keyof typeof PartnerEmailOutEncryption],
    /**
     * @description Partner to which email account belongs.
     */
    partner: IPartner | string | null,
    /**
     * @description Record status
     *  0 = deleted
     *  1 = active.
     * @default 1
     */
    status?: number,
    /**
     * @description Creation timestamp.
     * @default CURRENT_TIMESTAMP
     * @format date-time
     */
    created?: string,
    /**
     * @description Last modification timestamp.
     * @format date-time
     */
    modified?: string | null
}
