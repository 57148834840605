/* eslint-disable max-len */
/**
 * This file has been auto generated. Do not edit.
 */
import {IService} from '_types/rest/Entity/IRestService';
import {IUserLogin} from '_types/rest/Entity/IRestUserLogin';
import {IBaseRestEntity} from '_types/rest/IBaseRestEntity';

export const CredentialsState = {
    CREDENTIALS_STATE_REQUESTED: 1,
    CREDENTIALS_STATE_GRANTED: 2,
    CREDENTIALS_STATE_EDIT_REQUESTED: 3,
    CREDENTIALS_STATE_REJECTED: 4
} as const;

export const CredentialsStateOptions = [
    {
        value: CredentialsState.CREDENTIALS_STATE_REQUESTED,
        label: 'CREDENTIALS_STATE_REQUESTED',
    },
    {
        value: CredentialsState.CREDENTIALS_STATE_GRANTED,
        label: 'CREDENTIALS_STATE_GRANTED',
    },
    {
        value: CredentialsState.CREDENTIALS_STATE_EDIT_REQUESTED,
        label: 'CREDENTIALS_STATE_EDIT_REQUESTED',
    },
    {
        value: CredentialsState.CREDENTIALS_STATE_REJECTED,
        label: 'CREDENTIALS_STATE_REJECTED',
    }
] as const;

export const CredentialsSource = {
    CREDENTIALS_SOURCE_FRONTEND_FORM: 1,
    CREDENTIALS_SOURCE_EMAIL: 2,
    CREDENTIALS_SOURCE_PHONE: 3
} as const;

export const CredentialsSourceOptions = [
    {
        value: CredentialsSource.CREDENTIALS_SOURCE_FRONTEND_FORM,
        label: 'CREDENTIALS_SOURCE_FRONTEND_FORM',
    },
    {
        value: CredentialsSource.CREDENTIALS_SOURCE_EMAIL,
        label: 'CREDENTIALS_SOURCE_EMAIL',
    },
    {
        value: CredentialsSource.CREDENTIALS_SOURCE_PHONE,
        label: 'CREDENTIALS_SOURCE_PHONE',
    }
] as const;

export interface ICredentialsConst {
    state: [{id: 1, value: 'CREDENTIALS_STATE_REQUESTED'}, {id: 2, value: 'CREDENTIALS_STATE_GRANTED'}, {id: 3, value: 'CREDENTIALS_STATE_EDIT_REQUESTED'}, {id: 4, value: 'CREDENTIALS_STATE_REJECTED'}],
    source: [{id: 1, value: 'CREDENTIALS_SOURCE_FRONTEND_FORM'}, {id: 2, value: 'CREDENTIALS_SOURCE_EMAIL'}, {id: 3, value: 'CREDENTIALS_SOURCE_PHONE'}]
}

export interface ICredentials extends IBaseRestEntity {
    id?: number,
    type: number,
    /**
     * @default 1
     */
    state?: typeof CredentialsState[keyof typeof CredentialsState],
    /**
     * @default 1
     */
    source?: typeof CredentialsSource[keyof typeof CredentialsSource],
    /**
     * @format date-time
     */
    requestDate?: string,
    /**
     * @format date-time
     */
    grantDate?: string | null,
    info: string,
    service: IService | string,
    requestUserLogin?: IUserLogin | string,
    grantUserLogin?: IUserLogin | string | null,
    /**
     * @description Record status
     *  0 = deleted
     *  1 = active.
     * @default 1
     */
    status?: number,
    /**
     * @description Creation timestamp.
     * @default CURRENT_TIMESTAMP
     * @format date-time
     */
    created?: string,
    /**
     * @description Last modification timestamp.
     * @format date-time
     */
    modified?: string | null
}
