import {Component} from '@angular/core';
import {StateService, UIRouter} from '@uirouter/core';


@Component({
    selector: 'back-button',
    templateUrl: './back-button.component.html'
})
export class BackButtonComponent {
    constructor(
        private readonly stateService: StateService,
        private readonly uiRouter: UIRouter
    ) {
    }

    goBack(): void {
        this.stateService.go('^', this.uiRouter.globals.params);
    }

}
