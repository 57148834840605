import {Pipe, PipeTransform} from '@angular/core';
import {IDynamicFieldOption} from 'src/modules/dynamic-fields/interfaces';
import {OptionsUtil} from 'src/services/options-util';

/**
 * This pipe is universal. The options array can appear on the left or right side of pipe
 */
@Pipe({name: 'fromOptions'})
export class FromOptionsPipe<T = unknown> implements PipeTransform {
    transform(
        arg1: IDynamicFieldOption<T>[] | T,
        arg2: T | IDynamicFieldOption<T>[],
    ): string {
        return OptionsUtil.getFromOption(arg1, arg2);
    }
}
