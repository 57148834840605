<div class="nav-link"
     (click)="setActive()"
     [ngClass]="{'active': active}">
    <span #ref>
        <ng-content></ng-content>
    </span>
    <span *ngIf="!content">
        {{ tabElement.name | translate }}
    </span>
</div>
