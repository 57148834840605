/* eslint-disable max-len */
/**
 * This file has been auto generated. Do not edit.
 */
import {IACLPrivilege} from '_types/rest/Entity/IRestACLPrivilege';
import {IRestACLPrivilege} from '_types/rest/Entity/IRestACLPrivilege';
import {IACLOGRole} from '_types/rest/Entity/IRestACLOGRole';
import {IRestACLOGRole} from '_types/rest/Entity/IRestACLOGRole';
import {IACLApiScope} from '_types/rest/Entity/IRestACLApiScope';
import {IRestACLApiScope} from '_types/rest/Entity/IRestACLApiScope';
import {IACLOrganicGroup} from '_types/rest/Entity/IRestACLOrganicGroup';
import {IRestACLOrganicGroup} from '_types/rest/Entity/IRestACLOrganicGroup';
import {IBaseRestEntity} from '_types/rest/IBaseRestEntity';
import {IRestObject} from 'core/rest/rest-object.service';

export const ACLGrantType = {
    ACL_GRANT_TYPE_ROLE: 1,
    ACL_GRANT_TYPE_OG_ROLE: 2,
    ACL_GRANT_TYPE_API_SCOPE: 3,
    ACL_GRANT_TYPE_ORGANIC_GROUP: 4
} as const;

export const ACLGrantTypeOptions = [
    {
        value: ACLGrantType.ACL_GRANT_TYPE_ROLE,
        label: 'ACL_GRANT_TYPE_ROLE',
    },
    {
        value: ACLGrantType.ACL_GRANT_TYPE_OG_ROLE,
        label: 'ACL_GRANT_TYPE_OG_ROLE',
    },
    {
        value: ACLGrantType.ACL_GRANT_TYPE_API_SCOPE,
        label: 'ACL_GRANT_TYPE_API_SCOPE',
    },
    {
        value: ACLGrantType.ACL_GRANT_TYPE_ORGANIC_GROUP,
        label: 'ACL_GRANT_TYPE_ORGANIC_GROUP',
    }
] as const;

export const ACLGrantRole = {
    ACL_ROLE_ADMIN: 1,
    ACL_ROLE_USER: 2,
    ACL_ROLE_ANONYMOUS: 3,
    ACL_ROLE_API_USER: 4
} as const;

export const ACLGrantRoleOptions = [
    {
        value: ACLGrantRole.ACL_ROLE_ADMIN,
        label: 'ACL_ROLE_ADMIN',
    },
    {
        value: ACLGrantRole.ACL_ROLE_USER,
        label: 'ACL_ROLE_USER',
    },
    {
        value: ACLGrantRole.ACL_ROLE_ANONYMOUS,
        label: 'ACL_ROLE_ANONYMOUS',
    },
    {
        value: ACLGrantRole.ACL_ROLE_API_USER,
        label: 'ACL_ROLE_API_USER',
    }
] as const;

export interface IACLGrantConst {
    type: [{id: 1, value: 'ACL_GRANT_TYPE_ROLE'}, {id: 2, value: 'ACL_GRANT_TYPE_OG_ROLE'}, {id: 3, value: 'ACL_GRANT_TYPE_API_SCOPE'}, {id: 4, value: 'ACL_GRANT_TYPE_ORGANIC_GROUP'}],
    role: [{id: 1, value: 'ACL_ROLE_ADMIN'}, {id: 2, value: 'ACL_ROLE_USER'}, {id: 3, value: 'ACL_ROLE_ANONYMOUS'}, {id: 4, value: 'ACL_ROLE_API_USER'}]
}

export interface IACLGrant extends IBaseRestEntity {
    id?: number,
    ACLPrivilege: IACLPrivilege | string,
    /**
     * @default 1
     */
    type?: typeof ACLGrantType[keyof typeof ACLGrantType],
    /**
     * @default 1
     */
    role?: typeof ACLGrantRole[keyof typeof ACLGrantRole],
    ACLOGRole?: IACLOGRole | string | null,
    ACLApiScope?: IACLApiScope | string | null,
    ACLOrganicGroup?: IACLOrganicGroup | string | null,
    /**
     * @description Record status
     *  0 = deleted
     *  1 = active.
     * @default 1
     */
    status?: number,
    /**
     * @description Creation timestamp.
     * @default CURRENT_TIMESTAMP
     * @format date-time
     */
    created?: string,
    /**
     * @description Last modification timestamp.
     * @format date-time
     */
    modified?: string | null
}

/** @deprecated */
export interface IRestACLGrant extends IACLGrant, IRestObject {
    ACLPrivilege: IRestACLPrivilege | string,
    ACLOGRole?: IRestACLOGRole | string | null,
    ACLApiScope?: IRestACLApiScope | string | null,
    ACLOrganicGroup?: IRestACLOrganicGroup | string | null,
}
