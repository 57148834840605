export const BOOTSTRAP_COLORS = [
    'primary',
    'secondary',
    'success',
    'danger',
    'warning',
    'info',
    'light',
    'dark'
] as const;

export type BootstrapColor = typeof BOOTSTRAP_COLORS[number];

export const THEME_COLORS = [
    'white',
    'red',
    'pink',
    'purple',
    'blue',
    'cyan',
    'teal',
    'yellow',
    'amber',
    'blue-grey',
    'grey-blue'
] as const;

export type ThemeColor = typeof THEME_COLORS[number];

export const COLORS = [...BOOTSTRAP_COLORS, ...THEME_COLORS] as const;

export type Color = BootstrapColor | ThemeColor;
