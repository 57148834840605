import {AbstractOperationDefinition} from 'src/modules/operations/interfaces';
import {AbstractDeleteOperation} from 'view-modules/operations/abstract/abstract-delete.operation';

export class CompaniesAddOperation extends AbstractOperationDefinition<'companies'> {
    readonly endpoint = 'companies';
    readonly name = 'post';
    lang = 'CLIENTS_ADD_CLIENT';
    icon = 'fa-plus';
    component = (): Promise<unknown> => import('./companies-form/companies-form.component');
}

export class CompaniesEditOperation extends AbstractOperationDefinition<'companies'> {
    readonly endpoint = 'companies';
    readonly name = 'put';
    lang = 'CLIENTS_EDIT_CLIENT';
    icon = 'fa-edit';
    component = (): Promise<unknown> => import('./companies-form/companies-form.component');
}

export class CompaniesDeleteOperation extends AbstractDeleteOperation<'companies'> {
    readonly endpoint = 'companies';
    lang = 'DELETE';
    confirmMessage = 'DELETE_CLIENT_CONFIRM';
    confirmOptions = {
        text: 'ACTION_CANNOT_BE_UNDONE',
        langYes: 'DELETE_CLIENT_CONFIRM_YES',
        primaryBtn: 'danger'
    } as const;
}

export const companiesOperations = [
    CompaniesAddOperation,
    CompaniesEditOperation,
    CompaniesDeleteOperation
] as const;
