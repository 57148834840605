import {NgModule} from '@angular/core';
import {WysiwygEditorComponent} from 'src/modules/wysiwyg-editor/wysiwyg-editor.component';
import {AppCommonModule} from 'src/modules/app-common/app-common.module';
import {AppDirectivesModule} from 'src/directives/app-directives.module';
import {SelectionTooltipComponent} from 'src/modules/wysiwyg-editor/tooltips/selection-tooltip/selection-tooltip.component';
import {TranslateModule} from 'src/modules/translate/translate.module';
import {LinkTooltipComponent} from 'src/modules/wysiwyg-editor/tooltips/link-tooltip/link-tooltip.component';
import {AppFormsModule} from 'src/modules/app-forms/app-forms.module';
import {WysiwygContentComponent} from 'src/modules/wysiwyg-editor/wysiwyg-content/wysiwyg-content.component';
import {
    MentionUserListComponent
} from 'src/modules/wysiwyg-editor/tooltips/mention-user-list/mention-user-list.component';
import {ColorModule} from 'src/modules/colors/color.module';
import {BsDropdownModule} from 'angular-bootstrap4';
import {UIRouterModule} from '@uirouter/angular';
import {
    TextColorDropdownComponent
} from 'src/modules/wysiwyg-editor/tool-dropdowns/text-color-dropdown/text-color-dropdown.component';
import {ComponentRendererModule} from 'src/components/component-renderer/component-renderer.module';


@NgModule({
    imports: [
        AppCommonModule,
        AppDirectivesModule,
        AppFormsModule,
        BsDropdownModule,
        ColorModule,
        ComponentRendererModule,
        TranslateModule,
        UIRouterModule,
    ],
    declarations: [
        LinkTooltipComponent,
        MentionUserListComponent,
        SelectionTooltipComponent,
        TextColorDropdownComponent,
        WysiwygContentComponent,
        WysiwygEditorComponent
    ],
    exports: [
        WysiwygContentComponent,
        WysiwygEditorComponent
    ]
})
export class WysiwygEditorModule {
}
