import {Component, Inject, Input} from '@angular/core';
import {RouteResolve} from 'src/modules/route-helper/route-resolve.decorator';
import {RouteHelper} from 'src/modules/route-helper/route-helper.service';
import {IRestObject} from 'src/modules/rest/objects';
import {ENV, IEnvironment} from 'app-custom-providers';
import {Transition} from '@uirouter/core';
import {ConfigService} from 'src/services/config.service';

@Component({
    selector: 'app-cms',
    templateUrl: './app-cms.component.html'
})
export class AppCmsComponent {
    @Input() @RouteResolve(RouteHelper.restResolveObject('cms', (transition: Transition) => {
        return transition.injector()
            .get<ConfigService>(ConfigService)
            .get('cms')
            .find((cms) => cms.cmsType === transition.params().cmsType)
            .id;
    })) cms: IRestObject<'cms'>;

    constructor(
        @Inject(ENV) public env: IEnvironment
    ) {
    }
}
