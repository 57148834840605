import {Directive, ElementRef, InjectFlags, Injector, Input, NgZone, OnInit, Type} from '@angular/core';
import {FormControlDirective, FormControlName, NgControl, NgModel} from '@angular/forms';
import {LocatorsService} from 'src/modules/locators/locators.service';
import {DynamicTableComponent} from 'src/modules/dynamic-table/dynamic-table/dynamic-table.component';
import {
    DynamicTableConfigComponent
} from 'src/modules/dynamic-table/dynamic-table-config/dynamic-table-config.component';
import {
    DynamicTableFiltersComponent
} from 'src/modules/dynamic-table/dynamic-table-filters/dynamic-table-filters.component';
import {DynamicTableCellDirective} from 'src/modules/dynamic-table/dynamic-table/dynamic-table-cell.directive';
import {ActionsComponent} from 'src/modules/actions/actions.component';

interface ILocatorProcessor<T> {
    providers: Type<T>[],
    processor: (instance: T, elRef: ElementRef<HTMLElement>) => string | null
}

const LOCATOR_PROCESSORS: ILocatorProcessor<unknown>[] = [
    {
        providers: [FormControlDirective, FormControlName, NgModel],
        processor: (instance: NgControl): string | null => {
            if (typeof instance.name === 'string') {
                return instance.name;
            } else if (typeof instance.name === 'number') {
                return `_${instance.name}`;
            }

            return null;
        }
    },
    {
        providers: [DynamicTableComponent, DynamicTableConfigComponent, DynamicTableFiltersComponent],
        processor: (
            instance: DynamicTableComponent<object>
                | DynamicTableConfigComponent<object>
                | DynamicTableFiltersComponent,
            elRef: ElementRef<HTMLElement>
        ): string => {
            return elRef.nativeElement.tagName.toLowerCase() + '_' + instance.tableConfig.id;
        }
    },
    {
        providers: [DynamicTableCellDirective],
        processor: (instance: DynamicTableCellDirective<object>, elRef: ElementRef<HTMLElement>): string => {
            return appendIndexIfTableCell(
                elRef.nativeElement.tagName.toLowerCase() + '_' + instance.dynamicTableCell.field,
                elRef.nativeElement
            );
        }
    },
    {
        providers: [ActionsComponent],
        processor: (instance: ActionsComponent, elRef: ElementRef<HTMLElement>): string => {
            return appendIndexIfTableCell('actions', elRef.nativeElement);
        }
    }
];

function appendIndexIfTableCell(locator: string, element: HTMLElement): string {
    if (element.tagName === 'TD') {
        const tr = element.parentElement;
        const index = [...tr.parentElement.children].indexOf(tr);
        if (index > 0) {
            return `${locator}_${index}`;
        }
    }

    return locator;
}

@Directive({
    selector: '[locator]'
        + ',[ngModel],[formControl],[formControlName]'
        + ',dynamic-table,dynamic-table-config,dynamic-table-filters'
        + ',[dynamicTableCell],[actions]'
        + ',button,confirm-modal,task-view'
})
export class LocatorDirective implements OnInit {
    @Input() locator: string;

    constructor(
        private readonly injector: Injector,
        private readonly ngZone: NgZone
    ) {
    }

    ngOnInit(): void {
        if (LocatorsService.mode === 'DISABLED') {
            return;
        }

        this.afterRender(() => {
            const elRef = this.injector.get<ElementRef<HTMLElement>>(ElementRef);

            this.injector
                .get(LocatorsService)
                .addElementLocator(
                    elRef,
                    {
                        locator: this.locator || this.getElementLocator(elRef)
                    }
                );
        });
    }

    /**
     * Ensure that callback is called after DOM has been fully rendered
     */
    private afterRender(callback: () => void): void {
        this.ngZone.runOutsideAngular(() => {
            setTimeout(callback);
        });
    }

    private getElementLocator(elRef: ElementRef<HTMLElement>): string {
        let locator = this.getElementLocatorByFromTag(elRef);

        if (!locator) {
            locator = this.getElementLocatorFromProcessor(elRef);
        }

        return locator;
    }

    private getElementLocatorByFromTag(elRef: ElementRef<HTMLElement>): string {
        switch (elRef.nativeElement.tagName) {
            case 'CONFIRM-MODAL':
            case 'TASK-VIEW':
                return elRef.nativeElement.tagName.toLowerCase();
            case 'BUTTON': {
                const locator = 'btn',
                    type = elRef.nativeElement.getAttribute('type');

                if (type && type !== 'button') {
                    return locator + '_' + type;
                }

                const css = ['close', 'dropdown-toggle'].find((css) => {
                    return elRef.nativeElement.classList.contains(css);
                }) || Array.from(elRef.nativeElement.classList).find((css) => {
                    return css.startsWith('btn-');
                });

                if (css) {
                    return locator + '_' + css;
                }

                return locator;
            }
        }
    }

    private getElementLocatorFromProcessor(elRef: ElementRef<HTMLElement>): string {
        let locator;

        LOCATOR_PROCESSORS.some((item) => {
            return item.providers.some((provider) => {
                const instance = this.injector.get(
                    provider, null, InjectFlags.Self | InjectFlags.Optional
                );

                if (instance) {
                    return locator = item.processor(instance, elRef);
                }
            });
        });

        return locator;
    }
}
