import {AppEditorSchema} from 'src/modules/wysiwyg-editor/configs/app-schema';


export const AppTooltips = {
    LINK: 'link'
} as const;

/**
 * Open tooltip when certain mark is present in selection.
 */
export const TooltipsOpenOnMark = {
    [AppTooltips.LINK]: AppEditorSchema.marks.link
};
