import {AbstractOperationDefinition, IOperationResult} from 'src/modules/operations/interfaces';
import {Injector} from '@angular/core';
import {PinItemsService} from 'src/services/pin-items.service';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

type IPinnedItem = {
    [pinnedProperty in AbstractPinItem['PINNED_PROPERTY']]?: boolean
};

abstract class AbstractPinItem extends AbstractOperationDefinition<string, unknown> {
    readonly endpoint = 'pin_items';
    abstract pin: boolean;

    private readonly PINNED_PROPERTY = 'pinnedItem';

    invoke = (context, injector: Injector): Observable<IOperationResult<boolean>> | IOperationResult<boolean> => {
        const result = {success: true, customValue: this.pin};

        if (!('@id' in context)) {
            return result;
        }

        const pinItemsService = injector.get(PinItemsService);
        return pinItemsService.changeItemPinned(context['@id'], this.pin)
            .pipe(
                map(() => {
                    return result;
                })
            );
    };

    access(context: unknown & IPinnedItem): boolean {
        const isPinned = context[this.PINNED_PROPERTY];
        return this.pin ? !isPinned : isPinned;
    }
}


export class PinItemOperation extends AbstractPinItem {
    readonly name = 'pin';
    lang = 'PIN';
    icon = 'fa-thumbtack';
    pin = true;
}

export class UnPinItemOperation extends AbstractPinItem {
    readonly name = 'unpin';
    lang = 'UNPIN';
    icon = 'fa-thumbtack text-danger';
    pin = false;
}

export const pinItemOperations = [
    PinItemOperation,
    UnPinItemOperation
] as const;
