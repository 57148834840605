import {Component, OnInit, Output, EventEmitter, OnDestroy} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {IDropZoneSettings} from 'src/modules/file-upload/drop-zone/drop-zone.component';
import {IFile} from '_types/rest/Entity/IRestFile';
import {ICanvasImg, TypeMime} from 'src/modules/file-upload/img-upload/img-upload-canvas.directive';
import {Input} from '@angular/core';
import {ImgUploadAnimations} from 'src/modules/file-upload/img-upload/img-upload-animations';
import {RestClient} from 'src/modules/rest/rest-client.service';
import {IRestObject} from 'src/modules/rest/objects';
import {takeUntil} from 'rxjs/operators';

export interface IImgUploadResult {
    file: IFile,
    base64: string,
    thumbnail: string
}

interface CurrentFile {
    name?: string;
    size?: number;
    id?: null | number;
}

@Component({
    selector: 'img-upload',
    templateUrl: './img-upload.component.html',
    animations: ImgUploadAnimations
})
export class ImgUploadComponent implements OnInit, OnDestroy {
    @Input() tableName: string;
    @Input() entityFieldKey: string;
    @Input() entityFieldName = 'file';
    @Input() dropZoneSettings: IDropZoneSettings = {};
    @Input() thumbnailWidth: number;
    @Input() thumbnailHeight: number;
    @Input() width: number;
    @Input() height: number;
    @Input() cropToFit = true;
    @Input() mimeType: TypeMime = 'image/png';
    @Input() visualSettings = false;
    @Output() private readonly imgUploaded = new EventEmitter<IImgUploadResult>();
    @Input() model: IFile;
    @Output() readonly modelChange = new EventEmitter<IFile>();
    visualSettingsWidth: number;
    visualSettingsHeight: number;
    imageAlert = false;
    imgBase64: string;
    isLoading = false;
    fileName = '';
    currentFile: CurrentFile = {};
    showImage = new Subject<string>();
    imgUploadObservable: Observable<string>;
    private _destroy$ = new Subject<void>();

    constructor(
        private restClient: RestClient
    ) {
        this.imgUploadObservable = this.showImage.asObservable();
    }

    ngOnInit(): void {
        this.initSettingsValues();
        this.setDefault('button', false);
        this.setDefault('image', true);
    }

    ngOnDestroy(): void {
        this._destroy$.next();
        this._destroy$.complete();
    }

    inputChange($event: File[]): void {
        this.isLoading = true;
        const file = $event[0];
        this.currentFile = {
            name: file.name,
            size: file.size,
            id: null
        };
        const reader = new FileReader();
        this.fileName = file.name;
        reader.onload = (e) => {
            const isTypeFileImage = !file.type.match(/image.*/);
            this.imgBase64 = e.target.result.toString();

            if (isTypeFileImage) {
                this.imageAlert = true;
                this.isLoading = false;
            } else {
                this.imageAlert = false;
            }

            this.showImage.next(this.imgBase64);
        };
        reader.readAsDataURL(file);
    }

    scaleImage(obs: Observable<ICanvasImg>): void {
        obs.pipe(takeUntil(this._destroy$))
            .subscribe({
                next: (result) => {
                    this.restClient.endpoint('files/upload-base64')
                        .create({
                            file: result.base64,
                            tableName: this.tableName,
                            entityFieldKey: this.entityFieldKey,
                            entityFieldName: this.entityFieldName
                        })
                        .pipe(takeUntil(this._destroy$))
                        .subscribe({
                            next: (response: IRestObject<'files/upload-base64'> & IFile) => {
                                this.isLoading = false;
                                this.modelChange.emit(response);
                                this.imgUploaded.next({
                                    file: response,
                                    base64: result.base64,
                                    thumbnail: result.thumbnail
                                });
                            },
                            error: (error) => {
                                this.imgBase64 = null;
                                this.isLoading = false;
                                throw error;
                            }
                        });
                },
                error: () => {
                    this.model = undefined;
                    this.imgBase64 = null;
                    this.isLoading = false;
                }
            });
    }

    private initSettingsValues(): void {
        this.visualSettingsWidth = this.thumbnailWidth + 9;
        this.visualSettingsHeight =  this.thumbnailHeight + 9;
        this.thumbnailWidth = typeof this.thumbnailWidth !== 'undefined'
            ? this.thumbnailWidth : this.width;
        this.thumbnailHeight = typeof this.thumbnailHeight !== 'undefined'
            ? this.thumbnailHeight : this.height;
    }

    private setDefault(key: string, value: boolean): void {
        if (typeof this.dropZoneSettings[key] === 'undefined') {
            this.dropZoneSettings[key] = value;
        }
    }

}
