import {Directive, ElementRef, HostBinding} from '@angular/core';

@Directive({
    selector: '[actionsBoundary]'
})
/**
 * Helper directive that find elementRef
 */
export class ActionsBoundaryDirective {
    @HostBinding('class.actions-boundary') css = true;

    constructor(public elRef: ElementRef) {
    }
}
