import {
    ConfirmModalService,
    IConfirmModalOptions
} from 'src/modules/global-components/confirm-modal/confirm-modal.service';
import {Injector} from '@angular/core';
import {Observable} from 'rxjs';
import {tap} from 'rxjs/operators';
import {RestClient} from 'src/modules/rest/rest-client.service';
import {AbstractOperationDefinition} from 'src/modules/operations/interfaces';
import {RealEndpointName} from '_types/rest/endpoint-to-type';
import {IRestObject} from 'src/modules/rest/objects';
import {IOperationResult} from 'src/modules/operations/interfaces';


export abstract class AbstractDeleteOperation<TEndpoint extends RealEndpointName>
    extends AbstractOperationDefinition<TEndpoint, unknown> {

    readonly name = 'delete';
    lang = 'DELETE';
    icon = 'fa-trash-alt';
    color = 'danger';

    abstract confirmMessage: string;
    confirmOptions: IConfirmModalOptions;

    invoke(
        context: IRestObject<string>,
        injector: Injector
    ): Observable<IOperationResult> | IOperationResult {
        return injector.get(ConfirmModalService).confirmOperation(
            this.confirmMessage,
            () => {
                return context.delete().pipe(tap(() => injector.get(RestClient).savedToast()));
            },
            Object.assign(
                {
                    primaryBtn: 'danger'
                },
                this.confirmOptions
            )
        );
    }
}
