import {Component, DoCheck, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {ICheckboxDynamicFieldConfig, IDynamicFieldControlValueAccessor} from 'src/modules/dynamic-fields/interfaces';
import {CheckboxControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';

@Component({
    selector: 'custom-checkbox',
    templateUrl: './custom-checkbox.component.html',
    styleUrls: ['./custom-checkbox.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: CustomCheckboxComponent
        }
    ]
})
export class CustomCheckboxComponent
    extends CheckboxControlValueAccessor
    implements IDynamicFieldControlValueAccessor, DoCheck, OnInit {

    @Input() fieldConfig: ICheckboxDynamicFieldConfig;
    @Input() id: string;

    customControlClass: string;
    isDisabled: boolean;

    @ViewChild('inputElement', {static: true}) inputElement: ElementRef<HTMLInputElement>;

    _onChange: (value: unknown) => void;

    ngDoCheck(): void {
        if (this.fieldConfig.isIndeterminate !== this.inputElement.nativeElement.indeterminate) {
            this.inputElement.nativeElement.indeterminate = this.fieldConfig.isIndeterminate;
        }
    }

    ngOnInit(): void {
        // @ts-expect-error: we need to override elementRef of BaseControlValueAccessor for proper setDisabled support
        this._elementRef = this.inputElement;

        switch (this.fieldConfig.viewType) {
            case 'checkBar':
                this.customControlClass = 'custom-checkbox custom-checkbox-bar';
                break;
            case 'switch':
                this.customControlClass = 'custom-switch custom-switch-lg';
                break;
            case 'checkbox':
            default:
                this.customControlClass = 'custom-checkbox';
                break;
        }
    }

    writeValue(value: unknown): void {
        let checked = !!value;
        if ('trueValue' in this.fieldConfig) {
            checked = value === this.fieldConfig.trueValue;
        }

        this.setProperty('checked', checked);
    }

    registerOnChange(fn: (value: unknown) => {}): void {
        this._onChange = (value) => {
            fn(value ? this._getTrueValue() : this._getFalseValue());
        };
    }

    setDisabledState(isDisabled: boolean): void {
        super.setDisabledState(isDisabled);
        this.isDisabled = isDisabled;
    }

    private _getTrueValue(): unknown {
        if ('trueValue' in this.fieldConfig) {
            return this.fieldConfig.trueValue;
        }

        return true;
    }

    private _getFalseValue(): unknown {
        if ('falseValue' in this.fieldConfig) {
            return this.fieldConfig.falseValue;
        }

        return false;
    }
}
