import {Inject, Injectable} from '@angular/core';
import {WINDOW} from 'app-custom-providers';
import {TranslateService} from 'src/modules/translate/translate.service';
import {Location} from '@angular/common';
import {ConfirmModalService} from 'src/modules/global-components/confirm-modal/confirm-modal.service';
import {noop, Observable, of} from 'rxjs';
import {UrlParserService} from 'src/services/url-parser.service';
import {UserService} from 'src/modules/rest/user/user.service';
import {RestClient} from 'src/modules/rest/rest-client.service';
import {ILoginSuccessResponse} from 'src/modules/rest/user/user-login.service';
import {IUserLogin, UserLoginActive} from '_types/rest';
import {RestClientConfig} from 'src/modules/rest/rest-client-config.service';
import {IOperationResult} from 'src/modules/operations/interfaces';

export interface IUserAction {
    operation: string;
    lang: string;
    icon: string;
    isAvailable(user: IUserLogin): boolean;
    endpoint: string;
    confirmPrimaryBtn?: 'danger';
    singleOnly?: boolean;
    execute?: Function
}

export interface IImpersonateResponse {
    token?: string,
    panel?: string,
    user?: unknown
}

const _actions: IUserAction[] = [
    {
        operation: 'resend_activation_mail',
        lang: 'USER_RESEND_ACTIVATION',
        icon: 'fa-envelope',
        confirmPrimaryBtn: 'danger',
        isAvailable: (user) => {
            return user.active === UserLoginActive.USER_STATE_INACTIVE;
        },
        endpoint: 'user_logins/resend_activation_email'
    },
    {
        operation: 'force_mfa_disable',
        lang: 'USER_MFA_DISABLE',
        icon: 'fa-unlock-alt',
        confirmPrimaryBtn: 'danger',
        isAvailable: (user) => {
            return !!user.mfaEnabled;
        },
        endpoint: 'user_logins/mfa/force_disable'
    },
    {
        operation: 'force_privileges',
        lang: 'USER_RECREATE_PRIVILEGES',
        icon: 'fa-sync',
        isAvailable: () => {
            return true;
        },
        endpoint: 'user_logins/refresh_privileges'
    },
    {
        operation: 'impersonate',
        lang: 'USER_IMPERSONATE',
        icon: 'fa-sign-in-alt',
        isAvailable: (user) => {
            return user.active === UserLoginActive.USER_STATE_ACTIVE;
        },
        singleOnly: true,
        endpoint: 'user_logins/impersonate'
    },
    {
        operation: 'force_logout',
        lang: 'USER_LOGOUT',
        icon: 'fa-sign-out-alt',
        isAvailable: () => {
            return true;
        },
        endpoint: 'user_logins/logout'
    }
];

@Injectable({
    providedIn: 'root'
})
export class UserActionsService {
    constructor(
        private translateService: TranslateService,
        @Inject(WINDOW) private window: Window,
        private location: Location,
        private confirmModal: ConfirmModalService,
        private restClient: RestClient,
        private urlParser: UrlParserService,
        private restClientConfig: RestClientConfig,
        private user: UserService) {
    }

    /**
     * Get actions that currently logged in user has privileges to use.
     * @param {RestObject} [user=null]
     * @param {boolean} [eligible=true] - only return actions that are available for given user
     * @return {Array}
     */
    get(user = null, eligible = true): IUserAction[] {
        return _actions.filter((action) => {
            return !user
                || (
                    user.access(action.operation)
                    && (!eligible || action.isAvailable(user))
                );
        }).map((action) => {
            action.execute = (userOrUsers, callback) => this._execute(action, userOrUsers, callback);
            return action;
        });
    }

    _execute(
        action: IUserAction,
        userOrUsers: IUserLogin[] | IUserLogin,
        callback: Function): Observable<IOperationResult> {
        const massAction = Array.isArray(userOrUsers);
        const _users = userOrUsers as IUserLogin[];
        const _user = userOrUsers as IUserLogin;

        if (massAction && action.singleOnly) {
            throw new Error(`Trying to execute '${action.operation}' on multiple users at once!`);
        }
        if (typeof callback !== 'function') {
            callback = noop;
        }

        return this.confirmModal.confirmOperation(
            `${massAction ? 'MASS_' : ''}${action.lang}_CONFIRM`,
            () => {
                return new Observable((subscriber) => {
                    let request$;

                    if (massAction) {
                        request$ = this.restClient.endpoint(action.endpoint).update(undefined,
                            {userLogins: _users.map((item) => item['@id'])});
                    } else {
                        request$ = this.restClient.endpoint(action.endpoint).update(_user.id, {});
                    }
                    request$.subscribe({
                        next: (response) => {
                            if (action.operation === 'impersonate') {
                                this.handleImpersonateResponse(response);
                            }

                            of(callback(response)).subscribe(() => {
                                this.restClient.savedToast();
                                subscriber.next();
                                subscriber.complete();
                            });
                        }, error: (e) => {
                            subscriber.error(e);
                            subscriber.complete();
                        }
                    });
                });
            },
            {
                text: this._translateIfAvailable(
                    `${massAction ? 'MASS_' : ''}${action.lang}_CONFIRM_TEXT`
                ),
                langYes: this._translateIfAvailable(
                    `${massAction ? 'MASS_' : ''}${action.lang}_CONFIRM_LANG_YES`
                ),
                primaryBtn: action.confirmPrimaryBtn || 'success'
            }
        );
    }

    handleImpersonateResponse(response :IImpersonateResponse | ILoginSuccessResponse): void {
        if ('panel' in response && response.panel && response.token) {
            let href = this.urlParser.parse(response.panel).href
                + `login-by-token?token=${response.token}`;
            if (this.restClientConfig.port) {
                href = href.replace(
                    ':' + this.restClientConfig.port,
                    this.window.location.port ? ':' + this.window.location.port : ''
                );
            }
            if (response.panel !== this.window.location.hostname) {
                this.window.open(href, '_blank');
            } else {
                this.window.location.href = href;
            }
        } else if (response.user) {
            this.user.login.loginByResponse(response as ILoginSuccessResponse);
        }
    }

    _translateIfAvailable(translationKey: string): string {
        return this.translateService.has(translationKey) ? this.translateService.get(translationKey) : undefined;
    }
}

