import {
    Component,
    ElementRef,
    Input, OnInit, ViewChild
} from '@angular/core';
import {NavTabsComponent} from 'src/modules/nav-tabs/nav-tabs.component';

export interface ITabElement {
    id: string | number,
    name?: string
}

@Component({
    selector: 'nav-tab',
    templateUrl: './nav-tab.component.html'
})
export class NavTabComponent implements OnInit {
    @Input() public tabElement: ITabElement;
    @Input() selectable = true;
    content = true;

    public active: boolean;
    @ViewChild('ref', { static: true }) ngContent: ElementRef;

    constructor(
        public elRef: ElementRef,
        public navTabs: NavTabsComponent
    ) {}

    ngOnInit(): void {
        this.content = !!this.ngContent.nativeElement.innerHTML.trim().length;
    }

    setActive(): void {
        if (this.selectable && !this.active) {
            this.navTabs.setActive(this);
        }
    }
}
