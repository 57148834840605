import {IWysiwygEditorMetadata} from 'src/modules/wysiwyg-editor/interfaces';
import {IUserLogin} from '_types/rest';
import {Subject} from 'rxjs';


export const getDefaultMetadata = (): IWysiwygEditorMetadata  => {
    return {
        mentions: {
            users: [],
            userMentionedSubject: new Subject<IUserLogin>(),
            disableUserSubject: new Subject<IUserLogin>(),
            enableUserSubject: new Subject<IUserLogin>(),
        }
    };
};
