import {Pipe, PipeTransform} from '@angular/core';

@Pipe({name: 'humanFileSize'})
export class HumanFileSizePipe implements PipeTransform {
    private thresh = 1024;
    private units = ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    transform(value: unknown): unknown | string {
        if (typeof value !== 'number') {
            return value;
        }
        let u = -1;
        if (value < this.thresh) {
            return value + ' B';
        }
        do {
            value /= this.thresh;
            ++u;
        } while (value >= this.thresh);
        return value.toFixed(2) + ' ' + this.units[u];
    }
}
