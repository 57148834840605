import {NgModule} from '@angular/core';
import {LoaderComponent} from 'src/components/loader/loader.component';
import {AppCommonModule} from 'src/modules/app-common/app-common.module';

@NgModule({
    declarations: [
        LoaderComponent
    ],
    imports: [
        AppCommonModule
    ],
    exports: [
        LoaderComponent
    ]
})
export class LoaderModule {}
