import {chainCommands, splitBlock, toggleMark} from 'prosemirror-commands';
import {AppEditorSchema} from 'src/modules/wysiwyg-editor/configs/app-schema';
import {redo, redoDepth, undo, undoDepth} from 'prosemirror-history';
import {wrapInList} from 'prosemirror-schema-list';
import {AppTooltips} from 'src/modules/wysiwyg-editor/configs/app-tooltips';
import {insertBlockFactory, insertText} from 'src/modules/wysiwyg-editor/configs/app-commands';
import {EditorState, Plugin as ProsemirrorPlugin} from 'prosemirror-state';
import {IToolbarSectionDefinition} from 'src/modules/wysiwyg-editor/interfaces';
import {mentionFactoryPluginKey} from 'src/modules/wysiwyg-editor/plugins/mention-factory/mention-factory';
import {
    TextColorDropdownComponent
} from 'src/modules/wysiwyg-editor/tool-dropdowns/text-color-dropdown/text-color-dropdown.component';


export const WYSIWYG_TOOLBAR_ITEMS = {
    PARAGRAPH: 'paragraph',
    BOLD: 'bold',
    ITALIC: 'italic',
    UNDERLINE: 'underline',
    OL: 'list-ol',
    UL: 'list-ul',
    CODE: 'code',
    LINK: 'link',
    UNDO: 'undo',
    REDO: 'redo',
    MENTION: 'mention',
    TEXT_COLOR: 'text-color',
} as const;

export function getToolbarSections(): IToolbarSectionDefinition[] {
    return [
        [
            {
                name: WYSIWYG_TOOLBAR_ITEMS.PARAGRAPH,
                icon: 'fa-paragraph',
                lang: 'WYSIWYG_PARAGRAPH',
                commandFactory: () => {
                    return chainCommands(splitBlock, insertBlockFactory('paragraph'));
                },
            },
            {
                name: WYSIWYG_TOOLBAR_ITEMS.BOLD,
                icon: 'fa-bold',
                lang: 'WYSIWYG_BOLD',
                activeMark: AppEditorSchema.marks.strong,
                commandFactory: () => {
                    return toggleMark(AppEditorSchema.marks.strong);
                },
            },
            {
                name: WYSIWYG_TOOLBAR_ITEMS.ITALIC,
                lang: 'WYSIWYG_ITALIC',
                icon: 'fa-italic',
                activeMark: AppEditorSchema.marks.em,
                commandFactory: () => {
                    return toggleMark(AppEditorSchema.marks.em);
                },
            },
            {
                name: WYSIWYG_TOOLBAR_ITEMS.UNDERLINE,
                lang: 'WYSIWYG_UNDERLINE',
                icon: 'fa-underline',
                activeMark: AppEditorSchema.marks.underline,
                commandFactory: () => {
                    return toggleMark(AppEditorSchema.marks.underline);
                },
            },
            {
                name: WYSIWYG_TOOLBAR_ITEMS.TEXT_COLOR,
                lang: 'WYSIWYG_TEXT_COLOR',
                icon: 'fa-palette',
                toolDropdownComponent: TextColorDropdownComponent
            },
        ],
        [
            {
                name: WYSIWYG_TOOLBAR_ITEMS.OL,
                lang: 'WYSIWYG_LIST_OL',
                icon: 'fa-list-ol',
                commandFactory: () => {
                    return wrapInList(AppEditorSchema.nodes.ordered_list);
                },
            },
            {
                name: WYSIWYG_TOOLBAR_ITEMS.UL,
                lang: 'WYSIWYG_LIST_UL',
                icon: 'fa-list-ul',
                commandFactory: () => {
                    return wrapInList(AppEditorSchema.nodes.bullet_list);
                },
            },
        ],
        [
            {
                name: WYSIWYG_TOOLBAR_ITEMS.CODE,
                lang: 'WYSIWYG_CODE',
                icon: 'fa-code',
                commandFactory: () => {
                    return insertBlockFactory('code_block');
                },
            },
        ],
        [
            {
                name: WYSIWYG_TOOLBAR_ITEMS.LINK,
                lang: 'WYSIWYG_LINK',
                icon: 'fa-link',
                tooltip: AppTooltips.LINK
            },
        ],
        [
            {
                name: WYSIWYG_TOOLBAR_ITEMS.UNDO,
                lang: 'WYSIWYG_UNDO',
                icon: 'fa-undo',
                disabledCallback: (state: EditorState) => {
                    return undoDepth(state) === 0;
                },
                commandFactory: () => {
                    return undo;
                },
            },
            {
                name: WYSIWYG_TOOLBAR_ITEMS.REDO,
                lang: 'WYSIWYG_REDO',
                icon: 'fa-redo',
                disabledCallback: (state: EditorState) => {
                    return redoDepth(state) === 0;
                },
                commandFactory: () => {
                    return redo;
                },
            }
        ],
        [
            {
                name: WYSIWYG_TOOLBAR_ITEMS.MENTION,
                lang: 'WYSIWYG_MENTION',
                icon: 'fa-at',
                disabledCallback: (state: EditorState) => {
                    const mentionPlugin = state.plugins.find((plugin: ProsemirrorPlugin & {key?: string}) => {
                        return plugin.key === mentionFactoryPluginKey(true);
                    });

                    if (!mentionPlugin) {
                        return true;
                    }

                    return mentionPlugin.getState(state).active;
                },
                commandFactory: () => {
                    return insertText('@');
                },
            }
        ]
    ];
}


export type IAvailableToolbarNames = typeof WYSIWYG_TOOLBAR_ITEMS[keyof typeof WYSIWYG_TOOLBAR_ITEMS];
