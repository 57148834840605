import {Inject, Injectable} from '@angular/core';
import {DOCUMENT} from '@angular/common';
import {ENV, IEnvironment} from 'app-custom-providers';
import {LazyLoadService} from 'src/services/lazy-load.service';
import {first} from 'rxjs/operators';


@Injectable({
    providedIn: 'root'
})
export class ThemeService {
    private _defaultTheme: string;
    private _currentTheme: string;
    private _currentIsInline: boolean;
    private _inlineElement: Element;
    private APP: IEnvironment['APP'];
    private readonly _suffix: string;

    constructor(
        private lazyLoadService: LazyLoadService,
        @Inject(DOCUMENT) private document: Document,
        @Inject(ENV) env: IEnvironment
    ) {
        this.APP = env.APP;
        this._suffix = this.APP.production ? '.min.css?' + this.APP.build : '.css';
    }

    setDefaultTheme(theme: string): void {
        this._defaultTheme = theme;
    }

    getDefaultTheme(): string {
        return this._defaultTheme;
    }

    getThemeUrl(theme: string): string {
        return `/theme/${theme}${this._suffix}`;
    }

    setTheme(theme: string, isInline?: boolean): void {
        if (typeof theme === 'undefined') {
            if (typeof this._defaultTheme === 'undefined') {
                return;
            }
            theme = this._defaultTheme;
        }
        if (typeof this._currentTheme !== 'undefined') {
            if (this._currentTheme === theme) {
                return;
            }
            if (!this._currentIsInline) {
                this.lazyLoadService.unloadCss(this.getThemeUrl(this._currentTheme)).pipe(first()).subscribe();
            } else if (!isInline) {
                this._inlineElement.remove();
                this._inlineElement = undefined;
            }
        }
        if (!isInline) {
            this.lazyLoadService.getCss(this.getThemeUrl(theme));
        } else {
            if (typeof this._inlineElement === 'undefined') {
                this._inlineElement = this.document.createElement('style');
                this._inlineElement.setAttribute('type', 'text/css');
                this.document.querySelector('head').append(this._inlineElement);
            }
            this._inlineElement.textContent = theme;
        }
        this._currentTheme = theme;
        this._currentIsInline = !!isInline;
    }

    getCurrentCSS(): string {
        let css = '';
        if (!this._currentIsInline) {
            Object.keys(this.document.styleSheets).some((key) => {
                const styleSheet = this.document.styleSheets[key];
                if (styleSheet.ownerNode.getAttribute('href') === this.getThemeUrl(this._currentTheme)) {
                    [...styleSheet.cssRules].forEach((cssRule) => {
                        css += cssRule.cssText;
                    });
                    return true;
                }
            });
        } else {
            css = this._inlineElement.textContent;
        }
        return css;
    }
}
