import {EditorView} from 'prosemirror-view';
import {AppEditorSchema} from 'src/modules/wysiwyg-editor/configs/app-schema';
import {NodeSelection} from 'prosemirror-state';
import {IEditorNodeAttributes} from 'src/modules/wysiwyg-editor/interfaces';


export class CustomNodesUtils {
    /**
     * Remove node from editor in single transaction based on getPos method from custom nodes.
     * @param view
     * @param getPos
     * @param history
     */
    static removeSelfFromEditor(view: EditorView, getPos: () => number, history = false): void {
        const tr = view.state.tr;
        tr.deleteRange(getPos(), getPos() + 1);
        tr.setMeta('addToHistory', history);
        view.dispatch(tr);
    }

    static replaceSelfWithText(view: EditorView, getPos: () => number, text: string): void {
        CustomNodesUtils.removeSelfFromEditor(view, getPos);

        const tr = view.state.tr;
        tr.insertText(text, getPos());
        view.dispatch(tr);
    }

    static insertNodeOutsideCodeEditor(
        view: EditorView,
        getPos: () => number,
        blockName: string,
        attrs?: IEditorNodeAttributes
    ): void {
        const tr = view.state.tr,
            block = AppEditorSchema.nodes[blockName].createAndFill(attrs);

        tr.insert(getPos() + 1, block);
        const resolvedPos = tr.doc.resolve(getPos() + 1);
        tr.setSelection(new NodeSelection(resolvedPos));
        view.focus();
        view.dispatch(tr);
    }
}
