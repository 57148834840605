import {Component, OnInit} from '@angular/core';
import {Color} from 'src/modules/colors/colors.constant';
import {
    AbstractToolDropdownComponent
} from 'src/modules/wysiwyg-editor/tool-dropdowns/abstract-tool-dropdown.component';
import {Mark} from 'prosemirror-model';


@Component({
    selector: 'text-color-dropdown',
    templateUrl: './text-color-dropdown.component.html'
})
export class TextColorDropdownComponent extends AbstractToolDropdownComponent implements OnInit {
    selectedColor: Color;

    private colorMark: Mark;
    private readonly COLOR_MARK_TYPE = 'color';

    ngOnInit(): void {
        this.getCurrentColorFromSelection();
    }

    private getCurrentColorFromSelection(): void {
        const marks = this.view.state.selection.$anchor.marks(),
            colorMark = marks.find(({type}) => type.name === this.COLOR_MARK_TYPE);

        this.selectedColor = colorMark?.attrs?.color;
        this.colorMark = colorMark;
    }

    toggleMarkInSelection(newColor: Color): void {
        if (!this.view) {
            return;
        }

        if (
            this.colorMark
            && newColor === this.selectedColor
        ) {
            this.removeCurrentMark();
            this.view.focus();
            return;
        }

        this.changeCurrentMark(newColor);
        this.view.focus();
    }

    private changeCurrentMark(colorToChange: Color): void {
        const state = this.view.state,
            {from, to} = state.selection,
            transaction = state.tr,
            mark = state.schema.marks.color.create({
                color: colorToChange
            });

        transaction.addMark(from, to, mark);
        transaction.addStoredMark(mark);

        this.view.dispatch(transaction);
    }

    private removeCurrentMark(): void {
        const state = this.view.state,
            {from, to} = state.selection,
            transaction = state.tr;

        transaction.removeMark(from, to, this.colorMark);
        transaction.removeStoredMark(this.colorMark);

        this.view.dispatch(transaction);
    }
}
