import {Injector} from '@angular/core';
import {ConfirmModalService} from 'src/modules/global-components/confirm-modal/confirm-modal.service';
import {RestClient} from 'src/modules/rest/rest-client.service';
import {Observable} from 'rxjs';
import {IRestObject} from 'src/modules/rest/objects';
import {AbstractOperationDefinition} from 'src/modules/operations/interfaces';
import {ICredentials, With} from '_types/rest';
import {IOperationResult} from 'src/modules/operations/interfaces';

type ICredentialsContext = IRestObject<'credentials', With<ICredentials, 'service' | 'requestUserLogin'>>;

export class AddCredential extends AbstractOperationDefinition<'credentials'> {
    readonly endpoint = 'credentials';
    readonly name = 'post';
    lang = 'ADD';
    icon = 'fa-plus';

    component = (): Promise<unknown> =>
        import('view-modules/operations/credentials/credentials-add/credentials-add.component');
}

export class ViewCredential extends AbstractOperationDefinition<'credentials'> {
    readonly endpoint = 'credentials';
    readonly name = 'get_password';
    lang = 'CLIENTS_CLIENT_ACCESSES_VIEW';
    icon = 'fa-eye';

    component = (): Promise<unknown> =>
        import('view-modules/operations/credentials/credentials-edit/credentials-edit.component');
}

export class EditCredential extends AbstractOperationDefinition<'credentials'> {
    readonly endpoint = 'credentials';
    readonly name = 'put';
    lang = 'EDIT';
    icon = 'fa-pencil-alt';

    component = (): Promise<unknown> =>
        import('view-modules/operations/credentials/credentials-edit/credentials-edit.component');
}

export class RequestRepeatCredential extends AbstractOperationDefinition<'credentials'> {
    readonly endpoint = 'credentials';
    readonly name = 'request';
    lang = 'CLIENTS_CLIENT_ACCESSES_ASK_AGAIN';
    icon = 'fa-envelope';

    component = (): Promise<unknown> =>
        import('view-modules/operations/credentials/credentials-request-repeat/credentials-request-repeat.component');
}

export class DeleteCredential extends AbstractOperationDefinition<'credentials'>  {
    readonly endpoint = 'credentials';
    readonly name = 'delete';
    lang = 'DELETE';
    icon = 'fa-trash-alt';

    invoke = (
        context: ICredentialsContext,
        injector: Injector
    ): Observable<IOperationResult> => {
        const confirmModal = injector.get(ConfirmModalService),
            restClient = injector.get(RestClient);

        return confirmModal.confirmOperation('DELETE_CREDENTIAL_CONFIRM', () => {
            return context
                .delete()
                .subscribe(() => {
                    restClient.savedToast();
                });
        });
    }
}

export const credentialsOperations = [
    AddCredential,
    ViewCredential,
    EditCredential,
    DeleteCredential,
    RequestRepeatCredential
] as const;
