/* eslint-disable max-len */
/**
 * This file has been auto generated. Do not edit.
 */
import {ISearchConsoleTemplateQuery} from '_types/rest/Entity/IRestSearchConsoleTemplateQuery';
import {IRestSearchConsoleTemplateQuery} from '_types/rest/Entity/IRestSearchConsoleTemplateQuery';
import {ISearchConsoleField} from '_types/rest/Entity/IRestSearchConsoleField';
import {IRestSearchConsoleField} from '_types/rest/Entity/IRestSearchConsoleField';
import {ISearchConsoleTemplateFilter} from '_types/rest/Entity/IRestSearchConsoleTemplateFilter';
import {IRestSearchConsoleTemplateFilter} from '_types/rest/Entity/IRestSearchConsoleTemplateFilter';
import {IPartner} from '_types/rest/Entity/IRestPartner';
import {IRestPartner} from '_types/rest/Entity/IRestPartner';
import {ISearchConsoleService} from '_types/rest/Entity/IRestSearchConsoleService';
import {IRestSearchConsoleService} from '_types/rest/Entity/IRestSearchConsoleService';
import {ISearchConsoleReport} from '_types/rest/Entity/IRestSearchConsoleReport';
import {IRestSearchConsoleReport} from '_types/rest/Entity/IRestSearchConsoleReport';
import {IBaseRestEntity} from '_types/rest/IBaseRestEntity';
import {IRestObject} from 'core/rest/rest-object.service';

export const SearchConsoleTemplateReportPeriodType = {
    REPORT_PERIOD_MONTHLY: 1,
    REPORT_PERIOD_WEEKLY: 2
} as const;

export const SearchConsoleTemplateReportPeriodTypeOptions = [
    {
        value: SearchConsoleTemplateReportPeriodType.REPORT_PERIOD_MONTHLY,
        label: 'REPORT_PERIOD_MONTHLY',
    },
    {
        value: SearchConsoleTemplateReportPeriodType.REPORT_PERIOD_WEEKLY,
        label: 'REPORT_PERIOD_WEEKLY',
    }
] as const;

export const SearchConsoleTemplateFetchPeriodType = {
    FETCH_PERIOD_MONTHLY: 1,
    FETCH_PERIOD_WEEKLY: 2,
    FETCH_PERIOD_DAILY: 3
} as const;

export const SearchConsoleTemplateFetchPeriodTypeOptions = [
    {
        value: SearchConsoleTemplateFetchPeriodType.FETCH_PERIOD_MONTHLY,
        label: 'FETCH_PERIOD_MONTHLY',
    },
    {
        value: SearchConsoleTemplateFetchPeriodType.FETCH_PERIOD_WEEKLY,
        label: 'FETCH_PERIOD_WEEKLY',
    },
    {
        value: SearchConsoleTemplateFetchPeriodType.FETCH_PERIOD_DAILY,
        label: 'FETCH_PERIOD_DAILY',
    }
] as const;

export const SearchConsoleTemplateDomainPriority = {
    DOMAIN_PRIORITY_EXACT: 1,
    DOMAIN_PRIORITY_SC_DOMAIN: 2
} as const;

export const SearchConsoleTemplateDomainPriorityOptions = [
    {
        value: SearchConsoleTemplateDomainPriority.DOMAIN_PRIORITY_EXACT,
        label: 'DOMAIN_PRIORITY_EXACT',
    },
    {
        value: SearchConsoleTemplateDomainPriority.DOMAIN_PRIORITY_SC_DOMAIN,
        label: 'DOMAIN_PRIORITY_SC_DOMAIN',
    }
] as const;

export interface ISearchConsoleTemplateConst {
    reportPeriodType: [{id: 1, value: 'REPORT_PERIOD_MONTHLY'}, {id: 2, value: 'REPORT_PERIOD_WEEKLY'}],
    fetchPeriodType: [{id: 1, value: 'FETCH_PERIOD_MONTHLY'}, {id: 2, value: 'FETCH_PERIOD_WEEKLY'}, {id: 3, value: 'FETCH_PERIOD_DAILY'}],
    domainPriority: [{id: 1, value: 'DOMAIN_PRIORITY_EXACT'}, {id: 2, value: 'DOMAIN_PRIORITY_SC_DOMAIN'}]
}

export interface ISearchConsoleTemplate extends IBaseRestEntity {
    /**
     * @description ID.
     */
    id?: number,
    /**
     * @maxLength 128
     */
    name: string,
    /**
     * @default 1
     */
    reportPeriodType?: typeof SearchConsoleTemplateReportPeriodType[keyof typeof SearchConsoleTemplateReportPeriodType],
    /**
     * @default 1
     */
    fetchPeriodType?: typeof SearchConsoleTemplateFetchPeriodType[keyof typeof SearchConsoleTemplateFetchPeriodType],
    queries?: (ISearchConsoleTemplateQuery | string)[],
    /**
     * @default 1
     */
    domainPriority?: typeof SearchConsoleTemplateDomainPriority[keyof typeof SearchConsoleTemplateDomainPriority],
    reportedFields?: (ISearchConsoleField | string)[],
    /**
     * @description Day of fetching, values rely on fetchPeriodType:
     * Monthly - date('j') equals this value
     * Weekly - date('N') equals this value
     * Daily - rely on reportPeriodType, if monthly - date('j') of when should we start a new month, if
     * weekly - date('N') when we should start a new week.
     */
    fetchDay?: number,
    filters?: (ISearchConsoleTemplateFilter | string)[],
    /**
     * @default true
     */
    active?: boolean,
    partner?: IPartner | string | null,
    searchConsoleServices?: (ISearchConsoleService | string)[],
    searchConsoleReports?: (ISearchConsoleReport | string)[],
    /**
     * @description Record status
     *  0 = deleted
     *  1 = active.
     * @default 1
     */
    status?: number,
    /**
     * @description Creation timestamp.
     * @default CURRENT_TIMESTAMP
     * @format date-time
     */
    created?: string,
    /**
     * @description Last modification timestamp.
     * @format date-time
     */
    modified?: string | null
}

/** @deprecated */
export interface IRestSearchConsoleTemplate extends ISearchConsoleTemplate, IRestObject {
    queries?: IRestSearchConsoleTemplateQuery[] | string[],
    reportedFields?: IRestSearchConsoleField[] | string[],
    filters?: IRestSearchConsoleTemplateFilter[] | string[],
    partner?: IRestPartner | string | null,
    searchConsoleServices?: IRestSearchConsoleService[] | string[],
    searchConsoleReports?: IRestSearchConsoleReport[] | string[],
}
